/**
 * Angular core imports.
 */
import { Component, OnInit, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';
/**
* Angular router import.
*/
import { Router, ActivatedRoute } from '@angular/router';
/**
 * Services import.
 */
import { SearchDataService } from '../../dataservices/searching/search-data.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { EventMsgService } from '../../dataservices/master-service/event-msg.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { GetService } from '../../dataservices/get.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Imports from Rxjs.
 */
import { Subject, Subscription } from 'rxjs';
/**
 * Imports from constant.
 */
import { ACTION_ON, ACTION_TYPE, CTA_TYPE, KEYBOARD_EVENT, NAVIGATION_TARGET, PRODUCT_TYPE_CONSTANT, PRODUCT_TYPE_CONSTANT_NAME, SCREEN_NAME } from './../../constants/app.constants';

/**
 * Imports from ngx-owl-carousel.
 */
import { SlidesOutputData } from 'ngx-owl-carousel-o';
/**
 * Interface for data to be display on search popup.
 */
import { SearchData } from '../../interface/search/search.model';

/**
* Interface for EventTracker model.
*/
import { EventTrackerConfig } from '../../interface/event/event';

/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';


@Component({
  selector: 'app-searching',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'handleClick($event)',
  },
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.scss']
})

export class SearchingComponent implements OnInit, OnDestroy, AfterViewInit {
/**
* Commenting below code, as this functionality(i.e - showing filter carousal below search box ) is not supported now
*/
 /* customOptions: OwlOptions = {
    loop: false,
    touchDrag: true,
    navSpeed: 700,
    navText: ['<i class="prev-slider position-absolute"></i>', '<i class="next-slider position-absolute"></i>'],
    margin: 6,
    autoWidth: true,
    items: 4,
    dots: false,
    nav: true
  };
 */
// default event tracking configuration for focus on searchbox
  eventTrackerConfig: EventTrackerConfig = {
    'category': this.mls.googleEventText.global_search.category,
    'action': this.mls.googleEventText.global_search.key5,
    'product_type': PRODUCT_TYPE_CONSTANT.SEARCH,
    'product_id': ''
  };

  @Input() search_place_holder: string;
  @Input() focus_place_holder: string;

  public elementRef;
  public searchContentShow = false;
  public offset = 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public search_data_list: any;
  public recent_search_list = [];
  public trending_search_list = [];
  public isShownNewSearch = false;
  public isShowRecentSearch = false;
  public finished = false;
  public isShownCloseText = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gsplaceholder: any;
  public searchFilter = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sub: any;
  public results: object;
  public searchTerm$ = new Subject<string>();
  public activeSlides: SlidesOutputData;
  public slidesStore = [];
  public searchBoxField: HTMLInputElement;
  public focusItemIndex = -1;

  /**
   * Navigation targets imports from constant file.
   */
  public navigation_targets = NAVIGATION_TARGET;

  /**
   * Data to be display on search popup.
   */
  public searchData!: SearchData;
  /**
  * Varibale for storing subscription of observable for storing searches.
  */
   public searchSuggestionSubscription: Subscription;
   /**
    * Use to subscribe observable for setting focus on input field.
    */
   public focusOnInputSubscription: Subscription;

   /**
    *
    * tracks down all the subscription in the component
    * @private
    * @type {Subscription}
    * @memberof SearchingComponent
    */
   private allSubscriptions: Subscription = new Subscription();

  constructor(
    public dss: DataStorageService,
    public ems: EventMsgService,
    private jwtSer: JwtDecodeService,
    public funCollectionService: FunCollectionService,
    private masterService: MasterserviceService,
    public route: ActivatedRoute,
    private router: Router,
    private analyticSdk: Analytics,
    private searchDataService: SearchDataService,
    myElement: ElementRef,
    private getSer: GetService,
    public mls: NewLanguageService,
    private deviceService: DeviceDetectorService) {

    this.allSubscriptions.add(this.route.params.subscribe(params => {
      if ((params['searchkey']) == undefined) {
        this.gsplaceholder = this.dss.tempsearchstorage;
      }
    }));

    /**
     * Subscribe Observable to confirm wheter to set focus on input field or not.
     */
    this.focusOnInputSubscription = this.searchDataService.focuse$.subscribe(data => {
      if (data === true) {
        this.setFocusOnInputField();
      }
    },
      error => this.funCollectionService.errorHandeler(error)
    );
    this.allSubscriptions.add(this.focusOnInputSubscription);

    /**
     * Subscribe Observable to confirm wheter to set focus on input field or not.
     */
    this.focusOnInputSubscription =  this.searchDataService.focuse$.subscribe(data => {
        if (data === true) {
          this.setFocusOnInputField();
        }
      },
      error => this.funCollectionService.errorHandeler(error)
    );
    this.allSubscriptions.add(this.focusOnInputSubscription);

    this.elementRef = myElement;

    const searchAgainSubscription = this.masterService.search_again.subscribe(() => {
      this.searchBoxField.focus();
    });
    this.allSubscriptions.add(searchAgainSubscription);

    const searchSubscription = this.searchDataService.search(this.searchTerm$)
      .subscribe(results => {
        if (this.searchBoxField.value.trim() == '') {
          this.isShownNewSearch = false;
          return false;
        }
        if (results.status == 1) {
          this.dss.search_keyword = this.searchBoxField.value;
          this.dss.search_keyword = this.dss.search_keyword.trim();
          if (this.dss.search_keyword == ' ' || this.dss.search_keyword == '') {
            this.results = [];
          } else {
            this.results = results.data.search_term;
          }
          this.isShownCloseText = true;
        }

        this.isShownNewSearch = true;
        this.searchContentShow = false;
      });
    this.allSubscriptions.add(searchSubscription);
  }

  /**
   *  view initialization hook
   */
   ngAfterViewInit(): void {
    this.searchBoxField = this.elementRef.nativeElement.querySelector('#searchBox');
    /**
     * Condition used to focus the search on mobile and tablet.
     */
    if (this.deviceService.isMobile() === true || this.deviceService.isTablet() === true) {
      this.searchBoxField.focus();
    }
   }

  /**
   * Execute when component initialized.
   */
  ngOnInit(): void {
    const channelFilterSubscription = this.getSer.getChannelFilter(32).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.dss.global_filter_list = data['data'].filter;
          this.dss.global_filter_list[0].list = this.dss.global_filter_list[0].list.filter(obj => obj.name != '');
        }
      });
    });
    this.allSubscriptions.add(channelFilterSubscription);
  }

  /**
  * On focus calling.
  */
  focusFunction(): void {
    this.searchBoxField.placeholder = this.focus_place_holder;
    this.focusItemIndex = -1;
    this.searchSuggestionsOrRecentHistory();
  }

 /**
  * OnBlurHandler for searchBox.
  */
  onBlurHandler(): void {
    this.searchBoxField.placeholder = this.search_place_holder;
  }

  /**
   * Get data from API.
   */
  searchSuggestionsOrRecentHistory(): void {
    this.searchSuggestionSubscription = this.getSer.getSearchSuggestion().subscribe(response => {
      this.searchData = response.data.searchData;
      this.searchContentShow = true;
    },
      error => this.funCollectionService.errorHandeler(error));
    this.allSubscriptions.add(this.searchSuggestionSubscription);
  }

  /**
   * Used to get data.
   */
  getData(data: SlidesOutputData): void {
    this.activeSlides = data;
  }

  /**
   * Recent search list.
   */
  getRecentSearche(): void {
    this.searchContentShow = true;
  }

  /**
   * Will active when user click on anywhere on page.
   */
  handleClick(event): void {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      // No Code
    } else {
      this.search_data_list = [];
      this.isShownNewSearch = false;
      this.finished = true;
      this.searchContentShow = false;
    }
  }

  /**
   * Reset search box.
   */
  resetSearchBox(): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.SEARCH,0,this.funCollectionService.currentScreename, CTA_TYPE.ICON,ACTION_TYPE.CLICK, ACTION_ON.REMOVE_SEARCH_RESULT, null);
    this.searchBoxField.value = '';
    this.dss.search_keyword = '';
    this.isShownCloseText = false;
    this.focusItemIndex = -1;
    this.searchSuggestionsOrRecentHistory();
  }

  /**
   * Function used to add in recent search.
   */
  addInrecentSearch(): void {
    if (sessionStorage.getItem('recent_search_list') == null) {
      this.recent_search_list = [];
      const temp = { search_text: this.searchBoxField.value };
      this.recent_search_list.push(temp);
      sessionStorage.setItem('recent_search_list', JSON.stringify(this.recent_search_list));
    } else {
      this.recent_search_list = [];
      let sessiontemp = [];
      sessiontemp = JSON.parse(sessionStorage.getItem('recent_search_list'));
      if (sessiontemp.length > 4) {
        sessiontemp.splice(0, 1);
      }
      for (let i = 0; i < sessiontemp.length; i++) {
        if (sessiontemp[i].search_text != this.searchBoxField.value) {
          const temp = { search_text: sessiontemp[i].search_text };
          this.recent_search_list.push(temp);
        }
      }
      const temp = { search_text: this.searchBoxField.value };
      this.recent_search_list.push(temp);
      sessionStorage.setItem('recent_search_list', JSON.stringify(this.recent_search_list));
    }
  }

  /**
   * Function used to navigate to related section.
   */
  navigateToRelatedSection(data): void {
    this.isShownNewSearch = false;
    this.addInrecentSearch();
    switch (data.product_type) {
      case 8: {
        const chid = data.target_identifier.toString();
        if (data.target_identifier != '') {
          this.router.navigateByUrl('/cme/detail/' + chid);
        }
        break;
      }
      case 10: {
        const chid = data.target_identifier.toString();
        if (data.target_identifier != '') {
          this.router.navigateByUrl('/channel/detail/' + (chid));
        }
        break;
      }
      case 14: {
        if (data.target_identifier != '') {
          this.funCollectionService.navigateToUserProfie(data.target_identifier);
        }
        break;
      }
      default:
        {
          this.navigateToSearchAll();
          break;
        }
    }
  }

  /**
   * search bar clicked
   */
  searchBarClicked(e): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.SEARCH, 0, this.funCollectionService.currentScreename, CTA_TYPE.TEXT_INPUT_AREA, ACTION_TYPE.CLICK, ACTION_ON.SEARCH, null);
  }

  /**
   * Function used to navigate all search data.
   */
  navigateToSearchAll(): void {
    if (this.dss.search_keyword != '') {
      this.isShownNewSearch = false;
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 10; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      this.router.navigateByUrl('/search/list/' + text);
    }
  }

  /**
   * Function used to search all recent data.
   */
  navigateToSearchAllFromRecent(text): void {
    this.dss.search_keyword = text;
    this.searchBoxField.value = text;
    setTimeout(() => {
      this.addInrecentSearch();
      this.nvigatetosection(text.target);
    }, 300);
  }

  /**
   * Function used to navigate to section.
   */
  nvigatetosection(target): void {
    if (target.type == this.navigation_targets.CONNECTION) {
      this.funCollectionService.navigateToUserProfie(target.url);
    } else if (target.type == this.navigation_targets.FEED) {
      this.funCollectionService.navigateToFeedDetail(target.id);
    } else if (target.type == this.navigation_targets.EVENT) {
      this.funCollectionService.navigateToEventDetail(target.id);
    } else if (target.type == this.navigation_targets.JOURNAL) {
      this.funCollectionService.navigateToJournalDetail(target.id);
    } else if (target.type == this.navigation_targets.CME) {
      this.funCollectionService.navigateToCmeDetail(target.id);
    } else if (target.type == this.navigation_targets.CHANNEL) {
      this.funCollectionService.navigateToChannelDetail(target.id);
    } else if (target.type == this.navigation_targets.WEBINAR) {
      this.funCollectionService.navigateToWebinarDetail(target.id);
    } else if (target.type == this.navigation_targets.DOCTALK) {
      this.funCollectionService.navigateToSpeakerDetail(target.id);
    }
  }

  /**
   * Used to get search list.
   */
  getSearchList(e): void {
    this.dss.search_keyword = e.target.value;
    if (this.dss.search_keyword == '') {
      this.isShownNewSearch = false;
      return;
    }
    this.isShownNewSearch = true;
    this.offset = 1;
    const searchTermSubscription = this.getSer.getSearchTerm(this.offset,
      this.dss.search_keyword,
      this.dss.global_selected_filter,
      this.dss.search_term).subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          this.results = data.data.search_term;
        });
      });
    this.allSubscriptions.add(searchTermSubscription);
  }

  /**
   * Used to search all.
   */
  searchAll(e): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.SEARCH, 0,this.funCollectionService.currentScreename, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.SHOW_SEARCH_RESULT, null, new Date().getTime(), [{value: e.target.value, key:'VALUE'}]);
    if (e.target.value.trim() == '') {
      return;
    }

    this.dss.search_keyword = e.target.value;
    this.searchContentShow = false;
    this.navigateToSearchAll();
  }

  /**
   * Search given value.
   */
  searchGivenText(text: string): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.SEARCH, 0, this.funCollectionService.currentScreename, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.SEARCH_HISTORY, null, new Date().getTime(), [{key: 'VALUE', value: text}]);
    this.dss.search_keyword = text;
    this.searchContentShow = false;
    this.navigateToSearchAll();
  }

  /**
   * Just paste text on search bar.
   */
  pasteOnSearchBar(text: string): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.SEARCH, 0, this.funCollectionService.currentScreename, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.SEARCH_HISTORY, null,new Date().getTime(), [{key: 'VALUE', value: text}]);
    this.dss.search_keyword = text;
    this.setFocusOnInputField();
  }

  /**
   * Set focus on search field.
   */
  setFocusOnInputField(): void {
    this.searchBoxField.focus();
  }

  /**
   * Used to set filter value in dropdown tabs.
   */
  setFilter(data): void {
    this.searchFilter = data;
    this.getRecentSearche();
    this.dss.global_selected_filter = data;
  }
  /**
   *
   * Handles uparrow and downarrow key event to select dropdown list
   * @param {KeyboardEvent} event
   * @memberof SearchingComponent
   */
  keyDownHandler(event: KeyboardEvent): void {
    switch (event.key) {
      case KEYBOARD_EVENT.ARROWDOWN:
        this.focusItemIndex++;
        if (this.focusItemIndex === this.searchData.keywords.length) {
          this.focusItemIndex = 0;
        }
        this.dss.search_keyword = this.searchData.keywords[this.focusItemIndex].keyword;
        break;
      case KEYBOARD_EVENT.ARROWUP:
        this.focusItemIndex--;
        if (this.focusItemIndex === -1) {
          this.focusItemIndex = this.searchData.keywords.length - 1;
        }
        this.dss.search_keyword = this.searchData.keywords[this.focusItemIndex].keyword;
        break;
    }

  }

  /**
   * Invoked immediately before instance of component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.allSubscriptions) {
      this.allSubscriptions.unsubscribe();
    }
  }
}
