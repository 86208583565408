/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { ButtonDirective } from '../.././../directives/ga.directive';

/**
 * Library imports.
 */
import { Lightbox } from 'ngx-lightbox';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Constant Imports
 */
import { ERROR_CODES, FILES_EXTENSIONS, LOGOUT_TYPE  } from '../../../constants/app.constants';

/**
 * Verify GroupUser Component
 */
@Component({
  selector: 'app-verify-group-user',
  templateUrl: './verify-group-user.component.html',
  styleUrls: ['./verify-group-user.component.scss']
})

export class VerifyGroupUserComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
  * Hold can Deatctivate flag
  */
  public canDeactive = true;
  public _albums = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_data: any;
  public err_msg = '';
  public err_msg_file = '';
  public api_err_msg = '';
  public api_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public benifitesData: any;
  public is_skippable = 0;
  public showColor = false;
  public isUserVerifyModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info_data: any;
  public infoshow = false;
  public url = '';
  public uploadfilearray = [];
  public uploadcounter = 1;
  public uploadFile_api_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public mrm_path: any;
  public error_msg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public upload_mrm_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public mrm_preview: any;
  public isConfirmModalShown = false;
  public isSkipConfirmModalShown = false;
  public user_input_json =
    [{
      'key': 'verify_user',
      'value': { 'mrn': '', 'document_url': '' }
    }];
  public claim_data = { 'key': '', 'value': '' };
  public sessiontime = { 'start_time': '', 'end_time': '' };
  public page_from = this.mls.googleEventText.verifygroup_user.category;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(public multiLangService: MultiLangService,
              public Dss: DataStorageService,
              private jwtSer: JwtDecodeService,
              private masterService: MasterserviceService, private router: Router, public onboardingService: OnboardingService, private _lightbox: Lightbox,
              public mls: NewLanguageService, public fcs: FunCollectionService,
              private directive_ser: ButtonDirective,
              public route: ActivatedRoute
  ) { }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    setTimeout(() => {
      const carouselPrev = (document.querySelectorAll<HTMLElement>('.carousel-control-prev'));
      carouselPrev.forEach(el => el?.classList?.add('crsl-w', 'cursor-pointer', 'crsl-w-l'));
      const carouselNext = (document.querySelectorAll<HTMLElement>('.carousel-control-next'));
      carouselNext.forEach(el => el?.classList?.add('crsl-w', 'cursor-pointer', 'crsl-w-r'));
    }, 100);

    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }
    if (sessionStorage.getItem('verify_user') == null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('verify_user'));
      if (this.verify_mobile.status == 1) {
        this.is_skippable = this.verify_mobile.data.screen_data.is_skippable;
        this.verify_data = this.verify_mobile.data.screen_data['data'];
        this.benifitesData = this.verify_data.benefits;
        this.info_data = this.verify_data.registration.info;
        if (this.verify_data.registration.title == '') {
          this.verify_data.registration.title = this.mls.languageText.verifygroup_user.key6;
        }
        if (this.verify_data.registration.inputHint == '') {
          this.verify_data.registration.inputHint = this.mls.languageText.verifygroup_user.key7;
        }
      }
    }

    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.Dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.verifygroup_user.key6, '', '', this.page_from, '');

      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));
    this.canDeactive = false;
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    console.log(this.Dss.urlsection);
  }

  /**
   * open
   */
  open(src): void {
    this._albums = [{ src: src, thumb: '' }];
    this._lightbox.open(this._albums, 0);
  }

  /**
   * close
   */
  close(): void {
    this._lightbox.close();
  }

  /**
   * clback screenose
   */
  back_screen(): void {
    this.isConfirmModalShown = true;
  }

  /**
   * reset Confirm Modal
   */
  resetConfirmModal(cnfrm_act): void {
    this.canDeactive = cnfrm_act;
    this.isConfirmModalShown = false;
  }

  /**
   * user Input
   */
  userInput(event): void {
    this.err_msg = '';
    const value = event.target.value;
    if (value.trim() != '' && value.trim() != undefined) {
      this.user_input_json[0].value.mrn = value.trim();
      this.showColor = true;
    } else {
      event.target.value = event.target.value.trim();
      this.user_input_json[0].value.mrn = '';
      if (this.upload_mrm_data == '') {
        this.showColor = false;
      }
    }
  }

  /**
   * user Register
   */
  userRegister(): boolean {
    this.err_msg_file = '';
    this.err_msg = '';
    this.api_err_msg = '';

    if (this.user_input_json[0].value.mrn == '' && this.user_input_json[0].value.document_url == '') {
      this.err_msg = this.mls.languageText.verifygroup_user.key8;
      return;
    }
    if (this.verify_data.key == 'email') {
      const email = document.querySelector<HTMLInputElement>('#registration_no').value;
      // eslint-disable-next-line no-useless-escape
      const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (regex.test(email) == false) {
        this.err_msg = this.mls.languageText.verifygroup_user.key9;
        return;
      }
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    this.api_in_process = true;

    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, this.user_input_json, 0).subscribe(registerPromise => {
      this.api_in_process = false;
      registerPromise.then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));

            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {

              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality' || data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              if(sessionStorage.getItem('logoutType') && sessionStorage.getItem('logoutType') === LOGOUT_TYPE.MANUAL) {
                this.router.navigateByUrl('/');
                return;
              }
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else if (this.route.snapshot.queryParams.redirectUrl !== undefined) {
                  this.router.navigate([this.route.snapshot.queryParams.redirectUrl]);
                } else {
                  this.router.navigateByUrl('web');
                }
              }, 500);

            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          }
        } else if (data['status'] == 0) {
          if (data['error'].code == 1005 || data['error'].code === ERROR_CODES.UNABLE_TO_AUTHENTICATE_REQUEST) {
            this.api_err_msg = data['error'].msg;
            return;
          }
        }
      },
        () => {
          this.api_in_process = false;

        });
    }
    ));
  }

  /**
   * skip User Verification
   */
  skipUserVerification(): void {
    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, this.user_input_json, 1).subscribe(registerPromise => {
      this.api_in_process = false;
      registerPromise.then(data => {
        if (data['status'] == 1) {


          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));

            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality' || data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  if(sessionStorage.getItem('logoutType') && sessionStorage.getItem('logoutType') === LOGOUT_TYPE.MANUAL) {
                    this.router.navigateByUrl('/');
                    return;
                  }
                  this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl);
                }

              }, 500);

            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          }
        } else if (data['status'] == 0) {
          this.isSkipConfirmModalShown = false;
          if (data['error'].code == 1005) {
            this.api_err_msg = data['error'].msg;
            return;
          }
        }
      },
        () => {
          this.isSkipConfirmModalShown = false;
          this.api_in_process = false;
        }
      );
    }
    ));
  }

  /**
   * info modal
   */
  infomodal(): void {
    this.isUserVerifyModalShown = true;
  }

  /**
   * reset Info Modal
   */
  resetInfoModal(): void {
    this.isUserVerifyModalShown = false;
    this.info_data = '';
  }

  /**
   * reset Hint Modal
   */
  resetHintModal(): void {
    this.isUserVerifyModalShown = false;
  }

  /**
   * mouse Enter
   */
  mouseEnter(): void {
    this.infoshow = true;
  }

  /**
   * mouse Leave
   */
  mouseLeave(): void {
    this.infoshow = false;
  }

  /**
   * onSelect File
   */
  onSelectFile(fileInput): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.err_msg_file = '';
    this.uploadfilearray = [];
    this.uploadcounter = 1;
    const file = fileInput.target.files[0];
    this.url = fileInput.target.result;
    const file_type = fileInput.target.files[0].type;

    if (file_type == 'image/jpeg' || file_type == 'image/png' || file_type == 'image/webp') {
      const blob = fileInput.target.files[0];
      let BYTES_PER_CHUNK = 1000024; // **1MB chunk sizes.

      const SIZE = blob.size;

      if (SIZE > 100024) {
        BYTES_PER_CHUNK = 100024;
      } else if (SIZE > 10024) {
        BYTES_PER_CHUNK = 10024;
      } else {
        BYTES_PER_CHUNK = 1024;
      }
      let start = 0;
      let end = BYTES_PER_CHUNK;
      let count_x = Math.round(SIZE / BYTES_PER_CHUNK);
      count_x += 1;
      let x = 0;
      while (start < SIZE) {

        const chunk = blob.slice(start, end);
        this.uploadfilearray[this.uploadcounter] = chunk;
        this.uploadcounter = this.uploadcounter + 1;
        start = end;
        end = start + BYTES_PER_CHUNK;
        x++;
      }

      if (x < count_x) {
        const chunk = blob.slice(start, end);
        this.uploadfilearray[this.uploadcounter] = chunk;
        this.uploadcounter = this.uploadcounter + 1;
        start = end;
        end = start + BYTES_PER_CHUNK;
      }

      const num_chunks = this.uploadcounter - 1; // total number of chunks
      this.uploadcounter = 1;

      const fileName = this.getFileName(file);
      this.uploadFile_api_in_process = true;
      this.uploadFile(this.uploadfilearray[this.uploadcounter], fileName, num_chunks, this.uploadcounter, file_type, '1');
      fileInput.target.value = '';
      fileInput.target.files = null;


    } else {
      this.err_msg_file = this.mls.languageText.verifygroup_user.key10;
    }
  }

  /**
   * Method for returning the file name
   */
  getFileName(file): string {
    let fileName = file.type ? `.${file.type.split('/')[1]}` : `.${FILES_EXTENSIONS.PNG}`;
    const currentTimestamp = new Date().getTime();
    fileName = currentTimestamp + (fileName);
    return fileName;
  }

  /**
   * upload File
   */
  uploadFile(blobFile, filename, num_chunks, num, file_type, product_type): void {
    if (this.uploadfilearray.length > this.uploadcounter) {
      this.subscriptions.add(this.onboardingService.newUploadInChunk(blobFile, filename, num_chunks, num, file_type, product_type).subscribe(data => {

        this.mrm_path = data.data.fileurl;
        this.mrm_preview = data.data.absolute_path;
        this.user_input_json[0].value.document_url = this.mrm_path;
        this.uploadcounter = this.uploadcounter + 1;
        if (this.uploadfilearray.length > this.uploadcounter) {
          this.uploadFile(this.uploadfilearray[this.uploadcounter], filename, num_chunks, this.uploadcounter, file_type, product_type);
        } else {
          this.error_msg = '';
          this.upload_mrm_data = data.data;
          if (this.upload_mrm_data.fileurl != undefined) {
            this.uploadFile_api_in_process = false;
            this.showColor = true;
          }
        }

      }, () => {
        this.uploadFile_api_in_process = false;
      }));
    }
  }

  /**
   * remove Mrm
   */
  removeMrm(): void {
    this.upload_mrm_data = '';
    if (this.user_input_json[0].value.mrn == '') {
      this.showColor = false;
    }
  }

  /**
   * number Input
   */
  numberInput(e): void {
    if (e.target.value > 0) {
      if (e.which == 13) {
        this.userRegister();
        return;
      }
    }
  }

  /**
   * skip Onboarding Modal
   */
  skipOnboardingModal(): void {
    this.isSkipConfirmModalShown = true;
  }

  /**
   * reset Skip Confirm Modal
   */
  resetSkipConfirmModal(data): void {
    if (data == true) {
      this.skipUserVerification();
      this.canDeactive = true;
    } else {
      this.isSkipConfirmModalShown = false;
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.verifygroup_user.key6, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Used to show confirmation modal and no internet popup
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    return this.canDeactive;
  }

}
