import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterConnection'
})
export class FilterConnectionPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(arr: any): any {
    const sl = arr;
    const out = [];

    for (let i = 0, l = sl.length; i < l; i++) {
      let unique = true;
      for (let j = 0, k = out.length; j < k; j++) {
        if ((sl[i].custom_id === out[j].custom_id)) {
          unique = false;
        }
      }
      if (unique) {
        out.push(sl[i]);
      }
    }


    return out;
  }

}
