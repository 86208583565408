/**
 * Environment imports.
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs/Observable';

/**
 * Interface imports.
 */
import { InAppData } from '../../onboarding/interface/apiResponse.model';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { AuthService } from '../../auth/auth.service';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';

/**
 * Notification imports.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationListService {

  private token: string;
  public lang = 'en';

  /**
   * Necessary instance created.
   */
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.token = this.authService.getToken();
    if (localStorage.getItem('lang') != null && localStorage.getItem('lang') != 'null' && localStorage.getItem('lang') != undefined) {
      this.lang = localStorage.getItem('lang');
    }
  }

  /**
   * get Notification List
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNotificationList(offset): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.CONNECT_WEB_SERVICE_GET, {
      params: {
        rquest: 'notification_list_per_user',
        app_version: environment.versions.appVersion,
        device_type: environment.deviceType,
        lang: this.lang,
        limit: '',
        offset: offset,
        user_auth_key: this.token
      }
    });
  }

  /**
   * notification unread
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notification_unread(message_id_json): Observable<any> {
    message_id_json = '[' + message_id_json + ']';
    const body = new HttpParams()
      .set('message_id_json', message_id_json)
      .set('user_auth_key', this.token)
      .set('lang', this.lang)
      .set('app_version', environment.versions.appVersion)
      .set('app_version', environment.deviceType);
    return this.httpClient.post(API.CONNECT_WEB_SERVICE_SET, body, {
      params: { rquest: 'notification_unread' }
    });
  }

  /**
   * get Notification Count
   */
  getNotificationCunt(): Observable<InAppData> {
    return this.httpClient.get<InAppData>(API.IN_APP);
  }
}
