<div class="form-input" [ngClass]="{'disabled' : disabled, 'error' : error}" id="c_{{componentKey}}">
  <div class="otp-fields">
    <input #inp id="otp_{{i}}_{{componentKey}}" *ngFor="let item of otpForm?.controls | keys;let i=index"
      class="input-field mr-md-3 mr-2" [pattern]="config.allowNumbersOnly ? '\\d*' : ''" [type]="inputType"
      [autocomplete]="config.autocomplete" [formControl]="otpForm.controls[item]" (keyup)="onKeyUp($event,i)"
      (input)="onInput($event)" (keydown)="onKeyDown($event,i)" (paste)="handlePaste($event)" />
  </div>
  <p *ngIf="error" class="help-block">
    {{errorMsg}}
  </p>
</div>
