import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-verify-hint',
  templateUrl: './user-verify-hint.component.html',
  styleUrls: ['./user-verify-hint.component.scss']
})
export class UserVerifyHintComponent {
  @Output() resetHintModal = new EventEmitter();
  @Input() isHintModalShown;
  @Input() info_data;

  constructor() {
    // No Code
  }

  resetHintMsgModal(): void {
    this.resetHintModal.emit(false);
  }


  showModal(): void {
    this.isHintModalShown = true;

  }

  hideModal(): void {
    this.isHintModalShown = false;
    this.resetHintModal.emit(false);
  }

  onHidden(): void {
    this.isHintModalShown = false;
    this.resetHintModal.emit(false);
  }

}
