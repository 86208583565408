/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { PostApiService } from './../post-api.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { DataStorageService } from '../data-storage.service';
import { DocquityAnalyticsService } from '../docquity-analytics/docquity-analytics.service';
import { GetService } from '../get.service';
import { JwtDecodeService } from '../jwt-decode.service';
import { NewLanguageService } from '../new-language/new-language.service';
import { SqsdataService } from '../sqsdata.service';
import { PollSocketService } from '../../dataservices/poll-socket.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
import { HistoricalPollResponseData } from '../../onboarding/interface/apiResponse.model';

/**
 * Rxjs imports.
 */
import { BehaviorSubject } from 'rxjs';

/**
 * Constant imports.
 */
import { AGORA_CONTROLS, AWS_SQS, CONTROL_PANEL_ELEMENTS, LOCATION, NUMBERS, OVERFLOW_MENU_BUTTONS, PIP_TAG_LIMIT, PIP_TAG_LOCAL_STORAGE, PLAYER_STATUS, POLL_AGORA_STATUS, PRODUCT_TYPE_CONSTANT, SEEK_BARS_COLOR, SHOW_NEW_TAG, SPONSORS_EXPRESSION, STATUS_CODE, TUTORIAL_LOCAL_STORAGE, WEBINAR_AGORA_STATUS, SCREEN_NAME } from '../../constants/app.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';

/**
 * Directive imports.
 */
import { ButtonDirective } from '../../directives/ga.directive';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Modal imports.
 */
import { Poll } from '../../webinar/webinar-detail/models/Poll';
import { PollDataForPoll, SubmitPollData } from '../../webinar/webinar-detail/models/PollInterface';
import { SponsorsColorCode } from '../../broadcast/models/sponsorsColorCode';
import { Ticker } from '../../broadcast/models/ticker';
import { Sponsor } from '../../broadcast/models/sponsor';
import { takeWhile } from 'rxjs/operators';
// TODO:Pavan: remove commented code and console.
/// <reference types="aws-sdk" />

@Injectable({
  providedIn: 'root'
})
export class DqVideoPlayerService implements OnDestroy {

  /**
   * Used to store the Webinar detail.
   * Used to check the past webinar status.
   * Used to check if agora is start/ended.
   * Used to check the exit miniPlayer.
   * Get the flag to start the pip tutorial.
   * Used to check the exit audio miniPlayer.
   * Get the flag to start the audio tutorial.
   * Get the detail for the audio tutorial.
   * Audio player is active or not.
   * Status of the audio play, pause, ended.
   * Flag to auto play the audio.
   * Enable the signed in cookies for the audio.
   */
  public doctalkDetail = new BehaviorSubject('');
  public checkForPastWebinar = new BehaviorSubject({ playerStage: '' });
  public webinarStatus = new BehaviorSubject('');
  public exitMiniPlayer = new BehaviorSubject(false);
  public isPipTutorialEnabled = new BehaviorSubject(false);
  public isSubscribePopupOpen = false;
  public exitMiniAudioPlayer = new BehaviorSubject(false);
  public isTextToSpeechTutorialEnabled = new BehaviorSubject(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public audioPlayerDetail: any = {};
  public isAudioPlayer = false;
  public isAudioMiniPlayer = false;
  public audioPlayerShortForm = false;
  public articleId: number;
  public audioPlayerStatus = PLAYER_STATUS.PAUSE;
  public autoplay = true;
  public enableCookiesForAudio = true;
  public isPipEnabled = true;
  public webinarContinueWatchResumeTime: number;

  /**
   * Mini player enable/disable flag.
   * Store the last log sqs data.
   * Store the sqs credentials.
   * Store the language to send in the apis.
   * Store the timezone offset.
   * Joined webinar status for the agora.
   * Store the current webinar id.
   * Store the fullscreen mode status.
   * Flag for pause the webinar when consent is open.
   * Contain the color code for sponsors panel.
   * Device compatible message.
   * Remove the can deactivate in case of pip mode request.
   * Used to show or hide the poll component.
   * used to store the poll values.
   * used to send the submit poll data to post service.
   * used to send the poll question and option to poll component.
   * Used to set the loading flag.
   * Ticker list data.
   * Agora option data.
   * Is agora flag.
   * Sponsor list data.
   * Shaka player configuration constants.
   * @calledFrom for capturing screen name
   * @communityId for communityId
   */
  public isMiniPlayerVisible = false;
  public isChatPanelVisible = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public lastSQSData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public SQSCredentials: any;
  public lang: string;
  public timeZoneOffset: string;
  public page_from = this.mls.googleEventText.webinar_detail.category;
  public joinedWebinar: boolean;
  public endwebinarFlag = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public section_key: any = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public offset: any = '';
  public webinarId = '';
  public fullscreenchat = false;
  public fullScreenMode: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fullscreenControl: any;
  public playPauseVideoState = false;
  public sponsorsColorCode: SponsorsColorCode;
  public deviceCompatibleMsg = this.mls.languageText.webinar_detail.key13;
  public removeCanDeactivate = false;
  public isPollModalShown = false;
  public poll: Poll;
  public submitPollData: SubmitPollData;
  public recievePollData: PollDataForPoll;
  public isLoading = false;
  public endBannerVisible = false;
  public tickerList: Ticker[] = [];
  public agoraOption = {
    appID: '',
    channel: '',
    uid: null,
    token: '',
    rtmUid: null,
    rtmToken: ''
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public is_agora: any = false;
  public sponsorList: Sponsor[] = [];
  public controlPanelElements = CONTROL_PANEL_ELEMENTS;
  public overflowMenuButtons = OVERFLOW_MENU_BUTTONS;
  public seekBarsColor = SEEK_BARS_COLOR;
  public recordedConfig = {
    addSeekBar: true,
    addBigPlayButton: true,
    enableTooltips: false,
    controlPanelElements: [this.controlPanelElements.PLAY_PAUSE, this.controlPanelElements.SPACER, this.controlPanelElements.TIME_AND_DURATION,
    this.controlPanelElements.MUTE, this.controlPanelElements.LIVE_POLL, this.controlPanelElements.LIVE_POLL, this.isPipEnabled ? this.controlPanelElements.MINI_PLAYER : '', this.controlPanelElements.FULLSCREEN, this.controlPanelElements.OVERFLOW_MENU
    ],
    overflowMenuButtons: [this.overflowMenuButtons.QUALITY, this.overflowMenuButtons.CAPTIONS],
    seekBarColors: {
      base: this.seekBarsColor.BASE,
      buffered: this.seekBarsColor.BUFFERED,
      played: this.seekBarsColor.PLAYED
    },
    castReceiverAppId: ''
  };
  public liveConfig = {
    addBigPlayButton: true,
    enableTooltips: false,
    controlPanelElements: [this.controlPanelElements.PLAY_PAUSE, this.controlPanelElements.LIVE, this.controlPanelElements.SPACER,
    this.controlPanelElements.MUTE, this.controlPanelElements.LIVE_POLL, this.isPipEnabled ? this.controlPanelElements.MINI_PLAYER : '', this.controlPanelElements.FULLSCREEN, this.controlPanelElements.OVERFLOW_MENU
    ],
    overflowMenuButtons: [this.overflowMenuButtons.QUALITY, this.overflowMenuButtons.CAPTIONS],
    castReceiverAppId: ''
  };
  public showPipNewTag = '';
  public pipNewTagCount = 1;
  /**
   * Live Poll Modal Visibility Flag
   * Live Poll Modal Full Screen Flag
   * Live Poll Modal Overlay Flag
   */
  public openLivePollWithoutFullScreen = true;
  public livePollPopupOverlayHide = false;

  public shakaPlayerFullScreenStatus: boolean;
  /**
   * Used to subscribe the subscription.
   */
  private serviceActive = true;
  private eventName = '';
  public callingFrom = SCREEN_NAME.WEBINAR_DETAIL;
  public isExplicitConsentMandatory = false;

  /** TO DO: To remove screen wise check as the webinar posted in community details are not
    not available in webinar listing page
    */
  public calledFrom: string;
  public communityId: string;
  
  public linkedProductData: any;
  /**
   * resume on key for past webinar
   */
  seekTimePastWebinarFlag = 0;

  /**
   * Necessary instances
   */
  constructor(
    private sqsdataService: SqsdataService,
    private mls: NewLanguageService,
    private docAnalyticsService: DocquityAnalyticsService,
    private Dss: DataStorageService,
    private getApiService: GetService,
    private jwtSer: JwtDecodeService,
    private directive_ser: ButtonDirective,
    private fcs: FunCollectionService,
    private postSer: PostApiService,
    public pollDataSer: PollSocketService,
    private analyticsEventService: AnalyticsEventService
  ) {
    /**
 * Import entire SDK.
 */
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    this.getLanguage();
    this.timeZoneOffset = this.getTimeZoneString();
  }

  /**
   * Get timezone string.
   */
  getTimeZoneString(): string {
    return new Date().toTimeString().slice(9);
  }

  /**
   * Get language from the localStorage.
   */
  getLanguage(): void {
    if (localStorage.getItem('lang') != null) {
      this.lang = localStorage.getItem('lang');
    }
  }

  /**
   * initialize agora value and add sponsor time.
   */
  initializeAgora(doctalkDetail): void {
    this.agoraOption.appID = doctalkDetail.media[0].partner.data.app_id;
    this.agoraOption.channel = doctalkDetail.media[0].partner.data.chanel;
    doctalkDetail.sponsor.forEach(sponsor => {
      if (!new RegExp(SPONSORS_EXPRESSION.WEBSITE, SPONSORS_EXPRESSION.VARIABLES).test(sponsor.website)) {
        if (sponsor.website !== null && sponsor.website !== '') {
          sponsor.website = SPONSORS_EXPRESSION.HTTP + sponsor.website;
        }
      }
      this.sponsorList.push(new Sponsor(sponsor.sponsor_id, sponsor.name, sponsor.logo,
        sponsor.website, sponsor.start_time, sponsor.end_time,
        sponsor.is_primary));
    });
    this.getAgoraToken();
  }

  /**
   * Get agora token.
   */
  getAgoraToken(): void {
    this.getApiService.getAgoraToken(PRODUCT_TYPE_CONSTANT.WEBINAR, this.webinarId)
      .pipe(takeWhile(() => this.serviceActive))
      .subscribe(data => {
        if (data['status'] == NUMBERS.ONE && data['code'] == STATUS_CODE.TWO_THOUSAND) {
          this.agoraOption.token = data['data'].tokendetails[NUMBERS.ZERO].token;
          this.agoraOption.uid = data['data'].tokendetails[NUMBERS.ZERO].udid;
          this.agoraOption.rtmToken = data['data'].tokendetails[NUMBERS.ONE].token;
          this.agoraOption.rtmUid = data['data'].tokendetails[NUMBERS.ONE].udid;
        } else {
          this.fcs.errorHandeler(data);
        }
      }, () => {
        console.log('Something went wrong in getAgoraToken');
      });
  }

  /**
   * Calculate sqs interval.
   */
  receiveSQSInterval(data, productType, productTypeId, eventName, screenName, unlockWatchTime?, hasParent?): void {
    if (data.timespent === NUMBERS.ZERO) {
      return;
    }
    this.page_from = screenName;
    this.eventName = eventName;
    this.lastSQSData = data;
    if (this.SQSCredentials && this.SQSCredentials !== undefined && this.SQSCredentials !== null) {
      this.sqsdataService.SQSEventData.location = {
        latitude: LOCATION.LATITUDE,
        longitude: LOCATION.LONGITUDE,
        local: this.lang,
        time_zone: this.timeZoneOffset
      };

      this.linkedProductData = {
        unlock_time: unlockWatchTime,
        products: [
          {
            product_type: productType,
            product_id: productTypeId
          }
        ]
      }

      this.sqsdataService.SQSEventData.linked_product_restriction = {
        unlock_time: unlockWatchTime,
        products: [
          {
            product_type: productType,
            product_id: productTypeId
          }
        ]
      };

      const currentDate = new Date();
      const sessionTime = { start_time: currentDate.getTime() - data.SQSTimeInterval, end_time: currentDate.getTime() };
      this.sqsdataService.sqsVideoLog(Object.assign({}, sessionTime),
        this.eventName,
        productType,
        productTypeId,
        this.page_from,
        this.Dss.user_profile_data.profile.track_id, data.seekTime, data.timespent, this.linkedProductData, hasParent);

      this.sqsdataService.SQSEventData.session_time = sessionTime;
      const events = { device_info: this.docAnalyticsService.sqsDeviceInfo, event: [this.sqsdataService.SQSEventData]};
      /**
       *  Send message in SQS
       */
      const sqs = new this.Dss.awsInstance.SQS({
        region: AWS_SQS.REGION,
        apiVersion: AWS_SQS.API_VERSION,
        accessKeyId: this.SQSCredentials.sts.Credentials.AccessKeyId,
        secretAccessKey: this.SQSCredentials.sts.Credentials.SecretAccessKey,
        sessionToken: this.SQSCredentials.sts.Credentials.SessionToken
      });
      const params = {
        DelaySeconds: AWS_SQS.DELAY_SECONDS,
        MessageBody: JSON.stringify(events),
        QueueUrl: environment.baseUrls.sqsQueueURL
      };
      sqs.sendMessage(params, (err) => {
        if (err) {
          this.getToken(NUMBERS.ONE, productType, productTypeId);
        }
      });
    }
  }

  /**
   * Used to get STS token from API
   */
  getToken(type: number, productType: number, productTypeId: string): void {
    this.getApiService.getStSToken()
      .pipe(takeWhile(() => this.serviceActive))
      .subscribe(data => {
        if (data && data['status'] === NUMBERS.ONE && data['code'] === STATUS_CODE.TWO_THOUSAND && data['data'] !== undefined && data['data'] !== null) {
          this.SQSCredentials = data['data'];
          if (type === NUMBERS.ONE) {
            this.receiveSQSInterval(this.lastSQSData, productType, productTypeId, this.eventName, this.page_from);
          }
        }
      });
  }

  /**
   * Triggers the controls of webinar agora.
   */
  controlsTrigger(data): void {
    this.fullscreenchat = data.display;
  }

  /**
   * Add the chat in controls of webinar agora.
   */
  controlsEmitchat(event): void {
    this.fullscreenchat = event;
    this.fullscreenControl = [
      { name: AGORA_CONTROLS.CHAT.name, url: AGORA_CONTROLS.CHAT.url, action: '', display: event },
    ];
  }

  /**
   * Controls send in fullscreen agora webinar.
   */
  fullscreenControls(): void {
    this.fullscreenControl = [
      { name: AGORA_CONTROLS.CHAT.name, url: AGORA_CONTROLS.CHAT.url, action: '', display: true },
    ];
  }

  /**
   * Click on full screen.
   */
  fullscreenStatus(event): void {
    this.fullScreenMode = event;
    if (this.fullScreenMode == true) {
      this.fullscreenchat = true;
    } else {
      this.fullscreenchat = false;
      this.livePollPopupOverlayHide = false;
    }
  }

  /**
   * Check for when joined webinar for NPS.
   */
  joinedStatus(event): void {
    this.joinedWebinar = event;
    this.webinarStatus.next(WEBINAR_AGORA_STATUS.START);
  }

  /**
   * receiving clicked sponsor data and send it to analytic.
   */
  sponsorLogoAnalytic(data, webinarId, productType): void {
    this.sessionTimeForEvent();
    this.directive_ser.productDetailEvent(Object.assign({},
      this.fcs.sessiontime), this.mls.googleEventText.webinar_detail.key4,
      productType,
      webinarId,
      this.mls.googleEventText.webinar_detail.category,
      this.Dss.user_profile_data.profile.track_id);
  }

  /**
   * Add the session for time event.
   */
  sessionTimeForEvent(): void {
    const currentDate = new Date();
    this.fcs.sessiontime.start_time = currentDate.getTime().toString();
    this.fcs.sessiontime.end_time = currentDate.getTime().toString();
  }

  /**
   * Method for Submit Poll in Poll submit api.
   */
  pollSubmit(pollData): void {
    this.postSer.submitWebinarPoll(pollData)
      .pipe(takeWhile(() => this.serviceActive))
      .subscribe((data) => {
        this.isLoading = false;
        if (data['status'] === NUMBERS.ONE) {
          this.fcs.showSuccess(data['msg'], 'Success');
          this.isPollModalShown = false;
        } else if (data['status'] === NUMBERS.ZERO) {
          this.fcs.showError(data['msg'], 'Error');
          this.fcs.errorHandeler(data);
          this.isPollModalShown = true;
          return;
        }
      },
        (err) => {
          this.fcs.showError(err['msg'], 'Error');
          this.isLoading = false;
          this.isPollModalShown = true;
        }
      );
  }

  /*
   * Method for receving the poll cross click event
   */
  pollcrossClick(event, productType, productTypeId): void {
    this.sessionTimeForEvent();
    this.directive_ser.productDetailEvent(Object.assign({},
      this.fcs.sessiontime),
      this.mls.googleEventText.poll_popup.key3,
      productType,
      productTypeId,
      this.page_from,
      this.Dss.user_profile_data.profile.track_id);
    this.isPollModalShown = false;
  }

  /**
   * Method for receving the poll submit click event
   */
  submitPollClick(event, product_type, product_type_id): void {
    this.isLoading = true;
    this.submitPollData = {
      pollId: this.poll.pollId,
      optionId: event.option_id,
      productType: product_type,
      productTypeId: product_type_id
    };

    this.pollSubmit(this.submitPollData);

    this.sessionTimeForEvent();
    this.directive_ser.productDetailEvent(Object.assign({},
      this.fcs.sessiontime),
      this.mls.googleEventText.poll_popup.key2,
      product_type,
      product_type_id,
      this.page_from,
      this.Dss.user_profile_data.profile.track_id);
  }

  /**
   * initialize sponsors for agora webinar.
   */
  initializeSponsor(data): void {
    this.sponsorsColorCode = new SponsorsColorCode(
      data.color_code.header_code,
      data.color_code.footer_code
    );
  }

  /**
   * start the poll in case of live webinar.
   */
  initializePoll(data): void {
    const pollData = data.poll.find(obj => obj.poll_state === POLL_AGORA_STATUS.LIVE);
    if (pollData !== undefined) {
      const pollSubmitData = data.is_poll_submit.find(obj => obj.poll_id === pollData.poll_id);
      if (pollSubmitData.is_poll_submit === NUMBERS.ZERO) {
        this.poll = new Poll();
        this.poll = pollData;
        this.poll.pollId = pollData.poll_id;
        this.setValueForPoll(data.product_type, data.product_type_id);
      }
    }
  }

  /**
   * Get poll detail.
   */
  polldetail(product_type, product_type_id): void {
    this.getApiService.getWebinarList(this.section_key, this.offset, this.webinarId)
      .pipe(takeWhile(() => this.serviceActive))
      .subscribe(
        resData => {
          this.jwtSer.decodeData(resData).then(data => {
            if (data.status == NUMBERS.ONE) {
              const pollData = data.data.records.webinar.poll.find(obj => obj.poll_state === POLL_AGORA_STATUS.LIVE);
              if (pollData !== undefined && pollData.poll_state === POLL_AGORA_STATUS.LIVE) {
                const pollSubmitData = data.data.records.webinar.is_poll_submit.find(obj => obj.poll_id === pollData.poll_id);
                if (pollSubmitData.is_poll_submit === NUMBERS.ZERO) {
                  this.poll = new Poll();
                  this.poll = pollData;
                  this.poll.pollId = pollData.poll_id;
                  this.setValueForPoll(product_type, product_type_id);
                }
              }
              if (pollData === undefined) {
                this.isPollModalShown = false;
              }
            }
          });
        });
  }

  /**
   * Method to set the poll value for poll component.
   */
  setValueForPoll(product_type, product_type_id): void {
    this.recievePollData = {
      question: this.poll.question,
      options: this.poll.options
    };

    this.isPollModalShown = true;

    this.sessionTimeForEvent();
    this.directive_ser.productDetailEvent(Object.assign({}, this.fcs.sessiontime),
      this.mls.googleEventText.poll_popup.key1,
      product_type,
      product_type_id,
      this.page_from,
      this.Dss.user_profile_data.profile.track_id);
  }

  /**
   * initialize ticker for agora webinar.
   */
  initializeTicker(doctalkDetail): void {
    doctalkDetail.ticker.forEach(ticker => {
      this.tickerList.push(new Ticker(ticker.title, ticker.start_datetime, ticker.end_datetime,
        ticker.begining_to_end));
    });
  }

  /**
   * Reset the shaka controls.
   */
  resetShakaControls(): void {
    this.recordedConfig = {
      addSeekBar: true,
      addBigPlayButton: true,
      enableTooltips: false,
      controlPanelElements: [this.controlPanelElements.PLAY_PAUSE, this.controlPanelElements.SPACER, this.controlPanelElements.TIME_AND_DURATION,
      this.controlPanelElements.MUTE, this.controlPanelElements.LIVE_POLL, this.isPipEnabled ? this.controlPanelElements.MINI_PLAYER : '', this.controlPanelElements.FULLSCREEN, this.controlPanelElements.OVERFLOW_MENU
      ],
      overflowMenuButtons: [this.overflowMenuButtons.QUALITY, this.overflowMenuButtons.CAPTIONS],
      seekBarColors: {
        base: this.seekBarsColor.BASE,
        buffered: this.seekBarsColor.BUFFERED,
        played: this.seekBarsColor.PLAYED
      },
      castReceiverAppId: ''
    };
    this.liveConfig = {
      addBigPlayButton: true,
      enableTooltips: false,
      controlPanelElements: [this.controlPanelElements.PLAY_PAUSE, this.controlPanelElements.LIVE, this.controlPanelElements.SPACER,
      this.controlPanelElements.MUTE, this.controlPanelElements.LIVE_POLL, this.isPipEnabled ? this.controlPanelElements.MINI_PLAYER : '', this.controlPanelElements.FULLSCREEN, this.controlPanelElements.OVERFLOW_MENU
      ],
      overflowMenuButtons: [this.overflowMenuButtons.QUALITY, this.overflowMenuButtons.CAPTIONS],
      castReceiverAppId: ''
    };
  }

  /**
   * Add the mini player controls in shaka.
   */
  addMiniPlayerShakaControls(): void {
    this.recordedConfig = {
      addSeekBar: true,
      addBigPlayButton: true,
      enableTooltips: false,
      controlPanelElements: [this.controlPanelElements.SPACER],
      overflowMenuButtons: [],
      seekBarColors: {
        base: this.seekBarsColor.BASE,
        buffered: this.seekBarsColor.BUFFERED,
        played: this.seekBarsColor.PLAYED
      },
      castReceiverAppId: ''
    };
    this.liveConfig = {
      addBigPlayButton: false,
      enableTooltips: false,
      controlPanelElements: [this.controlPanelElements.LIVE, this.controlPanelElements.SPACER],
      overflowMenuButtons: [],
      castReceiverAppId: ''
    };
  }

  /**
   * Check if new tag need to start/stop.
   */
  newTagConfig(): void {
    if (localStorage.getItem(TUTORIAL_LOCAL_STORAGE.KEY)) {
      this.showPipNewTag = SHOW_NEW_TAG.PIP;
      this.pipNewTagCount = Number(localStorage.getItem(PIP_TAG_LOCAL_STORAGE));
      this.pipNewTagCount = this.pipNewTagCount + 1;
      localStorage.setItem(PIP_TAG_LOCAL_STORAGE, this.pipNewTagCount.toString());
      if (localStorage.getItem(PIP_TAG_LOCAL_STORAGE) && Number(localStorage.getItem(PIP_TAG_LOCAL_STORAGE)) > PIP_TAG_LIMIT) {
        this.showPipNewTag = '';
      }
    }
  }

  /**
   * Toggle the short form of the audio player.
   */
  toggleAudioShortForm(): void {
    this.audioPlayerShortForm = !this.audioPlayerShortForm;
  }

  /**
   * Set the audio player detail and enable it.
   */
  openAudioPlayer(audioPlayerDetail): void {
    this.audioPlayerDetail = audioPlayerDetail;
    this.isAudioPlayer = true;
    this.isAudioMiniPlayer = false;
  }

  /**
   * Clear the webinar details when we enter into the new webinar detail page.
   */
  clearCompleteData(): void {
    this.isMiniPlayerVisible = false;
    this.lastSQSData = '';
    this.SQSCredentials = '';
    this.timeZoneOffset = this.getTimeZoneString();
    this.joinedWebinar = false;
    this.endwebinarFlag = false;
    this.section_key = '';
    this.offset = '';
    this.webinarId = '';
    this.fullscreenchat = false;
    this.fullScreenMode = false;
    this.fullscreenControl = [];
    this.sponsorsColorCode = null;
    this.isPollModalShown = false;
    this.poll = null;
    this.submitPollData = null;
    this.recievePollData = null;
    this.isLoading = false;
    this.tickerList = [];
    this.agoraOption = {
      appID: '',
      channel: '',
      uid: null,
      token: '',
      rtmUid: null,
      rtmToken: ''
    };
    this.is_agora = false;
    this.sponsorList = [];
    this.webinarStatus.next('');
  }

  /**
     * Open Live Poll Popup for Agora Player
     */
  loadLivePollPopup(): void {
    this.pollDataSer.isLivePollModalShown = true;
    if (this.fullScreenMode) {
      this.livePollPopupOverlayHide = true;
    }
    this.openLivePollPopup();
  }

  /**
   * Open Live Poll Popup
   */
  openLivePollPopup(): void {
    this.pollDataSer.isLivePollModalShown = true;
    const d = new Date();
    const start_time = d.getTime().toString();
    /**
     * Set analytics data for analytics event.
     */
    const analyticsData = {
      'event_name': EVENT_NAME.WEBINAR_LIVE_POLL_BUTTON_CLICK,
      'product_type': PRODUCT_TYPE_CONSTANT.WEBINAR,
      'product_type_id': Number(this.webinarId),
      'screen_name': SCREEN_NAME.WEBINAR_DETAIL,
      'start_time': start_time,
      'end_time': start_time
    };
    this.analyticsEventService.logAnalyticsEvent(analyticsData);
  }

  /*
     * Method for receving the poll cross click event
     */
  livePollcrossClick(event, product_type, product_type_id): void {
    this.sessionTimeForEvent();
    this.directive_ser.productDetailEvent(Object.assign({},
      this.fcs.sessiontime),
      this.mls.googleEventText.poll_popup.key3,
      product_type,
      product_type_id,
      this.page_from,
      this.Dss.user_profile_data.profile.track_id);
    this.pollDataSer.isLivePollModalShown = false;
  }

  /**
     * Get Historical poll.
     */
  getHistoricalPollList(): void {
    this.getApiService.getHistoricalPollList(PRODUCT_TYPE_CONSTANT.WEBINAR, this.webinarId).subscribe((data: HistoricalPollResponseData) => {
      if (data.status === NUMBERS.ONE) {
        this.pollDataSer.historicalPollData = data.data.list;
        this.getLivePollIndex();
      }
    });
  }

  /**
     * Submit live poll.
     */
  submitLivePoll(event): void {
    const d = new Date();
    const start_time = d.getTime().toString();

    const analyticsData = {
      'event_name': EVENT_NAME.WEBINAR_LIVE_POLL_ATTEMPT_CLICK,
      'product_type': PRODUCT_TYPE_CONSTANT.WEBINAR,
      'product_type_id': Number(this.webinarId),
      'screen_name': SCREEN_NAME.WEBINAR_DETAIL,
      'start_time': start_time,
      'end_time': start_time,
      'poll_id': event.tab.poll_id,
      'option_id': event.userSelectedOption
    };
    this.analyticsEventService.logAnalyticsEvent(analyticsData);

    const pollData = {
      product_type: PRODUCT_TYPE_CONSTANT.WEBINAR,
      product_id: this.webinarId,
      poll_id: event.tab.poll_id,
      option_id: event.userSelectedOption
    };
    this.postSer.submitLivePoll(pollData).subscribe(data => {
      if (data.status === NUMBERS.ONE) {
        this.pollDataSer.livePollUserActivity = !this.pollDataSer.livePollUserActivity;
        this.pollDataSer.historicalPollData.find(obj => {
          if (obj.poll_id === event.tab.poll_id) {
            obj.user_selected_option = event.userSelectedOption;
            obj.options.map(opt => {
              const updatedPollObj = data.data.options.find(updatedOpt => updatedOpt.option_id == opt.option_id);
              if (updatedPollObj) {
                opt.percentage = updatedPollObj.percentage;
              }
            });
          }
        });
      } else {
        this.fcs.showError(data.error['msg'], 'Error');
      }
    });
  }

  /**
   * get live poll index.
   */
  getLivePollIndex(): void {
    const index = this.pollDataSer.historicalPollData.findIndex(obj => obj.state === 'live');
    if (index !== NUMBERS.MINUS_ONE) {
      this.pollDataSer.hasLivePoll = true;
      this.pollDataSer.livePollIndex = index;
    } else {
      this.pollDataSer.hasLivePoll = false;
      // this.pollDataSer.livePollIndex = this.pollDataSer.livePollIndex;
    }
  }

  /**
    * Click on full screen.
    */
  shakaFullScreenStatus(event): void {
    this.shakaPlayerFullScreenStatus = event;
    if (event == true) {
      // this.livePollPopupOverlayHide = true;
    } else {
      this.livePollPopupOverlayHide = false;
    }

  }

  /**
   * Destroy the service subscriptions.
   */
  ngOnDestroy(): void {
    this.serviceActive = false;
  }
}
