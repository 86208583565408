<ul class="overflow-auto">
  <div class="form-input pl-3 pt-3">
    <span class="input-label secondary-paragraph-bold">
      {{searchData.header_text}}
    </span>
  </div>

  <ng-container *ngIf="searchData.isStatic">
    <li class="search-item-container cursor-pointer"
      [class.active]="index === focusItem"
      *ngFor="let data of searchData.keywords; trackBy: trackByIdentity; let index = index">
      <span class="suggestion-container" (click)="searchKeyword(data.keyword)">
        <img src="assets/img/search.svg">
        <span class="mx-2">
          {{data.keyword}}
        </span>
        <img src="assets/img/arrow_right.svg">
      </span>
    </li>
  </ng-container>

  <ng-container *ngIf="!searchData.isStatic">
    <li class="search-item-container cursor-pointer"
      [class.active]="index === focusItem"
      *ngFor="let data of searchData.keywords; trackBy: trackByIdentity; let index = index">
      <span class="history-container" (click)="searchKeyword(data.keyword)">
        <img src="assets/themes/icons/glo-search-his-grey.svg" alt="history" />
        <span class="mx-2 search-item-box">
          <span class="secondary-paragraph-bold">{{data.keyword}}</span>
        </span>
      </span>
      <img src="assets/themes/icons/global-search-arrow.svg" alt="icon" class="akar-iconsarrow-up-left" (click)="pasteKeyword(data.keyword)" />
    </li>
  </ng-container>
</ul>
