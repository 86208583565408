
<!-- <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" 
    [settings]="dropdownSettings" 
    (onSelect)="onItemSelect($event)" 
    (onDeSelect)="OnItemDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)">
</angular2-multiselect> -->

 <div class="multi-dropdown">
  <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll()"
    (onDeSelectAll)="onSelectAll()" (onDeSelectAll)="onDeSelectAll()">
    <c-badge>
      <ng-template let-item="item">
        <img [src]="item.image" class="img-flag" onerror="this.src='assets/themes/icons/banner-placeholder.svg'" />
           <span class="secondary-paragraph">{{item.code}}</span>
      </ng-template>
    </c-badge>
    <c-item>
      <ng-template let-item="item" class="new">
        <img [src]="item.image" class="default-flag" onerror="this.src='assets/themes/icons/banner-placeholder.svg'" />
        <span class="secondary-paragraph mx-1">{{item.code}}</span>
        <span class="secondary-paragraph">{{item.itemName}}</span>
      </ng-template>
    </c-item>
  </angular2-multiselect>
 </div>