/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Used to convert the date according to timestamp.
 */
@Pipe({
  name: 'dateTimezone'
})
export class DateTimezonePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(date: number, TZone: string): any {
    return new Date((typeof date === 'number' ? new Date(date) : date)
    .toLocaleString('en-US', {timeZone: TZone}));
  }

}
