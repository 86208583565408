<div id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" bsModal>
    <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
        <div>
            <p>Get in touch with us to learn more about 400,000+ doctors</p>
            <img src="./assets/themes/icons/public/cancel.svg" />
        </div>
        <div>
            <div>
                <label>What are you looking for?</label>
                <input type="text" />
            </div>
            <div>
                <label>Name</label>
                <input type="text" />
            </div>
            <div>
                <label>Email Address</label>
                <input type="text" />
            </div>
            <div>
                <label>Contact Number</label>
                <input type="text" />
            </div>
            <div>
                <label>How can Docquity help?</label>
                <input type="text" />
            </div>
            <div>
                <label class="pl-1">
                    <button class="tooltip-list chip large filled icon-position-left post-btn button-border">
                        <span>Next</span>
                    </button>
                </label>
            </div>
        </div>
    </div>
</div>