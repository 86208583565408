import { Component, OnDestroy } from '@angular/core';
import { MultiLangService } from '../dataservices/master-service/multi-lang.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';
import { DataStorageService } from '../dataservices/data-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnDestroy {

  private subscriptions: Subscription = new Subscription();
  constructor(public mls: MultiLangService, public fcs: FunCollectionService, public dss: DataStorageService) { }


  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
