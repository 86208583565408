/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Service imports.
 */
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { RecievePollData, PollOptions } from './pollInterface';

/**
 * This component is used to display the Poll Modal
 * which contain Poll Question and Options
 */
@Component({
  selector: 'app-poll-modal',
  templateUrl: './poll-modal.component.html',
  styleUrls: ['./poll-modal.component.scss']
})
export class PollModalComponent {

  /**
   * Used to Recieve the question and option.
   */
  @Input() recievePollData: RecievePollData;

  /**
   * Used to store the loading flag;
   */
  @Input() isLoading: boolean;

  /**
   * Used to send the submit data event.
   */
  @Output() submitPollClick = new EventEmitter<PollOptions>();

  /**
   * Used to send the cross button click event.
   */
  @Output() pollcrossClick = new EventEmitter<boolean>();


  /**
   * Import Language service for localization
   */
  constructor(public mls: NewLanguageService) {}

  /**
   * Used to send the cross click event.
   */
  hideModal(): void {
    this.pollcrossClick.emit(true);
  }

  /**
   * Used to send the submit click event.
   */
  submitPoll(index): void {
    this.isLoading = true;
    this.submitPollClick.emit(this.recievePollData.options[index]);
  }

}
