/**
 * Angular imports.
 */
import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Samvaad Component
 */
@Component({
  selector: 'app-samvaad',
  templateUrl: './samvaad.component.html',
  styleUrls: ['./samvaad.component.css']
})
export class SamvaadComponent {
  public isDetailPage = false;
  /**
   * Necessary instances
   */
  constructor(private router: Router) {
    this.isDetailPage = (router.url === '/samvaad/list' ? true : false);
  }

}
