/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/**
 * Component imports.
 */
import { ChatComponent } from './chat.component';
import { ChatListPanelComponent } from './components/chat-list-panel/chat-list-panel.component';
import { ChatDetailPanelComponent } from './components/chat-detail-panel/chat-detail-panel.component';
import { ChatGroupCreationComponent } from './components/chat-group-creation/chat-group-creation.component';

/**
 * Pipe imports.
 */
import { OnlyGroupListPipe } from '../pipes/only-group-list.pipe';
import { OnlyUserListPipe } from '../pipes/only-users-list.pipe';
import { NiceDateFormatPipe } from '../pipes/nice-date.pipe';
import { SortByPipe } from '../pipes/sortBy.pipe';
import { GroupByPipe } from '../pipes/groupByDate.pipe';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { RoleByPipe } from '../pipes/roleSorting.pipe';
import { SortedWithSecondaryByPipe } from '../pipes/sortSecondaryBy.pipe';
import { StringNullCheckPipe } from '../pipes/stringValidation.pipe';

/**
 * Module imports.
 */
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploadModule } from './file-upload/file-upload.module';
import { EmojiModule } from './emojis/emojis.module';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShareModule } from './share/share.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    ChatComponent,
    ChatListPanelComponent,
    ChatDetailPanelComponent,
    ChatGroupCreationComponent,
    OnlyGroupListPipe,
    OnlyUserListPipe,
    NiceDateFormatPipe,
    SortByPipe,
    GroupByPipe,
    SearchFilterPipe,
    RoleByPipe,
    SortedWithSecondaryByPipe,
    StringNullCheckPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModalModule,
    ModalModule,
    NgbModule,
    InfiniteScrollModule,
    FileUploadModule,
    EmojiModule,
    ShareModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      maxOpened: 1
    }),
    TooltipModule.forRoot()
  ],
  exports: [
    ChatComponent
  ]
})
export class ChatModule { }
