/**
 * Angular imports.
 */
import { Component, ElementRef, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';

/**
 * Service imports.
 */
import { EventMsgService } from '../../../dataservices/master-service/event-msg.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { ImageGroupService } from '../../../dataservices/master-service/image-group.service';
import { AuthService } from '../../../auth/auth.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { PostApiService } from '../../../dataservices/post-api.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { GetService } from '../../../dataservices/get.service';
import { UserStoryService } from 'src/app/dataservices/userStory/user-story.service';
/**
 * Rxjs imports.
 */
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
/**
 * Constant import.
 */
import { COMMENT_ACTION, NUMBERS, TUTORIAL_FOR, USER_TAG_REGX, READ_KEY } from '../../../constants/app.constants';

/**
 * Reply Comment Card Component
 */
@Component({
  selector: 'app-reply-comment-card',
  templateUrl: './reply-comment-card.component.html',
  styleUrls: ['./reply-comment-card.component.scss']
})
export class ReplyCommentCardComponent implements OnInit, OnDestroy {
  public reply_comment_card_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  public showColor = false;
  @Input() rcL;
  @Input() commentObj;
  @Input() index;
  @Input() callingfrom;

  /**
   * is story avalailbe
   */
  public isStoryAvailable = 0;

  public uuid = '';

  @Output() showReplyBox = new EventEmitter();
  @Output() resetReplyComment = new EventEmitter();
  /**
   * Delete reply comment.
   */
  @Output() deleteReplyComment = new EventEmitter();
  public isModalShown = false;
  public loginUserCustomeId = '';
  public user_profile_pic_path = '';
  public edit_comment = '';
  public reply_comment_like_loader = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public likeObj: any;
  public showLikeListModal = false;
  public isConfirmModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_permission: any;
  public screen_name = '';
  public commentTag = '';
  public confirmInfo = '';

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
  * Search Word For Tag
  * Actual Comment
  * Is Tag Connections Enabled
  * Is Suggestion List For Tag Enabled
  * Tutorial for Tag User
  * Tag User Suggestion Connect List
  * Tag User Suggestion Connection List offset
  * Tag User List for Iteration
  * Tag User Suggestion List Flag for API Call Finished
  * Comment Section Text as Html Element
  */
  public searchWordForTag = '';
  public actualComment = '';
  public isTagConnectionsEnabled = false;
  public isSuggestionListForTagEnabled = false;
  public tutorialFor = TUTORIAL_FOR;
  public tagSuggestionList = [];
  public tagSuggestionListOffSet = NUMBERS.ONE;
  public tagUserList = [];
  public tagSuggestionListfinished = false;
  public commentSectionText: HTMLElement;
  public editableTag = [];
  public tagOffSet = NUMBERS.ONE;

  /**
   * Taguser search
   */
  public tagUserSearchWord = '';
  public selectAll = false;
  subject = new Subject();


  /**
   * Search Term to make a Switch Map call with Latest Search Term
   */
  searchTerm = new Subject<string>();
  @ViewChild('editableCommentContent', { read: ElementRef }) editableCommentContent: ElementRef;
  /**
   * Necessary instances.
   */
  constructor(
    private dss: DataStorageService,
    public fcs: FunCollectionService,
    private jwtSer: JwtDecodeService,
    private postSer: PostApiService,
    private authService: AuthService,
    private masterService: MasterserviceService,
    public igs: ImageGroupService,
    public ems: EventMsgService,
    public multiLangService: MultiLangService,
    public mls: NewLanguageService,
    public storyServ: UserStoryService,
    private getSer: GetService) {
    this.loginUserCustomeId = this.authService.getbyName('profile_obj');
    this.user_permission = this.dss.user_profile_data.profile.permission;
    this.user_profile_pic_path = this.dss.user_profile_data.profile.profile_pic_path;
  }
  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.reply_comment_card_image.prflcid = this.rcL.user_profile.custom_id;
    this.reply_comment_card_image.prflrname = this.rcL.user_profile.registered_name;
    this.reply_comment_card_image.prflfname = this.rcL.user_profile.first_name;
    this.reply_comment_card_image.prfllname = this.rcL.user_profile.last_name;

    this.reply_comment_card_image.prflpic = this.rcL.user_profile.profile_pic;
    this.reply_comment_card_image.badge.is_verified = (this.rcL.user_profile.permission == 'all') ? 1 : 0;
    this.reply_comment_card_image.anonymous = this.rcL.is_anonymous;
    this.reply_comment_card_image.partial_permission_state = this.rcL.user_profile.partial_permission_state;
    this.isStoryAvailable = this.rcL?.user_profile?.is_story_available;
    this.uuid = this.rcL?.user_profile?.uuid;

    this.subject
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.resetTagSuggestion();
        if (this.tagUserSearchWord.includes('@')) {
          // tslint:disable-next-line: deprecation
          this.openTagUserSuggestionList();
        }
      }
      );
  }

  /**
   * view reply comment profile
   */
  viewrplycmmntprfl(): void {
    if (this.isStoriesAvailable() &&  this.uuid != null && this.uuid != undefined) {
      this.openStory(this.uuid);
    }
    else if (this.rcL.is_anonymous == '0' && this.rcL.user_profile.custom_id != undefined && this.rcL.user_profile.custom_id != '') {
      this.fcs.navigateToUserProfie(this.rcL.user_profile.custom_id);
    }
  }

  openStory(uuid): void {
    this.dss.viewStoryThroughProfileUuid = uuid;
    this.dss.showStoryThroughProfile.next(true);
   }

   /**
   * 
   * @param story function to check if all stories are viewed
   * @returns 
   */
   isStoriesAvailable(): boolean {
    const apiRes = this.isStoryAvailable === 1 ? true : false;
    let realTime = false;
    if (this.uuid) {
      realTime = !this.storyServ.isAllStoryViewed(this.uuid);
    }
    return apiRes && realTime;
  }

  /**
   * update Comment
   */
  updateComment(): void {
    this.isModalShown = true;
    this.isSuggestionListForTagEnabled = false;
    this.editableTag = [];

    // TODO: Need to remove and add alternative solution for settimeout.
    setTimeout(() => {
      this.editableCommentContent.nativeElement.setAttribute('data-placeholder', this.mls?.languageText?.comment_card?.key24);
      /* Code Start to Extract Tag User Detail From API */
      const path = this.rcL?.comment;
      const paramsPattern = USER_TAG_REGX.removeBracesRegex;
      const extractParams = path.match(paramsPattern);
      if (extractParams) {
        for (let i = 0; i < extractParams.length; i++) {
          this.editableTag.push(extractParams[i]);
          const words = extractParams[i].split(':');
          const url = '#/webprofile/view/info/' + words[0];
          const string = '<a class=\'user-tag-comment-detail color-black\' href=\'' + url + '\'><b>@' + words[1] + '</b></a>';
          this.rcL.comment = this.rcL?.comment.replace('{' + extractParams[i] + '}', string);
        }
      }
      this.edit_comment = this.rcL['comment'];
      this.commentSectionText = document.getElementById('editComment');
      setTimeout(() => {
        this.addAttr();
      }, 1000);
    }, NUMBERS.ZERO);
  }


  /**
   * This is used to add the contenteditable attribute.
   */
  addAttr(): void {
    const matches = document.querySelectorAll('a.user-tag-comment-detail');
    matches.forEach((el) => {
      el.setAttribute('contenteditable', 'false');
    });
  }


  /**
   * onUpdate Comment
   */
  onUpdateComment(): void {
    let comment = this.commentSectionText.textContent;
    for (let i = 0; i < this.tagUserList.length; i++) {
      let tagUserObj = '';
      if (comment.includes(this.tagUserList[i]['full_name'])) {
        tagUserObj = `{${this.tagUserList[i]['custom_id']}:${this.tagUserList[i]['full_name']}}`;
        comment = comment.replace(`@${this.tagUserList[i]['full_name']}`, tagUserObj);
      }
    }
    for (let i = 0; i < this.editableTag.length; i++) {
      let tagUserObj = '';
      const words = this.editableTag[i].split(':');
      if (comment.includes(words[1])) {
        tagUserObj = `{${words[0]}:${words[1]}}`;
        comment = comment.replace(`@${words[1]}`, tagUserObj);
      }
    }
    this.subscriptions.add(this.postSer.productComment(2, this.rcL['comment_id'], comment, [], 'edit').subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.showColor = false;
          this.fcs.showSuccess(data['msg'], 'Success');
          this.edit_comment = comment;
          this.rcL['comment'] = this.edit_comment;
          this.isModalShown = false;
        } else if (data['status'] == 0) {
          this.fcs.errorHandeler(data);
          return;
        }
      });
    }));
  }

  /**
   * hideModal
   */
  hideModal(): void {
    this.isModalShown = false;
  }

  /**
   * onHidden
   */
  onHidden(): void {
    this.isModalShown = false;
  }

  /**
   * product Like
   */
  productLike(): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    if (this.reply_comment_like_loader == true) { return; }
    if (this.commentObj.kind == 'cases') {
      if (this.rcL.comment_like_status == 1) { return; }

      const action = (this.rcL.comment_like_status == 1) ? 'unlike' : 'like';
      this.reply_comment_like_loader = true;
      this.subscriptions.add(this.postSer.productLike(2, this.rcL.comment_id, action).subscribe(resData => {
        this.reply_comment_like_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.fcs.showSuccess(data['msg'], 'Success');
            this.rcL.comment_like_status = (action == 'unlike') ? 0 : 1;
            this.rcL.total_comment_like = (action == 'unlike') ? this.rcL.total_comment_like - 1 : this.rcL.total_comment_like + 1;
          } else if (data['status'] == 0) {
            this.fcs.errorHandeler(data);
            return;
          }
        }, () => {
          this.reply_comment_like_loader = false;
        });
      }));
    } else {
      if (this.rcL.comment_like_status == 1) { return; }

      this.reply_comment_like_loader = true;
      this.subscriptions.add(this.postSer.productLike(2, this.rcL.comment_id, 'like').subscribe(resData => {
        this.reply_comment_like_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.fcs.showSuccess(data['msg'], 'Success');
            this.rcL.comment_like_status = 1;
            this.rcL.total_comment_like = this.rcL.total_comment_like + 1;
          } else if (data['status'] == 0) {
            this.fcs.errorHandeler(data);
            return;
          }
        }, () => {
          this.reply_comment_like_loader = false;
        });
      }));

    }

  }

  /**
   * get Product Like List
   */
  getProductLikeList(): void {
    this.likeObj = { 'product_id': this.rcL.comment_id, 'product_type': 2, 'callLikeProperty': 'list' };
    this.showLikeListModal = true;
  }

  /**
   * reset Like
   */
  resetLike(): void {
    this.showLikeListModal = false;
  }

  /**
   * on Delete Comment
   */
  onDeleteComment(): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.isConfirmModalShown = true;
    this.confirmInfo = this.mls.languageText.comment_card.key17;
    return false;
  }

  /**
   * delete Comment
   */
  deleteComment(): void {
    this.subscriptions.add(this.postSer.productComment(2, this.rcL['comment_id'], this.edit_comment, [], 'delete').subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.fcs.showSuccess(data['msg'], 'Success');
          this.resetReplyComment.emit(this.index);
          this.deleteReplyComment.emit(COMMENT_ACTION.REMOVE);
        } else if (data['status'] == 0) {
          this.fcs.errorHandeler(data);
          return;
        }
      });
    }));
  }

  /**
   * reset Confirm Modal
   */
  resetConfirmModal(data): void {
    this.isConfirmModalShown = false;
    this.confirmInfo = '';
    if (data == true) {
      this.edit_comment = this.rcL['comment'];
      this.deleteComment();
    }
  }

  /**
   * isActivate Button
   */
  isActivateButton(e): void {
    if (this.edit_comment.trim() !== e.target.innerText.trim()) {
      this.showColor = true;
    } else {

      this.showColor = false;
    }
  }
  /**
   * Check for Default Show Tutorial for Tagged User
   */
  checkToShowTutorial(): void {
    if (this.reply_comment_card_image.badge.is_verified || (this.reply_comment_card_image.partial_permission_state.toString() === NUMBERS.TWO.toString())) {
      if (localStorage.getItem('tagConnectionInformationCardFlag') !== 'true') {
        this.isTagConnectionsEnabled = true;
      } else {
        this.isTagConnectionsEnabled = false;
      }
    } else {
      this.isTagConnectionsEnabled = false;
    }
  }

  /**
   * Reset Tag Suggestion List
   */
  resetTagSuggestion(): void {
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.isSuggestionListForTagEnabled = false;
    this.tagSuggestionListfinished = true;
  }
  /**
   * Save Selected Tagged User
   */
  saveTaggedUserDataEmit(sugg): void {
    this.tagUserList.push(sugg);
    this.isSuggestionListForTagEnabled = false;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML + `##${sugg.full_name}##` ;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(this.searchWordForTag+'##'+sugg.full_name+'##', '##'+sugg.full_name+'##');
    const tagUserClassName = 'tag-user';
    setTimeout(() => {
      for (let i = 0; i < this.tagUserList.length; i++) {
        this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(
          `@##${this.tagUserList[i]['full_name']}##` || this.tagUserList[i]['full_name'],
          `<span contenteditable=false class="${tagUserClassName}">@${this.tagUserList[i]['full_name']}</span>&nbsp;`
        );
      }
      // put the cursor to the end of field again...
      this.selectEnd();
    }, NUMBERS.TEN);
    this.searchWordForTag = '';
    this.tagUserSearchWord = '';

  }

  /**
   * put the cursor to the end of field again...
   */
  selectEnd(): void {
    let range, selection;
    // eslint-disable-next-line prefer-const
    range = document.createRange();
    if (document.getElementById('editComment')) {
      range.selectNodeContents(document.getElementById('editComment'));
    }
    range.collapse(false);
    // eslint-disable-next-line prefer-const
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
  /**
   * Get User List in case of Scroll down
   */
  getOffSetUserList(event): void {
    if (event) {
      this.tagSuggestionListOffSet = event;
      this.openTagUserSuggestionList();
    }
  }
  /**
   * Close Tag Tutorial
   */
  closeTagTutorial(): void {
    localStorage.setItem('tagConnectionInformationCardFlag', 'true');
    this.isTagConnectionsEnabled = false;
  }

  /**
   * Reset Tag Box
   */
  resetTagBox(): void {
    if (document.getElementById('editComment')) {
      document.getElementById('editComment').innerHTML = '';
    }
    this.searchWordForTag = '';
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.isSuggestionListForTagEnabled = false;
    this.tagUserSearchWord = '';
  }


  /**
   * Used to receive user input.
   */
  userInput(event): void {
    switch (event.inputType) {
      case 'insertText': {
          if (this.selectAll) {
            this.tagUserSearchWord = event.data || '';
            this.selectAll = false;
          } else {
            this.tagUserSearchWord = this.tagUserSearchWord + event.data;
          }
          break;
        }
      case 'deleteContentBackward': {
          if (this.selectAll) {
            this.tagUserSearchWord = event.data || '';
            this.selectAll = false;
          } else {
            this.tagUserSearchWord = this.tagUserSearchWord.substring(0, this.tagUserSearchWord.length - 1);
          }
          break;
        }
    }

    this.subject.next();
  }

  onKeyUp(event): void {

    if (event.ctrlKey && event.key === READ_KEY.A) {
      this.selectAll = true;
    }

    if (event.target.value?.trim() != '') {
      this.showColor = true;
    } else {
      this.showColor = false;
    }
  }

  onKeyDown(event): void {
    if (this.fcs.isSelectingAll(event)) {
      this.selectAll = true;
    }
  }


  openTagUserSuggestionList(): void {
    let finalText = '';
    if (this.actualComment.trim() !== this.commentSectionText.textContent.trim()) {
      this.tagSuggestionListOffSet = NUMBERS.ONE;
      this.tagSuggestionList = [];
    }
    this.actualComment = this.commentSectionText.textContent;
    if (this.tagUserSearchWord.includes(USER_TAG_REGX.tag_char)) {
      finalText = this.searchWordForTag = this.tagUserSearchWord.split('@')[1];

      this.tagSuggestionListfinished = false;
      finalText = finalText.replace(USER_TAG_REGX.search_char_regex, '');
      this.subscriptions.add(this.getSer.searchUserTagEntries(this.commentObj.product_type, this.commentObj.product_id, finalText, this.tagSuggestionListOffSet).subscribe(resData => {
        if (resData && resData.data && resData.status === NUMBERS.ONE) {
          if (this.tagSuggestionListOffSet > NUMBERS.ONE) {
            this.tagSuggestionList = this.tagSuggestionList.concat(resData.data.list);
          } else {
            this.tagSuggestionList = resData.data.list;
          }
          this.tagOffSet = resData.data.offset;
          this.isSuggestionListForTagEnabled = true;
          this.tagSuggestionListfinished = true;
        } else {
          this.resetTagSuggestion();
        }
      }));
    } else {
      this.isSuggestionListForTagEnabled = false;
    }


  }


  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetTagBox();
  }
}
