<div class="p-2 walkthrough-card" [class]="positionCss">
    <div>
      <small class="primary-small text-white">
        {{stepText}}
      </small>
    </div>
    <div>
      <p class="paragraph-bold text-white">
        {{title}}
      </p>
    </div>
    <div class="d-flex justify-content-end">
      <span type="button" *ngIf="!isLastStep" (click)="skip()" class="p-0 mr-3 secondary-paragraph">
       <span class="color-grey-300 ">{{mls?.languageText?.tutorial?.key4}}</span> 
      </span>
      <span type="button" *ngIf="!isLastStep" (click)="next()" class="p-0 paragraph">
       <span class="color-blue">{{mls?.languageText?.tutorial?.key5}}</span> 
        <img src="assets/themes/icons/blue-arrow.svg" alt="arrow" width="11px">
      </span>
      <span type="button" *ngIf="isLastStep" (click)="finish()" class="p-0 paragraph">
       <span class="color-blue"> {{mls?.languageText?.tutorial?.key6}}!</span>
        <img src="assets/themes/icons/blue-arrow.svg" alt="arrow" width="11px">
      </span>
    </div>
  </div>