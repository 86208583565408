/**
 * ViewChild is for binding tags of html to be accessible for this file
 * Input is for receiving inputs from smart component
 */
import { Component, AfterViewInit, ElementRef, HostListener, ViewChild, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Import browser platforms.
 */
import { Platform } from '@angular/cdk/platform';

/**
 * Import constants.
 */
import { CONTROL_PANEL_ELEMENTS, LANG_ABBREVIATIONS, MENIFEST_RETRY_PARAMETERS, MILLISECOND_TIME, NUMBERS, OVERFLOW_MENU_BUTTONS, SHAKA, TIME, WEBINAR_STATUS, WEBINAR_TYPE, PLAYER_STAGE, SHOW_NEW_TAG, SHAKA_PLAYER_CLASS_NAME, BOOTSTRAP_CLASS_NAME } from '../../constants/app.constants';

/* Interface for UI configuration of shaka player */
import { Config } from '../shakaPlayer.interface';

/**
 * Custom Decorator import.
 */
import { OnChange } from '../../custom-decorators/ngOnchanges.Decorator';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';

import { DataStorageService } from 'src/app/dataservices/data-storage.service';

/* variable for shaka player */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let shaka: any;

/**
 * Can be used as a dumb component by using app-shaka-basic selector
 */
@Component({
  selector: 'app-shaka-hls',
  templateUrl: './hls.component.html',
  styleUrls: ['./hls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HlsComponent implements AfterViewInit, OnDestroy, OnChanges {
  /** @ViewChild to query the view using the given selectors
   * videoElementRef contains a reference to the video element
   */
  @ViewChild('videoPlayer') public videoElementRef: ElementRef;

  /**
  * videoContainerRef includes a reference to the div that wraps the video element.
  */
  @ViewChild('videoContainer') public videoContainerRef: ElementRef;

  /**
  * emit SQS event outside the component
  */
  @Output() receiveSQSInterval = new EventEmitter();

  /**
   * emit end video event
   */
  @Output() videoEnd = new EventEmitter();

  /**
   * Emit Exit mini player event outside.
   */
  @Output() emitMiniPlayer = new EventEmitter();

  /**
   * URL for video, receiving as input
   */
  @Input() videoSrc: string;

  /**
   * Shows the new tag on controls.
   */
  @Input() showNewTag: string;

  /**
   * UI configuration for video, receiving as input
   */
  @OnChange<Config>('onConfigChange')
  @Input() public config: Config;
  /**
   * If cookie need to enable or not.
   */
  @Input() enableCookies = false;
  /**
  * recieve the product type
  */
  @Input() pType: number;

  /**
   * For recorded webinar.
   */
  @Input() recordedWebinar;

  /**
   * emit Past Webinar Data outside the component
   */
  @Output() shakaPlayerEvent: EventEmitter<{ playerStage: string; }> = new EventEmitter();
  /**
   * emit pip play event outside the component
   */
  @Output() pipPlayEvent: EventEmitter<boolean> = new EventEmitter();

  /**
   * To Play/Pause Video in the middle.
   */
  @OnChange<boolean>('onPlayPauseVideoState')
  @Input() playPauseVideoState: boolean;

  /**
   * Set the autoPlay state.
   */
  @Input() autoplay = false;

  /**
   * Set the autoPlay state.
   */
  @OnChange<boolean>('onReloadPlayer')
  @Input() reloadHls: number;

  /**
   * To Load Live Poll Popup
   */
  @Output() loadLivePollPopup: EventEmitter<boolean> = new EventEmitter();

  /**
   * Shaka Live Poll Screen Status
   */
  @Output() shakaFullScreenStatus: EventEmitter<boolean> = new EventEmitter();


  /**
   * Video element and container for our video element
   */
  private videoElement: HTMLVideoElement;
  private videoContainerElement: HTMLDivElement;

  /**
   * Reference variable for our shaka player object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private player: any;

  /**
   * flag for detecting if there is error in URL
   */
  public errorInPlaying = false;

  /**
   * error message to be displayed on browser if URL not found
   */
  public errorMsg: string;

  /**
   * reference the instance of event manager
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventManager: any;

  /**
   * store the object which contains timespent,SQSTimeInterval,seekTime
   * timespent : the amount of time user spends streaming the audio
   * SQSTimeInterval : default value 0
   * seekTime : timeline of the seek bar
   */
  private startedSqs = { timespent: NUMBERS.ONE, SQSTimeInterval: NUMBERS.ZERO, seekTime: NUMBERS.ONE };
  /**
   * store the value of previously emitted startedSqs.
   */
  private previousStartedSqs = NUMBERS.MINUS_ONE;

  /**
   * Interval after which we are sending data
   */
  private SQSTimeInterval = MILLISECOND_TIME.SIXTY_SECOND;

  /**
   * stored value for clearing the SQSTimeInterval
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sqsInterval: any;

  /**
   * stored value for clearing the perSecondInterval
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private perSecondInterval: any;

  /**
   * increasing the value of time spent in every second
   */
  private secondInterval = MILLISECOND_TIME.ONE_SECOND;

  /**
   * Store the instance of ui controls of shaka
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private ui: any;

  /**
   * difference in orignal audio duration and seek value at the end of audio
   */
  private TIME_DIFFERENCE = SHAKA.AUDIO_SEEK_TIME_DIFFERENCE;

  /**
   * maximum range for time spent
   */
  private TIME_SPENT_RANGE = TIME.SIXTY_SECOND;

  /**
   * Container for big play button and small play button.
   */
  private bigPlayButtonContainer: HTMLDivElement;
  private smallPlayButtonContainer: HTMLDivElement;
  private live: HTMLDivElement;
  private livePollButton: HTMLDivElement;

  /**
   * Point of time from where video should start.
   */
  @Input() resumeTime = 0;

  /**
   * Sent SQS while buffering for one time.
   */
  private sqsInBuffer = false;

  /**
   * For live poll.
   * Show Live Poll Button Flag
   * Live Poll Index
   * Webinar Status Flag
   * Has Live Poll Flag
   */
  @Input() showLivePollButton;

  /**
   * Flag for full screen.
   */
  @OnChange<Config>('onHasLivePoll')
  @Input() hasLivePoll: boolean;

  /**
   * Flag for full screen.
   */
  public isFullScreen = false;
  /*
   * Flag for show loader in case of Webinar Reload
   */
  public showReloadLoader = false;
  /**
   * Inject dependencies in our component.
   */
  constructor(private platform: Platform, public mls : NewLanguageService, private dss: DataStorageService) { }

  /**
   * Check the full screen state then according to this change the poll icon state live/past.
   */
  onHasLivePoll(hasLivePoll: boolean): void {
    if (this.isFullScreen && this.showLivePollButton) {
      if (!hasLivePoll) {
        const liveCircle = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.LIVE_CIRCLE);
        const pollImg = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.POLL_IMAGE);
        liveCircle.classList.add(BOOTSTRAP_CLASS_NAME.DISPLAY_NONE);
        pollImg.setAttribute('src', 'assets/images/live-poll/ended-poll-full-screen.svg');
        this.livePollButton.classList.add(SHAKA_PLAYER_CLASS_NAME.NO_WHITE_BG_COLOR);
      } else {
        const liveCircle = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.LIVE_CIRCLE);
        const pollImg = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.POLL_IMAGE);
        liveCircle.classList.remove(BOOTSTRAP_CLASS_NAME.DISPLAY_NONE);
        pollImg.setAttribute('src', 'assets/images/live-poll/progress.svg');
        this.livePollButton.classList.remove(SHAKA_PLAYER_CLASS_NAME.NO_WHITE_BG_COLOR);
      }
    }
  }

  /**
   * Trigger when any changes happens in configuration.
   */
  onConfigChange(value: Config, simpleChange: SimpleChange): void {
    if (!simpleChange.firstChange) {
      this.config = value;
      this.ui.configure(this.config);
      if (this.checkForLive()) {
        this.playPauseVideoState = false;
        this.insetLiveText();
        this.playPauseVideoState = true;
      }
    }
  }

  /**
   * Used to play pause the video in player run time.
   */
  onPlayPauseVideoState(value: boolean, simpleChange): void {
    if (!simpleChange.firstChange) {
      value ? this.ui.video_.play() : this.ui.video_.pause();
    }
  }

  /**
   * Installing built-in polyfills and checking browser incompatibilities and then
   * loading the video on player
   */
  ngAfterViewInit(): void {
    this.init();
    this.runPlayer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resumeTime'].currentValue > 0){
      this.runPlayer();
    }
  }
  /**
   * Install built-in polyfills to patch browser incompatibilities and then
   * calling initPlayer function if everything looks good!
   */
  private init(): void {
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.videoElement = this.videoElementRef.nativeElement;
      this.videoContainerElement = this.videoContainerRef.nativeElement;
      this.initPlayer();
    } else {
      console.error('Browser not supported!');
    }
  }

  /**
   * Setting up all the configuration and controls on the UI and
   * Adding container for custom video controls
   */
  private initPlayer(): void {
    this.createMiniPlayer();
    this.createLiveText();
    if(this.mls.featureOnOffList.featureList.poll.value || !this.mls.userLoginFlow){
    this.createLivePollIconButton();
    }
    this.player = new shaka.Player(this.videoElement);
    this.player.configure({
      manifest: {
        defaultPresentationDelay: 0,
        retryParameters: {
          timeout: MENIFEST_RETRY_PARAMETERS.TIMEOUT,
          stallTimeout: MENIFEST_RETRY_PARAMETERS.STALL_TIMEOUT,
          connectionTimeout: MENIFEST_RETRY_PARAMETERS.CONNECTION_TIMEOUT,
          maxAttempts: MENIFEST_RETRY_PARAMETERS.MAX_ATTEMPTS,
          baseDelay: MENIFEST_RETRY_PARAMETERS.BASE_DELAY,
          backoffFactor: MENIFEST_RETRY_PARAMETERS.BACKOFF_FACTOR,
          fuzzFactor: MENIFEST_RETRY_PARAMETERS.FUZZ_FACTOR
        },
        hls: {
          useFullSegmentsForStartTime: false,
        }
      },
      streaming: {
        useNativeHlsOnSafari: false,
        alwaysStreamText: true,
        ignoreTextStreamFailures: true,
        autoLowLatencyMode: true
      }

    });

    this.ui = new shaka.ui.Controls(
      this.player,
      this.videoContainerElement,
      this.videoElement,
      this.config
    );

    this.eventManager = new shaka.util.EventManager();
    this.shakaEvents();
    this.eventManager.listen(this.videoElement, 'play', () => {
      this.videoContainerElement.appendChild(this.ui.spinnerContainer_);
    });

    /**
     * In case of SAFARI browser we need to append signed URL.
     */
    this.appendSignedURL();
    console.log('Cookie appended', this.enableCookies);
  }

  /**
   * Append signed URL.
   */
  appendSignedURL(): void {
    this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
      switch (type) {
        case shaka.net.NetworkingEngine.RequestType.MANIFEST:
        case shaka.net.NetworkingEngine.RequestType.SEGMENT:
        case shaka.net.NetworkingEngine.RequestType.TIMING:
        case shaka.net.NetworkingEngine.RequestType.APP:
        case shaka.net.NetworkingEngine.RequestType.LICENSE:
          if (request && request.uris && request.uris.length) {
            if (this.platform.SAFARI && this.enableCookies) {
              if (request.uris[NUMBERS.ZERO].endsWith('.m3u8') || request.uris[NUMBERS.ZERO].endsWith('.ts')) {
                const cookies = JSON.parse(sessionStorage?.getItem('getDstreamDetails'));
                if (cookies) {
                  request.uris[NUMBERS.ZERO] = request.uris[NUMBERS.ZERO] + '?Key-Pair-Id=' + cookies['CloudFront-Key-Pair-Id'] + '&Policy=' + cookies['CloudFront-Policy'] + '&Signature=' + cookies['CloudFront-Signature'];
                }
              }
            }
          }
          if (this.enableCookies) {
            request.allowCrossSiteCredentials = true;
          } else {
            request.allowCrossSiteCredentials = false;
          }
          console.log('this.enable', this.enableCookies);
          break;

        default:
          break;
      }
    });
  }

  /**
   * Add the custom button for the controls.
   */
  createLiveText(): void {
    shaka.ui.LiveButton = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('button');
        this.button_.classList.add(SHAKA_PLAYER_CLASS_NAME.LIVE);
        this.button_.classList.add(BOOTSTRAP_CLASS_NAME.DISPLAY_NONE);
        this.button_.textContent = 'LIVE';
        this.parent.appendChild(this.button_);
      }
    };
    shaka.ui.LiveButton.Factory = class {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      create(rootElement, controls): any {
        return new shaka.ui.LiveButton(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(SHAKA_PLAYER_CLASS_NAME.LIVE, new shaka.ui.LiveButton.Factory());
  }

  /**
   * Add the custom button for the controls.
   */
  createMiniPlayer(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const dom = this;
    shaka.ui.MiniPlayerButton = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('button');
        this.img_ = document.createElement('img');
        this.button_.classList.add(SHAKA_PLAYER_CLASS_NAME.SHAKA_TOOLTIP);
        this.button_.classList.add(BOOTSTRAP_CLASS_NAME.POSITION_RELATIVE);
        this.button_.setAttribute('aria-label', 'PIP');
        this.img_.src = 'assets/images/pip.svg';
        this.parent.appendChild(this.button_);
        if (dom.showNewTag === SHOW_NEW_TAG.PIP) {
          this.newTag_ = dom.createNewTag();
          this.button_.appendChild(this.newTag_);
        }
        this.button_.appendChild(this.img_);
        this.eventManager.listen(this.button_, 'click', () => {
          dom.emitMiniPlayer.emit(null);
        });
      }
    };
    shaka.ui.MiniPlayerButton.Factory = class {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      create(rootElement, controls): any {
        return new shaka.ui.MiniPlayerButton(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(CONTROL_PANEL_ELEMENTS.MINI_PLAYER, new shaka.ui.MiniPlayerButton.Factory());
  }

  /**
   * Create the new tag for the controls.
   */
  createNewTag(): HTMLImageElement {
    const newTag_ = document.createElement('img');
    newTag_.src = 'assets/images/profile-explore/new_tag.gif';
    newTag_.alt = 'new';
    newTag_.classList.add('shaka-new-tag');
    return newTag_;
  }

  /**
   * Emit mini player clicked event outside.
   */
  emitMiniPlayerClickEvent(): void {
    this.emitMiniPlayer.emit(null);
  }

  /**
   * loading the video on player
   * event for catching error
   */
  private runPlayer(): void {
    this.player
      .load(this.videoSrc, this.resumeTime)
      .then(() => {
        if (this.config.overflowMenuButtons?.includes(OVERFLOW_MENU_BUTTONS.CAPTIONS)) {
          this.setCaption();
        }
        this.player.addEventListener('textchanged', () => { this.onCaptionChanged(); });
        this?.videoContainerElement?.removeChild(this?.ui?.spinnerContainer_);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        if (e.code === SHAKA.BAD_HTTP_STATUS) {
          this.errorInPlaying = true;
          this.errorMsg = 'URL not found';
        }
        console.error('error in playing', e);
      });
  }

  /**
   * Fired when user changes caption
   */
  onCaptionChanged(): void {
    const textTrack = this.player.getTextTracks().find(track => track.active);
    localStorage.setItem('cap', textTrack.language);
  }

  /**
   * Set the caption according to the system default value.
   */
  setCaption(): void {
    const lang = localStorage.getItem('lang');
    const selectedCap = localStorage.getItem('cap');
    const tracks = this.player.getTextTracks();
    if (tracks.length > 0) {
      const availableLangs = tracks.reduce((key, value) => {
        key[value.language] = value;
        return key;
      }, {});
      this.player.selectTextTrack(availableLangs[lang] || availableLangs[LANG_ABBREVIATIONS.ENGLISH]);
      this.player.setTextTrackVisibility(true);
      if (selectedCap) {
        this.player.selectTextTrack(availableLangs[selectedCap]);
        this.player.setTextTrackVisibility(true);
      }
    }
  }

  /**
   * includes all the events used for shaka player
   */
  private shakaEvents(): void {
    this.eventManager.listen(this.videoElement, 'play', () => {
      this.play();
    });
    this.eventManager.listen(this.videoElement, 'buffering', () => {
      console.log('buffering::::::::::::');
    });
    this.eventManager.listen(this.videoElement, 'pause', () => {
      this.pause();
    });

    this.eventManager.listen(this.videoElement, 'ended', () => {
      this.ended();
    });
    this.eventManager.listen(this.videoElement, 'timeupdate', () => {
      this.timeUpdated();
    });
  }
  /**
   * function is called after pause is hit
   */
  private pause(): void {
    this.shakaPlayerEvent.emit({
      playerStage: PLAYER_STAGE.PAUSE
    });
    if (this.startedSqs.seekTime !== NUMBERS.ZERO && this.startedSqs.seekTime + this.TIME_DIFFERENCE < this.roundOff(this.videoElement.duration)) {
      this.clearingIntervals();
      this.emittingSQSInterval();
    }
  }

  /**
   * function for clearing the interval time
   */
  private clearingIntervals(): void {
    clearInterval(this.sqsInterval);
    clearInterval(this.perSecondInterval);
    this.startedSqs.seekTime = this.roundOff(this.videoElement.currentTime);
  }

  /**
   * For emitting SQS data
   */
  private emittingSQSInterval(): void {
    if (this.startedSqs.seekTime !== this.previousStartedSqs || this.startedSqs.timespent === NUMBERS.ONE) {
      this.startedSqs = {
        timespent: this.startedSqs.timespent > NUMBERS.ONE ? this.startedSqs.timespent : NUMBERS.ONE,
        SQSTimeInterval: this.startedSqs.SQSTimeInterval,
        seekTime: this.startedSqs.seekTime > NUMBERS.ONE ? this.startedSqs.seekTime : NUMBERS.ONE
      };
      this.startedSqs.timespent = this.startedSqs.timespent >= this.TIME_SPENT_RANGE ? this.TIME_SPENT_RANGE : this.startedSqs.timespent;
      this.startedSqs.SQSTimeInterval = this.startedSqs.timespent === NUMBERS.ONE ? NUMBERS.ZERO : ((this.startedSqs.timespent === this.TIME_SPENT_RANGE) ? MILLISECOND_TIME.SIXTY_SECOND : this.startedSqs.timespent * 1000);
      if (this.recordedWebinar.status === WEBINAR_STATUS.LIVE && this.recordedWebinar.type === WEBINAR_TYPE.WOWZA && this.player.isBuffering()) {
        if (!this.sqsInBuffer) {
          this.sqsInBuffer = true;
          this.receiveSQSInterval.emit(this.startedSqs);
        }
      } else {
        this.receiveSQSInterval.emit(this.startedSqs);
      }
      this.previousStartedSqs = this.startedSqs.seekTime;
      this.startedSqs.timespent = NUMBERS.ONE;
    }
  }

  /**
   * Function is called when video state is changed from pause to play and hide play button in case of live webinar.
   */
  private play(): void {
    if (this.pType === NUMBERS.TWENTY_SEVEN) {
      this.sqsBuilder();
      if (this.checkForLive()) {
        this.insetLiveText();
      }
    }
    /**
     * Check if Play Event is Performed, then Emit Play Event
     */
    this.shakaPlayerEvent.emit({
      playerStage: PLAYER_STAGE.PLAY
    });
    this.pipPlayEvent.emit();
  }

  /**
   * For sending data after specific time intervals
   */
  private sqsBuilder(): void {
    this.clearingIntervals();

    this.emittingSQSInterval();
    this.perSecondInterval = setInterval(() => {
      this.startedSqs.seekTime = this.roundOff(this.videoElement.currentTime);
      this.startedSqs.timespent++;
    }, this.secondInterval);
    this.sqsInterval = setInterval(() => {
      this.emittingSQSInterval();
    }, this.SQSTimeInterval);
  }

  /**
   * function for rounding off the number
   */
  private roundOff(time): number {
    return Math.round(time);
  }

  /**
   * function is called when audio ends
   */
  private ended(): void {
    this.videoEnd.emit();
    this.clearingIntervals();
    this.emittingSQSInterval();
    this.startedSqs = {
      timespent: NUMBERS.ONE,
      SQSTimeInterval: NUMBERS.ZERO,
      seekTime: NUMBERS.ONE
    };
  }
  /**
   * function when seek time is updated
   */
  private timeUpdated(): void {
    this.shakaPlayerEvent.emit({
      playerStage: PLAYER_STAGE.SEEKING
    });
  }
  /**
   * Create Live Poll Icon Button.
   */
  createLivePollIconButton(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const dom = this;
    shaka.ui.LivePollButton = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('button');
        this.img_ = document.createElement('img');
        this.button_.classList.add(SHAKA_PLAYER_CLASS_NAME.LIVE_POLL_BUTTON);
        this.button_.classList.add(BOOTSTRAP_CLASS_NAME.DISPLAY_NONE);
        this.button_.classList.add(BOOTSTRAP_CLASS_NAME.POSITION_RELATIVE);
        this.button_.setAttribute('aria-label', 'Live Poll Button');
        this.img_.classList.add(SHAKA_PLAYER_CLASS_NAME.POLL_IMAGE);
        this.img_.src = 'assets/images/live-poll/progress.svg';
        this.parent.appendChild(this.button_);
        this.dot_img_ = document.createElement('img');
        this.dot_img_.src = 'assets/images/live-poll/pulse.gif';
        this.dot_img_.classList.add(SHAKA_PLAYER_CLASS_NAME.LIVE_CIRCLE);
        this.button_.appendChild(this.dot_img_);
        this.button_.appendChild(this.img_);
        this.eventManager.listen(this.button_, 'click', () => {
          dom.loadLivePollPopup.emit(true);
        });
      }
    };
    shaka.ui.LivePollButton.Factory = class {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      create(rootElement, controls): any {
        return new shaka.ui.LivePollButton(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(CONTROL_PANEL_ELEMENTS.LIVE_POLL, new shaka.ui.LivePollButton.Factory());
  }

  /**
   * Detect and sent the fullscreen status of the shaka player.
   */
  @HostListener('document:fullscreenchange', [])
  fullScreen(): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
      this.shakaFullScreenStatus.emit(true);
      this.insertLivePoll();
    } else {
      this.isFullScreen = false;
      this.shakaFullScreenStatus.emit(false);
      this.removeLivePoll();
    }
  }

  /**
   * Get the Html div element of the ui controls container.
   */
  getUiContainerOfButtons(className: string): HTMLDivElement {
    return shaka.util.Dom.getElementByClassName(className, this.ui.controlsContainer_);
  }

  /**
   * Check to show or hide the live controls.
   */
  checkForLive(): boolean {
    return this.config.controlPanelElements && this.config.controlPanelElements.includes(CONTROL_PANEL_ELEMENTS.LIVE);
  }

  /**
   * Insert the live text in case of live video.
   */
  insetLiveText(): void {
    this.bigPlayButtonContainer = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.PLAY_BUTTON);
    this.smallPlayButtonContainer = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.SMALL_PLAY_BUTTON);
    this.live = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.LIVE);

    this.live.classList.add(BOOTSTRAP_CLASS_NAME.DISPLAY_BLOCK);
    if (this.bigPlayButtonContainer) {
      this.bigPlayButtonContainer.classList.add(SHAKA_PLAYER_CLASS_NAME.SHAKA_HIDDEN);
    }
    if (this.smallPlayButtonContainer) {
      this.smallPlayButtonContainer.classList.add(SHAKA_PLAYER_CLASS_NAME.SHAKA_HIDDEN);
    }
  }

  /**
   * Insert live poll control in the ui.
   */
  insertLivePoll(): void {
    if (this.showLivePollButton) {
      this.livePollButton = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.LIVE_POLL_BUTTON);
      this.livePollButton.classList.add(BOOTSTRAP_CLASS_NAME.DISPLAY_BLOCK);
      this.onHasLivePoll(this.hasLivePoll);
    }
  }

  /**
   * Remove the live poll control from ui.
   */
  removeLivePoll(): void {
    if (this.showLivePollButton) {
      this.livePollButton = this.getUiContainerOfButtons(SHAKA_PLAYER_CLASS_NAME.LIVE_POLL_BUTTON);
      this.livePollButton.classList.remove(BOOTSTRAP_CLASS_NAME.DISPLAY_BLOCK);
    }
  }

  /**
   * Reload the player on demand.
   */
  async onReloadPlayer(value: number): Promise<void> {
    this.showReloadLoader = true;
    setTimeout(async () => {
      if (value !== 0) {
        this.reloadHls = 0;
        await this.player.unload();
        await this.runPlayer();
        this.appendSignedURL();
        this.playPauseVideoState = false;
        console.log('ReloadPlayer successfully');
        this.playPauseVideoState = true;
      }
    }, 700);
    setTimeout(() => {
      this.reloadHls = 0;
      this.showReloadLoader = false;
    }, 2000);
  }

  /**
   * destroying SQSInterval emission
   */
  ngOnDestroy(): void {
    this.player.unload().then(console.log('Unload successfully'));
    if (this.startedSqs.seekTime !== NUMBERS.ZERO) {
      this.clearingIntervals();
      this.emittingSQSInterval();
    }
  }
}
