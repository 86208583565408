  <div class="custom-theme-modal shadow-none live-comment-card modal-dialog modal-dialog-scrollable m-0 mw-100 scroll-able-modal p-0" role="document"
    [ngClass]="{'fixed-bottom bg-white': deviceType=='mb-5 pb-3'}">
    <div *ngIf="webinarObjData?.is_live_comment==1"
      class="modal-content border-0"
      [ngClass]="
        {'mobile-live-chat' : deviceType === deviceTypeConst.MOBILE,
        'mobile-live-chat-open' : fullscreenchat || mobileLiveChat}">
      <div class="modal-header p-3 border-0">
        <p class="heading-4"><span class="color-grey-700">{{mls?.languageText?.comment_card?.key18}}<span *ngIf="mls?.is_lskey_display == 1">(comment_card.key18)</span></span></p>
        <span *ngIf="fullscreenchat || deviceType === deviceTypeConst.MOBILE"
        (click)="deviceType === deviceTypeConst.MOBILE ? closelivechat(deviceTypeConst.MOBILE) : closelivechat();" class="close-icon position-absolute cursor-pointer">
          <img src="assets/themes/icons/cancel.svg" alt="cross">
        </span> 
      </div>
      <div infiniteScroll class="modal-body px-3 py-2 border-top position-relative chat-div" nfinite-scroll
        [infiniteScrollDistance]="5" [infiniteScrollThrottle]="2000" [scrollWindow]="false" (scrolled)="onScrollDown();"
        #scrollingBlock>
  
        <virtual-scroller #scroll [childWidth]="100" [childHeight]="100" [items]="websocketService.comment_list"
          [enableUnequalChildrenSizes]="true" [parentScroll]="scrollingBlock" [bufferAmount]="5">
          <div class="comment-live mb-3" *ngFor="let cmtLst of scroll.viewPortItems;">
            <div *ngIf="this.websocketService.webinar_live_comment_list[cmtLst.unique_code]?.is_reported==false || dss.user_profile_data.profile.custom_id===cmtLst?.user_profile.custom_id">
                <app-user-card [userdata]="cmtLst?.user_profile"   *ngIf="cmtLst?.comment!=''"></app-user-card>
            <div class="modal-comment-bg position-relative p-2 mt-2" *ngIf="cmtLst?.comment!=''">
              <app-content-card class="mb-3" [cmt]="cmtLst?.comment" [showChar]=150></app-content-card>
              <span *ngIf="this.websocketService.webinar_live_comment_list[cmtLst.unique_code]?.has_send==false" (click)="retryCommentPost(cmtLst)"
                class="position-absolute cursor-pointer refresh-arrow">
                <img src="assets/themes/icons/refresh-arrow.svg">
              </span>
            </div>
            </div>
          </div>
        </virtual-scroller>
  
        <div
          *ngIf="websocketService?.comment_list?.length==0 && comment_list_api_in_process==false && error_modal.show==false"
          class="text-center">
          <span><img src="assets/themes/icons/no-chat.svg" alt="no comment"></span>
          <p>{{mls?.languageText?.comment_card?.key19}}<span *ngIf="mls?.is_lskey_display == 1">(comment_card.key19)</span></p>
        </div>
        <!-- error display-->
        <div *ngIf="error_modal.show==true" class="container py-2">
            <div class="text-center">
              <img class="mb-3" src="assets/themes/icons/chat-error-placeholder.svg" />
              <br>
              <span class="heading-4">
                <span class="color-white">{{error_modal.message}}</span></span>
              <br>
              <button (click)="getLiveCommentList()" class="btn-design small my-3 filled">{{mls?.languageText?.comment_card?.key20}}<span *ngIf="mls?.is_lskey_display == 1">(comment_card.key20)</span></button>
            </div>
        </div>
        <!--loader start-->
        <div class='text-center' *ngIf="comment_list_api_in_process==true">
          <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post">
        </div>
        <!--loader end-->
        <div class="sticky-top chat-top-arrow clearfix" id="cmmnt-top-live">
          <div class="border rounded-circle bg-white float-right text-center cursor-pointer"
            (click)="srolltopcommnt()">
            <img src="assets/themes/icons/double-up-arrow.svg" width="35px" />
          </div>
        </div>
  
      </div>
      <div class="modal-footer border-top-color p-2 position-relative form-textarea"  [ngClass]="{'mb-5' : deviceType === deviceTypeConst.MOBILE}"
        *ngIf="error_modal.show==false">
  
        <span class="position-absolute cursor-pointer comment-send-icon" (click)="setComment()">
          <img src="assets/themes/icons/arrow.svg" width="20" alt="arrow">
        </span>
  
        <textarea id="livecmt"
          class="textarea-field message-textbox"
          (keyup.enter)="postLiveComment($event)" placeholder="{{mls?.languageText?.comment_card?.key22}}" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" (click)="generateAnalytic()"></textarea>
      </div>
    </div>
  
    <div class="modal-content border-0" *ngIf="webinarObjData?.is_live_comment==0">
  
      <div class="modal-header px-3 py-2 border-color">
        <p class="heading-4"><span class="color-grey-700">{{mls?.languageText?.comment_card?.key21}}<span *ngIf="mls?.is_lskey_display == 1">(comment_card.key21)</span></span></p>
      </div>
      <!-- comment -->
      <div class="modal-body px-3 py-2 border-top-color position-relative chat-div">
        <!-- no comment -->
        <div class="p-2" *ngIf="commentObj && commentObj.total_comment==0">
          <p class="secondary-paragraph text-center">
            <span><img src="assets/themes/icons/no-chat.svg" alt="no comment"></span>
            <span class="color-grey-500">{{mls.languageText.comment_card.key12}}<span
              *ngIf="mls?.is_lskey_display==1">(comment_card.key12)</span></span></p>
        </div>
        <!--  -->
        <app-product-comment *ngIf="commentObj && commentObj.total_comment!=0" (resetCmtList)="resetCmtList($event)"
          [commentObj]=commentObj [screen_name]="" [list_type]="1" [showCommentBox]="0"></app-product-comment>
      </div>
      <div class="modal-footer border-top-color p-2 position-relative">
  
        <div class="w-100">
          <app-product-comment-box [comment_box_obj]="comment_box_obj" (resetComment)="resetComment($event)"
            [callingFrom]="'webinar'"></app-product-comment-box>
        </div>
  
      </div>
    </div>
  </div>