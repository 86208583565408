/**
 * Angular imports.
 */
import { Directive, ViewContainerRef, OnInit, Input } from '@angular/core';

/**
 * Constant imports.
 */
import { REUSABLE_COMPONENTS } from '../constants/app.constants';

/**
 * Service imports.
 */
import { ReusableService } from '../dataservices/dqVideoPlayer/reusable.service';

/**
 * This directive is used to attach the view of miniPlayer in view container reference.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[reusable-outlet]',
})
export class ReusableDirective implements OnInit {

  /**
   * Get the status for which component to attach.
   */
  @Input() reusableComponent = REUSABLE_COMPONENTS.DQ_VIDEO_PLAYER;

  /**
   * Necessary instances.
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private reusableService: ReusableService
  ) { }

  /**
   * Assign the design to miniPlayer Component.
   * Attach the view of miniPlayer in view container reference.
   */
  ngOnInit(): void {
    if (this.reusableComponent === REUSABLE_COMPONENTS.DQ_VIDEO_PLAYER) {
      this.reusableService.attachDqVideoPlayerComponent(this.viewContainerRef);
    }
    if (this.reusableComponent === REUSABLE_COMPONENTS.DQ_AUDIO_PLAYER) {
      this.reusableService.attachDqAudioPlayerComponent(this.viewContainerRef);
    }
  }
}
