<angular2-multiselect [data]="suggestionValue" [(ngModel)]="selectedValues" [settings]="dropdownSettings"
  (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
  (onDeSelectAll)="onDeSelectAll($event)">
  <c-badge fetchpriority="low">
    <ng-template let-item="item" pTemplate="selectedItems">
      <ng-container *ngTemplateOutlet="selectedItems; context: { $implicit: item  }"></ng-container>
    </ng-template>
  </c-badge>
  <c-item>
    <ng-template let-item="item" pTemplate="suggestionList">
      <ng-container *ngTemplateOutlet="suggestionList; context: { $implicit: item  }"></ng-container>
    </ng-template>
  </c-item>
</angular2-multiselect>
