/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Directive imports.
 */
import { ButtonDirective } from './ga.directive';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';
import { OnboardingEventTrackerDirective } from './onboarding-event-tracker.directive';
import { DataTestIdDirective } from './dataTestId.directive';
import { AppearDirective } from './intersection/appear.directive';
import { ReusableDirective } from './reusable-outlet.directive';
import { ReusableChatDirective } from './reusable-outlet-chat.directive';
import { ShortPressDirective } from './short-press.directive';
import { LongPressDirective } from './long-press.directive';

import { ViewVisibilityDirective } from './view-visibility.directive';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ButtonDirective,
    ScrollToBottomDirective,
    OnboardingEventTrackerDirective,
    DataTestIdDirective,
    AppearDirective,
    ReusableDirective,
    ReusableChatDirective,
    ShortPressDirective,
    LongPressDirective,
    ViewVisibilityDirective,
    BlockCopyPasteDirective
  ],
  exports: [
    ButtonDirective,
    OnboardingEventTrackerDirective,
    DataTestIdDirective,
    AppearDirective,
    ReusableDirective,
    ReusableChatDirective,
    ShortPressDirective,
    LongPressDirective,
    ViewVisibilityDirective,
    BlockCopyPasteDirective
  ]
})
export class DirectivesModule { }
