/**
 * Angular imports.
 */
import {
  Injectable,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  Injector,
} from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';

/**
 * Constant imports.
 */
import { NUMBERS } from './../../constants/app.constants';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Service imports.
 */
import { DqChatPanelComponent } from '../../samvaad/dq-chat-panel/dq-chat-panel.component';
import { DqChatPanelService } from 'src/app/samvaad/dq-chat-panel.service';

/**
 * This service is specific to the reusable directive
 * Do not create another instance of this service.
 */
@Injectable()
export class ChatReusableService {

  /**
   * Reference of the mini player component.
   */
  private dqChatPanelComponentRef: ComponentRef<DqChatPanelComponent>;

  /**
   * Reference of the view container.
   */
  private currentViewContainerRef: ViewContainerRef;

  /**
   * Subscription.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router,
    private dqChatPanelService: DqChatPanelService
  ) {
    this.createDqChatPanelComponent();
    this.subscriptions.add(this.router.events.subscribe((event) => {
      if (!this.dqChatPanelService.isChatPanelVisible && event instanceof ResolveEnd && this.currentViewContainerRef) {
        this.detachDqChatPanelComponent(this.currentViewContainerRef);
      }
    }));
    this.subscriptions.add(this.dqChatPanelService.exitMiniChatPanel
      .subscribe((status: boolean) => {
        if (status) {
          this.destroyDqChatPanelComponent();
        }
      }));
  }

  /**
   * Create the mini player component by resolveComponentFactory.
   */
  createDqChatPanelComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DqChatPanelComponent);
    this.dqChatPanelComponentRef = componentFactory.create(this.injector);
  }

  /**
   * Insert the view of the mini player in view container reference.
   */
  attachDqChatPanelComponent(viewContainerRef: ViewContainerRef): void {
    this.currentViewContainerRef = viewContainerRef;
    if (this.dqChatPanelComponentRef.hostView.destroyed) {
      this.createDqChatPanelComponent();
    }
    viewContainerRef.insert(this.dqChatPanelComponentRef.hostView)
  }

  /**
   * Detach the view of the mini player in view container reference.
   */
  detachDqChatPanelComponent(viewContainerRef: ViewContainerRef): void {
    if (viewContainerRef.indexOf(this.dqChatPanelComponentRef.hostView) > NUMBERS.MINUS_ONE) {
      viewContainerRef.detach(
        viewContainerRef.indexOf(this.dqChatPanelComponentRef.hostView)
      );
    }
  }

  /**
   * Destroy the component.
   */
  destroyDqChatPanelComponent(): void {
    this.dqChatPanelComponentRef.destroy();
  }
}
