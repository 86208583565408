import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Services Imports.
 */
import { OpenAccessService } from '../open-access.service';
import { OnboardingService } from '../../dataservices/onboarding/onboarding.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';

/**
 * Constant imports
 */
import { NUMBERS, OPEN_ACCESS } from '../../constants/app.constants';

/**
 * Global Modal imports.
 */
import { openArticle } from './../../onboarding/interface/global.model';
import { IEventName, IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';
import { PurpleOnboarding } from '../purple-onboarding.service';


@Component({
  selector: 'app-public-listing',
  templateUrl: './public-listing.component.html',
  styleUrls: ['./public-listing.component.scss']
})
export class PublicListingComponent implements OnInit{

  @ViewChild('articleloginContainer') articleloginContainer!: ElementRef;

  /**
   * Used to hold the product list.
   */
  public productList: any;

  /**
   * Offset for api call.
   */
  public productListOffset = 1;

  /**
   * Initialized product Type.
   */
  public productType = 0;

  /**
   * Hold const value for open access.
   */
  public openAccessConst = OPEN_ACCESS;

  // TODO:Pavan: add screen name.
  public callingFrom = 'productList';
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  public subscription: Subscription;
  /**
   * Used to hold page title.
   */
  public pageTitle: string;

  /**
   * Flag for api process.
   */
  public apiInProcess = false;

  public tabId ;

  /**
   * card container.
   */
      public textContainer = {width:'352px'};

  private readonly eventName: Map<number,IEventName> = new Map([
    [OPEN_ACCESS.productlist.community, EVENT_NAME.COMMUNITY_ICON_CLICK],
    [OPEN_ACCESS.productlist.doctalk, EVENT_NAME.DOCTALKS_ICON_CLICK],
    [OPEN_ACCESS.productlist.article, EVENT_NAME.ARTICLES_ICON_CLICK],
    [OPEN_ACCESS.productlist.webinar, EVENT_NAME.WEBINAR_ICON_CLICK]
  ]);

  public record : IPublicAnalyticsData;
 
  constructor(
      public onboardingSer: OnboardingService,
      public oas: OpenAccessService,
      private route: ActivatedRoute,
      public mls: NewLanguageService,
      private paes: PublicAnalyticsEventService,
      public fcs : FunCollectionService,
      public login : PurpleOnboarding) {
    this.subscriptions.add(route.params.subscribe(val => {
      this.productList = null;
      this.productListOffset = 1;
      this.login.loaderSwitch = false;
      this.productType = Number(this.route.snapshot.params['pType']);
      this.getProductList();
    }));
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.oas.selectedTab = params.get('pType');
      this.record = {
        product_type: this.productType,
        product_id: 0,
        screen_name: screenName.PRODUCT_DASHBOARD,
        event_name: '',
        eventName: EVENT_NAME.COUNTRY_DROPDOWN_CLICK
      };
    });
    this.sendEntryEvent();
    this.subscription = this.login.login$.subscribe(() => {
      this.fcs.navigateToAppProductDetail(this.oas.openArticle.product_type, this.oas.openArticle.id);
    })
  }


  /**
   * This method is used to get the product list.
   */
  getProductList() {
    this.apiInProcess = true;
    this.subscriptions.add(this.oas.getOpenArticleList(this.productType, this.productListOffset).subscribe(
      (response: any) => {
        this.apiInProcess = false;
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          res.data.response.forEach(element => {
            if(this.productListOffset === 1) {
              this.productList = element;
                      } else {
              this.productList.list = this.productList.list.concat(element.list);
            }
            this.productListOffset = element.offset;
          });
        }
      }
    ));
  }

  /**
   * On scroll loadmore data.
   */
  onScroll(): void {
    if(this.productListOffset !== 0) {
      this.getProductList();
    }
  }

  /**
   * Destroy the subscriptions.
   */
  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.sendExitEvent();
  }

  /**
   * sends header menu click event
   * @param pType
   * @returns
   */
  sendEvent(pType: number): void {
    if(!this.eventName.has(pType)) {
      return;
    }
    const record: IPublicAnalyticsData = {
      product_type: this.productType,
      product_id: 0,
      screen_name: screenName.PRODUCT_DASHBOARD,
      event_name: '',
      eventName: this.eventName.get(pType)
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: this.productType,
      product_id: 0,
      screen_name: screenName.PRODUCT_DASHBOARD,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DASHBOARD_ENTRY,
      extra: JSON.stringify({unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id})
    }; 
    this.paes.sendAnalytics(record);
  }

  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: this.productType,
      product_id: 0,
      screen_name: screenName.PRODUCT_DASHBOARD,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DASHBOARD_EXIT
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * updates product id in analytic record
   * @param productId
   * @returns
   */
  merge(productId: number): IPublicAnalyticsData {
    const record: IPublicAnalyticsData = {
      product_type: this.productType,
      product_id: productId,
      screen_name: screenName.PRODUCT_DASHBOARD,
      event_name: '',
      eventName: EVENT_NAME.CARD_CLICK
    };
    return record;
  }

  onCardClick():void {
    this.login.callLoginSdk(this.articleloginContainer);
  }
}


