  <div *ngIf="showSubscribeModal && (this.mls.featureOnOffList.featureList.follow.value || !mls.userLoginFlow)" 
    class="modal fade product-subscribe-container" 
    tabindex="-1" 
    role="dialog"
    [config]="{ show: true, backdrop: 'static' }" 
    (onHidden)="onHidden()" 
    bsModal 
    #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-12 text-right close-icon">
            <span class="cursor-pointer"
            (click)="cancelSubscribemodal()"
            [eventTracker]='{"category":mls.googleEventText.product_subscribe.category,"action":mls.googleEventText.product_subscribe.key2,"product_type":"", "product_id":""}' >
              <img src="assets/themes/icons/cancel.svg" alt="close icon">
            </span>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="clearfix">

            <div class="float-left mr-3">
              <img
                *ngIf='channelcardcalling != "doctalkCard" && channelcardcalling !=  "webinarCard" && channelcardcalling !=  "feedcard" '
                src={{channelData?.publication_logo}} class="post-pic rounded-circle profile-icon"
                onerror="this.src='assets/themes/icons/channel-placeholder.svg'">
              <img
                *ngIf='channelcardcalling == "doctalkCard" || channelcardcalling == "webinarCard"  || channelcardcalling == "feedcard"'
                src={{channelData?.channel_logo}} class="post-pic rounded-circle profile-icon"
                onerror="this.src='assets/themes/icons/channel-placeholder.svg'">

            </div>
            <div class="float-left w-100 d-table-container" >
              <div class="d-table d-table-content">
                <div class="d-table-cell align-middle">
                  <h3 class="mb-1 heading-3"> {{channelData?.channel_name}}</h3>
                  <p class="mb-1 paragraph"
                    *ngIf='channelcardcalling == "doctalkCard" || channelcardcalling == "webinarCard" || channelcardcalling == "feedcard"'>
                    <span class="color-grey-700">{{channelData?.title}}</span></p>

                  <p class="mb-2 paragraph"
                    *ngIf='channelcardcalling == "doctalkCard" || channelcardcalling == "webinarCard" || channelcardcalling == "feedcard"'>
                    <span class="color-grey-700">{{channelData?.powered_by_label}}</span>
                    {{channelData?.powered_name}}</p>
                  <p class="mb-2 paragraph"><span class="color-grey-700">{{channelData?.target_label}}
                    {{channelData?.target_name}}</span></p>

                  <p class="mb-3 paragraph-bold"><span class="color-grey-400">{{channelData?.no_of_members}}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="paragraph"><span class="color-grey-700">{{channelData?.sponsor_footer_text}}</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="clearfix mt-2 mb-3">
                <label class="checkbox mb-0 pl-2">
                  <input 
                    [checked]="showSubscribeChecked==true" 
                    (change)="change($event)" 
                    id="subscribeCbx"
                    type="checkbox" />
                  <span class="ml-4 secondary-paragraph">
                    <span>{{mls.languageText.product_subscribe.key1}} 
                      <span *ngIf="mls?.is_lskey_display==1">(product_subscribe.key1)</span>
                      <span class="cursor-pointer color-blue"
                  [eventTracker]='{"category":mls.googleEventText.product_subscribe.category,"action":mls.googleEventText.product_subscribe.key1,"product_type":"", "product_id":""}'
                  (click)="showtnc()">{{mls.languageText.product_subscribe.key2}}<span
                    *ngIf="mls?.is_lskey_display==1">(product_subscribe.key2)</span>.</span>
                    </span>
                    </span>
                </label>
                <p class="erroMsg help-block ml-4" *ngIf="erroMsg==true"> {{mls.languageText.product_subscribe.key3}}<span
                  *ngIf="mls?.is_lskey_display==1">(product_subscribe.key3)</span></p>
              </div>
            </div>
          </div>

        </div>
        <div class="row pb-2">
          <div class="col-12 d-flex justify-content-end py-0 px-3 button-group">
            <button class="btn-design outline ellipsis mr-3" [ngClass]="funCollectionService.device_detail.is_mobile ? 'small' : 'medium'"
              (click)="cancelSubscribemodal()"
              [eventTracker]='{"category":mls.googleEventText.product_subscribe.category,"action":mls.googleEventText.product_subscribe.key2,"product_type":"", "product_id":""}'>{{mls.languageText.product_subscribe.key4}}<span
                *ngIf="mls?.is_lskey_display==1">(product_subscribe.key4)</span>
            </button>
          
            <button class="btn-design filled ellipsis" [ngClass]="funCollectionService.device_detail.is_mobile ? 'small' : 'medium'"
              (click)="showSubscribe(channelData.safe_url)"
              [eventTracker]='{"category":mls.googleEventText.product_subscribe.category,"action":mls.googleEventText.product_subscribe.key3,"product_type":"", "product_id":""}'>
              <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              {{mls.languageText.product_subscribe.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(product_subscribe.key5)</span>
            </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div *ngIf="isShowTncModal" class="modal fade termConditionpopup" tabindex="-1" role="dialog" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
  <div class="modal-dialog modal-md" role="document" style="min-height: 500px;">
     <div class="modal-content"> 
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{multiLangService.Terms_and_Condition}}</h4>
        <button type="button" class="close pull-right" (click)="hideTncModal()" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
         <div class="modal-body">      
           
            <iframe id="tnc_url" width="100%" height="5300px" scrolling="no"   [src]="tnc_scr | safeurl" ></iframe>
              
              <div class="btn-inline clearfix">
            </div>
          </div>          
        </div>
      </div>
</div> -->
<!-- error modal -->
<!-- <app-error-modal (resetErrorModal) = resetErrorModal($event)   [isErrorModalShown]=isErrorModalShown [error_msg]=error_msg></app-error-modal> -->
<!-- <app-error-modal *ngIf="isErrorModalShown==true" (resetErrorModal) = resetErrorModal($event)   [isErrorModalShown]=isErrorModalShown [error_msg]="error_msg"></app-error-modal> -->