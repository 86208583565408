import { Component, OnInit, OnDestroy } from '@angular/core';
/**
 * Rxjs imports.
 */
import { timer as observableTimer, Subject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
/**
 * Service imports.
 */
import { PublicAPIService } from './public-module-services/public-api.service';
import { CommonFunctionService } from './public-module-services/common-function.service';
import { OpenAccessService } from './open-access.service';
import { OnboardingService } from '../dataservices/onboarding/onboarding.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../dataservices/new-language/new-language.service';

@Component({
  selector: 'app-public-module',
  templateUrl: './public-module.component.html',
  styleUrls: ['./public-module.component.scss']
})
export class PublicModuleComponent implements OnInit {
  private appConfigDataFlag = false;


  /**
   * Variable to store client IP
   */
    public ip: string;
   /**
    * Variable to store country code
    */
    public countryCode : any;

  /**
   * used to hold Carousal data.
   */
  public carousaldata = [
    {image:'assets/themes/images/welcome-screen-img-1.svg', text: this.mls.languageText.open_article.key36},
    {image:'assets/themes/images/welcome-screen-img-2.svg', text: this.mls.languageText.open_article.key37},
    {image:'assets/themes/images/welcome-screen-img-3.svg', text: this.mls.languageText.open_article.key38}
  ];

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  selectedLanguage: string;
  constructor(public publicAPIService : PublicAPIService,
     public cfs: CommonFunctionService,
     public oas : OpenAccessService,
     public onboarding : OnboardingService, public fcs: FunCollectionService,
     public mls: NewLanguageService) {
      this.onboarding.resetOpenLanguage();
      }

  /**
   *  get country list 
   * @param ip_address 
   * @param country_code 
   */
  getCountryList(ip_address, country_code): void {
    this.oas.getCountryList(ip_address).subscribe(data => {
      this.oas.iso2Code = this.getIsoByCountryCode(country_code, data.data.country_list);
    })
  }
  ngOnInit(): void {
    const browserFingerprintId = this.cfs.getCustomUUID();
    const browserUniqueId = this.cfs.getFingerPrintUUID();
    const trackId = '';
    this.subscriptions.add(this.publicAPIService.recordBrowserDetails(browserFingerprintId, browserUniqueId, trackId).subscribe((response) => {
    }, (err) => {

    })
    );

        const ip = localStorage.getItem('ipAddress') ? localStorage.getItem('ipAddress') : '';
        this.oas.getCountryCode(ip).subscribe(res => {
          if (res) {
            this.countryCode = res.data.response[0].country_id;
            this.oas.countryId = this.countryCode;
            this.oas.countryId=this.verifyCountryCode(this.countryCode);
            const retrievedOpenAccess = JSON.parse(localStorage.getItem('openAccess'));
            if (retrievedOpenAccess) {
            this.selectedLanguage = this.getHeaderLanguage(retrievedOpenAccess.countryCode);
            this.oas.countryId = retrievedOpenAccess?.countryCode;
          } else {

            const language = this.getLanguageByCountryCode(this.oas.countryId);
            const openObject = {
              countryCode: this.oas.countryId,
              openLang: language,
            };
            localStorage.setItem('openAccess', JSON.stringify(openObject));
            this.onboarding.resetOpenLanguage();
          }
          this.getCountryList(ip, this.oas.countryId);
          }});

  }

   /**
    * Get the language mapped with country code
    */
    getLanguageByCountryCode(countryCode) {
      const country = this.oas.countryList.find((c) => c.id === countryCode);
      return country ? country.lang : 'en';
      }

   /**
    * Get the language name mapped with country code
    */
    getHeaderLanguage(countryCode) {
      const country = this.oas.countryList.find((c) => c.id === countryCode);
      return country ? country.name : 'Global';
      }
      verifyCountryCode(countryCode) {
        const country = this.oas.countryList.find((c) => c.id === countryCode);
        return country ? country.id : countryCode;
        }

    /**
     * Close welcome screen.
     */
    closeAction(event): void {
       this.fcs.openAccessWelcomeScreenData.extras.welcome_popup=0;
    }

    /**
    * Get the language mapped with country code
    */
    getIsoByCountryCode(countryCode, countryList) : string {
      const country = countryList.find((c) => c.id === countryCode);
      return country ? country.iso_code : 'IN';
    }

    /**
     * Component destroy
     */
    ngOnDestroy() : void {
      this.onboarding.setLanguage();
    }

 }
