import { AfterViewInit } from '@angular/core';
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

/**
 * Modal imports.
 */
import { Comment, CommentTag, Tag } from '../../interface/comment/comment';

/**
 * Service imports.
 */
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
import { AuthService } from '../../auth/auth.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { GetService } from '../../dataservices/get.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { EventMsgService } from '../../dataservices/master-service/event-msg.service';
import { ImageGroupService } from '../../dataservices/master-service/image-group.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { PostApiService } from '../../dataservices/post-api.service';

/**
 * Library imports.
 */
import { ModalDirective } from 'ngx-bootstrap/modal';
import { listAnimation } from '../../animations/utility.animation';

/**
 * Constant imports.
 */
import { EVENT_NAME } from '../../analytics-event/analytics.constant';
import { COMMENT_ACTION, NUMBERS, PRODUCT_TYPE_CONSTANT, SCREEN_NAME } from '../../constants/app.constants';
/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
/**
 * Interface import.
 */
import { AnalyticsInterface, EventDataKey } from '../../analytics-event/analytics.model';
import { userComment } from '../../onboarding/interface/global.model';

/**
 * Product Comment Component
 */
@Component({
  selector: 'app-product-comment',
  templateUrl: './product-comment.component.html',
  styleUrls: ['./product-comment.component.scss'],
  animations: [listAnimation]

})
export class ProductCommentComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() commentObj;
  @Input() is_activity;
  @Input() showCommentBox;
  @Input() list_type;
  @Input() screenName;
  @Input() comment_box_obj;
  @Input() callingFrom;
  @Output() shareCommentCountToParent = new EventEmitter();
  @Output() resetCmtList = new EventEmitter();
  /**
   * Total reply count on main comment.
   */
  @Output() deleteTotalReplyComment = new EventEmitter();
  public comment_post_prfl_image = {
    prflclass: 'text-uppercase user-40 text-center rounded-circle text-white d-table-cell align-middle fnt-size14px',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'user-40 post-pic rounded-circle',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  public file_type: string;
  public errfrm_img_video_pdf: string;
  public isloadershow = false;
  public postingImgs = [];
  public media_json = [];
  public info_msg: string;
  public isInfoModalShown = false;
  public isErrorModalShown = false;
  public error_msg: string;
  public commentList: Comment[] = [];
  public product_id: number;
  public product_type: number;
  public page = 1;  // page number
  public userCustomId;
  public totalComment = 0;
  public commentTag: CommentTag;
  public tag: Tag[] = [];
  public offset = 1;
  public kind: string;
  public replyflag = 0;
  public postComment: Comment[] = []; // store post data on success
  public commentVal = '';  // get value of comment box
  public replyVal = '';  // get value of reply box
  public user_profile_pic_path = '';  // user profile
  public likeObj; // Like Component Object
  public showLikeListModal = false;
  public profile;
  public confirmInfo = '';
  public statusMsg: string;
  public isDocReqModalShown = false;
  public isConfirmModalShown = false;
  public upload_doc = 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public indx: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public comment_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public type: any;
  public loginUserCustomeId: string;
  public comment_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public media_source: any;
  public loader_comment = false;
  public loader_commentreply = false;
  public comment_like_loader = 0;
  public reply_comment_like_loader = 0;
  public loader_for_comment = 0;
  public Retry = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_permission: any;
  public isModalShown = false; isSuccessModalShown = false;
  @ViewChild('autoShownModal') autoShownModal: ModalDirective;

  /**
   * Product type from constants.
   */
  public productType = PRODUCT_TYPE_CONSTANT;
  /**
   * Analytics data.
   */
  public analyticsViewMoreComment: AnalyticsInterface;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Emit comment option on click.
   */
  @Output() emitComment = new EventEmitter();
  /**
   * Analyticsdata object.
   */
  public analyticsData: AnalyticsInterface;

  /**
   * Instance created.
   */
  constructor(
    private dss: DataStorageService,
    private jwtSer: JwtDecodeService,
    private postSer: PostApiService,
    private getSer: GetService,
    public igs: ImageGroupService,
    public ems: EventMsgService,
    private masterService: MasterserviceService,
    private authService: AuthService,
    public funCollectionService: FunCollectionService,
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService) {
    this.user_permission = this.dss.user_profile_data.profile.permission;
    this.user_profile_pic_path = this.dss.user_profile_data.profile.profile_pic_path;
    this.subscriptions.add(this.masterService.media_source.subscribe(d => {
      this.media_source = d;
    }));
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.subscriptions.add(this.dss.product_comment.subscribe(data => {
      if (data.product_id == this.commentObj['product_id']) {
        if (this.list_type == 2) {
          this.commentList.unshift(data.comment);
        } else {
          this.commentList = this.commentList.concat(data.comment);
        }
        this.totalComment = this.totalComment + 1;
      }

    }));
    this.loginUserCustomeId = this.authService.getbyName('profile_obj');
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    this.commentList = [];
    if (this.commentObj && (this.commentObj !== null || this.commentObj !== '')) {
      this.product_id = this.commentObj['product_id'];
      this.product_type = this.commentObj['product_type'];
      if(this.commentObj['total_comment']){
        this.totalComment = this.commentObj['total_comment'];
        }
        if(this.commentObj['comment_count']){
          this.totalComment = this.commentObj['comment_count'];
        }
        if (this.product_type === 1) {
          this.kind = this.commentObj['kind'];
        }
        if ((this.product_type === NUMBERS.THREE && this.commentObj['comment'].length > NUMBERS.ZERO)) {
          this.commentList = this.commentObj['comment'];
            this.getCommentList(this.product_type, this.product_id, this.offset, NUMBERS.ONE);
        }
        if ((this.product_type === PRODUCT_TYPE_CONSTANT.DOCTALKS && this.commentObj['comment'].length > NUMBERS.ZERO)) {
          this.commentList = this.commentObj['comment'];
            this.getCommentList(this.product_type, this.product_id, this.offset, NUMBERS.ONE);
        }
      /**
       * product type ==1 is Feed && product_type ==3 journal.
       */
      if ((this.product_type === 1 && this.commentObj['comment'].length > 0)) {
        this.commentList = this.commentObj['comment'];
        if (this.commentObj.kind === 'cases') {
          this.getCommentList(this.product_type, this.product_id, this.offset, 1);
        } else {
          this.commentList = this.commentObj['comment'];
        }
      } else {
        if (this.totalComment > NUMBERS.ZERO && this.callingFrom !== SCREEN_NAME.GRAND_ROUND) {
          this.getCommentList(this.product_type, this.product_id, this.offset, 2);
        }
      }
    }

    this.comment_post_prfl_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
    this.comment_post_prfl_image.prflrname = this.dss.user_profile_data.profile.registered_name;
    this.comment_post_prfl_image.prflfname = this.dss.user_profile_data.profile.first_name;
    this.comment_post_prfl_image.prfllname = this.dss.user_profile_data.profile.last_name;
    this.comment_post_prfl_image.prflcid = this.dss.user_profile_data.profile.custom_id;
    this.comment_post_prfl_image.full_name = this.dss.user_profile_data.profile.full_name;
    this.comment_post_prfl_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
    this.comment_post_prfl_image.partial_permission_state = this.dss.user_profile_data.profile.partial_permission_state;

    if (this.callingFrom) {
      this.analyticsViewMoreComment = {
        'event_name': EVENT_NAME.FEED_VIEWCOMMENT_CLICK,
        'product_type': Number(this.product_type),
        'product_type_id': Number(this.product_id),
        'screen_name': this.callingFrom,
      };
    }
  }
  // getting Comment list calling api
  private getCommentList(product_type, product_id, offset, frm): void {
    this.Retry = '';
    if (offset === 0 && this.page === 0) {
      return;
    }
    this.subscriptions.add(this.getSer.getProductCommentList(product_type, product_id, offset).subscribe(async data => {
      this.jwtSer.decodeData(data).then(rootObject => {
        this.loader_comment = false;

        if (rootObject['status'] === 1) {
          if (rootObject['data']['comment'].length > 0) {
            this.commentList = this.commentList.concat(rootObject['data']['comment']);
            if (this.callingFrom === SCREEN_NAME.FEED_DETAIL) {
              setTimeout(() => {
                document.getElementById('feed-detail-tagcomment').scrollIntoView();
              }, 2000);
            }
            if (this.offset == 1) {
              this.totalComment = rootObject['data'].total_count;

              const sl = this.commentList;
              const out = [];
              for (let i = 0, l = sl.length; i < l; i++) {
                let unique = true;
                for (let j = 0, k = out.length; j < k; j++) {
                  if ((sl[i].comment_id === out[j].comment_id)) {
                    unique = false;
                  }
                }
                if (unique) {
                  out.push(sl[i]);
                }
              }
              this.commentList = out;
            }

            if (frm == 1) {

              this.commentList = this.commentList.splice(0, 1);
              this.commentTag = rootObject['data']['comment_tag'];
              this.page = 1;
            }
          }
          // Offset management
          if (rootObject['data']['offset'] != 0) {
            this.page = rootObject['data']['offset'];
            if (frm == 1) {
              this.page = 1;
            }
          } else
            if (frm == 1) {
              this.page = 1;
            } else {
              this.page = 0;
            }
        } else if (rootObject['status'] === 0) {
          this.funCollectionService.errorHandeler(rootObject);
          return;
        }
      },
        () => {
          this.loader_comment = false;

        }

      );
    }));
  }

  ngAfterViewInit(): void {
    if (this.dss.notificationAdditionalData) {
      //this.funCollectionService.scrollToTaggedUserComment();
    }
  }

  /**
   * Post Comment
   */
  setComment(product_id, product_type, comment, file_json): void {
    this.commentVal = '';
    this.replyVal = '';
    if (comment.trim() == '' && (file_json == '[]' || file_json == '')) { return; }

    this.postComment = [];
    this.subscriptions.add(this.postSer.productComment(product_type, product_id, comment.trim(), file_json, 'add')
      .subscribe(resData => {
        this.media_json = [];
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] === 1) {
            this.funCollectionService.showSuccess(data['msg'], 'Success');
            this.postComment.push(data['data']['comment']);
            this.shareCommentCountData('add');
            this.commentList = this.commentList.concat(this.postComment);

          } else if (data['status'] === 0) {
            this.funCollectionService.errorHandeler(data, 'generate_certificate');
          }
        }
        );
      }
      ));

    /**
     * Set analytics data for analytics event.
     */
    this.sendAnalytics(EVENT_NAME.COMMENT_POST_CLICK, product_type, product_id, this.callingFrom);
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, productType: number, productTypeId: number, screenName: string, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    this.analyticsData = {
      event_name: eventName,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(this.analyticsData);
  }
  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * reset information modal
   */
  resetInfoModal(): void {
    this.isInfoModalShown = false;
  }

  /**
   * this will call, click on like members of feed
   */
  callLikeComponent(action, comment_id, like_status, indx, childindx): boolean {


    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    if (action == 'set' && like_status === 1) {
      if (this.kind != 'cases' && this.product_type != 1) {
        return false;
      }

    }
    let ptype = 2;
    if (childindx == '') {
      ptype = this.product_type;
    }
    this.loader_for_comment = comment_id;
    if (ptype == 2) {
      this.comment_like_loader = 1;
    } else if (ptype == 1) {
      this.reply_comment_like_loader = 1;

    }
    const returnparams = { 'index': indx, 'childindx': childindx, 'ptype': ptype };
    this.likeObj = { 'product_id': comment_id, 'product_type': 2, 'callLikeProperty': action, 'callBackParams': returnparams };
    this.showLikeListModal = true;
  }

  /**
   * reset like list modal
   */
  resetLikeModalList(val): void {
    this.comment_like_loader = 2;
    this.reply_comment_like_loader = 2;
    this.loader_for_comment = 0;

    this.showLikeListModal = val['reset'];
    const likeAction = val['reaction']['action'];
    const likeObj = val['reaction']['likeObj'];
    const returnObj = val['reaction']['returnObj'];
    if (likeAction == 'set') {
      const callBackParams = likeObj['callBackParams'];
      const indx = callBackParams['index'];
      const childindx = callBackParams['childindx'];
      if (childindx == -1) {
        if (this.commentList[indx]['comment_like_status'] != 1) {
          if (returnObj.like == 1) {
            this.commentList[indx]['comment_like_status'] = 1;
            this.commentList[indx]['total_comment_like'] += 1;
          } else {
            this.commentList[indx]['comment_like_status'] = 0;
            this.commentList[indx]['total_comment_like'] -= 1;
            if (this.commentList[indx]['total_comment_like'] < 0) {
              this.commentList[indx]['total_comment_like'] = 0;
            }
          }

        } else {
          if (returnObj.like == 0) {

            this.commentList[indx]['comment_like_status'] = 0;
            this.commentList[indx]['total_comment_like'] -= 1;
          }
        }

      } else {
        if (this.commentList[indx]['comment_reply'][childindx]['comment_like_status'] != 1) {
          this.commentList[indx]['comment_reply'][childindx]['comment_like_status'] = 1;
          this.commentList[indx]['comment_reply'][childindx]['total_comment_like'] += 1;
        }

      }

    }
  }

  /**
   * Share comment count to Parent
   */
  shareCommentCountData(process): void {
    this.shareCommentCountToParent.emit(process);
  }

  /**
   * loadmore of comment
   */
  loadMore(product_type, product_id, offset): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.loader_comment = true;
    if ((this.commentObj != null || this.commentObj != '') && offset == 1) {
      if (this.commentObj['comment'].length > 0) {
        // this.commentList=[];
      }

    }
    this.getCommentList(product_type, product_id, offset, 2);
  }

  /**
   * Status Handler
   */
  showModal(status, msg): void {
    this.statusMsg = msg;
    if (status === 1) {
      this.isSuccessModalShown = true;
    } else if (status === 2) {
      this.isErrorModalShown = true;
    } else {
      this.isModalShown = true;
    }

    this.postingImgs = [];
    this.media_json = [];
  }

  /**
   * file Change Event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fileChangeEvent(fileInput: any): boolean {

    // let file_size = '';
    let selected_file_type = [];
    this.postingImgs = [];
    this.media_json = [];

    this.file_type = fileInput.target.files[0].type;
    selected_file_type = this.file_type.split('/');
    this.file_type = selected_file_type[0];

    if (this.file_type == 'image') {
      if (selected_file_type.toString() != 'image,png' && selected_file_type.toString() != 'image,PNG' && selected_file_type.toString() != 'image,jpg' && selected_file_type.toString() != 'image,JPG' && selected_file_type.toString() != 'image,jpeg' && selected_file_type.toString() != 'image,JPEG') {
        this.errfrm_img_video_pdf = this.mls.languageText.comment_card.key25;
        return false;
      } else {
        this.errfrm_img_video_pdf = '';
      }
      this.isloadershow = true;
      let chk_fileType = fileInput.target.files[0].type;
      const chk_selected_file_type = chk_fileType.split('/');
      chk_fileType = chk_selected_file_type[0];
      // file_size = fileInput.target.files[0].size;
    }
  }

  /**
   * remove Img
   */
  removeImg(): void {
    this.postingImgs = [];
    this.media_json = [];
  }

  /**
   * set Event Comment
   */
  setEventComment(): void {
    const comment = document.querySelector<HTMLInputElement>('#comment_content')?.value;
    this.setComment(this.commentObj.product_id, this.commentObj.product_type, comment, JSON.stringify(this.media_json));
    document.querySelector<HTMLInputElement>('#comment_content').value = '';
    this.postingImgs = [];
    this.media_json = [];
  }

  /**
   * update Event Comment
   */
  updateEventComment(file_json): void {
    file_json = JSON.stringify(this.media_json);
    if (file_json == '[]') {
      this.postingImgs = [];
      this.media_json = [];
      file_json = '';
    }
  }

  /**
   * resetError Modal
   */
  resetErrorModal(): void {
    this.isErrorModalShown = false;
  }

  /**
   * reset Comment
   */
  resetComment(index): void {
    const dummyCommentList = this.commentList;
    this.deleteTotalReplyComment.emit(dummyCommentList[index].total_comment_reply);
    this.commentList.splice(index, 1);
    this.shareCommentCountData('sub');
    this.totalComment = this.totalComment - 1;
    this.resetCmtList.emit(this.commentList);
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  /**
   * Add and remove count on reply on main comment.
   */
  replyCommentCount(count): void {
    if (count === NUMBERS.ONE) {
      this.shareCommentCountToParent.emit(COMMENT_ACTION.ADD);
    } else {
      this.shareCommentCountToParent.emit(COMMENT_ACTION.DEL);
    }
  }

  /**
   * Emit auto comment on click.
   */
  emitAutoComment(data: userComment): void {
    this.emitComment.emit(data);
  }

}
