<div class="header">
    <app-public-page-header (emitIconClick)="sendEvent($event)" [analytic]="record"
    [communityText]="mls.languageText.open_article.key10"
    [doctalkText]="mls.languageText.open_article.key11"
    [articleText]="mls.languageText.open_article.key12"
    [webinarText]="mls.languageText.open_article.key13"></app-public-page-header>
</div>
<div class="container">
    <div class='text-center' *ngIf="login.loaderSwitch">
        <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post">
    </div>
    <div *ngIf="!login.loaderSwitch" class="main-container mt-3">
        <div class="row">
            <div class="col-md-7">
                <div class="mb-3">
                 <app-public-page-content></app-public-page-content>
                </div>
            </div>
            <div *ngIf="suggestionList?.list.length > 0" class="col-md-5">
                <div>
                    <app-product-suggestion-list
                     [callingFrom]="callingFrom"
                     [suggestionList]="suggestionList?.list"
                     [listTitle]="suggestionList?.label"
                     [pType]="openAccessConst.productlist.article"
                     (cardClicked)="onCardClick()">
                    </app-product-suggestion-list>
                </div>
                <div class="text-center">
                    <app-public-view-all
                    [viewAllText]="mls.languageText.open_article.key15"
                    [pType]="openAccessConst.productlist.article"
                    [callingFrom]="callingFrom"
                    [title]="mls.languageText.open_article.key30"
                    [offset]="suggestionList?.offset"
                    (viewAllClick)="viewAllClick($event)"></app-public-view-all>
                </div>
            </div>
        </div>
    </div>
</div>
<div #articleLoginController class="d-none"></div>


    <!-- <app-business-enquiry-form></app-business-enquiry-form> -->

