/**
 * Angular imports.
 */
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { OnboardingEventTrackerDirective } from '../../../directives/onboarding-event-tracker.directive';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';

/**
 * Directive imports.
 */
import { ButtonDirective } from '../.././../directives/ga.directive';

/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';

/**
 * Environment import
 */
import { environment } from './../../../../environments/environment';
/**
 * Constant Imports.
 */
import { SUPPORT_EMAIL, GET_OTP_WHITELIST_ERROR_CODE, LANG_ABBREVIATIONS, NUMBERS, SAMVAAD_INDEX_DB } from '../../../constants/app.constants';
import { IPublicAnalyticsData, SCREEN_NAME as screenName } from '../../../public-module/public-analytics/public-analytics.model';
import { EVENT_NAME } from '../../../public-module/public-analytics/public-analytics.constant';
import { PublicAnalyticsEventService } from '../../../public-module/public-analytics/public-analytics-event.service';

/**
 * InputMobile Component
 */
@Component({
  selector: 'app-input-mobile',
  templateUrl: './input-mobile.component.html',
  styleUrls: ['./input-mobile.component.scss']
})
export class InputMobileComponent implements OnInit, OnDestroy {

  @ViewChild('mobile_no', { static: true }) mobile_no: ElementRef;
  public sessiontime = { 'start_time': '', 'end_time': '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedCountryCode: any;
  public selected_lang = 'en';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_country: any = [];
  public err_msg = '';
  public api_err_msg = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_mob_data: any;
  public api_in_process = false;
  public showColor = false;
  public country_api_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public lang_value: any = this.mls.languageText.input_screen.key7;
  public page_from = this.mls.googleEventText.input_screen.category;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public phonenumber: any;
  public captcharesponse = '';
  public captcha = false;
  /**
   * Hold recaptcha related values.
   */
  public whteListModal = false;
  public whiteListErrorMsg = '';
  public whileListErrorCode = 0;
  public whiteListMobNo: number;
  public whileListEmail = '';
  public isValidWhiteListEmail = true;
  public whileListSub: Subscription;

  /**
   * Used for website URL.
   */
  public website_url = environment.baseUrls.main_website_url;

  /**
   * Support email constant.
   */
  public supportEmail = SUPPORT_EMAIL;
  /**
   * Language abbreviations.
   */
  public langAbbreviations = LANG_ABBREVIATIONS;

  /**
   * Otp whitelist error code.
   */
  public OTP_WHITELIST_ERROR_CODE = GET_OTP_WHITELIST_ERROR_CODE;

  /**
   * Used to store hcaptcha token.
   */
  public HcaptchaGeneratedToken = '';

  /**
   * To unsubscribe data.
   */
  public subscriptions: Subscription = new Subscription();

  /**
   * Number constant.
   */
  public numbers = NUMBERS;

  /**
   * Necessary instances
   */
  constructor(public route: ActivatedRoute,
              private onboardDir: OnboardingEventTrackerDirective,
              private jwtSer: JwtDecodeService,
              private masterService: MasterserviceService,
              public Dss: DataStorageService,
              public mls: NewLanguageService,
              private router: Router,
              public onboardingService: OnboardingService,
              private directive_ser: ButtonDirective,
              public fcs: FunCollectionService,
              private elementRef: ElementRef,
              private paes: PublicAnalyticsEventService) {
    const d = new Date();
    this.sessiontime.start_time = d.getTime().toString();

    if (this.Dss.countryId != '' && this.Dss.countryId != null) {
      this.phonenumber = this.Dss.phoneNumber;
      this.showColor = true;
    }
  }


  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.HcaptchaGeneratedToken = '';
    if (localStorage.getItem('lang') != null) {
      this.selected_lang = localStorage.getItem('lang');

    } else {
      this.selected_lang = 'en';
    }
    this.elementRef.nativeElement.querySelector('#mobile_no')?.focus();


    if (localStorage.getItem("lang") == "en") {
      this.lang_value = "English";
    } else if (localStorage.getItem("lang") == "in") {
      this.lang_value = "Bahasa";
    } else if (localStorage.getItem("lang") == "vi") {
      this.lang_value = "Tiếng Việt";
    } else if (localStorage.getItem("lang") == "ko") {
      this.lang_value = "x한국어";
    } else if (localStorage.getItem("lang") == "zh") {
      this.lang_value = "中文";
    } else if (localStorage.getItem("lang") === LANG_ABBREVIATIONS.JAPANESE) {
      this.lang_value = this.mls.languageText.input_screen.key26;
    } else if (localStorage.getItem("lang") === LANG_ABBREVIATIONS.SPANISH) {
      this.lang_value = this.mls.languageText.open_article.key43;}

    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('.c-btn')?.classList?.add('border-right');
    }, 200);

    if (sessionStorage.getItem('user_mob_data') != null) {
      this.user_mob_data = JSON.parse(sessionStorage.getItem('user_mob_data'));
      if (this.user_mob_data.mobile_no != '' && this.user_mob_data.mobile_no != undefined) {
        this.showColor = true;
      }
    }


    sessionStorage.removeItem('req_data');

    if (this.onboardingService.ddl_data.list_data.length == 0) {
      // this.subscriptions.add(this.onboardingService.getClentIp().subscribe((data) => {
      //   this.onboardingService.client_ip_address = data;
      //   this.getCountryList();
      // }, () => {
      //   this.getCountryList();
      // }));
      this.getCountryList();

    } else {
      this.user_country = this.onboardingService.ddl_data.list_data[0];
      if (sessionStorage.getItem('user_mob_data') != null) {
        const temp = JSON.parse(sessionStorage.getItem('user_mob_data'));
        const xx = this.onboardingService.ddl_data.list_data.find(x => x.id == temp.country_id);
        this.user_country = xx;
      }
    }
    if (sessionStorage.getItem('user_mob_data') != null) {
      this.user_mob_data = JSON.parse(sessionStorage.getItem('user_mob_data'));

    }

    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.Dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.input_screen.key4, '', '', this.page_from, '');

      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));

    this.sendEntryEvent();
  }


  /**
   * get Country List
   */
  getCountryList(): void {
    this.country_api_in_process = true;
    this.subscriptions.add(this.onboardingService.getCountryList().subscribe(resData => {
      this.country_api_in_process = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.onboardingService.ddl_data.list_data = [];
          for (let i = 0; i < data['data'].country_list.length; i++) {
            // tslint:disable-next-line: radix
            const temp = { id: parseInt(data['data'].country_list[i].id), itemName: data['data'].country_list[i].country, code: data['data'].country_list[i].country_code, image: 'assets/' + (data['data'].country_list[i].flag_url ? data['data'].country_list[i].flag_url : 'images/countryflags/tn.png'), index: i };
            this.onboardingService.ddl_data.list_data.push(temp);
          }
          this.onboardingService.ddl_data.selected_data = [];
          this.onboardingService.ddl_data.selected_data.push(this.onboardingService.ddl_data.list_data[0]);
          this.user_country = this.onboardingService.ddl_data.list_data[0];

          this.selectedCountryCode = this.user_country.code;

        }
      });

    }, () => {
      this.country_api_in_process = false;
    }));
  }

  /**
   * resetDDL Data
   */
  resetDDLData(data): void {
    const d = new Date();
    this.sessiontime.start_time = d.getTime().toString();
    this.sessiontime.end_time = d.getTime().toString();
    this.onboardDir.productDetailEvent(this.sessiontime, this.mls.googleEventText.input_screen.key3, '', '', this.mls.googleEventText.input_screen.category, 0);

    this.onboardingService.ddl_data.selected_data = [];
    this.onboardingService.ddl_data.selected_data.push(data.item[0]);
    this.user_country = [];
    this.user_country = data.item[0];
    this.selectedCountryCode = this.user_country.code;
    this.err_msg = '';

    this.Inputvalid();

    this.elementRef.nativeElement.querySelector('#mobile_no')?.focus();

  }

  /**
   * Use to check if hcaptcha token is genrated or not.
   */
  verifyHcpToken(token: string): void {
    if (this.HcaptchaGeneratedToken === '') {

      this.HcaptchaGeneratedToken = token;
      this.requestOtp();
    }
  }

  /**
   * The verification expired.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onExpired(response: any): void {
    console.log('hcaptchaExpiredError' + response);
  }

  /**
   * An error occured during the verification process.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError(error: any): void {
    console.log('hcaptchaError' + error);
  }

  /**
   * Function used to verify otp.
   */
  requestOtp(): boolean {
    window.indexedDB.deleteDatabase(SAMVAAD_INDEX_DB);
    this.api_err_msg = '';
    const user_mob_data = {
      country_id: '',
      country_code: '',
      mobile_no: ''
    };
    this.err_msg = '';
    const mobile_no = this.elementRef.nativeElement.querySelector('#mobile_no').value;
    if (this.user_country.id == undefined) {
      this.err_msg = 'Please select country code';
      return false;
    } else {
      user_mob_data.country_code = this.user_country.code;
      user_mob_data.country_id = this.user_country.id;
    }

    if (mobile_no == '' || mobile_no == undefined) {
      this.err_msg = 'Please enter mobile number';
      this.elementRef.nativeElement.querySelector('#mobile_no')?.focus();
      return false;
    }

    if (mobile_no.length < 4 || mobile_no.length > 15) {
      this.err_msg = 'Please enter valid mobile number';
      this.elementRef.nativeElement.querySelector('#mobile_no')?.focus();
      return false;
    } else {
      this.err_msg = '';
      user_mob_data.mobile_no = mobile_no;
      this.whiteListMobNo = Number(mobile_no);
      if (this.masterService.isOnline == false) {
        this.masterService.isConnectionModalShown.next('1');
        return false;
      }
      sessionStorage.setItem('user_mob_data', JSON.stringify(user_mob_data));
      this.api_in_process = true;


      this.subscriptions.add(this.onboardingService
        // tslint:disable-next-line: radix
        .plain_request_otp(parseInt(mobile_no), this.user_country.id, this.user_country.code, this.HcaptchaGeneratedToken).subscribe(resData => {
          this.country_api_in_process = false;
          this.jwtSer.decodeData(resData).then(data => {

            this.api_in_process = false;
            if (data['status'] == 1) {
              const req_data = {
                wait_time: data['data'].wait_time,
                msg: data['msg'],
                screen_data: data['data'].screen_data,
                token_id: data['data'].token_id,
              };
              sessionStorage.setItem('req_data', JSON.stringify(req_data));
              this.router.navigate(['/login/otp'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            } else if (data['status'] == 0) {
              this.captcharesponse = '';
              this.captcha = false;
              if (data['error'].code === 1028) {
                this.whteListModal = true;
                this.HcaptchaGeneratedToken = '';
                this.whiteListErrorMsg = data['error'].msg;
                this.whileListErrorCode = data['error'].code;
              }
              if (data['error'].code == 1001 || data['error'].code == 1005) {
                this.api_err_msg = data['error'].msg;
              }
            }
          });
        },
          () => {
            this.api_in_process = false;
          }
        ));
    }
  }

  /**
   * number Input
   */
  numberInput(e): boolean {
    this.HcaptchaGeneratedToken = '';
    this.api_err_msg = '';
    if (e.which == 13) {
      document.getElementById('requestOtpClick')?.click();
      return;
    }
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      document.getElementById('errmsg').innerHTML = this.mls.languageText.input_screen.key9['style']['display'] = 'block';
      return false;
    }
  }

  /**
   * Input valid
   */
  Inputvalid(): void {

    if (this.selectedCountryCode == 62) {
      if (this.phonenumber?.length > 5) {
        this.showColor = true;
        this.phonenumber = this.phonenumber.substr(0, 12);
      } else {
        this.showColor = false;
      }
    } else if (this.selectedCountryCode == 63) {
      if (this.phonenumber?.length > 5) {
        this.showColor = true;
        this.phonenumber = this.phonenumber.substr(0, 11);
      } else {
        this.showColor = false;
      }
    } else if (this.selectedCountryCode == 66) {

      if (this.phonenumber?.length > 8) {
        this.showColor = true;
        this.phonenumber = this.phonenumber.substr(0, 10);
      } else {
        this.showColor = false;
      }
    } else if (this.selectedCountryCode == 60) {

      if (this.phonenumber?.length > 8) {
        this.showColor = true;
        this.phonenumber = this.phonenumber.substr(0, 10);
      } else {
        this.showColor = false;
      }
    } else {
      if (this.phonenumber?.length > 3) {
        this.showColor = true;
        this.phonenumber = this.phonenumber.substr(0, 15);
      } else {
        this.showColor = false;
      }
    }
  }

  /**
   * select Lang
   */
  selectLang(val): void {
    localStorage.setItem('lang', val);
    if (val == 'en') {
      this.lang_value = this.mls.languageText.input_screen.key7;
      this.selected_lang = 'en';
    } else if (val == 'in') {
      this.lang_value = this.mls.languageText.input_screen.key8;
      this.selected_lang = 'in';
    } else if (val == 'vi') {
      this.lang_value = this.mls.languageText.input_screen.key10;
      this.selected_lang = 'vi';
    } else if (val == 'ko') {
      this.lang_value = this.mls.languageText.input_screen.key11;
      this.selected_lang = 'ko';
    } else if (val == 'zh') {
      this.lang_value = this.mls.languageText.input_screen.key25;
      this.selected_lang = 'zh';
    } else if (val === LANG_ABBREVIATIONS.JAPANESE) {
      this.lang_value = this.mls.languageText.input_screen.key26;
      this.selected_lang = LANG_ABBREVIATIONS.JAPANESE;
    } else if (val === LANG_ABBREVIATIONS.SPANISH) {
      this.lang_value = this.mls.languageText.open_article.key43;
      this.selected_lang = LANG_ABBREVIATIONS.SPANISH;
    }

    window.location.reload();
  }

  /**
   * Redirect user to terms and condition.
   */
  redirectToTnc(): void {
    this.fcs.navigateTotnc();
  }

  /**
   * Redirect user to privacy policy.
   */
  redirectToPrivacy(): void {
    this.fcs.navigateToPrivacyPolicy();
  }

  /**
   * resolved
   */
  resolved(captchaResponse: string): void {
    this.api_err_msg = '';
    this.captcharesponse = captchaResponse;
    if (this.captcharesponse != null && this.captcharesponse !== '') {
      this.captcha = true;

    } else {
      this.captcha = false;
    }
  }

  /**
   * Hide whitelist modal.
   */
  hideModal(): void {
    this.whteListModal = false;
    this.whiteListErrorMsg = '';
    this.whileListErrorCode = 0;
    this.whteListModal = false;
    this.isValidWhiteListEmail = true;
    this.whileListEmail = '';
  }

  /**
   * Submit Whitelist data.
   */
  submitWhitelistData(): void {

    this.isValidWhiteListEmail = this.fcs.isEmail(this.whileListEmail);
    if (this.isValidWhiteListEmail === false && this.whileListEmail !== '') {
      return;
    } else {
      this.whileListSub = this.onboardingService
        .userCaptchaRequest(this.user_country, this.whiteListMobNo, this.whileListEmail).subscribe(data => {
          if (data['status'] === 1 && data['code'] === 2000) {
            this.whileListErrorCode = data['code'];
          } else if (data['status'] === 1) {
            this.whileListErrorCode = data['code'];
          }
        });

    }

  }

  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser
      .productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.input_screen.key4, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    if (this.whileListSub) {
      this.whileListSub.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.sendExitEvent();
  }

  /**
   * sends analytic entry event
   */
  private sendEntryEvent(): void {
    const record =  {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.LOGIN_SCREEN,
      event_name: '',
      eventName: EVENT_NAME.LOGIN_SCREEN_ENTRY,
      extra: JSON.stringify({unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id})
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * sends analytic exit event
   */
  private sendExitEvent(): void {
    const record =  {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.LOGIN_SCREEN,
      event_name: '',
      eventName: EVENT_NAME.LOGIN_SCREEN_EXIT
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * analytic terms and condition click event
   */
  termsRecord(): IPublicAnalyticsData {
    return {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.LOGIN_SCREEN,
      event_name: '',
      eventName: EVENT_NAME.TERMS_CONDITIONS_CLICK
    };
  }
  /**
   * analytic data privacy policy click event
   */
  privacyRecord(): IPublicAnalyticsData {
    return {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.LOGIN_SCREEN,
      event_name: '',
      eventName: EVENT_NAME.DATA_POLICY_CLICK
    };
  }

  /**
   * analytic language dropdown click event
   */
  langRecord(): IPublicAnalyticsData {
    return {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.LOGIN_SCREEN,
      event_name: '',
      eventName: EVENT_NAME.LANGUAGE_DROPDOWN_CLICK
    };
  }
}
