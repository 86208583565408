 <div class="rply-wrapper">
  <div class="clearfix mt-2">
    <div class="float-left mr-2" [ngClass]="{'cursor-pointer':rcL?.is_anonymous!='1'}"
      (click)="viewrplycmmntprfl()"
      [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key3, "product_type": commentObj?.product_type, "product_id": rcL?.user_profile?.custom_id}'>
      <div class="position-relative">
        <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()" [profile_pic_image]="reply_comment_card_image"></app-user-pofile-pic>
      </div>
    </div>
    <div class="float-left pl-0 d-table w-100 rply-input-width min-height">
      <div class="rply-box">
        <p class="text-capitalize">
          <span class="paragraph" [ngClass]="{'cursor-pointer':rcL?.is_anonymous!='1'}"
            (click)="viewrplycmmntprfl()"
            [eventTracker]='{"category": callingfrom,"action": mls.googleEventText.reply_comment_card.key3, "product_type": commentObj?.product_type, "product_id": rcL?.user_profile?.custom_id}'>
            <span class="pr-2 color-grey-700">{{rcL?.user_profile?.full_name}}</span>
            <span class="ml-2" *ngIf="rcL?.is_anonymous!='1'"><span class="slash mr-1"></span>
              <span class="secondary-paragraph pl-1"
                *ngFor="let userSpe of rcL?.user_profile?.specialities">
                <span class="color-grey-500">{{(userSpe?.is_primary==1)?userSpe?.speciality_name:''}}</span></span>
            </span>
          </span>
        </p>
        <div *ngIf="rcL?.is_expert" class="mt-1"></div>
        <p *ngIf="(commentTag!=null || commentTag!='')">
          <span *ngFor="let tg of tag" href="javascript:void(0);"> <small class="iota-separator"></small>
            {{tg.tag_name}}</span>
        </p>
      </div>
    </div>
    <div class="float-right d-table min-height">
      <div class="rply-box">
        <p><!-- edit comment -->
          <span *ngIf="rcL?.user_profile?.custom_id==loginUserCustomeId" ngbDropdown
            class="d-inline-block float-right mt-1">
            <span (click)="false" rel="tooltipTop" data-container="body" title="" id="dropdownBasic1" ngbDropdownToggle
              class="position-relative cursor-pointer">
              <img src="assets/themes/icons/ngbdot.svg">
            </span>
            <div ngbDropdownMenu class="ngb-dropdown-list paragraph" aria-labelledby="dropdownBasic1">
              <a class="cursor-pointer" ngbDropdownItem (click)="updateComment()"
                [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key1, "product_type": commentObj?.product_type, "product_id": rcL?.user_profile?.custom_id}'>{{mls.languageText.reply_comment_card.key1}}
                <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key1)</span></a>
              <!-- delete -->
              <a class="cursor-pointer" ngbDropdownItem (click)="onDeleteComment()"
                [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key2, "product_type": commentObj?.product_type, "product_id": rcL?.user_profile?.custom_id}'
                (click)="onDeleteComment(indx,rcL?.comment_id,rindx,'c')">{{mls.languageText.reply_comment_card.key2}}
                <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key2)</span>
              </a>
            </div>
          </span>
        </p>
      </div>
    </div>

  </div>
  <!-- end  -->
  <div class="text-left">
    <div class="pad-left" id="comment-2">
      <div class="py-1 paragraph cursor-pointer">
        <app-product-comment-content-card 
          [cmt]="rcL?.comment" 
          [commentObject]="rcL" 
          [screenName]="callingfrom"
          [showChar]="200">
        </app-product-comment-content-card>
      </div>

      <!-- Like Activity Section -->
      <div class="img-container secondary-paragraph mt-1">
        <div class="row">
          <div class="col-12 ml-2 pr-0">
            <a [ngClass]="{'active color-black': rcL?.comment_like_status===1}" class="mr-2 cursor-pointer"
              (click)="productLike()"
              [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key5, "product_type": commentObj?.product_type, "product_id": rcL?.comment_id}'>
              <!--spinner  -->
            
              <img class="animation-dur" width="15px" height="15px" *ngIf="reply_comment_like_loader==true" src="assets/themes/images/primary-loader.gif" alt="loader" />
              <span *ngIf="rcL?.comment_like_status!=1 && reply_comment_like_loader!=true">
                <img width=16x height=16px src="assets/themes/icons/like-white.svg" alt="like" /></span>
              <span *ngIf="rcL?.comment_like_status===1 && reply_comment_like_loader!=true">
                <img width=16px height=16px  src="assets/themes/icons/like-black.svg" alt="liked" /></span>
                <span class="ml-1 color-grey-700" *ngIf="rcL?.total_comment_like==0">
                  {{mls.languageText.reply_comment_card.key3}}
                  <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key3)</span>
                </span>
                <span (click)="getProductLikeList()" *ngIf="rcL?.total_comment_like>0" class="ml-1" 
                  [ngClass]="rcL?.comment_like_status ? 'color-black' : 'color-grey-700' " [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key7, "product_type": commentObj?.product_type, "product_id": rcL?.comment_id}'>
                  {{rcL?.total_comment_like>1 ? rcL?.total_comment_like + ' '+mls.languageText.reply_comment_card.key5: '1 '+mls.languageText.reply_comment_card.key3}}
                  <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key5)(reply_comment_card.key3)</span>
                </span>
            </a>
            <a (click)="showReplyBox.emit(true)"
              [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key4, "product_type": commentObj?.product_type, "product_id": rcL?.comment_id}'
              class="cursor-pointer ml-3">
              <img width="16px" height="16px"  src="assets/themes/icons/reply.svg" alt="reply" />
              <span class="ml-1">{{mls.languageText.reply_comment_card.key6}}
                <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key6)</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Comment edit-->
  <div *ngIf="isModalShown" class="modal fade edit-comment-popup" id="editCommentModal" tabindex="-1" role="dialog"
    [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
    <div class="custom-theme-modal modal-dialog sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header px-4 pt-4">
          <span class="cursor-pointer float-right" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
            <img src="assets/themes/icons/cancel.svg" alt="close" />
          </span>
          <p class="paragraph">
            {{mls.languageText.reply_comment_card.key12}}<span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key12)</span>
          </p>
        </div>
        <div class="modal-body text-center">
          <div class="form-textarea" *ngIf="product_type!=7">
            <div #editableCommentContent id="editComment"
              class="editable h-auto reply-comment text-left"
              contenteditable="true" 
              data-placeholder="" 
              [innerHtml]="edit_comment"
              [(ngModel)]="edit_comment"
              (click)="checkToShowTutorial()"
              (input)="userInput($event)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)">
            </div>

            <button [disabled]="showColor==false"
              [ngClass]=" showColor ? 'btn-design small filled' : 'btn-design small filled-disabled'"
              (click)="onUpdateComment()" class="btn-design small mt-3"
              [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key9, "product_type": commentObj?.product_type, "product_id": rcL?.user_profile?.custom_id}'>{{mls.languageText.reply_comment_card.key11}}
              <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key11)</span>
            </button>
          </div>
          <app-tag-connections-pop-over *ngIf="isTagConnectionsEnabled" 
              [isInfoModalShown]="isTagConnectionsEnabled"
              [tutorialTitle]="mls?.languageText?.tutorial?.key15" 
              [tutorialMsg]="mls?.languageText?.tutorial?.key16"
              [tutorialCta]="mls?.languageText?.tutorial?.key17" 
              [isTutorialFor]="tutorialFor.TAG_CONNECTION"
              (emitStartRequest)="closeTagTutorial()"
              (emitHideModal)="closeTagTutorial()"
              [callingFrom]="callingfrom"
              [comment_box_obj]="rcL">
          </app-tag-connections-pop-over>
      
            <div *ngIf="isSuggestionListForTagEnabled">
              <app-reply-suggestion-list-for-tag-users 
                [fromCommentUpdate]=true
                [suggList]="tagSuggestionList" 
                [offSet]="tagOffSet"
                [finished]="tagSuggestionListfinished" 
                (getOffSetUserList)="getOffSetUserList($event)"
                (saveTaggedUserDataEmit)="saveTaggedUserDataEmit($event)">
              </app-reply-suggestion-list-for-tag-users>
            </div>




        </div>

      </div>
    </div>
  </div>
  <!--Eof Comment edit-->

  <app-confirm-modal 
    *ngIf="isConfirmModalShown==true" 
    (resetConfirmModal)="resetConfirmModal($event)"
    [isConfirmModalShown]="isConfirmModalShown" 
    [confirmInfo]="confirmInfo">
  </app-confirm-modal>

  <!-- like list popup start**************************** -->
  <app-product-like 
    *ngIf="showLikeListModal" 
    (resetLikeModalList)="resetLikeModalList($event)"
    (resetLike)="resetLike($event)" 
    [likeObj]="likeObj">
  </app-product-like>
  <!-- like list popup end ***************************** -->
 </div>