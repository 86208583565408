import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subscription, interval } from 'rxjs';

import { Ticker } from '../models/ticker';

/**
 * This component is used to show ticker on live webinar.
 */
@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit, OnDestroy {

  /**
   * Use to recevie ticker list data in array.
   */
  @Input() ticker: Ticker[];

  /**
   * Current time
   */
  currentTime: number;

  /**
   * Use to subscribe the getTime persecond.
   */
  private getTime: Subscription;

  /**
   * We calculate current time.
   */
  ngOnInit(): void {

    const source = interval(1000);
    this.getTime = source.subscribe(() => {
      this.currentTime = Math.round(Date.now() / 1000);
    });

  }

  /**
   * Use to unsubscribe getTime.
   */
  ngOnDestroy(): void {
    this.getTime.unsubscribe();
  }

}
