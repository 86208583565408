/**
 * Angular imports.
 */
import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

/**
 * Constant import.
 */
import { EVENT_NAME } from '../../analytics-event/analytics.constant';

/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { ImageGroupService } from '../../dataservices/master-service/image-group.service';
/**
 * Interface import.
 */
import { AnalyticsInterface } from '../../analytics-event/analytics.model';
/**
* Constant imports
 */
import { EDITORS_DETAIL, NUMBERS, PARTIAL_VERIFIED_USER_STATE } from '../../constants/app.constants';

/**
 * User Profile Pic Component.
 */
@Component({
  selector: 'app-user-pofile-pic',
  templateUrl: './user-pofile-pic.component.html',
  styleUrls: ['./user-pofile-pic.component.scss']
})
export class UserPofilePicComponent implements OnInit, OnChanges {
  @Input() profile_pic_image;
  @Input() hasChanged;

  @Input() isStoryAvailable = false;

  /**
   * Input decorator added to identify for component.
   */
  @Input() usedFor: string;

  /**
   * Key is used to show when conditions true with it for speaker list component.
   */
  public speakerList = 'speakers-list';

  /**
  * Analyticsdata object.
  */
  public analyticsData: AnalyticsInterface;
  /**
   * Constant for string editors_detail.
   */
  public editorChoice = EDITORS_DETAIL.EC_DETAIL;

  /**
   * Constant for numbers
   */
  public number = NUMBERS;
  public sort_name = '';
  public bg_color = '';
  /**
   * Partial Verified User State
   */
  public partial_verified_user_state = PARTIAL_VERIFIED_USER_STATE;

  /**
   * Screen name from where that component is called.
   */
  @Input() screenName?: string;

  /**
   * emit profile pic clicked
   */
  @Output() profilePicClicked = new EventEmitter();

  /**
   * Necessary instances.
   */
  constructor(public igs: ImageGroupService, private fcs: FunCollectionService) { }

  ngOnInit(): void {
    /**
     *Analytics profile object
     */
    if (this.screenName) {
      this.analyticsData = {
        'event_name': EVENT_NAME.GR_PROFILE_IMAGE_CLICK,
        'product_type': 0,
        'product_type_id': 0,
        'screen_name': this.screenName,
      };
    }
  }

  /**
   * makeColor.
   */
  makeColor(): void {
    let hash = 0;
    const name_str = this.profile_pic_image.prflfname.trim() + ' ' + this.profile_pic_image.prfllname.trim();
    for (let i = 0; i < name_str.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = name_str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line: no-bitwise
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    this.bg_color = colour;
    // console.log(this.bg_color)

  }

  /**
   * ngOnChanges.
   */
  ngOnChanges(): void {
    this.sort_name = this.profile_pic_image.prflfname.charAt(0) + '' + this.profile_pic_image.prfllname.charAt(0);
    this.makeColor();
  }

  /**
   * navigate To Profile.
   */
  navigateToProfile(): void {
    this.profilePicClicked.emit();
    if (this.profile_pic_image.is_action == true) {
      this.fcs.navigateToUserProfie(this.profile_pic_image.prflcid);
      return;
    }
  }
}

