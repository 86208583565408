/**
 * Angular imports.
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Service imports.
 */
import { GetService } from '../../dataservices/get.service';
import { DqChatPanelService } from '../dq-chat-panel.service';
import { MasterserviceService } from 'src/app/dataservices/master-service/masterservice.service';
import { PostApiService } from 'src/app/dataservices/post-api.service';
import { FunCollectionService } from 'src/app/common/common-functions/fun-collection.service';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsEventService } from 'src/app/analytics-event/analytics-event.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Class imports.
 */
import { SamvaadLanguages } from '../samvaad.language';
import { SamvaadConfig,  } from '../../onboarding/interface/global.model';
import { ChatDetailTimeTopic, GroupCreationSuccessFailure } from '../chat/modal/chat.interface';

/**
 * Constant imports.
 */
import { ACTION_ON, ACTION_TYPE, CHAT_VIEW_TYPE, CTA_TYPE, PIP_CLASS, PRODUCT_TYPE_CONSTANT, PRODUCT_TYPE_CONSTANT_NAME, SCREEN_NAME, TRANSACTION_TYPE } from './../../constants/app.constants';
import { EVENT_NAME } from './../../analytics-event/analytics.constant';
import { AnalyticsInterface, EventDataKey } from 'src/app/analytics-event/analytics.model';

/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';
import { DETAIL_ENTRY, DETAIL_EXIT, ENTRY, ERROR, EXIT, LIST_ENTRY, LIST_EXIT, NAVIGATION_BAR_CLICK, SUBMIT, SUCCESS } from 'analytics2.0/dist/core/constants/analytic.interface';
import { CHAT_VIEW } from '../chat/lib/constants/chat.constants';
import { Action } from 'rxjs/internal/scheduler/Action';

/**
 * Chat panel for alway on when user login contains the chat panel.
 */
@Component({
  selector: 'app-dq-chat-panel',
  templateUrl: './dq-chat-panel.component.html',
  styleUrls: ['./dq-chat-panel.component.scss']
})
export class DqChatPanelComponent implements OnInit, OnDestroy {

  @ViewChild('libChat') libChat;

  /**
   * Samvaad token for the chat panel login.
   * Close chat in case of no samvaad token.
   * Is report to show the reporting.
   * Is chat detail expanded/Minimize flag.
   */
  public samToken: string;
  public authToken: string;
  public closeChat = false;
  public isReport = false;
  public isChatListExpanded = false;
  public isChatListHide = false;
  public reportProductId = '0';
  public reportProductType: number;
  public permission = '';
  public samvaadLanguageText = new SamvaadLanguages(this.mls)?.languageText;
  public productType = PRODUCT_TYPE_CONSTANT;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  public displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: []
  };
  public samvaadConfig: SamvaadConfig;
  public chatInfo;

  /**
   * Analyticsdata object.
   */
  private analyticsData: AnalyticsInterface;
  private productTypeId = 0;
  private chatListStartTime: Date;

  /**
   * Necessary instances created.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getService: GetService,
    public dqChatPanelService: DqChatPanelService,
    public masterService: MasterserviceService,
    public deviceService: DeviceDetectorService,
    private postSer: PostApiService,
    private fcs: FunCollectionService,
    public mls: NewLanguageService,
    private analyticSdk: Analytics) {
  }

  /**
   * Chat initiate api call.
   * Flag for the expand chat.
   */
  ngOnInit(): void {
    this.subscriptions.add(this.masterService.getProfile()
      .subscribe(value => {
        if (value.code === 2000 && value.status === 1) {
          this.permission = value.data.profile.permission;
          this.closeChat = value.data.chat_allow ? false : true;
          if (this.closeChat) {
            this.samvaadConfig = undefined;
          } else {
            if (this.masterService?.masterApiData?.code === 2000) {
              this.chatInfo = this.masterService?.masterApiData?.data.page_info?.chat;
              if (this.chatInfo) {
                this.samvaadConfig = {
                  appName: this.chatInfo.samvaad.app_name,
                  host: this.chatInfo.samvaad.host,
                  apiKey: this.chatInfo.samvaad.session_key,
                  transport: this.chatInfo.samvaad.transport,
                  secure: this.chatInfo.samvaad.secure ? true : false,
                  platform: 'web',
                  persist: this.chatInfo.samvaad.persist ? true : false
                };
              } else {
                this.samvaadConfig = undefined;
              }
            }
          }
        }
      }));
    this.getOtherUserChatId();
    this.subscriptions.add(this.dqChatPanelService.isChatListHide
      .subscribe(value => {
        this.isChatListHide = value;
        this.closeChat = false;
      }));
    if (!this.closeChat) {
      this.getSamToken();
    }
  }

  /**
   * Get the sam token.
   */
  getSamToken(): void {
    const auth = JSON.parse(this.getSamTokenFromLocalStorage());
    if (!auth) {
      this.requestSamToken();
    } else {
      this.checkExpiryTime(auth);
    }
    this.subscriptions.add(this.dqChatPanelService.expandChat
      .subscribe(value => {
        if (value) {
          this.isChatListExpanded = false;
        }
      }));
  }

  /**
   * Start the user chat from the website chat icon.
   */
  getOtherUserChatId(): void {
    this.fcs.chatUserId
      .subscribe(detail => {
        if (detail.isNotify) {
          this.openChatDetailByTopicId(detail.uuid);
          return;
        }
        if (detail.uuid) {
          this.libChat.startNewChat({ uuid: detail.uuid });
        }
      });
  }

  /**
   * Check the expiry time for the token.
   */
  checkExpiryTime(auth): void {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(auth?.params?.expires).getTime();
    if (currentTime > (expiryTime - 100000)) {
      this.requestSamToken();
    } else {
      this.authToken = auth?.params?.token;
    }
  }

  /**
   * Request sam token in case of no auth available.
   */
  requestSamToken(): void {
    if(this.mls.featureOnOffList.featureList.chat.value || !this.mls.userLoginFlow) {
    if (this.permission === 'all') {
      this.subscriptions.add(this.getService.chatInitiate()
        .subscribe(res => {
          if (res.data && res.data.samToken) {
            this.samToken = res.data.samToken;
          } else {
            this.closeChat = true;
          }
        }));
    }}
  }

  /**
   * Get the samvaad auth from the local storage.
   */
  getSamTokenFromLocalStorage(): string {
    return localStorage.getItem('samvaadAuthData');
  }

  /**
   * Navigate to the samvaad detail.
   */
  goToSamvaadDetail(): void {
    this.onChatExpandButtonClick();
    this.router.navigate(['/samvaad/list']);
  }

  /**
   * Hide the report modal.
   */
  onReportHidden(): void {
    this.isReport = false;
  }

  /**
   * Navigate to the user profile.
   */
  redirectToProfile(uuid): void {
    this.router.navigate(['/webprofile/view/info/', uuid], { queryParams: { uuid: true } });
  }

  /**
   * Get the chat list expand flag.
   */
  onChatListExpanded(status: boolean): void {
    this.isChatListExpanded = status;
    if (this.isChatListExpanded) {
      if (this.chatListStartTime) {
        this.onChatListLeave(new Date());
      }
    } else {
      this.onChatListVisit(new Date());
    }
  }

  /**
   * Get the chat list hide flag.
   */
  OnChatListHide(status: boolean): void {
    this.isChatListHide = status;
  }

  /**
   * Get the unread count.
   */
  onUnreadCount(count: number): void {
    this.dqChatPanelService.unreadNotification.next(count);
  }

  /**
   * Get the reported user/group.
   */
  onReportUser(info: { id: string, type: number }): void {
    this.reportProductId = info.id;
    this.reportProductType = info.type ? PRODUCT_TYPE_CONSTANT.CHAT : PRODUCT_TYPE_CONSTANT.USER;
    this.isReport = true;
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT,info.id,SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.REPORT,null, new Date().getTime(), extra);
  }

  /**
   * Check if user is verified or not.
   */
  checkVerification(): void {
    this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0)
      .subscribe(resData => {
        if (resData.code === 2000 && resData.status === 1) {
          this.displayObj.isShow = true;
          this.displayObj.display_section = 'verificationSuccess';
          this.displayObj.data = resData.data;
          this.displayObj.errObj = [];
          this.masterService.showWebModal.next(this.displayObj);
        } else {
          this.fcs.errorHandeler(resData, 'generate_certificate');
          return;
        }
      }));
  }

  /**
   * Set the close chat in case of failure.
   */
  setCloseChat(status: boolean): void {
    if (!status) {
      const queryParams = this.route?.snapshot?.queryParams;
      const topicId = queryParams ? queryParams['id'] : '';
      if (topicId) {
        this.openChatDetailByTopicId(topicId);
        this.router.navigateByUrl('/samvaad/list');
      }
    }
    this.closeChat = status;
  }

  /**
   * Open the chat detail using the topic id.
   */
  openChatDetailByTopicId(topicId: string): void {
    this.libChat.getTopicDetail({ topicId: topicId });
  }

  /**
   * Send chat detail visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatDetailVisit(event: ChatDetailTimeTopic): void {
    document.body?.classList?.add(PIP_CLASS);
    const entryData: DETAIL_ENTRY = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic,
      current_screen_name: SCREEN_NAME.CHAT_DETAIL,
      source: SCREEN_NAME.CHAT_LIST,
      start_time: event.date.getTime()
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entryData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.DETAIL_ENTRY(entryData);
  }

  /**
   * Send chat detail leave analytic.
   */
  onChatDetailLeave(event: ChatDetailTimeTopic): void {
    document.body?.classList?.remove(PIP_CLASS);
    const exitData : DETAIL_EXIT = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic,
      current_screen_name: SCREEN_NAME.CHAT_DETAIL,
      start_time: event.date.getTime(),
      end_time: new Date().getTime(),
      extra:  [ {key: 'VIEW_TYPE',value: this.getChatListViewType()}]
    }
    this.analyticSdk.analyticService.DETAIL_EXIT(exitData);
  }

  /**
   * 
   * @param event event for chat detail switch
   */
  chatDetailSwitched(event: {date: Date, previousTopic: string, currentTopic: string}) {
    this.onChatDetailLeave({date: event.date, topic: event.previousTopic});
    this.onChatDetailVisit({date: event.date, topic: event.currentTopic});
  }

  /**
   * Send chat list visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatListVisit(event: Date): void {
    this.chatListStartTime = new Date();
    const entrydata: LIST_ENTRY = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      source: this.fcs.currentScreename ? this.fcs.currentScreename : localStorage.getItem('triggerSource'),
      current_screen_name: SCREEN_NAME.CHAT_LIST,
      start_time: event.getTime(),
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entrydata.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.LIST_ENTRY(entrydata);
  }

  /**
   * get view type
   */
  getChatListViewType(): string {
    return this.dqChatPanelService.isChatPanelVisible ? CHAT_VIEW_TYPE.MINIMISED_VIEW :  CHAT_VIEW_TYPE.EXPANDED_VIEW;
  }

  /**z
   * Send chat bar click analytic.
   */
  onChatBarClick(): void {
    const clickData: NAVIGATION_BAR_CLICK = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      current_screen_name: this.fcs.currentScreename,
      start_time: new Date().getTime(),
      position: '',
      cta_type: CTA_TYPE.TAB,
      action_type: ACTION_TYPE.CLICK,
      action_on: ACTION_ON.CHAT,

    }
    this.analyticSdk.analyticService.NAVIGATION_BAR_CLICK(clickData);
  }

  /**
   * Send chat list leave analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatListLeave(event: Date): void {
    const listExitData: LIST_EXIT = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      start_time: this.chatListStartTime?.getTime(),
      end_time: new Date().getTime(),
      current_screen_name :  SCREEN_NAME.CHAT_LIST
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      listExitData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.LIST_EXIT(listExitData);
  }

  /**
   * Send new chat visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNewChatVisit(event: Date): void {
    const entryData: ENTRY = {
      entity_type:PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      start_time: event.getTime(),
      source: SCREEN_NAME.CHAT_LIST,
      current_screen_name: SCREEN_NAME.CHATS_SEARCH_DOCTORS
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entryData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.ENTRY(entryData);
  }

  /**
   * Send new chat leave analytic.
   */
  onNewChatLeave(event: Date): void {
    const exitData: EXIT = {
      entity_type:PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      start_time: event.getTime(),
      current_screen_name: SCREEN_NAME.CHATS_SEARCH_DOCTORS,
      end_time: new Date().getTime()
      }
      if (!this.deviceService.isMobile()) {
        const viewType = this.getChatListViewType();
        exitData.extra = [
          {key: 'VIEW_TYPE',
          value: viewType}
        ];
      }
    this.analyticSdk.analyticService.EXIT(exitData);
  }

  /**
   * Send create group leave analytic.
   */
  onGroupLeave(event: ChatDetailTimeTopic): void {
    const exitData: EXIT = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic ? event.topic : 0,
      current_screen_name: SCREEN_NAME.GROUP_WINDOW,
      start_time: event.date.getTime(),
      end_time: new Date().getTime()
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      exitData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.EXIT(exitData);
  }

  /**
   * Send create group visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGroupVisit(event: ChatDetailTimeTopic): void {
    const entryData : ENTRY = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic ?  event.topic : 0 ,
      current_screen_name : SCREEN_NAME.GROUP_WINDOW,
      start_time: event.date.getTime(),
      source: this.fcs.currentScreename ? this.fcs.currentScreename : localStorage.getItem('triggerSource')
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entryData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.ENTRY(entryData);
  }

  /**
   * Send new member entry analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNewMemberVisit(event: ChatDetailTimeTopic): void {
    const entryData : ENTRY = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic ? event.topic : 0,
      current_screen_name : SCREEN_NAME.GROUP_MEMBERS,
      start_time: event.date.getTime(),
      source: SCREEN_NAME.GROUP_WINDOW
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entryData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.ENTRY(entryData);
  }

  /**
   * Send new member leave analytic.
   */
  onNewMemberLeave(event: ChatDetailTimeTopic): void {
    const entryData : EXIT = {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: event.topic ? event.topic : 0,
      current_screen_name : SCREEN_NAME.GROUP_MEMBERS,
      start_time: event.date.getTime(),
      end_time: new Date().getTime()
    }
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      entryData.extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.EXIT(entryData);
  }

  /**
   * Send start new chat click analytic.
   */
  onStartNewChatClick(): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, 0, this.fcs.currentScreename, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.START_MESSAGING,null, new Date().getTime(),extra);
  }
  /**
   * send start new chat analytic by clicking plus icon
   */
  onStartNewChatClickFromPlus(): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, 0,SCREEN_NAME.CHAT_LIST , CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.CREATE_PERSONAL_CHAT,null, new Date().getTime(),extra);
  }

  /**
   * Send message click analytic.
   */
  onSendClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.SEND_MESSAGE, null, new Date().getTime(),extra)
  }

  /**
   * Send create new group click analytic.
   */
  onCreateNewGroupClick(): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, 0,SCREEN_NAME.CHAT_LIST, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.CREATE_GROUP_CHAT,null, new Date().getTime(),extra);
  }
  /**
   * when user clicks on done after adding members in group
   */
  onGroupAddMemberDone(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event,SCREEN_NAME.GROUP_MEMBERS, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.DONE,null, new Date().getTime(),extra );
  }

  /**
   * create group success failure event
   */
  onCreateGroupSuccesFailure(event: GroupCreationSuccessFailure): void {
    if (event.success) {
      const eventData : SUCCESS =  {
        current_screen_name: SCREEN_NAME.GROUP_WINDOW,
        start_time: new Date().getTime(),
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
        entity_code: event.topicId,
        transaction_type: TRANSACTION_TYPE.GROUP_CREATION
      }
      this.analyticSdk.analyticService.SUCCESS(eventData);
    }
    else {
      const eventData : ERROR = {
        current_screen_name: SCREEN_NAME.GROUP_WINDOW,
        start_time: new Date().getTime(),
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
        entity_code: 0,
        transaction_type: TRANSACTION_TYPE.GROUP_CREATION,
        type: event.topicId
      }
      this.analyticSdk.analyticService.ERROR(eventData)
    }
  }

  /**
   * Send  create new group click analytic.
   */
  onCreateGroupDoneClick(): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    const submitData: SUBMIT= {
      entity_type: PRODUCT_TYPE_CONSTANT_NAME.CHAT,
      entity_code: 0,
      current_screen_name: SCREEN_NAME.GROUP_WINDOW,
      start_time: new Date().getTime(),
      action_on: ACTION_ON.GROUP_CREATION,
      cta_type: CTA_TYPE.BUTTON,
      action_type: ACTION_TYPE.CLICK
    }
    submitData.extra = extra
    this.analyticSdk.analyticService.SUBMIT(submitData);
  }

  /**
   * Send chat expand button click analytic.
   */
  onChatExpandButtonClick(): void {
    this.onChatListLeave(new Date());
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, 0, this.fcs.currentScreename, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.EXPAND_VIEW, null);
  }
  /**
   * event on clicking search icon
   */
  onSearchIconClicked(): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT,0, SCREEN_NAME.CHAT_LIST, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.SEARCH_TOPIC, null, new Date().getTime(), extra);
  }

  onViewProfileClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
       extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT,event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.VIEW_TOPIC_INFO, null, new Date().getTime(), extra);
  }
  /**
   * Send attachment click analytic.
   */
  onAttachmentClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.BUTTON, ACTION_TYPE.CLICK, ACTION_ON.ATTACH_MEDIA, null, new Date().getTime(), extra);
  }

  /**
   * Send mute notification click analytic.
   */
  onMuteNotificationClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.MUTE_INBOX_NOTIF, null, new Date().getTime(), extra)
  }

  /**
   * Send block user click analytic.
   */
  onBlockUserClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.BLOCK, null, new Date().getTime(), extra);
  }

  /**
   * Send unmute notification click analytic.
   */
  onUnmuteNotificationClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.UNMUTE_INBOX_NOTIF, null,  new Date().getTime(), extra);
  }

  /**
   * Send unblock user click analytic.
   */
  onUnblockUserClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.UNBLOCK, null,  new Date().getTime(), extra);
  }

  /**
   * Send exit group click analytic.
   */
  onExitGroupClick(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.CHAT_DETAIL, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.EXIT_GROUP, null,new Date().getTime(), extra);
  }

  /**
   * Send when member removed from the group analytic.
   */
  onMemberRemoveFromGroup(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.GROUP_INFO, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.REMOVE_MEMBER, null,new Date().getTime(), extra)
  }

  /**
   * Send when member removed from the group analytic.
   */
  onAddDoctorInGroup(event: string): void {
    let extra = [];
    if (!this.deviceService.isMobile()) {
      const viewType = this.getChatListViewType();
      extra = [
        {key: 'VIEW_TYPE',
        value: viewType}
      ];
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CHAT, event, SCREEN_NAME.GROUP_INFO, CTA_TYPE.MORE_OPTION, ACTION_TYPE.CLICK, ACTION_ON.ADD_MEMBER, null, new Date().getTime(), extra);
  }

  /**
   * Send when member removed from the group analytic.
   */
  onFailedMsg(): void {
  }

  /**
   * Check for the minimize or maximize chat view.
   */
  checkForMiniOrMaxView(first: string, second: string): string {
    return this.dqChatPanelService.isChatPanelVisible ? first : second;
  }


  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * Back to history.
   */
  backToHistory(): void {
    history.back();
  }

  /**
   * Reset samvaad chat.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetChat(code: number): void {
    this.closeChat = true;
    this.samToken = '';
    this.authToken = '';
    if (localStorage.getItem('samvaadAuthData')) {
      localStorage.removeItem('samvaadAuthData');
    }
    this.getSamToken();
    this.closeChat = false;
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
