/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/**
 * Validation imports.
 */
import { NoSpaceValidator } from 'src/app/validators/noSpaceValidator/noSpace.validator';

/**
 * Service imports.
 */
import { ChatService } from '../../chat.service';

/**
 * Constant imports.
 */
import { API_AUTH_TOKEN, API_KEY_MEDIA, BANNER_FILE_LIMIT, CHAT_VIEW, CONNECTION, FILE_UPLOAD_EXTENSION, FILE_UPLOAD_SIZE, GROUP_TITLE_MAX_LENGTH, HEADERS, MAX_GROUP_MEMBERS_LIMIT, ONE, ZERO } from '../../constants/chat.constants';
import { CHAT_API } from '../../constants/chat-api.constant';

/**
 * Rxjs imports.
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChatDetailTimeTopic } from '../../../modal/chat.interface';

/**
 * Chat group create/edit form for the chat panel.
 */
@Component({
  selector: 'app-chat-group-creation',
  templateUrl: './chat-group-creation.component.html',
  styleUrls: ['./chat-group-creation.component.scss']
})
export class ChatGroupCreationComponent implements OnInit, OnDestroy {

  /**
   * Change upload image from Dom.
   */
  @ViewChild('bannerInput') bannerInput;

  /**
   * Chat group create/edit form for the chat panel.
   * Get the group detail for the edit.
   * Loading flag for the char group.
   */
  @Input() groupMembers = [];
  @Input() groupDetail;
  @Input() chatGroupLoading: boolean;
  @Input() chatGroupMiniLoading: boolean;
  @Input() isRemoveConfirm: boolean;
  @Input() isViewMode: boolean;
  @Input() authToken: string | number | boolean;
  @Input() groupTopic: string;
  @Input() imageSrc = '';
  @Input() samvaadLanguageText: any = {};
  @Input() chatView: number;
  @Input() hostKey: string;
  @Input() apiKey: string;

  /**
   * Emit the go back from the group creation.
   * Emit the create group submit.
   * Emit the edit group submit.
   * Emit the upload group pic submit.
  */
  @Output() onGoBack: EventEmitter<void> = new EventEmitter();
  @Output() emitGroupFormSubmit: EventEmitter<any> = new EventEmitter();
  @Output() emitGroupEditFormSubmit: EventEmitter<any> = new EventEmitter();
  @Output() emitUploadGroupPic: EventEmitter<any> = new EventEmitter();
  @Output() emitCreationGroupPic: EventEmitter<any> = new EventEmitter();
  @Output() emitRemoveUser: EventEmitter<any> = new EventEmitter();
  @Output() onAvatarClick: EventEmitter<{ customId: string }> = new EventEmitter();
  @Output() onVisit: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onLeave: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onNewMemberVisit: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onNewMemberLeave: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onAddDoctorInGroup: EventEmitter<string> = new EventEmitter();
  @Output() emitSearchInputClick: EventEmitter<void> = new EventEmitter();
  @Output() onMaxLimitEvent: EventEmitter<number> = new EventEmitter();
  @Output() onGroupAddMemberDone: EventEmitter<string> = new EventEmitter()

  /**
   * Chat group form.
   * Search user popup show/hide.
   * Upload group pic preview show/hide.
   * Search user offset for the api.
   * Search user list from the api.
   * Group title in case of creation.
   * Group title in case of edit.
   * Image preview for group pic.
   * Group title max length
  */
  public chatGroupForm!: FormGroup;
  public isSearchUser = false
  public isProfileUploadPreview = false
  public searchUserOffset: number;
  public searchUsersList: any;
  public groupTitle = this.samvaadLanguageText?.samvaad_create_edit_group?.key14;
  public submitBtn = this.samvaadLanguageText?.samvaad_create_edit_group?.key14;
  public groupTitleMaxLength = GROUP_TITLE_MAX_LENGTH;
  public fileUploadSize = FILE_UPLOAD_SIZE;
  public fileUploadExtensions = FILE_UPLOAD_EXTENSION;
  public fileLimits = BANNER_FILE_LIMIT;
  public newMembers = [];
  public newMembers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public removeMember: any;
  public searchUserLoading: boolean;
  public chatViewConstant = CHAT_VIEW;
  public maxLimit = MAX_GROUP_MEMBERS_LIMIT;

  private subscriptions: Subscription = new Subscription();
  private groupStartTime: Date;

  /**
   * Email formControl for input validation.
   */
  get name(): any | null { return this.chatGroupForm.get('name'); }
  get banner(): any | null { return this.chatGroupForm.get('banner'); }

  /**
   * Create necessary instances.
   */
  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService
  ) { }

  /**
   * Get the group detail.
   * Initialize the group chat.
   */
  ngOnInit(): void {
    this.emitOnVisit();
    this.groupTitle = this.samvaadLanguageText?.samvaad_create_edit_group?.key14;
    this.submitBtn = this.samvaadLanguageText?.samvaad_create_edit_group?.key14;
    this.subscriptions.add(this.newMembers$
      .subscribe(value => {
        if (value) {
          this.newMembers = value;
        }
      }));
    if (this.groupDetail) {
      this.groupMembers = this.groupDetail.members;
      this.groupTitle = this.samvaadLanguageText?.samvaad_create_edit_group?.key12;
      this.submitBtn = this.samvaadLanguageText?.samvaad_create_edit_group?.key12;
      if (this.isViewMode) {
        this.groupTitle = this.samvaadLanguageText?.samvaad_create_edit_group?.key13;
      }
    }
    this.chatGroupForm = this.formBuilder.group({
      banner: [this.groupDetail ? this.groupDetail.banner : ''],
      name: [this.groupDetail ? this.groupDetail.name : '', [Validators.required, Validators.maxLength(this.groupTitleMaxLength), NoSpaceValidator]],
      members: [...this.newMembers]
    });
  }

  /**
   * Emit the entry event outside.
   */
  emitOnVisit(): void {
    this.groupStartTime = new Date();
    this.onVisit.emit({
      date: this.groupStartTime,
      topic: this.groupDetail?.topicId
    });
  }

  /**
   * Emit the leave event outside.
   */
  emitOnLeave(): void {
    this.onLeave.emit({date: this.groupStartTime, topic: this.groupDetail?.topicId});
  }

  /**
   * Emit the go back.
   */
  goBack(): void {
    this.onGoBack.emit();
  }

  /**
   * Used to receive the selected image file.
   */
  setBanner(event): void {
    this.imageSrc = '';
    const file = event[0].selectedFile;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
      this.banner.setValue([{ banner: this.imageSrc, caption: '', file: file }]);
      this.isProfileUploadPreview = true;
    };
  }

  /**
   * Remove the input field image.
   */
  closeImage(): void {
    if (this.bannerInput) {
      this.bannerInput.nativeElement.value = '';
    }
    this.imageSrc = '';
    this.groupDetail?.banner ? this.groupDetail.banner = '' : '';
  }

  /**
   * Add users start the search user popup.
   */
  AddUsers(): void {
    // this.isSearchUser = true;
    this.emitAddDoctorInGroup(this.groupDetail?.topicId);
    this.toggleChatGroupMiniLoading(true);
    this.searchUsers();
  }

  /**
   * Toggle the chat group loading.
   */
  toggleChatGroupLoading(status: boolean): void {
    this.chatGroupLoading = status;
  }

  /**
   * Toggle the chat group loading.
   */
  toggleChatGroupMiniLoading(status: boolean): void {
    this.chatGroupMiniLoading = status;
  }

  /**
   * Search the users foe adding in the group.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  searchUsers(keyword = '', key = '', offset = ONE): void {
    this.searchUserLoading = true;
    this.searchUserOffset = offset;
    const url = CHAT_API.CHAT_SEARCH_LIST;
    const params = {
      offset: offset,
      speciality_id: ZERO.toString(),
      association_id: ZERO.toString(),
      search_type: CONNECTION,
      is_suggestion: ZERO,
      keyword: keyword,
      is_group: '',
      filter: ''
    };
    const headers = {
      ver: HEADERS.VER_4_3
    }
    this.chatService.searchUser(url, params, headers)
      .subscribe(list => {
        if (this.searchUserOffset > ONE) {
          if(list?.data.search_list[ZERO]?.list) {
            this.searchUsersList[ZERO].list = this.searchUsersList[ZERO]?.list.concat(list?.data?.search_list[ZERO]?.list);
          }
        } else {
          this.searchUsersList = list?.data?.search_list;
        }
        for (const members of this.groupMembers) {
          if (this.searchUsersList[ZERO] && this.searchUsersList[ZERO].list) {
            this.searchUsersList[ZERO]?.list.map(value => value.uuid === members.uuid ? value.isDisabled = ONE : '');
          }
        }
        this.searchUserOffset = list.data.offset;
        this.isSearchUser = true;
        this.toggleChatGroupMiniLoading(false);
        this.searchUserLoading = false;
      });
  }

  /**
   * Get the group members list.
   */
  getGroupList(list: any): void {
    this.isSearchUser = false;
    this.onGroupAddMemberDone.emit(this.groupDetail?.topicId);
    this.newMembers$.next(list);
  }

  /**
   * Remove the members from the list.
   */
  removeGroupMember(member: any): void {
    this.removeMember = null;
    this.isRemoveConfirm = true;
    this.removeMember = member;
  }

  /**
   * Remove the new added members from the list.
   */
  removeNewMembers(member: any): void {
    this.newMembers = [...this.newMembers.filter(user => user !== member)];
  }

  /**
   * Emit the remove member member.
   */
  onRemove(): void {
    this.emitRemoveUser.emit({ member: this.removeMember, topicId: this.groupDetail?.topicId });
    this.isRemoveConfirm = false;
  }

  /**
   * Update the group pic.
   */
  uploadGroupPic(event: any): void {
    if (this.groupDetail) {
      this.emitUploadGroupPic.emit({ files: event, topicId: this.groupDetail?.topicId });
    } else {
      this.banner.setValue(event);
    }
    this.isProfileUploadPreview = false;
  }

  /**
   * Emit close chat flag.
   */
  createGroup(): void {
    this.toggleChatGroupLoading(true);
    this.chatGroupForm.setValue({
      banner: this.banner.value,
      name: this.chatGroupForm.get('name').value.trim(),
      members: [...this.newMembers]
    });
    /**
     * stop here if form is invalid
     */
    if (this.chatGroupForm.invalid || (this.groupMembers?.length > ONE ? false : !this.newMembers?.length)) {
      this.toggleChatGroupLoading(false);
      return;
    }
    if (!this.groupDetail) {
      this.emitGroupFormSubmit.emit(this.chatGroupForm.value);
    } else {
      this.emitGroupEditFormSubmit.emit({ groupDetail: this.chatGroupForm.value, topicId: this.groupDetail?.topicId });
    }
  }

  /**
   * Get the sanitize dp.
   */
  dpSanitize(image: any): string {
    if (image?.ref) {
      const authToken = encodeURIComponent(this.authToken);
      return CHAT_API.BASE_URL + this.hostKey + image.ref + API_KEY_MEDIA + this.apiKey + API_AUTH_TOKEN + authToken;
    }
  }

  /**
   * Emit the avatar click event.
   */
  emitAvatarClick(topicId: string): void {
    this.onAvatarClick.emit({ customId: topicId });
  }

  /**
   * Emit the new member entry event.
   */
  emitOnNewMemberVisit(event: Date): void {
    this.onNewMemberVisit.emit({date: event, topic: this.groupDetail?.topicId});
  }

  /**
   * Emit the new member exit event.
   */
  emitOnNewMemberLeave(event: Date): void {
    this.onNewMemberLeave.emit({date: event, topic: this.groupDetail?.topicId});
  }

  /**
   * Emit the add doctor click event.
   */
  emitAddDoctorInGroup(event: string): void {
    this.onAddDoctorInGroup.emit(event);
  }

  searchInputClick(): void {
    this.emitSearchInputClick.emit();
  }

  /**
   * Emit the max members limit reached event.
   */
  emitMaxLimitEvent(event: number): void {
    this.onMaxLimitEvent.emit(event);
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    this.emitOnLeave();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
