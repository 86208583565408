/**
 * Prefix for the data test id in directive.
 */
export const DATA_TEST_ID_PREFIX = 'data-testId';

/**
 * Test data id name for the feed detail.
 */
export const FEED_DETAIL = {
  FEED_DETAIL_TITLE: 'feed-detail-title',
  FEED_DETAIL_DESCRIPTION: 'feed-detail-description',
  FEED_DETAIL_SPECIALITY: 'feed-detail-speciality',
  FEED_DETAIL_TAG_USER: 'feed-detail-Tag-user',
  FEED_DETAIL_LIKE_RIGHT: 'feed-detail-like-right',
  FEED_DETAIL_BOOKMARK_RIGHT: 'feed-detail-bookmark-right',
  FEED_DETAIL_SHARE_RIGHT: 'feed-detail-share-right',
  FEED_DETAIL_MEDIA: 'feed-detail-media',
  FEED_DETAIL_LIKE_LEFT: 'feed-detail-like-left',
  FEED_DETAIL_BOOKMARK_LEFT: 'feed-detail-bookmark-left',
  FEED_DETAIL_SHARE_LEFT: 'feed-detail-share-left'
};

/**
 * Test data id name for the comment box.
 */
export const COMMENT = {
  COMMENT_BOX_SEND: 'comment-box-send'
};

/**
 * Test data id name for the channel user card.
 */
export const CHANNEL_USER_CARD = {
  CHANNEL_USER_CARD_TITLE: 'channel-user-card-title',
  CHANNEL_USER_CARD_POWERED_BY: 'channel-user-card-powered-by'
};

/**
 * Test data id name for the search filter.
 */
export const SEARCH_FILTER = {
  SEARCH_FILTER_NAME: 'search-filter-name'
};

/**
 * Test data id name for the search list.
 */
export const SEARCH_LIST = {
  SEARCH_LIST_TEXT_HIGHLIGHT: 'search-list-text-highlight'
};

/**
 * Test data id name for the search feed card.
 */
export const SEARCH_FEED_CARD = {
  SEARCH_FEED_CARD_MEDIA: 'search-feed-card-media',
  SEARCH_FEED_CARD_DESCRIPTION: 'search-feed-card-description'
};

/**
 * Test data id name for the search clinical card.
 */
export const SEARCH_CLINICAL_CARD = {
  SEARCH_CLINICAL_CARD_ARTICLE_TITLE: 'search-clinical-card-article-title'
};

/**
 * Test data id name for the explore section for the home page.
 */
export const EXPLORE_SECTION_HAMBURGER = {
  EXPLORE_SECTION_HAMBURGER_MENU: 'explore-section-hamburger-menu'
};

/**
 * Test data id name for the online events.
 */
export const ONLINE_EVENTS = {
  ONLINE_EVENTS_TITLE: 'online-events-title',
  ONLINE_EVENTS_TYPES_OF_EVENT: 'online-events-type-of-event',
  ONLINE_EVENTS_FILTER_UPCOMING: 'online-events-filter-upcoming',
  ONLINE_EVENTS_FILTER_ATTENDING: 'online-events-filter-attending',
  ONLINE_EVENTS_FILTER_PAST: 'online-events-filter-past',
  ONLINE_EVENTS_FILTER_ATTENDED: 'online-events-filter-attending'
};

/**
 * Test data id name for the online event card.
 */
export const ONLINE_EVENT_CARD = {
  ONLINE_EVENT_CARD_BANNER: 'online-events-card-banner',
  ONLINE_EVENT_CARD_DATE: 'online-events-card-date',
  ONLINE_EVENT_CARD_LOCATION: 'online-events-card-location',
  ONLINE_EVENT_CARD_ADDRESS: 'online-events-card-address',
  ONLINE_EVENT_CARD_REGISTERED: 'online-events-card-registered',
  ONLINE_EVENT_CARD_CME_CREDITS: 'online-events-card-cme-credits',
  ONLINE_EVENT_CARD_NO_CREDITS: 'online-events-card-no-credits',
  ONLINE_EVENT_CARD_CREDITS_LABEL: 'online-events-card-credits-label',
  ONLINE_EVENT_CARD_CREDITS_STATUS: 'online-events-card-credits-status',
  ONLINE_EVENT_CARD_ITINERARY_BUTTON: 'online-events-card-itinerary-button',
  ONLINE_EVENT_CARD_DETAIL_BUTTON: 'online-events-card-detail-button',
  ONLINE_EVENT_CARD_MARK_GOING_BUTTON: 'online-events-card-mark-going-button',
  ONLINE_EVENT_CARD_SHARE_BUTTON: 'online-events-card-share-button',
};

/**
 * Test data id name for the online event itinerary.
 */
export const ONLINE_EVENT_ITINERARY = {
  ONLINE_EVENT_ITINERARY_HEADING: 'online-events-itinerary-heading'
};

/**
 * Test data id name for the online event session.
 */
export const ONLINE_EVENT_SESSION = {
  ONLINE_EVENT_SESSION_CARD_TITLE: 'online-events-session-card-title'
};

/**
 * Test data id name for the online event detail.
 */
export const ONLINE_EVENTS_DETAIL = {
  ONLINE_EVENTS_DETAIL_HEADING: 'online-events-detail-heading',
  ONLINE_EVENTS_DETAIL_BANNER: 'online-events-detail-banner',
  ONLINE_EVENTS_DETAIL_SPECIALITY: 'online-events-detail-speciality',
  ONLINE_EVENTS_DETAIL_TITLE: 'online-events-detail-title',
  ONLINE_EVENTS_DETAIL_DATE: 'online-events-detail-date',
  ONLINE_EVENTS_DETAIL_LOCATION: 'online-events-detail-location',
  ONLINE_EVENTS_DETAIL_HOSTED_BY: 'online-events-detail-hosted-by',
  ONLINE_EVENTS_DETAIL_REGISTERED: 'online-events-detail-registered',
  ONLINE_EVENTS_DETAIL_CME_CREDITS: 'online-events-detail-cme-credits',
  ONLINE_EVENTS_DETAIL_CREDITS_STATUS: 'online-events-detail-credits-status',
  ONLINE_EVENTS_DETAIL_ITINERARY_HEADING: 'online-events-detail-itinerary-heading',
  ONLINE_EVENTS_DETAIL_ITINERARY_VIEW_DETAIL: 'online-events-detail-itinerary-view-detail'
};

/**
 * Test data id name for the online event session detail.
 */
export const ONLINE_EVENTS_SESSION_DETAIL = {
  ONLINE_EVENTS_SESSION_DETAIL_HEADING: 'online-events-session-detail-heading',
  ONLINE_EVENTS_SESSION_DETAIL_BANNER: 'online-events-session-detail-banner',
  ONLINE_EVENTS_SESSION_DETAIL_SPECIALITY: 'online-events-session-detail-speciality',
  ONLINE_EVENTS_SESSION_DETAIL_TITLE: 'online-events-session-detail-title',
  ONLINE_EVENTS_SESSION_DETAIL_DATE: 'online-events-session-detail-date',
  ONLINE_EVENTS_SESSION_DETAIL_HOSTED_BY: 'online-events-session-detail-hosted-by',
  ONLINE_EVENTS_SESSION_DETAIL_CME_CREDITS: 'online-events-session-detail-cme-credits',
  ONLINE_EVENTS_SESSION_DETAIL_CREDITS_STATUS: 'online-events-session-detail-credits-status',
  ONLINE_EVENTS_SESSION_DETAIL_SHARE_BUTTON: 'online-events-session-detail-share-button',
};

/**
 * Test data id name for the online events activity.
 */
export const ONLINE_EVENTS_ACTIVITY = {
  ONLINE_EVENTS_ACTIVITY_HEADING: 'online-events-activity-heading',
  ONLINE_EVENTS_ACTIVITY_VIEW_STATS: 'online-events-activity-view-stats',
  ONLINE_EVENTS_ACTIVITY_VIEW_VAULT: 'online-events-activity-view-vault',
  ONLINE_EVENTS_ACTIVITY_VIEW_DETAIL: 'online-events-activity-view-detail',
};

/**
 * Test data id name for the doc talks list.
 */
export const DOC_TALKS_LIST = {
  DOC_TALKS_LIST_HEADING: 'doc-talks-list-heading'
};

/**
 * Test data id name for the doc talks section.
 */
export const DOC_TALKS_SECTION = {
  DOC_TALKS_SECTION_HEADING: 'doc-talks-section-heading',
  DOC_TALKS_SECTION_VIEW_ALL: 'doc-talks-section-view-all'
};

/**
 * Test data id name for the doc talks card.
 */
export const DOC_TALKS_CARD = {
  DOC_TALKS_CARD_BANNER: 'doc-talks-card-banner',
  DOC_TALKS_CARD_TITLE: 'doc-talks-card-title',
  DOC_TALKS_CARD_SPECIALITY: 'doc-talks-card-speciality',
  DOC_TALKS_CARD_VIEWS: 'doc-talks-card-views',
  DOC_TALKS_CARD_DOTS_MENU: 'doc-talks-card-dots-menu'
};

/**
 * Test data id name for the doc talks detail.
 */
export const DOC_TALKS_DETAIL = {
  DOC_TALKS_DETAIL_TITLE: 'doc-talks-detail-title',
  DOC_TALKS_DETAIL_VIEWS: 'doc-talks-detail-views',
  DOC_TALKS_DETAIL_LIKE_COUNT: 'doc-talks-detail-like-count',
  DOC_TALKS_DETAIL_DESCRIPTION: 'doc-talks-detail-description',
  DOC_TALKS_DETAIL_SPECIALITY: 'doc-talks-detail-speciality',
  DOC_TALKS_DETAIL_SPEAKERS_DETAIL_LABEL: 'doc-talks-detail-speakers-detail-label',
  DOC_TALKS_DETAIL_PLAY_EPISODE_BUTTON: 'doc-talks-detail-play-episode-button',
  DOC_TALKS_DETAIL_LIKE_BUTTON: 'doc-talks-detail-like-button',
  DOC_TALKS_DETAIL_BOOKMARK_BUTTON: 'doc-talks-detail- bookmark-button',
  DOC_TALKS_DETAIL_SHARE_BUTTON: 'doc-talks-detail-share-button'
};

/**
 * Test data id name for the article detail.
 */
export const ARTICLE_DETAIL = {
  ARTICLE_DETAIL_FOLLOW_LEFT: 'article-detail-follow-left',
  ARTICLE_DETAIL_FOLLOW_BOTTOM: 'article-detail-follow-bottom',
  ARTICLE_DETAIL_LIKE_LEFT: 'article-detail-like-left',
  ARTICLE_DETAIL_LIKE_BOTTOM: 'article-detail-like-bottom',
  ARTICLE_DETAIL_BOOKMARK_LEFT: 'article-detail-bookmark-left',
  ARTICLE_DETAIL_BOOKMARK_BOTTOM: 'article-detail-bookmark-bottom',
  ARTICLE_DETAIL_SHARE_LEFT: 'article-detail-share-left',
  ARTICLE_DETAIL_SHARE_BOTTOM: 'article-detail-share-bottom',
  ARTICLE_DETAIL_DOT_MENU_LEFT: 'article-detail-dot-menu-left',
  ARTICLE_DETAIL_TITLE: 'article-detail-title',
  ARTICLE_DETAIL_SPECIALITY: 'article-detail-speciality',
  ARTICLE_DETAIL_CHANNEL_NAME: 'article-detail-channel-name',
  ARTICLE_DETAIL_POWERED_BY: 'article-detail-powered-by',
};

/**
 * Test data id for the headers.
 */
export const HEADER = {
  HEADER_VIEW_PROFILE_USER_DROPDOWN: 'header-view-profile-user-dropdown',
  HEADER_MY_VAULT_USER_DROPDOWN: 'header-my-vault-user-dropdown',
  HEADER_CALENDAR_USER_DROPDOWN: 'header-calendar-user-dropdown',
  HEADER_BOOKMARKS_USER_DROPDOWN: 'header-bookmarks-user-dropdown',
  HEADER_SETTING_USER_DROPDOWN: 'header-settings-user-dropdown',
  HEADER_SEND_FEEDBACK_USER_DROPDOWN: 'header-send-feedback-user-dropdown',
  HEADER_TC_USER_DROPDOWN: 'header-tc-user-dropdown',
};
