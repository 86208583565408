<div class="custom-toast-container bg-white color-black row mt-2" (click)="notificationClicked()" *ngIf="toastPackage.toastType === 'notification'">
    <div *ngIf="notificationImg" class="col-2 left-content pr-0">
        <span *ngIf="notificationImg">
            <img width="30" height="30" [src]='title.img'/>
        </span>
    </div>

    <div class="center-content pb-3 cursor-pointer toastclick"  [ngClass]="notificationImg  ? 'col-9 px-0' :'col-11'" (click)="navigateToNotifi()">
        <div class="paragraph-bold">
            <span class="color-black notification-text">
            {{title.title}}
            </span>
        </div>
        <div class="secondary-paragraph pt-1 pt-md-2">
            <span *ngIf="!isSamvaadNotify" class="color-black notification-text">
             {{message}}
            </span>
            <ng-container *ngIf="isSamvaadNotify && (message | keyvalue)?.length">
              <div *ngFor="let msg of message | keyvalue" class="color-black notification-text">
                {{msg.value}}
              </div>
            </ng-container>
        </div>
    </div>
    <div class="col-1 right-cotent px-0">
        <img class="close-icon cursor-pointer"
            src="assets/themes/icons/cancel.svg"
            width="11"
            height="11"
            alt="cancel icon"
            (click)="closeToast()"/>
    </div>
 </div>

<div class="custom-toast-container row mt-2" *ngIf="toastPackage.toastType !== 'notification'">
    <div class="col-2 left-content pr-0">
        <span class="spinner" *ngIf="toastPackage.toastType === 'progress'"></span>
        <span class="success-badge" *ngIf="toastPackage.toastType === 'success'">
            <img class="success"
                src="assets/themes/icons/toast-success.svg"
                alt="success icon" />
        </span>
        <span class="waring-badge" *ngIf="toastPackage.toastType === 'error'">
            <img class="warning"
                src="assets/themes/icons/toast-warning.svg"
                alt="warning icon" />
        </span>
    </div>
    <div class="col-9 center-content px-0 pb-3">
        <div class="message heading-4" *ngIf="toastPackage.toastType === 'progress'">
            <span class="color-white">
                {{message}}
            </span>
        </div>
        <div class="success-message" *ngIf="toastPackage.toastType === 'success'">
            <div class="heading-4">
                <span class="color-white">{{title}}</span>
            </div>
            <div class="paragraph pt-1 pt-md-2">
                <span class="color-grey-100">
                    {{message}}
                </span>
            </div>
        </div>
        <div class="warning message" *ngIf="toastPackage.toastType === 'error'">
            <div class="heading-4">
                <span class="color-white">{{title}}</span>
            </div>
            <div class="paragraph pt-1 pt-md-2">
                <span class="color-grey-100">
                    {{message}}
                </span>
            </div>
        </div>
        <div class="action-cta mt-3" *ngIf="$any(options)?.toastButtons?.primaryCTAText || $any(options)?.toastButtons?.secondaryCTAText">
            <button class="btn-design small filled primary-cta mr-2"
                (click)="primaryCTAClick()">{{$any(options)?.toastButtons?.primaryCTAText}}</button>
            <button class="btn-design small outline secondary-cta ml-1"
                (click)="secondaryCTAClick()">{{$any(options)?.toastButtons?.secondaryCTAText}}</button>
        </div>
    </div>
    <div class="col-1 right-cotent px-0" *ngIf="toastPackage.toastType === 'success' || toastPackage.toastType === 'error'">
        <img class="close-icon cursor-pointer"
        [ngClass]="{'top-50': $any(options)?.toastButtons?.primaryCTAText || $any(options)?.toastButtons?.secondaryCTAText}"
            src="assets/themes/icons/toast-cancel.svg"
            width="11"
            height="11"
            alt="cancel icon"
            (click)="closeToast()"/>
    </div>
    <div class="custom-progress-bar" [ngStyle]="{'animation-duration': animationDuration}" ></div>
</div>
