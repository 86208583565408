import { Pipe, PipeTransform } from '@angular/core';
import { RemoteStream } from '../models/remoteStream';

@Pipe({
  name: 'withoutScreenShare',
  pure: false
})
export class WithoutScreenSharePipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(remoteStreams: RemoteStream[]): any {
    const streams: { [name: number]: RemoteStream } = {};
    for (const key in remoteStreams) {
      if (!remoteStreams[key].screenShare && remoteStreams[key].stream !== null) {
        streams[key] = remoteStreams[key];
      }
    }
    return streams;
  }

}
