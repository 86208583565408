/**
 * Angular imports.
 */
import { Directive, HostListener, Input, NgZone, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { DocquityAnalyticsService } from '../dataservices/docquity-analytics/docquity-analytics.service';
import { JwtDecodeService } from '../dataservices/jwt-decode.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
/**
 * Onboarding Event Tracker Directive
 */
@Directive({
  selector: '[appOnboardingEventTracker]'
})
export class OnboardingEventTrackerDirective implements OnDestroy {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public eventdata: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profileInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfileData: any;
  public user_id: number;
  public user_track_id = '';
  public api_exe_flag = false;
  public unique_id = '0';
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  private componentActive = true;
  /**
   * Necessary instances.
   */
  constructor(private fcs: FunCollectionService, private docquityabalytics: DocquityAnalyticsService, private jwtSer: JwtDecodeService, private zone: NgZone) {
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('appOnboardingEventTracker') option: any;

  /**
   * onClick
   */
  @HostListener('click', ['$event']) onClick() {
    this.zone.runOutsideAngular(() => {
      this.unique_id = this.fcs.makenumericid();

      if (environment.event_tracking == 1 || environment.production == true) {

        if (environment.api_debugger == 1) {
          console.log(this.option.category + '====' + this.option.action + '=========' + this.option.product_type + '============' + this.option.product_id);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).ga('send', 'event', this.option.category, this.option.action, {
          hitCallback:  () => {
            // No Code
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).gtag('event', this.option.category, {'event_category': this.option.action});
      }

      const d = new Date();
      const sessiontime = { 'start_time': d.getTime(), 'end_time': d.getTime() };
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let location;
      {
        location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': timezone };
      }
      if (sessionStorage.getItem('event_session_id') == null) {
        sessionStorage.setItem('event_session_id', this.docquityabalytics.event_session_id);
      }

      const utmData = JSON.parse(localStorage.getItem('utmSourceData'))

      this.eventdata = {
        id: this.unique_id,
        event_name: this.option.action,
        product_type: this.option.product_type,
        product_type_id: this.option.product_id,
        session_id: this.docquityabalytics.event_session_id,
        local_id: '0',
        screen_name: this.option.category,
        user_id: this.user_id,
        untrack_user_identifier: '',
        session_time: sessiontime,
        location: location,
        track_id: this.user_track_id,
        utm: {
          source: utmData?.['UTM_Source'],
          campaign: utmData?.['UTM_Campaign'],
          medium:  utmData?.['UTM_Medium']
        }
      };
      this.docquityabalytics.all_event.push(this.eventdata);
      if (environment.event_tracking == 1 || environment.production == true) {
        if (this.api_exe_flag == true) { return; }
        this.api_exe_flag = true;
        this.setDocquityAnalytic();
      }
    });
  }
  /**
   * set Docquity Analytic
   */
  setDocquityAnalytic(): void {
    this.docquityabalytics.docquityAnalytics(this.docquityabalytics.all_event)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            if (environment.api_debugger == 1) {
              console.log(data);

            }
            this.docquityabalytics.all_event = [];
            this.api_exe_flag = false;
          }
        }, () => {
          this.api_exe_flag = false;
        });
      });
  }

  /**
   * product Detail Event
   */
  productDetailEvent(sessiontime, action, product_type, product_id, category, track_id): void {
    this.user_track_id = track_id;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let location;
    {
      location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': timezone };
    }

    const utmData = JSON.parse(localStorage.getItem('utmSourceData'))

    this.unique_id = this.fcs.makenumericid();
    this.eventdata = {
      id: this.unique_id,
      event_name: action,
      product_type: product_type,
      product_type_id: product_id,
      session_id: this.docquityabalytics.event_session_id,
      local_id: '0',
      screen_name: category,
      user_id: this.user_id,
      untrack_user_identifier: '',
      session_time: sessiontime,
      location: location,
      track_id: this.user_track_id,
      utm: {
        source: utmData?.['UTM_Source'],
        campaign: utmData?.['UTM_Campaign'],
        medium:  utmData?.['UTM_Medium']
      }
    };
    if (environment.event_tracking == 1 || environment.production == true) {
      if (environment.api_debugger == 1) {
        console.log(category + '====' + action + '=========' + product_type + '============' + product_id);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).ga('send', 'event', category, action, {
        hitCallback: function () {
          // console.log('Tracking is successful');
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).gtag('event', category, {'event_category': action});
      this.docquityabalytics.all_event.push(this.eventdata);
      setTimeout(() => {
        this.setDocquityAnalytic();
      }, 500);
    }

  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.componentActive = false;
  }
}
