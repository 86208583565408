<div class="onboarding-background">
  <div class="container p-0">
    <div class="text-center my-4">
      <img src="assets/images/docuity-logo.png"/>
    </div>
    <div class="row mx-3">
      <div class="col-md-1"></div>
      <div class="col-md-10 onboarding-card">
        <router-outlet></router-outlet>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
<!-- <div *ngIf="showElement" class="col-6 mx-auto">
  <div class="custom-owl-carousel">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide [width]="387" *ngFor="let listItem of [1,2,3,4,5]; let index = index;">
        <div>
          <img class="custom-carousel__banner__with__height" src="assets/images/onboarding/carousal-img.png" alt="banner">
          <div class="custom-carousel-content">
            <h3 class="custom-carousel-content-heading">
              Hello
            </h3>
            <p class="custom-carousel-content-desc">
              Untangling the complexities of HFpEF Diagnosis
            </p>
            <p class="custom-carousel-powered-by">
              By
              <span>
                Pfizer APAC
              </span>
            </p>
            <p class="custom-carousel-creation">
              <img src="assets/images/onboarding/lock-orange.svg" alt="date">
              <span>
                Fri, 04th Mar 2022, 7:00pm ASEAN
              </span>
            </p>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div class="custom-mdb-carousel carousal-dark">
  <mdb-carousel [animation]="'zoom'"  [isControls]="true" [interval]="0" [keyboard]="true">
      <mdb-carousel-item *ngFor="let listItem of [1,2,3,4,5]; let index = index;">
        <div>
          <img class="custom-carousel__banner__without__height" src="assets/images/onboarding/carousal-img.png" alt="banner">
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </div>
  <label class="checkbox ml-5">
    <input type="checkbox" checked />
    <span>checkbox</span>
  </label>
  <label class="radio ml-5">
    <input name="1" type="radio" />
    <span>radio</span>
  </label>
  <label class="radio ml-5">
    <input name="1" type="radio" checked disabled/>
    <span>radio</span>
  </label>
  <div></div>
  <label class="checkbox ml-5">
    <input type="checkbox" checked disabled />
    <span>checkbox</span>
  </label>
  <label class="radio ml-5">
    <input name="1" type="radio" disabled />
    <span>radio</span>
  </label>
  <label class="radio ml-5">
    <input name="1" type="radio"  disabled/>
    <span>radio</span>
  </label>
  <div></div>
  <label class="list-item radio ml-5">
    <input name="n" type="radio" />
    <span>list radio</span>
  </label>
  <label class="list-item radio ml-5" >
    <input name="n" type="radio" checked disabled/>
    <span>list radio</span>
  </label>
  <label class="list-item checkbox ml-5" >
    <input name="n" type="checkbox" />
    <span>list checkbox</span>
  </label>
  <div></div>
  <label class="list-item radio ml-5" >
    <input name="n" type="radio" disabled />
    <span>list radio</span>
  </label>
  <label class="list-item checkbox ml-5" >
    <input name="n" type="checkbox" disabled />
    <span>list checkbox</span>
  </label>
  <label class="list-item checkbox ml-5" >
    <input name="n" type="checkbox" checked disabled />
    <span>
      list checkbox
      <small>sample text</small>
    </span>
  </label>
  <label class="list-item checkbox ml-5" >
    <input name="n" type="checkbox" />
    <span>
      list checkbox
      <small>sample text</small>
    </span>
  </label>
  <div></div>
  <label class="switch checkbox" >
    <input name="n" type="checkbox" />
    <span></span>
  </label>
  <div></div>
  <label class="switch checkbox switch-position-left" >
    <input name="n" type="checkbox" />
    <span>lorem ipsum sit amet dummy text</span>
  </label>
  <div></div>
  <label class="switch checkbox switch-position-right" >
    <input name="n" type="checkbox" />
    <span>lorem ipsum sit amet dummy text</span>
  </label>
  <div></div>
  <label class="switch checkbox" >
    <input name="n" type="checkbox" disabled />
    <span></span>
  </label>
  <div></div>
  <label class="switch checkbox switch-position-left" >
    <input name="n" type="checkbox" checked disabled />
    <span>lorem ipsum sit amet dummy text</span>
  </label>
  <div class="btn-design medium filled">button div</div>
  <div style="margin:10px auto;width:100%;display: inline-block;">
    <div class="form-input" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">Name</label>
      <input class="input-field" type="text" placeholder="Name" />
      <p class="help-text">Please enter a valid email address</p>
    </div>
    <div class="form-input error" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">Name</label>
      <input class="input-field" type="text" placeholder="Name" />
      <p class="help-block">Please enter a valid email address</p>
    </div>
    <div class="form-input disabled" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">Name</label>
      <input class="input-field" type="text" placeholder="Name" readonly value="Mohan" />
      <p class="help-block">Help text</p>
    </div>
    <div></div>
    <div class="form-textarea" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="textarea-label">Details</label>
      <textarea class="textarea-field" placeholder="Name">hi</textarea>
      <p class="help-text">Help text</p>
    </div>
    <div class="form-textarea error" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="textarea-label">Details</label>
      <textarea class="textarea-field" placeholder="Name">hi</textarea>
      <p class="help-text">Help text</p>
    </div>
    <div class="form-textarea disabled" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="textarea-label">Details</label>
      <textarea class="textarea-field" placeholder="Name" readonly>hi</textarea>
      <p class="help-text">Help text</p>
    </div>
    <div></div>
    <div class="form-input with-icon" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">Name</label>
      <input class="input-field" type="text" placeholder="Name" />
      <span class="icon-in-field"><img src="assets/themes/images/eye-off.svg" alt="icon" /></span>
    </div>
    <div class="form-input with-icon error" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">Name</label>
      <input class="input-field" type="text" placeholder="Name" />
      <span class="icon-in-field"><img src="assets/themes/images/eye-off.svg" alt="icon" /></span>
      <p class="help-block">Please enter a valid email address</p>
    </div>
    <div></div>
    <div class="form-input" style="width: 100%;float: left;margin-right:10px;">
      <label for="" class="input-label">OTP</label>
      <div class="otp-fields">
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
      </div>
    </div>
    <div></div>
    <div class="form-input disabled" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">OTP</label>
      <div class="otp-fields">
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
      </div>
    </div>
    <div></div>
    <div class="form-input error" style="width: 400px;float: left;margin-right:10px;">
      <label for="" class="input-label">OTP</label>
      <div class="otp-fields">
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
        <input class="input-field" type="text" />
      </div>
      <p class="help-block">Please enter correct OTP</p>
    </div>
  </div>
  <div class="col-4">
    <div class="single-dropdown form-input">
      <label for="" class="input-label">Dropdown</label>
      <app-basic-multiselect-select
        [suggestionValue]="countryCodes" [selectedItems]="selectedItems" [selectedValues]="selectedCode"
        [suggestionList]="suggestionList" [dropdownSettings]="codeDropdownSettings">
      </app-basic-multiselect-select>
      <p class="help-text">Please enter a valid email address</p>
      <ng-template let-selectedCode #selectedItems>
        <div>
          {{selectedCode.value}}
        </div>
      </ng-template>
      <ng-template let-countryCodes #suggestionList>
        <div>
          {{countryCodes.name}}
        </div>
      </ng-template>
    </div>
  </div>
  <input class="btn-design small filled" type="button" value="button" />
  <input class="btn-design small filled" type="submit" value="submit" />
  <button class="btn-design small filled">button tag</button>
  <div></div>
  <input class="btn-design medium filled" type="button" value="button" />
  <input class="btn-design medium filled" type="submit" value="submit" />
  <button class="btn-design medium filled">button tag</button>
  <div></div>
  <input class="btn-design large filled" type="button" value="button" />
  <input class="btn-design large filled" type="submit" value="submit" />
  <button class="btn-design large filled">button tag</button>
  <div></div>
  <input class="btn-design small filled-disabled" type="button" value="button" />
  <input class="btn-design small filled-disabled" type="submit" value="submit" />
  <button class="btn-design small filled-disabled">button tag</button>
  <div></div>
  <input class="btn-design medium filled-disabled" type="button" value="button" />
  <input class="btn-design medium filled-disabled" type="submit" value="submit" />
  <button class="btn-design medium filled-disabled">button tag</button>
  <div></div>
  <input class="btn-design large filled-disabled" type="button" value="button" />
  <input class="btn-design large filled-disabled" type="submit" value="submit" />
  <button class="btn-design large filled-disabled">button tag</button>
  <div></div>
  <input class="btn-design small outline" type="button" value="button" />
  <input class="btn-design small outline" type="submit" value="submit" />
  <button class="btn-design small outline">button tag</button>
  <div></div>
  <input class="btn-design medium outline" type="button" value="button" />
  <input class="btn-design medium outline" type="submit" value="submit" />
  <button class="btn-design medium outline">button tag</button>
  <div></div>
  <input class="btn-design large outline" type="button" value="button" />
  <input class="btn-design large outline" type="submit" value="submit" />
  <button class="btn-design large outline">button tag</button>
  <div></div>
  <input class="btn-design small outline-disabled" type="button" value="button" />
  <input class="btn-design small outline-disabled" type="submit" value="submit" />
  <button class="btn-design small outline-disabled">button tag</button>
  <div></div>
  <input class="btn-design medium outline-disabled" type="button" value="button" />
  <input class="btn-design medium outline-disabled" type="submit" value="submit" />
  <button class="btn-design medium outline-disabled">button tag</button>
  <div></div>
  <input class="btn-design large outline-disabled" type="button" value="button" />
  <input class="btn-design large outline-disabled" type="submit" value="submit" />
  <button class="btn-design large outline-disabled">button tag</button>
  <div></div>
  <span class="chip small outline">option</span>
  <span class="chip small filled success"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline">option</span>
  <div></div>
  <span class="chip small filled">option</span>
  <span class="chip large filled">option</span>
  <div></div>
  <span class="chip large filled success"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled info"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled warning"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled error"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled loading"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <div></div>
  <span class="chip large outline success"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline info"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline warning"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <div></div>
  <span class="chip large filled success icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled info icon-position-right"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled warning icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large filled loading icon-position-right"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <div></div>
  <span class="chip large outline success icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline info icon-position-right"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline warning icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline loading icon-position-right"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <div></div>
  <span class="chip large outline icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline icon-position-right"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <span class="chip large outline icon-position-left"><img src="assets/images/onboarding/lock-orange.svg" alt="Lock Icon">option</span>
  <div></div>
    <div style="margin: 20px auto;max-width:1000px;">
      <div class="custom-tooltip bottom-left" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block; padding: 20px;">
        <p class="secondary-paragraph">
          <b>bottom left</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip bottom-right" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>bottom right</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet.
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip top-left" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>top left</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip top-right" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>top right</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip top" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>top</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip right" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>right</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip bottom" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>bottom</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
      <div class="custom-tooltip left" style="width: 33%;background: #fff;margin: 40px 20px 70px; display:inline-block;padding: 20px;">
        <p class="secondary-paragraph">
          <b>left</b><br>
          lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit amet...  lorem ipsum sit
          <span class="tooltip-content">lorem ipsum sit</span>
        </p>
      </div>
    </div>
  <div></div>
  <div>
    <div class="hz-tabs bg-white shadow" style="width:500px;margin-bottom:30px;">
      <ul class="tab-items">
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">Point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
      </ul>
    </div>
    <div class="hz-tabs bg-white shadow" style="width:500px;margin-bottom:30px;">
      <ul class="tab-items cover-space">
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">Point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
      </ul>
    </div>
    <div class="hz-tabs bg-white shadow-md" style="width:500px;margin-bottom:30px;">
      <ul class="tab-items">
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
      </ul>
    </div>
    <div class="hz-tabs" style="width:500px;margin-bottom:30px;">
      <ul class="tab-items">
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
        <li>
          <label for="tab3" (click)="activeTab('active-tab3')" [ngClass]="{'active' : activateTab === 'active-tab3'}">point 3</label>
        </li>
      </ul>
      <div class="tab-content-container">
        <input type="radio" name="tab" id="tab1" checked />
        <div class="tab-content">
          lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit
        </div>
        <input type="radio" name="tab" id="tab2" />
        <div class="tab-content">
          lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum
        </div>
        <input type="radio" name="tab" id="tab3" />
        <div class="tab-content">
          lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit amet... lorem ipsum sit amet
        </div>
      </div>
      <ul class="tab-items cover-space">
        <li>
          <label for="tab1" (click)="activeTab('active-tab1')" [ngClass]="{'active' : activateTab === 'active-tab1'}">point 1</label>
        </li>
        <li>
          <label for="tab2" (click)="activeTab('active-tab2')" [ngClass]="{'active' : activateTab === 'active-tab2'}">point 2</label>
        </li>
        <li>
          <label for="tab3" (click)="activeTab('active-tab3')" [ngClass]="{'active' : activateTab === 'active-tab3'}">point 3</label>
        </li>
      </ul>
    </div>
  </div>
  <div></div>
  <div class="calendar-container dark" style="width: 400px;display: inline-block;margin-right:20px;">
    <app-calender-event></app-calender-event>
  </div>
  <div class="calendar-container light" style="width: 400px;display: inline-block;">
    <app-calender-event></app-calender-event>
  </div>
  <div></div>
  <div>
    <div class="avatar horizontal-grp" style="max-width: 400px;margin: 30px;">
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="more-count">+78</span>
    </div>
    <div class="avatar triangle-grp" style="max-width: 400px;margin: 30px;">
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
      <span class="more-count">+8</span>
    </div>
    <div class="avatar single-avatar" style="max-width: 400px;margin: 30px;">
      <span class="icon"><img src="intro-home.jpg" alt="icon" /></span>
      <span class="icon"><img src="https://s3-ap-southeast-1.amazonaws.com/dbucket.docquity.com/images/category-images/yxy/doctor/Cardiology.jpg" alt="icon" /></span>
    </div>
    <span class="back-to-top"><img width="24" src="assets/themes/images/arrow-up.svg" alt="icon" /> <span>Back to Top</span></span>
  </div>
  <div></div>
  <div>
    <button class="btn-design medium filled" (click)="displayObj.isShow = true">open modal</button>
    <app-web-modal
      *ngIf="displayObj?.isShow"
      [isShownWebModal]="displayObj?.isShow"
      [displayObj]="displayObj"></app-web-modal>
  </div>
  <div></div>
  <div style="padding:30px;">
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
        <li class="page-item active"><a class="page-link" href="#">01</a></li>
        <li class="page-item"><a class="page-link" href="#">02</a></li>
        <li class="page-item"><a class="page-link" href="#">03</a></li>
        <li class="page-item"><a class="page-link" href="#">04</a></li>
        <li class="page-item"><a class="page-link" href="#">Next</a></li>
      </ul>
    </nav>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
        <li class="page-item"><a class="page-link" href="#">01</a></li>
        <li class="page-item"><a class="page-link" href="#">02</a></li>
        <li class="page-item active"><a class="page-link" href="#">03</a></li>
        <li class="page-item"><a class="page-link" href="#">04</a></li>
        <li class="page-item"><a class="page-link" href="#">Next</a></li>
      </ul>
    </nav>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
        <li class="page-item"><a class="page-link" href="#">01</a></li>
        <li class="page-item"><a class="page-link" href="#">02</a></li>
        <li class="page-item"><a class="page-link" href="#">03</a></li>
        <li class="page-item active"><a class="page-link" href="#">04</a></li>
        <li class="page-item disabled"><a class="page-link" href="#">Next</a></li>
      </ul>
    </nav>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
        <li class="page-item"><a class="page-link" href="#">01</a></li>
        <li class="page-item"><a class="page-link" href="#">02</a></li>
        <li class="page-item"><a class="page-link" href="#"></a></li>
        <li class="page-item"><a class="page-link" href="#">09</a></li>
        <li class="page-item active"><a class="page-link" href="#">10</a></li>
        <li class="page-item disabled"><a class="page-link" href="#">Next</a></li>
      </ul>
    </nav>

   <div *ngIf="openArticle">
    <app-product-suggestion-list
     [suggestionList]="openArticle"
     [callingFrom]="login">
    </app-product-suggestion-list>
 </div> -->
