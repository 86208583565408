// TODO: Remove Tslint disabled check.
/**
 * Model import.
 */
import { AgStream } from './agStream';

export class RemoteStream implements AgStream {
  /**
   * This will contain Agora's uid, which could be integer or string.
   * This is basically streamId
   */
  // tslint:disable-next-line: variable-name
  private _id: string | number;

  /**
   * A user can initiate multiple stream, such as video and screen share.
   * This id is unique for a particular user.
   */
  // tslint:disable-next-line: variable-name
  private _userId: string;
  /**
   * This will contain Agora's stream object.
   */
  // tslint:disable-next-line: variable-name
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _stream: any;

  /**
   * Name of the user who initiated Agora stream.
   */
  // tslint:disable-next-line: variable-name
  private _name: string;

  /**
   * Audio flag of the stream
   */
  // tslint:disable-next-line: variable-name
  private _audioMuted: boolean;

  /**
   * Video flag of the steam
   */
  // tslint:disable-next-line: variable-name
  private _videoMuted: boolean;

  // tslint:disable-next-line: variable-name
  private _speaking: boolean;
  /**
   * We need to create two stream, to do the screen share. This flag mark
   * the presenter's audio video screen as presenting
   */
  // tslint:disable-next-line: variable-name
  private _presenting: boolean;

  // tslint:disable-next-line: variable-name
  private _screenShare: boolean;

  /**
   * in case of obs streaming get attribute will fail for that
   * we add this flag to check if streaming is from OBS this is the requirement.
   */
  private _isObsStreaming: boolean;

  get id(): string | number {
    return this._id;
  }

  set id(val: string | number) {
    this._id = val;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(val: string) {
    this._userId = val;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get stream(): any {
    return this._stream;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set stream(val: any) {
    if (val != null) {
      const remoteAudioTrack = val.audioTrack;
      const remoteVideoTrack = val.videoTrack;

      if (remoteAudioTrack) {
        remoteAudioTrack.play();
      }
      if (remoteVideoTrack) {


        remoteVideoTrack.play('stream_' + val.uid, { fit: 'contain' }, (errState) => {
          if (errState && errState.status !== 'aborted') {
            // check if condition for error state
          }
        });
      }
    }
    this._stream = val;
  }
  get name(): string {
    return this._name;
  }

  set name(val: string) {
    this._name = val;
  }

  get audioMuted(): boolean {
    return this._audioMuted;
  }

  set audioMuted(val: boolean) {
    this._audioMuted = val;
  }

  get videoMuted(): boolean {
    return this._videoMuted;
  }

  set videoMuted(val: boolean) {
    this._videoMuted = val;
  }

  get speaking(): boolean {
    return this._speaking;
  }

  set speaking(val: boolean) {
    this._speaking = val;
  }

  get screenShare(): boolean {
    return this._screenShare;
  }

  set screenShare(val: boolean) {
    this._screenShare = val;
  }

  get initials(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let initials: any = this._name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  set presenting(val: boolean) {
    this._presenting = val;
  }

  get presenting(): boolean {
    return this._presenting;
  }

  set isObsStreaming(val: boolean) {
    this._isObsStreaming = val;
  }

  get isObsStreaming(): boolean {
    return this._isObsStreaming;
  }

  /**
   * Set the value.
   */
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id, stream: any = null, screenShare = false,
    audioMuted = false, videoMuted = false, name = 'unknown', userId = null, isObsStreaming = false
  ) {
    this.id = id;
    this.stream = stream;
    this.screenShare = screenShare;
    this.audioMuted = audioMuted;
    this.videoMuted = videoMuted;
    this.name = name;
    this.userId = userId;
    this.isObsStreaming = isObsStreaming;
  }
}
