<!-- Initializing the video player -->
<div id="videoContainer" #videoContainer>
  <p *ngIf="errorInPlaying">
      <!-- error message to be display on UI  -->
      {{errorMsg}}
  </p>
  <div *ngIf="showReloadLoader && reloadHls > 0" class="text-center reloader-img">
    <img src="assets/themes/images/img-video-loader.gif" width="40" height="40" id="loadmore_post"  />
  </div>
  <video #videoPlayer id="videoPlayer" width="100%" height="100%" [autoplay]="autoplay"></video>
  <ng-content></ng-content>
</div>
