/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { ImageGroupService } from '../../dataservices/master-service/image-group.service';
import { GetService } from '../../dataservices/get.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';

/**
 * Library imports.
 */
import { ModalDirective } from 'ngx-bootstrap/modal';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Product Like Component.
 */
@Component({
  selector: 'app-product-like',
  templateUrl: './product-like.component.html',
  styleUrls: ['./product-like.component.scss']
})
export class ProductLikeComponent implements OnInit, OnDestroy {
  @ViewChild('autoShownModal') autoShownModal: ModalDirective;
  @Output() resetLikeModalList = new EventEmitter();
  @Output() resetLike = new EventEmitter();
  @Input() callingfrom;
  @Input() likeObj;
  @Input() doctalkLikes;
  @Input() websocketLikes;

  public error_msg: string;
  public showLikeListModal = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public likeList: any = [];
  public offset = 1;
  public product_id: number;
  public product_type: number;
  public action: string;
  public callBackParams; // return params
  public like_list_loader = false;
  public checkoffset: number;

  /**
   * To unsubscribe data.
   */
   private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    public igs: ImageGroupService,
    public mls: NewLanguageService,
    public funCollectionService: FunCollectionService,
    private getSer: GetService,
    private jwtSer: JwtDecodeService,
    public multi: MultiLangService) { }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (this.likeObj != null || this.likeObj != '') {
      this.product_id = this.likeObj['product_id'];
      this.product_type = this.likeObj['product_type'];
      this.action = this.likeObj['callLikeProperty'];
      if (this.action == 'list') {
        this.showLikeListModal = true;

        this.getLikeList();
      }
      if (this.websocketLikes != '') {
        this.doctalkLikes = this.websocketLikes;
      }
    }
  }

  /**
   * get Like List
   */
  getLikeList(): void {
    this.like_list_loader = true;
    if (this.offset != 0) {
      this.like_list_loader = true;
      this.subscriptions.add(this.getSer.getProductLikeList(this.product_type, this.product_id, this.offset).subscribe(resData => {
        this.like_list_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data.status == 1) {
            this.offset = data.data.offset;
            this.likeList = this.likeList.concat(data.data.like_list);
            if (this.offset == 2) {
              this.getLikeList();
            }
          } else if (data.status == 0) {
            this.funCollectionService.errorHandeler(data);
          }
        }, () => {
          this.like_list_loader = false;
        });
      }));
    }
  }

  /**
   * Scroll Down
   */
  onScrollDown(): void {
    if (this.offset > 2 && this.checkoffset != this.offset) {
      this.checkoffset = this.offset;
      this.getLikeList();
    }
  }

  /**
   * hide Parent Like Modal
   */
  hideParentLikeModal(): void {
    this.resetLike.emit();
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.showLikeListModal = false;
    this.hideParentLikeModal();
    this.resetLike.emit();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
