/**
 * Angular imports.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { NewLanguageService } from './../../../dataservices/new-language/new-language.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { Subscription } from 'rxjs';
/**
 * Constants imports.
 */
import { LOGOUT_TYPE } from 'src/app/constants/app.constants';

/**
 * This component used to certified the students through ID proof.
 */
@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit, OnDestroy {
  /**
   * Hold can Deatctivate flag
   */
  public canDeactive = true;
  public url = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  public err_msg = '';
  public user_input_json = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selected_university: any = { university_id: '', university_name: '', college_id: '' };
  public isErrorModalShown = false;
  public error_msg: string;
  public uploadcounter = 1;
  public uploadfilearray = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public upload_college_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public screen_data: any;
  public showColor = false;
  public uploadFile_api_in_process = false;
  public api_in_process = false;
  public infoshow = false;
  public isConfirmModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public err_msg_file: any;
  public isSkipConfirmModalShown = false;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(public route: ActivatedRoute,
              private jwtSer: JwtDecodeService,
              private Dss: DataStorageService,
              private fcs: FunCollectionService,
              private masterService: MasterserviceService,
              public mls: NewLanguageService,
              private router: Router,
              public onboardingService: OnboardingService) { }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }
    if (sessionStorage.getItem('select_university') == null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('select_university'));
      if (this.verify_mobile.status == 1) {
        this.screen_data = this.verify_mobile.data.screen_data;
      }
    }
    this.canDeactive = false;
  }

  /**
   * mouseEnter
   */
  mouseEnter(): void {
    this.infoshow = true;
  }

  /**
   * mouseLeave
   */
  mouseLeave(): void {
    this.infoshow = false;
  }

  /**
   * onSelectFile
   */
  onSelectFile(fileInput): boolean {
    this.upload_college_data = '';
    this.uploadfilearray = [];
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    const file = fileInput.target.files[0];
    this.url = fileInput.target.result;
    const file_type = fileInput.target.files[0].type;

    if (file_type == 'image/jpeg' || file_type == 'image/png' || file_type == 'image/webp') {
      const blob = fileInput.target.files[0];
      let BYTES_PER_CHUNK = 1000024; // **1MB chunk sizes.

      const SIZE = blob.size;

      if (SIZE > 100024) {
        BYTES_PER_CHUNK = 100024;
      } else if (SIZE > 10024) {
        BYTES_PER_CHUNK = 10024;
      } else {
        BYTES_PER_CHUNK = 1024;
      }
      let start = 0;
      let end = BYTES_PER_CHUNK;
      const d = new Date();
      const n = d.getTime();
      let fileName;

      let count_x = Math.round(SIZE / BYTES_PER_CHUNK);
      count_x += 1;
      let x = 0;
      while (start < SIZE) {

        const chunk = blob.slice(start, end);
        this.uploadfilearray[this.uploadcounter] = chunk;
        this.uploadcounter = this.uploadcounter + 1;
        start = end;
        end = start + BYTES_PER_CHUNK;
        x++;
      }

      if (x < count_x) {
        const chunk = blob.slice(start, end);
        this.uploadfilearray[this.uploadcounter] = chunk;
        this.uploadcounter = this.uploadcounter + 1;
        start = end;
        end = start + BYTES_PER_CHUNK;
      }

      const num_chunks = this.uploadcounter - 1; // total number of chunks
      this.uploadcounter = 1;

      fileName = n + file.name;
      fileName = fileName.replace(/ /g, '_');
      this.uploadFile_api_in_process = true;
      this.uploadFile(this.uploadfilearray[this.uploadcounter], fileName, num_chunks, this.uploadcounter, file_type, '1');

      fileInput.target.value = '';
      fileInput.target.files = null;
    } else {
      this.err_msg_file = 'please select image file';
    }

  }

  /**
   * uploadFile
   */
  uploadFile(blobFile, filename, num_chunks, num, file_type, product_type): void {
    if (this.uploadfilearray.length > this.uploadcounter) {
      this.subscriptions.add(this.onboardingService.newUploadInChunk(blobFile, filename, num_chunks, num, file_type, product_type).subscribe(data => {

        this.uploadcounter = this.uploadcounter + 1;
        if (this.uploadfilearray.length > this.uploadcounter) {
          this.uploadFile(this.uploadfilearray[this.uploadcounter], filename, num_chunks, this.uploadcounter, file_type, product_type);
        } else {
          this.error_msg = '';
          this.upload_college_data = data.data;
          if (this.upload_college_data.fileurl != undefined) {
            this.uploadFile_api_in_process = false;
            this.showColor = true;
            this.selected_university.college_id = this.upload_college_data.fileurl;

            this.showColor = true;
          }

        }
      }, () => {
        this.uploadFile_api_in_process = false;
      }));
    }
  }

  /**
   * user University
   */
  userUniversity(val): void {
    this.error_msg = '';
    this.selected_university.university_id = val.university_id;
    this.selected_university.university_name = val.university_name;

    if (this.selected_university.college_id != '') {
      this.showColor = true;
    }

  }

  /**
   * user Register
   */
  userRegister(): boolean {
    let uni_name = '';
    const user_input_json = [{
      'key': 'select_university',
      'value': { university_id: this.selected_university.university_id, university_name: uni_name, college_id: this.selected_university.college_id }
    }];
    if (this.selected_university.college_id == '') {
      this.error_msg = 'Please enter university or upload college id';
      this.showColor = false;
      return;
    } else {
      this.error_msg = '';
    }
    if (this.selected_university.university_id == 0) {
      uni_name = this.selected_university.university_name;
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.api_in_process = true;
    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, 0).subscribe(registerPromise => {
      this.api_in_process = false;
      registerPromise.then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));

            if (data['data'].screen_data.identifier == 'select_profession') {
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);
            }
            if (data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  if(sessionStorage.getItem('logoutType') && sessionStorage.getItem('logoutType') === LOGOUT_TYPE.MANUAL) {
                    this.router.navigateByUrl('/');
                    return;
                  }
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);

            }
            if (data['data'].screen_data.identifier == 'select_university') {
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
          }

          if (data['code'] == 2007) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            if (data['data'].screen_data == null) {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);
            }
            if (data['data'].screen_data.identifier == 'select_profession') {
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              this.router.navigateByUrl('/login/verify');
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                // window.location.href="/#/web";
                // window.location.reload(true);
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);
            }
            if (data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);

            }
            if (data['data'].screen_data.identifier == 'select_university') {
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }

          }
        } else if (data['status'] == 0 || !data) {
            this.error_msg = data['error'].msg;
          }

      });
    }));
  }

  /**
   * remove CollegeId
   */
  removeCollegeId(): void {
    this.showColor = false;
    this.upload_college_data = '';
    this.selected_university.college_id = '';
  }

  /**
   * skip Onboarding Modal
   */
  skipOnboardingModal(): void {
    this.isSkipConfirmModalShown = true;
  }

  /**
   * reset Skip Confirm Modal
   */
  resetSkipConfirmModal(cnfrm_act): void {
    this.isSkipConfirmModalShown = false;
    if (cnfrm_act == 1) {
      this.skipRegistraion();
      this.canDeactive = true;
    }
  }

  /**
   * skip Registration
   */
  skipRegistraion(): boolean {
    const user_input_json = [{
      'key': 'select_university',
      'value': { university_id: '', university_name: '', college_id: '' }
    }];
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, 1).subscribe(registerPromise => {
      registerPromise.then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));

            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              sessionStorage.setItem('verify_user', JSON.stringify(data));
              this.router.navigate(['/login/verify-user'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);

            }
            if (data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.Dss.pse_election_url == 'pseelection') {
                  this.Dss.urlsection = 'pseelection';
                  this.fcs.redirectOnSection(this.Dss.urlsection, this.Dss.urlsectionId);
                } else {
                  if(sessionStorage.getItem('logoutType') && sessionStorage.getItem('logoutType') === LOGOUT_TYPE.MANUAL) {
                    this.router.navigateByUrl('/');
                    return;
                  }
                  this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                }
              }, 500);


            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          }
        } else if (data['status'] == 0) {
          {
            this.error_msg = data['error'].msg;
          }
        }
      });
    }));
  }

  /**
   * reset Error Modal
   */
  resetErrorModal(): void {
    this.error_msg = '';
    this.isErrorModalShown = false;
  }

  /**
   * back screen
   */
  back_screen(): void {
    this.isConfirmModalShown = true;
  }

  /**
   * Used to show confirmation modal and no internet popup
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.isConfirmModalShown = true;
    return this.canDeactive;
  }

  /**
   * Used to get the click events from confirmation modal.
   */
  resetConfirmModal(cnfrm_act): void {
    this.isConfirmModalShown = false;
    this.canDeactive = cnfrm_act;
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
