export const API = {

  WEB_GET_APP: '/web/get-app',
  CONNECT_SERVICE_REGISTRATION_GET: '/connect/services/registration/get', // Not in used
  PROFILE: '/profile', // NOt useed in web too
  CONNECT_SERVICES_ASSOCIATION_GET: '/connect/services/association/get', // NOt useed in web too

  JOURNAL_ARCHIVE: '/journal-archieve',// NOt useed in web too
  DOCQUITY_V1: '/docquity_v1', // NOt useed in web too
  CHAT_MESSAGE: '/chat/Message/', // NOt useed in web too

  CONNECT_SERVICES_AUTHENCITY_SET: '/connect/services/authencity/set', // Not used now
  FORM_SAVE_MISSING_DATA: '/4.1/form/save-missing-data', // Not used now
  // onboarding module ----------
  USER_BASIC_INFO: '/4.0/user-basic-info',  // not use- pavvan sir

  CONNECT_SERVICES_FEED_SET: '/connect/services/feed/set', // not in use
  CONNECT_WEB_SERVICE_SET_API_PHP: '/connect/webservice/setApi.php', //  not in use now

  WEBINAR_LIST: '/api/v2/7.0/webinar/list',
  CONNECT_SERVICES_BOOKMARK_SET: '/connect/services/bookmark/set', // not in used
  PHARMA_CHANNEL_TNC_v2: '/4.1/pharma/channel-term-condition-v2', // ask pavn sir-------------- in use
  FORM_PRODUCT_MISSING_DATA: '/4.1/form/product-missing-data', // not used

  NEWS_FILTER_LIST: '/news/news-filter-list',
  NEWS_LIST: '/news/news-list',
  PHARMA_CHANEL_SUBSCRIBE: '/pharma/chanel-subscribe',
  PHARMA_CHANNEL_TNC: '/pharma/channel-term-condition',
  USER_PROFILE_REPLY_QUESTION: '/user/profile/reply-question',
  SEARCH_DOCQUITY_LIST: '/search/docquity-data', // comment code on mob , not used
  SEARCH_TERMS: '/search/terms', // comment code on mob , not used
  SEARCH_STORE_TERM: '/search/store-term', // comment code on mob , not used
  HOME_FEED_DETAIL: '/home/feed-detail', // comment code on mob , not used
  HOME_FEED_TIMELINE: '/home/feed-timeline', // comment code on mob , not used
  HOME_FEED_ARCHIEVE: '/home/feed-archieve', // comment code on mob , not used
  ARCHIEVE_LIST: '/archieve-list',  // comment code on mob , not used
  PROFILE_MEDIA_LIST: '/profile/media-list',  // comment code on mob , not used
  USER_PROFILE_QUESTION_LIST: '/user/profile/question-list',  // comment code on mob , not used
  SPECIALITY_LIST: '/speciality-list',  // comment code on mob , not used
  HOME_POSTING_ON: '/home/posting-on',  // comment code on mob , not used
  CASE_FOLLOW: '/case-follow',  // comment code on mob , not used

  // **********************************************

  EVENT_LIST_V3: '/4.3/event-v3/event-list',
  EVENT_DETAILS_V3: '/4.3/event-v3/event-details',
  SESSION_DETAILS_V3: '/4.3/event-v3/session-details',
  USER_ACTIVITY_V3: '/4.3/event-v3/user-activity',
  CERTIFICATE_DETAIL_V3: '/4.3/event-v3/certificate-detail',
  SPEAKERS_LIST_V3: '/4.3/event-v3/speakers-list',
  EVENT_SCHEDULE: '/4.3/event-v3/event-schedule',
  SESSION_MAPPED_PRODUCT_V3: '/4.3/event-v3/session-mapped-product',
  EVENT_REGISTER: '/event-register',
  EVENT_GOING: '/4.1/event/going',
  EVENT_CERTIFICATE: '/4.1/event/certificate',
  EVENT_CERTIFICATE_HISTORY: '/4.1/event/certificate-history',
  EVENT_LIST_V2: '/4.1/event/list_v2',
  EVENT_DETAIL: '/4.1/event/detail',
  EVENT_SESSION_LIST: '/4.1/event/session-list',
  EVENT_SESSION_DETAIL: '/4.1/event/session-detail',
  EVENT_MEDIA_LIST: '/4.1/event/media-list',
  SEARCH_GET_SUGGESTIONS: '/api/v2/7.0/search/get-suggestions',
  SEARCH_STORE_TERMS: '/api/v2/7.0/search/store-terms',
  SEARCH_DOCQUITY_DATA_GROUP: '/api/v2/7.0/search/docquity-data-group',
  LIST_SEARCH_TREND: '/4.1/list/search-trend',
  COMMUNITY: '/api/v2/7.0/community',
  COMMUNITY_DETAIL: '/api/v2/7.0/community',
  NCP_SUBTYPE_DETAILS: '/4.3/ncp-subtype/details',
  GAME_PLAY_COUNT: '/4.3/ncp-subtype/click',
  GAME_LEADER_BOARD: '/4.3/game-leaderboard/list',
  GAME_NCP_DETAIL: '/4.3/ncp/details',
  CARD_RENDERING: '/api/v2/7.0/card-rendering',
  EDITOR_CHOICE_DETAILS: '/4.3/editor-choice/details',
  USER_DELETION_DELETE_ACCOUNT: '/4.3/user-deletion/delete-account',
  USER_DELETION_LIST: '/4.3/user-deletion/list',
  USER_DOCUMENT_SUBMIT: '/api/v2/7.0/user-document-submit',
  USER_TNC_UPDATE: '/4.3/user/tnc-update',
  ACCEPT_CONSENT: '/4.3/clinic/accept-consent',
  CALENDER_LIST: '/calendar/list',
  CHATBOT_HISTORY: '/chatbot/history',
  MR_COMMENT_GET: '/mr/comment/get',
  BACKGROUND_ACTIVE: '/api/v2/7.0/list/background-active',
  BACKGROUND_ACTIVE_V1: '/api/v2/7.0/list/background-active',
  LIST_SUGGESTION_LIST: '/4.1/list/suggestion-list',
  REASON_LIST: '/4.1/reason/list',
  REASON_CREATE_REPORT: '/4.1/reason/create-report',
  REFERRAL_CONTENT: '/4.1/referral/content',
  REFERRAL_USER_POINT: '/referral/user-referral-point',
  NOTIFICATION_USER_LIST: '/4.1/notification/user-notification-list',
  SET_NOTIFICATION: '/4.1/notification/set-notification',
  GRAND_ROUND_LIST: '/api/v2/7.0/grand-round-list',
  VERIFICATION_UNDER_PROCESS: '/api/v2/7.0/verification-under-process',
  ASSOCIATION_SUGGESTED_LIST: '/api/v2/7.0/association/suggested-association-list',
  ASSIGN_ASSOCIATION: '/api/v2/7.0/assign-association',
  LIST_SHARE_CONTENT: '/api/v2/7.0/list/share-content',
  COUNTRY_LIST_V4: '/4.0/country-listv4',  // new
  COUNTRY_LIST_V5: '/api/v2/7.0/country-listv4',  // new
  MASTER_LIST: '/api/v2/7.0/master/list',
  USER_ASSOCIATION_LIST: '/4.1/association/user-association-list',
  VALIDATE_API_EXTRACTION_V1: '/4.1/validate/api-extraction-v1',
  TEXT_TO_SPEECH: '/api/v2/7.0/text-to-speech',
  HOME_DATA_LISTS: '/home/data-lists',
  CONNECT_WEB_SERVICE_GET: '/connect/webservice/get',
  CONNECT_WEB_SERVICE_SET: '/connect/webservice/set',
  CONNECT_WEB_SERVICE: '/connect/webservice/get',
  VALIDATE_API_EXTRACTION: '/validate/api-extraction',
  LOG_INFO_CARD_CROSS: '/4.3/list/tutorial-log',
  IN_APP: '/inapp',
  PHARMA_DRUG_LIST_V2: '/api/v2/7.0/pharma/drug-list_V2',
  PHARMA_DRUG_DETAIL_V2: '/4.1/pharma/drug-detail_V2',
  PHARMA_CHANNEL_SUBSCRIBE: '/api/v2/7.0/pharma/channel-subscribe',
  PHARMA_DRUG_SAMPLE_REQUEST_V2: '/api/v2/7.0/pharma/drug-sample-request_v2',
  PHARMA_CHANNEL_HOME_LIST: '/api/v2/7.0/pharma/channel-home-list',
  PHARMA_PRODUCT_LIST_V2: '/api/v2/7.0/pharma/product-list_V2',
  PHARMA_DETAIL_V4: '/4.1/pharma/detail_v4',
  E_DETAILING_PHARMA_LIST: '/api/v2/7.0/e-detailing/pharma-list',
  PHARMA_DRUG_SIMPLE_REQUEST: '/pharma/drug-sample-request',
  PHARMA_DRUG_DETAIL: '/pharma/drug-detail',
  E_DETAILING_THERAPEUTIC_LIST: '/4.1/e-detailing/therapeutic-list',
  E_DETAILING_PHARMA_TNC: '/api/v2/7.0/e-detailing/pharma-tnc',
  E_DETAILING_PHARMA_DETAIL: '/api/v2/7.0/e-detailing/pharma-detail',
  E_DETAILING_THERAPEUTIC_CHANNEL_LIST: '/api/v2/7.0/e-detailing/therapeutic-channel-list',
  E_DETAILING_THERAPEUTIC_DRUG_DETAIL: '/api/v2/7.0/e-detailing/therapeutic-drug-list',
  E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE: '/api/v2/7.0/e-detailing/pharma-therapeutic-subscribe',
  E_DETAILING_DO_LATER: '/api/v2/7.0/e-detailing/do-later',
  E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE: '/api/v2/7.0/e-detailing/pharma-tnc-option-subscribe',
  E_DETAILING_PROFILE_MISSING_UPDATE: '/api/v2/7.0/e-detailing/profile-missing-update',
  E_DETAILING_DRUG_DETAILS: '/api/v2/7.0/e-detailing/drug-detail',
  PHARMA_DRUG_TIME_SLOT: '/api/v2/7.0/pharma/drug-timeslot',
  FILTER_LIST_V2: '/api/v2/7.0/list/filter-list_V2',
  PHARMA_DRUG_LIST: '/pharma/drug-list',
  SURVEY_TNC: '/4.1/survey-tnc',

  PROFILE_TIMELINE: '/api/v2/7.0/profile/timeline',
  PROFILE_VIEW_LIST: '/4.1/profile/view-list',
  PROFILE_DETAIL: '/api/v2/7.0/profile/detail',
  PROFILE_UPDATE: '/api/v2/7.0/profile/update',
  PUBLIC_PROFILE: '/4.1/public-profile',
  HOSPITAL_LIST: '/api/v2/7.0/hospital-list',
  UNIVERSITY_LIST_V2: '/api/v2/7.0/university-listV2',
  DEGREE_LIST: '/api/v2/7.0/degree-list',
  JOURNAL_LIST: '/api/v2/7.0/journal/list',
  JOURNAL_DETAIL: '/api/v2/7.0/journal/detail',
  PRODUCT_FEEDBACK: '/4.1/product-feedback',
  HISTORICAL_POLL_LIST: '/v2/poll',
  LIVE_POLL_SUBMIT: '/v2/poll',
  MEDIA_STREAMING_LOG: '/4.1/media-streaming-log',
  WEBINAR_V2_LIST: '/api/v2/7.0/webinar/v2/list',
  DOC_TALKS_LIST: '/api/v2/7.0/doctalks/list',
  DOC_TALKS_DETAIL: '/api/v2/7.0/doctalks/details',
  WEBINAR_STATUS: '/api/v2/7.0/webinar/status',
  WEBINAR_NOTIFY_ME: '/api/v2/7.0/webinar/notifyme',
  COOKIE: '/v1/application/cookie',
  WEBINAR_CDN_DETAILS: '/4.1/webinar/cdnpatnerdetails',
  WEBINAR_AGORA_TOKEN_V2: '/4.1/webinar/agoratoken/v2',
  WEBINAR_POLL_SUBMIT: '/api/v2/7.0/webinar/poll_submit',
  CME_GENERATE_CERTIFICATE: '/4.1/cme/generate-cme-certificate',
  CME_SUBMIT_ANSWER_V2: '/4.1/cme/submit-answerV2',
  CME_DETAIL: '/4.1/cme/detail',
  CME_HISTORY_V2: '/4.1/cme/cme-historyV2',
  CME_LIST_V2: '/4.3/cme/cme-listV2',
  CME_SOURCE_LIST: '/4.1/cme/cme-source-list',
  CME_OFFLINE_CME: '/4.1/cme/offline-cme',
  CME_DESCRIPTION: '/4.1/cme/cme-description',
  FORM_CONTROL_DETAIL: '/api/v2/7.0/form-control/detail', // webinar module

  CME_MODULE_DETAIL_V2 : '/4.3/cme/module-data',
  CME_MODULE_NEXT_STEP_JOURNEY : '/4.3/cme/next-step-journey',

  LIKE_LIST: '/4.0/like/like-list',
  LIKE_LIKE_V2: '/api/v2/7.0/like/like',
  LIKE_LIST_V2: '/api/v2/7.0/like/like-list',
  LIKE_LIKE: '/4.0/like/like',
  BOOKMARK_PRODUCT: '/4.1/bookmark/bookmark-product',
  BOOKMARK_PRODUCT_LIST: '/4.1/bookmark/product-list',
  COMMENT_LIST: '/api/v2/7.0/comment/comment-list',
  TAG_USER_SEARCH: '/api/v2/7.0/comment/user-search',
  COMMENT_POST: '/api/v2/7.0/comment/comment-post',
  COMMENT_GET: '/comment/get',
  ANALYTIC_RECORDS: '/analytic/records',
  FEED_POST: '/4.1/feed-post',
  FEED_EDIT: '/4.1/feed-edit',
  FEED_DELETE: '/4.1/feed-delete',
  FEED_DETAIL_V4: '/4.1/feed-detail-v4',
  FEED_POLL_RECORD: '/4.1/feed-poll-record',
  SPECIALITY_LIST_V4: '/api/v2/7.0/speciality-list-v4',
  REGISTRATION_SPECIALITY_LIST_V4: '/4.1/registration/speciality-list-v4',
  CHAT_SEARCH_LIST: '/4.1/chat/search-list',
  CHAT_DIALOGUE: '/4.1/chat/dialogue',
  CHAT_EDIT_DIALOGUE: '/4.1/chat/edit-dialogue',
  CHAT_USER_CONNECTION: '/4.1/chat/user-connection',
  CHAT_CONNECTION_LIST: '/4.1/chat/connection-list',
  REGISTRATION_SUCCESS_SCREEN: '/4.3/av/success-screen',
  REGISTRATION_CAPTCHA_USER_REQUEST: '/4.1/registration/captcha-user-request',
  REGISTRATION_OTP_REQUEST: '/4.0/registration/otp-request',
  REGISTRATION_OTP_RESEND: '/4.0/registration/otp-resend',
  FORWORD_FLOWS_SUGGESTION: '/api/v2/7.0/forward-flow/suggestions',
  HCF_CONTINUE_WATCH_VIEW_ALL: '/4.3/horizontal-carousal/continue-watch',
  REPOST_DEFAULT_CONTENT: '/4.3/repost/default-content',
  REGISTRATION_VERIFY_MOBILE: '/4.3/registration/verify-mobile',
  USER_REGISTRATION: '/4.3/registration/user-register',
  REGISTRATION_INVITE_PROCESS: '/4.1/registration/invite-process',
  REGISTRATION_UNIVERSITY_LIST: '/4.1/registration/university-list',
  CONNECT_SERVICE_F_UPLOAD_SET: '/connect/services/fupload/set',
  USER_STORY_CAROUSEL_LIST: '/api/v2/user-stories/story',
  STORY_USER_SEARCH_LIST: '/api/v2/user-stories/user-search',
  STORY_DETAIL: '/api/v2/user-stories/story',
  TEMPLATE_LISTING: '/api/v2/user-stories/template',
  VIEW_LIST: '/api/v2/user-stories/view/story',
  STORY_DEFAULT_CONTENT: '/api/v2/user-stories/default-content',
  CREATE_USER_STORY: '/api/v2/user-stories/story',
  GET_PROFILE_NUDGE_DATA: '/api/v2/profile/nudge/',
  SUBMIT_PROFIL_DATA: '/api/v2/profile/nudge/',
  VIEW_STORY_LOG: '/api/v2/user-stories/view/story',
  LOG_STORY_REACTION: '/api/v2/user-stories/reaction/story',
  SAVE_SEARCH_RECORD: '/api/v2/7.0/search/save-search-record',
  DOCQUITY_STS_TOKEN: '',
  FILE_UPLOAD: '/file-upload',
  MR_CALENDER_LIST: '/4.3/mr-connect/calender-list',
  MR_MEETING_ACTION: '/4.3/mr-connect/meeting-action',
  MR_MEETING_RESCHEDULE: '/4.3/mr-connect/meeting-reschedule',
  MR_CALENDER_DOTS: '/4.3/mr-connect/calender-dots',
  MR_MEETING_DETAILS: '/4.3/mr-connect/meeting-detail',
  USER_INVITE_CODE: '/4.3/user/invite-code',
  USER: '/api/v2/7.0/user/getMembersByType',
  USER_LOGOUT: '/4.3/user/logout',
  USER_TOKEN: '/4.3/magistrate/user-token',
  REFRESH_TOKEN: '/api/v2/refreshToken',
  CREATE_FILE: '/api/v2/createFile',
  RECREATE_SINGLE_URL: '/api/v2/recreateUrl',
  RECREATE_MULTIPART_URL: '/api/v2/recreateMultipartUrl',
  READ_FILE: '/api/v2/readFile',
  READ_FILES: '/api/v2/readFiles',
  UPDATE_PART_STATUS: '/api/v2/updatePartUploadStatus',
  COMPLETE_MULTIPART_STATUS: '/api/v2/completeMultipart',


  SURVEY_LIST: '/api/v2/brahma/client/survey/list',
  SURVEY_PENDING_LIST: '/api/v2/brahma/client/survey/carousel/inprogress',
  SURVEY_DETAIL: '/api/v2/brahma/client/survey',
  SURVEY_FOOTER: '/api/v2/brahma/miscellaneous/footer',
  SURVEY_CONSENT: '/api/v2/brahma/miscellaneous/consent',
  SURVEY_CONSENT_SUBMIT:'/api/v2/brahma/client/consent',
  SURVEY_DETAIL_SUBMIT: '/api/v2/brahma/client/survey',
  SURVEY_DETAIL_SECTION: '/api/v2/brahma/client/survey',

  SURVEY_REWARDS_LIST : '/api/v2/brahma/client/survey/reward/list',
  SURVEY_REWARDS_TOTAL : '/api/v2/brahma/client/survey/reward/total',
  SURVEY_REWARDS_REDEEM_ALL : '/api/v2/brahma/client/survey/redeem',
  SURVEY_REWARD_REDEEM : '/api/v2/brahma/client/survey/:code/redeem',


  SURVEY_PATIENT_LIST : '/api/v2/brahma/client/patient',
  PIF_CME_STATUS: '/api/v2/7.0/cme/pif-detail',

  PUBLIC_SURVEY_LIST: '/api/v2/brahma/public/client/survey/list',
  PUBLIC_SURVEY_PENDING_LIST: '/api/v2/brahma/public/client/survey/carousel/inprogress',
  PUBLIC_SURVEY_DETAIL: '/api/v2/brahma/public/client/survey',
  PUBLIC_SURVEY_DETAIL_SUBMIT: '/api/v2/brahma/public/client/survey',
  PUBLIC_SURVEY_DETAIL_SECTION: '/api/v2/brahma/public/client/survey',
  PUBLIC_SURVEY_CONSENT: '/api/v2/brahma/public/miscellaneous/consent',
  PUBLIC_SURVEY_CONSENT_SUBMIT: '/api/v2/brahma/public/client/consent',
  PUBLIC_SURVEY_FOOTER: '/api/v2/brahma/public/miscellaneous/footer',
  PROMO_VIEW_STRIP: '/4.3/pvs',
  PROMO_VIEW_STRIP_CLOSE: '/pvs/close',
  DEVICE_TOKEN_GENERATE:'/device-token-generate',
  DELETE_FILE: '/api/v2/deleteFile',
  KEEPER_TOKEN: '/api/v2/token/',
  META_PREVIEW : '/4.3/meta-preview',
  DISCARD_SURVEY : '/api/v2/brahma/client/survey',
  PUBLIC_DISCARD_SURVEY : '/api/v2/brahma/public/client/survey',
  OPEN_CONTENT : '/4.3/open-content/contentList',
  OPEN_CONTENT_DETAIL : '/4.3/open-content/contentDetails',
  OPEN_CONTENT_COUNTRY : '/4.3/geoLocation/getCountry',
  OPEN_CONTENT_ANALYTICS : '/4.3/open-content/analytics',
  OTHER_USER_INFO: '/4.3/chat/other-user-info',
  CHAT_INITIATE: '/4.3/chat/initiate',
  OPEN_CONTENT_TOKEN_DETAIL: '/4.3/open-content/getTokenDetail',

  XENDIT_PAYMENT_GATEWAY : '/api/v2/brahma/xendit/payouts_channels',
};
