/**
 * Angular imports.
 */
import { Component } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
/**
 * Module Import
 */
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
/**
 * Service Import
 */
import { FunCollectionService } from '../common-functions/fun-collection.service';
import { GetService } from '../../dataservices/get.service';
/**
 * Constant Import
 */
import { SURVEY_REMARKS,NUMBERS,ERROR_CODES, NOTIFICATION_TYPE, NAVIGATION_TARGET, PRODUCT_TYPE_CONSTANT_NAME, CTA_TYPE, ACTION_TYPE, ACTION_ON, NOTIFICATION_TYPE_SOURCE } from '../../constants/app.constants';
/**
 * Rxjs Import
 */
import { Subscription } from 'rxjs';
/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';
import { NOTIFICATION_RECEIVED } from 'analytics2.0/dist/core/constants/analytic.interface';


@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      state('removed', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
      ]))),
    ]),
  ],
})
/**component for customized toaster */
export class CustomToastComponent extends Toast {
  private notificationType: string;
  private notificationId: number;
  public notificationImg: string;
  private notificationUrl: string;
  public loginUserCustomeId: string;
  public additional_info: any;
  public surveyError: string;
  private subscriptions: Subscription = new Subscription();
  private isSamvaadNotify: boolean;
  constructor(
    public router: Router,

    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private fcs: FunCollectionService,
    private GetSer: GetService,
    private analyticSdk: Analytics
  ) {
    super(toastrService, toastPackage);
    this.notificationType = toastPackage.title['identi'];
    this.notificationId = parseInt(toastPackage.title['id']);
    this.isSamvaadNotify = toastPackage.title['isSamvaadNotify'];
    this.notificationImg= toastPackage.title['img'];
    this.notificationUrl= toastPackage.title['url'];
    this.additional_info = toastPackage.title['additional_info'];
  }
  ngOnInit(): void {
    if (this.toastPackage.toastType === 'notification') {
      const notificationRecieved: NOTIFICATION_RECEIVED = {
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.NOTIFICATION,
        entity_code: parseInt(this.toastPackage.title['id']),
        start_time: new Date().getTime(),
        type: NOTIFICATION_TYPE_SOURCE.SANCHAR
      }
      this.analyticSdk.analyticService.NOTIFICATION_RECEIVED(notificationRecieved);
    }
  }

  /**
   * event handler for secondary cta click
   * @returns boolean
   */
  primaryCTAClick(): boolean {
    this.toastPackage.triggerAction('primary');
    return false;
  }
  /**
   * event handler for secondary cta click
   * @returns boolean
   */
  secondaryCTAClick(): boolean {
    this.toastPackage.triggerAction('secondary');
    return false;
  }

  /**
   * event handled for close event
   * @returns boolean
   */
  closeToast(): boolean {
    this.fcs.notifyGrouping[this.notificationUrl] = null;
    this.toastPackage.triggerAction('close');
    return false;
  }

  /**
   * notification clicked
   */
  notificationClicked(): void {
    const extra  = [
      {key: 'VIEW_TYPE',
      value: NOTIFICATION_TYPE_SOURCE.SANCHAR}
    ];
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.NOTIFICATION,parseInt(this.toastPackage.title['id']),
    this.fcs.currentScreename, CTA_TYPE.CARD, ACTION_TYPE.CLICK, ACTION_ON.BACK_GROUND_NOTIFICATION, null, new Date().getTime(),extra);
  }
  /**
   * getter for animation duration
   */
  get animationDuration(): string {
    return `${(this.toastPackage.config.timeOut || 5000) / 1000}s`;
  }

  navigateToNotifi(): void {
    console.log(this.toastPackage, 'toasterpackage');
    console.log(this.notificationUrl, this.notificationType, this.additional_info.storyCode, this.additional_info);
    setTimeout(() => {
    if (this.isSamvaadNotify) {
      this.closeToast();
      this.fcs.navigateForChat(this.notificationUrl, true);
      return;
    }
    if (this.notificationType == NOTIFICATION_TYPE.FEED) {
      this.fcs.navigateToFeedDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.ONLINEEVENT) {
      this.fcs.navigateToNewEventDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.ONLINESESSION) {
      this.fcs.navigateToNewSessionDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.EVENTSCHEDULE) {
      this.fcs.navigateToEventScheduleList(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.NCP_DETAIL) {
      this.fcs.navigateToNCPDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.GAME_DETAIL) {
      this.fcs.navigateToGameDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.EDITORS_CHOICE) {
      if(this.notificationId !== 0){
        this.fcs.navigateToEditorsChoice(this.notificationId);
      }
      else
      this.fcs.navigateToGRFilter(NOTIFICATION_TYPE.EDITORS_CHOICE);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.PROFILE_SUGGESTION) {
      this.fcs.navigateToUserProfie(this.notificationUrl);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.CONNECTION) {
      this.fcs.navigateToConnection();
    }
    else if (this.notificationType == NOTIFICATION_TYPE.CONNECTION_HOME) {
      this.fcs.navigateToConnection();
    }
    else if (this.notificationType == NOTIFICATION_TYPE.PHARMA) {
      this.fcs.redirectOnSection(this.notificationType, this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.CHANNEL) {
      this.fcs.navigateToChannelDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.POST_CASE) {
      this.fcs.navigateToGRFilter(NAVIGATION_TARGET.CASES_POST);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.FEED_POST_LIST) {
      this.fcs.navigateToGRFilter(NAVIGATION_TARGET.POLL);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.FEED_CLINICAL_CASES) {
      this.fcs.navigateToGRFilter(NAVIGATION_TARGET.CASES);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.DRUG_SAMPLE) {
      this.fcs.navigateToDrugSample(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.WEBINAR_LIVE_LIST) {
      this.fcs.navigateToLiveWebinarList();
    }
    else if (this.notificationType == NOTIFICATION_TYPE.UPCOMING_WEBINAR_LIST) {
      this.fcs.navigateToUpcomingWebinar();
    }
    else if (this.notificationType == NOTIFICATION_TYPE.JOIN_INVITATION) {
      this.fcs.redirectOnSection(this.notificationType, this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.COMMUNITY_LIST) {
      this.fcs.navigateToCommunityDetail(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.PRODUCTCHATBOT) {
      this.fcs.navigateToProductChatBot(this.notificationId);
    }
    else if (this.notificationType == NOTIFICATION_TYPE.LINK) {
      window.open(this.notificationUrl, '_blank');
    }
    /**
     * commenting below code as part of ticket#WEBDC-8253
     */
    // else if (this.notificationType == NOTIFICATION_TYPE.SURVEY) {
    //   if(this.notificationUrl !== ''){
    //   this.getSurveyDetail(this.notificationUrl);}
    //   else
    //   this.router.navigateByUrl('/survey/list/not-attempted');
    // }

    else if (this.notificationType == 'invite_code_redirection') {
      this.router.navigateByUrl('/webprofile/notification?inviteCode=123');
    }
    else if (this.notificationType == 'session_schedule') {
      this.router.navigateByUrl('/eventv3/session/schedule/21/'+this.notificationId);
    }
    else if (this.notificationType === 'user_story') {
      this.fcs.openStory(this.notificationUrl, this.additional_info.storyCode, this.additional_info.viewerList);
    }
    else
    this.fcs.redirectOnSection(this.notificationType, this.notificationId);

  }, NUMBERS.FIVE_HUNDRED);
  }

  /**
   * commenting below code as part of ticket#WEBDC-8253
   */
  // getSurveyDetail(url): void {
  //   this.subscriptions.add(this.GetSer.getSurveyDetail(url).subscribe(data => {
  //     if ( data.status === NUMBERS.ONE) {
  //         if ( data.data.survey.remark === SURVEY_REMARKS.COMPLETED ) {
  //             this.fcs.navigateToSurveyCompletedList();
  //         } else  if ( data.data.survey.remark === SURVEY_REMARKS.ONGOING ) {
  //           this.fcs.navigateToSurvey(url);
  //         } else if(data.data.survey.remark === SURVEY_REMARKS.NOT_ATTEMPTED ) {
  //           this.fcs.openUrlInNewTab(data.data.survey.redirect_url);
  //         }
  //     } else if (data.status === NUMBERS.ZERO ) {
  //        if (data.error.code === ERROR_CODES.SURVEY_EXPIRED) {
  //           this.surveyError = data.error.msg;
  //        } else {
  //         this.fcs.errorHandeler(data);
  //        }
  //     }
  //    }));
  // }
}
