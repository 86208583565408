<div class="w-100">
  <form class="d-flex justify-content-between align-items-center w-100" [formGroup]="chatForm"
    (ngSubmit)="sendMessage()" (keydown.enter)="disableEnter ?  null: sendMessage()">
    <div class="form-textarea w-100 position-relative">
      <textarea id="sendMessageField" #sendMessageField
        [ngClass]="{'pr-64': showFileUploader,'pr-40': !showFileUploader}" formControlName="message"
        class="creator-textarea textarea-field" [placeholder]="samvaadLanguageText?.samvaad?.key36" (keydown.enter)="$event.preventDefault()"
        (input)="valueChange($event.target.value)">
      </textarea>
      <div [hidden]="!showEmoji"
        class="d-none d-md-block icon-hover"
        [ngClass]="{'emoji-container-without-upload' : !showFileUploader, 'emoji-container': showFileUploader}">
        <span class="icon d-flex justify-content-center custom-tooltip top" (click)="isEmoji=!isEmoji">
          <img class="emoji-icon" src="assets/samvaad-assets/images/icons/emoji-icon.svg" alt="cross" width="24" height="24"/>
          <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key10}}</span>
        </span>
        <div [hidden]="!isEmoji">
          <app-emojis (getEmoji)="getEmoji($event)" [toggleEmoji]="isEmoji" (onClose)="isEmoji = false"></app-emojis>
        </div>
      </div>
      <div class="file-upload-creator icon-hover" *ngIf="showFileUploader">
        <lib-file-upload #upload [fileLimit]="filesLimits" [maxFileSize]="uploadingSize" [multiple]="true"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG" (emitUploadFileList)="setBanner($event)" [samvaadLanguageText]="samvaadLanguageText">
          <div class="rounded text-center mb-2">
            <label class="cursor-pointer mb-2 icon custom-tooltip top">
              <input #bannerInput id="add" (click)="emitAttachmentClick($event)" class="d-none" type="file" accept="image/*"
                (change)="upload.handleFileInput($event)" [multiple]="upload.multiple" />
              <img src="assets/samvaad-assets/images/icons/attachment-icon.svg" alt="pic" width="24" height="24">
              <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key11}}</span>
            </label>
          </div>
        </lib-file-upload>
      </div>
    </div>
    <button *ngIf="showSubmitBtn" type="submit" [disabled]="chatForm.invalid"
      class="icon-hover ml-2 send-btn btn p-0 btn-default border-0 bg-transparent custom-tooltip top">
      <span [ngClass]="{'icon': !chatForm.invalid}" class="d-flex send-span">
        <img
          [src]="chatForm.invalid ? 'assets/samvaad-assets/images/icons/send-button.svg' : 'assets/samvaad-assets/images/icons/send-active.svg'"
          alt="send icon" width="24" height="24">
          <span class="tooltip-content" *ngIf="!chatForm.invalid">{{samvaadLanguageText?.samvaad_tooltips?.key12}}</span>
      </span>
    </button>
  </form>
</div>

<div *ngIf="isProfileUploadPreview">
  <app-chat-preview [profileForm]="files" [isChatPreview]="isProfileUploadPreview" [isMultiple]="true" [samvaadLanguageText]="samvaadLanguageText"
    [file]="chatForm.controls.file.value" [showCaption]="true" [title]="chatPreviewTitle" [imageSrc]="imageSrc"
    (onHide)="isProfileUploadPreview = false;closeImage()" (onSubmitPic)="uploadPicForMessage($event)">
  </app-chat-preview>
</div>

<div *ngIf="isErrorModal">
  <app-chat-error-modal [isErrorModal]="isErrorModal"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg" [paragraph]="errorMsg"
    (onHide)="isErrorModal = false" [samvaadLanguageText]="samvaadLanguageText"></app-chat-error-modal>
</div>

<div *ngIf="chatLoading">
  <app-chat-progress [isSmallLoading]="false" [isFullScreen]="1" [width]="60" [height]="60"></app-chat-progress>
</div>
