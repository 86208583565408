/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * Service imports.
 */
import { DqChatPanelService } from '../samvaad/dq-chat-panel.service';

/**
 * Local storage Service
 */
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  /**
   * Necessary instances.
   */
  constructor(private dqChatPanelService: DqChatPanelService) {}

  /**
   * set value to storage
   */
  setInLocalstorage(key, value): void {
    localStorage.setItem(key, value);
  }

  /**
   * clear all data from storage
   */
  clearLocalStorage(): void {
    if (localStorage.getItem('lang') != null) {
      let x = localStorage.getItem('lang');
      sessionStorage.clear();
      localStorage.clear();
      this.exitChatPanel();
      x = (x == '') ? 'en' : x;
      localStorage.setItem('lang', x);
    } else {
      sessionStorage.clear();
      localStorage.clear();
      this.exitChatPanel();
    }

  }

  /**
   * Exit the chat panel in case of logout.
   */
  exitChatPanel(): void {
    this.dqChatPanelService.exitMiniChatPanel.next(true);
  }

  /**
   * remove item from localstorage
   */
  removeItemLocalStorage(key): void {
    localStorage.removeItem(key);
  }

  /**
   * get item from local storage
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInLocalStorage(key): any {
    return localStorage.getItem(key);
  }

  /**
   * set value to storage
   */
  setInSessionstorage(key, value): void {
    sessionStorage.setItem(key, value);
  }

  /**
   * clear all data from storage
   */
  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * remove Item SessionStorage
   */
  removeItemSessionStorage(key): void {
    sessionStorage.removeItem(key);
  }

  /**
   * get InSession Storage
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInSessionStorage(key): any {
    return sessionStorage.getItem(key);
  }

}
