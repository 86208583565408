/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * This service is used for sqs video log.
 */
@Injectable({
  providedIn: 'root'
})
export class SqsdataService {
  /**
     * SQS data.
     */
  public SQSEventData = {
    id: '',
    event_name: '',
    product_type: '',
    product_type_id: '',
    session_id: '',
    local_id: '0',
    screen_name: '',
    user_id: 0,
    untrack_user_identifier: '',
    session_time: { start_time: 0, end_time: 0 },
    location: { latitude: '0', longitude: '0', local: '', time_zone: '' },
    track_id: '',
    seektime: 0,
    time_spent: 0,
    linked_product_restriction:{},
    is_module_webinar: 0
  };

  /**
   * Used to store the used id.
   */
  public user_id: number;

  /**
   * Used to hold the lat long.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public lat_long: any;

  /**
   * Used to hold unique id.
   */
  public unique_id = '0';

  /**
   * Used to hold the session id.
   */
  public session_id = '';

  /**
   * Used to hold the date onject.
   */
  public d = new Date();

  public linkedProductData: any;

  /**
   * Initialize the unique id.
   */
  constructor() {
    this.unique_id = this.makenumericid();
  }

  /**
   * Send data in sqs log.
   */
  sqsVideoLog(sessiontime, action, product_type, product_id, category, track_id, seektime, time_spent, linkedData, hasParent?): void {
    this.unique_id = this.makenumericid();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let location;
    {
      location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': timezone };
    }
    if (sessionStorage.getItem('event_session_id') != null && sessionStorage.getItem('event_session_id') != '') {
      this.session_id = sessionStorage.getItem('event_session_id');
    } else {
      const dd = new Date();
      this.session_id = 'web-' + dd.getTime() + this.makenumericid();
      sessionStorage.setItem('event_session_id', this.session_id);
    }

    this.SQSEventData = {
      id: this.unique_id,
      event_name: action,
      product_type: product_type,
      product_type_id: product_id,
      session_id: this.session_id,
      local_id: '0',
      screen_name: category,
      user_id: this.user_id,
      untrack_user_identifier: '',
      session_time: sessiontime,
      location: location,
      track_id: track_id,
      seektime: seektime,
      time_spent: time_spent,
      linked_product_restriction:linkedData,
      is_module_webinar: hasParent
    };
  }

  makenumericid(): string {
    let text = '';
    const possible = '0123456789';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}

