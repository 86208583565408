<div class="custom-dropdown mb-2">
  <form>
    <div>
      <div>
        <span class="icon-left">
          <img src="assets/themes/icons/search-icon.svg" class="mr-1 mb-1" alt="search" />
        </span>
        <span class="icon-right" *ngIf="dss.search_keyword!=''" (click)="resetSearchBox()">
          <img src="assets/themes/icons/cancel.svg" width="16" alt="close" />
        </span>
        <input class="search-height" type="text" [eventTracker]='eventTrackerConfig' autocomplete="off" id="searchBox"
          placeholder="{{search_place_holder}}" value="{{dss.search_keyword}}" (focus)="focusFunction()"
          (blur)="onBlurHandler()" (keyup)="getSearchList($event)"  (click)="searchBarClicked($event)" (keyup.enter)="searchAll($event)"
          (keydown)="keyDownHandler($event)" />
      </div>
    </div>
  </form>

  <div *ngIf="searchContentShow">
    <app-search-history-suggestion-list [searchData]="searchData" [focusItem]="focusItemIndex"
      (searchGivenText)="searchGivenText($event)" (pasteOnSearchBar)="pasteOnSearchBar($event)">
    </app-search-history-suggestion-list>
  </div>
</div>