/**
 * angular core import
 */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
/**
 * Rx js imports
 */
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
/**
 * library imports
 */
import * as moment from 'moment';
/**
 * Environment import
 */
import { environment } from '../../environments/environment';

/**
 * const import
 */
import { DEFAULT_COUNTRY_CODE, DEFAULT_IP_ADDR, DEFAULT_LANG, ERROR_CODES, MODULE_NAME } from '../constants/app.constants';
/**
 * Service import
 */
import { AccessTokenService } from '../auth/access-token.service';
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';
import { MatchUrlsHelper } from '../helper/matchUrls.helper';

@Injectable()
export class AccessTokenAdditionalHeadersInterceptor implements HttpInterceptor {

  /**
   * Device type.
   */
  private DEVICE_TYPE = environment.deviceType;

  constructor(private tokenService: AccessTokenService, private masterService: MasterserviceService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (MatchUrlsHelper.isAccessTokenSupportedApi(request)) {
      const dqApiOldHeaders = MatchUrlsHelper.matchHeaderUrls(request);
      let baseUrl = environment.API_URLS.magistrate;
      let headers = new HttpHeaders()
        .set('Accept-Language', localStorage.getItem('lang') || DEFAULT_LANG)
        .set('Content-type', 'application/json')
        .set('Timezone', moment.tz.guess())
        .set('Country-Code', localStorage.getItem('country_code') || DEFAULT_COUNTRY_CODE)
        .set('IP-Address', DEFAULT_IP_ADDR)
        .set('Device-Type', environment.deviceType)
        .set('Allow-Origin', window.location.hostname)
        .set('Application-Version', environment.versions.appVersion)
        .set('Request-Id', this.generateLocalId())
        .set('Module', MODULE_NAME.DQ)
        .set('ver', '7.0')

      for (const key in dqApiOldHeaders['lazyUpdate']) {
        headers = headers.set(dqApiOldHeaders['lazyUpdate'][key].name, dqApiOldHeaders['lazyUpdate'][key].value)
      }

      if (MatchUrlsHelper.isPublicApi(request)) {
        headers = headers.set('uuid', localStorage.getItem('uuid') || '');
        if(localStorage.getItem('externalUuid')) {
          headers = headers.set('uuid', localStorage.getItem('externalUuid'));
        }
        const authReq = request.clone({
          url: `${baseUrl}${request.url}`,
          headers
        });
        return next.handle(authReq);
      }

      const mcToken = this.tokenService.isAccessTokenAvailable();
      if (request.url.indexOf('https') !== -1) {
        baseUrl = '';
      }
      headers = headers.set('Authorization', `Bearer ${this.tokenService.token?.at}`);
      const authReq = request.clone({
        url: `${baseUrl}${request.url}`,
        headers
      });

      if (mcToken !== EMPTY) {
        return mcToken.pipe(
          mergeMap(response => {
            if (response?.at && response?.rt) {
              const headers = authReq.headers;
              const updatedAuthReq = authReq.clone({
                headers: headers.set('Authorization', `Bearer ${response?.at}`)
              });
              this.tokenService.tokenRequest$ = undefined;

              return next.handle(updatedAuthReq).pipe(
                catchError((error: HttpErrorResponse)=>{
                  return throwError(error);
                })
              );
            }
          }),
        );
      } else {
        return next.handle(authReq).pipe(
          catchError((error: HttpErrorResponse) => this.handleRetry(error, authReq, next))
        );
      }
    } else {
      return next.handle(request);
    }
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${this.DEVICE_TYPE}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  handleRetry(error: HttpErrorResponse, authReq: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>{
    if(error.status == ERROR_CODES.UNAUTHORIZED) {
      const displayObj = {
        errObj: { msg: '' },
        isShow: false,
        display_section: 'infoModal'
      };
      this.masterService.showWebModal.next(displayObj);
      const mcToken = this.tokenService.handleAccessTokenRequest();
      if (mcToken !== EMPTY) {
        return mcToken.pipe(
          mergeMap(response => {
            if (response && response?.at && response?.rt) {
              const headers = authReq.headers;
              const updatedAuthReq = authReq.clone({
                headers: headers.set('Authorization', `Bearer ${response?.at}`)
              });
              this.tokenService.tokenRequest$ = undefined;
              return next.handle(updatedAuthReq);
            }
          }),
        );
      }
    }
    return throwError(error);
  }
}
