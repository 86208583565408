/**
 * Angular imports.
*/
import { Component, EventEmitter, Output, Input } from '@angular/core';

/**
 * Service imports.
*/
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';

/**
 * Constant imports.
 */
import { TUTORIAL_FOR } from './../../constants/app.constants';

@Component({
  selector: 'app-new-chat-tutorial',
  templateUrl: './new-chat-tutorial.component.html',
  styleUrls: ['./new-chat-tutorial.component.css']
})
export class NewChatTutorialComponent {
   /**
   * Title of the tutorial.
   * Banner of the tutorial.
   * Message of the tutorial.
   * CTA of the tutorial.
   * Tutorial is for.
   * Arrow position css.
   * Tutorial position css.
  */
  @Input() tutorialTitle = this.mls?.languageText?.tutorial?.key17;
  @Input() tutorialBanner = 'assets/images/chat-tutorial-user.svg';
  @Input() tutorialMsg = this.mls?.languageText?.tutorial?.key18;
  @Input() tutorialCta = this.mls?.languageText?.tutorial?.key19;
  @Input() isTutorialFor = TUTORIAL_FOR.PIP;
  @Input() arrowPositionCss = '';
  @Input() tutorialPositionCss = '';


  /**
   * Emit start request outside.
   * Emit hide request outside.
   */
  @Output() emitStartRequest: EventEmitter<void> = new EventEmitter();
  @Output() emitHideModal: EventEmitter<void> = new EventEmitter();

  /**
   * Flag for the tutorial constant.
   */
  public tutorialFor = TUTORIAL_FOR;

  constructor(public mls: NewLanguageService) { }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.emitHideModal.emit();
  }

  /**
   * Request for chat.
   */
  startTutorial(): void {
    this.emitStartRequest.emit();
  }

}
