/**
 * Constant imports.
 */
import { API } from './api.constants';

/**
 * urls for the base url interceptor.
 */
export const BASE_URLS = {
  calendar: {
    [API.CALENDER_LIST]: API.CALENDER_LIST
  },
  amazon: {
    [API.DOCQUITY_STS_TOKEN]: API.DOCQUITY_STS_TOKEN
  },
  privacy_policy: {},
  terms_and_condition: {},
  analytics: {
    [API.ANALYTIC_RECORDS]: API.ANALYTIC_RECORDS
  },
  chat_credentials: {},
  auto_verification: {
    [API.REGISTRATION_SUCCESS_SCREEN]: API.REGISTRATION_SUCCESS_SCREEN
  },
  onboarding: {
    [API.VALIDATE_API_EXTRACTION_V1]: API.VALIDATE_API_EXTRACTION_V1,
    [API.REGISTRATION_CAPTCHA_USER_REQUEST]: API.REGISTRATION_CAPTCHA_USER_REQUEST,
    [API.REGISTRATION_OTP_REQUEST]: API.REGISTRATION_OTP_REQUEST,
    [API.REGISTRATION_OTP_RESEND]: API.REGISTRATION_OTP_RESEND,
    [API.REGISTRATION_UNIVERSITY_LIST]: API.REGISTRATION_UNIVERSITY_LIST,
    [API.REGISTRATION_INVITE_PROCESS]: API.REGISTRATION_INVITE_PROCESS,
    [API.USER_REGISTRATION]: API.USER_REGISTRATION,
    [API.REGISTRATION_VERIFY_MOBILE]: API.REGISTRATION_VERIFY_MOBILE,
    [API.USER_TOKEN]: API.USER_TOKEN,
    [API.USER_LOGOUT]: API.USER_LOGOUT,
  },
  quickblox: {},
  voip_node: {},
  comment: {
    [API.COMMENT_LIST]: API.COMMENT_LIST,
    [API.TAG_USER_SEARCH]: API.TAG_USER_SEARCH,
    [API.COMMENT_POST]: API.COMMENT_POST,
    [API.COMMENT_GET]: API.COMMENT_GET
  },
  bookmark: {
    [API.BOOKMARK_PRODUCT_LIST]: API.BOOKMARK_PRODUCT_LIST,
    [API.BOOKMARK_PRODUCT]: API.BOOKMARK_PRODUCT
  },
  event: {
    [API.EVENT_LIST_V2]: API.EVENT_LIST_V2,
    [API.EVENT_GOING]: API.EVENT_GOING,
    [API.EVENT_DETAIL]: API.EVENT_DETAIL,
    [API.EVENT_MEDIA_LIST]: API.EVENT_MEDIA_LIST,
    [API.EVENT_SESSION_LIST]: API.EVENT_SESSION_LIST,
    [API.EVENT_SESSION_DETAIL]: API.EVENT_SESSION_DETAIL,
    [API.EVENT_CERTIFICATE_HISTORY]: API.EVENT_CERTIFICATE_HISTORY,
    [API.EVENT_CERTIFICATE]: API.EVENT_CERTIFICATE,
    [API.EVENT_LIST_V3]: API.EVENT_LIST_V3,
    [API.EVENT_DETAILS_V3]: API.EVENT_DETAILS_V3,
    [API.SESSION_DETAILS_V3]: API.SESSION_DETAILS_V3,
    [API.USER_ACTIVITY_V3]: API.USER_ACTIVITY_V3,
    [API.CERTIFICATE_DETAIL_V3]: API.CERTIFICATE_DETAIL_V3,
    [API.SPEAKERS_LIST_V3]: API.SPEAKERS_LIST_V3,
    [API.EVENT_SCHEDULE]: API.EVENT_SCHEDULE,
    [API.SESSION_MAPPED_PRODUCT_V3]: API.SESSION_MAPPED_PRODUCT_V3,
    [API.EVENT_REGISTER]: API.EVENT_REGISTER,
  },
  voip: {},
  search: {
    [API.SEARCH_GET_SUGGESTIONS]: API.SEARCH_GET_SUGGESTIONS,
    [API.SEARCH_STORE_TERMS]: API.SEARCH_STORE_TERMS,
    [API.SEARCH_DOCQUITY_DATA_GROUP]: API.SEARCH_DOCQUITY_DATA_GROUP,
    [API.LIST_SEARCH_TREND]: API.LIST_SEARCH_TREND
  },
  community: {
    [API.COMMUNITY]: API.COMMUNITY,
    [API.HCF_CONTINUE_WATCH_VIEW_ALL]: API.HCF_CONTINUE_WATCH_VIEW_ALL,
    [API.SAVE_SEARCH_RECORD]: API.SAVE_SEARCH_RECORD
  },
  ncp: {
    [API.NCP_SUBTYPE_DETAILS]: API.NCP_SUBTYPE_DETAILS,
    [API.GAME_PLAY_COUNT]: API.GAME_PLAY_COUNT,
    [API.GAME_LEADER_BOARD]: API.GAME_LEADER_BOARD,
    [API.GAME_NCP_DETAIL]: API.GAME_NCP_DETAIL
  },
  card_rendering_analytics: {
    [API.CARD_RENDERING]: API.CARD_RENDERING
  },
  editor: {
    [API.EDITOR_CHOICE_DETAILS]: API.EDITOR_CHOICE_DETAILS
  },
  user: {
    [API.USER_DELETION_DELETE_ACCOUNT]: API.USER_DELETION_DELETE_ACCOUNT,
    [API.USER_DELETION_LIST]: API.USER_DELETION_LIST,
    [API.LIST_SUGGESTION_LIST]: API.LIST_SUGGESTION_LIST
  },
  clinic_consent: {
    [API.ACCEPT_CONSENT]: API.ACCEPT_CONSENT
  },
  chat_bot: {
    [API.CHATBOT_HISTORY]: API.CHATBOT_HISTORY
  },
  mr: {
    [API.MR_COMMENT_GET]: API.MR_COMMENT_GET
  },
  webinar: {
    [API.WEBINAR_AGORA_TOKEN_V2]: API.WEBINAR_AGORA_TOKEN_V2,
    [API.WEBINAR_CDN_DETAILS]: API.WEBINAR_CDN_DETAILS,
    [API.WEBINAR_POLL_SUBMIT]: API.WEBINAR_POLL_SUBMIT,
    [API.FORM_CONTROL_DETAIL]: API.FORM_CONTROL_DETAIL,
    [API.WEBINAR_V2_LIST]: API.WEBINAR_V2_LIST,
    [API.WEBINAR_STATUS]: API.WEBINAR_STATUS,
    [API.WEBINAR_NOTIFY_ME]: API.WEBINAR_NOTIFY_ME,
    [API.COOKIE]: API.COOKIE,
    // [API.WEBINAR_LIST]: API.WEBINAR_LIST,
  },
  payment: {},
  nudge: {
    [API.USER_TNC_UPDATE]: API.USER_TNC_UPDATE,
  },
  background: {
    [API.BACKGROUND_ACTIVE]: API.BACKGROUND_ACTIVE,
    [API.BACKGROUND_ACTIVE_V1]: API.BACKGROUND_ACTIVE_V1
  },
  report_user: {
    [API.REASON_CREATE_REPORT]: API.REASON_CREATE_REPORT,
    [API.REASON_LIST]: API.REASON_LIST
  },
  referral: {
    [API.REFERRAL_CONTENT]: API.REFERRAL_CONTENT,
    [API.REFERRAL_USER_POINT]: API.REFERRAL_USER_POINT
  },
  notification: {
    [API.NOTIFICATION_USER_LIST]: API.NOTIFICATION_USER_LIST,
    [API.SET_NOTIFICATION]: API.SET_NOTIFICATION,
    [API.DEVICE_TOKEN_GENERATE]: API.DEVICE_TOKEN_GENERATE
  },
  gr: {
    [API.GRAND_ROUND_LIST]: API.GRAND_ROUND_LIST
  },
  verification: {
    [API.VERIFICATION_UNDER_PROCESS]: API.VERIFICATION_UNDER_PROCESS,
    [API.REGISTRATION_VERIFY_MOBILE]: API.REGISTRATION_VERIFY_MOBILE
  },
  others: {
    [API.REPOST_DEFAULT_CONTENT]: API.REPOST_DEFAULT_CONTENT,
    [API.LOG_INFO_CARD_CROSS]: API.LOG_INFO_CARD_CROSS,
    // [API.ASSOCIATION_SUGGESTED_LIST]: API.ASSOCIATION_SUGGESTED_LIST,
    // [API.ASSIGN_ASSOCIATION]: API.ASSIGN_ASSOCIATION,
    // [API.USER_DOCUMENT_SUBMIT]: API.USER_DOCUMENT_SUBMIT,
    [API.LIST_SHARE_CONTENT]: API.LIST_SHARE_CONTENT,
    [API.COUNTRY_LIST_V4]: API.COUNTRY_LIST_V4,
    [API.MASTER_LIST]: API.MASTER_LIST,
    [API.USER_ASSOCIATION_LIST]: API.USER_ASSOCIATION_LIST,
    [API.TEXT_TO_SPEECH]: API.TEXT_TO_SPEECH,
    [API.HOME_DATA_LISTS]: API.HOME_DATA_LISTS,

    [API.CONNECT_WEB_SERVICE_SET]: API.CONNECT_WEB_SERVICE_SET,
    [API.VALIDATE_API_EXTRACTION]: API.VALIDATE_API_EXTRACTION,
    [API.IN_APP]: API.IN_APP,
    [API.META_PREVIEW] : API.META_PREVIEW
  },
  pharma: {
    [API.PHARMA_DRUG_LIST_V2]: API.PHARMA_DRUG_LIST_V2,
    [API.PHARMA_DRUG_SAMPLE_REQUEST_V2]: API.PHARMA_DRUG_SAMPLE_REQUEST_V2,
    [API.PHARMA_DRUG_DETAIL_V2]: API.PHARMA_DRUG_DETAIL_V2,
    [API.PHARMA_CHANNEL_HOME_LIST]: API.PHARMA_CHANNEL_HOME_LIST,
    [API.PHARMA_CHANNEL_SUBSCRIBE]: API.PHARMA_CHANNEL_SUBSCRIBE,
    [API.PHARMA_PRODUCT_LIST_V2]: API.PHARMA_PRODUCT_LIST_V2,
    [API.PHARMA_DETAIL_V4]: API.PHARMA_DETAIL_V4,
    [API.E_DETAILING_PHARMA_LIST]: API.E_DETAILING_PHARMA_LIST,
    [API.PHARMA_DRUG_SIMPLE_REQUEST]: API.PHARMA_DRUG_SIMPLE_REQUEST,
    [API.PHARMA_DRUG_TIME_SLOT]: API.PHARMA_DRUG_TIME_SLOT,
    [API.FILTER_LIST_V2]: API.FILTER_LIST_V2,
    [API.PHARMA_DRUG_LIST]: API.PHARMA_DRUG_LIST,

    [API.PHARMA_DRUG_DETAIL]: API.PHARMA_DRUG_DETAIL,
  },
  edetailing: {
    [API.E_DETAILING_THERAPEUTIC_LIST]: API.E_DETAILING_THERAPEUTIC_LIST,
    [API.E_DETAILING_PHARMA_TNC]: API.E_DETAILING_PHARMA_TNC,
    [API.E_DETAILING_PHARMA_DETAIL]: API.E_DETAILING_PHARMA_DETAIL,
    [API.E_DETAILING_THERAPEUTIC_CHANNEL_LIST]: API.E_DETAILING_THERAPEUTIC_CHANNEL_LIST,
    [API.E_DETAILING_THERAPEUTIC_DRUG_DETAIL]: API.E_DETAILING_THERAPEUTIC_DRUG_DETAIL,
    [API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE]: API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE,
    [API.E_DETAILING_DO_LATER]: API.E_DETAILING_DO_LATER,
    [API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE]: API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE,
    [API.E_DETAILING_PROFILE_MISSING_UPDATE]: API.E_DETAILING_PROFILE_MISSING_UPDATE,
    [API.E_DETAILING_DRUG_DETAILS]: API.E_DETAILING_DRUG_DETAILS
  },
  survey: {
    [API.SURVEY_TNC]: API.SURVEY_TNC
  },
  profile: {
    [API.PROFILE_TIMELINE]: API.PROFILE_TIMELINE,
    [API.PROFILE_VIEW_LIST]: API.PROFILE_VIEW_LIST,
    [API.PROFILE_DETAIL]: API.PROFILE_DETAIL,
    [API.PROFILE_UPDATE]: API.PROFILE_UPDATE,
    [API.PUBLIC_PROFILE]: API.PUBLIC_PROFILE,
    // [API.HOSPITAL_LIST]: API.HOSPITAL_LIST,
    // [API.UNIVERSITY_LIST_V2]: API.UNIVERSITY_LIST_V2,
    // [API.DEGREE_LIST]: API.DEGREE_LIST,
    [API.USER_LOGOUT]: API.USER_LOGOUT
  },
  journal: {
    [API.JOURNAL_LIST]: API.JOURNAL_LIST,
    [API.JOURNAL_DETAIL]: API.JOURNAL_DETAIL
  },
  feedback: {
    [API.PRODUCT_FEEDBACK]: API.PRODUCT_FEEDBACK
  },
  socket: {
    [API.HISTORICAL_POLL_LIST]: API.HISTORICAL_POLL_LIST,
    [API.LIVE_POLL_SUBMIT]: API.LIVE_POLL_SUBMIT
  },
  socket_comment: {
  },
  doctalks: {
    [API.MEDIA_STREAMING_LOG]: API.MEDIA_STREAMING_LOG,
    [API.DOC_TALKS_LIST]: API.DOC_TALKS_LIST,
    [API.DOC_TALKS_DETAIL]: API.DOC_TALKS_DETAIL,
  },
  cme: {
    [API.CME_GENERATE_CERTIFICATE]: API.CME_GENERATE_CERTIFICATE,
    [API.CME_SUBMIT_ANSWER_V2]: API.CME_SUBMIT_ANSWER_V2,
    [API.CME_DETAIL]: API.CME_DETAIL,
    [API.CME_HISTORY_V2]: API.CME_HISTORY_V2,
    [API.CME_LIST_V2]: API.CME_LIST_V2,
    [API.CME_SOURCE_LIST]: API.CME_SOURCE_LIST,
    [API.CME_OFFLINE_CME]: API.CME_OFFLINE_CME,
    [API.CME_DESCRIPTION]: API.CME_DESCRIPTION,
    [API.CME_MODULE_DETAIL_V2] : API.CME_MODULE_DETAIL_V2,
    [API.CME_MODULE_NEXT_STEP_JOURNEY] : API.CME_MODULE_NEXT_STEP_JOURNEY,
  },
  like: {
    [API.LIKE_LIST]: API.LIKE_LIST,
    [API.LIKE_LIKE_V2]: API.LIKE_LIKE_V2,
    [API.LIKE_LIST_V2]: API.LIKE_LIST_V2,
    [API.LIKE_LIKE]: API.LIKE_LIKE
  },
  feed: {
    [API.FEED_POST]: API.FEED_POST,
    [API.FEED_EDIT]: API.FEED_EDIT,
    [API.FEED_DELETE]: API.FEED_DELETE,
    [API.FEED_DETAIL_V4]: API.FEED_DETAIL_V4,
    [API.FEED_POLL_RECORD]: API.FEED_POLL_RECORD
  },
  speciality: {
    [API.SPECIALITY_LIST_V4]: API.SPECIALITY_LIST_V4,
    [API.REGISTRATION_SPECIALITY_LIST_V4]: API.REGISTRATION_SPECIALITY_LIST_V4
  },
  chat: {
    [API.CHAT_SEARCH_LIST]: API.CHAT_SEARCH_LIST,
    [API.CHAT_DIALOGUE]: API.CHAT_DIALOGUE,
    [API.CHAT_EDIT_DIALOGUE]: API.CHAT_EDIT_DIALOGUE,
    [API.CHAT_USER_CONNECTION]: API.CHAT_USER_CONNECTION,
    [API.CHAT_CONNECTION_LIST]: API.CHAT_CONNECTION_LIST,
    [API.OTHER_USER_INFO]: API.OTHER_USER_INFO,
    [API.CHAT_INITIATE]: API.CHAT_INITIATE
  },
  registration: {
    [API.USER_INVITE_CODE]: API.USER_INVITE_CODE
  },
  media_upload: {
    [API.CONNECT_SERVICE_F_UPLOAD_SET]: API.CONNECT_SERVICE_F_UPLOAD_SET,
    [API.FILE_UPLOAD]: API.FILE_UPLOAD
  },
  meta_data: {
    [API.CONNECT_WEB_SERVICE_GET]: API.CONNECT_WEB_SERVICE_GET,
    [API.CONNECT_WEB_SERVICE]: API.CONNECT_WEB_SERVICE,
  },

  forward_flow: {
    // [API.FORWORD_FLOWS_SUGGESTION]: API.FORWORD_FLOWS_SUGGESTION,
  },
  pvs: {
    [API.PROMO_VIEW_STRIP]: API.PROMO_VIEW_STRIP
  },
  join_meeting_web: {},

  ip_address_api: {},

  mr_connect_mobile: {},

  mr_connect: {
    [API.MR_MEETING_ACTION]: API.MR_MEETING_ACTION,
    [API.MR_MEETING_RESCHEDULE]: API.MR_MEETING_RESCHEDULE,
    [API.MR_MEETING_DETAILS]: API.MR_MEETING_DETAILS,
    [API.MR_CALENDER_LIST]: API.MR_CALENDER_LIST,
    [API.MR_CALENDER_DOTS]: API.MR_CALENDER_DOTS,

  },
  nestApiUrl: {
    [API.CARD_RENDERING]: API.CARD_RENDERING,
    [API.COMMUNITY]: API.COMMUNITY,
    [API.USER]: API.USER,
   // [API.FORWORD_FLOWS_SUGGESTION]: API.FORWORD_FLOWS_SUGGESTION,
    [API.PROMO_VIEW_STRIP]: API.PROMO_VIEW_STRIP,
    [API.OPEN_CONTENT] : API.OPEN_CONTENT,
    [API.OPEN_CONTENT_DETAIL] : API.OPEN_CONTENT_DETAIL,
    [API.OPEN_CONTENT_COUNTRY] : API.OPEN_CONTENT_COUNTRY,
    [API.OPEN_CONTENT_ANALYTICS] : API.OPEN_CONTENT_ANALYTICS,
    [API.OPEN_CONTENT_TOKEN_DETAIL] : API.OPEN_CONTENT_TOKEN_DETAIL
  },
  getLivePollUrls: {
    [API.HISTORICAL_POLL_LIST]: API.HISTORICAL_POLL_LIST,
    [API.LIVE_POLL_SUBMIT]: API.LIVE_POLL_SUBMIT
  },
  baseUrl2_0: {
    [API.USER_STORY_CAROUSEL_LIST]: API.USER_STORY_CAROUSEL_LIST,
    [API.VIEW_STORY_LOG]: API.VIEW_STORY_LOG,
    [API.STORY_DETAIL]: API.STORY_DETAIL,
    [API.STORY_DEFAULT_CONTENT]: API.STORY_DEFAULT_CONTENT,
    [API.CREATE_USER_STORY]: API.CREATE_USER_STORY,
    [API.SUBMIT_PROFIL_DATA]: API.SUBMIT_PROFIL_DATA,
    [API.GET_PROFILE_NUDGE_DATA]: API.GET_PROFILE_NUDGE_DATA,
    [API.STORY_USER_SEARCH_LIST]: API.STORY_USER_SEARCH_LIST,
    [API.LOG_STORY_REACTION]: API.LOG_STORY_REACTION
  },
  dfs: {
    [API.SURVEY_LIST]: API.SURVEY_LIST,
    [API.SURVEY_PENDING_LIST]:API.SURVEY_PENDING_LIST,
    [API.SURVEY_DETAIL]: API.SURVEY_DETAIL,
    [API.SURVEY_FOOTER]: API.SURVEY_FOOTER,
    [API.SURVEY_CONSENT]: API.SURVEY_CONSENT,
    [API.DISCARD_SURVEY]: API.DISCARD_SURVEY,

    [API.SURVEY_PATIENT_LIST]: API.SURVEY_PATIENT_LIST,
    [API.PIF_CME_STATUS]: API.PIF_CME_STATUS
  },
  keeper: {
    [API.CREATE_FILE]: API.CREATE_FILE,
    [API.UPDATE_PART_STATUS]: API.UPDATE_PART_STATUS,
    [API.COMPLETE_MULTIPART_STATUS]: API.COMPLETE_MULTIPART_STATUS,
    [API.READ_FILE]: API.READ_FILE,
    [API.READ_FILES]: API.READ_FILES,
    [API.RECREATE_MULTIPART_URL]: API.RECREATE_MULTIPART_URL,
    [API.RECREATE_SINGLE_URL]: API.RECREATE_SINGLE_URL,
    [API.FORWORD_FLOWS_SUGGESTION]: API.FORWORD_FLOWS_SUGGESTION,
    // [API.COUNTRY_LIST_V4]: API.COUNTRY_LIST_V4,
    [API.ASSOCIATION_SUGGESTED_LIST]: API.ASSOCIATION_SUGGESTED_LIST,
    [API.ASSIGN_ASSOCIATION]: API.ASSIGN_ASSOCIATION,
    [API.UNIVERSITY_LIST_V2]: API.UNIVERSITY_LIST_V2,
    [API.DEGREE_LIST]: API.DEGREE_LIST,
    [API.HOSPITAL_LIST]: API.HOSPITAL_LIST,
    [API.COUNTRY_LIST_V5]: API.COUNTRY_LIST_V5,
    [API.WEBINAR_LIST]: API.WEBINAR_LIST,
    [API.USER_DOCUMENT_SUBMIT]: API.USER_DOCUMENT_SUBMIT,
  },
  openContent: {
    [API.OPEN_CONTENT] : API.OPEN_CONTENT,
    [API.OPEN_CONTENT_DETAIL] : API.OPEN_CONTENT_DETAIL,
    [API.OPEN_CONTENT_COUNTRY] : API.OPEN_CONTENT_COUNTRY,
    [API.OPEN_CONTENT_ANALYTICS]: API.OPEN_CONTENT_ANALYTICS
  }
};
