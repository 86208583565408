/**
 * Angular imports.
 */
import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
/**
 * Rxjs imports.
 */
import { Subject } from 'rxjs';
/**
 * Package imports
 */
import { LoginSdk } from 'onboarding2.0';
/**
 * Interface imports
 */
import { LoginSdkAuthDataResponse, LoginSdkParameter,SdkCallback, TokenDetail } from '../onboarding/interface/global.model';
/**
 * Service imports
 */
import { NewLanguageService } from '../dataservices/new-language/new-language.service';
import { LocalstorageService } from '../auth/localstorage.service';
import { AccessTokenService } from '../auth/access-token.service';
/**
 * Constant imports
 */
import { LOGIN_SDK, NUMBERS, SDK_PAGE_TYPE } from '../constants/app.constants';
import { OpenAccessService } from './open-access.service';


@Injectable({
  providedIn: 'root'
})
export class PurpleOnboarding {
  /**
   * callback defined
   */
  public callback: SdkCallback = { element: document.createElement('div'), view: false, properties: {newUser:false , language:'es', isSocialLogin:false} };
  /**
   * response data on login
   */
  public sdkData : LoginSdkAuthDataResponse;
  /**
   * Subject for successful login
   */
  private newLoginSubject = new Subject<string>();
  login$ = this.newLoginSubject.asObservable();
  
  /**
   * Loader switch for open access
   */
  public loaderSwitch = false;

  constructor(
    private loginSdk: LoginSdk,
    private router: Router,
    private mls: NewLanguageService,
    private storageService: LocalstorageService,
    private oas : OpenAccessService,
    private accessTokenService: AccessTokenService
  ) {
    loginSdk.component((callback) => {
      this.callback = { element: callback.element, view: callback.view, tokenResponse: callback.tokenResponse, properties : callback.properties };
      if (callback.tokenResponse) {
      this.sdkData = callback.tokenResponse;
      if(this.sdkData.at && this.sdkData.rt){
        this.loaderSwitch = true;
        localStorage.setItem('fromOpenAccess', '1');
        localStorage.setItem('token','');
        const language = callback.properties.language;
        localStorage.setItem('lang',language);
        this.mls.userLoginFlow = NUMBERS.ONE;
        const {at, rt, expin, rexpin, ttype} = callback.tokenResponse;
        this.setTokenDetailsToStorage({at, rt, expin, rexpin, ttype});
        this.newLoginSubject.next();
      }
      }
      if(!callback.view && !callback.tokenResponse){
        this.oas.skipPopUp = true;
        this.oas.isHeaderLoginButtonClicked = false;
      }
      if (this.callback.view) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    });
  }

/**
 * Loads the Login SDK with the specified parameters and updates the login container.
 *
 * @param parameters - The parameters to be passed to the Login SDK.
 * @param loginContainer - The ElementRef representing the login container in the DOM.
 */
  public loadSdk(parameters: LoginSdkParameter, loginContainer: ElementRef): void {
    this.loginSdk.loadParameters(parameters);
    if (loginContainer) {
      loginContainer.nativeElement.classList.add('show-login-container');
      loginContainer.nativeElement.appendChild(this.callback.element);
      const firstChild = loginContainer.nativeElement?.firstElementChild;
      const lastChild = loginContainer.nativeElement?.lastElementChild;
      if (firstChild && firstChild !== lastChild) {
        loginContainer.nativeElement.replaceChild(lastChild, firstChild);
      }
    }
  }

 /**
  * Sets token details to local storage.
  *
  * @param data - The token details to be stored in local storage.
  */
  public setTokenDetailsToStorage(data): void {
  this.storageService.setInLocalstorage('mcTokenDetail', JSON.stringify(data));
  const token: TokenDetail = JSON.parse(localStorage.getItem('mcTokenDetail')) || null;
  this.accessTokenService.calculateRefreshInterval(token?.expin)
  
 }

 /**
  * Calls the Login SDK with the specified container element.
  *
  * @param container - The ElementRef representing the container in which the Login SDK will be loaded.
  */
  public callLoginSdk(container: ElementRef) : void {
  const openLang = localStorage.getItem('openAccess')? (JSON.parse(localStorage.getItem('openAccess'))?.openLang):'en';
  const sdkParameters: LoginSdkParameter = {
    country: 'MX', language: 'es', version: LOGIN_SDK.VERSION, module: LOGIN_SDK.MODULE, client: LOGIN_SDK.CLIENT, page: SDK_PAGE_TYPE.SIGNUP, defaultAction: LOGIN_SDK.DEFAULT_ACTION , disableClose : LOGIN_SDK.DISABLE_CLOSE
  };
  if(openLang=== 'es'){
  this.loadSdk(sdkParameters, container);
  }
  else{
    this.router.navigateByUrl('/login');
    console.log('blue');
  }
  }

}
