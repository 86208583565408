<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div class="mt-3 mr-3">
      <div ngbDropdown class="d-block float-right">
        <a class="lnguage-selector cursor-pointer position-relative pl-5 lnguage-selector-card" id="dropdownBasic1" ngbDropdownToggle [pAppAnalyticsEvent]="langRecord()">

          <img class="position-absolute s-l-icon" src="assets/themes/icons/language-icon.svg" alt="">
          <small class="primary-small">
            <span class="color-grey-500">{{mls?.languageText?.input_screen?.key6}}
            <span *ngIf="mls?.is_lskey_display==1">(input_screen.key6)</span></span>
          </small>
          <span class="d-block secondary-paragraph-bold"><span class="color-grey-700">{{lang_value}}</span></span>

          <img class="position-absolute arrow-position" src="assets/themes/icons/language-drop-icon.svg" alt="">
        </a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list paragraph">
          <a [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key2,"product_type":"", "product_id":""}'
            class="cursor-pointer " (click)="selectLang('en')" [ngClass]="{'color-black': selected_lang=='en','color-grey-500': selected_lang!='en'}"
            ngbDropdownItem>{{mls?.languageText?.input_screen?.key7}}<span
              *ngIf="mls?.is_lskey_display==1">(input_screen.key7)</span></a>
          <a [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key2,"product_type":"", "product_id":""}'
            class="cursor-pointer" (click)="selectLang('in')" [ngClass]="{'color-black': selected_lang=='in','color-grey-500': selected_lang!='in'}"
            ngbDropdownItem>{{mls?.languageText?.input_screen?.key8}}<span
              *ngIf="mls?.is_lskey_display==1">(input_screen.key8)</span></a>
          <a [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key2,"product_type":"", "product_id":""}'
            class="cursor-pointer" (click)="selectLang('vi')" [ngClass]="{'color-black': selected_lang=='vi','color-grey-500': selected_lang!='vi'}"
            ngbDropdownItem>{{mls?.languageText?.input_screen?.key10}}<span
              *ngIf="mls?.is_lskey_display==1">(input_screen.key10)</span></a>
          <a [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key2,"product_type":"", "product_id":""}'
            class="cursor-pointer" (click)="selectLang('zh')" [ngClass]="{'color-black': selected_lang=='zh','color-grey-500': selected_lang!='zh'}"
            ngbDropdownItem>{{mls?.languageText?.input_screen?.key25}}<span
              *ngIf="mls?.is_lskey_display==1">(input_screen.key25)</span></a>
          <a [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key2,"product_type":"", "product_id":""}'
        class="cursor-pointer" (click)="selectLang(langAbbreviations.JAPANESE)" [ngClass]="{'color-black': selected_lang === langAbbreviations.JAPANESE,'color-grey-500': selected_lang != langAbbreviations.JAPANESE}"
        ngbDropdownItem>{{mls?.languageText?.input_screen?.key26}}<span
          *ngIf="mls?.is_lskey_display== numbers.ONE">(input_screen.key26)</span>
        </a>
        <a 
        class="cursor-pointer" (click)="selectLang(langAbbreviations.SPANISH)" [ngClass]="{'color-black': selected_lang === langAbbreviations.SPANISH,'color-grey-500': selected_lang != langAbbreviations.SPANISH}"
        ngbDropdownItem>{{mls?.languageText?.open_article?.key43}}
        </a>
        </div>
      </div>

    </div>
    <div class="px-3 py-5 mt-4">
      <p class="mb-3 mt-4 heading-3"><span class="color-deep-green">{{mls?.languageText?.input_screen?.key1}} <span
          *ngIf="mls?.is_lskey_display==1">(input_screen.key1)</span></span></p>
      <p class="paragraph"><span class="color-grey-700">{{mls?.languageText?.input_screen?.key2}}<span
          *ngIf="mls?.is_lskey_display==1">(input_screen.key2)</span></span></p>
      <div>
        <div class="mt-3 px-0 country-dropdown-input">
          <div class="single-dropdown hide-cross">
            <app-basic-multiselect-select
              [suggestionValue]="onboardingService.ddl_data.list_data" [selectedItems]="selectedItems" [selectedValues]="onboardingService.ddl_data.selected_data"
              [suggestionList]="suggestionList" [dropdownSettings]="{searchPlaceholderText: mls.languageText.multiSelectDropDown.key1,
                singleSelection: true,
                enableSearchFilter: true,
                showCheckbox: false,
                classes: '',
                enableCheckAll: false,
                enableFilterSelectAll: false,
                disabled: false,
                single_select: true,
                enable_search: true,
                position: 'top',
                autoPosition: false,
                tagToBody: true}" (emitOnItemSelect)="resetDDLData({item: [$event], identifier: onboardingService.ddl_data.identifier})">
            </app-basic-multiselect-select>
            <ng-template let-selectedCode #selectedItems>
              <div>
                <img class="mr-2" [src]="selectedCode.image" alt="flag" width="19" height="14" onerror="this.src='assets/images/default-icon.jpg'">
                +{{selectedCode.code}}
              </div>
            </ng-template>
            <ng-template let-countryCodes #suggestionList>
              <div>
                <img class="mr-2" [src]="countryCodes.image" alt="flag" width="19" height="14" onerror="this.src='assets/images/default-icon.jpg'">
                {{countryCodes.code}} {{countryCodes.name}}
              </div>
            </ng-template>
          </div>
          <div class="form-input">
            <input class="input-field" type="text" placeholder="Name" [(ngModel)]='phonenumber' (keyup)="Inputvalid()" autocomplete="off"
            (keypress)="numberInput($event)" #mobile_no id="mobile_no" type="tel"
            placeholder="{{mls?.languageText?.input_screen?.key3}}{{(mls?.is_lskey_display==1)? '(input_screen.key3)':''}}"
            value="{{user_mob_data?.mobile_no}}"/>
          </div>
        </div>
        <p class="primary-small color-error mb-3">{{err_msg}}</p>
      </div>
      <div class="row clearfix m-0">
        <div class="float-left privacy-icon">
          <img src="assets/themes/icons/black-lock.svg" alt="Lock Icon" height="14px" width="14px"/>
        </div>
        <div class="float-left privacy-text">
          <p class="mb-4 pb-2 primary-small">
            <span class="color-grey-700">{{mls?.languageText?.input_screen?.key4}}
            <span *ngIf="mls?.is_lskey_display==1">(input_screen.key4)</span></span>
          </p>
        </div>

      </div>

      <div class="form-input error pb-2">
        <p class="help-text">{{api_err_msg}}</p>
      </div>
      <button class="btn-design w-100 mb-3" [disabled]="showColor==false" type="button" [ngClass]="[showColor ? 'filled' : 'filled-disabled',fcs.device_detail.is_mobile ? 'medium' : 'large']"
      id="requestOtpClick" ngHcaptchaInvisibleButton (verify)="verifyHcpToken($event)" (expired)="onExpired($event)"
      (error)="onError($event)" [appOnboardingEventTracker]='{"category":mls.googleEventText.input_screen.category,"action":mls.googleEventText.input_screen.key1,"product_type":"", "product_id":""}'>
      <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      {{mls?.languageText?.input_screen?.key5}}<span *ngIf="mls?.is_lskey_display==1">(input_screen.key5)</span></button>
      <div class="float-left primary-small color-grey-700">
        By Clicking Agree & Request OTP, you agree to our
        <a class="cursor-pointer color-blue" (click)="redirectToTnc()" [pAppAnalyticsEvent]="termsRecord()">Term & Condition</a> &
        <a class="cursor-pointer color-blue" (click)="redirectToPrivacy()" [pAppAnalyticsEvent]="privacyRecord()">Data Policy</a>
      </div>
    </div>

  </div>
</div>


<app-whitelisting-modal *ngIf="whteListModal" (hideModal)="hideModal($event)">
  <div *ngIf="whileListErrorCode === OTP_WHITELIST_ERROR_CODE.WHITELIST_ERROR_CODE">
  <div class="p-sm-3">
    <div class="text-center">
      <img src="assets/images/suspicious-activity.webp">
      <h4 class="heading-4">Suspicious activity detected!</h4>
      <p class="pt-3 pb-4 secondary-paragraph">
        {{mls.languageText.input_screen.key12}}
        <a href="mailto:support@docquity.com" class="color-success">{{supportEmail.EMAIL}}</a>
        {{mls.languageText.input_screen.key13}}
      </p>
    </div>
    <div>
      <div class="form-input">
        <label for="userPhone" class="input-label">
          {{mls.languageText.input_screen.key15}}
        </label>
        <input class="input-field w-100 mb-2" id="userPhone"  value="{{whiteListMobNo}}" type="text" disabled>
      </div>
      <div class="form-input">
        <label for="useremail" class="input-label">
          {{mls.languageText.input_screen.key16}}
        </label>
        <input class="input-field w-100" id="useremail" type="text" [(ngModel)]="whileListEmail" placeholder={{mls.languageText.input_screen.key16}}>
      </div>
      <div class="form-input" [ngClass]="{'error': !isValidWhiteListEmail && whileListEmail !== ''}">
        <p *ngIf="!isValidWhiteListEmail && whileListEmail !== ''" class="help-block">
          {{mls.languageText.input_screen.key17}}
        </p>
      </div>
    </div>
    <div class="mt-4 d-block text-right">
      <button type="button" class="btn-design medium filled" (click)="submitWhitelistData()">
        {{mls.languageText.input_screen.key14}}
      </button>
    </div>
  </div>

</div>

<div *ngIf="whileListErrorCode === 2014 || whileListErrorCode === 2000">
  <div class="p-3">
    <div class="text-center" *ngIf="whileListErrorCode === 2000">
      <img src="assets/images/whitelist-success.svg">
      <h4 class="heading-4"> {{mls.languageText.input_screen.key23}}</h4>
      <p class="pt-3 secondary-paragraph">{{mls.languageText.input_screen.key18}}
        <a href="mailto:support@docquity.com" class="color-success">{{supportEmail.EMAIL}}</a>
          {{mls.languageText.input_screen.key19}}
      </p>
    </div>

    <div class="text-center" *ngIf="whileListErrorCode === 2014">
      <img src="assets/images/whitelist-success.svg">
      <h4 class="heading-4"> {{mls.languageText.input_screen.key24}}</h4>
      <p class="pt-3 secondary-paragraph">{{mls.languageText.input_screen.key21}}
        <a href="mailto:support@docquity.com" class="color-success">{{supportEmail.EMAIL}}</a>
          {{mls.languageText.input_screen.key22}}
      </p>
    </div>
  </div>
    <div class="d-block text-center">
      <button type="button" class="btn-design small filled" (click)="hideModal(false)">
        {{mls.languageText.input_screen.key20}}
      </button>
    </div>
</div>

</app-whitelisting-modal>
