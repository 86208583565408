<div class="chat-group" [hidden]="isSearchUser">
  <form [formGroup]="chatGroupForm" (ngSubmit)="createGroup()">
    <div class="chat-group-heading d-flex align-items-center p-3 icon-hover">
      <span class="icon custom-tooltip bottom" (click)="goBack()">
        <img class="mr-2 cursor-pointer" src="assets/samvaad-assets/images/icons/search-user-left-arrow.svg" alt="back">
        <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key5}}</span>
      </span>
      <h4 class="heading-4">
        {{groupTitle}}
      </h4>
    </div>
    <div class="d-flex">
      <div class="mt-3 pl-3 flex-shrink-0">
        <div class="position-relative" *ngIf="!isViewMode">
          <lib-file-upload #upload [fileLimit]="fileLimits" [maxFileSize]="fileUploadSize" [multiple]="false"
            accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG" (emitUploadFileList)="setBanner($event)" [samvaadLanguageText]="samvaadLanguageText">
            <div *ngIf="!groupDetail?.banner && !imageSrc && !isViewMode" class="rounded text-center mb-2"
              id="drag_zone" (drop)="upload.dropFiles($event)" (dragover)='upload.dragOverHandler($event)'>
              <label class="cursor-pointer mb-2">
                <input #bannerInput id="add" class="d-none" type="file" accept="image/*"
                  (change)="upload.handleFileInput($event)" [multiple]="upload.multiple" />
                <img src="assets/samvaad-assets/images/icons/group-profile-pic.svg" alt="pic" width="120" height="120">
              </label>
            </div>
            <div class=" mb-2 rounded text-center w-100" *ngIf="imageSrc || groupDetail?.banner">
              <span class="position-relative img-prev">
                <label class="cursor-pointer mb-2">
                  <input #bannerInput id="add" class="d-none" type="file" accept="image/*"
                    (change)="upload.handleFileInput($event)" [multiple]="upload.multiple" />
                  <img class="rounded" [src]="imageSrc || dpSanitize(groupDetail?.banner)" alt="preview" width="120"
                    height="120" onerror="this.src='assets/samvaad-assets/images/icons/group-default.svg'">
                  <div class="hover-effect">
                    <span class="change">
                      {{samvaadLanguageText?.samvaad_create_edit_group?.key1}}
                    </span>
                  </div>
                </label>
              </span>
            </div>
          </lib-file-upload>
        </div>
        <div *ngIf="isViewMode" class="text-center">
          <img class="rounded" [src]="dpSanitize(banner.value)" alt="preview" width="120" height="120"
            onerror="this.src='assets/samvaad-assets/images/icons/group-default.svg'">
        </div>
      </div>
      <div class="form-input w-100 p-3">
        <label for="" class="input-label">{{!isViewMode ? samvaadLanguageText?.samvaad_create_edit_group?.key3+'*' :
          samvaadLanguageText?.samvaad_create_edit_group?.key2}}</label>
        <input style="height: 40px;" [readonly]="isViewMode" class="input-field" type="text" [placeholder]="samvaadLanguageText?.samvaad_create_edit_group?.key2"
          [maxLength]="groupTitleMaxLength" formControlName="name" />
        <small class="secondary-small" *ngIf="!isViewMode">({{this.samvaadLanguageText?.samvaad_create_edit_group?.key22}})</small>
      </div>
    </div>
    <div class="group-members mt-3" [ngClass]="{
        'height-with-create': !isViewMode && chatView === chatViewConstant.FULL,
        'height-without-create':isViewMode && chatView === chatViewConstant.FULL,
        'mini_height-with-create': !isViewMode && chatView === chatViewConstant.MINI,
        'mini_height-without-create':isViewMode && chatView === chatViewConstant.MINI
      }">
      <p class="px-3 paragraph" *ngIf="groupMembers?.length">
        {{samvaadLanguageText?.samvaad_create_edit_group?.key4}}({{groupMembers.length}})
      </p>
      <div *ngFor="let list of groupMembers | roleBy:'asc':'isAdmin':'isMe'"
        [ngClass]="{'d-flex justify-content-between': list?.isAdmin || list?.isMe}" class="individual-info-box pl-3">
        <app-individual-info-box [ngClass]="{'flex-1 min-width-0': list?.isAdmin || list?.isMe}"
          [info]="{pic: list?.profile_pic ? list.profile_pic : '', name: list.full_name, badgePic: ''}"
          [isCursorPointer]="false" [profileSecondary]="list?.specialities[0]?.speciality_name"
          [endSrc]="(!isViewMode && !list.isMe && !list.isAdmin) ? 'assets/samvaad-assets/images/icons/chat-window-cross.svg': ''"
          [isEndSrcHoverState]="!isViewMode && !list.isMe && !list.isAdmin ? true :  false" [endSrcTooltip]="samvaadLanguageText?.samvaad_tooltips?.key15" (onEndSrcClick)="removeGroupMember(list)"
          (onAvatarClick)="emitAvatarClick(list.custom_id)">
        </app-individual-info-box>
        <div class="align-self-center">
          <span *ngIf="list?.isAdmin" class="chip small filled info">
            {{samvaadLanguageText?.samvaad_create_edit_group?.key5}}
          </span>
          <span *ngIf="list?.isMe" class="chip small filled success ml-2">
            {{samvaadLanguageText?.samvaad?.key1}}
          </span>
        </div>
      </div>
      <p class="p-3 paragraph" *ngIf="!isViewMode && newMembers?.length">
        {{samvaadLanguageText?.samvaad_create_edit_group?.key6}}({{newMembers.length}})
      </p>
      <div *ngFor="let list of newMembers" class="individual-info-box pl-3">
        <app-individual-info-box
          [info]="{pic: list?.profile_pic ? list.profile_pic : '', name: list.full_name, badgePic: ''}"
          [isCursorPointer]="false" [profileSecondary]="list?.specialities[0]?.speciality_name"
          [endSrc]="!isViewMode ? 'assets/samvaad-assets/images/icons/chat-window-cross.svg': ''"
          [isEndSrcHoverState]="!isViewMode && !list.isMe && !list.isAdmin ? true :  false" [endSrcTooltip]="samvaadLanguageText?.samvaad_tooltips?.key15" (onEndSrcClick)="removeNewMembers(list)">
        </app-individual-info-box>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center px-2 pb-3" *ngIf="!isViewMode">
      <button type="button" class="btn-design outline small h-auto ml-2" (click)="AddUsers()">
        {{samvaadLanguageText?.samvaad_create_edit_group?.key7}}
      </button>
      <button type="submit"
        [disabled]="chatGroupForm.invalid || (groupMembers?.length > 1 ? false : !this.newMembers?.length)"
        [ngClass]="chatGroupForm.invalid || (groupMembers?.length  > 1 ? false : !this.newMembers?.length) ? 'filled-disabled': 'filled'"
        class="btn-design medium h-auto">
        {{submitBtn}}
      </button>
    </div>
  </form>
</div>
<div class="w-100 chat-group position-relative" *ngIf="isSearchUser">
  <app-search-users [isLoading]="searchUserLoading" key="connection" [maxLimit]="maxLimitUsers" [backKey]="false" [chatView]="chatView"
    [showFooter]="true" [selectedLabel]="samvaadLanguageText?.samvaad_create_edit_group?.key6"
    [noDataFoundLabel]="samvaadLanguageText?.samvaad?.key21" [isSearchUser]="isSearchUser" [alreadyExistLength]="groupMembers?.length ? groupMembers?.length : 0"
    [title]="samvaadLanguageText?.samvaad_create_edit_group?.key8" [btnText]="samvaadLanguageText?.samvaad?.key12"
    [selectedList]="newMembers" [endSrc]="false" [offset]="searchUserOffset" [connectionsList]="searchUsersList[0]"
    [isCheckBox]="true" [suggestionsList]="searchUsersList[1]" [samvaadLanguageText]="samvaadLanguageText"
    (onSearchKeyword)="searchUsers($event.keyword, $event.key, $event.offset)" (onMaxLimitEvent)="emitMaxLimitEvent($event)"
    (onViewAll)="searchUsers($event.keyword, $event.key, $event.offset)" (onHide)="isSearchUser = false"
    (onSubmit)="getGroupList($event)" (onSearchInputClick)="searchInputClick()" (onVisit)="emitOnNewMemberVisit($event)" (onLeave)="emitOnNewMemberLeave($event)">
  </app-search-users>
</div>
<div *ngIf="chatGroupMiniLoading">
  <app-chat-progress [isSmallLoading]="false" [isFullScreen]="2" [width]="60" [height]="60"></app-chat-progress>
</div>
<div *ngIf="isProfileUploadPreview">
  <app-chat-preview [isChatPreview]="isProfileUploadPreview" [profileForm]="banner.value" [showCaption]="false"
    [title]="samvaadLanguageText?.samvaad?.key13" [imageSrc]="imageSrc" [samvaadLanguageText]="samvaadLanguageText"
    (onHide)="isProfileUploadPreview = false;closeImage()" (onSubmitPic)="uploadGroupPic($event)">
  </app-chat-preview>
</div>
<div *ngIf="chatGroupLoading">
  <app-chat-progress [isSmallLoading]="false" [isFullScreen]="1" [width]="60" [height]="60"></app-chat-progress>
</div>
<div *ngIf="isRemoveConfirm">
  <app-chat-confirm-modal [isConfirmModal]="isRemoveConfirm"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg"
    [heading]="samvaadLanguageText?.samvaad_create_edit_group?.key9"
    [paragraph]="samvaadLanguageText?.samvaad_create_edit_group?.key10" [btnCancel]="samvaadLanguageText?.samvaad?.key4"
    [btnText]="samvaadLanguageText?.samvaad_create_edit_group?.key11" (onSubmit)="onRemove()"
    (onHide)="isRemoveConfirm = false">
  </app-chat-confirm-modal>
</div>
