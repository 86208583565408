/**
 * Angular imports.
 */
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';

/**
 * Constant imports.
 */
import { MINI_PLAYER_CONTROLS_DURATION, WEBINAR_STATUS, NUMBERS, WEBINAR_TYPE, WEBINAR_AGORA_STATUS, WEBINAR_AGORA_ROLE, PRODUCT_TYPE_CONSTANT, SHOW_NEW_TAG, ACTIVE_STEPS, TUTORIAL_LOCAL_STORAGE, PIP_TAG_LOCAL_STORAGE, SCREEN_NAME, MEDIA_TYPE, DOC_TALK_ENDED, CONSENT_TYPE, ONLY_LABEL_CONSENT, PLAYER_STAGE, PIP_CLASS } from '../../../constants/app.constants';
import { EVENT_NAME } from '../../../analytics-event/analytics.constant';

/**
 * Service imports.
 */
import { DqVideoPlayerService } from '../dq-video-player.service';
import { WebsocketService } from '../../websocket.service';
import { NewLanguageService } from '../../new-language/new-language.service';
import { AnalyticsEventService } from '../../../analytics-event/analytics-event.service';
import { ConsentService } from '../../consent/consent.service';
import { PollSocketService } from '../../../dataservices/poll-socket.service';
import { PostApiService } from '../../post-api.service';
import { GetService } from '../../get.service';

/**
 * Rxjs imports.
 */
import { of, Subscription } from 'rxjs';
import { delay, takeWhile, tap } from 'rxjs/operators';

/**
 * Modal imports.
 */
import { AnalyticsInterface, EventDataKey } from '../../../analytics-event/analytics.model';
import { DataStorageService } from '../../data-storage.service';
import { WebinarContinueWatchLog } from 'src/assets/service-worker/dev/WebinarContinueWatchLog.service';

/**
 * This component is design to store the players for the docquity.
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-dqVideoPlayer',
  templateUrl: './dqVideoPlayer.component.html',
  styleUrls: ['./dqVideoPlayer.component.scss']
})
export class DqVideoPlayerComponent implements OnInit, OnDestroy {

  /**
   * Store the webinar detail.
   * Webinar status from constant.
   * control event.
   * Used for hiding the video controls bar
   * Constant steps for active tutorial.
   * Tutorial started flag.
   * Active step of the tutorial.
   * Tutorial popover is open/close flag.
   * Show/hide new tag on controls.
   * Number of time pip new tag show.
   * Flag to auto play the agora.
   * Event start time for pip mode.
   * Event end time for pip mode.
   * Analytics data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public doctalkDetail: any;
  public WEBINAR_STATUS = WEBINAR_STATUS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public timeoutId: any;
  public duration = MINI_PLAYER_CONTROLS_DURATION;
  public showMiniPlayerControls = false;
  public webinarType = WEBINAR_TYPE;
  public webinarAgoraStatus = WEBINAR_AGORA_STATUS;
  public webinarAgoraRole = WEBINAR_AGORA_ROLE;
  public productType = PRODUCT_TYPE_CONSTANT;
  public activeSteps = ACTIVE_STEPS;
  public isTutorialStarted = false;
  public activeStep = 1;
  public isPopoverOpen = false;
  public autoPlayAgoraWebinar = false;
  public pipModeStartTime: string;
  public pipModeEndTime: string;
  public analyticsData: AnalyticsInterface;
  public numbers = NUMBERS;

  /**
   * //TODO resume time for webinar coming from continue watch
   */
  public resumeTime: number;

  /**
   * To unsubscribe data.
   * is pip tutorial started.
   */
  private componentActive = true;

  /**
   * Channel container.
   */
  @ViewChild('dqVideoContainer') dqVideoContainer: ElementRef;
  /**
   * Number zero constant.
   */
  public numberZero = NUMBERS.ZERO;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * To Reload the hls player increase the value.
   */
  public reloadHls = 0;
  public isSecure = true;


  /**
   * is end flag
   */
  public isEnd: string = DOC_TALK_ENDED.NO;

  /**
   * seek time of video seekBar
   */
  private seekTime = 0;

  /**
   * Default drag position.
   */
  public dragPosition = { x: 0, y: 0 };

  /**
   * Necessary instances.
   */
  constructor(
    public dss: DataStorageService,
    public renderer2: Renderer2,
    private router: Router,
    public dqVideoPlayerService: DqVideoPlayerService,
    public websocketService: WebsocketService,
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService,
    public consentService: ConsentService,
    public pollDataSer: PollSocketService,
    public postSer: PostApiService,
    public getApiService: GetService,
    public webinarContinueWatchService:WebinarContinueWatchLog
  ) {
  }

  /**
   * call the webinar detail information.
   */
  public ngOnInit(): void {
    this.getWebinarDetail();
    this.getReloadStatus();
  }

  /**
   * Get the reload status for the wowza and d-stream webinar.
   */
  getReloadStatus(): void {
    this.websocketService.newStreamingData
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(data => {
        this.isSecure = true;
        if (data.webinarDownScale) {
          this.doctalkDetail.type = data.webinarDownScale.type;
          if (this.doctalkDetail.type === this.webinarType.DSTREAM) {
            this.doctalkDetail.dstream_data = data.webinarDownScale.stream_info.dstream_data;
            this.checkToCallDstreamDetail();
          }
          if (this.doctalkDetail.media.length > NUMBERS.ZERO) {
            this.doctalkDetail.media[NUMBERS.ZERO].media_url = data.webinarDownScale.stream_info.media.media_url;
            this.doctalkDetail.media[NUMBERS.ZERO].optimize_url = data.webinarDownScale.stream_info.media.optimize_url;
          }
        }
        if (data.reload) {
          this.reloadHls = ++this.reloadHls;
        }
      });
  }

  /**
   * Check to call the D-stream detail or not.
   */
  checkToCallDstreamDetail(): void {
    if (this.doctalkDetail.type === WEBINAR_TYPE.DSTREAM  && this.doctalkDetail.dstream_data.is_dstream_secure === NUMBERS.ONE && this.doctalkDetail.status === WEBINAR_STATUS.LIVE ) {
      this.isSecure = false;
      this.getDstreamDetails();
    }
  }

  /**
   * Get Dstream detail.
   */
  getDstreamDetails(): void {
    this.getApiService.getSignedCookie(this.doctalkDetail.dstream_data.stream_key,
      this.doctalkDetail.dstream_data.token, this.doctalkDetail.dstream_data.base_url)
      .pipe(takeWhile(() => this.componentActive)).subscribe((resData) => {
        sessionStorage.setItem('getDstreamDetails', JSON.stringify(resData['cookie']));
        this.isSecure = true;
        this.reloadHls = ++this.reloadHls;
      });
  }

  /**
   * Check if tutorial need to start.
   */
  startTutorial(): void {
    if (!localStorage.getItem(TUTORIAL_LOCAL_STORAGE.KEY)) {
      this.sendAnalytics(EVENT_NAME.PIP_WALKTHROUGH_TRIGGERED, SCREEN_NAME.WEBINAR_DETAIL);
      this.dqVideoPlayerService.showPipNewTag = SHOW_NEW_TAG.PIP;
      localStorage.setItem(PIP_TAG_LOCAL_STORAGE, this.dqVideoPlayerService.pipNewTagCount.toString());
      this.isTutorialStarted = true;
      this.isPopoverOpen = true;
    }
  }

  /**
   * Subscribe the webinar detail information.
   */
  getWebinarDetail(): void {
    this.dqVideoPlayerService.doctalkDetail
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(data => {
        if (data) {
          of(true).pipe(
            takeWhile(() => this.componentActive),
            delay(NUMBERS.ZERO),  
            tap(() => {
              this.doctalkDetail = data;
              this.getPastWebinarWatchSeekTime();
              this.dqVideoPlayerService.webinarId = this.doctalkDetail.webinar_id;
              this.dqVideoPlayerService.isPipTutorialEnabled
                .pipe(takeWhile(() => this.componentActive))
                .subscribe(data => {
                  if (data && (this.doctalkDetail.status === this.webinarAgoraStatus.LIVE || this.doctalkDetail.status === this.webinarAgoraStatus.END)) {
                    for (const media of this.doctalkDetail['media']) {
                      if (media && media?.media_type === MEDIA_TYPE.VIDEO) {
                        this.startTutorial();
                      }
                    }
                  }
                });
            })).subscribe();
        } else {
          this.dqVideoPlayerService.is_agora = false;
          this.doctalkDetail = data;
        }
      });
  }

  /**
   * get past webinar watch seek time
   */
  async getPastWebinarWatchSeekTime() {
    const { status, media, webinar_id, product_type} = this.doctalkDetail;
    let seekTime = 0;
  
    if ([WEBINAR_STATUS.PAST, WEBINAR_STATUS.END].includes(status) && product_type == 27) {
      const resumeOnInSec = media[0]?.resume_on_in_sec ?? 0;
      
      if (resumeOnInSec >= 0 && this.dqVideoPlayerService.seekTimePastWebinarFlag) {
        seekTime = resumeOnInSec;
        /**
         * Use Set time out for video to get buffer when we get resume in time.
         */
        setTimeout(() => {
          this.dqVideoPlayerService.webinarContinueWatchResumeTime = seekTime;
        }, 1000);
      } else {
          this.webinarContinueWatchService.getData(product_type,webinar_id).then((res)=>{
            seekTime = res?.duration ?? 0;
            this.dqVideoPlayerService.webinarContinueWatchResumeTime = seekTime;
          }).
          catch((err)=>{
            seekTime = 0; 
            this.dqVideoPlayerService.webinarContinueWatchResumeTime = seekTime;
          })
      }
    }
  }


  /**
   * send past webinar continue watch log
   */
  sendContinueWatchWebinarLogs():void {
    const { status, webinar_id, product_type} = this.doctalkDetail;
    const resumeOnInSec = this.seekTime;
  
    if ([WEBINAR_STATUS.PAST, WEBINAR_STATUS.END].includes(status) && resumeOnInSec > 0 && product_type == 27) {
      this.webinarContinueWatchService.addData({
        type:product_type,
        id: webinar_id,
        duration: resumeOnInSec,
      });
    }
  }
  

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, screenName: string, stepNumber?: number, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    this.analyticsData = {
      event_name: eventName,
      product_type: PRODUCT_TYPE_CONSTANT.WEBINAR,
      product_type_id: this.doctalkDetail.product_type_id,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    if (stepNumber) {
      this.analyticsData.step_number = stepNumber;
    }
    this.analyticsEventService.logAnalyticsEvent(this.analyticsData);
  }

  /**
   * Open the mini player.
   */
  openMiniPlayer(): void {
    document.body?.classList?.remove(PIP_CLASS);
    if (this.dqVideoPlayerService.isAudioMiniPlayer) {
      this.dqVideoPlayerService.exitMiniAudioPlayer.next(true);
    }
    this.setTutorialLocalStorageInfo();
    this.pipModeStartTime = this.getTimeString();
    this.sendAnalytics(EVENT_NAME.PIP_BUTTON_CLICK, SCREEN_NAME.WEBINAR_DETAIL);
    if (this.isPopoverOpen) {
      this.sendAnalytics(EVENT_NAME.PIP_WALKTHROUGH_SKIP, SCREEN_NAME.WEBINAR_DETAIL, this.activeStep);
      this.dqVideoPlayerService.playPauseVideoState = false;
      this.isPopoverOpen = false;
      this.autoPlayAgoraWebinar = true;
      this.dqVideoPlayerService.playPauseVideoState = true;
    }
    this.sendAnalytics(EVENT_NAME.PIP_MODE_ENTRY, SCREEN_NAME.PIP_MODE);
    this.dqVideoPlayerService.removeCanDeactivate = true;
    if (this.dqVideoPlayerService.is_agora.toString() !== this.webinarType.AGORA.toString()) {
      this.dqVideoPlayerService.addMiniPlayerShakaControls();
      if (this.doctalkDetail?.linked_product_restriction != null) {
        this.dqVideoPlayerService.recordedConfig.addSeekBar = false;
      }
      else {
        this.dqVideoPlayerService.addMiniPlayerShakaControls();
      }
    }
    of(true).pipe(
      takeWhile(() => this.componentActive),
      delay(0),
      tap(() => {
        /** TO DO: To remove screen wise check as the webinar posted in community details are not
            not available in webinar listing page*/
        if (this.dqVideoPlayerService.calledFrom === SCREEN_NAME.COMMUNITY_DETAILS) {
          this.router.navigate([`community/detail/${this.dqVideoPlayerService.communityId}`]);
        } else {
          this.router.navigate(['webinar/list/all']);
        }

      })).subscribe();
    this.dqVideoPlayerService.isMiniPlayerVisible = true;
  }
  /**
   * Create the media obj for the media streaming api
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createMediaLog(is_end: string, stop_time: number): any {
    const mediaLog = {
      ptype: this.doctalkDetail.product_type,
      pid: this.doctalkDetail.product_type_id,
      media_id: this.doctalkDetail?.media[0]?.media_id,
      stop_time: stop_time,
      is_end: this.isEnd
    };
    return mediaLog;
  }
  /**
   * send media streming log
   */
  sendMediaSteamingLog(data): void {
    this.postSer.mediaSteamingLog(data)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(resData => {
        if (resData.status == NUMBERS.ONE) {
          this.sendContinueWatchWebinarLogs();
          console.log('MediaSteamingLog success');
        } else {
          console.log('MediaSteamingLog Failed');
        }
      });
  }
  /**
   * video end event
   */
  videoEnd(): void {
    if(this.doctalkDetail.status === WEBINAR_AGORA_STATUS.LIVE && this.doctalkDetail.type === WEBINAR_TYPE.RECORDED) {
      this.dqVideoPlayerService.endBannerVisible = true;
    }
    this.isEnd = DOC_TALK_ENDED.YES;
  }
  /**
   * Calculate sqs interval.
   */
  receiveSQSInterval(data): void {
    this.seekTime = data?.seekTime;
    const mediaLog = this.createMediaLog(this.isEnd, data?.seekTime);
    this.dss.webinarPlayTime$.next(data.timespent);
    this.sendMediaSteamingLog(mediaLog);
    this.dqVideoPlayerService.receiveSQSInterval(data, this.doctalkDetail.product_type, this.doctalkDetail.product_type_id, this.mls.googleEventText.webinar_detail.key5, this.mls.googleEventText.webinar_detail.category, this.doctalkDetail?.linked_product_restriction?.unlock_time, this.doctalkDetail?.has_parent);
  }

  /**
   * Check webinar status.
   */
  checkWebinarStatus(): void {
    this.dqVideoPlayerService.webinarStatus.next(this.webinarAgoraStatus.END);
  }

  /**
   * Check If Login User already given the Explicit Consent or not for Past Webinar
   */
  checkForPastWebinar(event: { playerStage: string; }): void {
    if (event.playerStage === PLAYER_STAGE.SEEKING) {
      this.isEnd = DOC_TALK_ENDED.NO;
    }
    if (event.playerStage) {
      this.dqVideoPlayerService.checkForPastWebinar.next(event);
    }
  }

  /**
   * Trigger the agora webinar controls.
   */
  controlsTrigger(data): void {
    this.dqVideoPlayerService.controlsTrigger(data);
  }

  /**
   * Click on full screen.
   */
  fullscreenStatus(event): void {
    this.dqVideoPlayerService.fullscreenStatus(event);
  }

  /**
   * Check for when joined webinar for NPS.
   */
  joinedStatus(event): void {
    this.dqVideoPlayerService.joinedStatus(event);
  }

  /**
   * receiving clicked sponsor data and send it to analytic.
   */
  sponsorLogoAnalytic(data): void {
    this.dqVideoPlayerService.sponsorLogoAnalytic(data, this.doctalkDetail.webinar_id, this.doctalkDetail.product_type);
  }

  /**
   * Method for receving the poll submit click event
   */
  submitPollClick(event): void {
    this.dqVideoPlayerService.submitPollClick(event, this.doctalkDetail.product_type, this.doctalkDetail.product_type_id);
  }

  /**
   * Method for receving the poll cross click event
   */
  pollcrossClick(event, productType, productTypeId): void {
    this.dqVideoPlayerService.pollcrossClick(event, productType, productTypeId);
  }

  /**
   * Expand the miniPlayer to fullscreen.
   */
  expandMiniPlayer(): void {
    this.pipModeEndTime = this.getTimeString();
    this.sendAnalytics(EVENT_NAME.PIP_FULLSCREEN_CLICK, SCREEN_NAME.PIP_MODE);
    this.sendAnalytics(EVENT_NAME.PIP_MODE_VISIT, SCREEN_NAME.PIP_MODE, 0, this.pipModeStartTime, this.pipModeEndTime);
    this.router.navigate(['webinar/detail/', this.dqVideoPlayerService.webinarId]);
  }

  /**
   * Close the player and destroy the component.
   */
  closeMiniPlayer(): void {
    this.pipModeEndTime = this.getTimeString();
    this.dqVideoPlayerService.exitMiniPlayer.next(true);
    this.sendAnalytics(EVENT_NAME.PIP_MODE_VISIT, SCREEN_NAME.PIP_MODE, 0, this.pipModeStartTime, this.pipModeEndTime);
    this.sendAnalytics(EVENT_NAME.PIP_CLOSE_CLICK, SCREEN_NAME.PIP_MODE);
  }

  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * Add the chat in controls of webinar agora.
   */
  controlsEmitchat(event): void {
    this.dqVideoPlayerService.controlsEmitchat(event);
  }

  /**
   * Mouse move.
   */
  onMouseMove(): void {
    const minimized = this.dqVideoContainer.nativeElement;
    this.showMiniPlayerControls = minimized.querySelector('#miniPlayerControl');
    if (this.showMiniPlayerControls) {
      this.renderer2.setStyle(this.showMiniPlayerControls, 'display', 'block');
    }

    /**
     * Reset time.
     */
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (this.showMiniPlayerControls) {
        this.renderer2.setStyle(this.showMiniPlayerControls, 'display', 'none');
      }
    }, this.duration);
  }

  /**
   * Hide the mini player controls on mouse leave.
   */
  onMouseLeave(): void {
    this.renderer2.setStyle(this.showMiniPlayerControls, 'display', 'none');
  }

  /**
   * Skip the tutorial.
   */
  skipTutorial(): void {
    this.sendAnalytics(EVENT_NAME.PIP_WALKTHROUGH_SKIP, SCREEN_NAME.WEBINAR_DETAIL, this.activeStep + 1);
    this.isTutorialStarted = false;
  }

  /**
   * Proceed the tutorial to next step.
   */
  nextStep(): void {
    this.sendAnalytics(EVENT_NAME.NEXT_PIPWALKTHROUGH_CLICK, SCREEN_NAME.WEBINAR_DETAIL, this.activeStep + 1);
    this.activeStep = this.activeStep + 1;
  }

  /**
   * Tutorial is complete flag.
   */
  tutorialComplete(): void {
    this.sendAnalytics(EVENT_NAME.PIP_WALKTHROUGH_COMPLETE, SCREEN_NAME.WEBINAR_DETAIL);
    this.isTutorialStarted = false;
    this.activeStep = 0;
  }

  /**
   * Set tutorial is show once.
   */
  setTutorialLocalStorageInfo(): void {
    localStorage.setItem(TUTORIAL_LOCAL_STORAGE.KEY, TUTORIAL_LOCAL_STORAGE.VALUE);
  }

  /**
   * Close the tutorial popover and set the local storage.
   */
  closePopOver(): void {
    this.setTutorialLocalStorageInfo();
    this.sendAnalytics(EVENT_NAME.PIP_WALKTHROUGH_CLOSE, SCREEN_NAME.WEBINAR_DETAIL);
    this.isPopoverOpen = false;
    this.isTutorialStarted = false;
  }

  /**
   * Get the resume time for the shaka player.
   */
  getResumeTime(): number {
    if (this.doctalkDetail && this.doctalkDetail.type === WEBINAR_TYPE.RECORDED && this.doctalkDetail.status === WEBINAR_STATUS.LIVE) {
      if (this.doctalkDetail.media.length > NUMBERS.ZERO) {
        return (this.doctalkDetail.media[NUMBERS.ZERO].resume_on_in_sec > NUMBERS.ZERO) ? this.doctalkDetail.media[NUMBERS.ZERO].resume_on_in_sec : NUMBERS.ZERO;
      }
    }    
    if (this.dqVideoPlayerService.webinarContinueWatchResumeTime !== null) {
      this.resumeTime = this.dqVideoPlayerService.webinarContinueWatchResumeTime;
      this.dqVideoPlayerService.webinarContinueWatchResumeTime = null;
      return this.resumeTime;
    }
    return NUMBERS.ZERO;
  }

  /**
   * Show Consent Form
   */
  showConsentFormOpen(): void {
    this.consentService.openConsentForm(this.doctalkDetail.product_type.toString(), this.doctalkDetail.webinar_id, CONSENT_TYPE.EXPLICIT_CONSENT, ONLY_LABEL_CONSENT.FALSE);
  }

  /**
   * Event for play in pip
   */
  pipPlayClicked(): void {
    if (this.dqVideoPlayerService.isMiniPlayerVisible) {
      this.sendAnalytics(EVENT_NAME.PIP_PLAY_CLICK, SCREEN_NAME.PIP_MODE);
    }
  }

  /**
   * Reset the drag position in webinar detail page.
   */
  checkDragPosition(): unknown {
    return !this.dqVideoPlayerService.isMiniPlayerVisible ? { x: 0, y: 0 } : this.dragPosition;
  }

  /**
   * Stop the subscription.
   */
  ngOnDestroy(): void {
    this.sendContinueWatchWebinarLogs();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.componentActive = false;
    this.dqVideoPlayerService.calledFrom = '';
  }
}
