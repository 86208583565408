<span #myMoreDrop="ngbDropdown" ngbDropdown (openChange)="toggleFloating($event)"
  class="more-options-dropdown cursor-pointer flex-shrink-0" display="static" container="null">
  <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">
    <img src="assets/samvaad-assets/images/icons/samvaad-three-dots.svg" alt="dots">
  </span>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" (click)="myMoreDrop.close()" display="static" container="null">
    <ul *ngFor="let list of moreOptions; let i = index">
      <li class="cursor-pointer" (click)="doAction(list.id)" *ngIf="list.status">
        <img *ngIf="list.url" [src]="list.url" alt="list" class="mr-2">
        {{list.text}}
      </li>
    </ul>
  </div>
</span>
