/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable, Subject } from 'rxjs';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { AuthService } from '../../auth/auth.service';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';

/**
 * Interface imports.
 */
import { SpecialityListData } from '../../onboarding/interface/apiResponse.model';

/**
 * Post web Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PostwebService {
  public isfeedAdded: Subject<boolean> = new Subject();
  private user_auth_key: string;

  /**
   * Necessary instances.
   */
  constructor(private httpClient: HttpClient, private authService: AuthService, private handler: HttpBackend) {
    this.user_auth_key = this.authService.getToken();
  }

  /**
   * submit feed post new.
   */
  submit_feed_post_new(
    title,
    content,
    feed_type,
    meta_url,
    feed_type_id,
    feed_kind,
    classification,
    meta_array,
    speciality_json_array,
    poll_options,
    extra_content,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    media_json): Observable<any> {
    poll_options = JSON.stringify(poll_options);
    extra_content = (JSON.stringify(extra_content));
    media_json = (JSON.stringify(media_json));

    const body = new HttpParams().set('title', title)
      .append('content', content)
      .append('feed_type', feed_type)
      .append('meta_url', meta_url)
      .append('feed_type_id', feed_type_id)
      .append('feed_kind', feed_kind)
      .append('classification', classification)
      .append('meta_array', meta_array)
      .append('speciality_json_array', speciality_json_array)
      .append('poll_options', poll_options)
      .append('extra_content', extra_content)
      .append('version', '2.6')
      .append('media_json', media_json);

    return this.httpClient.post(API.CONNECT_SERVICES_FEED_SET, body,
      {
        params: {
          rquest: 'feed_post'
        }
      }
    );
  }

  /**
   * Edit Feed Post New
   */
  edit_feed_post_new(
    feed_id, title,
    content, feed_type, meta_url,
    feed_type_id,
    feed_kind,
    classification,
    meta_array,
    speciality_json_array,
    poll_options,
    extra_content,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    media_json): Observable<any> {
    poll_options = JSON.stringify(poll_options);
    extra_content = (JSON.stringify(extra_content));
    media_json = (JSON.stringify(media_json));

    const body = new HttpParams().set('title', title)
      .append('feed_id', feed_id)
      .append('content', content)
      .append('feed_type', feed_type)
      .append('meta_url', meta_url)
      .append('feed_type_id', feed_type_id)
      .append('feed_kind', feed_kind)
      .append('classification', classification)
      .append('meta_array', meta_array)
      .append('speciality_json_array', speciality_json_array)
      .append('poll_options', poll_options)
      .append('extra_content', extra_content)
      .append('version', '2.6')
      .append('media_json', media_json);

    return this.httpClient.post(API.CONNECT_SERVICES_FEED_SET, body,
      {
        params: {
          rquest: 'feed_edit'
        }
      }
    );
  }

  /**
   * Delete Feed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteFeed(feed_id): Observable<any> {
    const body = new HttpParams().set('feed_id', feed_id);
    return this.httpClient.post(API.CONNECT_SERVICES_FEED_SET, body, {
      params: {
        rquest: 'feed_delete'
      }
    });
  }

  /**
   * Upload File Base
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFileBase(basestr): Observable<any> {
    basestr = basestr.replace('data:image/png;base64,', '');
    const body = new HttpParams().set('file', basestr)
      .append('type', 'image/png')
      .append('format', 'format')
      .append('access_key', '')
      .append('user_auth_key', this.user_auth_key);

    return this.httpClient.post(API.CONNECT_WEB_SERVICE_SET_API_PHP, body,
      {
        params: { rquest: 'UploadFile' }
      }
    );
  }

  /**
   * New Upload Chunk.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newUploadInChunk(file, name, num_chunks, num, file_type, product_type): Observable<any> {
    const httpClient = this.createNewHttpClient();
    let f_type = file_type;
    if (file_type == 'application') {
      f_type = 'document';
    }
    const input = new FormData();
    input.append('file', file);
    input.append('name', name);
    input.append('file_type', f_type);
    input.append('num', num);

    input.append('num_chunks', num_chunks);
    input.append('product_type', product_type);
    if (num_chunks == num) { input.append('chunk_status', '1'); } else { input.append('chunk_status', '0'); }
    const headers = this.uploadFileHeaders();
    return httpClient.post( `${environment.API_URLS.media_upload}/${API.CONNECT_SERVICE_F_UPLOAD_SET}`, input,
      {
        headers,
        params: { rquest: 'uploadfile' }
      }
    );

  }

  createNewHttpClient(): HttpClient {
    return new HttpClient(this.handler);
  }

  uploadFileHeaders(): { Authorization: string; version: string; device_type: string; } {
    const headers = {
      'Authorization': environment.appKey,
      'version': '2.0',
      'device_type': 'web'
    };
    return headers;
  }

  /**
   * feed Poll
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feed_poll(feed_id, option_id): Observable<any> {
    const body = new HttpParams().set('feed_id', feed_id)
      .append('option_id', option_id)
      .append('version', '2.0')
      .append('iso_code', '')
      .append('app_version', '');

    return this.httpClient.post(API.CONNECT_SERVICES_FEED_SET, body,
      {
        params: {
          rquest: 'poll_record'
        }
      }
    );
  }

  /**
   * Submit Question Reply
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitQuestionReply(comment, selected_que_obj): Observable<any> {
    const body = { 'comment': comment, file_type: '', 'file_url': '', 'action': 'add', 'question_id': selected_que_obj.question_id, 'comment_id': '' };

    return this.httpClient.post(API.USER_PROFILE_REPLY_QUESTION, body);
  }

  /**
   * Set Feed Added
   */
  setFeedAdded(val): void {
    this.isfeedAdded.next(val);
  }

  /**
   * Get Feed Added
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFeedAdded(): any {
    return this.isfeedAdded;
  }

  /**
   * Get Speciality List
   */
  getSpecialityList(): Observable<SpecialityListData> {
    const temp = JSON.parse(sessionStorage.getItem('req_data'));
    const body = { token_id: temp.token_id, is_encoded_response: environment.is_encoded_response };

    return this.httpClient.post<SpecialityListData>(API.REGISTRATION_SPECIALITY_LIST_V4, body);
  }
}
