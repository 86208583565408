/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';

 /**
  * Service imports.
  */
 import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
 import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
 import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
 import { AuthService } from '../../auth/auth.service';
 import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
 import { DataStorageService } from '../../dataservices/data-storage.service';
 import { PostApiService } from '../../dataservices/post-api.service';
 import {NewLanguageService} from '../../dataservices/new-language/new-language.service';
 import { UserStoryService } from 'src/app/dataservices/userStory/user-story.service';

/**
 * Constant imported.
 */
import { CALLING_FROM, CONNECTION_ENABLE, CONNECTION_STATUS, PRODUCT_TYPE_CONSTANT, COMPONENT_CALLING_FROM, CONNECTION, NUMBERS, COMMUNITY_USER_TYPE, GRADIENT_DIRECTION, PRODUCT_TYPE_CONSTANT_NAME, PRODUCT_TYPE_NUMBER_MAPPING, CTA_TYPE, ACTION_TYPE, ACTION_ON } from '../../constants/app.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Interface import.
 */
import { PostedIn } from '../../onboarding/interface/global.model';

/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';

 /**
  * Connection Card Component
  */
 @Component({
   selector: 'app-connection-card',
   templateUrl: './connection-card.component.html',
   styleUrls: ['./connection-card.component.scss']
 })
 export class ConnectionCardComponent implements OnInit, OnChanges, OnDestroy {
   @Input() usercardsize;
   @Input() calling_from;
   @Input() callingfrom;
   @Input() isECEnable = false;
   @Input() isPostedEvent: boolean;
   public connection_pic_image = {
     prflclass: 'box-size-40 text-center rounded-circle d-table-cell align-middle',
     prflpic: '',
     prflfname: '',
     prflmname: '',
     prfllname: '',
     prflrname: '',
     prflcid: '',
     prfltype: '',
     prflpicclass: 'rounded-circle box-size-40 post-pic',
     badge: { is_verified: 0, is_show: true },
     full_name: '',
     is_action: false,
     anonymous: '0',
     partial_permission_state:''
   };
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   public suggestionData: any;
   public ids = [];

   public displayObj = {
     isShow: false,
     display_section: '',
     data: [],
     errObj: []
   };
   public showthreedotsongr = false;
   @Input() index;

   @Output() removeconcard = new EventEmitter();
   /**
    * Key is used to show when conditions true with it for speaker list component.
    */
   public speakerList = 'speakers-list';

   /**
    * Connection status constant.
    */
   public connectionStatus = CONNECTION_STATUS;
  /**
  * callingFrom constant.
  */
  public callingFrom = CALLING_FROM;

  /**
  *
  * constant for NUMBERS
  * @memberof ConnectionCardComponent
  */
  public numbers = NUMBERS;
  /**
   * Gradient direction constants.
   */
  public gradientDirection = GRADIENT_DIRECTION;

  /**
   * is story avalailbe
   */
  public isStoryAvailable: any;

  @Output() resetSuggList = new EventEmitter();
  @Output() emitCommunityDetail: EventEmitter<string> = new EventEmitter();
  @Output() emitOpenFeed: EventEmitter<boolean> = new EventEmitter();
  @Output() closeParentModal = new EventEmitter();
  @Input() suggObj;
  @Input() idx;
  @Input() is_anonymous;
  @Input() showbutton;
  @Input() posted_in: PostedIn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connectData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public buttonSent: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info_msg: any;
  public isInfoModalShown = false;
  public finished = false;
  public accept_loader = false;
  public reject_loader = false;
  public withdrawbutton = false;
  public withdrawbuttoncon = false;
  public showListConnection: boolean;
  public removeacceptbutton: boolean;
  public emitRes = { status: 1, idx: '' };
  public withdrawisValid: boolean;
  public showRejected: number;
  public loginUserCustomeId = '';
  public showSubscribeModal = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subscribe_obj: any;
  public tooltip_data = [];
  public openFeedstatus = false;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Constant for checking if connection is enable or not.
   */
  public connectionEnable = CONNECTION_ENABLE;

  /**
   * Caller of current component.
   */
  public componentCallingFrom = COMPONENT_CALLING_FROM;
  /**
   * Constant for product types.
   */
  public pType = PRODUCT_TYPE_CONSTANT;
  /**
   * status for author of the post
   * @type {number}
   * @memberof ConnectionCardComponent
   */
  @Input() isAuthor: number;
  /**
   * Product data where that connection card is used.
   */
  @Input() product?;

  /**
   * Community member type constant.
   */
  public memberType =COMMUNITY_USER_TYPE;

   /**
    * Necessary instances.
    */
   constructor(
     private postSer: PostApiService,
     private masterService: MasterserviceService,
     public multiLangService: MultiLangService,
     public fcs: FunCollectionService,
     private authService: AuthService,
     private jwtSer: JwtDecodeService,
     private dss: DataStorageService,
     public mls: NewLanguageService,
     public storyServ: UserStoryService,
     public analyticSdk: Analytics
   ) {
     this.subscriptions.add(this.dss.userConnStatusChanged.subscribe(data => {
       if (data.custome_id == this.suggObj.custom_id) {
         if (parseInt(data.connection_status) == 9) {
           this.buttonSent = 1;
         } else if (parseInt(data.connection_status) == 0) {
           this.buttonSent = undefined;
         }
         this.suggObj.connection_status = data.connection_status;
       }
     }));
   }

   /**
    * ngOnInit
    */
   ngOnInit(): void {
     this.connection_pic_image.prflrname = (this.is_anonymous == 1) ? '' : this.suggObj.registered_name;
     this.connection_pic_image.prflpic = (this.is_anonymous == 1) ? '' : this.suggObj.profile_pic;
     this.connection_pic_image.prflcid = (this.is_anonymous == 1) ? '' : this.suggObj.custom_id;
     this.connection_pic_image.prflfname = (this.is_anonymous == 1) ? '' : this.suggObj.first_name;
     this.connection_pic_image.prfllname = (this.is_anonymous == 1) ? '' : this.suggObj.last_name;
     this.connection_pic_image.full_name = this.suggObj.full_name;
     this.connection_pic_image.partial_permission_state = this.suggObj.partial_permission_state;
     this.connection_pic_image.badge.is_verified = (this.suggObj.permission == 'all') ? 1 : (this.suggObj.permission == 'not_show') ? -1 : 0;

     this.connection_pic_image.anonymous = this.is_anonymous;

     this.loginUserCustomeId = this.authService.getbyName('profile_obj');
     this.emitRes = { status: 1, idx: this.idx };
     this.subscribe_obj = { 'target_id': this.suggObj.target_id, 'channel_name': this.suggObj.channel_name, 'tnc_url': this.suggObj.safe_url, 'target_name': this.suggObj.powered_name, 'publication_logo': this.suggObj.profile_pic, 'target_label': this.suggObj.powered_by_label };
     this.isStoryAvailable = this.suggObj?.is_story_available;
  }

  /**
   * profile picture clicked
   */
  profilePicClicked(): void {
    if (this.callingfrom && this.product.product_type &&  this.product.feed_id) {
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_NUMBER_MAPPING[this.product.product_type], this.product.feed_id, this.callingfrom, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.PROFILE_ICON, null);
    }
  }

   /**
    * navigateTo userDetail section
    */
   navigateTouser(customeId, navigate_channel, channelID): boolean {
     if (this.is_anonymous == 1) { return; }

     if (this.masterService.isOnline == false) {
       this.masterService.isConnectionModalShown.next('1');
       return false;
     }
     if (this.isStoriesAvailable()) {
      this.openStory(this.suggObj?.uuid);
     }
     else {
      if (navigate_channel == 1) {

        this.fcs.navigateToChannelDetail(channelID);
      } else {

        const x = { is_show: false, feed_id: 0, mediaIdx: 0 };
        this.dss.showFeedDetailInModal.next(x);
        this.fcs.navigateToTop();
        this.fcs.navigateToUserProfie(customeId);
      }
     }
   }

   openStory(uuid): void {
    this.dss.viewStoryThroughProfileUuid = uuid;
    this.dss.showStoryThroughProfile.next(true);
   }
   /**
   *
   * @param story function to check if all stories are viewed
   * @returns
   */
  isStoriesAvailable(): boolean {
    const apiRes = this.isStoryAvailable === 1 ? true : false;
    let realTime = false;
    realTime = !this.storyServ.isAllStoryViewed(this.suggObj?.uuid);
    return apiRes && realTime;
  }




   /**
    * unsubscribe the subscription.
    */


      /**
   *
   * checks if the user is having option to send EC author request
   * @readonly
   * @type {boolean}
   * @memberof ConnectionCardComponent
  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    if (this.calling_from == 'feed_detail') {
      this.tooltip_data = this.dss.tooltip_list.filter(obj => obj.screen_identifier == 'FEED_VISIT');
    }
  }

  /**
   * call connect
   */
  connectionAdd(action, requestee_custom_id, action_type): boolean {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CONNECTION, requestee_custom_id, this.callingfrom, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.CONNECTION_REQUEST_SENT, null);
    if (this.finished === false) {
      this.emitRes = { status: 9, idx: this.idx };

      if (this.masterService.isOnline == false) {
        this.masterService.isConnectionModalShown.next('1');
        return false;
      }
      this.finished = true;
      this.subscriptions.add(this.postSer.userConnection(action, requestee_custom_id, action_type).subscribe(resData => {


        this.jwtSer.decodeData(resData).then(data => {

          if (data.status == 1) {
            this.showthreedotsongr = true;
            this.connectData = data;
            const x = { custome_id: requestee_custom_id, connection_status: 9 };
            this.dss.userConnStatusChanged.next(x);
            this.buttonSent = this.connectData.status;
            this.fcs.showSuccess(this.connectData.msg, 'Success');
            this.resetSuggList.emit(this.emitRes);
          } else if (data.status == 0) {
            this.fcs.errorHandeler(data, 'generate_certificate');
          }
          this.finished = false;
        },
          () => {
            this.finished = false;
          });
      }));
    }
  }

  /**
   * call reject, withdraw and remove
   */
  connectionWithdraw(action, request, action_type): boolean {
    let actionOn = '';
    const requestee_custom_id = request.custom_id;
    if (request?.connection_status == CONNECTION_STATUS.ADD.ACTION) {
      actionOn = ACTION_ON.CONNECTION_WITHDRAW_CLICK
    }
    else if (request?.connection_status == CONNECTION_STATUS.RECIVED.ACTION) {
      actionOn = ACTION_ON.REQUEST_REJECT_CLICK
    }
    else if (request?.connection_status == CONNECTION_STATUS.ACCEPT.ACTION) {
      actionOn = ACTION_ON.REMOVE_CONNECTION
    }
    if (actionOn.length > 0) {
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CONNECTION, requestee_custom_id, this.callingfrom, CTA_TYPE.ICON, ACTION_TYPE.CLICK, actionOn, null);
    }
    if (this.reject_loader === false) {
      this.withdrawisValid = false;
      if (this.masterService.isOnline == false) {
        this.masterService.isConnectionModalShown.next('1');
        return false;
      }
      this.reject_loader = true;
      this.subscriptions.add(this.postSer.userConnection(action, requestee_custom_id, action_type).subscribe(resData => {
        this.removeacceptbutton = false;
        this.buttonSent = undefined;

        this.jwtSer.decodeData(resData).then(data => {
          if (data.status == 1) {
            this.suggObj.connection_status = 0;
            const x = { custome_id: requestee_custom_id, connection_status: 0 };
            this.dss.userConnStatusChanged.next(x);

            this.connectData = data;
            this.fcs.showSuccess(this.connectData.msg, 'Success');
            this.withdrawbutton = true;
            this.withdrawbuttoncon = true;
            this.withdrawisValid = true;
            this.resetSuggList.emit(this.emitRes);
          } else if (data.status == 0) {
            this.fcs.errorHandeler(data);
          }

        },
          () => {
            this.finished = false;
          });
        this.reject_loader = false;
      }));
    }
  }

  /**
   * connection rejected
   * call reject, withdraw and remove
   */
  connectionReject(action, requestee_custom_id, action_type): boolean {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CONNECTION, requestee_custom_id, this.callingfrom, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.REQUEST_REJECT_CLICK, null);
    this.withdrawisValid = false;
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.reject_loader = true;
    this.subscriptions.add(this.postSer.userConnection(action, requestee_custom_id, action_type).subscribe(resData => {
      this.buttonSent = undefined;
      this.reject_loader = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data.status == 1) {



          this.removeconcard.emit(this.index);



          this.suggObj.connection_status = 88;
          this.connectData = data;
          this.fcs.showSuccess(this.connectData.msg, 'Success');
          this.withdrawbutton = true;
          this.showRejected = 1;
          this.resetSuggList.emit(this.emitRes);
        } else if (data.status == 0) {
          this.fcs.errorHandeler(data);
        }
      },
        () => {
          this.finished = false;
        });
    }));
  }

  /**
   * list show function
   */
  connectionList(): void {
    this.showListConnection = true;
  }


  /**
   * reset Info Modal
   */
  resetInfoModal(): void {
    this.isInfoModalShown = false;
  }

  /**
   * connection Accept
   */
  connectionAccept(action, requestee_custom_id, action_type): boolean {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.CONNECTION, requestee_custom_id, this.callingfrom, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.CONNECTION_REQUEST_ACCEPT, null);
    this.emitRes = { status: 8, idx: this.idx };
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    this.accept_loader = true;
    this.subscriptions.add(this.postSer.userConnection(action, requestee_custom_id, action_type).subscribe(resData => {
      this.accept_loader = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data.status == 1) {
          this.suggObj.connection_status = 7;
          this.dss.user_profile_data.user_connection.total_connections = this.dss.user_profile_data.user_connection.total_connections + 1;

          this.removeconcard.emit(this.index);
          this.connectData = data;
          this.fcs.showSuccess(this.connectData.msg, 'Success');
          this.removeacceptbutton = true;
          this.resetSuggList.emit(this.emitRes);


        } else if (data.status == 0) {
          this.fcs.errorHandeler(data);
        }
      },
        () => {
          this.accept_loader = false;
          this.fcs.showError(this.connectData.msg, 'error');
        });
    }));
  }

  /**
   * show Subscribe Popup
   */
  showSubscribePopup(): void {
    this.showSubscribeModal = true;
  }

  /**
   * reset Product Subscribe Modal
   */
  resetProductSubscribeModal(status): void {

    this.showSubscribeModal = false;
    if (status == true) {
      this.suggObj.connection_status = 1;
    }

  }

  /**
   * navigate To User chat
   */
  navigateToUserchat(): void {
    if (this.product && this.product.product_type) {
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_NUMBER_MAPPING[this.product.product_type], this.product.feed_id, this.callingfrom, CTA_TYPE.CARD, ACTION_TYPE.CLICK, ACTION_ON.USER_CHAT, null);
    }
    else if (this.suggObj?.uuid) {
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.USER, this.suggObj?.uuid, this.callingfrom, CTA_TYPE.CARD, ACTION_TYPE.CLICK, ACTION_ON.USER_CHAT, null);
    }
    if (this.dss.user_profile_data.profile.permission != 'all') {

      this.dss.selected_suggAssList = [];
      this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0).subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            if (data['code'] == 2000) {
              this.displayObj.isShow = true;
              this.displayObj.display_section = 'verificationSuccess';
              this.displayObj.data = data['data'];
              this.displayObj.errObj = [];
              this.masterService.showWebModal.next(this.displayObj);
            }
          } else {
            this.fcs.errorHandeler(data, 'generate_certificate');
            return;
          }
        });
      }));

      return;
    }
    this.closeParentModal.emit();
    this.fcs.navigateForChat(this.suggObj.uuid);
  }

  /**
   * back Clicked
   */
  backClicked(): void {
    // No Code
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
*
* checks if the user is having option to send EC author request
* @readonly
* @type {boolean}
* @memberof ConnectionCardComponent
*/
  get isAddVisible(): boolean {
    return CONNECTION.ADD === this.suggObj.connection_status;
  }

  /**
  *
  * checks if the user is having option to Accept EC author request
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */
  get isAcceptVisible(): boolean {
    return CONNECTION.ACCEPT === this.suggObj.connection_status;
  }

  /**
  *
  * checks if the user is already connected to feed user
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */

  get isUserConnected(): boolean {
    return NUMBERS.ONE === this.suggObj.connection_status;
  }

  /**
  *
  * checks if the user is not connected to the feed user
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */
  get isUserNotConnected(): boolean {
    return NUMBERS.ZERO === this.suggObj.connection_status;
  }

  /**
  *
  * checks if the user is anonymous
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */
  get isAnonymousUser(): boolean {
    return NUMBERS.ONE === this.is_anonymous;
  }

  /**
  *
  * checks if connection button is visible
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */
  get isConnectEnable(): boolean {
    return NUMBERS.ONE === this.suggObj.is_connect_enable;
  }

  /**
  *
  * checks if the connection is shown on ec post
  * @readonly
  * @type {boolean}
  * @memberof ConnectionCardComponent
  */
  get isEditorsChoicePost(): boolean {
    return this.dss.ecProductFeed &&
      CALLING_FROM.FEED_DETAIL === this.calling_from &&
      this.dss.ecProductFeed.isEcProduct;
  }

  /**
   * checks if the post is from author
   *
   * @readonly
   * @type {boolean}
   * @memberof ConnectionCardComponent
   */
  get isAuthorPost(): boolean {
    return !!this.isAuthor;
  }

  /**
   * shows title when clicked on icon
   * @param event
   * @returns boolean
   */
  showTitle(event): boolean {
    event.stopPropagation();
    return false;
  }


    /**
     * emits community detail event
     * @param community_id
     */
    openCommunityDetail(event, community_id: string): void {
      event.stopPropagation();
      this.emitCommunityDetail.emit(community_id);
    }

  /**
   * emits open feed event
   */
  openFeed(event): void {
    event.stopPropagation();
    this.openFeedstatus = !(this.openFeedstatus || this.isPostedEvent);
    this.emitOpenFeed.emit(this.openFeedstatus);
  }

}
