<app-public-page-header
    (emitIconClick)="sendEvent($event)" [analytic]="record"
    [communityText]="mls.languageText.open_article.key10"
    [doctalkText]="mls.languageText.open_article.key11"
    [articleText]="mls.languageText.open_article.key12"
    [webinarText]="mls.languageText.open_article.key13"></app-public-page-header>

<div class="container">
    <div *ngIf="apiInProcess" class="text-center mt-4">
        <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post" alt="loader" />
    </div>
    <div *ngIf="!restrictContent">
    <div *ngIf="!apiInProcess" class="main-container mt-3">
        <div class="row">
            <div class="col-md-7">
                <div class="mb-3">
                    <div class="webinar-detail">
                        <div *ngIf="webinarDetail?.status === 'upcoming' " class="mt-3">
                            <img [src]="webinarDetail?.banner" class="w-100 webinar-detail__banner" alt="banner" onerror="this.src='assets/themes/images/webinar-default.svg'">
                        </div>
                        <div *ngIf="webinarDetail?.show_end_image" class="article-banner mt-3">
                            <img [src]="webinarDetail?.banner" alt="banner" onerror="this.src='assets/themes/images/webinar-default.svg'">
                        </div>
                        <div *ngIf="webinarDetail && webinarDetail !== undefined && webinarDetail?.status !== 'upcoming' && !webinarDetail?.show_end_image">
                            <app-shaka-basic [videoSrc]="webinarDetail?.media?.media_url"
                             [videoBanner]="webinarDetail?.media?.media_thumbnail"
                                shakaStyleCss='shaka-model'>
                            </app-shaka-basic>
                        </div>

                        <div *ngIf="webinarDetail && webinarDetail !== undefined && webinarDetail?.type === 'upcoming'">
                              <img [src]="webinarDetail?.banner_image" alt="banner" onerror="this.src='assets/themes/images/webinar-default.svg'">
                        </div>

                        <div class="w-100 p-3 border-top-0 detail-content__card ">
                        <p class="heading-2 mt-2"> <span class="color-grey-700 ">{{webinarDetail?.title}}</span></p>
                        <p class="heading-4 mt-2">  {{mls.languageText.open_article.key18}} </p>
                        <div class="paragraph new-inner-html new-inner-html-list banner-image mt-3">
                            <span innerHTML="{{webinarDetail?.description}}"> </span>
                        </div>
                        <p class="color-grey-500 mt-3">
                            <span *ngFor="let hashtag of webinarDetail?.speciality">
                                <span class="secondary-paragraph"><span class="color-grey-500 mr-2">#{{hashtag.name}}</span>
                                </span></span>
                        </p>
                        <div *ngIf="webinarDetail?.speaker">
                            <p class="heading-4 color-grey-700 mt-2">{{mls.languageText.open_article.key19}}</p>
                            <div class="mt-3">
                                <ng-container *ngFor="let speaker of webinarDetail?.speaker.slice(0, 3); let i = index">
                                    <div class="d-flex align-items-start">
                                        <img class="speaker mr-2 rounded-circle" [src]="speaker.profile_pic" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="speaker" />
                                        <div class="d-flex flex-column ml-1 mt-1">
                                            <span class="paragraph">{{ speaker.name }}</span>
                                            <span class="secondary-paragraph color-grey-500">{{ speaker.qualification }}</span>
                                        </div>
                                    </div>
                                    <div class="mt-3"></div>
                                </ng-container>

                                <div *ngIf="webinarDetail?.speaker.length > 3 && !hideViewAll" class=" text-center mt-3">
                                    <button class="btn-design small outline" (click)="toggleShowAll()">
                                      {{ showAll ? 'Hide' : mls.languageText.open_article.key15}} 
                                    </button>
                                  </div>

                                <div *ngIf="showAll">
                                    <ng-container *ngFor="let speaker of webinarDetail?.speaker.slice(3); let i = index">
                                        <div class="d-flex align-items-start">
                                            <img class="speaker mr-2 rounded-circle" [src]="speaker.profile_pic" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="speaker" />
                                            <div class="d-flex flex-column ml-1 mt-1">
                                                <span class="paragraph">{{ speaker.name }}</span>
                                                <span class="secondary-paragraph color-grey-500">{{ speaker.qualification }}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="i < webinarDetail?.speaker.length - 4" class="mt-3"></div>
                                        <!-- Add a div after each speaker except the last one -->
                                    </ng-container>
                                </div>
                            </div>


                        </div>
                        <div *ngIf="webinarDetail?.status === 'upcoming'">
                        <p class="mt-3">
                            <span class="paragraph color-grey-700">{{mls.languageText.open_article.key20}}</span>
                        </p>
                                <p class="paragraph-bold mt-3" *ngIf="webinarDetail?.status != 'live' && webinarStatus?.status !=  'end'">
                                    {{webinarDetail?.release_on*1000 | date : 'd MMMM y, h:mm a' : webinarDetail?.timezone }},
                                    {{webinarDetail?.timezone}}</p>

                        <div>
                        </div>
                        <div>
                            <button (click)="register()" class="btn-design large filled mt-2" [pAppAnalyticsEvent]="webinarRecord">
                                {{mls.languageText.open_article.key35}}
                            </button>
                        </div>
                        </div>
                    </div>
                        <div>
                            <app-share-strip
                                [title]="mls.languageText.open_article.key25"
                                [fbText]="mls.languageText.open_article.key26"
                                [twText]="mls.languageText.open_article.key27"
                                [liText]="mls.languageText.open_article.key28"
                                [shareUrl]="webinarDetail?.share_url"
                                (emitClick)="sendShareEvent($event)">
                            </app-share-strip>
                        </div>
                        <div>
                            
                            <p class="heading-4 color-grey-700 mt-4">{{mls.languageText.open_article.key42}}</p>
                            <div class="my-3">
                                    <div class="text-center signin-card cursor-pointer" (click)="navigateTologgedInWebinarDetail()" >
                                        <div class="card-elements p-2">
                                            <div>
                                                <img src="assets/themes/icons/public/doctor-icon.svg" alt="doctor-icon" class="doctor-img mt-3">
                                            </div>
                                            <p class="heading-4 mt-3">{{mls.languageText.open_article.key40}}</p>
                                            <p class="secondary-paragraph mt-2">{{mls.languageText.open_article.key41}}</p>
                                            <button [pAppAnalyticsEvent]="signInRecord"
                                                class="btn-design small filled h-auto cursor-pointer my-3">
                                                {{mls.languageText.open_article.key8}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                  

                        </div>
                    </div>



                </div>
            </div>
            <div *ngIf="suggestionList && suggestionList?.list.length > 0" class="col-md-5">
                <div>
                    <app-product-suggestion-list
                     [callingFrom]="'OPEN_ARTICLE_DETAIL'"
                     [suggestionList]="suggestionList?.list"
                     [listTitle]="suggestionList?.label"
                     [pType]="openAccessConst.productlist.webinar"
                     (cardClicked)="onCardClick()">
                    </app-product-suggestion-list>
                </div>
                <div class="text-center">
                  <app-public-view-all
                  [viewAllText]="mls.languageText.open_article.key15"
                  [pType]="openAccessConst.productlist.webinar"
                  [callingFrom]="callingFrom"
                  [title]="mls.languageText.open_article.key30"
                  [offset]="suggestionList?.offset"
                  (viewAllClick)="viewAllClick($event)"></app-public-view-all>
                </div>
            </div>
        </div>
    </div>
</div>
    <div *ngIf="restrictContent">
        <app-restricted-popup
        [restrictedPopup]="restrictContent"
        [restrictData]="restrictData">
        </app-restricted-popup>
        </div>

</div>

<app-public-skip-popup 
(loginClicked)="onCardClick()">
</app-public-skip-popup>

<div #webinarloginContainer class="d-none"></div>
