<span #myDrop="ngbDropdown" ngbDropdown (openChange)="toggleFloating($event)"
  class="d-flex flex-column float-dropdown ml-2 mt-1 cursor-pointer flex-shrink-0" display="static" container="null">
  <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer float-button">
    <img
      [src]="isDropdown ? 'assets/samvaad-assets/images/icons/chat-white-cross.svg' : 'assets/samvaad-assets/images/icons/chat-add.svg'"
      alt="dots">
  </span>
  <div style="cursor: default;" ngbDropdownMenu aria-labelledby="dropdownBasic1" display="static" container="null"
    (click)="myDrop.close()">
    <ng-container *ngFor="let list of floatingOptions; let i = index">
      <div class="float-button color-white cursor-pointer" [ngClass]="{'expand' : over[i] === true}"
        (mouseover)="over[i] = true" (mouseout)="over[i] = false" (click)="doAction(list.id)">

        <img [src]="list.url" alt="list" [ngClass]="{'mr-3' : over[i] === true}">

        {{over[i] === true ? list.text : ''}}
      </div>
    </ng-container>
  </div>
</span>
