export const SCREEN_NAME = {
  LOGIN_SCREEN: "LOGIN_SCREEN",
  PRODUCT_DETAIL_PAGE: "PRODUCT_DETAIL_PAGE",
  RESOURCE_LISTING: "RESOURCE_LISTING",
  PRODUCT_DASHBOARD: "PRODUCT_DASHBOARD",
  WELCOME_PAGE: "WELCOME_PAGE",
  ROLE_SELECTION_PAGE: "ROLE_SELECTION_PAGE",
  BUSINESS_QUERY_OTP: "BUSINESS_QUERY_OTP",
  BUSINESS_QUERY_FORM: "BUSINESS_QUERY_FORM",
};

export const EVENT_NAME = {
  CARD_CLICK: {
    name: "CARD_CLICK",
    hasDQ: true,
  },
  LANGUAGE_DROPDOWN_CLICK: {
    name: "LANGUAGE_DROPDOWN_CLICK",
    hasDQ: true,
  },
  SUBMIT_LOGIN_CLICK: {
    name: "SUBMIT_LOGIN_CLICK",
    hasDQ: true,
  },
  TERMS_CONDITIONS_CLICK: {
    name: "TERMS_CONDITIONS_CLICK",
    hasDQ: true,
  },
  DATA_POLICY_CLICK: {
    name: "DATA_POLICY_CLICK",
    hasDQ: true,
  },
  LOGIN_SCREEN_ENTRY: {
    name: "LOGIN_SCREEN_ENTRY",
    hasDQ: true,
  },
  LOGIN_SCREEN_EXIT: {
    name: "LOGIN_SCREEN_EXIT",
    hasDQ: true,
  },
  PRODUCT_DETAIL_PAGE_ENTRY: {
    name: "PRODUCT_DETAIL_PAGE_ENTRY",
    hasDQ: true,
  },
  PRODUCT_DETAIL_PAGE_EXIT: {
    name: "PRODUCT_DETAIL_PAGE_EXIT",
    hasDQ: true,
  },
  LOGIN_CLICK: {
    name: "LOGIN_CLICK",
    hasDQ: true,
  },
  SEARCH_CLICK: {
    name: "SEARCH_CLICK",
    hasDQ: true,
  },
  COMMUNITY_ICON_CLICK: {
    name: "COMMUNITY_ICON_CLICK",
    hasDQ: true,
  },
  DOCTALKS_ICON_CLICK: {
    name: "DOCTALKS_ICON_CLICK",
    hasDQ: true,
  },
  WEBINAR_ICON_CLICK: {
    name: "WEBINAR_ICON_CLICK",
    hasDQ: true,
  },
  ARTICLES_ICON_CLICK: {
    name: "ARTICLES_ICON_CLICK",
    hasDQ: true,
  },
  FB_SHARE_CLICK: {
    name: "FB_SHARE_CLICK",
    hasDQ: true,
  },
  TWITTER_SHARE_CLICK: {
    name: "TWITTER_SHARE_CLICK",
    hasDQ: true,
  },
  LINKEDIN_SHARE_CLICK: {
    name: "LINKEDIN_SHARE_CLICK",
    hasDQ: true,
  },
  REGISTER_CLICK: {
    name: "REGISTER_CLICK",
    hasDQ: true,
  },
  PLAY_EPISODE_CLICK: {
    name: "PLAY_EPISODE_CLICK",
    hasDQ: true,
  },
  SIGNIN_CLICK: {
    name: "SIGNIN_CLICK",
    hasDQ: true,
  },
  COUNTRY_DROPDOWN_CLICK: {
    name: "COUNTRY_DROPDOWN_CLICK",
    hasDQ: true,
  },
  RESOURCE_LISTING_ENTRY: {
    name: "RESOURCE_LISTING_ENTRY",
    hasDQ: true,
  },
  RESOURCE_LISTING_EXIT: {
    name: "RESOURCE_LISTING_EXIT",
    hasDQ: true,
  },
  PRODUCT_DASHBOARD_ENTRY: {
    name: "PRODUCT_DASHBOARD_ENTRY",
    hasDQ: true,
  },
  PRODUCT_DASHBOARD_EXIT: {
    name: "PRODUCT_DASHBOARD_EXIT",
    hasDQ: true,
  },
  WELCOME_PAGE_ENTRY: {
    name: "WELCOME_PAGE_ENTRY",
    hasDQ: true,
  },
  WELCOME_PAGE_EXIT: {
    name: "WELCOME_PAGE_EXIT",
    hasDQ: true,
  },
  WELCOME_CLOSE_CLICK: {
    name: "WELCOME_CLOSE_CLICK",
    hasDQ: true,
  },
  WELCOME_VERIFY_CLICK: {
    name: "WELCOME_VERIFY_CLICK",
    hasDQ: true,
  },
  WELCOME_ESCAPE_CLICK: {
    name: "WELCOME_ESCAPE_CLICK",
    hasDQ: true,
  },
  VIEW_ALL_CLICK: {
    name: "VIEW_ALL_CLICK",
    hasDQ: true,
  },
  DETAIL_VIEW_CLICK: {
    name: "DETAIL_VIEW_CLICK",
    hasDQ: true,
  },
};
