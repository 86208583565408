<!-- web view -->
<div *ngIf="!fcs.device_detail.is_mobile && callingFrom !== screenName.OPEN_ARTICLE_DETAIL " class="custom-card jj cursor-pointer"
  (click)="navigateToOpenProductdetail()" [pAppAnalyticsEvent]="analyticRecord">
  <div class="text-center">
  <img class="banner img-cover-fit card-img-height" [src]="openArticle.banner" alt="Card Image" (error)="errorHandler($event)" >
  <img *ngIf="pType === openAccessConst.productlist.doctalk  || pType === openAccessConst.productlist.series"
            class="position-absolute toast-center-center doctalk-play-icon" src="assets/themes/icons/Play.svg" />
</div>
  <div class="card-content p-3" [ngStyle]="textContainer">
    <span class="heading-4 article-card-heading h-49 pb-2">{{openArticle.title}}</span>
      <div class="d-flex align-items-center">
        <div class="hash-tag">
      <span  *ngFor="let hashtag of openArticle.speciality | slice: 0: 2">
        <span class="secondary-paragraph  mr-2 "><span class="color-grey-500">#{{hashtag.name}}</span>
        </span></span>
      </div>
        <div *ngIf="openArticle?.speciality.length> numbers.TWO" class="primary-small ml-2"> <span>+{{openArticle?.speciality?.length - numbers.TWO}}{{mls.languageText.open_article.key14}}
        </span>
      </div>
      </div>
      <div *ngIf="pType === this.openAccessConst.productlist.article">
    <p class="paragraph pt-2 mt-1 mb-2 ">
      <span class="color-grey-700">{{mls.languageText.open_article.key23}}</span>
    </p>
    <div class="d-flex align-items-center  mr-1">
      <div class="mr-2 img-powerBy">
        <img src="assets/themes/icons/public/Profile_Default.svg" class="profile-default" alt="channel">
      </div>
      <span class="secondary-paragraph">{{mls.languageText.open_article.key16}}</span>
    </div>
  </div>

  <div *ngIf="!isEpisode && (pType === this.openAccessConst.productlist.webinar || pType === this.openAccessConst.productlist.series || pType === this.openAccessConst.productlist.doctalk)" class="h-49 d-flex  pt-2 mt-1 mb-2">
    <div>
      <div *ngFor="let speaker of openArticle.speaker | slice: 0: 1">
        <div class="d-flex align-items-center mr-1 w-100">
          <div class="mr-2">
            <img [src]="speaker?.profile_pic" class="profile-default rounded-circle" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="profile">
          </div>
          <div class="d-flex flex-column ml-1">
            <p class="secondary-paragraph speaker-desc">{{speaker.name}}</p>
            <p class="secondary-paragraph color-grey-500 speaker-desc">{{ speaker.qualification }}</p>
          </div>
        </div>
      </div>
      
  </div>
  
    <div *ngIf="openArticle?.speaker.length> numbers.ONE" class="primary-small ml-2 align-top"> <span>+{{openArticle?.speaker?.length - numbers.ONE}}{{mls.languageText.open_article.key14}}
    </span>
  </div> 
  </div>
  <div *ngIf="isEpisode && openArticle?.index" class="paragraph pt-2 mb-2 mt-1"> 
     E{{openArticle.index}}
  </div>
  </div>
</div>

<!-- mobile view-->
<div *ngIf="fcs.device_detail.is_mobile && callingFrom !== screenName.OPEN_ARTICLE_DETAIL && renderType !== openAccessConst.prodListRenderType.product_sugg_list" [ngClass]="{'mobile-card cursor-pointer': isCarousel , 'mobile-public-card cursor-pointer': !isCarousel}"
  (click)="navigateToOpenProductdetail()" [pAppAnalyticsEvent]="analyticRecord">
  <div class="text-center">
  <img class="banner card-img-height img-cover-fit w-100" [src]="openArticle.banner" alt="Card Image" (error)="errorHandler($event)" >
  <img *ngIf="pType === openAccessConst.productlist.doctalk  || pType === openAccessConst.productlist.series"
            class="position-absolute toast-center-center doctalk-play-icon" src="assets/themes/icons/Play.svg" />
          </div>
  <div class="card-content p-3">
    <span class="heading-4 h-49 article-card-heading ">{{openArticle.title}}</span>
    <div class="d-flex align-items-center">
      <div class="hash-tag">
    <span  *ngFor="let hashtag of openArticle.speciality | slice: 0: 2">
      <span class="secondary-paragraph  mr-2 "><span class="color-grey-500">#{{hashtag.name}}</span>
      </span></span>
    </div>
      <div *ngIf="openArticle?.speciality.length> numbers.TWO" class="primary-small ml-2"> <span>+{{openArticle?.speciality?.length - numbers.TWO}}{{mls.languageText.open_article.key14}}
      </span>
    </div>
    </div>
    <p class="primary-small pt-1 mb-2 d-none d-md-block"><span class="color-grey-700">{{mls.languageText.open_article.key23}}</span></p>
    <div *ngIf="pType === this.openAccessConst.productlist.article " class="d-flex align-items-center  mr-1" [ngClass]="fcs.device_detail.is_mobile ? 'mt-3 pt-1' : ''"><div class="mr-2" > <img src="assets/themes/icons/public/Profile_Default.svg" class="profile-default" alt="channel"></div><span class="primary-small ">{{mls.languageText.open_article.key16}}</span></div>
    
    <div *ngIf="!isEpisode && (pType === this.openAccessConst.productlist.webinar || pType === this.openAccessConst.productlist.series || pType === this.openAccessConst.productlist.doctalk)" class="h-49 d-flex pt-2 mt-1 mb-2">
      <div>
        <div *ngFor="let speaker of openArticle.speaker | slice: 0: 1">
          <div class="d-flex align-items-center mr-1 w-100">
            <div class="mr-2">
              <img [src]="speaker?.profile_pic" class="profile-default rounded-circle" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="profile">
            </div>
            <div class="d-flex flex-column ml-1">
              <p class="secondary-paragraph speaker-desc-mob">{{speaker.name}}</p>
              <p class="secondary-paragraph color-grey-500 speaker-desc-mob">{{ speaker.qualification }}</p>
            </div>
          </div>
        </div>
        
    </div>
    
      <div *ngIf="openArticle?.speaker.length> numbers.ONE" class="primary-small ml-2 align-top"> <span>+{{openArticle?.speaker?.length - numbers.ONE}}{{mls.languageText.open_article.key14}}
      </span>
    </div>
    </div>

    <div *ngIf="isEpisode && openArticle?.index" class="paragraph pt-2 mb-2 mt-1"> 
      E{{openArticle.index}}
   </div>

  </div>
</div>



<div *ngIf="fcs.device_detail.is_mobile && renderType === openAccessConst.prodListRenderType.product_sugg_list" class="vertical-card mm cursor-pointer"
(click)="navigateToOpenProductdetail()" [pAppAnalyticsEvent]="analyticRecord">
  <div class="row">
    <div class="col-9">
      <span class="heading-4 article-card-heading">{{openArticle.title}}</span>
      <div>
        <span *ngFor="let hashtag of openArticle.speciality | slice: 0: 2">
          <span class="primary-small pt-1 mr-2"><span class="color-grey-500">#{{hashtag.name}}</span>
          </span></span>
          <span *ngIf="openArticle?.speciality.length> 2" class="primary-small cursor-pointer">
            <span>+{{openArticle?.speciality?.length - numbers.TWO}}{{mls.languageText.open_article.key14}}</span>
          </span>
      </div>

      <div class="channel pt-1">
        <img src="assets/images/icons/sponsor-default.svg" alt="sponsor-default" class="mr-1 ">
        <span class="primary-small pt-1 mb-2"><span class="color-grey-700">{{mls.languageText.open_article.key23}}</span></span>
      </div>
    </div>
    <div class="col-3">
      <img [src]="openArticle.banner" alt="Card Image" (error)="errorHandler($event)" class="float-right" >
    </div>
  </div>
</div>

<!-- suggestion -->
<div *ngIf=" callingFrom === screenName.OPEN_ARTICLE_DETAIL " class="vertical-card jj cursor-pointer"
(click)="navigateToOpenProductdetail()" [pAppAnalyticsEvent]="analyticRecord">
  <div class="d-flex">
    <div class="w-100 pr-2 mr-1" style="max-width:225px">
      <span class="secondary-paragraph article-card-heading" ><span [ngClass]="fcs.device_detail.is_mobile ? 'font-weight-bold' : ''">{{openArticle.title}}</span></span>
      <div class="d-flex align-items-center mt-1">
        <div class="hash-tag">
      <span  *ngFor="let hashtag of openArticle.speciality | slice: 0: 2">
        <span class="mr-2 " [ngClass]="fcs.device_detail.is_mobile ? 'primary-small' : 'secondary-paragraph'"><span class="color-grey-500">#{{hashtag.name}}</span>
        </span></span>
      </div>
        <div *ngIf="openArticle?.speciality.length> numbers.TWO" class="primary-small ml-2"> <span>+{{openArticle?.speciality?.length - numbers.TWO}}{{mls.languageText.open_article.key14}}
        </span>
      </div>
      </div>

      <div *ngIf=" pType === this.openAccessConst.productlist.article" class="d-flex align-items-center channel pt-1 pt-md-2">
        <img src="assets/images/icons/sponsor-default.svg"  class="mr-2 ">
        <span class="secondary-paragraph">
          <span class="color-grey-700">{{mls.languageText.open_article.key23}}</span>
        </span>
      </div>
      <div *ngIf="pType === this.openAccessConst.productlist.webinar || pType === this.openAccessConst.productlist.series || pType === this.openAccessConst.productlist.doctalk" class="d-flex pt-2 mt-1 mb-2">
        <div class="hash-tag">
      <span  *ngFor="let speaker of openArticle.speaker | slice: 0: 1">
        <div class="d-flex  mr-1">
          <div class="mr-2 ">
            <img  [src]="speaker?.profile_pic" class=" profile-default rounded-circle" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="profile">
          </div>
          <span class="d-flex align-items-start flex-column ml-1 secondary-paragraph mr-2 ">
          <span class="secondary-paragraph speaker-desc-suggestion">{{speaker.name}}</span>
          <span class="secondary-paragraph speaker-desc-suggestion color-grey-500">{{ speaker.qualification }} 
          
          </span>
        </span>
      </div>
    </span>
      </div>
      
        <div *ngIf="openArticle?.speaker.length> numbers.ONE" class="primary-small ml-2 align-top"> <span class="align-top">+{{openArticle?.speaker?.length - numbers.ONE}}{{mls.languageText.open_article.key14}}
        </span>
      </div>
      </div>

    </div>
    <div class="img-min-width">
      <img  [src]="openArticle.banner" alt="Card Image" (error)="errorHandler($event)" class="float-right">
    </div>
  </div>
</div>

<div #articleloginContainer class="d-none"></div>