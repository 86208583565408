import { Pipe, PipeTransform } from '@angular/core';
// Services
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';

@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterData: any;

  constructor(private ms: MasterserviceService, ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(imgUrl: any): any {

    this.masterData = this.ms.masterApiData;
    if (this.masterData != undefined && imgUrl != null && imgUrl != '') {
        // alert( imgUrl.replace(/yxy/g, '3x2'));
        return imgUrl.replace(/yxy/g, '3x2');

      }
    return imgUrl;
  }

}
