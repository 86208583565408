<div class="position-relative"
  [ngClass]="{'full-chat-list-panel': chatView === chatViewConstant.FULL, 'mini-chat-list-panel': chatView === chatViewConstant.MINI ,'h-unset': isChatListExpanded && chatView === chatViewConstant.MINI}">
  <div class="chat-list-panel-header d-flex justify-content-between align-items-center"
    [ngClass]="{'cursor-pointer': chatView === chatViewConstant.MINI}"
    (click)="chatView === chatViewConstant.MINI ? toggleExpandWindow() : null">
    <div class="d-flex w-100 icon-hover align-items-center ml-1">
      <span class="icon custom-tooltip" [ngClass]="!isChatListExpanded ? 'bottom' : 'top'" *ngIf="chatView === chatViewConstant.MINI && !isInternalSearch || isChatListExpanded">
        <img class="cursor-pointer"
          [src]="!isChatListExpanded ? 'assets/samvaad-assets/images/icons/chat-list-expand.svg' : 'assets/samvaad-assets/images/icons/chat-list-collpased.svg'"
          alt="expand">
        <span class="tooltip-content">{{!isChatListExpanded ? samvaadLanguageText?.samvaad_tooltips?.key1 : samvaadLanguageText?.samvaad_tooltips?.key2 }}</span>
      </span>
      <span class="icon" *ngIf="chatView === chatViewConstant.MINI" class="chat-list-cross cursor-pointer"
        (click)="$event.preventDefault();$event.stopPropagation();closeChatList()">
        <img src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="cross">
      </span>
      <h4 *ngIf="!isInternalSearch || isChatListExpanded" class="heading-4 py-3 pr-3" [ngClass]="{
          'mr-2' : totalUnreadCount > 0 && isChatListExpanded,
          'pl-3' : !(chatView === chatViewConstant.MINI && !isInternalSearch || isChatListExpanded)
        }">
        {{samvaadLanguageText?.samvaad?.key14}}
        <span *ngIf="totalUnreadCount > 0 && isChatListExpanded" class="count secondary-paragraph mt-1">
          <span class="color-white">
            {{totalUnreadCount > maxUnreadCount.COUNT ? maxUnreadCount.TEXT : totalUnreadCount}}
          </span>
        </span>
      </h4>
      <div class="w-100 internal-search-padding" *ngIf="isInternalSearch" [hidden]="isChatListExpanded"
        (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="d-flex form-input with-icon icon-hover align-items-center">
          <span class="icon custom-tooltip bottom"
            (click)="$event.preventDefault();$event.stopPropagation();isInternalSearch = false;searchText = ''">
            <img class="cursor-pointer" src="assets/samvaad-assets/images/icons/search-user-left-arrow.svg" alt="back">
            <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key3}}</span>
          </span>
          <input #internalSearch class="input-field ml-2" type="text"
            [placeholder]="samvaadLanguageText?.samvaad?.key16" [(ngModel)]="searchText"/>
          <span *ngIf="searchText" class="icon-in-field icon custom-tooltip bottom"
            (click)="$event.preventDefault();$event.stopPropagation();searchText=''">
            <img src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="icon" />
            <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key6}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0 chat-list-side-icons icon-hover mr-md-0 mr-3">
      <span class="icon mr-2 custom-tooltip bottom" *ngIf="!isInternalSearch && !isChatListExpanded"
        (click)="$event.preventDefault();$event.stopPropagation();startInternalChat()">
        <img [hidden]="isChatListExpanded" (click)="searchIconClicked()" class="cursor-pointer chat-list-search"
          src="assets/samvaad-assets/images/icons/chat-search.svg" alt="search">
          <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key7}}</span>
      </span>
      <span class="icon custom-tooltip" [ngClass]="!isChatListExpanded ? 'bottom-right' : 'top-right'" *ngIf="chatView === chatViewConstant.MINI"
        (click)="$event.preventDefault();$event.stopPropagation();(!isChatListExpanded ? chatExpand() : doFloatingAction(chatType.GROUP))">
        <img class="chat-list-expander cursor-pointer"
          [src]="!isChatListExpanded ? 'assets/samvaad-assets/images/icons/chat-expand.svg' : 'assets/samvaad-assets/images/icons/chat-mini-window-expand.svg'"
          alt="expander">
          <span class="tooltip-content">{{!isChatListExpanded ? samvaadLanguageText?.samvaad_tooltips?.key8 : samvaadLanguageText?.samvaad_tooltips?.key9}}</span>
      </span>
    </div>
  </div>
  <div class="chat-list-small-screen-body" [hidden]="isChatListExpanded && chatView === chatViewConstant.MINI">
    <div class="chat-tabs hz-tabs pl-0">
      <ul class="tab-items mb-0  pl-0 cover-space">
        <li class="px-0" (click)="changeChatMode(chatType.P2P)">
          <label [ngClass]="{'active': chatMode === chatType.P2P}" class="heading-4 cursor-pointer">
            <span class="position-relative">
              {{samvaadLanguageText?.samvaad?.key15}}
              <img *ngIf="getUnreadStatus(chatList | onlyUserList)" class="not-dot"
                src="assets/samvaad-assets/images/icons/pulse.gif" alt="dot">
            </span>
          </label>
        </li>
        <li class="px-0" (click)="changeChatMode(chatType.GROUP)">
          <label [ngClass]="{'active': chatMode === chatType.GROUP}" class="heading-4 cursor-pointer">
            <span class="position-relative">
              {{samvaadLanguageText?.samvaad_create_edit_group?.key15}}
              <img *ngIf="getUnreadStatus(chatList | onlyGroupList)" class="not-dot"
                src="assets/samvaad-assets/images/icons/pulse.gif" alt="dot">
            </span>
          </label>
        </li>
      </ul>
    </div>
    <div class="chat-list-panel-body">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
      <ng-container>
        <div [hidden]="chatMode === chatType.GROUP"
          *ngFor="let users of chatList | onlyUserList | sortedWithSecondaryBy: 'lastMsg': 'ts': true | searchFilter: searchText; trackBy:trackMe;let i = index">
          <app-individual-info-box class="individual-info-box"
            [ngClass]="{'bg-deep-green-100': users.topic === chatList[selectedUserIndex]?.topic}"
            [info]="{pic: users.public?.dp ? users.public?.dp?.data : '', name: (!users.public?.misc?.deleted ? users.public?.fn + ' ' + users.public?.ln : deletedUserName), badgePic: getUserOnlineOfflineStatus(users)}"
            [isCursorPointer]="true" [secondaryType]="getLastMsgDate(users)" [count]="users.unread"
            [endSrc]="users.acs.isJoiner() && !users.acs.isBuzzer() ? 'assets/samvaad-assets/images/icons/mute-list-icon.svg' : ''"
            [profileSecondary]="getLastMsg(users, chatType.P2P) | linebreaks"
            (click)="openChatDetail(users.topic, chatType.P2P)"
            (onAvatarClick)="emitAvatarClick(chatType.P2P, users.topic)">
          </app-individual-info-box>
        </div>
      </ng-container>
      <ng-container>
        <div [hidden]="chatMode === chatType.P2P"
          *ngFor="let users of chatList | onlyGroupList | sortedWithSecondaryBy: 'lastMsg': 'ts': true | searchFilter: searchText; trackBy:trackMe; let i = index">
          <app-individual-info-box class="individual-info-box"
            defaultSrc="assets/samvaad-assets/images/icons/group-default.svg"
            [ngClass]="{'bg-deep-green-100': users.topic === chatList[selectedUserIndex]?.topic}"
            [info]="{pic: users?.public?.photo ? dpSanitize(users?.public?.photo) : '', name: users?.public?.name, badgePic: ''}"
            [isCursorPointer]="true" [secondaryType]="getLastMsgDate(users)" [count]="users.unread"
            [endSrc]="users.acs.isJoiner() && !users.acs.isBuzzer() ? 'assets/samvaad-assets/images/icons/mute-list-icon.svg' : ''"
            [profileSecondary]="getLastMsg(users, chatType.GROUP) | linebreaks"
            (click)="openChatDetail(users.topic, chatType.GROUP)"
            (onAvatarClick)="emitAvatarClick(chatType.GROUP, users.topic)">
          </app-individual-info-box>
        </div>
      </ng-container>
      <div class="h-100" [hidden]="isLoading"
        *ngIf="searchText && ((!(chatList | onlyUserList | searchFilter: searchText)?.length && chatMode === chatType.P2P) || (!(chatList | onlyGroupList | searchFilter: searchText)?.length && chatMode === chatType.GROUP))">
        <app-chat-no-info banner="assets/samvaad-assets/images/icons/no-group-chat.svg"
          [paragraph]="chatMode !== chatType.P2P ? samvaadLanguageText?.samvaad?.key23 : samvaadLanguageText?.samvaad_create_edit_group?.key16"
          [btnText]="chatMode === chatType.P2P ? samvaadLanguageText?.samvaad?.key3 : samvaadLanguageText?.samvaad_create_edit_group?.key21"
          (onBtnClick)="newJourney()">
        </app-chat-no-info>
      </div>
      <div class="h-100" [hidden]="isLoading"
        *ngIf="(!(chatList | onlyUserList)?.length && chatMode === chatType.P2P) || (!(chatList | onlyGroupList)?.length && chatMode === chatType.GROUP)">
        <app-chat-no-info banner="assets/samvaad-assets/images/icons/no-group-chat.svg"
          [paragraph]="chatMode !== chatType.P2P ? samvaadLanguageText?.samvaad?.key23 : samvaadLanguageText?.samvaad_create_edit_group?.key16"
          [btnText]="chatMode === chatType.P2P ? samvaadLanguageText?.samvaad?.key3 : samvaadLanguageText?.samvaad_create_edit_group?.key21"
          (onBtnClick)="newJourney()">
        </app-chat-no-info>
      </div>
    </div>
  </div>
  <div class="floating-button" [hidden]="isChatListExpanded">
    <app-floating-buttons [floatingOptions]="floatingOptions" (emitAction)="doFloatingAction($event)">
    </app-floating-buttons>
  </div>
</div>

<ng-template #loading>
  <app-chat-progress *ngIf="isLoading" [isSmallLoading]="false" [width]="60" [height]="60"></app-chat-progress>
</ng-template>
