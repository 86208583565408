/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';

/**
 * Service imports.
 */
import { AuthService } from '../auth/auth.service';
import { DataStorageService } from '../dataservices/data-storage.service';

/**
 * Rxjs imports.
 */
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, debounceTime, retry } from 'rxjs/operators';

/**
 * Constant imports.
 */
import { PRODUCT_TYPE_CONSTANT, WEBINAR_PAGE_TYPE, is_sharelink_applicable, NUMBERS, META_PREVIEW_TYPE } from '../constants/app.constants';
import { API } from '../constants/api.constants';

/**
 * Interface imports.
 */
import {
  AssociationListData,
  BackgroundActiveData,
  BookmarkProductListData,
  CertificateHistoryData,
  ChannelTncData,
  ChatConnectionListData,
  ChatSearchListData,
  CMEDescriptionData,
  CMEDetailData,
  CMEHistoryData,
  CMEListData,
  CommentListData,
  CommunityListResponse,
  DegreeListData,
  DeleteProfileResponse,
  DoctalkDetailData,
  DoctalkListData,
  DynamicFormResponse,
  EdetailingDrugDetailData,
  EdetailingPharmaDetailData,
  EdetailingPharmaListData,
  EdetailingPharmaTncData,
  EdetailingTherapeuticChannelData,
  EdetailingTherapeuticDrugListData,
  EdetailingTherapeuticListData,
  EventCertificateDetailData,
  EventDetailData,
  EventListData,
  EventMediaListData,
  EventSessionDetailData,
  EventSessionListData,
  EventUserActivityData,
  FilterListData,
  GrandRoundData,
  HospitalListData,
  JournalDetailData,
  JournalListData,
  LikeListData,
  MRCommentGetData,
  MRMeetingDetailData,
  PharmaChannelHomeListData,
  PharmaDetailData,
  PharmaDrugDetailData,
  PharmaDrugListData,
  PharmaDrugTimeSlotData,
  PharmaProductListData,
  ProductMissingData,
  ProfileTimelineData,
  ProfileViewListData,
  ReasonListData,
  ReferralContentData,
  ScheduleListData,
  SearchDocquityGroupData,
  SearchSuggestionData,
  SearchTrendData,
  SessionMappedProductData,
  ShareContentData,
  SourceListData,
  SpeakersListData,
  SpecialityListData,
  SuggestionListData,
  SurveyData,
  UniversityListData,
  UserAssociationListData,
  UserNotificationListData,
  UserReferralPointData,
  VaultListData,
  VerificationUnderProcessData,
  WebinarData,
  WebinarNotifyMeData,
  WebinarStatusResponseData,
  CommunityDetailResponse,
  CommunityMembersResponse,
  CommunityPostedByResponse,
  TextToSpeechResponse,
  VerificationSuccessResponse,
  MemberListData,
  DefaultContentResponse,
  ContinueWatchViewALLResponse,
  CMEModuleDetailData,
  CMEModuleNextStepData
} from '../onboarding/interface/apiResponse.model';
import { UserStoryListApiResponse } from '../interface/user-story/user-story';
import { EcDetail } from './../interface/editors-choice/editors-choice.model';
import { GameDetailResponse, NCPDetailResponse } from './../game/model/ncp.model';
import { SurveyApiResponse } from '../survey/model/survey.model';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';
/**
 * Get Service
 */
@Injectable({
  providedIn: 'root'
})
export class GetService implements OnDestroy {
  public lang = 'en';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_Sentlist_var: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sent_counter = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_list_counter = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public doctalkseriesbookmark = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public doctalkserieslike = new Subject<any>();
  public token: string;

  /**
   * Necessary Instances.
   */
  constructor(private httpClient: HttpClient, private authService: AuthService, private handler: HttpBackend, private dss: DataStorageService) {
    if (localStorage.getItem('lang') != null && localStorage.getItem('lang') != 'null' && localStorage.getItem('lang') != undefined) {
      this.lang = localStorage.getItem('lang');
    }
    this.token = this.authService.getToken();
  }

  /**
   * channel list data
   */
  getsuggestedAssociation(country_id): Observable<AssociationListData> {
    const params = { country_id: country_id };
    return this.httpClient.get<AssociationListData>(API.ASSOCIATION_SUGGESTED_LIST, {
      params: params
    });
  }

  /**
   * get StS Token
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStSToken(): Observable<any> {
    return this.httpClient.get(API.DOCQUITY_STS_TOKEN);
  }

  /**
   * get User Association
   */
  getUserAssociation(repostData): Observable<UserAssociationListData> {
    const params = { product_type: repostData.product_type, product_id: repostData.product_id };
    return this.httpClient.get<UserAssociationListData>(API.USER_ASSOCIATION_LIST, {
      params: params
    });
  }

  /**
   * grand Round List
   */
  grandRoundList(offset, feed_filter): Observable<GrandRoundData> {
    const params = { offset: offset, filters: JSON.stringify(feed_filter), 'is_sharelink_applicable': '0' };
    return this.httpClient.get<GrandRoundData>(API.GRAND_ROUND_LIST, {
      params: params
    });
  }

  /**
   * get Timeline List
   */
  getTimelineList(offset, custom_id): Observable<ProfileTimelineData> {
    const params = { offset: offset, custom_id: custom_id };
    return this.httpClient.get<ProfileTimelineData>(API.PROFILE_TIMELINE, {
      params: params
    });
  }

  /**
   * clinical-resource list data
   */
  getClinicalcaseList(offset, type, speciality): Observable<JournalListData> {
    let journalType;
    if (type.length == '') {
      journalType = '';
    } else {
      journalType = '["' + type + '"]';
    }

    let clinical_speciality_filter;
    if (speciality.length != 0) {
      clinical_speciality_filter = speciality[0].value;
    } else {
      clinical_speciality_filter = '';
    }

    const params = {
      'offset': offset,
      'speciality': '[' + clinical_speciality_filter + ']',
      'limit': ' ', 'type': journalType,
      'is_sharelink_applicable': '0'
    };

    return this.httpClient.get<JournalListData>(API.JOURNAL_LIST, {
      params: params
    });
  }

  /**
   * clinical-case list data
   */
  getClinicalspecialityList(): Observable<SpecialityListData> {
    return this.httpClient.get<SpecialityListData>(API.SPECIALITY_LIST_V4);
  }

  /**
   * clinical-resource detail
   */
  getClinicalDetail(journal_id, ec_id?): Observable<JournalDetailData> {
    if (ec_id) {
      return this.httpClient.get<JournalDetailData>(API.JOURNAL_DETAIL, {
        params: { journal_id, ec_id, 'is_sharelink_applicable': is_sharelink_applicable.OFF }
      });
    } else {
      return this.httpClient.get<JournalDetailData>(API.JOURNAL_DETAIL, {
        params: { journal_id, is_sharelink_applicable: is_sharelink_applicable.OFF }
      });
    }

  }

  /**
   * clinical-resource desc
   */
  getClinicalDes(id): Observable<JournalDetailData> {
    const params = {
      'journal_id': id,
      description: 'true',
      'is_sharelink_applicable': '0'
    };

    return this.httpClient.get<JournalDetailData>(API.JOURNAL_DETAIL, {
      params: params
    });
  }

  /**
   * get Cme List
   */
  getCmeList(offset, speciality): Observable<CMEListData> {
    const specialities = speciality ? speciality : '';
    const params = { 'offset': offset, 'filters': JSON.stringify(specialities) };
    return this.httpClient.get<CMEListData>(API.CME_LIST_V2, {
      params: params
    });
  }

  /**
   * get Cme detail
   */
  getCmeDetail(lesson_id, type): Observable<CMEDetailData> {
    const headers = {
      ver: environment.versions.apiVer50,
    }
    const params = { 'lesson_id': lesson_id, 'type': type };

    return this.httpClient.get<CMEDetailData>(API.CME_DETAIL, {
      headers,
      params: params
    });
  }

  /**
   * get cme detail if cme type is module and sub module is assign to it ex: Survey,CME,Webinar
   */
  public getCmeChildModuleDetail(parent_cme_id:number) : Observable<CMEModuleDetailData>{
    const params = { 
      'lesson_id' : parent_cme_id
    }
    const headers = {
      ver: environment.versions.apiVer50,
    }
    return this.httpClient.get<CMEModuleDetailData>(API.CME_MODULE_DETAIL_V2 ,{
      headers,
      params : params
    });
  }

  /**
   * get cme module next child module details if available OR user is allow to generate certificate 
   */
  public getNextStepForCmeModule(type:string,cme_child_module_id:number): Observable<CMEModuleNextStepData> {
    const params = {
      'product_id': cme_child_module_id,
      'product_type' : type
    }
    const headers = {
      ver: environment.versions.apiVer50,
    }
    return this.httpClient.get<CMEModuleNextStepData>(API.CME_MODULE_NEXT_STEP_JOURNEY, {
      headers,
      params: params
    });
  }

  /**
   * get Cme description
   */
  getCmeDesc(lesson_id): Observable<CMEDescriptionData> {
    const params = { 'lesson_id': lesson_id };
    return this.httpClient.get<CMEDescriptionData>(API.CME_DESCRIPTION, {
      params: params
    });
  }

  /**
   * CME vault offline n online list
   */
  getCmeVaultList(type, offset): Observable<CMEHistoryData> {
    const params = { 'offset': offset, 'type': type };
    return this.httpClient.get<CMEHistoryData>(API.CME_HISTORY_V2, {
      params: params
    });
  }

  /**
   * get Product Like List
   */
  getProductLikeList(type, type_id, offset): Observable<LikeListData> {
    const params = { type: type, type_id: type_id, offset: offset };
    return this.httpClient.get<LikeListData>(API.LIKE_LIST_V2, {
      params: params
    });
  }

  /**
   * get Product Comment List
   */
  getProductCommentList(type, type_id, offset): Observable<CommentListData> {
    const params = { type: type, type_id: type_id, offset: offset };
    if (this.dss.notificationAdditionalData
      && this.dss.notificationAdditionalData.subtype_identifier
      && this.dss.notificationAdditionalData.subtype_identifier === 'comment'
      && this.dss.notificationAdditionalData.subtype_target) {
      params['comment_id'] = this.dss.notificationAdditionalData.subtype_target;
    }
    return this.httpClient.get<CommentListData>(API.COMMENT_LIST, {
      params: params
    });
  }

  /**
   * get Tnc
   */
  getTnc(safeUrl): Observable<SurveyData> {
    const params = { safeurl: safeUrl };
    return this.httpClient.get<SurveyData>(API.SURVEY_TNC, {
      params: params
    });
  }

  /**
   * get Cme Source List
   */
  getCmeSourceList(): Observable<SourceListData> {
    return this.httpClient.get<SourceListData>(API.CME_SOURCE_LIST);
  }

  /**
   * get Doctalk List
   */
  getDoctalkList(section_key, offset, product_by_id, product_by, filter_value, filter_name): Observable<DoctalkListData> {
    const params = {
      section_key: section_key,
      offset: offset, product_by_id: product_by_id,
      filter_name: filter_name, product_by: product_by,
      filter_value: filter_value
    };
    return this.httpClient.get<DoctalkListData>(API.DOC_TALKS_LIST, {
      params: params
    });
  }

  /**
   * get Doctalk Detail
   */
  getDoctalkDetail(doctalks_id, type): Observable<DoctalkDetailData> {
    const params = { doctalks_id: doctalks_id, product_type: type };

    return this.httpClient.get<DoctalkDetailData>(API.DOC_TALKS_DETAIL, {
      params: params
    });
  }

  /**
   * webinar list api
   */
  getWebinarList(section_key, offset, webinar_id,resume_on=0): Observable<WebinarData> {
    const headers = {
      ver: environment.versions.apiVer50,
    }
    const params = { 'section_key': section_key, 'offset': offset, 'webinar_id': webinar_id ,'resume_on':resume_on};
    return this.httpClient.get<WebinarData>(API.WEBINAR_V2_LIST, {
      params: params
    });
  }

  /**
   * Get cookie detail.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSignedCookie(streamkey, token, baseUrl): Observable<any> {
    const httpClient = this.createNewHttpClient();
    const url = baseUrl + API.COOKIE;
    const headers = {
      Authorization: 'Bearer ' + token
    };
    const params = new HttpParams()
      .set('streamKey', streamkey);

    return httpClient.get(url, {
      headers,
      params: params,
      withCredentials: true
    });
  }

  /**
   * webinar speciality list api
   */
  getWebinarSpecialityList(section_key, offset, speciality_id): Observable<WebinarData> {
    const params = { section_key: section_key, offset: offset, speciality_id: speciality_id };

    return this.httpClient.get<WebinarData>(API.WEBINAR_LIST, {
      params: params
    });
  }

  /**
   * webinar notify me
   */
  getWebinarNotifyme(product_type_id, webinarStatus, consentStatus, page_type: string): Observable<WebinarNotifyMeData> {
    const params = {
      product_type: PRODUCT_TYPE_CONSTANT.WEBINAR.toString(),
      product_type_id: product_type_id,
      consent: consentStatus,
      consent_type: webinarStatus,
      page_type
    };

    return this.httpClient.get<WebinarNotifyMeData>(API.WEBINAR_NOTIFY_ME, {
      params: params
    });
  }

  /**
   * get Share
   */
  getShare(product_type, product_type_id): Observable<ShareContentData> {
    const params = { product_type: product_type, product_type_id: product_type_id };

    return this.httpClient.get<ShareContentData>(API.LIST_SHARE_CONTENT, {
      params: params
    });
  }

  /**
   * getFeedMeta
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFeedMeta(meta_url): Observable<any> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
    }
    const params = {  'meta_url': meta_url , type_for : META_PREVIEW_TYPE.FEED}
    const endpoint = `${API.META_PREVIEW}`;
    return this.httpClient.get<any>(endpoint, {
      headers,
      params
    });
  }

  /**
   * channel_list
   */
  getChannelList(offset, section_key): Observable<PharmaChannelHomeListData> {
    const params = { offset: offset, section_key: section_key };
    return this.httpClient.get<PharmaChannelHomeListData>(API.PHARMA_CHANNEL_HOME_LIST, {
      params: params
    });
  }

  /**
   * get Channel Detail
   */
  getChannelDetail(offset, safe_url, filters, channel_id): Observable<PharmaProductListData> {
    const params = { offset: offset, safe_url: safe_url, filters: filters, channel_id: channel_id };
    const header = { releaseVersion: environment.versions.RELEASE_VERSION_17 };
    return this.httpClient.get<PharmaProductListData>(API.PHARMA_PRODUCT_LIST_V2, {
      params: params,
      headers: header
    });
  }

  /**
   * get Pharma Detail
   */
  getPharmaDetail(id): Observable<PharmaDetailData> {
    const params = { id: id };

    return this.httpClient.get<PharmaDetailData>(API.PHARMA_DETAIL_V4, {
      params: params
    });
  }

  /**
   * get Channel Filter
   */
  getChannelFilter(product_type): Observable<FilterListData> {
    const params = { product_type: product_type };
    return this.httpClient.get<FilterListData>(API.FILTER_LIST_V2, {
      params: params
    });
  }

  /**
   * get calender Event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getcalenderEvent(day, month, year): Observable<any> {
    const params = { day: day, month: month, year: year };

    return this.httpClient.get(API.CALENDER_LIST, {
      params: params
    });
  }

  /**
   * get Suggestion List
   */
  getSuggestionList(offset, product_type, product_type_id, section): Observable<SuggestionListData> {
    const params = { offset: offset, product_type: product_type, product_type_id: product_type_id, section: section };

    return this.httpClient.get<SuggestionListData>(API.LIST_SUGGESTION_LIST, {
      params: params
    });

  }

  /**
   * get Event List
   */
  getEventList(offset, list_type, filter): Observable<EventListData> {
    const params = { offset: offset, list_type: list_type, filter: filter };

    return this.httpClient.get<EventListData>(API.EVENT_LIST_V2, {
      params: params
    });

  }

  /**
   * get Event Detail
   */
  getEventDetail(event_id, filterMedia): Observable<EventDetailData> {
    const params = { event_id: event_id, filterMedia: filterMedia };

    return this.httpClient.get<EventDetailData>(API.EVENT_DETAIL, {
      params: params
    });
  }

  /**
   * get Session List
   */
  getSessionList(parent_id): Observable<EventSessionListData> {
    const params = { parent_id: parent_id };

    return this.httpClient.get<EventSessionListData>(API.EVENT_SESSION_LIST, {
      params: params
    });
  }

  /**
   * get Session Detail
   */
  getSessionDetail(session_id): Observable<EventSessionDetailData> {
    const params = { session_id: session_id };

    return this.httpClient.get<EventSessionDetailData>(API.EVENT_SESSION_DETAIL, {
      params: params
    });
  }

  /**
   * get Event Medialist
   */
  getEventMedialist(type, type_id): Observable<EventMediaListData> {
    const params = { type: type, type_id: type_id };
    return this.httpClient.get<EventMediaListData>(API.EVENT_MEDIA_LIST, {
      params: params
    });
  }

  /**
   * get live CommentList
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getliveCommentList(offset, type, type_id): Observable<any> {
    const params = { offset: offset, type: type, type_id: type_id };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(`${environment.API_URLS.socket_comment}${API.COMMENT_GET}`, {
      params: params
    });
  }

  /**
   * get DrugSample
   */
  getDrugSample(offset, product_type, product_type_id, filter, is_ordered): Observable<PharmaDrugListData> {
    const params = {
      offset: offset,
      product_type: product_type,
      product_type_id: product_type_id,
      filter: filter,
      is_ordered: is_ordered
    };

    return this.httpClient.get<PharmaDrugListData>(API.PHARMA_DRUG_LIST_V2, {
      params: params
    });
  }

  /**
   * get Drug Detail
   */
  getDrugDetail(drug_id): Observable<PharmaDrugDetailData> {
    const params = { drug_id: drug_id };

    return this.httpClient.get<PharmaDrugDetailData>(API.PHARMA_DRUG_DETAIL_V2, {
      params: params
    });
  }

  /**
   * get Drug Timeslot
   */
  getDrugTimeslot(drug_id): Observable<PharmaDrugTimeSlotData> {
    const params = { drug_id: drug_id };

    return this.httpClient.get<PharmaDrugTimeSlotData>(API.PHARMA_DRUG_TIME_SLOT, {
      params: params
    });
  }

  /**
   * connection list data
   */
  getconnectionList(search_type, offset, is_suggestion, keyword, filter, is_group = '0', community_id?): Observable<ChatSearchListData> {
    const params = {
      'offset': offset,
      'speciality_id': '0',
      'association_id': '0',
      'search_type': search_type,
      'is_suggestion': is_suggestion,
      'keyword': keyword,
      is_group: is_group,
      'filter': filter
    };
    if (community_id) {
      params['community_id'] = community_id;
    }

    return this.httpClient.get<ChatSearchListData>(API.CHAT_SEARCH_LIST, {
      params: params
    });
  }

  /**
   * connection list data
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAddMemberList(type: string, offset, is_suggestion, keyword: string, filter, is_group = '0', community_id?: string): Observable<MemberListData> {

    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
      version: environment.versions.api_version_updated
    };
    const params = {
      'type': 'ADD_MEMBER',
      'offset': offset,
      'keyword': keyword,
      'filter': filter
    };


    if (community_id) {
      params['community_id'] = community_id;
    }

    const endpoint = `${API.USER}`;
    return this.httpClient.get<MemberListData>(`${endpoint}`, {
      headers,
      params
    });
  }

  // TODO : Add Typing
  /**
   * Get exisiting community members
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCommunityMembers(keyword: string, communityId: string, offset?, connection_type?): Observable<CommunityMembersResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
      version: environment.versions.api_version_updated,

    };

    const params = {
      'type': 'EXISTING_MEMBER',
      'offset': offset,
      'keyword': keyword,
      'filter': ''
    };


    if (communityId) {
      params['community_id'] = communityId;
    }

    const endpoint = `${API.USER}`;
    return this.httpClient.get<CommunityMembersResponse>(`${endpoint}`, {
      headers,
      params
    });

  }

  // TODO : Add Typing
  /**
   * get Other User connection List
   */
  getOtherUserconnectionList(offset, cid): Observable<ChatConnectionListData> {
    const params = { 'offset': offset, 'requestee_custom_id': cid };

    return this.httpClient.get<ChatConnectionListData>(API.CHAT_CONNECTION_LIST, {
      params: params
    });
  }

  /**
   * bookmark list
   */
  getBookmarkList(offset, product_type): Observable<BookmarkProductListData> {
    const header = { releaseVersion: environment.versions.RELEASE_VERSION_20 };
    const params = { offset: offset, product_type: product_type, 'is_sharelink_applicable': '0' };
    return this.httpClient.get<BookmarkProductListData>(API.BOOKMARK_PRODUCT_LIST, {
      params: params,
      headers : header
    });
  }

  /**
   * get Notification List
   */
  getNotificationList(offset): Observable<UserNotificationListData> {
    const params = { offset: offset };

    return this.httpClient.get<UserNotificationListData>(API.NOTIFICATION_USER_LIST, {
      params: params
    });
  }

  /**
   * Registration status.
   */

  // TODO: Nilesh: auto verification
  getRegistrationStatus(): Observable<VerificationSuccessResponse> {
    const headers = {
      Authorization: environment.appKey,
      'Content-Type': 'application/x-www-form-urlencoded',
      userauthkey: this.token,
      lang: this.lang,
      ver: environment.versions.api_url_version_updated,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      udid: environment.udid,
      releaseVersion: environment.versions.RELEASE_VERSION_17
    };
    return this.httpClient.get<VerificationSuccessResponse>(API.REGISTRATION_SUCCESS_SCREEN, {
      headers
    });
  }

  /**
   * get Chat List
   */
  getChatList(params): Observable<ChatSearchListData> {
    return this.httpClient.get<ChatSearchListData>(API.CHAT_SEARCH_LIST, {
      params: params
    });
  }

  /**
   * global Search_get Trending
   */
  globalSearch_getTrending(product_type): Observable<SearchTrendData> {
    const params = { product_type: product_type };

    return this.httpClient.get<SearchTrendData>(API.LIST_SEARCH_TREND, {
      params: params
    });
  }

  /**
   * get Feedback Reason
   */
  getFeedbackReason(product_type): Observable<ReasonListData> {
    const params = { product_type: product_type };

    return this.httpClient.get<ReasonListData>(API.REASON_LIST, {
      params: params
    });

  }

  /**
   * get Search Term
   */
  getSearchTerm(offset: number, keyword: string, product_type: number, response_type: string, mycommunity?: number): Observable<SearchDocquityGroupData> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    let params: {} = { offset: offset.toString(), keyword, product_type: product_type.toString(), response_type };
    if (mycommunity >= NUMBERS.ZERO) {
      params = { ...params, mycommunity: mycommunity.toString() };
    }
    return this.httpClient.get<SearchDocquityGroupData>(API.SEARCH_DOCQUITY_DATA_GROUP, {
      params: params
    });
  }

  /**
   * get Hospital List
   */
  getHospitalList(offset, keyword): Observable<HospitalListData> {
    const params = { offset: offset, keyword: keyword };

    return this.httpClient.get<HospitalListData>(API.HOSPITAL_LIST, {
      params: params
    });

  }

  /**
   * get Universityl List
   */
  getUniversitylList(offset, keyword): Observable<UniversityListData> {
    const params = { offset: offset, keyword: keyword };

    return this.httpClient.get<UniversityListData>(API.UNIVERSITY_LIST_V2, {
      params: params
    });
  }

  /**
   * get Degreel List
   */
  getDegreelList(offset, keyword): Observable<DegreeListData> {
    const params = { offset: offset, keyword: keyword };

    return this.httpClient.get<DegreeListData>(API.DEGREE_LIST, {
      params: params
    });
  }

  /**
   * get Profile Viewer List
   */
  getProfileViewerList(custom_id, offset): Observable<ProfileViewListData> {
    const params = { offset: offset, custom_id: custom_id, };

    return this.httpClient.get<ProfileViewListData>(API.PROFILE_VIEW_LIST, {
      params: params
    });
  }

  /**
   * verification Under Process
   */
  verificationUnderProcess(): Observable<VerificationUnderProcessData> {
    return this.httpClient.get<VerificationUnderProcessData>(API.VERIFICATION_UNDER_PROCESS);
  }

  /**
   * get Nudge Data
   */
  getNudgeData(offset, tooltip_type): Observable<BackgroundActiveData> {
    const params = { offset: offset, tooltip_type: tooltip_type };

    return this.httpClient.get<BackgroundActiveData>(API.BACKGROUND_ACTIVE_V1, {
      params: params
    });

  }

  /**
   * get Refer Earn
   */
  getReferEarn(): Observable<ReferralContentData> {
    return this.httpClient.get<ReferralContentData>(API.REFERRAL_CONTENT);
  }

  /**
   * view Event Certificate
   */
  viewEventCertificate(program_id): Observable<VaultListData> {
    const params = { 'type': '21', 'type_id': program_id, };

    return this.httpClient.get<VaultListData>(API.EVENT_CERTIFICATE, {
      params: params
    });
  }

  /**
   * view Event Certificate List
   */
  viewEventCertificateList(eventId): Observable<CertificateHistoryData> {
    const params = { 'event_id': eventId, };

    return this.httpClient.get<CertificateHistoryData>(API.EVENT_CERTIFICATE_HISTORY, {
      params: params
    });
  }

  /**
   * get Agora Token
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAgoraToken(product_type, product_type_id): Observable<any> {
    const params = { product_type: product_type, product_type_id: product_type_id };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.WEBINAR_AGORA_TOKEN_V2, {
      params: params
    });
  }


  /**
   * get Agora Udid
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAgoraUdid(product_type, product_type_id, patner, udid): Observable<any> {
    const params = {
      product_type: product_type, product_type_id: product_type_id,
      patner: patner,
      udid: udid
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.WEBINAR_CDN_DETAILS, {
      params: params
    });
  }

  /**
   * get sample list
   */
  getSampleist(category_id, offset, drug_id, product_type, product_type_id): Observable<PharmaDrugListData> {
    const params = {
      'category_id': category_id,
      'offset': offset,
      'drug_id': drug_id,
      'product_type': product_type,
      'product_type_id': product_type_id
    };

    return this.httpClient.get<PharmaDrugListData>(API.PHARMA_DRUG_LIST, {
      params: params
    });
  }

  /**
   * get drug detail data
   */
  getDrugDetailold(safeUrl, drug_id): Observable<PharmaDrugDetailData> {
    safeUrl = safeUrl.split('/drug/');
    safeUrl = safeUrl[1];
    const params = { safe_url: safeUrl, drug_id: drug_id };

    return this.httpClient.get<PharmaDrugDetailData>(API.PHARMA_DRUG_DETAIL, {
      params: params
    });
  }

  /**
   * get Like List
   */
  getLikeList(product_id, product_type, offset): Observable<LikeListData> {
    return this.httpClient.get<LikeListData>(API.LIKE_LIST, {
      params: { 'offset': offset, 'type': product_type, 'type_id': product_id }
    });
  }

  /**
   * get Referal Data
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReferalData(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.CONNECT_SERVICE_REGISTRATION_GET, {
      params: { 'rquest': 'referal_content', iso_code: '91', version: '2.1' },
    });
  }

  /**
   * get Referal Point List Data
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReferalPointListData(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.CONNECT_SERVICE_REGISTRATION_GET, {
      params: {
        'rquest': 'referal_point_list',
        iso_code: '91',
        version: '2.1',
      }
    });
  }

  /**
   *  get referal point history
   */
  getReferalPointHistory(): Observable<UserReferralPointData> {
    return this.httpClient.get<UserReferralPointData>(API.REFERRAL_USER_POINT);
  }

  /**
   *  get Channel Tnc
   */
  getChannelTnc(safeUrl): Observable<ChannelTncData> {
    const params = { safeurl: safeUrl, };
    const headers = {
      Authorization: environment.appKey,
      userauthkey: this.token,
      lang: this.lang,
      ver: environment.versions.api_version,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
    };
    return this.httpClient.get<ChannelTncData>(`${environment.API_URLS.pharma}${API.PHARMA_CHANNEL_TNC_v2}`, {
      headers,
      params: params
    });
  }

  /**
   * Get missing data api.
   */
  getMissingData(productType, productId): Observable<ProductMissingData> {
    const params = { product_type: productType, product_id: productId, };

    return this.httpClient.get<ProductMissingData>(API.FORM_PRODUCT_MISSING_DATA, {
      params: params
    });
  }

  /**
   * EDetailList API.
   */
  getEDetailList(offset): Observable<EdetailingPharmaListData> {
    const params = { offset: offset };

    return this.httpClient.get<EdetailingPharmaListData>(API.E_DETAILING_PHARMA_LIST, {
      params: params
    });
  }

  /**
   * Therapeutic List API.
   */
  getTherapeuticList(id): Observable<EdetailingTherapeuticListData> {
    const params = { offset: '1', ed_phrm_id: id };

    return this.httpClient.get<EdetailingTherapeuticListData>(API.E_DETAILING_THERAPEUTIC_LIST, {
      params: params
    });
  }

  /**
   * Therapeutic List API.
   */
  getTherapeuticTnC(id): Observable<EdetailingPharmaTncData> {
    const params = { ed_phrm_id: id };

    return this.httpClient.get<EdetailingPharmaTncData>(API.E_DETAILING_PHARMA_TNC, {
      params: params
    });
  }

  /**
   * Used to get terms and conditions and update policy data.
   */
  getTermsPolicyUpdate(offset: number): Observable<BackgroundActiveData> {
    const params = { offset: offset.toString(), tooltip_type: 'static' };
    return this.httpClient.get<BackgroundActiveData>(API.BACKGROUND_ACTIVE, {
      params: params
    });
  }


  /**
   * EDetailList API.
   */
  getEDetailPharma(offset, ed_phrm_id): Observable<EdetailingPharmaDetailData> {
    const params = { offset: offset, ed_phrm_id: ed_phrm_id };

    return this.httpClient.get<EdetailingPharmaDetailData>(API.E_DETAILING_PHARMA_DETAIL, {
      params: params
    });
  }

  /**
   * EDetailList view all API.
   */
  getEDetailViewAllChannel(offset, ed_phrm_id, etpm_id, is_subscribed): Observable<EdetailingTherapeuticChannelData> {
    const params = {
      offset: offset, ed_phrm_id: ed_phrm_id, filter: JSON.stringify([{ 'key': 'therapeutic', 'value': [etpm_id] }]),
      is_subscribed: is_subscribed
    };

    return this.httpClient.get<EdetailingTherapeuticChannelData>(API.E_DETAILING_THERAPEUTIC_CHANNEL_LIST, {
      params: params
    });
  }

  /**
   * EDetailList view all API.
   */
  getEDetailViewAllDrug(offset, ed_phrm_id, filter): Observable<EdetailingTherapeuticDrugListData> {
    const params = { offset: offset, ed_phrm_id: ed_phrm_id, filter: JSON.stringify([{ 'key': 'therapeutic', 'value': [filter] }]) };

    return this.httpClient.get<EdetailingTherapeuticDrugListData>(API.E_DETAILING_THERAPEUTIC_DRUG_DETAIL, {
      params: params
    });
  }

  /**
   * Drug tnc API.
   */
  getDrugTnC(url): Observable<EdetailingPharmaTncData> {
    const params = { ed_phrm_id: url };

    return this.httpClient.get<EdetailingPharmaTncData>(API.E_DETAILING_PHARMA_TNC, {
      params: params
    });
  }

  /**
   * Bypass the interceptors.
   */
  createNewHttpClient(): HttpClient {
    return new HttpClient(this.handler);
  }

  /**
   * Get Filter List.
   */
  getFilterList(pharmaId, product_type, is_docrx): Observable<FilterListData> {
    const httpClient = this.createNewHttpClient();
    const headers = {
      Authorization: environment.appKey,
      userauthkey: this.token,
      lang: this.lang,
      ver: environment.versions.api_version,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      releaseVersion: environment.versions.GR_RELEASE_VERSION
    };
    const params = { et_phrm_id: pharmaId, product_type: product_type, is_docrx: is_docrx };
    return this.httpClient.get<FilterListData>(API.FILTER_LIST_V2, {
      headers,
      params: params
    });
  }

  /**
   * Get Calender List.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNewCalenderList(startDate, endDate, productType, onwards, offset): Observable<any> {
    const params = { startDate: startDate, endDate: endDate, productType: productType, onwards: onwards, offset: offset };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.MR_CALENDER_LIST, {
      params: params
    });
  }

  /**
   * Used to reschedule a meeting
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMeetingReschedule(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.MR_MEETING_RESCHEDULE);
  }

  /**
   * Get Calender List Color Codes.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCalenderColorCodes(Date): Observable<any> {
    const params = { date: Date };

    return this.httpClient.get(API.MR_CALENDER_DOTS, {
      params: params
    });
  }

  /**
   * Get meeting detail.
   */
  getMeetingDetail(meetingId): Observable<MRMeetingDetailData> {
    const params = { meetingId: meetingId };

    return this.httpClient.get<MRMeetingDetailData>(API.MR_MEETING_DETAILS, {
      params: params
    });
  }

  /**
   * Chatboat get chat history list.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getChatHistoryList(productType, ProductId, offset): Observable<any> {
    const params = { productType: productType, productId: ProductId, offset: offset };

    return this.httpClient.get(API.CHATBOT_HISTORY, {
      params: params
    });
  }

  /**
   * Get new drugn detail.
   */
  getNewDrugDetail(drugId, etpmId): Observable<EdetailingDrugDetailData> {
    const params = { drug_id: drugId, etpm_id: etpmId };

    return this.httpClient.get<EdetailingDrugDetailData>(API.E_DETAILING_DRUG_DETAILS, {
      params: params
    });
  }

  /**
   * Get meeting group chat.
   */
  getMeetingGroupChat(meetingId, offset): Observable<MRCommentGetData> {
    const params = { type: PRODUCT_TYPE_CONSTANT.GROUPCHAT, type_id: meetingId, offset: offset };
    return this.httpClient.get<MRCommentGetData>(API.MR_COMMENT_GET, {
      params: params
    });
  }

  /**
   * Get event list api.
   */
  getNewEventList(eventType, offSet): Observable<EventListData> {
    const params = { list_type: eventType, offset: offSet };

    return this.httpClient.get<EventListData>(API.EVENT_LIST_V3, {
      params: params
    });
  }

  /**
   * Get event detail api.
   */
  getEventDetails(id): Observable<EventDetailData> {
    const params = { event_id: id };

    return this.httpClient.get<EventDetailData>(API.EVENT_DETAILS_V3, {
      params: params
    });
  }

  /**
   * Get session details api.
   */
  getSessionDetails(id): Observable<EventSessionDetailData> {
    const params = { session_id: id };

    return this.httpClient.get<EventSessionDetailData>(API.SESSION_DETAILS_V3, {
      params: params
    });
  }

  /**
   * Get event detail api.
   */
  getEventActivityStats(product_type, product_id, event_type): Observable<EventUserActivityData> {
    const params = { product_type: product_type, product_id: product_id, event_type: event_type };

    return this.httpClient.get<EventUserActivityData>(API.USER_ACTIVITY_V3, {
      params: params
    });
  }

  /**
   * Get event certificate api.
   */
  getEventCertificate(product_id, product_type): Observable<EventCertificateDetailData> {
    const params = { product_id: product_id, product_type: product_type };

    return this.httpClient.get<EventCertificateDetailData>
      (API.CERTIFICATE_DETAIL_V3, {
        params: params
      });
  }

  /**
   * Get speakers list from api.
   */
  getSpeakersList(productType, productId): Observable<SpeakersListData> {
    const params = { product_type: productType, product_id: productId };

    return this.httpClient.get<SpeakersListData>(API.SPEAKERS_LIST_V3, {
      params: params
    });
  }

  /**
   * Get data from url.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDataFromUrl(url): Observable<any> {
    const headers = {
      Authorization: environment.appKey,
      userauthkey: this.token,
      lang: this.lang,
      ver: environment.versions.api_version_updated,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType
    };

    return this.httpClient.get(url, {
      headers,
      observe: 'response',
      responseType: 'text'
    });
  }

  /**
   * Get data for suggestions to be displayed on search field popup.
   */
  getSearchSuggestion(): Observable<SearchSuggestionData> {
    // const endpoint = this.getMockData('search/get-suggestions.json');
    return this.httpClient.get<SearchSuggestionData>(API.SEARCH_GET_SUGGESTIONS);
  }

  /**
   * Used to get information of the profile which is going to be deleted.
   */
  deleteProfileInfo(profileInfoType: string): Observable<DeleteProfileResponse> {
    const params = new HttpParams().set('type', profileInfoType);
    return this.httpClient.get<DeleteProfileResponse>(API.USER_DELETION_LIST, {
      params: params
    });
  }

  /**
  * Get data for Editor-choice detail page.
  */
  getEditorChoiceDetails(id: number): Observable<any> {
    const params = new HttpParams().set('ec_id', id.toString());
    return this.httpClient.get<EcDetail>(API.EDITOR_CHOICE_DETAILS, {
      params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * get Event Schedule
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEventSchedule(parameters: any): Observable<ScheduleListData> {
    return this.httpClient.get<ScheduleListData>(API.EVENT_SCHEDULE, {
      params: parameters
    });
  }

  /**
   * get Schedule Session Products
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getScheduleSessionProducts(parameters: any): Observable<SessionMappedProductData> {
    return this.httpClient.get<SessionMappedProductData>(API.SESSION_MAPPED_PRODUCT_V3, {
      params: parameters
    });
  }

  /**
   * Used to get the webinar status from the api.
   */
  webinarStatus(parameters): Observable<WebinarStatusResponseData> {
    return this.httpClient.get<WebinarStatusResponseData>(API.WEBINAR_STATUS, {
      params: parameters
    });
  }

  /**
   * Used to get game detail.
   */
  gameDetails(id: number): Observable<GameDetailResponse> {
    const params = new HttpParams().set('id', id.toString());
    // return this.httpClient.get('./assets/mock/ncp-product/ncp-product.json');
    return this.httpClient.get<GameDetailResponse>(API.NCP_SUBTYPE_DETAILS, {
      params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Used to get leader board on game detail.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLeaderBoard(offset, id: number): Observable<any> {
    const params = { offset: offset, 'game_id': id.toString() };
    // return this.httpClient.get('./assets/mock/game/leaderboard.json');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.GAME_LEADER_BOARD, {
      params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Used to get the community list from the api.
   */
  getCommunityList(type: string, offset: string, additionalParam?, repostParam?): Observable<CommunityListResponse> {
    let params;
    if (additionalParam?.product_id) {
      params = { type, offset, product_type: additionalParam?.product_type, product_id: additionalParam?.product_id };
    } else if (repostParam?.repost_product_type) {
      params = { type, offset, repost_product_type: repostParam?.repost_product_type, repost_product_id: repostParam?.repost_product_id };
    }
    else {
      params = { type, offset };
    }
    return this.httpClient.get<CommunityListResponse>(API.COMMUNITY, {
      params
    });
  }

  /*
   * Get the dynamic form data response.
   */
  getDynamicFormData(product_type: string, product_id: string, form_type: string, only_labels: string): Observable<DynamicFormResponse> {
    const params = {
      product_type,
      form_type,
      product_id,
      only_labels
    };
    return this.httpClient.get<DynamicFormResponse>(API.FORM_CONTROL_DETAIL, {
      params
    });
  }
  /**
   * Used to get the community detail from the api.
   */
  getCommunityDetail(communityId: string): Observable<CommunityDetailResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
      version: environment.versions.api_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}/${communityId}`;
    return this.httpClient.get<CommunityDetailResponse>(endpoint, {
      headers
    });
  }

  /**
   * used to get continue watch list data
   */
  getContinueWatchData(hcf_id, offset): Observable<ContinueWatchViewALLResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
      version: environment.versions.api_version_updated
    };
    const params = { hcf_id, offset };
    const endpoint = `${environment.API_URLS.others}${API.HCF_CONTINUE_WATCH_VIEW_ALL}`;
    return this.httpClient.get<ContinueWatchViewALLResponse>(endpoint,
      { headers, params });
  }

  /**
   * Used to get Posted by Filter Data from the api.
   */
  getPostedByFilterData(product_type: string, section_name: string): Observable<FilterListData> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version,
      version: environment.versions.api_version
    };
    const params = { product_type, section_name };
    return this.httpClient.get<FilterListData>(API.FILTER_LIST_V2, {
      headers,
      params
    });
  }

  /**
   * Used to get Members Tyoe Filter Data from the api.
  */
  getMembersFilterData(product_type: string, section_name: string): Observable<FilterListData> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version,
      version: environment.versions.api_version
    };
    const params = { product_type, section_name };
    return this.httpClient.get<FilterListData>(API.FILTER_LIST_V2, {
      headers,
      params
    });
  }

  // TODO : add typing
  /**
   * Used to get the pending member request from the api.
   */
  getPendingCommunityMembers(communityId: string, offset?, connection_type?): Observable<CommunityMembersResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_version_updated,
      version: environment.versions.api_version_updated
    };
    const params = { offset, connection_type };
    const endpoint = `${API.COMMUNITY_DETAIL}/${communityId}/members`;
    return this.httpClient.get<CommunityMembersResponse>(endpoint, {
      headers,
      params
    });
  }



  /**
   * Used to get Posted by Filter Data from the api.
   */
  getPostDataByFilter(posted_by: string, content_type: string, community_id: string, offset: string): Observable<CommunityPostedByResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.apiVer49,
      version: environment.versions.apiVer51
    };
    const params = { posted_by, content_type, offset };
    const endpoint = `${API.COMMUNITY_DETAIL}/${community_id}/posts`;
    return this.httpClient.get<CommunityPostedByResponse>(endpoint, {
      headers,
      params
    });
  }

  /**
   * In case if we need mock data, invoke this method.
   */
  getMockData(url: string): string {
    return '../../assets/mock/' + url;
  }

  /**
   * NCP detail page
   */
  getNcpDetail(ncpId: number, offset: number): Observable<NCPDetailResponse> {
    const params = { ncp_id: ncpId.toString(), offset: offset.toString() };
    //  return this.httpClient.get('../../assets/mock/game/ncp-detail.json');
    return this.httpClient.get<NCPDetailResponse>(API.GAME_NCP_DETAIL, {
      params: params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
 * Survey detail page
 */
  getSurveyDetail(safeurl): Observable<SurveyApiResponse> {
    const params = { safeurl: safeurl };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.SURVEY_DETAIL, {
      params: params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Get the text to speech details.
   */
  getTextToSpeech(product_type: string, product_id: string): Observable<TextToSpeechResponse> {
    const params = { product_type, product_id };
    return this.httpClient.get<TextToSpeechResponse>(API.TEXT_TO_SPEECH, {
      params,
      withCredentials: true
    });
  }

  /**
   * Historical poll list api
   */
  getHistoricalPollList(productType, productId): Observable<any> {
    const params = { 'product_type': productType, 'product_id': productId };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.HISTORICAL_POLL_LIST, {
      params: params
    });
  }

  /**
   * Get default content on create post.
   */
  repostdefaultContent(productType, productId): Observable<DefaultContentResponse> {
    const params = { product_type: productType, product_id: productId };
    return this.httpClient.get<DefaultContentResponse>(API.REPOST_DEFAULT_CONTENT, {
      params: params
    });
  }

  /**
   * Search User Tags
   */
  searchUserTags(product_type, product_id, terms: Observable<string>, offset): Observable<any> {
    return terms.pipe(debounceTime(300),
      switchMap(term => this.searchUserTagEntries(product_type, product_id, term, offset)));
  }

    /**
     * Search Entries
     */
    searchUserTagEntries(product_type,product_id,keyword,offset): Observable<any> {
      const params = {product_type, product_id, keyword, offset };
      return this.httpClient.get<any>(API.TAG_USER_SEARCH, {
        params: params
      });
    }

    // user story api
    // TODO: Rahul: To do datatyping
    /**
     * GET user story list
     */
    getUserStoryList(version = '1',offset): Observable<any> {
      const params = {version, offset };
      return this.httpClient.get<any>(API.USER_STORY_CAROUSEL_LIST, {
        params: params
      }
      ).pipe(
        map(response => {
          if(response?.mc) {
            return response;
          }
          else {
            return {status: 204};
          }
        }),
        catchError(error => {
          return of(null);
        })
      );
    }
    /**
     * get detail of stories
     */
    getUserStoryDetail(version = '1', userId, userType?: string): Observable<any> {
      const endpoint = API.STORY_DETAIL + '/' + userId;
      let params = {};
      if (userType != '' && userType != undefined) {
        params = {userType,version};
      }
      else {
        params = {version};
      }
      return this.httpClient.get<any>(endpoint, {
        params: params
      }).pipe(
        map(response => response),
        catchError(error => {
          return of(null);
        })
      );
    }

    /**
     * get default content for stories
     */
    getDefaultContentStories(entityType, entityCode): Observable<any> {
      let params;
      if (entityType && entityCode) {
         params = {entityType, entityCode};
      }
      else if (entityType && !entityCode) {
        params = {entityType};
      }
      else if (entityCode && !entityType) {
        params = {entityCode};
      }
      return this.httpClient.get<any>(API.STORY_DEFAULT_CONTENT, {
        params: params
      });
    }
    /**
     * GET user story list
     */
    getStoryUserSearchList(keyword, offset): Observable<any> {
      const params = {'keyword': keyword, 'offset': offset};
      if (!offset) {
        params['offset'] = 1;
      }
      return this.httpClient.get<any>(API.STORY_USER_SEARCH_LIST, {
        params: params
      }
      ).pipe(
        map(response => {
          if(response?.mc) {
            return response;
          }
          else {
            return {status: 204};
          }
        }),
        catchError(error => {
          return of(null);
        })
      );
    }

    /**
     * get template list
     */
    getTemplateList(templateCode): Observable<any> {
      const endpoint = API.TEMPLATE_LISTING + '/' + templateCode;
      return this.httpClient.get<any>(endpoint, {
      }
      ).pipe(
        map(response => {
          if(response?.mc) {
            return response;
          }
          else {
            return {status: 204};
          }
        }),
        catchError(error => {
          return of(null);
        })
      );
    }
    getViewListForStories(storyCode, offset): Observable<any> {
      const endpoint = API.VIEW_LIST + '/' + storyCode;
      const params = {offset};
      return this.httpClient.get<any>(endpoint, { params: params}
      ).pipe(
        map(response => {
          if(response?.mc) {
            return response;
          }
          else {
            return {status: 204};
          }
        }),
        catchError(error => {
          return of(null);
        })
      );
    }

    chatInitiate(): Observable<any> {
      const headers = {
          ver: "4.3"
      }
      return this.httpClient.get(API.CHAT_INITIATE, {
          headers
      }).pipe(
          retry(3),
          map(response => response),
          catchError(error => {
              return of(error);
          })

      );
  }

  /**
   * profile 2.0 apis
   */
  /**
   * 
   * @param endPoint get seatch list suggestions
   * @returns 
   */
  getSearchDataForDropDown(endPoint: string): Observable<any> {
    return this.httpClient.get<any>(endPoint
      ).pipe(map(response => {
        if(response?.mc) {
          return response;
        }
        else {
          return {status: 204};
        }
      }),
      catchError(error => {
        return of(null);
      })
    );
  }
  /**
   * 
   * @param event get nudge fields
   * @returns 
   */
  getProfileNudgeData(event): Observable<any> {
    const endPoint = API.GET_PROFILE_NUDGE_DATA + event;
    return this.httpClient.get<any>(endPoint
      ).pipe(map(response => {
        if(response?.mc) {
          return response;
        }
        else {
          return {status: 204};
        }
      }),
      catchError(error => {
        return of(null);
      })
    );
  }

  /**
   * Forword-flows api
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getForwordFlowsList(productType, productId): Observable<any> {
    const params = { product_type: productType, product_id: productId, suggestions_flag: NUMBERS.ONE.toString() };
    // return = this.getMockData('forword-flows-suggestion/suggestion-list.json');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.FORWORD_FLOWS_SUGGESTION, {
      params: params
    });

  }
  /**
   * promo view strip
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPromoViewStrip(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.PROMO_VIEW_STRIP);
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${environment.deviceType}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  /**
   * Get json data for feature on/off.
   */
  public getFeatureOnOffJSON(): Observable<any> {
    return this.httpClient.get(environment.azFeatureOnOffData);
}

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    this.dss.notificationAdditionalData = {};
  }
}
