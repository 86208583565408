<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div class="position-relative px-3 px-sm-4 py-sm-5 pb-3 pt-5">
      <a (click)="back_screen()" class="back-page position-absolute"><img src="assets/themes/icons/back-arrow.svg"
          width="20px" /></a>
      <span (click)="skipuserDetail()"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.userdetail_screen.category,"action":mls.googleEventText.userdetail_screen.key1,"product_type":"", "product_id":""}'
        class="position-absolute cursor-pointer skip-onboarding"
        *ngIf="is_skippable==1">{{mls?.languageText?.userdetail_screen?.key1}}<span
          *ngIf="mls?.is_lskey_display==1">(userdetail_screen.key1)</span></span>
      <h5 class="mb-1 heading-4">{{mls?.languageText?.userdetail_screen?.key2}}<span
          *ngIf="mls?.is_lskey_display==1">(userdetail_screen.key2)</span></h5>
      <!-- form -->
      <div class="my-3 row">
        <div class="col-12 mb-3" *ngFor="let arrData of controlsdata; let idx=index">
          <div *ngIf="arrData?.hide==0" class="form-input" [ngClass]="{ 'error': arrData?.show_err_msg==1 }">
            <label class="input-label">
              {{arrData?.label_value}}
              <span *ngIf="arrData?.is_optional!=1" class="help-block">*</span>
              <span *ngIf="arrData?.show_err_msg==1" class="float-right help-block">
                {{arrData?.err_msg}}
              </span>
            </label>
            <input type="text" [maxLength]="Dss?.inputBoxMaxLimit" (keypress)="numberInput($event)"
              (keyup)="userInput($event,idx)" class="input-field" placeholder="{{arrData?.place_holder}}" value="{{arrData?.value}}" />
          </div>
        </div>
      </div>
      <button class="btn-design w-100" [disabled]="showColor==false" [ngClass]=" [showColor ? 'filled' : 'filled-disabled',fcs.device_detail.is_mobile ? 'medium' : 'large']"
        type="button" (click)="userRegister()"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.userdetail_screen.category,"action":mls.googleEventText.userdetail_screen.key2,"product_type":"", "product_id":""}'>
        <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{mls?.languageText?.userdetail_screen?.key3}}
          <span *ngIf="mls?.is_lskey_display==1">(userdetail_screen.key3)</span>
      </button>
    </div>
  </div>
</div>

<app-backscreenmodal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event) [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-backscreenmodal>

