/**
 * Angular imports.
 */
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { GetService } from '../../dataservices/get.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { ToastrService } from 'ngx-toastr';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
/**
 * interface imports
 */
import { ToastrType } from '../../onboarding/interface/global.model';

/**
 * Product Report Component
 */
@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss']
})
export class ProductReportComponent implements OnInit, OnDestroy {
  @Input() product_type;
  @Input() callingfrom;
  @Input() product_type_id;
  @Input() uuid;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reasonList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public message: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public type: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reason_id: any;
  public api_in_process = false;

  /**
   * Emit the report submit event.
   */
  @Output() emitReportSubmit: EventEmitter<void> = new EventEmitter();

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(public multiLangService: MultiLangService, public getApi: GetService, private jwtSer: JwtDecodeService, public fcs: FunCollectionService, public postApi: PostApiService, public route: ActivatedRoute, private toastr: ToastrService, public mls: NewLanguageService) {

    this.subscriptions.add(this.route.params.subscribe(params => {
      this.type = params['type'];
    }));
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.feedbackReason();
  }

  /**
   * feed back Reason
   */
  feedbackReason(): void {
    this.subscriptions.add(this.getApi.getFeedbackReason(this.product_type).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data.status == 1) {

          this.reasonList = data.data.reason_list;
          if (this.reasonList.length > 0) {
            this.reason_id = this.reasonList[0].id;
          }

        } else if (data.status == 0) {
          this.fcs.errorHandeler(data);
        }
      });
    }));
  }

  /**
   * onChange Reasion
   */
  onChangeReasion(e): void {
    this.reason_id = e;
  }

  /**
   * send Feedback
   */
  sendFeedback(): void {
    this.api_in_process = true;
    this.subscriptions.add(this.postApi.postSenfFeedback(this.product_type, this.product_type_id, this.reason_id, this.message, this.uuid).subscribe(resData => {
      this.api_in_process = false;
      this.jwtSer.decodeData(resData).then(data => {
        this.fcs.closeWebModal();
        if (data['status'] == 1) {
          this.message = '';
          this.showSuccess(data['msg'], this.mls?.languageText?.report_screen?.key7);
          this.emitReportSubmit.emit();
        } else if (data['status'] == 0) {
          if (data.error['code'] == 1005) {
            this.message = '';
          }
          this.emitReportSubmit.emit();
          this.fcs.errorHandeler(data);
        }
      });
    }));
  }

  /**
   * success function msg
   */
  showSuccess(msg, msg_head): void {
    this.fcs.showCustomToast({
      toastType: ToastrType.SUCCESS,
      timeout: 10000,
      message: msg,
      title: msg_head
    });

  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
