/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/**
 * Component imports.
 */
import { PublicModuleComponent } from './public-module.component';
import { PublicArticleComponent } from './public-article/public-article.component';
import { PublicListingComponent } from './public-listing/public-listing.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { PublicWebinarDetailComponent } from './public-webinar-detail/public-webinar-detail.component';
import { PublicDoctalkDetailComponent } from './public-doctalk-detail/public-doctalk-detail.component';
import { PublicDtSeriesComponent } from './public-dt-series/public-dt-series.component';

/**
 * Guards import.
 */
import { OpenAccessCheckPassGuard } from '../auth/open-access-check-pass.guard';

const publicRoutes: Routes = [
  {
    path: 'open-access', component:PublicModuleComponent , canActivate: [OpenAccessCheckPassGuard],
    children: [
      {
        path: 'public-article/:id',
        component: PublicArticleComponent
      },
      {
        path: 'public-webinar/:id',
        component: PublicWebinarDetailComponent
      },
      {
        path: 'public-doctalk/:id',
        component: PublicDoctalkDetailComponent
      },
      {
        path: 'public-dt-series/:id',
        component: PublicDtSeriesComponent
      },
      {
        path: 'public-product-list/:pType',
        component: PublicListingComponent
      },
      {
        path: 'public-product-list',
        component: ProductListingComponent
      },

      { path: '', redirectTo: 'public-product-list', pathMatch: 'full' },


    ]

  }
];

@NgModule({
  imports: [
    RouterModule.forChild(publicRoutes),

  ],
  exports: [RouterModule],

})
export class PublicRoutingModule { }
