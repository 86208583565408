import { Component, Input, AfterViewInit, OnChanges } from '@angular/core';

/**
 * Service Import.
 */
import { FunCollectionService } from '../../common-functions/fun-collection.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';

/**
 * This component is used for product comments only to be display.
 */
@Component({
  selector: 'app-product-comment-content-card',
  templateUrl: './product-comment-content-card.component.html',
  styleUrls: ['./product-comment-content-card.component.scss']
})
export class ProductCommentContentCardComponent implements AfterViewInit, OnChanges {
  @Input() cmt: string;
  @Input() showChar: number;

  /**
   * Comment data.
   */
  @Input() commentObject?;
  /**
   * Scerren name.
   */
  @Input() screenName?;

  constructor(private fcs: FunCollectionService, private dss: DataStorageService) { }


  ngAfterViewInit(): void {
    const tagUser = document.querySelectorAll('.tag-user');
    tagUser.forEach(span =>
      span.addEventListener('click', this.onClick.bind(this), false));
  }

  onClick(e): void {
    const customId = e.target.className.split('tag-user__')[1];
    /**
     * Logic for analytics on taguser comment.
     */
    if (customId === this.commentObject.comment.split(':')[0].split('{')[1]) {
      this.dss.tagUserAnalytics.commentId = this.commentObject?.comment_id;
    }
    this.dss.tagUserAnalytics.screenName = this.screenName;
    this.dss.tagUserAnalyticStatus = true;


    this.fcs.navigateToUserProfie(customId);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      const tagUser = document.querySelectorAll('.tag-user');
      tagUser.forEach(span =>
        span.addEventListener('click', this.onClick.bind(this), false));
    }, 1000);
  }

}


