export const SURVEY_REWARD_TAB_TYPE = {
  PENDING: 'pending',
  REDEEMED: 'redeemed',
  EXPIRED: 'expired'
};


export const SURVEY_PARENT_COMP = {
  SURVEY_LIST : 'survey-list',
  SURVEY_REWARDS_LIST : 'survey-rewards-list'
}

export const SURVEY_ACCESS_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  PIF: 'PIF',
  PFF: 'PFF',
}