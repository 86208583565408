/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Helper imports.
 */
import { MatchUrlsHelper } from '../helper/matchUrls.helper';

/**
 * Used to set the Version headers for apis.
 */
@Injectable()
export class VersionHeadersInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = MatchUrlsHelper.matchHeaderUrls(request);
    const authReq = request.clone({
      headers: headers
    });

    return next.handle(authReq);
  }
}
