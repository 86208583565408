/**
 * Angular import.
 */
import { Injectable } from '@angular/core';

/**
 * Interface import.
 */
import { AnalyticsInterface, EventDataKey } from './analytics.model';
/**
 * Environment import.
 */
import { environment } from '../../environments/environment';
/**
 * Service import.
 */
import { DocquityAnalyticsService } from '../dataservices/docquity-analytics/docquity-analytics.service';
import { JwtDecodeService } from '../dataservices/jwt-decode.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';
import { DataStorageService } from '../dataservices/data-storage.service';
import { MobileWebViewCommunicationService } from '../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * Constant import.
 */
import { NUMBERS } from '../constants/app.constants';
/**
 * Library imports.
 */
import * as moment from 'moment-timezone';
/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';
/**
 * Analytics service for GA, CT and DQ.
 */
@Injectable({
  providedIn: 'root'
})
/**
 * Analytics service.
 */
export class AnalyticsEventService {

  /**
   * Unique id for analytics.
   */
  private uniqueId: string;
  /**
   * Analytics session id.
   */
  private session_id: string;
  /**
   * Seconday sepcility.
   */
  private secondarySpeciality = [];
  /**
   * Analytics screen name.
   */
  readonly analyticsScreenName = [
    'FEED_POSTING',
    'GRAND_ROUND',
    'POST_ASSOCIATION',
    'POST_TYPE',
    'TAG_PEOPLE',
    'EC_DETAIL',
    'EDITOR_CHOICE_CARD',
    'NCP_SUBTYPE_LISTING_PAGE',
    'NCP_SUBTYPE_DETAIL_PAGE',
    'NCP_HORIZONTAL_CAROUSEL',
    'NCP_SUBTYPE_WEBVIEW',
    'EDITOR_CHOICE_LIST',
    'COMMUNITY_INPUT_DETAILS_PAGE',
    'COMMUNITY_DASHBOARD',
    'CONNECTIONS_SUGGESTIONS_LIST',
    'NOTIFICATION_LIST',
    'EXPLORE_LIST',
    'COMMUNITY_DETAILS',
    'MY_COMMUNITIES_LIST',
    'PAST_INACTIVE_COMMUNITIES_LIST',
    'PUBLIC_COMMUNITIES_LIST',
    'WEBINAR_DETAIL',
    'PIP_MODE',
    'JOURNAL_DETAIL',
    'HORIZONTAL_CAROUSEL_SCREEN',
    'CONTINUE_WATCH_VIEWALL',
    'NCP_SUBTYPE_DETAIL_PAGE',
    'GR_PUBLIC_CAROUSEL',
    'COMMUNITY_EDIT_PAGE',
    'FEED_DETAIL',
    'DOCTALKS_DETAIL',
    'SESSION_DETAIL',
    'EVENT_DETAIL',
    'BOOKMARK_LIST',
    'CHANNEL_HOME',
    'CME_CERTIFICATE',
    'PROFILE_VISIT',
    'WEBINAR_LIST',
    'CHANNEL_DETAIL',
    'WEBINAR_LIST_VIEW_ALL',
    'GLOBAL_SEARCH',
    'EXPANDED_CHAT_WINDOW',
    'MINIMIZED_CHAT_WINDOW',
    'EXPANDED_CHATS_SEARCH_DOCTORS',
    'MINIMIZED_CHATS_SEARCH_DOCTORS',
    'MINIMIZED_CHAT_DETAIL',
    'EXPANDED_GROUP_CREATION_MEMBERS',
    'MINIMIZED_GROUP_MEMBERS',
    'EXPANDED_GROUP_CREATION_DETAILS',
    'MINIMIZED_GROUP_CREATION_DETAILS',
    'TOP_CHAT_ICON',
    'CHAT_BAR_BOTTOM',
    'EXPANDED_CHAT_DETAIL',
    'CHAT_DETAIL',
    'GROUP_INFO',
    'MINIMIZED_GROUP_INFO',
    'EXPANDED_GROUP_INFO',
    'CHAT_WINDOW',
    'CHATS_SEARCH_DOCTORS',
    'GROUP_MEMBERS',
    'SAMVAAD_MAINTENANCE_POPUP',
    'STORY_CREATION',
    'STORY_UPLOADING',
    'STORY_PLAY',
    'SURVEY_QUESTION',
    'SURVEY_DETAIL',
    'ALL_SURVEY_LISTING_SCREEN',
    'DRAFTS_SURVEY_LISTING_SCREEN',
    'SURVEY_COMPLETE',
    'THANK_YOU_SCREEN',
    'COMPLETED_QUESTIONNAIRE_SCREEN',
    'RESIDENTIAL_ADDRESS_VERIFICATION_POPUP',
    'EDUCATION_VERIFICATION_POPUP',
    'EXPERIENCE_VERIFICATION_POPUP',
    'PRACTICE_DETAILS_VERIFICATION_POPUP',
    'MEDICAL_AWARDS_VERIFICATION_POPUP',
    'CIVIL_AWARDS_VERIFICATION_POPUP',
    'CERTIFICATIONS_VERIFICATION_POPUP',
    'VOLUNTARY_SERVICE_VERIFICATION_POPUP',
    'PATENT_VERIFICATION_POPUP',
    'PAPER_PRESENTATION_VERIFICATION_POPUP',
    'THESIS_VERIFICATION_POPUP',
    'UNPUBLISHED_WORK_VERIFICATION_POPUP',
    'POSITION_OF_RESPONSIBILITY_VERIFICATION_POPUP',
    'WORKSHOP_VERIFICATION_POPUP',
    'SEMINAR_VERIFICATION_POPUP',
    'WEBINAR_VERIFICATION_POPUP',
    'CONFERENCE_VERIFICATION_POPUP',
    'TRAININGS_RECEIVED_VERIFICATION_POPUP',
    'TRAININGS_IMPARTED_VERIFICATION_POPUP',
    'BLOG_VERIFICATION_POPUP',
    'CONSULTATION_DETAILS_VERIFICATION_POPUP',
    'CERTIFICATIONS_DETAILS_VERIFICATION_POPUP',
    'PUBLICATION_VERIFICATION_POPUP',
    'CME_COURSE_DETAIL'
  ];
  /**
   * Calling clevertap configuration.
   */
  constructor(private docquityabalytics: DocquityAnalyticsService,
    private jwtSer: JwtDecodeService,
    private fcs: FunCollectionService,
    private dss: DataStorageService,
    private analyticSdk: Analytics,
    private mvc: MobileWebViewCommunicationService) {
      this.analyticSdk.analyticService.onEvent('clevarTapPush', (data) => {
        this.dss.cleverTapInstance.event.push(data.eventName, data.eventData
        );
    });
    this.clevertapConfig();
  }

  /**
   * Clevertap configuration.
   */
  clevertapConfig(): void {
    this.dss.cleverTapInstance.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    this.dss.cleverTapInstance.privacy.push({ useIP: false });  // Set the flag to true, if the user agrees to share their IP data
    this.dss.cleverTapInstance.init(environment.clevertapConfigKey);
  }

  /**
   * Clevertap set profile.
   */
  logClevertapProfileEvent(profileData): void {
    this.secondarySpeciality = [];
    if (profileData?.speciality.length > NUMBERS.ONE) {
      profileData.speciality.map(data => {
        this.secondarySpeciality.push(data.speciality_name);
      });
      this.secondarySpeciality.shift();
    }
    if (profileData) {
      this.dss.cleverTapInstance.onUserLogin.push({
        'Site': {
          Name: profileData.profile.registered_name,
          Identity: profileData.profile.track_id,
          Email: profileData.profile.email,
          // Phone: `+${profileData.profile.country_code}${profileData.profile.mobile}`,
          Permissions: profileData.profile.permission,
          'Primary Specialty': profileData.speciality[0]?.speciality_name,
          'Primary Specialty Id': profileData.speciality[0]?.speciality_id,
          secondarySpeciality: this.secondarySpeciality.length === NUMBERS.ZERO ? '' : JSON.stringify(this.secondarySpeciality),
          Association: profileData.profile.association,
          Country: profileData.profile.country,
          User_type: profileData.profile.user_type
        }
      });
    }
  }

  /**
   * Log Analytics events on diffrent platform.
   */
  logAnalyticsEvent(eventData: AnalyticsInterface, isHxrCall = false , passScreenName = false): void {
    if ((this.checkScreenName(eventData) || (eventData.event_name.hasCL && passScreenName === true) )) {
      if (eventData.event_name.hasCL) {
      const analyticsCTData = {
        event_name: eventData.event_name.name,
        product_type: eventData.product_type,
        product_type_id: eventData.product_type_id,
        screen_name: eventData.screen_name,
        PropertyType: eventData.PropertyType,
        Specialty: eventData.Specialty,
        start_time: this.getCTTimeFormat(eventData.start_time),
        end_time: this.getCTTimeFormat(eventData.end_time),
        product_code: eventData.product_code
      };
      if(eventData.step_number){
        analyticsCTData['step_number'] = eventData.step_number;
      }
      if ( eventData && eventData['option_id'] !== '' && eventData['poll_id'] !== '') {
        analyticsCTData['option_id'] = eventData['option_id'];
        analyticsCTData['poll_id'] = eventData['poll_id'];
      }
      this.logClevertapEvent(analyticsCTData);
      }
      if (eventData.event_name.hasDQ) {
        this.setDocquityAnalytic(eventData, isHxrCall);
      }
      if (eventData.event_name.hasGA) {
        this.setGA(eventData);
      }
    }
  }

  /**
   * Clevertap set event.
   */
  logClevertapEvent(eventData): void {
    this.dss.cleverTapInstance.event.push(eventData.event_name,
      eventData
    );
  }

  /**
   * Docquity analytics function.
   */
  setDocquityAnalytic(eventData: AnalyticsInterface, isHxrCall = false): void {
    const timezone = this.fcs.getLocalTimezone();

    const location = { latitude: '', longitude: '', local: '', time_zone: timezone };

    this.uniqueId = this.fcs.makenumericid();

    if (sessionStorage.getItem('event_session_id')) {
      this.session_id = sessionStorage.getItem('event_session_id');
    } else {
      this.session_id = `web-${new Date().getTime()}${this.fcs.makenumericid()}`;
      sessionStorage.setItem('event_session_id', this.session_id);
    }
    const utmData = JSON.parse(localStorage.getItem('utmSourceData'))
    const eventdata: any = {
      id: this.uniqueId,
      event_name: eventData.event_name.name,
      product_type: eventData.product_type,
      product_type_id: eventData.product_type_id,
      session_id: this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.sessionId : sessionStorage.getItem('event_session_id'),
      local_id: '0',
      screen_name: eventData.screen_name,
      user_id: '',
      untrack_user_identifier: '',
      session_time: { 'start_time': eventData.start_time, 'end_time': eventData.end_time },
      location: location,
      track_id: this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.trackId : this.dss.user_profile_data.profile.track_id,
      product_code: eventData.product_code,
      utm: {
        source: utmData?.['UTM_Source'],
        campaign: utmData?.['UTM_Campaign'],
        medium:  utmData?.['UTM_Medium']
      }
    };
    if (!isHxrCall) {
      this.docquityabalytics.docquityAnalytics([eventdata]).subscribe(resData => {
        this.jwtSer.decodeData(resData).then(() => {
          // No Code
        });
      });
    } else {
      this.docquityabalytics.xhrCall([eventdata]);
    }

  }

  /**
   * GA analytics events.
   */
  setGA(eventData: AnalyticsInterface): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>window).ga('send', 'event', eventData.screen_name, eventData.event_name.name, {});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>window).gtag('event', eventData.screen_name, { 'event_category': eventData.event_name.name });
  }

  /**
   * Get CT time format.
   */
  getCTTimeFormat(eventTime): string {
    return moment(new Date(Number(eventTime))).format('DD/MM/YYYY, hh:mm:ss A');
  }
  /**
   * Check if screen name is there or not.
   */
  checkScreenName(eventData): boolean {
    return this.analyticsScreenName.indexOf(eventData.screen_name) > -1;
  }

  sendAnalytics(
    eventName: EventDataKey,
    productType: number,
    productTypeId: number,
    screenName: string,
    startTime = this.getTimeString(),
    endTime = this.getTimeString(),
    product_code?: string): void {
    const analyticsData = {
      'event_name': eventName,
      'product_type_id': productTypeId,
      'product_type': productType,
      'screen_name': screenName,
      'start_time': startTime,
      'end_time': endTime,
      'product_code': product_code
    };
    this.logAnalyticsEvent(analyticsData);
  }

  /**
    * Get the current time in string.
    */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

}
