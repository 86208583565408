import { Pipe, PipeTransform } from '@angular/core';

/**
 * This is used for showing new tag in explore section.
 */

@Pipe({
  name: 'newTags'
})
export class NewTagsPipe implements PipeTransform {

  transform(value: string, args: NewTags[]): unknown {
    let returnValue = 'none';
    if (args.length > 0) {
      const hasNewTag = args.find(obj => obj.identifier === value);
      if (hasNewTag !== undefined) {
        returnValue = 'inline-block';
      } else {
        returnValue = 'none';
      }
    }
    return returnValue;
  }
}

/**
 * This is only for new tags.
 */
export class NewTags {
  identifier: string;
  badge_text: string;
}
