/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';

/**
 * Component for displaying the success message after submitting a business enquiry.
 */
@Component({
  selector: 'app-business-enquiry-success',
  templateUrl: './business-enquiry-success.component.html',
  styleUrls: ['./business-enquiry-success.component.css']
})
export class BusinessEnquirySuccessComponent implements OnInit {

  /**
   * Constructor.
   */
  constructor() { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
  }

}