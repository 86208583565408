/**
 * Angular import.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/**
 * Directive import.
 */
import { AnalyticsEventDirective } from './analytics-event.directive';

/**
 * Module used for tracking analytics event.
 */
@NgModule({
  declarations: [
    AnalyticsEventDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AnalyticsEventDirective
  ]
})
export class AnalyticsEventModule { }
