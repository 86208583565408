<!--
<span ngbDropdown class="d-inline-block ">
        <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">
            <svg id="Layer_1" width="25px" class="ml-2" data-name="Layer 1" viewBox="0 0 24 24"><defs><style>.edit-card{fill:#767577;}</style></defs><ellipse class="edit-card" cx="12" cy="4.84" rx="1.77" ry="1.84"/><ellipse class="edit-card" cx="12" cy="12" rx="1.77" ry="1.84"/><ellipse class="edit-card" cx="12" cy="19.16" rx="1.77" ry="1.84"/></svg>
        </span>
        <div ngbDropdownMenu class="common-dropdown-list fnt-size14px" aria-labelledby="dropdownBasic1">
            <a *ngFor="let data of ngbDDL?.data" (click)="ngbAction(data)" class="cursor-pointer" ngbDropdownItem>{{data?.text}}</a>
        </div>
</span> -->
<!-- [placement]="'bottom-right'" -->
  <span ngbDropdown class="d-inline-block ml-2 ngb-dropdown-container" *ngIf="ngbDDL && ngbDDL?.data && ngbDDL?.data.length > 0">
    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">

      <img src="assets/themes/icons/ngbdot.svg" >
    </span>
    <!-- ngbDropdownItem -->

    <div ngbDropdownMenu
      class="ngb-dropdown-list mr-1"
      aria-labelledby="dropdownBasic1">
      <div class="list-hover" *ngFor="let data of ngbDDL?.data">
        <a *ngIf="data.action != 'unsubscribe'"
          [ngClass]="{'opacity-disabled': isdisabled == true && data?.text == 'Remove from group' }"
          (click)="ngbAction(data)"
          class="cursor-pointer d-block p-1 pl-2 pr-2">
          <img *ngIf="data.src" class="pr-1 repost_icon" src="{{data.src}}" alt="icon-repost">
          {{ data.action != "Chat" ? data?.text : "" }}</a>
        <a *ngIf="data.action == 'unsubscribe'"
          [eventTracker]="{ category: callingfrom, action: mls.googleEventText.channel_detail.key1, product_type: data.product_type, product_id: data.id }"
          (click)="ngbAction(data)"
          class="cursor-pointer d-block p-1 pl-2 pr-2">{{ data.action != "Chat" ? data?.text : "" }}</a>
      </div>
    </div>
  </span>
