<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div class="position-relative px-3 px-sm-4 py-sm-5 pb-3 pt-5">
      <a (click)="screen_back()" class="back-page position-absolute" [appOnboardingEventTracker]='{"category":mls.googleEventText.studenttype_screen.category,"action":mls.googleEventText.studenttype_screen.key1,"product_type":"", "product_id":""}'><img src="assets/themes/icons/back-arrow.svg" width="20px" /></a>
      <div>
        <h5 class="mb-1 heading-4"><span class="color-deep-green">{{mls.languageText.studenttype_screen.key1}}</span></h5>
        <!-- form -->
        <div class="my-3 clearfix">
          <a class="form-control cursor-pointer user-type-card d-flex align-items-center justify-content-between" href="javascript:void();" *ngFor="let prof of profession_data; let idx=index" (click)="selectProfession(prof[1], idx)" [appOnboardingEventTracker]='{"category":mls.googleEventText.studenttype_click.category,"action":mls.googleEventText.studenttype_click.key1,"product_type":"", "product_id":""}'>
            <span class="card-type secondary-paragraph">
              <img *ngIf="prof[1]?.type === 'medical_student'" src="assets/themes/icons/user-student.svg" alt="Student" />
              <img *ngIf="prof[1]?.type === 'dental_student'" src="assets/themes/icons/dental-student.svg" alt="Student" />
              {{prof[1]?.value}}
            </span>
             <svg id="Layer_1" class="float-right" data-name="Layer 1" style="margin-top: 3px;" width="16px" viewBox="0 0 25 25"><defs><style>.cls-right-arrow{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><polyline class="cls-right-arrow" points="9.82 21 18.32 12.5 9.82 4"/></svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
