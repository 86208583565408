<div *ngIf="isSkipConfirmModalShown" [config]="{ show: true, backdrop: masterService.modals_backdrop.confirm_modal }"
  (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <svg [appOnboardingEventTracker]='{"category":mls.googleEventText.skiponboarding_screen.category,"action":mls.googleEventText.skiponboarding_screen.key1,"product_type":"", "product_id":""}'
        (click)="hideModal()" class="close-detail" version="1.1" width="10px" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414">
          <g>
            <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z"/>
          </g>
        </svg>
      </div>
      <div class="modal-body text-center pt-0">
          <div class="mt-3" *ngIf="benefits!=undefined">
            <img [src]="benefits[1]?.image" onerror="this.src='assets/themes/icons/warning-svg.svg'"
              width="120px" height="120px" class="post-pic rounded-circle mb-2" />
            <h4 class="heading-4 mt-2">{{benefits[1]?.title}}</h4>
            <p class="secondary-paragraph mt-2">{{benefits[1]?.description}}</p>
          </div>

          <p class="paragraph" *ngIf="benefits==undefined">
            {{mls?.languageText?.skiponboarding_screen?.key2}}<span
              *ngIf="mls?.is_lskey_display==1">(skiponboarding_screen.key2)</span></p>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn-design medium outline" (click)="ActivateAction()" [appOnboardingEventTracker]='{"category":mls.googleEventText.skiponboarding_screen.category,"action":mls.googleEventText.skiponboarding_screen.key2,"product_type":"", "product_id":""}'>
          <span *ngIf="skipInProcess==true" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
          {{mls?.languageText?.skiponboarding_screen?.key1}}
          <span *ngIf="mls?.is_lskey_display==1">(skiponboarding_screen.key1)</span>
        </button>
        <button class="btn-design medium filled ml-1 ml-md-3" (click)="hideModal()" [appOnboardingEventTracker]='{"category":mls.googleEventText.skiponboarding_screen.category,"action":mls.googleEventText.skiponboarding_screen.key3,"product_type":"", "product_id":""}'>
          {{mls?.languageText?.skiponboarding_screen?.key3}}
          <span *ngIf="mls?.is_lskey_display==1">(skiponboarding_screen.key3)</span>
        </button>
      </div>
    </div>
  </div>
</div>
