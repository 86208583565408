  <div *ngIf="isShownWebModal" 
    class="modal fade verification-success-modal-container" 
    tabindex="-1" 
    role="dialog" 
    [config]="{ show: true, backdrop: 'relative' }" 
    (onHidden)="onHidden()" 
    bsModal 
    #autoShownModal="bs-modal" 
    >
    <div class="custom-theme-modal modal-dialog modal-md verification-success">
      <div class="modal-content position-relative">
        <div class="modal-header pt-3 pb-3">
          <span (click)="close()" class="cursor-pointer float-right">
            <img src="assets/themes/icons/cancel.svg" alt="close icon" />
          </span>
        </div>
        <div class="modal-body text-center p-5">
          <img width="120" [src]="verificationContent[0].icon || 'assets/images/user.svg'"
            onerror="this.src='assets/images/user.svg'" alt="User icon" />
          <h3 class="heading-3">{{verificationContent[0].title}}</h3>
          <p class="paragraph">{{verificationContent[0].description}}</p>
        </div>
      </div>
    </div>
  </div>