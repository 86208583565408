/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Module imports
 */
import * as moment from 'moment';
import 'moment-duration-format';

/**
 * Pipe for calculating ageing (calculates the time difference between current time
 * and the time when the post is created and returns the largest factor e.g if a post is created
 * a year and a month back, it returns 1 Year, if 2 month and 1 week 18 hours, ite returns 2 month)
 * @timestamp input params defines time in seconds
 */
@Pipe({
  name: 'ageing'
})
export class CalculateAgeingPipe implements PipeTransform {

  // TODO: RAHUL: use variables from constant
  transform(timestamp: number, product?: string): string {
    if (product === 'user_story') {
      return this.calculateAgeingForStory(timestamp);
    }
   return this.calculateAgeing(timestamp);
  }

  calculateAgeing(timestamp: number): any {
    const date = new Date();
    const diff = moment(date).diff(timestamp * 1000, 'milliseconds');
    const duration = moment.duration(diff);
    const format = duration.format('Y [Year],M [Month],w [Week],d [Day],h [Hours],m [Mins],s [Now]');
    const arr = format.split(',');
    return arr[0].indexOf('Now') > -1 ? 'Now' : arr[0];
  }
  calculateAgeingForStory(timestamp: number) {
    const date = new Date();
    const diff = moment(date).diff(timestamp*1000, 'milliseconds');
    const duration = moment.duration(diff);
    const format = duration.format('Y[Year],M[Month],w[Week],d[d],h[h],m[m],s[Now]');
    const arr = format.split(',');
    const value = arr[0] + ' ago';
    return arr[0].indexOf('Now') > -1 ? 'Now' : value; 
  }
}
