/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';

/**
 * Used to set the Authorization headers.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const appKey = environment.appKey;
    let authReq;
    if (request.url.indexOf('cloudfront') != -1) {
      authReq = request.clone({}).url + '?id=' +  Date.now();
    }
    if (request.url.indexOf('amazonaws') !== -1) {
      authReq = request.clone({});
    }
    else {
      authReq = request.clone({
        headers: request.headers
        .set('Authorization', appKey)
      });
    }
    
    return next.handle(authReq);
  }
}
