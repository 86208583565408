
<div *ngIf="offset > 1" class="text-center ">
    <button (click)="ViewAllAction(pType)" class="btn-design small outline mt-3 mb-4">
        {{viewAllText}}
    </button>
</div>

<div class="d-flex justify-content-center mb-4">
<div class="view-card  p-4 mt-2">
  <div class="row">
    <div class="col-7">
       <div>
        <span class="heading-4"> {{title}}</span>
       </div>
       <div>
        <button (click)="ViewAllAction(numberConst.ZERO)"
          [pAppAnalyticsEvent]="viewAllRecord()"
          class="btn-design medium filled width cursor-pointer mt-3">
            {{viewAllText}}
        </button>
       </div>
    </div>

    <div class="col-5">
        <img src="assets/themes/icons/public/view-all.svg" alt="view-all">
    </div>
  </div>
</div>
</div>
