import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotedAfterSlice'
})
export class DotedAfterSlicePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, sliceAfter?: any): any {
    if (value.length > sliceAfter) {
      value = value.substr(0, sliceAfter);
      value = value + '...';
    }
    return value;
  }

}
