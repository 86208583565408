/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { OpenAccessService } from '../open-access.service';


/**
 * Constant imports
 */
import { USER_ROLE } from '../../constants/app.constants';
import { Router } from '@angular/router';

/**
 * Component for displaying the public user role.
 */
@Component({
  selector: 'app-public-skip-popup',
  templateUrl: './public-skip-popup.component.html',
  styleUrls: ['./public-skip-popup.component.scss']
})
export class PublicSkipPopupComponent {

  @Output() loginClicked: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Property to control the visibility of the user role popup.
   */
  public skipPopup = false;

  public selectedUserRole = USER_ROLE;

  /**
   * Constructor to inject FunCollectionService.
   * @param fcs - Instance of FunCollectionService.
   */
  constructor(public fcs: FunCollectionService,
    public mls: NewLanguageService,
    public oas: OpenAccessService,
    private router: Router) { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
  }

  /**
   * login button clicked and move to login flow
   */
  loginButtonClick(): void {
    this.loginClicked.emit();
    this.oas.skipPopUp = false;
  }

  /**
   * skip / close popup
   */
  closePopUp(): void {
    this.router.navigateByUrl('open-access/public-product-list');
    this.oas.skipPopUp = false;
  }
}

