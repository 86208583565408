import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterdata'
})
export class FilterPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(items: any[], value: string, label: string): any[] {
    if (!items) { return []; }
    if (!value) { return  items; }
    if (value == '' || value == null) { return []; }
    return items.filter(e => e[label].toLowerCase().indexOf(value) > -1 );

  }

}
