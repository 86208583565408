<div *ngIf="isChatPreview" id="SubscribeBoxModal" class="modal fade success-popup" style="z-index: 999999;"
  tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()" bsModal
  #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <div class="d-flex justify-content-between align-items-center pt-3 px-3">
          <h4 class="heading-4">{{title}}</h4>
          <img class="cursor-pointer" src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="cross"
            (click)="onHidden()">
        </div>
      </div>
      <div class="modal-body text-center position-relative px-3">
        <div *ngIf="imageSrc">
          <img [src]="imageSrc" alt="banner" style="height: 26dvh;">
        </div>
        <div class="m-3">
          <div *ngIf="isMultiple" class="chat-selected-list mr-2 pb-4 position-relative">
            <div *ngIf="widgetsContent?.scrollLeft > 0" class="nav-left" (click)="scrollLeft()">
            </div>
            <div #widgetsContent class="custom-slider-main"
              [ngClass]="{'justify-content-center': !(widgetsContent?.scrollLeft > 0) && (widgetsContent?.scrollLeft + widgetsContent?.offsetWidth >= widgetsContent?.scrollWidth)}">
              <div class="mr-2 mb-2 d-flex box-64" *ngFor="let profile of profileForm; let i = index; let last = last;">
                <div *ngIf="profile.banner" [ngClass]="{'selected': isSelected === i}"
                  class="position-relative flex-shrink-0 box-64" (click)="changeImage(i)">
                  <img class="h-100 w-100 object-fit-cover" [src]="profile.banner" alt="banner">
                  <img *ngIf="profileForm.length > 1" class="cursor-pointer cross-icon"
                    src="assets/samvaad-assets/images/icons/cross-with-border.svg" alt="cross"
                    (click)="$event.preventDefault();$event.stopPropagation();removeUploader(profile, i)" width="18"
                    height="18" />
                </div>
              </div>
            </div>
            <div *ngIf="!(widgetsContent?.scrollLeft + widgetsContent?.offsetWidth >= widgetsContent?.scrollWidth)"
              class="nav-right" (click)="scrollRight()">
            </div>
            <div class="position-relative" *ngIf="profileForm.length < 10">
              <lib-file-upload #upload [fileLimit]="filesLimits - profileForm.length" [maxFileSize]="uploadingSize" [multiple]="true"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG" (emitUploadFileList)="setBanner($event)" [samvaadLanguageText]="samvaadLanguageText">
                <div class="rounded text-center custom-tooltip bottom" id="drag_zone" (drop)="upload.dropFiles($event)"
                  (dragover)='upload.dragOverHandler($event)'>
                  <label class="mb-0 add-image box-64 d-flex justify-content-center align-items-center cursor-pointer">
                    <input #bannerInput id="add" class="d-none" type="file" accept="image/*"
                      (change)="upload.handleFileInput($event)" [multiple]="upload.multiple" />
                    <p class="text-center mt-2 plus-text">
                      +
                    </p>
                    <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key13}}</span>
                  </label>
                </div>
              </lib-file-upload>
            </div>
          </div>
          <ng-container *ngFor="let profile of profileForm; let i = index;">
            <div *ngIf="showCaption" [hidden]="isSelected !== i">
              <app-chat-creator [showFileUploader]="false" [samvaadLanguageText]="samvaadLanguageText" [disableEnter]="true"
                (emitValueChange)="getCaption($event, i)" [showSubmitBtn]="false">
              </app-chat-creator>
            </div>
          </ng-container>
        </div>
        <div *ngIf="paragraph">
          <p [innerHtml]="paragraph | linebreaks | linkify">
          </p>
        </div>
      </div>
      <div class="modal-footer pb-4 px-3 d-flex text-right mt-3 w-100 justify-content-end">
        <button type="button" class="btn-design outline medium" (click)="onHidden()">{{samvaadLanguageText?.samvaad?.key4}}</button>
        <button *ngIf="isUploadBtn" type="submit" [disabled]="isDisabled"
          [ngClass]="{'filled': !isDisabled, 'filled-disabled': isDisabled}" class="btn-design filled medium ml-md-3"
          (click)="onSubmit()">{{samvaadLanguageText?.samvaad?.key35}}</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isErrorModal">
  <app-chat-error-modal [isErrorModal]="isErrorModal"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg" [paragraph]="errorMsg"
    (onHide)="isErrorModal = false" [samvaadLanguageText]="samvaadLanguageText">
  </app-chat-error-modal>
</div>

<div *ngIf="chatLoading">
  <app-chat-progress [isSmallLoading]="false" [isFullScreen]="1" [width]="60" [height]="60"></app-chat-progress>
</div>
