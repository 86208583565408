<app-public-page-header (emitIconClick)="sendEvent($event)" [analytic]="record"
[communityText]="mls.languageText.open_article.key10"
[doctalkText]="mls.languageText.open_article.key11"
[articleText]="mls.languageText.open_article.key12"
[webinarText]="mls.languageText.open_article.key13"></app-public-page-header>
<div class="container">
  <div *ngIf="apiInProcess" class="text-center mt-4">
    <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post" alt="loader" />
</div>
  <div *ngIf="!restrictContent && !apiInProcess">
  <div  class="card-container mt-4 p-4">
<div class="row">
  <div class="col-md-6">
    <div><img class="banner-image post-pic" [src]="openDoctalk?.banner" onerror="this.src='assets/themes/images/doctalk-default.svg'"/></div>
  </div>
  <div class="col-md-6 ml-0 mt-3">
    <div class="heading-3 doctalk-desc ">{{openDoctalk?.title}}</div>
    <p class="secondary-paragrap mt-2 text-desc"><span class="color-grey-700">{{openDoctalk?.description  | slice:0:130}}</span>
      <span class="font-weight-bold color-grey-700"style="cursor:pointer;" *ngIf="openDoctalk?.description?.length>130"
        (click)="seeMore()">..{{mls.languageText.doctalk_details.key9}}<span
          *ngIf="mls?.is_lskey_display==1">(doctalk_details.key9)</span></span>
    </p>

    <p class="color-grey-500 mt-3">
      <span *ngFor="let hashtag of openDoctalk?.speciality" class="color-grey-500 mr-2 text-desc secondary-paragraph mt-3">
        #{{hashtag.name}}
      </span>
  </p>

    <div >
      <div *ngIf="openDoctalk.speaker.length !== 0 " class="secondary-paragrap-bold mt-4"><span class="color-grey-700">{{mls.languageText.open_article.key19}}</span></div>
      <div class="color-grey-500">
        <div class="row my-2">
        <div class="col-5 pr-0" *ngFor="let speaker of openDoctalk?.speaker | slice: 0: 2">
          <div class="row m-0 cursor-pointer">
              <div class="float-left mr-2 col-2 p-0" style="min-width: 40px; max-width: 40px;">
                <img [src]="speaker?.profile_pic" class=" user-40 rounded-circle"
                  onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" />
              </div>
              <div class="float-left col-10 p-0 speaker-name-max-width">
                <div class="d-table doctalk-height-layout">
                  <div class="d-table-cell align-middle secondary-paragraph">
                    <span [ngClass]="openDoctalk?.speaker.length>numbers.ONE ? 'speaker-desc' : '' ">{{speaker?.name}}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div [ngClass]="fcs.device_detail.is_mobile ? 'col-1' : 'ml-3'" *ngIf="openDoctalk?.speaker.length>numbers.TWO">
          <div class="d-table doctalk-height-layout"
            (click)="showSessionSpeakerList()">
            <div class="d-table-cell align-middle paragraph">
              <span class="cursor-pointer color-grey-700">+{{openDoctalk?.speaker?.length - numbers.TWO}}
                {{mls.languageText.open_article.key14}}</span>
            </div>
          </div>
        </div>

      </div>
      </div>

    </div>
  </div>
</div>
<div class="border-bottom-grey"></div>
<div class="d-flex flex-column flex-lg-row align-items-center justify-content-between mt-2">
  <app-share-strip
    [title]="mls.languageText.open_article.key25"
    [fbText]="mls.languageText.open_article.key26"
    [twText]="mls.languageText.open_article.key27"
    [liText]="mls.languageText.open_article.key28"
    [shareUrl]="openDoctalk?.share_url"
    (emitClick)="sendShareEvent($event)">
  </app-share-strip>
  <button (click)="videoPopUp()"
    [pAppAnalyticsEvent]="playEpisodeRecord"
    class="btn-design medium filled mt-4 mt-md-0"
    [ngClass]="fcs.device_detail.is_mobile ? 'w-100' : ''">{{mls.languageText.open_article.key21}}</button>
</div>
  </div>
  <div>
    <app-open-access-video *ngIf="doctalkDetail.isShow==true"  [videoSeriesData]="doctalkDetail" [isSeries]="isSeries"></app-open-access-video>
  </div>

  <div class="row">
    <div class="col-md-7" >
      <div class="py-2"> <span class="heading-4">{{mls.languageText.open_article.key34}}</span>
        <!-- carousel code start-->
        <div class="mt-2 custom-owl-carousel bg-transparent shadow-none p-0 carousel-wrapper">
          <owl-carousel-o [options]="customOptionsForCarousal">
            <ng-template carouselSlide *ngFor="let card of openDoctalk?.related_list">
              <div>
                <app-public-article-login-card
                  [openArticle]="card"
                  [callingFrom]="callingFrom"
                  [renderType]="openAccessConst.prodListRenderType.product_list"
                  [pType]="openAccessConst.productlist.doctalk"
                  [textContainer]="textContainer"
                  [isEpisode]="isEpisode"
                  (cardClicked)="onCardClick()"
                  [isCarousel]="isCarousel">
                </app-public-article-login-card>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
        <!--carousal code end-->
      </div>
      <div>
        <p class="heading-4 color-grey-700 mt-4">{{mls.languageText.open_article.key42}}</p>
        <div class="my-3">
        <div class="text-center signin-card cursor-pointer" (click)="navigateTologgedInDoctalkDetail()">
            <div class="card-elements p-2">
                <div>
                    <img src="assets/themes/icons/public/doctor-icon.svg" alt="doctor-icon" class="doctor-img mt-3">
                </div>
                <p class="heading-4 mt-3">{{mls.languageText.open_article.key40}}</p>
                <p class="secondary-paragraph mt-2">{{mls.languageText.open_article.key41}}</p>
                <button [pAppAnalyticsEvent]="singInRecord"
                    class="btn-design small filled h-auto cursor-pointer my-3">
                    {{mls.languageText.open_article.key8}}
                </button>
            </div>
        </div>
    </div>
    </div>
    </div>

      <div *ngIf="suggestionList && suggestionList?.list.length > 0" class="col-md-5">
        <div>
            <app-product-suggestion-list
             [callingFrom]="'OPEN_ARTICLE_DETAIL'"
             [suggestionList]="suggestionList?.list"
             [listTitle]="suggestionList?.label"
             (cardClicked)="onCardClick()"
             [pType]="openAccessConst.productlist.doctalk">
            </app-product-suggestion-list>
        </div>
        <div class="text-center">
          <app-public-view-all
          [viewAllText]="mls.languageText.open_article.key15"
          [pType]="openAccessConst.productlist.doctalk"
          [callingFrom]="callingFrom"
          [title]="mls.languageText.open_article.key30"
          [offset]="suggestionList?.offset"
          (viewAllClick)="viewAllClick($event)"></app-public-view-all>
        </div>
    </div>
  </div>
</div>
  <div *ngIf="restrictContent">
    <app-restricted-popup
    [restrictedPopup]="restrictContent"
    [restrictData]="restrictData">
    </app-restricted-popup>
    </div>
</div>

<div *ngIf="speakerList" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }"
  bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header d-block px-4 pt-4">
        <div class="">
          <div class="row">
            <div class="col-6">
              <p class="heading-4">
                <span class="color-grey-700">{{mls.languageText.open_article.key24}}</span>
              </p>
            </div>
            <div class="col-6 text-right">
              <span class="cursor-pointer" (click)="close()">
                  <img src="assets/themes/icons/cancel.svg" alt="cross">
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-body">
        <!-- list -->
        <div
          class="border-bottom last-item-border"
          *ngFor="let speakerObj of openDoctalk?.speaker">

            <div class="clearfix d-flex align-items-center py-3 position-relative border-bottom last-item-border">
              <img class="speaker mr-2 rounded-circle float-left mr-3" [src]="speakerObj?.profile_pic"
              onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'" alt="speaker" />

              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <p class="paragraph-bold">
                    {{ speakerObj?.name }}
                  </p>
                </div>
              </div>

            </div>

        </div>
        <!-- list -->
      </div>

    </div>
  </div>
</div>

<div *ngIf="seePopUp" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }"
  bsModal #autoShownModal="bs-modal">
<div  class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-body">
      <p class="p-2">
        <span data-dismiss="modal"
          aria-label="Close"
          class="cursor-pointer float-right clearfix">
          <span aria-hidden="true" >
            <img (click)="goBack()" src="assets/themes/icons/cancel.svg" alt="close icon">
          </span>
        </span>
      </p>
      <div class="mt-5 mb-5">
        <p class="heading-4">
          <span class="color-grey-7000">{{mls?.languageText?.cme_desc?.key1}}</span>
        </p>
        <p class="secondary-paragraph">{{openDoctalk?.description}} </p>
      </div>

    </div>
  </div>
</div>
</div>

<app-public-skip-popup 
(loginClicked)="onCardClick()">
</app-public-skip-popup>

<div #doctalkloginContainer class="d-none"></div>