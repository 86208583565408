/**
 * Angular Import.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Component used to show whitelistinfo modal.
 */
@Component({
  selector: 'app-whitelisting-modal',
  templateUrl: './whitelisting-modal.component.html',
  styleUrls: ['./whitelisting-modal.component.scss']
})
export class WhitelistingModalComponent {

  /**
   * Receive error code.
   */
  @Input() whileListErrorCode: number;

  /**
   * Receive error mesage.
   */
  @Input() whiteListErrorMsg: string;

  /**
   * Show modal variable.
   */
  @Input() showModal: boolean;

  /**
   * Modal title.
   */
  @Input() title: string;

  /**
   * Type of modal.
   */
  @Input() modalType: string;

  /**
   * Used to use the modal on current component
   */
  @Input() calling_From: string;

  /**
   * Output function when user close modal.
   */
  @Output() hideModal = new EventEmitter();

  /**
   * Output function when user submit data.
   */
  @Output() submitData = new EventEmitter();

}
