/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AccessTokenService } from '../auth/access-token.service';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Service imports.
 */
import { AuthService } from '../auth/auth.service';
import { mergeMap } from 'rxjs/operators';

/**
 * Used to set the additional headers for apis.
 */
@Injectable()
export class AdditionalHeadersInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

    let headers = request.headers;
    if (localStorage.getItem('token') != null) {
      const token = this.authService.getToken();
      headers = headers.set('userauthkey', token);
      if(localStorage.getItem('deviceToken')) {
        headers = headers.set('udid', localStorage.getItem('deviceToken'));
      }
    } else if (localStorage.getItem('token') != null) {
      const token = this.authService.byPassAuthGetbyName('token');
      headers = headers.set('userauthkey', token);
      if(localStorage.getItem('deviceToken')) {
        headers = headers.set('udid', localStorage.getItem('deviceToken'));
      }
    }
    if(request.url.includes('open-content')){
      const openLang = (localStorage.getItem('openAccess') && localStorage.getItem('openAccess')!= 'null')? (JSON.parse(localStorage.getItem('openAccess'))?.openLang):'en';
      headers = headers.set('lang', openLang );}
      else {
        headers = headers.set('lang', lang);
      }
    headers = headers.set('appversion', environment.versions.appVersion);
    if(request.url !='/device-token-generate'){
      headers = headers.set('devicetype', headers?.get('deviceType') || environment.deviceType);
    }

    const authReq = request.clone({
        headers: headers
      });
    return next.handle(authReq);
  }
}
