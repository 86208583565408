/**
 * App media type constant.
 */
export const MEDIA_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
  DOCUMENT: 'document',
  AUDIO: 'audio'
};

/**
 * App notification type constant.
 */
export const NOTIFICATION_TYPE = {
  MEETING: 'mrmeeting',
  ONLINEEVENT: 'event_v3',
  ONLINESESSION: 'session_v3',
  EVENTSESSIONSCHEDULE: 'session_schedule',
  EVENTSCHEDULE: 'event_schedule_v3',
  VAULT: 'vault',
  INVITECODE: 'invite_code_redirection',
  PRODUCTCHATBOT: 'redirection_drug_chatbot',
  PHARMA: 'pharma',
  JOIN_INVITATION: 'pharma-channel',
  // SURVEY: 'survey',
  EXPLORE: 'explore',
  CONNECTION: 'connection',
  TELECONSULTATION: 'TELECONSULTATION',
  EDITORS_CHOICE: 'editors_choice',
  COMMUNITY_LIST: 'community',
  COMMUNITY_CREATE: 'community_create',
  GAME_DETAIL: 'game_detail',
  NCP_DETAIL: 'ncp_detail',
  PROFILE_SUGGESTION: 'profile_suggestion',
  DOCTALK_SERIES: 'doctalkseries',
  WEBINAR_LIVE_LIST: 'webinar_live_list',
  UPCOMING_WEBINAR_LIST: 'upcoming_webinar_list',
  CHANNEL: 'channel',
  FEED: 'feed',
  CME: 'cme',
  EVENT: 'event',
  LINK: 'link',
  JOURNAL: 'journal',
  CONNECTION_HOME: 'connectionhome',
  PROFILE_INFO: 'profileinfo',
  WEBINAR: 'webinar',
  DOCTALK: 'doctalk',
  CONNECTION_LIST: 'connectionlist',
  USER: 'user',
  SESSION: 'session',
  POST_CASE: 'postcase',
  FEED_POST_LIST: 'feedpolllist',
  FEED_CLINICAL_CASES: 'feedclinicalcase',
  DRUG_SAMPLE: 'drugsample',
  PERMISSION: 'permission',
  USER_STORY: 'user_story'
};

/**
 * Event types.
 */
export const EVENT_TYPE = {
  EVENT: 7,
  SESSION: 21
};

/**
 * Sponsor types.
 */
export const SPONSOR_TYPE = {
  PHARMA: 'pharma',
  ProductType: 44
};

/**
 * Virtual booth types.
 */
export const VIRTUAL_BOOTH_TYPE = {
  ProductType: 43
};

/**
 * Event modal.
 */
export const EVENT_MODAL_TYPE = {
  VIEW_ALL: 'viewAll',
  SPEAKER_LIST: 'speakerList'
};

/**
 * Event Filter Type.
 */
export const EVENT_FILTER_TYPE = {
  HALL: 'HALL'
};

/**
 * Filter Mapper
 */
export const FILTER_MAPPER = {
  HALL: 'hall'
};

/**
 * Invite Code Success Target Types.
 */
export const INVITE_CODE_TARGET_TYPES = {
  PHARMA: 'pharma-channel',
  CLOSE: 'close'
};

/**
 * Product type constant.
 */
export const PRODUCT_TYPE_CONSTANT = {
  COMMENT: 2,
  ARTICLE: 3,
  BANNER: 9,
  CHANNEL: 10,
  CME: 8,
  DRUG: 15,
  DOCTALKS: 24,
  EVENT: 7,
  FEED: 1,
  GROUPCHAT: '41',
  PHARMA: 28,
  USER: 14,
  SURVEY: 25,
  SESSION: 21,
  SEARCH: '32',
  WEBINAR: 27,
  CONNECTION: 29,
  EDITORS_CHOICE: 46,
  DOCTALKS_SERIES: 36,
  NCP: 48,
  NCP_SUB_TYPE: 49,
  HCF: 50,
  COMMUNITY: 47,
  NEWS: 0,
  BOOKMARK: 0,
  CLINICALCASE: 1,
  POLL: 1,
  POSTING_CARD: 31,
  OTHER_CARD: 33,
  NOTIFICATION: 37,
  INSIGHT: 46,
  PVS: 52,
  ARTICLE_STRING : '3',
  DOCTALK_STRING : '24',
  CHAT: 23,
  STORY: 51,
};

/**
 * Group chat constant.
 */
export const NG_CIRCLE_CONSTANT = {
  radius: 50,
  outerStrokeWidth: 16,
  innerStrokeWidth: 8,
  outerStrokeColor: '#78C000',
  innerStrokeColor: '#C7E596',
  animationDuration: 300,
};

/**
 * Support Email constant.
 */
export const SUPPORT_EMAIL = {
  EMAIL: 'support@docquity.com',
};

/**
 * Get otp error code for whitelisting.
 */
export const GET_OTP_WHITELIST_ERROR_CODE = {
  WHITELIST_ERROR_CODE: 1028,
};

/**
 * Webinar status constant.
 */
export const WEBINAR_STATUS = {
  LIVE: 'live',
  PAST: 'past',
  PENDING: 'pending',
  UPCOMING: 'upcoming',
  END: 'end'
};

/**
 * Webinar type constant.
 */
export const WEBINAR_TYPE = {
  RECORDED: 'recorded',
  WOWZA: 'wowza',
  AGORA: 'agora',
  DSTREAM: 'dstream'
};

/**
 * Special character constant.
 */
export const SPECIAL_CHARACTER = {
  AT: '@',
  COMMA: ',',
  AND: '&',
  HASH: '#'
};

/**
 * Target constant.
 */
export const TARGET_TYPE = {
  LINK: 'link',
  EXTERNAL_LINK: 'externalLink',
  NCP_LINK: 'ncp_link',
  DEEPLINK: 'deeplink',
  DRUG: 'drugsample',
  ARTICLE: 'journal',
  PROFILE: 'user',
  WEBINAR: 'webinar',
  DOCTALK: 'doctalk',
  SURVEY: 'survey'
};

/**
 * Cme Type constant.
 */
export const CME_TYPE = {
  CME: 'cme',
  QUIZ: 'quiz',
  MODULE : 'module',
};

/**
 * Default Date constant.
 */
export const DEFAULT_DOB = {
  DOB: '0000-00-00'
};

/**
 * Webinar register button constant.
 */
export const WEBINAR_REGISTER = {
  SHOW_BUTTON: 1,
  IS_REGISTERED: 0,
  REGISTERED: 1
};

export const WEBINAR_CONSENT_COLOR = {
  CHECKED: '#27ae60',
  UNCHECKED: '#737171',
};

/**
 * Start month index for user experience
 */
export const START_MONTH_INDEX = {
  POSITIVE: 1,
  NEGATIVE: -1
};

/**
 * Constant values to navigate.
 */
export const NAVIGATION_TARGET = {
  CONNECTION: 'connection',
  FEED: 'feed',
  EVENT: 'event',
  JOURNAL: 'journal',
  CME: 'cme',
  CHANNEL: 'channel',
  WEBINAR: 'webinar',
  DOCTALK: 'doctalk',
  INSIGHTS_COLLECTOR: 'insightsCollector',
  PHARMA: 'pharma',
  ONLINE_EVENT: 'online_event',
  MY_DOWNLOAD: 'mydownload',
  CONTRIBUTOR: 'contributor',
  REWARDS: 'rewards',
  CHATS: 'chats',
  SURVEY: 'survey',
  CLINICAL_CASES: 'clinicalcases',
  POLL: 'poll',
  DRUGS_SAMPLE: 'drugsample',
  MY_BOOKMARKS: 'mybookmarks',
  INSIGHTS: 'insights',
  GR_FILTER: 'gr_filter',
  CALENDER: 'calender',
  CASES: 'cases',
  CASES_POST: 'CASES_POST'
};

/**
 * Constant values of explore identifiers.
 */
export const EXPLORE_IDENTIFIER = {
  EXPLORE_CLINICALREFERENCES: 'EXPLORE_CLINICALREFERENCES',
  EXPLORE_WEBINAR: 'EXPLORE_WEBINAR',
  EXPLORE_DOCTALKS: 'EXPLORE_DOCTALKS',
  EXPLORE_SURVEYS: 'EXPLORE_SURVEYS',
  EXPLORE_CME: 'EXPLORE_CME'
};

/**
 * Constant values for tooltip position.
 */
export const TOOLTIP_POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

/**
 * Constant values for view identifier.
 */
export const VIEW_IDENTIFIER = {
  CME_VAULT: 'CME_VAULT',
};

/**
 * Calling from
 */
export const CALLING_FROM = {
  SEARCH_List: 'search-list',
  FEED_DETAIL: 'feed_detail',
  SEARCH_FEED_CARD: 'search_feed_card',
  EC_DETAIL: 'EC_DETAIL',
  CASE_GLOBAL_SEARCH: 'case_global_search'
};

/**
 * Meeting End Hour, Minute, Second
 */
export const MEETING_END = {
  hour: 23,
  min: 59,
  sec: 59
};

/**
 * Route urls constants.
 */
export const ROUTE_URL = {
  SESSION_DETAILS: 'session-details'
};
/**
 * Delete reason constant
 */
export const DELETE_REASON = {
  textLimit: 350,
  url: '/webprofile/setting/delete_profile_reason'
};
/**
 * Profile delete API constants.
 */
export const DELETE_PROFILE_API_FIELD = {
  getProfileInfo: 'user_items',
  getProfileReason: 'reason'
};

/**
 * Profile setting menu type constants.
 */
export const PROFILE_CONSTANTS = {
  deleteProfile: 'delete_profile',
  deleteProfileReason: 'delete_profile_reason'
};

/**
 * Constant for checking if connection is enable.
 */
export const CONNECTION_ENABLE = {
  ENABLE: 1,
  DISABLE: 0
};

/**
 * timezone string.
 */
export const TIMEZONE_STRING = {
  EN_US: 'en-US',
  NUMERIC: 'numeric' as const,
  LONG: 'long' as const
};

/**
 * Time constant in milli-seconds.
 */
export const MILLISECOND_TIME = {
  ONE_SECOND: 1000,
  THIRTY_SECOND: 30000,
  SIXTY_SECOND: 60000
};

/**
 * Time constant in seconds.
 */
export const TIME = {
  ONE_SECOND: 1,
  THIRTY_SECOND: 30,
  SIXTY_SECOND: 60
};

/**
 * Constants for Integer value.
 */
export const NUMBERS = {
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FIVE: 5,
  SIX: 6,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  FIFTEEN: 15,
  TWENTY_THREE: 23,
  TWENTY_SEVEN: 27,
  FOURTY_TWO: 42,
  FOURTY_NINE: 49,
  EIGHTY: 80,
  ONE_FORTY_EIGHT: 148,
  ONE_FIFTY: 150,
  ONE_SEVENTY: 170,
  TWO_FIFTY: 250,
  THREE_HUNDRED_FIFTY: 350,
  ONE_THOUSAND: 1000,
  TWO_THOUSAND: 2000,
  TWO_THOUSAND_ONE: 2001,
  HUNDRED: 100,
  FOURTY_SIX: 46,
  EIGHT: 8,
  FOUR: 4,
  FIVE_HUNDRED: 500,
  TWO_HUNDRED: 200,
  TWO_HUNDRED_FOUR: 204,
  TEN_THOUSAND: 10000,
  TWO_HUNDRED_ONE: 201,
  EIGHT_HUNDRED: 800,
  SEVEN_HUNDRED: 700,
  SIX_HUNDRED: 600,
  FIFTY: 50
};

/**
 * Menifest retry parameters.
 */
export const MENIFEST_RETRY_PARAMETERS = {
  TIMEOUT: 30000,       // timeout in ms, after which we abort
  STALL_TIMEOUT: 5000,  // stall timeout in ms, after which we abort
  CONNECTION_TIMEOUT: 10000, // connection timeout in ms, after which we abort
  MAX_ATTEMPTS: 20,   // the maximum number of requests before we fail
  BASE_DELAY: 1000,  // the base delay in ms between retries
  BACKOFF_FACTOR: 1.5, // the multiplicative backoff factor between retries
  FUZZ_FACTOR: 0.5,  // the fuzz factor to apply to each retry delay
};

/**
 * General constants for shaka-player.
 */
export const SHAKA = {
  AUDIO_SEEK_TIME_DIFFERENCE: 1.5,
  BAD_HTTP_STATUS: 1001
};


export const BOOLEAN = {
  TRUE: true,
  FALSE: false
};

export const GLOBAL_SEARCH_FILTER = {
  ALL: 0,
  CASE: 1,
  CHANNELS: 10,
  CME: 8,
  COMMUNITY: 47,
  DOCTALKS: 24,
  EVENTS: 7,
  PEOPLE: 14,
  PHARMA: 28,
  PRODUCT_SAMPLE: 15,
  REFERENCES: 3,
  SURVEY: 25,
  WEBINARS: 27,
  EDITORS_CHOICE: 46
};

export const KEYBOARD_EVENT = {
  ARROWDOWN: 'ArrowDown',
  ARROWUP: 'ArrowUp'
};

/**
 * Constant for grand round filter.
 */
export const GR_FILTER = {
  CASES: 'cases',
  POLL: 'poll',
  EDITORS_CHOICE: 'editors_choice'
};

/**
 * Constant for post screen.
 */
export const POST_SCREEN = {
  CASES_POST: 'cases_post',
  POLL_POST: 'poll_post'
};

/**
 * Constants for naming calling component (from where current component is getting called).
 */
export const COMPONENT_CALLING_FROM = {
  GLOBAL_SEARCH: 'global_search',
  LIKE_CARD: 'like_card',
  EDITOR_CHOICE: 'editorChoiceList'
};

/**
 * Constants for different types of connections.
 */
export const CONNECTION_STATUS = {
  ADD: {
    ACTION: 9,
    ACTION_TYPE: 'add'
  },
  WITHDRAW: {
    ACTION: 0,
    ACTION_TYPE: 'edit'
  },
  ACCEPT: {
    ACTION: 1,
    ACTION_TYPE: 'edit'
  },
  REJECT: {
    ACTION: 0,
    ACTION_TYPE: 'edit'
  },
  RECIVED: {
    ACTION: 8,
    ACTION_TYPE: 'edit'
  }
};
/**
 * Constant social network.
 */
export const SOCIAL_NETWORK_SITE = {
  FACEBOOK: 'fb',
  TWITTER: 'tw',
  LINKED_IN: 'li',
  G_MAIL: 'gm'
};
/**
 * Constant for feed type.
 */
export const FEED_KIND = {
  CASES: 'cases',
  POLL: 'poll',
  POST: 'post'
};

export const WEBINAR_PAGE_TYPE = {
  LIST: 'list',
  WEBINAR_DETAIL: 'webinar_detail'
};

/**
 * Screen names.
 */
export const SCREEN_NAME = {
  GRAND_ROUND: 'GRAND_ROUND',
  FEED_POSTING: 'FEED_POSTING',
  TAG_PEOPLE: 'TAG_PEOPLE',
  POST_TYPE: 'POST_TYPE',
  POST_ASSOCIATION: 'POST_ASSOCIATION',
  EC_DETAIL: 'EC_DETAIL',
  EDITOR_CHOICE_CARD: 'EDITOR_CHOICE_CARD',
  BOOKMARK_LIST: 'BOOKMARK_LIST',
  NCP_GAMES: 'NCP_GAMES',
  NCP_SUBTYPE_LISTING_PAGE: 'NCP_SUBTYPE_LISTING_PAGE',
  NCP_SUBTYPE_DETAIL_PAGE: 'NCP_SUBTYPE_DETAIL_PAGE',
  NCP_SUBTYPE_LEADERBOARD: 'NCP_SUBTYPE_LEADERBOARD',
  NCP_HORIZONTAL_CAROUSEL: 'NCP_HORIZONTAL_CAROUSEL',
  NCP_SUBTYPE_WEBVIEW: 'NCP_SUBTYPE_WEBVIEW',
  CHANNEL_DETAIL: 'CHANNEL_DETAIL',
  EDITOR_CHOICE_LIST: 'EDITOR_CHOICE_LIST',
  COMMUNITY_INPUT_DEATILS_PAGE: 'COMMUNITY_INPUT_DEATILS_PAGE',
  WEBINAR_DETAIL: 'WEBINAR_DETAIL',
  PIP_MODE: 'PIP_MODE',
  JOURNAL_DETAIL: 'JOURNAL_DETAIL',
  COMMUNITY_DASHBOARD: 'COMMUNITY_DASHBOARD',
  PUBLIC_COMMUNITIES_LIST: 'PUBLIC_COMMUNITIES_LIST',
  PAST_INACTIVE_COMMUNITIES_LIST: 'PAST_INACTIVE_COMMUNITIES_LIST',
  MY_COMMUNITIES_LIST: 'MY_COMMUNITIES_LIST',
  EXPLORE_LIST: 'EXPLORE_LIST',
  COMMUNITY_INPUT_DETAILS_PAGE: 'COMMUNITY_INPUT_DETAILS_PAGE',
  CONNECTION_SUGGESTIONS: 'CONNECTION_SUGGESTIONS',
  NOTIFICATION_LIST: 'NOTIFICATION_LIST',
  COMMUNITY_DETAILS: 'COMMUNITY_DETAILS',
  COMMUNITY_DETAIL: 'COMMUNITY_DETAIL',
  CREATE_REPOST: 'CREATE_REPOST',
  CREATE_USER_STORY: 'CREATE_USER_STORY',
  HORIZONTAL_CAROUSEL_SCREEN: 'HORIZONTAL_CAROUSEL_SCREEN',
  CONTINUE_WATCH_VIEWALL: 'CONTINUE_WATCH_VIEWALL',
  GR_PUBLIC_CAROUSEL: 'GR_PUBLIC_CAROUSEL',
  COMMUNITY_EDIT_PAGE: 'COMMUNITY_EDIT_PAGE',
  FEED_DETAIL: 'FEED_DETAIL',
  DOCTALKS_DETAIL: 'DOCTALKS_DETAIL',
  SESSION_DETAIL: 'SESSION_DETAIL',
  EVENT_DETAIL: 'EVENT_DETAIL',
  BOOK_MARK: 'gr',
  CHANNEL_HOME: 'CHANNEL_HOME',
  CME_CERTIFICATE : 'CME_CERTIFICATE',
  WEBINAR_LIST : 'WEBINAR_LIST',
  WEBINAR_LIST_VIEW_ALL : 'WEBINAR_LIST_VIEW_ALL',
  GLOBAL_SEARCH : 'GLOBAL_SEARCH',
  EXPANDED_CHAT_WINDOW: 'EXPANDED_CHAT_WINDOW',
  MINIMIZED_CHAT_WINDOW: 'MINIMIZED_CHAT_WINDOW',
  EXPANDED_CHATS_SEARCH_DOCTORS: 'EXPANDED_CHATS_SEARCH_DOCTORS',
  MINIMIZED_CHATS_SEARCH_DOCTORS: 'MINIMIZED_CHATS_SEARCH_DOCTORS',
  MINIMIZED_CHAT_DETAIL: 'MINIMIZED_CHAT_DETAIL',
  EXPANDED_GROUP_CREATION_MEMBERS: 'EXPANDED_GROUP_CREATION_MEMBERS',
  MINIMIZED_GROUP_MEMBERS: 'MINIMIZED_GROUP_MEMBERS',
  EXPANDED_GROUP_CREATION_DETAILS: 'EXPANDED_GROUP_CREATION_DETAILS',
  MINIMIZED_GROUP_CREATION_DETAILS: 'MINIMIZED_GROUP_CREATION_DETAILS',
  TOP_CHAT_ICON: 'TOP_CHAT_ICON',
  CHAT_BAR_BOTTOM: 'CHAT_BAR_BOTTOM',
  EXPANDED_CHAT_DETAIL: 'EXPANDED_CHAT_DETAIL',
  CHAT_DETAIL: 'CHAT_DETAIL',
  GROUP_INFO: 'GROUP_INFO',
  MINIMIZED_GROUP_INFO: 'MINIMIZED_GROUP_INFO',
  EXPANDED_GROUP_INFO: 'EXPANDED_GROUP_INFO',
  CHAT_WINDOW: 'CHAT_WINDOW',
  CHATS_SEARCH_DOCTORS: 'CHATS_SEARCH_DOCTORS',
  GROUP_MEMBERS: 'GROUP_MEMBERS',
  SAMVAAD_MAINTENANCE_POPUP: 'SAMVAAD_MAINTENANCE_POPUP',
  STORY_CREATION: 'STORY_CREATION',
  STORY_UPLOADING: 'STORY_UPLOADING',
  STORY_PLAY: 'STORY_PLAY',
  SURVEY_DETAIL : 'SURVEY_DETAIL',
  ALL_SURVEY_LISTING_SCREEN : 'ALL_SURVEY_LISTING_SCREEN',
  DRAFTS_SURVEY_LISTING_SCREEN : 'DRAFTS_SURVEY_LISTING_SCREEN',
  SURVEY_COMPLETE : 'SURVEY_COMPLETE',
  THANK_YOU_SCREEN : 'THANK_YOU_SCREEN',
  SURVEY_QUESTION: 'SURVEY_QUESTION',
  COMPLETED_QUESTIONNAIRE_SCREEN: 'COMPLETED_QUESTIONNAIRE_SCREEN',
  CME_DETAIL: 'CME_DETAIL',
  CME_QUESTION: 'CME_QUESTION',
  CME_THANKYOU: 'CME_THANKYOU',
  RESIDENTIAL_ADDRESS_VERIFICATION_POPUP: 'RESIDENTIAL_ADDRESS_VERIFICATION_POPUP',
  EDUCATION_VERIFICATION_POPUP: 'EDUCATION_VERIFICATION_POPUP',
  EXPERIENCE_VERIFICATION_POPUP: 'EXPERIENCE_VERIFICATION_POPUP',
  PRACTICE_DETAILS_VERIFICATION_POPUP: 'PRACTICE_DETAILS_VERIFICATION_POPUP',
  MEDICAL_AWARDS_VERIFICATION_POPUP: 'MEDICAL_AWARDS_VERIFICATION_POPUP',
  CIVIL_AWARDS_VERIFICATION_POPUP: 'CIVIL_AWARDS_VERIFICATION_POPUP',
  CERTIFICATIONS_VERIFICATION_POPUP: 'CERTIFICATIONS_VERIFICATION_POPUP',
  VOLUNTARY_SERVICE_VERIFICATION_POPUP: 'VOLUNTARY_SERVICE_VERIFICATION_POPUP',
  PATENT_VERIFICATION_POPUP: 'PATENT_VERIFICATION_POPUP',
  PAPER_PRESENTATION_VERIFICATION_POPUP: 'PAPER_PRESENTATION_VERIFICATION_POPUP',
  THESIS_VERIFICATION_POPUP: 'THESIS_VERIFICATION_POPUP',
  UNPUBLISHED_WORK_VERIFICATION_POPUP: 'UNPUBLISHED_WORK_VERIFICATION_POPUP',
  POSITION_OF_RESPONSIBILITY_VERIFICATION_POPUP: 'POSITION_OF_RESPONSIBILITY_VERIFICATION_POPUP',
  WORKSHOP_VERIFICATION_POPUP: 'WORKSHOP_VERIFICATION_POPUP',
  SEMINAR_VERIFICATION_POPUP: 'SEMINAR_VERIFICATION_POPUP',
  WEBINAR_VERIFICATION_POPUP: 'WEBINAR_VERIFICATION_POPUP',
  CONFERENCE_VERIFICATION_POPUP: 'CONFERENCE_VERIFICATION_POPUP',
  TRAININGS_RECEIVED_VERIFICATION_POPUP: 'TRAININGS_RECEIVED_VERIFICATION_POPUP',
  TRAININGS_IMPARTED_VERIFICATION_POPUP: 'TRAININGS_IMPARTED_VERIFICATION_POPUP',
  BLOG_VERIFICATION_POPUP: 'BLOG_VERIFICATION_POPUP',
  CONSULTATION_DETAILS_VERIFICATION_POPUP: 'CONSULTATION_DETAILS_VERIFICATION_POPUP',
  OPEN_ARTICLE_DETAIL : 'OPEN_ARTICLE_DETAIL',
  WEBINAR_DASHBOARD : 'WEBINAR_DASHBOARD',
  SPEAKER_LIST: 'SPEAKER_LIST',
  SPEAKER_DETAIL: 'SPEAKER_DETAIL',
  DOCTALK_DASHBOARD: 'DOCTALKS_DASHBOARD',
  SPEAKER_DETAILS: 'SPEAKER_DETAILS',
  CME_COURSE_DETAIL : "CME_COURSE_DETAIL",
  SERIES_DETAIL:'SERIES_DETAIL',
  REFERENCE_DASHBOARD:'REFERENCE_DASHBOARD',
  REFERENCE_DETAIL:'REFERENCE_DETAIL',
  CHAT_LIST: 'CHAT_LIST',
  GROUP_WINDOW: 'GROUP_WINDOW',
  CONNECTION_DASHBOARD: 'CONNECTION_DASHBOARD',
  CONNNECTION_SUGGESTIONS: 'CONNNECTION_SUGGESTIONS',
  CONNECTION_LIST: 'CONNECTION_LIST',
  REQUEST_SENT_LIST: 'REQUEST_SENT_LIST',
  HAMBURGER_MENU: 'HAMBURGER_MENU',
  SETTINGS_PAGE: 'SETTINGS_PAGE',
  SUPPORT_FEEDBACK: 'SUPPORT_FEEDBACK',
  BOOKMARK: 'BOOKMARK',
  DELETE_PROFILE: 'DELETE_PROFILE',
  DELETE_PROFILE_POPUP: 'DELETE_PROFILE_POPUP',
  DELETE_PROFILE_REASON: 'DELETE_PROFILE_REASON',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  REPOST_OPTION: 'REPOST_OPTION',
  STORY_PLAYER: 'STORY_PLAYER',
  C0MMUNITY_CREATION: 'COMMUNITY_CREATION',
  COMMUNITY_SETTINGS: 'COMMUNITY_SETTINGS',
  COMMUNITY_ADD_MEMBERS: 'COMMUNITY_ADD_MEMBERS',
  COMMUNITY_ADD_SPECIALITY: 'COMMUNITY_ADD_SPECIALITY',
  COMMUNITY_EXISTING_MEMBER: 'COMMUNITY_EXISTING_MEMBER',
  COMMUNITY_REMOVE_MEMBER: 'COMMUNITY_REMOVE_MEMBER',
  REVIEW_REMOVAL_MEMEBRS: 'REVIEW_REMOVAL_MEMEBRS',
  COMMUNITY_MEMBER_REVIEW: 'COMMUNITY_MEMBER_REVIEW',
  PUSH_NOTIFICATION_SETTING: 'PUSH_NOTIFICATION_SETTING',
  POST_DRAFT_LIST: 'POST_DRAFT_LIST',
  SEARCH_SCREEN: 'SEARCH_SCREEN',
  SEARCH_RESULT: 'SEARCH_RESULT',
  NO_SEARCH_RESULT: 'NO_SEARCH_RESULT',
  EDITORS_CHOICE: 'EDITORS_CHOICE'
};

/**
 * Filter type
 */
export const FILTER_NAME = {
  speciality_name: 'speciality_name'
};

/**
 * chat view type
 */
export const CHAT_VIEW_TYPE = {
  EXPANDED_VIEW: 'EXPANDED_VIEW',
  MINIMISED_VIEW: 'MINIMISED_VIEW'
}

/**
 * Device type constant.
 */
export const DEVICE_TYPE = {
  WEB: '1',
  MOBILE: 'mobile',
  WEB_TEXT: 'web'
};

/**
 * Chat type constant.
 */
export const CHAT_STATUS = {
  CLOSE_CHAT: 'close-chat'
};

/**
 * Used to split and join the Product type and id.
 */
export const SPLIT = '-';

/**
 * Constant for response type on selecting filters.
 */
export const RESPONSE_TYPE = {
  GROUP: 'group',
};

/**
 * Constant for API Response Code
 */
export const STATUS_CODE = {
  TWO_THOUSAND: 2000,
  ONE_THOUSAND_FIVE: 1005,
  ONE_THOUSAND: 1000,
  ONE_THOUSAND_ONE: 1001,
  TWO_THOUSAND_ONE: 2001,
  ONE_THOUSAND_TWENTY_FIVE: 1025,
  ONE_THOUSAND_TWENTY_NINE: 1029,
  ONE_THOUSAND_THIRTY_THREE: 1033,
  ONE_THOUSAND_TWO: 1002
};

/**
 * Countries language abbreviations.
 */
export const LANG_ABBREVIATIONS = {
  ENGLISH: 'en',
  INDONESIA: 'in',
  VIETNAM: 'vi',
  MANDARIN: 'zh',
  JAPANESE: 'ja',
  SPANISH: 'es',
  PORTUGESE : 'pt'
};
/**
 * Countries language abbreviations.
 */
export const APP_LANGUAGE = {
  JAPANESE: 'Japanese',
  SPANISH: 'Spanish'
};

/**
 * Shaka player configuration overflow menu
 */
export const OVERFLOW_MENU_BUTTONS = {
  QUALITY: 'quality',
  CAPTIONS: 'captions'
};

/**
 * Shaka player configuration control panel elements.
 */
export const CONTROL_PANEL_ELEMENTS = {
  PLAY_PAUSE: 'play_pause',
  SPACER: 'spacer',
  TIME_AND_DURATION: 'time_and_duration',
  MUTE: 'mute',
  FULLSCREEN: 'fullscreen',
  OVERFLOW_MENU: 'overflow_menu',
  LIVE: 'live',
  MINI_PLAYER: 'mini_player',
  LIVE_POLL: 'live_poll_button'
};

/**
 * Shaka player configuration seek bar color
 */
export const SEEK_BARS_COLOR = {
  BASE: 'rgba(255, 255, 255, 0.3)',
  BUFFERED: 'rgba(255, 255, 255, 0.54)',
  PLAYED: 'rgb(255, 255, 255)'
};

/**
 * Constants for connections status.
 */
export const CONNECTION = {
  ACCEPT: 8,
  ADD: 9,
};

export const EDITORS_DETAIL = {
  EC_DETAIL: 'ec_detail'
};
export const GRADIENT_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom'
};
/**
 * Constant for explore section.
 */
export const EXPLORE_SECTION = {
  PHARMA: 'pharma',
  CHANNEL: 'channel',
  CME: 'cme',
  DOCTALK: 'doctalk',
  WEBINAR: 'webinar',
  SURVEY: 'survey',
  JOURNAL: 'journal',
  EVENT: 'event',
  CLINICAL_CASES: 'clinicalcases',
  POLL: 'poll',
  DRUG_SAMPLE: 'drugsample',
  MY_BOOKMARKS: 'mybookmarks',
  CONNECTION: 'connection',
  INSIGHTS: 'insights',
  GR_FILTER: 'gr_filter',
  CHATS: 'chats',
  CALENDER: 'calender',
  ONLINE_EVENT: 'online_event',
  EDITORS_CHOICE: 'editors_choice',
  COMMUNITY: 'community',
  SHARE_POST: 'posting',
  LINK: 'link',
  DEEPLINK: 'deeplink',
  OTSUKAFORM: 'otsuka_form'

};

export const ERROR_CODES = {
  RESTRICTED_CONTENT_ERROR_CODE: 1013,
  ERROR_1005: 1005,
  SURVEY_EXPIRED: 1031,
  ALREADY_EXIST: 1032,
  ERROR_1025: 1025,
  USER_NOT_AUTHORISED: 1002,
  UNABLE_TO_AUTHENTICATE_REQUEST: 1033,
  UNAUTHORIZED: 401
};

/**
 * Constant for bookmarking Post
 */
export const BOOKMARK_ACTION = {
  ADD: 'add',
  DELETE: 'delete'
};
/**
 * Comment list type.
 */
export const COMMENT_LIST_TYPE = {
  TOP: '2'    // When it is 2 then new commnet come from top of the list.
};

/**
 * Display content visibility.
 */
export const CONTENT_VISIBILITY = {
  VISIBLE: 1,     //  for visible content.
  MASKING: 0,     //  for masking.
  SUBMITTED: 2    //  for the user's document is submitted but not verified.
};

/**
 * Streaming type for the agora webinar.
 */
export const STREAMING_TYPE = {
  OBS: 'obs',
  SPEAKER: 'speaker'
};

/**
 * Screen sharing flag for agora.
 */
export const SCREEN_SHARING = {
  ON: '1',
  OFF: '0'
};

/**
 * Chunks sizes.
 */
export const CHUNKS_DIGITS = {
  FOUR: 1024,
  FIVE: 10024,
  SIX: 100024,
  SEVEN: 1000024
};

/**
 * Files Extensions.
 */
export const FILES_EXTENSIONS = {
  MP4: 'mp4',
  PDF: 'pdf',
  MKV: 'mkv',
  PNG: 'png',
  JPG: 'jpg'
};

/**
 * Chunk status.
 */
export const CHUNK_STATUS = {
  ONE: '1',
  ZERO: '0'
};

/**
 * Post Progress bar status.
 */
export const PROGRESS_STATUS = {
  FAILED: 'failed',
  PROCESS: 'in_process',
  COMPLETED: 'completed'
};

/**
 * Post Progress bar width.
 */
export const PROGRESS_WIDTH = {
  ONE: 1,
  ZERO: 0
};

/**
 * Media Dimensions.
 */
export const MEDIA_DIMENSIONS = {
  HEIGHT: 1233,
  RESOLUTION: 2222,
  WIDTH: 3222
};

/**
 * Members list type.
 */
export const MEMBERS_LIST_TYPE = {
  CONNECTION: 'connection',
  SUGGESTION: 'suggestion'
};

/**
 * Suggestion list required or not.
 */
export const IS_SUGGESTION = {
  YES: 1,
  NO: 0
};

/**
 * Breadcrumb paths.
 */
export const BREADCRUMB_PATHS = {
  HOME: '/web/list',
  COMMUNITY_LIST: '/community/list',
  COMMUNITY_PAST_LIST: '/community/past-list'
};

/**
 * Community privacy type.
 */
export const COMMUNITY_PRIVACY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  DEFAULT: 'default'
};

/**
 * Community list type.
 */
export const COMMUNITY_LIST_TYPE = {
  ALL_COMMUNITY: 'ALL_COMMUNITY',
  MY_COMMUNITY: 'MY_COMMUNITY',
  OTHER_COMMUNITY: 'OTHER_COMMUNITY',
  MY_ALL_COMMUNITY: 'MY_ALL_COMMUNITY',
  PAST_AND_INACTIVE_COMMUNITY: 'PAST_AND_INACTIVE_COMMUNITY',
  INACTIVE_COMMUNITY: 'INACTIVE_COMMUNITY',
  PAST_COMMUNITY: 'PAST_COMMUNITY',
  PUBLIC_COMMUNITY: 'PUBLIC_COMMUNITY'
};

/**
 * Community user type.
 */
export const COMMUNITY_USER_TYPE = {
  ADMIN: 'admin',
  MEMBER: 'member',
  CREATOR: 'creator'
};

/**
 * Community join type.
 */
export const COMMUNITY_JOIN_TYPE = {
  AUTOMATIC: 'automatic',
  APPROVAL: 'approval',
};

/**
 * Based on the identifiers calling the listing API's.
 */
export const DYNAMIC_FORM_IDENTIFIERS = {
  CODE: 'mobile'
};

/**
 * Regex expression for the mobile validation.
 */
export const MOBILE_VALIDATION_REGEX = /^\d+$/g;

/**
 * Type of consent.
 */
export const CONSENT_TYPE = {
  EXPLICIT_CONSENT: 'explicit_consent',
  IMPLICIT_CONSENT: 'implicit_consent'
};

/**
 * When what type of consent is to show.
 */
export const SHOW_CONSENT_CHECKBOX = {
  HIDE: 0,
  IMPLICIT: 1,
  EXPLICIT: 2
};

/**
 * Consent is giving or not.
 */
export const IS_CONSENT_GIVEN = {
  NO: 0,
  YES: 1
};

/**
 * Only Label for Consent
 */
export const ONLY_LABEL_CONSENT = {
  FALSE: 0,
  TRUE: 1
};

/**
 * Consent stage flags for webinar.
 */
export const CONSENT_STAGE = {
  LIVE: 'live',
  UPCOMING: 'upcoming',
  PAST: 'past',
  END: 'end'
};

/**
 * Shaka Player State
 */
export const PLAYER_STAGE = {
  PLAY: 'play',
  PAUSE: 'pause',
  SEEKING: 'seeking'

};
/**
 * Webinar Consent Popup Visibility Flag
 */
export const WEBINAR_CONSENT_POPUP_OBJECT = {
  SHOW_WEBINAR_CONSENT_POPUP_KEY: 'showwebinarconsentpopup',
  SHOW_WEBINAR_CONSENT_POPUP_TRUE: true,
};
/**
 * DocTalks video ended flag.
 */
export const DOC_TALK_ENDED = {
  YES: '1',
  NO: '0'
};
/*
 * Constant to convert the megabyte to bytes.
 */
export const CONVERT_TO_BYTES_DECIMAL = (1000 * 1000);

/**
 * Global search view all limit.
 */
export const GS_VIEW_ALL_MAX_LIMIT = 2;

/**
 * NCP Product card type.
 */
export const NCP_SUB_PRODUCT_CARD_TYPE = {
  SINGLE: 'single',
  MULTI: 'multi'
};

/**
 * Game rank constant.
 */
export const GAME_RANK = {
  RANK1: 1,
  RANK2: 2,
  RANK3: 3
};

/**
 * NCP PRODUCT subtypes.
 */
export const NCP_PRODUCT_SUBTYPE = {
  ACADEMY: 'academy',
  CHALLENGES: 'challenges',
  GAMES: 'games',
  HEALTH_CARD: 'healthcard',
  PAP_LISTING: 'pap_listing'
};

/***
 * Association View All Limit
*/
export const ASSOCIATION_VIEW_ALL_LIMIT = 10;
/**
 * Community Detail Action Tabs
 */
export const COMMUNITY_DETAIL_ACTION_TAB = {
  POSTS: 'posts',
  MEMBERS: 'members',
  JOIN_REQUESTS: 'join_requests'
};
/**
 * File Size Validation For Community Profile Image
 */
export const COMMUNITY_PROFILE_IMAGE_FILE_SIZE = 5000000;
/**
 * Community Members Action
 */
export const COMMUNITY_MEMBERS_ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
  PENDING: 'pending'
};
/**
 * Community Posted By Default Action
 */
export const COMMUNITY_POSTED_CONTENT_TYPE_ACTION = {
  ALL: 'ALL',
  POSTED_BY: 'posted_by',
  CONTENT_TYPE: 'content_type',
  COMMUNITY_POSTED_BY: 'community_posted_by',
  COMMUNITY_CONTENT_TYPE: 'community_content_type',
};
/**
 * Constant for new keyword
 */
export const NEW_TAG = {
  NEW: 'New!'
};
/**
 * Branch io identifier.
 */
export const BRANCH_IDENTIFIER = {
  DOCTALKS: 'doctalks',
  COMMUNITY_LIST: 'community',
  COMMUNITY_CREATE: 'community_create',
  USER: 'user',
  CONNECTION: 'connection',
  COMMUNITY_REQUEST: 'community_requests',
  COMMUNITY_POSTS: 'post_details',
  COMMUNITY_MEMBERS: 'community_members',
  COMMUNITY_LISTING: 'listing',
  PROFILE: 'profile',
  CONNECTION_HOME: 'connectionhome',
  PROFILE_CONNECT: 'profile_connect',
  CME: 'cme',
  WEBINAR: 'webinar',
  JOURNAL: 'journal',
  OFFLINE_EVENT: 'event',
  ONLINE_EVENT: 'online_event',
  PHARMA: 'pharma',
  DRUG: 'drug',
  FEED: 'feed',
  CHANNEL: 'channel',
  CONNECTION_LIST: 'connectionlist',
  CONNECTION_SENT: 'connectionsent',
  DOCTALK_SERIES: 'doctalkseries',
  DOCTALK: 'doctalk',
  GR_HOME: 'gr_home',
  WEBINAR_LIVE_LIST: 'webinar_live_list',
  UPCOMING_WEBINAR_LIST: 'UPCOMING_WEBINAR_LIST',
  USER_STORY: 'user_story',
  SETTINGS: 'settings',
  PROFILE_MEDIA: '',
  PROFILE_ACTIVITY: '',
  PROFILE_ADD_INTEREST: 'profileaddinterest',
  PROFILE_ADD_CERTIFICATE: 'profileaddcertificate',
  PROFILE_ADD_EDUCATION: 'profileaddeducation',
  PROFILE_ADD_EXP: 'profileaddexp',
  PROFILE_SUMMARY: 'profilesummary',
  PROFILE_ADD_PUBLICATION: 'profileaddpublication',
  BOOKMARK: 'bookmark',
  VAULT: 'vault',
  REWARDS: 'rewards',
  CALENDAR: 'calendar',
  PROFILE_INFO: 'profileinfo',
  PROFILE_POSTS: 'profileposts',
  PROFILE_COLLECTION: 'profilecollection',
  CERTIFICATE_DETAIL: 'certificatedetail',
  INVITE_CODE: 'invite_code_redirection',
  SPECIALITY: 'speciality',

};
/**
 * Community type.
 */
export const COMMUNITY_TYPE = {
  PAST: 1,
};
/**
 * Image Files Type.
 */
export const IMAGE_FILES_TYPE = {
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
};
/**
 * file type for keeper uploads
 */
export const FILE_UPLOAD_FILE_TYPE = {
  LOGO: 'LOGO',
  IDCARD: 'IDCARD',
  VIDEO: 'VIDEO',
}
/**
 * mime types
 */
export const MIME_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  JPG: 'image/jpg',
  MP4: 'video/mp4',
  WEBM: 'video/webm',
  PDF: 'application/pdf',
  GIF: 'image/gif',
  CSV: 'text/csv',
  SVG: 'image/svg+xml'
};
/**
 * access level
 */
export const ACCESS_LEVEL = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
}
/**
 * Image Files Extension.
 */
export const IMAGE_FILES_EXTENSIONS = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
};
/**
 * Banner Identifiers
 */
export const BANNER_IDENTIFIERS = {
  NCP_SUBTYPE: 'ncp_subtype_webview_open',
  GAME_DETAIL: 'game_detail',
  NCP_DETAIL: 'ncp_detail',
  COMMUNITY_LIST: 'community',
  COMMUNITY_CREATE: 'community_create',
  EXTERNAL_LINK: 'externalLink',

};
/**
 * Share link is allowed or not.
 */
export const is_sharelink_applicable = {
  OFF: '0'
};

/**
 * Sponsors regex string.
 */
export const SPONSORS_EXPRESSION = {
  // eslint-disable-next-line no-useless-escape
  WEBSITE: '^https?:\/\/.*$',
  HTTP: 'http://',
  VARIABLES: 'gm'
};

/**
 * Location for the sqs events.
 */
export const LOCATION = {
  LATITUDE: '0',
  LONGITUDE: '0',
};

/**
 * AWS sqs information.
 */
export const AWS_SQS = {
  REGION: 'ap-southeast-1',
  API_VERSION: '2012-11-05',
  DELAY_SECONDS: 0
};

/**
 * Agora controls information.
 */
export const AGORA_CONTROLS = {
  CHAT: { name: 'chat', url: 'assets/images/chatIcon.svg' }
};

/**
 * Agora webinar status.
 */
export const WEBINAR_AGORA_STATUS = {
  START: 'start',
  END: 'end',
  LIVE: 'live'
};

/**
 * Poll agora status.
 */
export const POLL_AGORA_STATUS = {
  LIVE: 'live',
  END: 'end'
};

/**
 * MiniPlayer controls hide duration.
 */
export const MINI_PLAYER_CONTROLS_DURATION = 3000;

/**
 * Webinar agora role.
 */
export const WEBINAR_AGORA_ROLE = 'audience';

/**
 * Community status.
 */
export const COMMUNITY_STATUS = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
};

export const NUDGE = {
  CONSTANT: 1,
  ACTION: 'action'
};

/**
 * Community Join Accept/Reject
 */
export const COMMUNITY_JOIN_ACTION = {
  ACCEPT: 'accept',
  REJECT: 'reject'
};

/**
 * Community Detail Page Tab Filter Text
 */
export const COMMUNITY_DETAIL_PAGE_TAB_FILTER_TEXT = {
  POSTS: 'posts',
  JOIN_REQUESTS: 'join_requests',
  MEMBERS: 'members'
};

/**
  * User Name Regex
  */
export const USER_NAME_REGX = {
  user_name: /user_name/gi
};

/**
 * Survey Remark.
 */
export const SURVEY_REMARKS = {
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  NOT_ATTEMPTED: 'not_attempted'
};

/**
 * Nudge Identifiers
 */
export const NUDGE_IDENTIFIERS = {
  NCP_SUBTYPE: 'ncp_subtype_webview_open',
  GAME_DETAIL: 'game_detail',
  NCP_DETAIL: 'ncp_detail'
};

/**
 * Show the new tag on controls of the player.
 */
export const SHOW_NEW_TAG = {
  PIP: 'pip'
};

/**
 * Active step of the tutorial.
 */
export const ACTIVE_STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3
};

/**
 * Set the tutorial is visible to user flag in local storage.
 */
export const TUTORIAL_LOCAL_STORAGE = {
  KEY: 'isTutorialComplete',
  VALUE: '1'
};

/**
 * Set the tutorial is new tag on pip control in local storage.
 * How many time user will see the new tag limit.
 */
export const PIP_TAG_LOCAL_STORAGE = 'pipNewTagCount';
export const PIP_TAG_LIMIT = 3;

/**
 * Set the tutorial is visible to user flag in local storage.
 */
export const ARTICLE_TUTORIAL_LOCAL_STORAGE = {
  KEY: 'isAudioTutorialPopoverOpen',
  VALUE: '1'
};

/**
 * Player Status.
 */
export const PLAYER_STATUS = {
  PLAY: 'play',
  PAUSE: 'pause',
  ENDED: 'ended'
};

/**
 * Reusable components.
 */
export const REUSABLE_COMPONENTS = {
  DQ_VIDEO_PLAYER: 'dqVideoPlayer',
  DQ_AUDIO_PLAYER: 'dqAudioPlayer',
  DQ_CHAT_PANEL: 'dqChatPanel',
};

/**
 * Tutorial for which module.
 */
export const TUTORIAL_FOR = {
  ARTICLE: 'article',
  PIP: 'pip',
  TAG_CONNECTION: 'tag_connection'
};

/**
 * Shaka player configuration seek bar color for audio
 */
export const AUDIO_SEEK_BARS_COLOR = {
  BASE: 'rgba(115, 116, 117, 1)',
  BUFFERED: 'rgba(115, 116, 117, 1)',
  PLAYED: 'rgb(238 59 76)'
};

/**
 * Shaka controls fade away time.
 */
export const AUDIO_FADE_DELAY = 99999;

/**
 * Status for the text to speech api
 */
export const TTS_STATUS = {
  ENABLE: '1',
  DISABLE: '0'
};

/**
 * Key available for local storage.
 */
export const LOCAL_STORAGE = {
  SUCCESS_SCREEN: 'successScreen',
  TOKEN_DETAIL : 'mcTokenDetail'
};
/**
 * User types.
 */
export const USER_TYPES = {
  STUDENT: 'student',
  DOCTOR: 'doctor'
};
/**
 * Single or multiple types.
 */
export const COUNTING_RECORDS = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};

/**
 * Is user coming from deeplink.
 */
export const ISFROMDEEPLINK = {
  TRUE: '1',
};

/**
 * For Scroll type
 */
export const SCROLL_TYPE = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

/**
 * For different breakpoints
 */
export const BREAKPOINTS = {
  XXSMALL: '(max-width: 389px)',
  XSMALL: '(min-width: 390px) and (max-width: 436px)',
  SMALL: '(min-width: 437px) and (max-width: 768px)',
  MEDIUM: '(min-width: 769px) and (max-width: 991px)',
  LARGE: '(min-width: 992px)'
};
export const BREAKPOINTS_WIDTH = {
  MEDIUM: 992
}
/**
 * For Carousel on Grandround
 */
export const CAROUSEL = {
  PUBLIC_COMMUNITY_CAROUSEL: 'public_community_carousel',
  GRANDROUND: 'gr',
  TYPE: 'public_community_carousel',
  VIEW_ALL: 'all',
  MY_COMMUNITY_CAROUSEL: 'my_community_carousel'
};

/**
 * Action on comment add and delete.
 */
export const COMMENT_ACTION = {
  ADD: 'add',
  DEL: 'del',
  REMOVE: 'remove'
};

/**
 * Partial Verified User State
 */
export const PARTIAL_VERIFIED_USER_STATE = {
  TWO: '2'
};

/**
 * webinar poll status.
 */
export const WEBINAR_POLL_STATUS = {
  LIVE: 'live',
  END: 'end'
};

export const COMMUNITY_REGEX = {
  COUNT: /actual_count/gi,
  USER_NAME: /user_name/gi
};

export const PROTOCOL = {
  HTTP: 'http',
  HTTPS: 'https',
  WWW: 'www'
};
/**
 * Repost status
 */
export const REPOST_STATUS = {
  ZERO: 0, // Repost not intiated
  ONE: 1,  // Repost enable
  TWO: 2   // repost enable and disable other feature for create post.
};
/**
 * Post type
 */
export const POST_TYPE = {
  POLL: 'poll',
  POST: 'post',
  REPOST: 'repost'
};
/**
 * Set the repost info card is visible to user flag in local storage.
 */
export const REPOST_INFO_LOCAL_STORAGE = {
  KEY: 'isRepostInfoPopoverOpen',
  VALUE: '1'
};

/**
 * Templates for the HCF
 */
export const HCF_TEMPLATES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4
};
/**
 * connection id for HCF
 */
export const HCF_CONNECTION_ID = {
  CONTINUE_WATCH: 8
};

/**
 * Status for the text to speech api
 */
export const HCF_TEMPLATE_STATUS = {
  ENABLE: '1',
  DISABLE: '0'
};



/**
 * Shaka player class name constants.
 */
export const SHAKA_PLAYER_CLASS_NAME = {
  PLAY_BUTTON: 'shaka-play-button-container',
  SMALL_PLAY_BUTTON: 'shaka-small-play-button',
  LIVE: 'live',
  LIVE_CIRCLE: 'live-circle-hls',
  LIVE_POLL_BUTTON: 'shaka-live-poll-button',
  FULLSCREEN_BUTTON: 'shaka-fullscreen-button',
  NO_WHITE_BG_COLOR: 'no-white-bg-color',
  SHAKA_HIDDEN: 'shaka-hidden',
  SHAKA_TOOLTIP: 'shaka-tooltip',
  POLL_IMAGE: 'poll-img'
};

/**
 * Bootstrap classes for Shaka player.
 */
export const BOOTSTRAP_CLASS_NAME = {
  DISPLAY_NONE: 'd-none',
  DISPLAY_BLOCK: 'd-block',
  POSITION_RELATIVE: 'position-relative'
};

/**
 * Reuse route for the id change issue.
 */
export const ReuseRoute = {
  DETAIL: 'detail'
};
/**
 * poll question text limit.
 */
export const POLL_QUESTION_TEXT_LIMIT = 50;
/**
 * Certificate type.
 */
export const CERTIFICATE_TYPE = {
  OFFLINE: 'offline',
  ONLINE: 'online'
};
/**
  * User Tag Regex
  */
export const USER_TAG_REGX = {
  tag_char_regex: /^@/i,
  removeBracesRegex: /[^{}]+(?=})/g,
  tag_char: '@',
  search_char_regex: /@/g
};

/**
 * Type of no story.
 * 0 for the whole info list
 * 1 for the single view detail
 * 2 for the thumbnail.
 */
export const NO_INFO_TYPE = {
  LIST: 0,
  SINGLE: 1,
  SINGLE_THUMBNAIL: 2,
};
// TODO: Rahul: To get colour from colour palet;
export const STORY_BACKGROUND_CODES = [
  {src: 'story_bg01', gradient: '0deg, #E0FF9C 0%, #38D5E0 100%'},
  {src: 'story_bg02', gradient: '0deg, #EEFF85 0%, #6EF9FF 51%, #8482EE 100%'},
  {src:'story_bg03',  gradient:'0deg, #8A8BE5 0%, #6CE8D3 100%'},
  {src: 'story_bg04', gradient:'0deg, #FFB17D 0%, #F6B684 8%, #DEC499 22%, #B8DCBB 38%, #99F0D7 50%, #009994 100%'},
  {src: 'story_bg05', gradient: '0deg, #6DE6CF 0%, #EEC7FF 51%, #735AF7 100%'},
  {src: 'story_bg06', gradient: '0deg, #7F84DE 0%, #F682BC 37%, #FFC494 100%'},
  {src: 'story_bg07', gradient: '0deg, #F5A2EB 0%, #EFA2EB 13%, #E0A2ED 28%, #C7A2F0 45%, #A5A2F4 63%, #79A2F9 81%, #45A2FF 100%'},
  {src: 'story_bg08', gradient: '0deg, #14253D 0%, #26557C 40%, #00BFB2 100%'},
  {src: 'story_bg09', gradient: '0deg, #AB1515 0%, #B01A19 1%, #CD362E 7%, #E34B3F 14%, #F25B4A 21%, #FC6451 30%, #FF6754 42%, #FFC061 100%'},
  {src: 'story_bg10', gradient: '0deg, #FFDA07 0%, #FF872B 19%, #E81C1C 49%, #D70E56 59%, #C90487 68%, #C4009A 73%, #A111A4 79%, #7625B1 88%, #5C32B9 96%, #5337BD 100%'},
];

/**
 * Highlighter sizes.
 */
export const THUMBNAIL_HIGHLIGHTER_SIZE = {
  SMALL: 32,
  MEDIUM: 46
};

/**
 * Which type of stories.
 */
export const STORY_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  COMPONENT: 'component',
  TEMPLATE: 'TEMPLATE',
  PHARMA: 'PHARMA',
  PHARMAREPOST: 'PHARMAREPOST',
  GIF: 'GIF',
  REPOST: 'REPOST',
  CERTIFICATE: 'CERTIFICATE',
  STORY: 'STORY',
  TEXT: 'TEXT'
};
/**
 * Create story types
 */
export const CREATE_STORY_TYPES = {
  REPOST: 'REPOST',
  CERTIFICATE: 'CERTIFICATE',
  TEMPLATE: 'TEMPLATE',
  STORY: 'STORY',
  PHARMA: 'PHARMA',
  PHARMAREPOST: 'PHARMAREPOST'
};
/**
 * user story nudges
 */
export const USER_STORY_NUDGE = {
  is_story_cme_certificate_share_enabled: 'is_story_cme_certificate_share_enabled',
  is_story_first_like_enabled: 'is_story_first_like_enabled',
  is_story_introduction_enabled: 'is_story_introduction_enabled',
  is_story_post_success_enabled: 'is_story_post_success_enabled',
  is_story_tutorial_enabled: 'is_story_tutorial_enabled',
  is_story_webinar_register_enabled: 'is_story_webinar_register_enabled',
  story_tutorial: 'story_tutorial',
  story_self_intro: 'story_self_intro',
  story_like: 'story_like'
};
/***
 * entity type
 */
export const STORY = {
  STORY : 'STORY',
  TEMPLATE: 'TEMPLATE'

};
/**
 * CTA Types
 */
export const CTA_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TAB: 'TAB',
  BUTTON: 'BUTTON',
  ICON: 'ICON',
  ENGAGEMENT_BAR: 'ENGAGEMENT_BAR',
  TEXT: 'TEXT',
  TOP_BAR: 'TOP_BAR',
  TEXT_INPUT_AREA:'TEXT_INPUT_AREA',
  MEDIA_PLAYER:'MEDIA_PLAYER',
  CARD: 'CARD',
  OPTIONS: 'OPTIONS',
  MEDIA: 'MEDIA',
  CLICK: 'CLICK',
  MORE_OPTION: 'MORE_OPTION',
  OPTION: 'OPTION',
  SUB_CARD: 'SUB_CARD',
  CHECKBOX:'CHECKBOX',
  TOGGLE: 'TOGGLE',
  FLOATING_BUTTON: 'FLOATING_BUTTON',
  BAR: 'BAR',
  OPTION_BAR: 'OPTION_BAR'
};
/**
 * cta action
 */
export const CTA_TYPE_ACTION = {
  DEEPLINK: 'DEEPLINK',
  REPOST: 'REPOST',
  TEMPLATE: 'TEMPLATE'
};
/**
 * story posting
 */
export const REPOST_TYPE = {

};
/**
 * user story creation type
 */
export const STORY_CREATION_TYPE = {
  CREATE_STORY: 'CREATE_STORY',
  REPOST_STORY: 'REPOST_STORY',
  TEMPLATE: 'TEMPLATE'
};
export const POSTING_TYPE = {
  repost: 'repost'
};
/**
 * reaction constants
 */
export const REACTION_TYPE = {
  LIKE: 'LIKE',
  LOVE: 'LOVE',
  CELEBRATE: 'CELEBRATE',
  THINK: 'THINK',
  SUPPORT: 'SUPPORT'
};

/**
 * Header actions for the story header dropdown.
 */
export const STORY_HEADER_ACTIONS = {
  DELETE: 0,
  CANCEL: 1,
  REPORT: 2
};

/**
 * Default running duration of particular story
 */
export const STORY_DEFAULT_DURATION = 7000;

/**
 * All story end actions item.
 */
export const ALL_STORIES_END = {
  PREVIOUS: 0,
  NEXT: 1
};
/**
 * story loading state
 */
export const STORY_LOADING_STATE = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAIL: 'FAIL'
};

export const STORY_DELETE_PROCESS ={
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  IN_PROGRESS: 'IN_PROGRESS'
};

/**
 * Template types.
 */
export const TEMPLATE_TYPE = {
  BACKGROUND: 0,
  TEMPLATE: 1,
  M_WEB: 2
};

/**
 * Story creation options svg's.
 */
export const STORY_CREATION_OPTIONS_SVG = {
  ADD_TEXT: 'assets/images/add-text.svg',
  ADD_TAG: 'assets/images/tag-user.svg',
};

/**
 * Story Creation options.
 */
export const STORY_CREATION_OPTIONS = {
  ADD_TEXT: 0,
  ADD_TAG: 1,
};

/**
 * Creation text type.
 */
export const CREATION_TEXT_TYPE = {
  WEB: 0,
  M_WEB: 1,
};
export const READ_KEY = {
  ENTER: 'Enter',
  A: 'a'
};

export const MEMBERS_LIMIT = 10000;

export const POSTING_PROGRESS = {
  IN_PROGRESS: 'in_process'
};

/**
 * Max unread count and text constant.
 */
export const MAX_UNREAD_COUNT = {
  TEXT: '99+',
  COUNT: 99
};

export const PIP_CLASS = 'pip-dec';

/**
 * Samvaad maintenance.
 */
export const SAMVAAD_MAINTENANCE = {
  NO: '0',
  YES: '1',
};

/**
 * Samvaad notification config.
 */
export const SAMVAAD_NOTIFICATION = {
  LIMIT: 3,
  MAX_SEQ: 99999999999999
};

/**
 * Index DB name for the samvaad.
 */
export const SAMVAAD_INDEX_DB = 'tinode-web';



export const REPOST_TYPE_ITEM = {
  REPOST_ON_FEED: 'REPOST_ON_FEED',
  REPOST_AS_STORY: 'REPOST_AS_STORY'
};
export const TOSTR_TIME = 4000;

export const MAPPED_PRODUCT_IN_COMMUNITY = [PRODUCT_TYPE_CONSTANT.FEED, PRODUCT_TYPE_CONSTANT.WEBINAR];
export const RATINGS_TYPE = {
  RADIO: 0,
  METER: 1
};

export const RATING_IS_ACTIVE = {
  ENABLE: 1,
  DISABLE: 0
};

export const RATING_LEVEL = {
  TWO: 2,
  THREE: 3,
  FIVE: 5,
  SEVEN: 7,
  TEN: 10
};

export const SURVEY_ATTEMPT_STATUS = {
  DISCARD: 0,
  SAVE: 1
};

export const QUESTION_TYPE = {
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  FILE: 'FILE',
  NUMBER: 'NUMBER',
  PHONE: 'PHONE',
  BUTTON: 'BUTTON',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  DATE_RANGE: 'DATE_RANGE',
  DATETIME_RANGE: 'DATETIME_RANGE',
  HIDDEN: 'HIDDEN',
  MONTH: 'MONTH',
  RANGE: 'RANGE',
  SEARCH: 'SEARCH',
  SUBMIT: 'SUBMIT',
  RESET: 'RESET',
  URL: 'URL',
  MCQS: 'MCQS',
  MCQM: 'MCQM',
  DROPDOWN: 'DROPDOWN',
  RANKING: 'RANKING',
  RATING: 'RATING',
  TIME: 'TIME',
  RATING_GRID: 'RATING_GRID',
  CONSTANT_SUM: 'CONSTANT_SUM',
  INPUT_GRID: 'INPUT_GRID'
};

export const OPTIONS_FORMAT = {
  TEXT: 'TEXT',
  FILE: 'FILE',
  NUMBER: 'NUMBER',
  ALPHANUMERIC: 'ALPHANUMERIC'
};

export const TEXT_VALUE_TYPE = {
  OPTION: 'OPTION',
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC',
  FILECODE: 'FILECODE',
  IG_OPTION: 'IG_OPTION'
};

export const RATING_TYPE = {
  NUMBER: 'number',
  CUSTOM: 'custom',
  STARS: 'stars',
  SMILEY: 'smiley',
  SATISFACTION: 'satisfaction',
  LIKELINESS: 'likeliness',
  AGREE: 'agree',
  THUMBS_UP: 'thumbs_up'
};
export const SURVEY_CONSENT = {
  MANDATORY: 'MANDATORY',
  OPTIONAL: 'OPTIONAL',
  NO: 'NO'
};

export const SURVEY_TYPE = {
  ALL: 'ALL',
  DRAFT: 'INPROGRESS',
  COMPLETED: 'COMPLETED'
};
export const GATEWAY_STATUS_CODE = {
  GATEWAY_1003: 'GATEWAY-1003', // Access token created
  GATEWAY_1004: 'GATEWAY-1004', //Unable to retrieve access token
  GATEWAY_1010: 'GATEWAY-1010', //Token is invalid
};

export const SURVEY_STATUS_CODE = {
  SUCCESS: 'S022000'
};

export const SURVEY_ERROR_CODE = {
  MAX_RESPONDENT: 'E027001',
  RESTRICTED_COTNENT: 'E027003',
  SURVEY_EXPIRED: 'E027004',
  YET_TO_LIVE: 'E027005',
  NOT_PUBLISHED: 'E027006',
  SURVEY_ENDED: 'E027008',
};

export const MODULE_NAME = {
  DQ: 'DQ',
  DC: 'DC',
  PAP: 'PAP',
  MR: 'MR',
  DOQTY: 'DOQTY',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};
export const COUNTRY_CONST = {
  IN: 'IN'
}

export const SURVEY_TAB_TYPE = {
  ALL: 'all',
  DRAFT: 'inprogress',
  COMPLETED: 'completed'
};

export const DEEPLINK_SURVEY = {
  SURVEY: 'survey',
  DFS: 'dfs'
};
/**
 * Used to set the method for the webview to mobile communication.
 */
export const WEBVIEW_MOBILE_METHOD_GET = 'GET';
export const WEBVIEW_MOBILE_METHOD_POST = 'POST';
export const WEBVIEW_MOBILE_METHOD_PUT = 'PUT';

export const CME_ACTION_POP_UP = {
  CLOSE : 'close',
  ALERT : 'alert',
  FAILED : 'failed',
  LINK : 'link',

};

/**
 * Used to set the params for the webview to mobile communication.
 */
export const WEBVIEW_MOBILE_PARAMS = {
  TOKEN: 'TOKEN',
  ANALYTICS: 'ANALYTICS',
  COMING_FROM: 'COMING_FROM',
  SURVEY_SPECIALITY_CLICK: 'SURVEY_SPECIALITY_CLICK',
  SURVEY_LISTING_BACK: 'SURVEY_LISTING_BACK',
  TOKEN_GENERATED: 'TOKEN_GENERATED',
  REFRESH_TOKEN_GENERATED: 'REFRESH_TOKEN_GENERATED',
  SHARE_CLICK: 'SHARE_CLICK',
  REDIRECT_CTA_CLICK: 'REDIRECT_CTA_CLICK',
  DEEPLINK_DATA: 'DEEPLINK_DATA',
  SPONSOR_REDIRECT: 'SPONSOR_REDIRECT',
  FILE_INPUT_TOUCHED: 'FILE_INPUT_TOUCHED',
  ALL_SURVEY_LISTING_SCREEN: 'ALL_SURVEY_LISTING_SCREEN'
};

export enum SURVEY_ACCESS_TYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export const MAX_UPLOAD_TRY = 3;

export const FILE_TYPES = {
  jpeg: 'jpeg',
  jpg: 'jpg',
  png: 'png',
  mp4: 'mp4',
  webm: 'webm',
  pdf: 'pdf',
  gif: 'gif'
};
export const DEVICES = {
  IOS: 'ios',
  ANDROID: 'android'
};

export enum SURVEY_DEEPLINK_ACTION {
  PREVIEW = 'preview',
  DETAIL = 'detail',
  SECTION = 'question_and_answer'
}

export const DEFAULT_COUNTRY_CODE = '91';
export const DEFAULT_IP_ADDR = '192.168.1.1';
export const DEFAULT_LANG = 'en';
export const DEFAULT_TIMEZONE = 'UTC';

export const INPUT_FILLED = 'INPUT';


export const PROFILE_NUDGE_FIELD_TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  TEXT_AREA: 'TEXT_AREA',
  FILE: 'FILE',
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_SEARCH: 'DROPDOWN_SEARCH',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  MONTH_YEAR: 'MONTH_YEAR',
  TIME: 'TIME',
  MEDIA: 'MEDIA',
  DROPDOWN_MULTISELECT: 'DROPDOWN_MULTISELECT',
  DROPDOWN_MULTISELECT_SEARCH: 'DROPDOWN_MULTISELECT_SEARCH',
  FLOATING_NUMBER: 'FLOATING_NUMBER'
};

export const PROFILE_NUDGE_TYPE = {
  PRACTICE_DETAILS: 'PRACTICE_DETAILS',
  RESIDENTIAL_ADDRESS: 'RESIDENTIAL_ADDRESS',
  PUBLICATION: 'PUBLICATION',
  UNPUBLISHED_WORK: 'UNPUBLISHED_WORK',
  PATENT: 'PATENT',
  THESIS: 'THESIS',
  VOLUNTARY_SERVICE: 'VOLUNTARY_SERVICE',
  SEMINAR: 'SEMINAR',
  WEBINAR: 'WEBINAR',
  WORKSHOP: 'WORKSHOP',
  CERTIFICATION: 'CERTIFICATION',
  CONFERENCE: 'CONFERENCE',
  PAPER_PRESENTATION: 'PAPER_PRESENTATION',
  POSITION_OF_RESPONSIBILITY: 'POSITION_OF_RESPONSIBILITY',
  CIVIL_AWARDS: 'CIVIL_AWARDS',
  CONSULTATION_DETAILS: 'CONSULTATION_DETAILS',
  TRAININGS_IMPARTED: 'TRAININGS_IMPARTED',
  TRAININGS_RECEIVED: 'TRAININGS_RECEIVED',
  MEDICAL_AWARDS: 'MEDICAL_AWARDS',
  EXPERIENCE: 'EXPERIENCE',
  EDUCATION: 'EDUCATION',
  BLOG: 'BLOG',
};
export const PROFILE_FIELD_CONSTANT = {
  EXPIRIENCE : {
    END_DATE: 'endDate',
    START_DATE: 'startDate'
  },
  CERTIFICATION: {
    EXPIRATION_DATE: 'expirationDate',
    ISSUE_DATE: 'issueDate'
  },
  SEMINAR: {
    END_DATE_TIME: 'endDateTime',
    START_DATE_TIME: 'startDateTime'
  },
  PRACTICE_DETAILS: {
    START_TIME: 'startTime',
    END_TIME: 'endTime'
  },
  THESIS: {
    date: 'date'
  },
  WORKSHOP: {
    title: 'title'
  },
  CIVIL_AWARDS: {
    date: 'date'
  },
  PATENT: {
    date: 'date'
  },
  VOLUNTARY_SERVICE: {
    START_DATE: 'startDate',
    END_DATE: 'endDate'
  },
  EDUCATION: {
    END_DATE: 'endDate',
  }, 
  POSTION_OF_RESPONSIBILITY: {
    END_DATE: 'endDate'
  }
}
export const PROFILE_NUDGE_PARAMETERS = {
  EXPERIENCE: 'EXPERIENCE',
  EDUCATION: 'EDUCATION',
  POSITION_OF_RESPONSIBILITY: 'POSITION_OF_RESPONSIBILITY',
  VOLUNTARY_SERVICE: 'VOLUNTARY_SERVICE',
  CERTIFICATION: 'CERTIFICATION' ,
  PATENT: 'PATENT',
  CIVIL_AWARDS: 'CIVIL_AWARDS',
  THESIS: 'THESIS',
  SEMINAR: 'SEMINAR',
  WORKSHOP: 'WORKSHOP'
}
export const INPUT_FILE = 'FILE';

export const FILE_TYPE_EXTENSIONS = {
  MP4: '.mp4',
  PNG: '.png',
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif',
  SVG: '.svg',
  PDF: '.pdf',
  PPT: '.ppt',
  DOCX: '.docx',
  DOC: '.doc'
}

export const FILE_TYPE_ACCESS = {
  VIDEO_MP4: 'video/mp4',
  IMAGE_PNG: 'image/png',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_GIF: 'image/gif',
  IMAGE_SVG: 'image/svg+xml',
  APP_PDF: 'application/pdf',
  APP_PPT: 'application/vnd.ms-powerpoint',
  APP_DOC: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const hrefRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/i;
export const META_PREVIEW_TYPE = {
  FEED : 'feed'
};

export const CANONICAL_DOMAIN_REGEX = /^(https?:\/\/)?(?:www\.)?([^/?#]+)\.com(?:[/?#]|$)/i;

export const USER_ROLE = {
  HCP: 'hcp',
  BR: 'br',
  OTHERS : 'other'

};

export const OPEN_ACCESS = {
  prodListRenderType : {
    product_list: 'list',
    product_sugg_list: 'suggestion'
  },
  productlist: {
    article: 3,
    webinar: 27,
    doctalk: 24,
    series: 36,
    community: 47,
  }
}

export const COUNRY_CODE = {
  BRAZIL : 97,
  MEXICO : 111
}
/**
 * Staging
 */
export const LOGIN_SDK = {
  COUNTRY : 'MX',
  MODULE : 'DQ',
  VERSION: "Mex1.0",
  CLIENT : 'J8TOKPYUVN',
  DEFAULT_ACTION: true ,
  DISABLE_CLOSE : false
}

export const TRIGGER_SOURCE = {
  ORGANIC: 'organic',
  DEEPLINK: 'deeplink',
  NOTIFICATION: 'notification'
}

export const ACTION_TYPE = {
  CLICK: 'CLICK',
  SWIPE: 'SWIPE',
  DETAIL_ENTRY:'DETAIL_ENTRY',
  DETAIL_EXIT:'DETAIL_EXIT',
  LIKE:'LIKE'
}

export const TRANSACTION_TYPE = {
  TTS_TOOLTIP: 'TTS_TOOLTIP',
  TTS_PLAYER: 'TTS_PLAYER',
  GROUP_CREATION: 'GROUP_CREATION',
  PIP_WALKTHROUGH: 'PIP_WALKTHROUGH',
  STORY_CREATED: 'STORY_CREATED',
  STORY_CREATION: 'STORY_CREATION',
  COMMUNITY_SETTINGS_POPUP: 'COMMUNITY_SETTINGS_POPUP',
  FEED_POST_CREATED: 'FEED_POST_CREATED',
  CREATE_FEED_POST: 'CREATE_FEED_POST',
  SEARCH: 'SEARCH'
}

export const ACTION_ON = {
  POLL: 'POLL',
  POLL_ATTEMPT: 'POLL_ATTEMPT',
  POLL_CLOSE: 'POLL_CLOSE',
  VIDEO_FULLSCREEN: 'VIDEO_FULLSCREEN',
  VIDEO_MINIMIZE: 'VIDEO_MINIMIZE',
  SPECIALITY_HASTAGS: 'SPECIALITY_HASTAGS',
  BOOKMARK: 'BOOKMARK',
  VIEW_WEBINAR_DETAILS: 'VIEW_WEBINAR_DETAILS',
  VIEW_COMMENTS: 'VIEW_COMMENTS',
  SHARE:'SHARE',
  COMMENT_POSTED:'COMMENT_POSTED',
  COMMENT:'COMMENT',
  VIEW_ALL: 'VIEW_ALL',
  TOP_SPEKAER: 'TOP_SPEKAER',
  SPECIALITY: 'SPECIALITY',
  DOCTALKS: 'DOCTALKS',
  DOCTALKS_SERIES: 'DOCTALKS_SERIES',
  WEBINAR: 'WEBINAR',
  ARTICLE: 'ARTICLE',
  REPOST: 'REPOST',
  LIKE: 'LIKE',
  REGISTER: 'REGISTER',
  EXTRA_BUTTON: 'EXTRA_BUTTON',
  VIEW_MORE: 'VIEW_MORE',
  FEED: 'FEED',
  POLL_ANSWER: 'POLL_ANSWER',
  DOCUMENT: 'DOCUMENT',
  VIDEO: 'VIDEO',
  LEFT_SWIPE: 'LEFT_SWIPE',
  RIGHT_SWIPE: 'RIGHT_SWIPE',
  IMAGE: 'IMAGE',
  VIDEO_PLAY: 'VIDEO_PLAY',
  VIDEO_PAUSE: 'VIDEO_PAUSE',
  AUDIO_PLAY: 'AUDIO_PLAY',
  AUDIO_PAUSE: 'AUDIO_PAUSE',
  EDIT_POST: 'EDIT_POST',
  DELETE_POST: 'DELETE_POST',
  REPORT: 'REPORT',
  FILTERS:'FILTERS',
  SPECIALITY_FILTER:'SPECIALITY_FILTER',
  APPLY:'APPLY',
  CLEAR_FILTER:'CLEAR_FILTER',
  TTS_START:'TTS_START',
  TTS_PLAYER_CLOSE:'TTS_PLAYER_CLOSE',
  TTS_PLAYER_PLAY:'TTS_PLAYER_PLAY',
  TTS_PLAYER_PAUSE:'TTS_PLAYER_PAUSE',
  PROPERTY_TABS:'PROPERTY_TABS',
  REFERENCE: 'REFERENCE',
  SUGGESTED_CONTENT: 'SUGGESTED_CONTENT',
  ALL: 'ALL',
  LIVE: 'LIVE',
  INSIGHT_COLLECTOR: 'INSIGHT_COLLECTOR',
  PROFILE_ICON: 'PROFILE_ICON',
  CHAT: 'CHAT',
  START_MESSAGING: 'START_MESSAGING',
  SEND_MESSAGE: 'SEND_MESSAGE',
  CREATE_GROUP_CHAT: 'CREATE_GROUP_CHAT',
  DONE: 'DONE',
  GROUP_CREATION: 'GROUP_CREATION',
  CREATE_PERSONAL_CHAT: 'CREATE_PERSONAL_CHAT',
  ATTACH_MEDIA: 'ATTACH_MEDIA',
  UNMUTE_INBOX_NOTIF: 'UNMUTE_INBOX_NOTIF',
  MUTE_INBOX_NOTIF: 'MUTE_INBOX_NOTIF',
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  EXIT_GROUP: 'EXIT_GROUP',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  ADD_MEMBER: 'ADD_MEMBER',
  EXPAND_VIEW: 'EXPAND_VIEW',
  SEARCH_TOPIC: 'SEARCH_TOPIC',
  VIEW_TOPIC_INFO: 'VIEW_TOPIC_INFO',
  USER_CHAT: 'USER_CHAT',
  CONNECTION_REQUEST_ACCEPT: 'CONNECTION_REQUEST_ACCEPT',
  REQUEST_REJECT_CLICK: 'REQUEST_REJECT_CLICK',
  CONNECTION_REQUEST_SENT: 'CONNECTION_REQUEST_SENT',
  CONNECTION_WITHDRAW_CLICK: 'CONNECTION_WITHDRAW_CLICK',
  REMOVE_CONNECTION: 'REMOVE_CONNECTION',
  FILTER_CLICK: 'FILTER_CLICK',
  PIP_WALKTHROUGH_DONE: 'PIP_WALKTHROUGH_DONE',
  PIP_WALKTHROUGH_SKIP: 'PIP_WALKTHROUGH_SKIP',
  PIP_WALKTHROUGH_CLOSE: 'PIP_WALKTHROUGH_CLOSE',
  PIP_WALKTHROUGH_NEXT: 'PIP_WALKTHROUGH_NEXT',
  PIP_FULLSCREEN_CONVERTER: 'PIP_FULLSCREEN_CONVERTER',
  PIP_CLOSE: 'PIP_CLOSE',
  PIP_PLAY: 'PIP_PLAY',
  PIP_PAUSE: 'PIP_PAUSE',
  NOTIFICATION_BELL: 'NOTIFICATION_BELL',
  NOTIFICATION: 'NOTIFICATION',
  BACK_GROUND_NOTIFICATION: 'BACK_GROUND_NOTIFICATION',
  HAMBURGER_MENU: 'HAMBURGER_MENU',
  SEND_SUPPORT_FEEDBACK: 'SEND_SUPPORT_FEEDBACK',
  DQ_TnC: 'DQ_TnC',
  APP_SETTINGS: 'APP_SETTINGS',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  DELETE_PROFILE: 'DELETE_PROFILE',
  CONTINUE_DELETE_PROCESS: 'CONTINUE_DELETE_PROCESS',
  CONFIRM_DELETE_PROCESS: 'CONFIRM_DELETE_PROCESS',
  CONFIRM_LANGUAGE_CHANGE: 'CONFIRM_LANGUAGE_CHANGE',
  LOGOUT: 'LOGOUT',
  STORY: 'STORY',
  STORY_CTA: 'STORY_CTA',
  CREATE_STORY: 'CREATE_STORY',
  CREATION_TRY_AGAIN: 'CREATION_TRY_AGAIN',
  ADD_TEXT: 'ADD_TEXT',
  TAG_USER: 'TAG_USER',
  SAVE_TAGGED_USER: 'SAVE_TAGGED_USER',
  TEMPLATE_SELECTION: 'TEMPLATE_SELECTION',
  DISCARD_CREATION: 'DISCARD_CREATION',
  DELETE_STORY: 'DELETE_STORY',
  DELETE_STORY_CONFIRM: 'DELETE_STORY_CONFIRM',
  REPOST_ON_FEED: 'REPOST_ON_FEED',
  REPOST_ON_STORY: 'REPOST_ON_STORY',
  CREATE_COMMUNITY: 'CREATE_COMMUNITY',
  FLOATING_BUTTON: 'FLOATING_BUTTON',
  REVIEW_AND_PROCEED: 'REVIEW_AND_PROCEED',
  REMOVE_MEMBER_REVIEW: 'REMOVE_MEMBER_REVIEW',
  COMMUNITY: 'COMMUNITY',
  EDIT_COMMUNITY: 'EDIT_COMMUNITY',
  COMMUNITY_PAST_INACTIVE: 'COMMUNITY_PAST_INACTIVE',
  EXIT_COMMUNITY_CONFIRM: 'EXIT_COMMUNITY_CONFIRM',
  EXIT_COMMUNITY: 'EXIT_COMMUNITY',
  DEACTIVATE_COMMUNITY_CONFIRM: 'DEACTIVATE_COMMUNITY_CONFIRM',
  ACTIVATE_COMMUNITY: 'ACTIVATE_COMMUNITY',
  JOIN_COMMUNITY: 'JOIN_COMMUNITY',
  COMMUNITY_JOIN_REQUEST: 'COMMUNITY_JOIN_REQUEST',
  WITHDRAW_REQUEST: 'WITHDRAW_REQUEST',
  WITHDRAW_REQUEST_CONFIRM: 'WITHDRAW_REQUEST_CONFIRM',
  ADD_COMMUNITY_BANNER: 'ADD_COMMUNITY_BANNER',
  REMOVE_COMMUNITY_MEMBER: 'REMOVE_COMMUNITY_MEMBER',
  ADD_COMMUNITY_MEMBER_AGAIN: 'ADD_COMMUNITY_MEMBER_AGAIN',
  REMOVE_COMMUNITY_MEMBER_CONFIRM: 'REMOVE_COMMUNITY_MEMBER_CONFIRM',
  INACTIVATE_COMMUNITY: 'INACTIVATE_COMMUNITY',
  CALENDAR: 'CALENDAR',
  EXPLORE: 'EXPLORE',
  CREATE_POST: 'CREATE_POST',
  ANONYMOUS_POSTING: 'ANONYMOUS_POSTING',
  ATTACH_DOCUMENT: 'ATTACH_DOCUMENT',
  ASK_QUESTION: 'ASK_QUESTION',
  ADD_MORE_OPTION: 'ADD_MORE_OPTION',
  TAG_PEOPLE: 'TAG_PEOPLE',
  CONNECT_AND_TAG: 'CONNECT_AND_TAG',
  SELECT_USER_TAG: 'SELECT_USER_TAG',
  REMOVE_TAG_USER: 'REMOVE_TAG_USER',
  NEXT: 'NEXT',
  POSTING_OPTIONS: 'POSTING_OPTIONS',
  POST_TYPE_TOGGLE: 'POST_TYPE_TOGGLE',
  SELECT_SPECIALITY_HASHTAG: 'SELECT_SPECIALITY_HASHTAG',
  REMOVE_SPECIALITY_HASHTAG: 'REMOVE_SPECIALITY_HASHTAG',
  CREATE_FEED_POST: 'CREATE_FEED_POST',
  FEED_POST_CREATED: 'FEED_POST_CREATED',
  SAVE_TO_DRAFT: 'SAVE_TO_DRAFT',
  OPEN_DRATS_LIST: 'OPEN_DRATS_LIST',
  DISCARD_POST: 'DISCARD_POST',
  CONTINUE_EDIT: 'CONTINUE_EDIT',
  POST_TRYAGAIN: 'POST_TRYAGAIN',
  POST_DRAFT_DELETE: 'POST_DRAFT_DELETE',
  POST_DRAFT_EDIT: 'POST_DRAFT_EDIT',
  SEARCH: 'SEARCH',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  SHOW_SEARCH_RESULT: 'SHOW_SEARCH_RESULT',
  REMOVE_SEARCH_RESULT: 'REMOVE_SEARCH_RESULT',
  SEARCH_AGAIN: 'SEARCH_AGAIN'
}
export const NUDGE_BLACKLIST_SCREENS = {
  WEBINAR_DETAIL: 'WEBINAR_DETAIL',
  DOCTALKS_DETAIL: 'DOCTALKS_DETAIL',
  SURVEY_DETAIL : 'SURVEY_DETAIL',
  SURVEY_QUESTION: 'SURVEY_QUESTION',
  THANK_YOU_SCREEN : 'THANK_YOU_SCREEN',
  CME_DETAIL: 'CME_DETAIL',
  CME_QUESTION: 'CME_QUESTION',
  CME_THANKYOU: 'CME_THANKYOU',
  ALL_SURVEY_LISTING_SCREEN : 'ALL_SURVEY_LISTING_SCREEN',
}

export const NOTIFICATION_TYPE_SOURCE = {
  SANCHAR: 'SANCHAR',
  CLEVERTAP: 'CLEVERTAP'
}

export const RESPONSE_CONSTANT = {
  S102000: 'S102000'
}

export const MAX_FILE_SIZE = 31457280;

export const PROFILE_NUDGE_VALIDATION = {
  REQUIRED: 'REQUIRED',
  DATE_TIME: 'DATE_TIME'
}

export const PROFILE_ACTIONS = {
  DECLINE: 'DECLINE',
  SUBMIT: 'SUBMIT',
  CLOSE: 'CLOSE'
}
//prod//
// export const LOGIN_SDK = {
//   COUNTRY: "MX",
//   LANGUAGE: "es",
//   VERSION: "Mexico1.0",
//   MODULE: "DQ",
//   CLIENT: "1GJ6Z0CGSZ",
//   DEFAULT_ACTION: true,
//   PAGE: "SIGNUP",
//   DISABLE_CLOSE: false
// }

export const SDK_PAGE_TYPE = {
  LOGIN : 'LOGIN',
  SIGNUP: 'SIGNUP'
}

export const API_METHOD_TYPE = {
  GET: 'GET'
}
/**
 * logout type
 */
export const LOGOUT_TYPE = {
  MANUAL: 'manual'
}

export const PRODUCT_TYPE_CONSTANT_NAME = {
  ARTICLE: 'article',
  WEBINAR: 'webinar',
  OTHER: 'other',
  FEED: 'feed',
  DOCTALK: 'doctalk',
  INSIGHT_COLLECTOR: 'insight_collector',
  USER_STORY: 'user_story',
  DOC_TALKS_SERIES:  'doctalks_series',
  USER: 'user',
  COMMUNITY: 'community',
  COMMENT: 'comment',
  CHAT: "chat",
  CONNECTION: "connection",
  NOTIFICATION: 'notification',
  PVS: 'pvs',
  CME: 'cme',
  POSTING: 'posting_card',
  SEARCH: 'search',
  PHARMA: 'pharma',
  CHANNEL: 'channel',
  EVENT: 'event',
  NUDGE: 'nudge'
}

export const PRODUCT_TYPE_NUMBER_MAPPING = {
  '3': 'article',
  '1': 'feed',
  '0': 'other',
  '24': 'doctalk',
  '36': 'doctalks_series',
  '14': 'user',
  '46': 'insight_collector',
  '27': 'webinar',
  '47': 'community',
  '2': 'comment',
  '23': 'chat',
  '29': 'connection',
  '8': 'cme'
};
export const COMMUNITY_CONSTANT={
  CREATE_COMMUNITY:'createCommunity',
  COMMUNITY_ID:'communityId',
  CREATE:'CREATE'
}