import { AnimationTriggerMetadata, trigger, style, transition, animate, keyframes, query, stagger, state } from '@angular/animations';


export const fade = trigger('fade', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('200ms', [
      animate('0.2s ease-in', keyframes([
        style({ opacity: 0, transform: 'translateY(0)', offset: 0 }),
        style({ opacity: .5, transform: 'translateY(0)', offset: 0.5 }),
        style({ opacity: .7, transform: 'translateY(0)', offset: 0.7 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])
]);

// export let slideInOut=  trigger('slideInOut', [
//       transition(':enter', [
//         style({transform: 'translateX(100%)'}),
//         animate('400ms ease-in', style({transform: 'translateX(-0.1%)'}))
//       ]),
//       transition(':leave', [
//         animate('400ms ease-in', style({transform: 'translateX(100%)'}))
//       ])
//     ])


export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('700ms ease-out', style({ transform: 'translateX(-0.1%)' }))
  ]),
  transition(':leave', [
    animate('700ms  ease-in', style({ transform: 'translateX(-100%)' }))
  ])
]);





export const listAnimation = trigger('listAnimation', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('300ms', [
      animate('400ms ease-in', keyframes([
        style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
        style({ opacity: .5, transform: 'translateY(-25%)', offset: 0.5 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
      ]))]), { optional: true }),



    query(':leave', stagger('300ms', [
      animate('500ms ease-in', keyframes([
        style({ opacity: 0.9, transform: 'translateY(0)', offset: 0 }),
        style({ opacity: .6, transform: 'translatex(-30%)', offset: 0.3 }),
        style({ opacity: 0, transform: 'translatex(-75%)', offset: 0.9 }),
      ]))]), { optional: true })

  ])
]);

export const focusPanel = trigger('focusPanel', [
  state('inactive', style({
    transform: 'scale(1)',
  })),
  state('active', style({
    transform: 'scale(1.1)',
  })),
  transition('inactive => active', animate('100ms ease-in')),
  transition('active => inactive', animate('100ms ease-out'))
]);


export const animationI = trigger('animationI', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('100ms', [
      animate('100ms   ease-in', keyframes([
        style({ opacity: 0, transform: 'translateY(0)', offset: 0 }),
        style({ opacity: .3, transform: 'translateY(0)', offset: 0.3 }),
        style({ opacity: .6, transform: 'translateY(0)', offset: 0.6 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])
]);

export const animationII = trigger('in', [
  state('*', style({ opacity: 0 })),
  state('in', style({ opacity: 1 })),
  transition('* => in', animate('100ms ease-in-out', keyframes([
    style({ opacity: 0.05, offset: 0 }),
    style({ opacity: 0.1, offset: 0.3 }),
    style({ opacity: 0.2, offset: 0.7 }),
    style({ opacity: 1, offset: 1.0 }),
  ])))
]);

export const image = trigger('image', [
  transition(':enter', [
    query('*', [
      style({ transform: 'translateY(200px)', opacity: 0 }),
      stagger(50, [
        animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style('*'))
      ])
    ])
  ])
]);


export const fadee = trigger('fadee', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(':enter', stagger('300ms', [
      animate('1000ms ease-in-out', keyframes([
        style({ opacity: 0.3, transform: 'translatex(-100%)', offset: 0 }),
        style({ opacity: 0.5, transform: 'translatex(-50%)', offset: 0.3 }),
        style({ opacity: 0.7, transform: 'translatex(-25%)', offset: 0.7 }),
        style({ opacity: 1, transform: 'translatex(0)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])
]);



export const animationIII = trigger('animationIII', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('300ms', [
      animate('700ms ease-out', keyframes([
        style({ opacity: 0.3, transform: 'scale(0.1)', offset: 0 }),
        style({ opacity: 0.5, transform: 'scale(0.3)', offset: 0.3 }),
        style({ opacity: 0.7, transform: 'scale(0.7)', offset: 0.7 }),
        style({ opacity: 1, transform: 'scale(1)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])]);



export const animationIV = trigger('inn', [
  state('*', style({ opacity: 0 })),
  state('in', style({ opacity: 1 })),
  transition('* => in', animate('500ms 500ms ease-in-out', keyframes([
    style({ opacity: 0.0, offset: 0 }),
    style({ opacity: 0.3, offset: 0.5 }),
    style({ opacity: 0.7, offset: 0.7 }),
    style({ opacity: 1, offset: 1.0 }),
  ])))
]);



export const animationV = trigger('animationV', [

  transition(':enter', [
    query('textarea', style({ opacity: 0 }), { optional: true }),

    query('textarea', stagger('300ms', [
      animate('700ms ease-out', keyframes([
        style({ opacity: 0.3, transform: 'scale(0.1)', offset: 0 }),
        style({ opacity: 0.5, transform: 'scale(0.3)', offset: 0.3 }),
        style({ opacity: 0.7, transform: 'scale(0.7)', offset: 0.7 }),
        style({ opacity: 1, transform: 'scale(1)', offset: 1.0 }),
      ]))]), { optional: true }),
  ]),

  transition(':leave', [
    query('textarea', style({ opacity: 0 }), { optional: true }),

    query('textarea', stagger('300ms', [
      animate('700ms ease-out', keyframes([
        style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
        style({ opacity: .7, transform: 'scale(0.7)', offset: 0.3 }),
        style({ opacity: .5, transform: 'scale(0.3)', offset: 0.7 }),
        style({ opacity: .3, transform: 'scale(.1)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])

]

);


export const fadeee = trigger('fadeee', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('200ms', [
      animate('500ms ease-out', keyframes([
        style({ opacity: 0.3, transform: 'scale(0.1)', offset: 0 }),
        style({ opacity: 0.5, transform: 'scale(0.3)', offset: 0.3 }),
        style({ opacity: 0.7, transform: 'scale(0.7)', offset: 0.7 }),
        style({ opacity: 1, transform: 'scale(1)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])
]
);



export const eventanimationI = trigger('eventanimationI', [

  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),

    query(':enter', stagger('300ms', [
      animate('300ms 300ms ease-in', keyframes([
        style({ opacity: 0, transform: 'translateY(0)', offset: 0 }),
        style({ opacity: .3, transform: 'translateY(0)', offset: 0.5 }),
        style({ opacity: .7, transform: 'translateY(0)', offset: 0.7 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
      ]))]), { optional: true }),
  ])
]);



export class FlipAnimation {
  static animations = FlipAnimation.getAnimations();

  static getAnimations(): Array<AnimationTriggerMetadata> {
    return [
      trigger('flipState', [
        state(
          'active',
          style({
            transform: 'rotateY(180deg)',
          })
        ),
        state(
          'inactive',
          style({
            transform: 'rotateY(0)',
          })
        ),
        transition('active => inactive', animate('400ms ease-out')),
        transition('inactive => active', animate('400ms ease-in')),
      ]),
    ];
  }
}
