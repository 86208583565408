/**
 * Angular imports.
 */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * service imports.
 */
import { CommonFunctionService } from '../public-module-services/common-function.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { OpenAccessService } from '../open-access.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';

/**
 * constant imports.
 */
import { NUMBERS, SCREEN_NAME, OPEN_ACCESS } from '../../constants/app.constants';

/**
 * Global model imports.
 */
import { openArticle } from '../../onboarding/interface/global.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';
import { IEventName, IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';
import { PurpleOnboarding } from '../purple-onboarding.service';

/**
 * Component for displaying public articles.
 */
@Component({
  selector: 'app-public-article',
  templateUrl: './public-article.component.html',
  styleUrls: ['./public-article.component.scss']
})
export class PublicArticleComponent implements OnInit, OnDestroy {

  /**
   * Property to store the context from which this component is called.
   */
  public callingFrom: string;

  /**
   * object for passing open article data
   */
  public suggestionList: any;

  /**
   * Used to hold the constant value;
   */
  public openAccessConst = OPEN_ACCESS;

  /**
   * Doctalk element reference for SDK
   */
  @ViewChild('articleLoginController') articleLoginController!: ElementRef;
  private articleId: string;

  private readonly eventName: Map<number,IEventName> = new Map([
    [OPEN_ACCESS.productlist.community, EVENT_NAME.COMMUNITY_ICON_CLICK],
    [OPEN_ACCESS.productlist.doctalk, EVENT_NAME.DOCTALKS_ICON_CLICK],
    [OPEN_ACCESS.productlist.article, EVENT_NAME.ARTICLES_ICON_CLICK],
    [OPEN_ACCESS.productlist.webinar, EVENT_NAME.WEBINAR_ICON_CLICK]
  ]);
  public record : IPublicAnalyticsData;

  /**
   * Constructor to inject CommonFunctionService.
   * @param cfs - Instance of CommonFunctionService.
   */
  constructor(private cfs: CommonFunctionService,
    public oas : OpenAccessService,
    public route : ActivatedRoute,
    public mls: NewLanguageService,
    public fcs: FunCollectionService,
    private paes: PublicAnalyticsEventService,
    public login: PurpleOnboarding) { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    this.callingFrom = SCREEN_NAME.OPEN_ARTICLE_DETAIL;
    this.oas.selectedTab = '3';
    this.route.params.subscribe((params) => {
      // This will be called when the route changes

      this.getArticlesuggList();
    });
    this.route.paramMap.subscribe(param => {
      this.articleId = param.get('id');
      this.record = {
        product_type: OPEN_ACCESS.productlist.article,
        product_id: +this.articleId,
        screen_name: screenName.PRODUCT_DETAIL_PAGE,
        event_name: '',
        eventName: EVENT_NAME.COUNTRY_DROPDOWN_CLICK
      };
      this.sendEntryEvent();
    });
  }

  /**
 * Method to fetch the list of open articles from the OpenAccessService.
 */
  getArticlesuggList(): void {
    this.oas.getOpenArticleList(OPEN_ACCESS.productlist.article).subscribe(
      (response: any) => {
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          res.data.response.forEach(element => {
            element.list = element.list.filter( (filterData) => filterData.id !== Number(this.articleId))
            this.suggestionList = element;
          });
        }
      }
    );
  }

  /**
   * Navigate user to list page.
   */
  viewAllClick(event): void {
    this.fcs.navigateToOpenProductList(event);
  }

  ngOnDestroy(): void {
    this.sendExitEvent();
  }
  /**
   * sends header menu click event
   * @param pType
   * @returns
   */
  sendEvent(pType: number): void {
    if(!this.eventName.has(pType)) {
      return;
    }
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.article,
      product_id: +this.articleId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: this.eventName.get(pType)
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * sends entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.article,
      product_id: +this.articleId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_ENTRY,
      extra: JSON.stringify({unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id})
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.article,
      product_id: +this.articleId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_EXIT
    };
    this.paes.sendAnalytics(record);
  }
    /**
   * function to render login sdk
   */
    onCardClick():void {
      this.login.callLoginSdk(this.articleLoginController);
    }

}
