/**
 * Angular import.
 */
import { Directive, HostListener, Input, NgZone } from "@angular/core";
/**
 * Service import.
 */
import { PublicAnalyticsEventService } from "./public-analytics-event.service";

/**
 * Interface import.
 */
import {
  IPublicAnalyticsData,
} from "./public-analytics.model";

/**
 * Analytics directive.
 */
@Directive({
  selector: '[pAppAnalyticsEvent]',
})
export class PublicAnalyticsEventDirective {
  /**
   * Instance created.
   */
  constructor(private paes: PublicAnalyticsEventService, private zone: NgZone) {}

  /**
   * Analytics data.
   */
  @Input() pAppAnalyticsEvent: IPublicAnalyticsData;

  /**
   *Click event that is called from directive.
   */
   @HostListener('click', ['$event']) onClick() {
    this.zone.runOutsideAngular(() => { 
      if (this.pAppAnalyticsEvent && this.pAppAnalyticsEvent.screen_name) {
        this.paes.sendAnalytics(this.pAppAnalyticsEvent);
      }
    })
  }
}
