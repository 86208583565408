/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format the date in Text format.
 */
@Pipe({
  name: 'niceDateFormat'
})
export class NiceDateFormatPipe implements PipeTransform {
  transform(value, todayStr = 'Today', yesterdayStr = 'Yesterday', other = (new DatePipe("en-US")).transform(value, 'dd/MM/YY')): any {

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (value.getFullYear() == today.getFullYear() && value.getMonth() == today.getMonth() && value.getDate() == today.getDate())
      return todayStr;
    else if (value.getFullYear() == yesterday.getFullYear() && value.getMonth() == yesterday.getMonth() && value.getDate() == yesterday.getDate())
      return yesterdayStr;
    else {
      return other;
    }
  }
}
