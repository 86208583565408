/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Sort the array according to the role.
 */
@Pipe({ name: 'roleBy' })
export class RoleByPipe implements PipeTransform {
  transform(
    value: any[],
    order = '',
    column = '',
    secColumn = ''
  ): any[] {
    if (!value || order === '' || !order) {
      return value;
    }

    value.sort((x, y) => {
      return x[secColumn] === 1 ? -1 : y[secColumn] === 1 ? 1 : 0;
    });
    value.sort((x, y) => {
      return x[column] === 1 ? -1 : y[column] === 1 ? 1 : 0;
    });

    return value;
  }
}
