import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PublicRoutingModule } from './public-module-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoginSdk } from "onboarding2.0";


import { PublicModuleComponent } from './public-module.component';
import { PublicArticleComponent } from './public-article/public-article.component';
import { PublicPageHeaderComponent } from './public-page-header/public-page-header.component';
import { PublicPageContentComponent } from './public-page-content/public-page-content.component';
import { PublicUserRoleComponent } from './public-user-role/public-user-role.component';
import { PublicUserProfileDetailComponent } from './public-user-profile-detail/public-user-profile-detail.component';
import { PublicUserOtpComponent } from './public-user-otp/public-user-otp.component';
import { PublicUserOtpInputComponent } from './public-user-otp-input/public-user-otp-input.component';
import { BusinessEnquirySuccessComponent } from './business-enquiry-success/business-enquiry-success.component';
import { PublicArticleLoginCardComponent } from './public-article-login-card/public-article-login-card.component';
import { PublicArticleLoginContentComponent } from './public-product-suggestion-list/product-suggestion-list.component';
import { ShareStripComponent } from './share-strip/share-strip.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShakaPlayerModule } from '../shaka-player/shaka-player.module';
import { MediaModalModule } from '../common/media-modal/media-modal.module';
import { BusinessEnquiryFormComponent } from './business-enquiry-form/business-enquiry-form.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PublicListingComponent } from './public-listing/public-listing.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { PublicDoctalkDetailComponent } from './public-doctalk-detail/public-doctalk-detail.component';
import { PublicWebinarDetailComponent } from './public-webinar-detail/public-webinar-detail.component';
import { NgbDropDownModule } from '../common/ngb-drop-down/ngb-drop-down.module';
import { PublicViewAllComponent } from './public-view-all/public-view-all.component';
import { InviteUserWelcomeScreenComponent } from './invite-user-welcome-screen/invite-user-welcome-screen.component';
import { RestrictedPopupComponent } from './restricted-popup/restricted-popup.component';
import { PublicAnalyticsModule } from './public-analytics/public-analytics.module';
import { OpenAccessVideoComponent } from './open-access-video/open-access-video.component';
import { PublicDtSeriesComponent } from './public-dt-series/public-dt-series.component';
import { PublicSkipPopupComponent } from './public-skip-popup/public-skip-popup.component';


@NgModule({
  declarations: [
    PublicModuleComponent,
    PublicArticleComponent,
    PublicPageHeaderComponent,
    PublicPageContentComponent,
    PublicUserRoleComponent,
    PublicUserProfileDetailComponent,
    PublicUserOtpComponent,
    PublicUserOtpInputComponent,
    BusinessEnquirySuccessComponent,
    PublicArticleLoginCardComponent,
    PublicArticleLoginContentComponent,
    ShareStripComponent,
    BusinessEnquiryFormComponent,
    PublicListingComponent,
    ProductListingComponent,
    PublicDoctalkDetailComponent,
    PublicWebinarDetailComponent,
    PublicViewAllComponent,
    InviteUserWelcomeScreenComponent,
    RestrictedPopupComponent,
    OpenAccessVideoComponent,
    PublicDtSeriesComponent,
    PublicSkipPopupComponent
  ],

  providers: [
    LoginSdk
  ],
  imports: [
    NgbModalModule,
    CommonModule,
    RouterModule,
    PublicRoutingModule,
    ModalModule,
    InfiniteScrollModule,
    CarouselModule,
    NgbDropDownModule,
    ShakaPlayerModule,
    PublicAnalyticsModule
  ],

  exports: [
    PublicModuleComponent,
    PublicArticleComponent,
    PublicArticleLoginCardComponent,
    PublicArticleLoginContentComponent,
    PublicListingComponent,
    ProductListingComponent,
    PublicWebinarDetailComponent,
    PublicDoctalkDetailComponent,
    PublicViewAllComponent,
    RestrictedPopupComponent,
    OpenAccessVideoComponent,
    PublicAnalyticsModule,
    
  ]
})
export class PublicModule {
  constructor() {
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'assets/js/hls.js/dist/hls.min.js';
    document.head.appendChild(script2);

    const script5 = document.createElement('script');
    script5.type = 'text/javascript';
    script5.src = 'assets/js/shaka-player/dist/shaka-player.ui.debug.js';
    document.head.appendChild(script5);

    const script6 = document.createElement('script');
    script6.type = 'text/javascript';
    script6.src = 'assets/js/mux.js';
    document.head.appendChild(script6);

  }
}
