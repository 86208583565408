/**
 * Angular imports.
 */
import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Used to validate the email address based on Regex pattern.
 */
export const NoSpaceValidator =
  (control: AbstractControl): ValidationErrors | null => {

    if (!(control.value.toString() || '')) {
      return null;
    }

    if ((control.value.toString() || '').trim().length === 0) {
      return { cannotContainSpace: true };
    }
    return null;
  };
