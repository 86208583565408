/**
 * Constant imports.
 */
import { API } from './api.constants';
export const HEADERS = {
  xApiKey: {
    [API.DOCQUITY_STS_TOKEN]: API.DOCQUITY_STS_TOKEN
  },
  version_21: {
    [API.WEB_GET_APP]: API.WEB_GET_APP
  },
  ver_api_version_updated: {
    [API.MR_CALENDER_LIST]: API.MR_CALENDER_LIST,
    [API.MR_MEETING_ACTION]: API.MR_MEETING_ACTION,
    [API.MR_MEETING_RESCHEDULE]: API.MR_MEETING_RESCHEDULE,
    [API.MR_CALENDER_DOTS]: API.MR_CALENDER_DOTS,
    [API.MR_MEETING_DETAILS]: API.MR_MEETING_DETAILS,
    [API.MR_COMMENT_GET]: API.MR_COMMENT_GET,
    [API.EVENT_LIST_V3]: API.EVENT_LIST_V3,
    [API.EVENT_DETAILS_V3]: API.EVENT_DETAILS_V3,
    [API.SESSION_DETAILS_V3]: API.SESSION_DETAILS_V3,
    [API.USER_ACTIVITY_V3]: API.USER_ACTIVITY_V3,
    [API.CERTIFICATE_DETAIL_V3]: API.CERTIFICATE_DETAIL_V3,
    [API.SPEAKERS_LIST_V3]: API.SPEAKERS_LIST_V3,
    [API.EVENT_SCHEDULE]: API.EVENT_SCHEDULE,
    [API.SESSION_MAPPED_PRODUCT_V3]: API.SESSION_MAPPED_PRODUCT_V3,
    [API.ACCEPT_CONSENT]: API.ACCEPT_CONSENT,
    [API.USER_INVITE_CODE]: API.USER_INVITE_CODE,
    [API.CME_LIST_V2]: API.CME_LIST_V2,
    [API.USER_DELETION_DELETE_ACCOUNT]: API.USER_DELETION_DELETE_ACCOUNT,
    [API.USER_DELETION_LIST]: API.USER_DELETION_LIST,
    [API.EDITOR_CHOICE_DETAILS]: API.EDITOR_CHOICE_DETAILS,
    [API.NCP_SUBTYPE_DETAILS]: API.NCP_SUBTYPE_DETAILS,
    [API.GAME_LEADER_BOARD]: API.GAME_LEADER_BOARD,
    [API.GAME_NCP_DETAIL]: API.GAME_NCP_DETAIL,
    [API.GAME_PLAY_COUNT]: API.GAME_PLAY_COUNT,
    // [API.TEXT_TO_SPEECH]: API.TEXT_TO_SPEECH,
    [API.REPOST_DEFAULT_CONTENT]: API.REPOST_DEFAULT_CONTENT,
    [API.LOG_INFO_CARD_CROSS]: API.LOG_INFO_CARD_CROSS,
    // [API.FORWORD_FLOWS_SUGGESTION]: API.FORWORD_FLOWS_SUGGESTION,
    [API.PROMO_VIEW_STRIP]: API.PROMO_VIEW_STRIP,
    [API.CME_MODULE_DETAIL_V2] : API.CME_MODULE_DETAIL_V2,
    [API.CME_MODULE_NEXT_STEP_JOURNEY] : API.CME_MODULE_NEXT_STEP_JOURNEY,
  },
  releaseVersion_updatedReleaseVersion: {
    [API.MR_CALENDER_LIST]: API.MR_CALENDER_LIST,
    [API.MR_MEETING_ACTION]: API.MR_MEETING_ACTION,
    [API.MR_MEETING_RESCHEDULE]: API.MR_MEETING_RESCHEDULE,
    [API.MR_CALENDER_DOTS]: API.MR_CALENDER_DOTS,
    [API.MR_MEETING_DETAILS]: API.MR_MEETING_DETAILS,
    [API.MR_COMMENT_GET]: API.MR_COMMENT_GET,
    [API.EVENT_LIST_V3]: API.EVENT_LIST_V3,
    [API.EVENT_DETAILS_V3]: API.EVENT_DETAILS_V3,
    [API.SESSION_DETAILS_V3]: API.SESSION_DETAILS_V3,
    [API.USER_ACTIVITY_V3]: API.USER_ACTIVITY_V3,
    [API.CERTIFICATE_DETAIL_V3]: API.CERTIFICATE_DETAIL_V3,
    [API.SPEAKERS_LIST_V3]: API.SPEAKERS_LIST_V3,
    [API.EVENT_SCHEDULE]: API.EVENT_SCHEDULE,
    [API.SESSION_MAPPED_PRODUCT_V3]: API.SESSION_MAPPED_PRODUCT_V3,
    [API.ACCEPT_CONSENT]: API.ACCEPT_CONSENT,
    [API.WEBINAR_STATUS]: API.WEBINAR_STATUS,
    [API.USER_INVITE_CODE]: API.USER_INVITE_CODE,
    [API.USER_DELETION_DELETE_ACCOUNT]: API.USER_DELETION_DELETE_ACCOUNT,
    [API.USER_DELETION_LIST]: API.USER_DELETION_LIST,
    
    [API.HOME_FEED_TIMELINE]: API.HOME_FEED_TIMELINE,
    [API.GRAND_ROUND_LIST]: API.GRAND_ROUND_LIST,
  },
  udid_123: {
    [API.MR_MEETING_ACTION]: API.MR_MEETING_ACTION,
    [API.MR_MEETING_RESCHEDULE]: API.MR_MEETING_RESCHEDULE,
    [API.WEBINAR_STATUS]: API.WEBINAR_STATUS,
    [API.COUNTRY_LIST_V4]: API.COUNTRY_LIST_V4,
    [API.COUNTRY_LIST_V5]: API.COUNTRY_LIST_V5,
    [API.USER_DELETION_LIST]: API.USER_DELETION_LIST,
    [API.SEARCH_GET_SUGGESTIONS]: API.SEARCH_GET_SUGGESTIONS,
    [API.FORM_CONTROL_DETAIL]: API.FORM_CONTROL_DETAIL,
    [API.EDITOR_CHOICE_DETAILS]: API.EDITOR_CHOICE_DETAILS,
    [API.NCP_SUBTYPE_DETAILS]: API.NCP_SUBTYPE_DETAILS,
    [API.GAME_LEADER_BOARD]: API.GAME_LEADER_BOARD,
    [API.GAME_NCP_DETAIL]: API.GAME_NCP_DETAIL,
    [API.GAME_PLAY_COUNT]: API.GAME_PLAY_COUNT
  },
  version_3: {
    [API.CHATBOT_HISTORY]: API.CHATBOT_HISTORY
  },
  version_GROUP_CHAT_VERSION: {
    [API.MR_COMMENT_GET]: API.MR_COMMENT_GET,
    [API.EVENT_LIST_V3]: API.EVENT_LIST_V3,
    [API.EVENT_DETAILS_V3]: API.EVENT_DETAILS_V3,
    [API.SESSION_DETAILS_V3]: API.SESSION_DETAILS_V3,
    [API.USER_ACTIVITY_V3]: API.USER_ACTIVITY_V3,
    [API.CERTIFICATE_DETAIL_V3]: API.CERTIFICATE_DETAIL_V3,
    [API.SPEAKERS_LIST_V3]: API.SPEAKERS_LIST_V3,
    [API.EVENT_SCHEDULE]: API.EVENT_SCHEDULE,
    [API.SESSION_MAPPED_PRODUCT_V3]: API.SESSION_MAPPED_PRODUCT_V3
  },
  ver_24_28: {
    [API.PROFILE]: API.PROFILE
  },
  ver_1: {
    [API.PROFILE]: API.PROFILE,
    [API.FILE_UPLOAD]: API.FILE_UPLOAD,
    [API.EVENT_REGISTER]: API.EVENT_REGISTER
  },
  ver_27: {
    [API.HOME_DATA_LISTS]: API.HOME_DATA_LISTS,
    [API.HOME_FEED_DETAIL]: API.HOME_FEED_DETAIL,
    [API.HOME_FEED_TIMELINE]: API.HOME_FEED_TIMELINE,
    [API.HOME_FEED_ARCHIEVE]: API.HOME_FEED_ARCHIEVE
  },
  ver_23: {
    [API.JOURNAL_ARCHIVE]: API.JOURNAL_ARCHIVE,
    [API.PHARMA_DRUG_SIMPLE_REQUEST]: API.PHARMA_DRUG_SIMPLE_REQUEST,
    [API.REFERRAL_USER_POINT]: API.REFERRAL_USER_POINT,
    [API.NEWS_FILTER_LIST]: API.NEWS_FILTER_LIST,
    [API.NEWS_LIST]: API.NEWS_LIST,
    [API.ARCHIEVE_LIST]: API.ARCHIEVE_LIST,
    [API.HOME_POSTING_ON]: API.HOME_POSTING_ON
  },
  version_20: {
    [API.CONNECT_SERVICES_AUTHENCITY_SET]: API.CONNECT_SERVICES_AUTHENCITY_SET,
    [API.CONNECT_SERVICE_F_UPLOAD_SET]: API.CONNECT_SERVICE_F_UPLOAD_SET,
  },
  releaseVersion_environment_appReleaseVersion: {
    [API.WEBINAR_POLL_SUBMIT]: API.WEBINAR_POLL_SUBMIT,
    [API.USER_REGISTRATION]: API.USER_REGISTRATION,
    [API.REGISTRATION_VERIFY_MOBILE]: API.REGISTRATION_VERIFY_MOBILE,
    [API.USER_TOKEN]: API.USER_TOKEN,
    [API.USER_LOGOUT]: API.USER_LOGOUT
  },
  ver_41: {
    [API.REGISTRATION_CAPTCHA_USER_REQUEST]: API.REGISTRATION_CAPTCHA_USER_REQUEST,
    [API.CME_SUBMIT_ANSWER_V2]: API.CME_SUBMIT_ANSWER_V2
  },
  ver_52: {
    [API.REGISTRATION_VERIFY_MOBILE]: API.REGISTRATION_VERIFY_MOBILE,
    [API.USER_REGISTRATION]: API.USER_REGISTRATION,
    [API.USER_TOKEN]: API.USER_TOKEN,
    [API.USER_LOGOUT]: API.USER_LOGOUT,
    [API.FEED_POST]: API.FEED_POST,
    [API.FEED_DETAIL_V4]: API.FEED_DETAIL_V4,
    [API.BOOKMARK_PRODUCT_LIST]: API.BOOKMARK_PRODUCT_LIST,
    [API.USER_ASSOCIATION_LIST]: API.USER_ASSOCIATION_LIST,
  },
  ver_50: {
    [API.CME_DETAIL]: API.CME_DETAIL,
    [API.CME_MODULE_DETAIL_V2]: API.CME_MODULE_DETAIL_V2,
    [API.CME_MODULE_NEXT_STEP_JOURNEY]: API.CME_MODULE_NEXT_STEP_JOURNEY,
    // [API.WEBINAR_V2_LIST] : API.WEBINAR_V2_LIST
  },
  releaseVersion_releaseVersion: {
    [API.CME_DETAIL]: API.CME_DETAIL,
    [API.CME_HISTORY_V2]: API.CME_HISTORY_V2
  },
  releaseVersion_environment_GR_RELEASE_VERSION: {
    [API.FILTER_LIST_V2]: API.FILTER_LIST_V2,
    [API.JOURNAL_DETAIL]: API.JOURNAL_DETAIL,
  },
  releaseVersion_environment_RELEASE_VERSION_17: {
    [API.PROFILE_DETAIL]: API.PROFILE_DETAIL,
    [API.CHAT_SEARCH_LIST]: API.CHAT_SEARCH_LIST,
    [API.CHAT_USER_CONNECTION]: API.CHAT_USER_CONNECTION,
    [API.COMMENT_LIST]: API.COMMENT_LIST,
    [API.COMMENT_POST]: API.COMMENT_POST,
    [API.USER_REGISTRATION]: API.USER_REGISTRATION,
    [API.USER_DOCUMENT_SUBMIT]: API.USER_DOCUMENT_SUBMIT,
    [API.CHAT_CONNECTION_LIST]: API.CHAT_CONNECTION_LIST,
  },
  releaseVersion_environment_RELEASE_VERSION_19: {
    [API.CME_LIST_V2]: API.CME_LIST_V2,
  },
  releaseVersion_environment_RELEASE_VERSION_20: {
    [API.CME_LIST_V2]: API.CME_LIST_V2,
    [API.PHARMA_PRODUCT_LIST_V2]: API.PHARMA_PRODUCT_LIST_V2
  },
  releaseVersion_9: {
    [API.WEBINAR_V2_LIST]: API.WEBINAR_V2_LIST
  },
  ver_25: {
    [API.USER_PROFILE_REPLY_QUESTION]: API.USER_PROFILE_REPLY_QUESTION,
    [API.PROFILE_MEDIA_LIST]: API.PROFILE_MEDIA_LIST,
    [API.USER_PROFILE_QUESTION_LIST]: API.USER_PROFILE_QUESTION_LIST,
  },
  ver_44: {
    [API.REGISTRATION_INVITE_PROCESS]: API.REGISTRATION_INVITE_PROCESS,
    [API.REGISTRATION_UNIVERSITY_LIST]: API.REGISTRATION_UNIVERSITY_LIST,
    [API.REGISTRATION_SPECIALITY_LIST_V4]: API.REGISTRATION_SPECIALITY_LIST_V4,
    [API.CHAT_USER_CONNECTION]: API.CHAT_USER_CONNECTION
  },
  ver_46: {
    [API.OPEN_CONTENT]: API.OPEN_CONTENT,
    [API.OPEN_CONTENT_DETAIL] : API.OPEN_CONTENT_DETAIL,
    [API.OPEN_CONTENT_COUNTRY] : API.OPEN_CONTENT_COUNTRY,
    [API.OPEN_CONTENT_ANALYTICS]: API.OPEN_CONTENT_ANALYTICS,
    [API.OPEN_CONTENT_TOKEN_DETAIL]: API.OPEN_CONTENT_TOKEN_DETAIL,

  },
  ver_49: {
    [API.SEARCH_DOCQUITY_DATA_GROUP]: API.SEARCH_DOCQUITY_DATA_GROUP,
  },
  ver_51: {
    [API.FEED_EDIT]: API.FEED_EDIT,
  },
    otpToken: {
    [API.USER_REGISTRATION]: API.USER_REGISTRATION,
    [API.REGISTRATION_INVITE_PROCESS]: API.REGISTRATION_INVITE_PROCESS,
    [API.REGISTRATION_UNIVERSITY_LIST]: API.REGISTRATION_UNIVERSITY_LIST,
    [API.REGISTRATION_SPECIALITY_LIST_V4]: API.REGISTRATION_SPECIALITY_LIST_V4,
  },
  Content_Type_application_json: {
    [API.CHAT_MESSAGE]: API.CHAT_MESSAGE,
  },
  Content_Type_Url_encoded_UTF_8: {
    [API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE]: API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE,
    [API.SEARCH_STORE_TERMS]: API.SEARCH_STORE_TERMS,
    [API.E_DETAILING_DO_LATER]: API.E_DETAILING_DO_LATER,
    [API.E_DETAILING_PROFILE_MISSING_UPDATE]: API.E_DETAILING_PROFILE_MISSING_UPDATE,
    [API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE]: API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE,
    [API.USER]: API.USER


  },
  iso_code_91: {
    [API.CONNECT_SERVICES_AUTHENCITY_SET]: API.CONNECT_SERVICES_AUTHENCITY_SET
  },
  ver_22: {
    [API.SEARCH_DOCQUITY_LIST]: API.SEARCH_DOCQUITY_LIST,
    [API.SEARCH_TERMS]: API.SEARCH_TERMS,
    [API.SEARCH_STORE_TERM]: API.SEARCH_STORE_TERM,
    [API.SPECIALITY_LIST]: API.SPECIALITY_LIST,
    [API.PHARMA_DRUG_LIST]: API.PHARMA_DRUG_LIST,
    [API.PHARMA_DRUG_DETAIL]: API.PHARMA_DRUG_DETAIL,
  },
  ver_api_version: {
    [API.COOKIE]: API.COOKIE,
    [API.CONNECT_WEB_SERVICE]: API.CONNECT_WEB_SERVICE,
    [API.COMMENT_GET]: API.COMMENT_GET,
    [API.CHAT_SEARCH_LIST]: API.CHAT_SEARCH_LIST,
    [API.WEBINAR_AGORA_TOKEN_V2]: API.WEBINAR_AGORA_TOKEN_V2,
    [API.WEBINAR_CDN_DETAILS]: API.WEBINAR_CDN_DETAILS,
    [API.LIKE_LIST]: API.LIKE_LIST,
    [API.CONNECT_SERVICE_REGISTRATION_GET]: API.CONNECT_SERVICE_REGISTRATION_GET,
    [API.E_DETAILING_THERAPEUTIC_LIST]: API.E_DETAILING_THERAPEUTIC_LIST,
    [API.BACKGROUND_ACTIVE]: API.BACKGROUND_ACTIVE,
    [API.CONNECT_WEB_SERVICE_GET]: API.CONNECT_WEB_SERVICE_GET,
    [API.CONNECT_WEB_SERVICE_SET]: API.CONNECT_WEB_SERVICE_SET,
    [API.CONNECT_SERVICES_ASSOCIATION_GET]: API.CONNECT_SERVICES_ASSOCIATION_GET,
    [API.DOCQUITY_V1]: API.DOCQUITY_V1,
    [API.CHAT_DIALOGUE]: API.CHAT_DIALOGUE,
    [API.CHAT_EDIT_DIALOGUE]: API.CHAT_EDIT_DIALOGUE,
    [API.LIKE_LIKE]: API.LIKE_LIKE,
    [API.PRODUCT_FEEDBACK]: API.PRODUCT_FEEDBACK,
    [API.FORM_SAVE_MISSING_DATA]: API.FORM_SAVE_MISSING_DATA,
    [API.USER_TNC_UPDATE]: API.USER_TNC_UPDATE,
    [API.VALIDATE_API_EXTRACTION_V1]: API.VALIDATE_API_EXTRACTION_V1,
    [API.USER_BASIC_INFO]: API.USER_BASIC_INFO,
    [API.CONNECT_SERVICES_FEED_SET]: API.CONNECT_SERVICES_FEED_SET,
    [API.CONNECT_WEB_SERVICE_SET_API_PHP]: API.CONNECT_WEB_SERVICE_SET_API_PHP,
    // [API.ASSOCIATION_SUGGESTED_LIST]: API.ASSOCIATION_SUGGESTED_LIST,
    // [API.SPECIALITY_LIST_V4]: API.SPECIALITY_LIST_V4,
    [API.CME_DESCRIPTION]: API.CME_DESCRIPTION,
    [API.SURVEY_TNC]: API.SURVEY_TNC,
    [API.CME_SOURCE_LIST]: API.CME_SOURCE_LIST,

    [API.PHARMA_DETAIL_V4]: API.PHARMA_DETAIL_V4,
    [API.CALENDER_LIST]: API.CALENDER_LIST,
    [API.LIST_SUGGESTION_LIST]: API.LIST_SUGGESTION_LIST,
    [API.EVENT_LIST_V2]: API.EVENT_LIST_V2,
    [API.EVENT_DETAIL]: API.EVENT_DETAIL,
    [API.EVENT_SESSION_LIST]: API.EVENT_SESSION_LIST,
    [API.EVENT_SESSION_DETAIL]: API.EVENT_SESSION_DETAIL,
    [API.EVENT_MEDIA_LIST]: API.EVENT_MEDIA_LIST,
    [API.PHARMA_DRUG_DETAIL_V2]: API.PHARMA_DRUG_DETAIL_V2,
    [API.CHAT_CONNECTION_LIST]: API.CHAT_CONNECTION_LIST,
    [API.CONNECT_SERVICES_BOOKMARK_SET]: API.CONNECT_SERVICES_BOOKMARK_SET,
    [API.NOTIFICATION_USER_LIST]: API.NOTIFICATION_USER_LIST,
    [API.LIST_SEARCH_TREND]: API.LIST_SEARCH_TREND,
    [API.REASON_LIST]: API.REASON_LIST,
    // [API.HOSPITAL_LIST]: API.HOSPITAL_LIST,
    // [API.UNIVERSITY_LIST_V2]: API.UNIVERSITY_LIST_V2,
    // [API.DEGREE_LIST]: API.DEGREE_LIST,
    // [API.PROFILE_VIEW_LIST]: API.PROFILE_VIEW_LIST,
    // [API.VERIFICATION_UNDER_PROCESS]: API.VERIFICATION_UNDER_PROCESS,
    [API.REFERRAL_CONTENT]: API.REFERRAL_CONTENT,
    [API.EVENT_CERTIFICATE]: API.EVENT_CERTIFICATE,
    [API.EVENT_CERTIFICATE_HISTORY]: API.EVENT_CERTIFICATE_HISTORY,
    [API.PHARMA_CHANNEL_TNC_v2]: API.PHARMA_CHANNEL_TNC_v2,
    [API.FORM_PRODUCT_MISSING_DATA]: API.FORM_PRODUCT_MISSING_DATA,
    [API.SURVEY_LIST]: API.SURVEY_LIST,
    [API.SURVEY_DETAIL]: API.SURVEY_DETAIL,
    [API.DISCARD_SURVEY]: API.DISCARD_SURVEY,
    [API.ANALYTIC_RECORDS]: API.ANALYTIC_RECORDS,
    [API.IN_APP]: API.IN_APP,
    [API.PHARMA_CHANEL_SUBSCRIBE]: API.PHARMA_CHANEL_SUBSCRIBE,
    [API.PHARMA_CHANNEL_TNC]: API.PHARMA_CHANNEL_TNC,
    // [API.ASSIGN_ASSOCIATION]: API.ASSIGN_ASSOCIATION,
    // [API.USER_DOCUMENT_SUBMIT]: API.USER_DOCUMENT_SUBMIT,
    [API.FEED_DELETE]: API.FEED_DELETE,
    [API.BOOKMARK_PRODUCT]: API.BOOKMARK_PRODUCT,
    [API.FEED_POLL_RECORD]: API.FEED_POLL_RECORD,
    [API.CME_GENERATE_CERTIFICATE]: API.CME_GENERATE_CERTIFICATE,
    [API.CME_OFFLINE_CME]: API.CME_OFFLINE_CME,
    [API.MEDIA_STREAMING_LOG]: API.MEDIA_STREAMING_LOG,
    [API.EVENT_GOING]: API.EVENT_GOING,
    [API.PUBLIC_PROFILE]: API.PUBLIC_PROFILE,
    [API.REASON_CREATE_REPORT]: API.REASON_CREATE_REPORT,
    [API.SET_NOTIFICATION]: API.SET_NOTIFICATION,
    [API.CASE_FOLLOW]: API.CASE_FOLLOW,
    [API.COUNTRY_LIST_V4]: API.COUNTRY_LIST_V4,
    [API.DOCQUITY_STS_TOKEN]: API.DOCQUITY_STS_TOKEN,
    [API.CHATBOT_HISTORY]: API.CHATBOT_HISTORY,
    [API.CME_DETAIL]: API.CME_DETAIL,
    [API.CME_HISTORY_V2]: API.CME_HISTORY_V2
  },
  rt_purple_apis: {
    [API.USER_LOGOUT]: API.USER_LOGOUT ,
  },
  access_token_headers_apis: {
    [API.SURVEY_LIST]: API.SURVEY_LIST,
    [API.SURVEY_PENDING_LIST]:API.SURVEY_PENDING_LIST,
    [API.SURVEY_DETAIL]: API.SURVEY_DETAIL,
    [API.SURVEY_FOOTER]: API.SURVEY_FOOTER,
    [API.SURVEY_CONSENT]: API.SURVEY_CONSENT,
    [API.DISCARD_SURVEY]: API.DISCARD_SURVEY,

    [API.SURVEY_PATIENT_LIST]: API.SURVEY_PATIENT_LIST,
    [API.PIF_CME_STATUS]: API.PIF_CME_STATUS
  },
  ver_47: {
  },
  ver_70: {
    // [API.GRAND_ROUND_LIST]: API.GRAND_ROUND_LIST,
    [API.MASTER_LIST]: API.MASTER_LIST,
    [API.BACKGROUND_ACTIVE]: API.BACKGROUND_ACTIVE,
    [API.BACKGROUND_ACTIVE_V1]: API.BACKGROUND_ACTIVE_V1,
    [API.PHARMA_CHANNEL_HOME_LIST]: API.PHARMA_CHANNEL_HOME_LIST,
    [API.PHARMA_PRODUCT_LIST_V2]: API.PHARMA_PRODUCT_LIST_V2,
    [API.PHARMA_DRUG_SAMPLE_REQUEST_V2]: API.PHARMA_DRUG_SAMPLE_REQUEST_V2,
    [API.PHARMA_CHANNEL_SUBSCRIBE]: API.PHARMA_CHANNEL_SUBSCRIBE,
    [API.PHARMA_DRUG_TIME_SLOT]: API.PHARMA_DRUG_TIME_SLOT,
    [API.E_DETAILING_PHARMA_LIST]: API.E_DETAILING_PHARMA_LIST,
    [API.E_DETAILING_PHARMA_TNC]: API.E_DETAILING_PHARMA_TNC,
    [API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE]: API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE,
    [API.E_DETAILING_PHARMA_DETAIL]: API.E_DETAILING_PHARMA_DETAIL,
    [API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE]: API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE,
    [API.E_DETAILING_THERAPEUTIC_DRUG_DETAIL]: API.E_DETAILING_THERAPEUTIC_DRUG_DETAIL,
    [API.E_DETAILING_THERAPEUTIC_CHANNEL_LIST]: API.E_DETAILING_THERAPEUTIC_CHANNEL_LIST,
    [API.E_DETAILING_DRUG_DETAILS]: API.E_DETAILING_DRUG_DETAILS,
    [API.PHARMA_DRUG_LIST_V2]: API.PHARMA_DRUG_LIST_V2,
    [API.JOURNAL_LIST]: API.JOURNAL_LIST,
    [API.JOURNAL_DETAIL]: API.JOURNAL_DETAIL,
    [API.DOC_TALKS_LIST]: API.DOC_TALKS_LIST,
    [API.DOC_TALKS_DETAIL]: API.DOC_TALKS_DETAIL,
    [API.LIKE_LIKE_V2]: API.LIKE_LIKE_V2,
    [API.LIKE_LIST_V2]: API.LIKE_LIST_V2,
    [API.PROFILE_DETAIL]: API.PROFILE_DETAIL,
    [API.PROFILE_TIMELINE]: API.PROFILE_TIMELINE,
    [API.PROFILE_UPDATE]: API.PROFILE_UPDATE,
    [API.COMMENT_POST]: API.COMMENT_POST,
    [API.COMMENT_LIST]: API.COMMENT_LIST,
    [API.TAG_USER_SEARCH]: API.TAG_USER_SEARCH,
    [API.E_DETAILING_DO_LATER]: API.E_DETAILING_DO_LATER,
    [API.E_DETAILING_PROFILE_MISSING_UPDATE]: API.E_DETAILING_PROFILE_MISSING_UPDATE,
    [API.SEARCH_DOCQUITY_DATA_GROUP]: API.SEARCH_DOCQUITY_DATA_GROUP,
    [API.SEARCH_STORE_TERMS]: API.SEARCH_STORE_TERMS,
    [API.SEARCH_GET_SUGGESTIONS]: API.SEARCH_GET_SUGGESTIONS,
    [API.FILTER_LIST_V2]: API.FILTER_LIST_V2,
    [API.LIST_SHARE_CONTENT]: API.LIST_SHARE_CONTENT,
    [API.WEBINAR_LIST]: API.WEBINAR_LIST,
    [API.WEBINAR_V2_LIST]: API.WEBINAR_V2_LIST,
    [API.WEBINAR_NOTIFY_ME]: API.WEBINAR_NOTIFY_ME,
    [API.WEBINAR_POLL_SUBMIT]: API.WEBINAR_POLL_SUBMIT,
    [API.WEBINAR_STATUS]: API.WEBINAR_STATUS,
    [API.FORM_CONTROL_DETAIL]: API.FORM_CONTROL_DETAIL,
    [API.CARD_RENDERING]: API.CARD_RENDERING,
    [API.SAVE_SEARCH_RECORD]: API.SAVE_SEARCH_RECORD,
    [API.COMMUNITY]: API.COMMUNITY,
    [API.COMMUNITY_DETAIL]: API.COMMUNITY_DETAIL,
    [API.USER]: API.USER,
    [API.FORWORD_FLOWS_SUGGESTION]: API.FORWORD_FLOWS_SUGGESTION,
    [API.SPECIALITY_LIST_V4]: API.SPECIALITY_LIST_V4,
    [API.TEXT_TO_SPEECH]: API.TEXT_TO_SPEECH,
    [API.WEBINAR_V2_LIST] : API.WEBINAR_V2_LIST,
    [API.VERIFICATION_UNDER_PROCESS]: API.VERIFICATION_UNDER_PROCESS,
    [API.ASSOCIATION_SUGGESTED_LIST]: API.ASSOCIATION_SUGGESTED_LIST,
    // [API.COUNTRY_LIST_V4]: API.COUNTRY_LIST_V4,
    [API.ASSIGN_ASSOCIATION]: API.ASSIGN_ASSOCIATION,
    [API.UNIVERSITY_LIST_V2]: API.UNIVERSITY_LIST_V2,
    [API.DEGREE_LIST]: API.DEGREE_LIST,
    [API.HOSPITAL_LIST]: API.HOSPITAL_LIST,
    [API.COUNTRY_LIST_V5]: API.COUNTRY_LIST_V5,
    [API.PROFILE_VIEW_LIST]: API.PROFILE_VIEW_LIST,
    [API.USER_DOCUMENT_SUBMIT]: API.USER_DOCUMENT_SUBMIT,
  },
  ver_71: {
    [API.GRAND_ROUND_LIST]: API.GRAND_ROUND_LIST,
  }
};
