
<div class="row">
    <div class="col-md-5 pr-0">
        <app-left-section></app-left-section>
        </div>
        <div class="col-md-7" [ngClass]="{'pl-0': onboardingService?.deviceType!='mobile'}">
            <div  class="position-relative" [ngClass]="{'p-5': onboardingService?.deviceType!='mobile', 'pl-4 pr-4 pb-5 pt-5': onboardingService?.deviceType=='mobile'}">
              <a (click)="back_screen()" class="back-page position-absolute"><img src="assets/images/onboarding/back.svg" width="20px" /></a>
    <h5 class="mb-1">{{verify_data?.title}}
       <!-- <img src="assets/images/onboarding/upload-info.svg" class="ml-2" width="20px" /> -->
      </h5>
    <!-- form -->
    <div class="mt-3 mb-3 row">
      <div class="col-12">
          <input (keyup)="userInput($event)"  (keypress)="inviteInput($event)" [maxLength]="Dss.inputEmailMaxLimit" autocomplete="off" id="registration_no" type="text" class="form-control input-height" placeholder="{{verify_data?.hint}}"/>
          <label style="color:red; font-size:12px">{{err_msg}}</label>
        </div>
    </div>


    <!-- <p class="or" style="margin-bottom: 32px !important;"><span class="text-light-grey">OR</span></p>
    <a style="padding: 13px 5px; height: auto;" class="form-control cursor-pointer mb-3 mt-3 text-success text-center bold">
        <img src="assets/images/onboarding/upload-mrn.svg" class="mr-1" style="margin-top: -3px;" width="20px" /> Upload MRN Photo</a> -->



   <label style="color:red">{{api_err_msg}}</label>
        <button  [disabled]="showColor==false" [ngClass]=" showColor ? 'primary cursor-pointer' : 'secondary'   "  type="button" class="btn btn-success full-width input-height" (click)="userRegister()">
                <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{mls?.languageText?.upload_mrn?.key1}}<span
                *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key1)</span></button>
   <!-- end form -->
   </div>


   <div class="pl-5 pr-5 pb-3" *ngIf="this.verify_data.key!='email'">
   <p class="text-center text-light-grey"><small>{{mls?.languageText?.upload_mrn?.key2}}<span
    *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key2)</span></small></p>
   <!-- slider -->
   <mdb-carousel [isControls]="true" [animation]="'slide'">
      <mdb-carousel-item>
        <div class="row">
<div class="col-1"></div>
<div class="col-10">
    <div class="bg p-3 pl-4 pr-4">
        <div class="row">
          <div class="col-3 pr-0"><img src="../../../../assets/images/onboarding/feature_small.svg" width="100px"/></div>
          <div class="col-9"><p><b>{{mls?.languageText?.upload_mrn?.key3}}<span
            *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key3)</span></b></p>
            <p class="desc"><small>{{mls?.languageText?.upload_mrn?.key4}}<span
              *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key4)</span><br/> {{mls?.languageText?.upload_mrn?.key5}}<span
              *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key5)</span></small></p></div>
        </div>
    </div>
</div>
<div class="col-1"></div>
        </div>

      </mdb-carousel-item>
      <mdb-carousel-item>
          <div class="row">
              <div class="col-1"></div>
              <div class="col-10">
                  <div class="bg p-3 pl-4 pr-4">
                      <div class="row">
                        <div class="col-3 pr-0"><img src="../../../../assets/images/onboarding/feature_small.svg" width="100px"/></div>
                        <div class="col-9"><p><b>{{mls?.languageText?.upload_mrn?.key6}}<span
                          *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key6)</span></b></p>
                          <p class="desc"><small>{{mls?.languageText?.upload_mrn?.key7}}<span
                            *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key7)</span><br/> {{mls?.languageText?.upload_mrn?.key8}}<span
                            *ngIf="mls?.is_lskey_display==1">(upload_mrn?.key8)</span></small></p>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="col-1"></div>
          </div>
      </mdb-carousel-item>

    </mdb-carousel>
   </div>



   </div>
   </div>

   <app-backscreenmodal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event) [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-backscreenmodal>
