    <div  [ngClass]="{ 'report-container mb-5': callingfrom === 'webProfileSettingFeedback' }">
        <div  [ngClass]="{ 'px-4 pt-4': callingfrom === 'webProfileSettingFeedback' }">
            <p class="heading-4" [ngClass]="{ 'pl-4': callingfrom !== 'webProfileSettingFeedback' }" >{{(product_type==34)?
                mls?.languageText?.report_screen?.key1:mls?.languageText?.report_screen?.key2}}</p>
        </div>
        <div class="px-4 pt-3">
            <div class="form-input mb-3 position-relative">
                <p class="secondary-paragraph mb-2">
                    <span class="color-grey-500">{{mls?.languageText?.report_screen?.key3}}</span><span
                        *ngIf="mls?.is_lskey_display==1">(report_screen?.key3)
                    </span>
                </p>
                <select class="input-field"
                    (change)="onChangeReasion($event.target.value)">
                    <option *ngFor="let reasons of reasonList" value="{{reasons.id}}">{{reasons?.reason}}</option>
                </select>
            </div>
            <div class="form-textarea mb-3">
                <p class="secondary-paragraph mb-2"><span
                    class="color-grey-500">{{mls?.languageText?.report_screen?.key4}}</span><span
                        *ngIf="mls?.is_lskey_display==1">(report_screen?.key4)</span></p>
                <textarea 
                    [(ngModel)]="message" 
                    name="" id="" 
                    class="textarea-field">
                </textarea>
            </div>
        </div>
        <div class="px-4 pb-3 text-left">
            <p class="secondary-paragraph">
                <span class="color-grey-500">
                    {{mls?.languageText?.report_screen?.key6}}
                </span><span *ngIf="mls?.is_lskey_display==1">(report_screen?.key6)</span>
            </p>
            <p><a class="support-link" href="mailto:support@docquity.com">support@docquity.com</a></p>
        </div>
        <div class="pt-2 pb-4 px-4 text-right">
            <button *ngIf="product_type==34" (click)="sendFeedback()"
                [eventTracker]='{"category":mls.googleEventText.feedback_screen.category,"action":mls.googleEventText.feedback_screen.key1,"product_type":"", "product_id":""}'
                class="btn-design medium filled pl-5 pr-5 ">
                <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                {{mls?.languageText?.report_screen?.key5}}<span
                    *ngIf="mls?.is_lskey_display==1">(report_screen?.key5)</span></button>
    
            <button *ngIf="product_type!=34" (click)="sendFeedback()"
                [eventTracker]='{"category":mls.googleEventText.report_screen.category,"action":mls.googleEventText.report_screen.key1,"product_type":product_type, "product_id":product_type_id}'
                class="btn-design medium filled pl-5 pr-5 ">
                <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                {{mls?.languageText?.report_screen?.key5}}<span
                    *ngIf="mls?.is_lskey_display==1">(report_screen?.key5)</span></button>
        </div>
    </div>