/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Rxjs imports.
 */
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Constant imports.
 */
import { HEADERS } from './constants/chat.constants';

/**
 * Chat service for the api call.
 */
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  /**
   * Necessary instances.
   */
  constructor(private httpClient: HttpClient) { }

  /**
   * Search users for connection and suggestion list.
   */
  searchUser(url, params, headers): Observable<any> {
    return this.httpClient.get(url, {
      headers,
      params
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Search the other users.
   */
  searchOtherUsers(url, headers, body): Observable<any> {
    return this.httpClient.post(url, body, {
      headers: { ver: HEADERS.VER_4_3 }
    }).pipe(
      map(response => response),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Check for the Samvaad Notification.
   */
  isSamvaadNotification(isSamvaadRequest: boolean): boolean {
    return isSamvaadRequest;
  }

  /**
   * Get the notification detail for the samvaad.
   */
  getSamvadNotification(info, additionalInfo, isP2p): any {
    const samInfo = additionalInfo;
    const notifyInfo = this.getSamUserInfo(samInfo, isP2p);
    return {
      data: {
        message: (isP2p ? notifyInfo.content : (additionalInfo?.fullName ? (additionalInfo.fullName + ": ") : '') + notifyInfo.content),
        title: notifyInfo.from ? notifyInfo.from : (isP2p ? info.message : info.title),
        identifier: info.identifier,
        target: samInfo?.topic,
        notification_pic: '',
        img: info?.notification_pic,
        target_url: samInfo?.topic,
        isSamvaadNotify: true
      }
    }
  }

  /**
   * Get the user and content information for the samvaad.
   */
  getSamUserInfo(samInfo, isP2p): any {
    let message = { from: samInfo.topic, content: JSON.parse(samInfo.content) };
    if (message.content.ent && message.content.ent[0].tp == "IM") {
      message.content = 'Image';
    } else {
      message.content = message.content.txt;
    }
    message = {
      content: message.content,
      from: isP2p ? samInfo?.fullName : samInfo?.name
    }
    return message;
  }
}
