/**
 * Angular imports.
 */
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';

/**
 * Rxjs imports.
 */
import { Subscription, timer as observableTimer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Service imports.
 */
import { GetService } from '../../dataservices/get.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { WebsocketService } from '../../dataservices/websocket.service';
import { AuthService } from '../../auth/auth.service';
import { ConnectionService } from 'ng-connection-service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * component imports.
 */
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

/**
 * Webinar constant imported.
 */
import { WEBINAR_STATUS, DEVICE_TYPE, SCREEN_NAME, PRODUCT_TYPE_CONSTANT } from '../../constants/app.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';
/**
 * Model imports
 */
import { AnalyticsInterface } from '../../analytics-event/analytics.model';
/**
 * LiveComment Component
 */
@Component({
  selector: 'app-live-comment',
  templateUrl: './live-comment.component.html',
  styleUrls: ['./live-comment.component.scss']
})
export class LiveCommentComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('element') field: ElementRef;

  @Input() pType;
  @Input() pId;
  @Input() webinarObjData;
  @Input() controlEvent;
  @Input() fullscreenchat;
  @Input() callingFrom: string;
  /**
   * Input used to modify live chat on mobile.
   */
  @Input() mobileLiveChat: string;
  /**
   * Output used to emit status for designed live chat.
   */
  @Output() closeMobileLiveChat: EventEmitter<string> = new EventEmitter<string>();

  public comment_box_obj = { profile_pic: '', product_type_id: '', product_id: '' };
  public commentObj; // Comment Component Object
  public offset = 1;
  public room_name = '';
  public commentMsg = '';
  public comment_list_api_in_process = false;
  public requestBody = {
    'user_auth': '',
    'roomname': '',
    'comment': '',
    'product_type': 0,
    'product_type_id': 0,
    'unique_code': ''
  };
  // recall_comment_list_time=0;
  public isConnected = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public timer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subscription: any;
  public authkey = '';
  public error_modal = {
    show: false,
    message: '',
  };
  public deviceType: string;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  @Output() controlsEmitchat = new EventEmitter();

  @ViewChild('scroll', { static: true }) scroller: VirtualScrollerComponent;

  /**
   * Device types from constant.
   */
  public deviceTypeConst = DEVICE_TYPE;
  /**
   * session time object.
   */
  public sessiontime = { start_time: '', end_time: '' };
 /**
  * Analytics object.
  */
 public analyticsWebinarComment: AnalyticsInterface;

  /**
   * Created all required instances.
   */
  constructor(private masterService: MasterserviceService, private connectionService: ConnectionService, private authService: AuthService, public websocketService: WebsocketService, private dss: DataStorageService, private getSer: GetService, public fcs: FunCollectionService, private deviceSer: DeviceDetectorService, public mls: NewLanguageService, private jwtSer: JwtDecodeService, private analyticsEventService: AnalyticsEventService) {
    this.authkey = this.authService.getToken();
    this.subscriptions.add(this.connectionService.monitor().pipe(debounceTime(1000))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((isConnected: any) => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.websocketService.comment_list = [];
          this.websocketService.webinar_live_comment_list = {};
          this.websocketService.live_comment_offset = 1;
          this.websocketService.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
          this.getLiveCommentList();
        }
      }));


    this.websocketService.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;

    this.timer = observableTimer(0, 1000);
    this.subscription = this.timer.subscribe( () => {
      if (environment.ideal_state.current_page == 'webinar_detail' || environment.ideal_state.current_page == 'Webinar_Detail') {
        this.websocketService.recall_comment_list_time--;
        if (this.websocketService.recall_comment_list_time <= 0) {
          this.websocketService.comment_list = [];
          this.websocketService.live_comment_offset = 1;
          this.websocketService.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
          document.getElementById('cmmnt-top-live')?.classList?.add('fadeIn');
          this.getLiveCommentList();
          this.websocketService.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
        }
      }
    });
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (this.deviceSer.isMobile() == true) {
      this.deviceType = 'mobile';
    }
    this.assignProfileObj();


  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    document.querySelector('.chat-div').addEventListener('scroll', () => {
      const height = document.querySelector('.chat-div')?.scrollTop;
      if (height > 100) {
        document.getElementById('cmmnt-top-live')?.classList?.add('fadeIn');
        document.getElementById('cmmnt-top-live').style.display = 'block';
      } else {
        document.getElementById('cmmnt-top-live').style.display = 'none';
      }
    });
    this.srolltopcommnt();
  }

  /**
   * assign Profile Obj
   */
  assignProfileObj(): void {
    const profile = {
      custom_id: this.dss.user_profile_data.profile.custom_id,
      permission: this.dss.user_profile_data.profile.permission,
      full_name: this.dss.user_profile_data.profile.first_name + ' ' + this.dss.user_profile_data.profile.middle_name + ' ' + this.dss.user_profile_data.profile.last_name,
      registered_name: this.dss.user_profile_data.profile.registered_name,
      profile_pic: this.dss.user_profile_data.profile.profile_pic_path,
      specialities: this.dss.user_profile_data.speciality
    };
    this.websocketService.current_comment_obj['user_profile'] = profile;
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    if (this.webinarObjData.status === WEBINAR_STATUS.LIVE) {
      this.websocketService.product_type = this.pType;
      this.websocketService.product_type_id = this.pId;
      this.room_name = 'webinar-' + this.pId + '-' + this.mls.selected_lang;
      this.websocketService.roomName = this.room_name;
      this.websocketService.comment_list = [];
      this.websocketService.live_comment_offset = 1;

      this.getLiveCommentList();
    } else {
      this.comment_box_obj.product_type_id = this.webinarObjData.product_type;
      this.comment_box_obj.product_id = this.webinarObjData.webinar_id;
      this.comment_box_obj.profile_pic = this.dss.user_profile_data.profile_pic;
      this.commentObj = { 'product_id': this.webinarObjData.webinar_id, 'product_type': this.webinarObjData.product_type, 'comment': [], 'total_comment': 1, 'kind': '' };
      this.getCommentList();

    }
  }

  /**
   * get Live CommentList
   */
  getLiveCommentList(): void {
    this.error_modal.message = '';
    this.error_modal.show = false;
    this.comment_list_api_in_process = true;

    if (this.websocketService.live_comment_offset == 1) {
      this.websocketService.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
      this.srolltopcommnt();
    }
    this.subscriptions.add(this.getSer.getliveCommentList(this.websocketService.live_comment_offset, this.pType, this.websocketService.product_type_id)
      .subscribe(data => {
        this.comment_list_api_in_process = false;
        if (data.status === 1) {
          if (this.websocketService.live_comment_offset === 1) {
            this.websocketService.comment_list = [];
            this.websocketService.webinar_live_comment_list = {};
          }

          if ((data.data.offset > this.websocketService.live_comment_offset || data.data.offset === 0)
            && (this.websocketService.live_comment_offset !== data.data.offset)) {
            data.data.comment.forEach(element => {
              if (this.websocketService.webinar_live_comment_list[element.unique_code] == undefined) {
                this.websocketService.comment_list = this.websocketService.comment_list.concat(element);
              }
            });
            Object.keys(this.websocketService.comment_list).map(
              (object) => {
                this.websocketService.comment_list[object]['has_send'] = true;
              });

            data.data.comment.forEach(commentData => {
              this.websocketService.webinar_live_comment_list[commentData.unique_code] = commentData;
            });

            this.websocketService.live_comment_offset = data.data.offset;
          }

        } else if (data.status == 0) {
          this.error_modal.message = this.mls.languageText.comment_card.key23;
          this.error_modal.show = true;
        }
      }, () => {
        this.error_modal.message = this.mls.languageText.comment_card.key23;
        this.error_modal.show = true;
        this.comment_list_api_in_process = false;
      }));
  }

  /**
   * reset SuggList
   */
  resetSuggList(): void {
    // No Code
  }

  /**
   * ronScroll Down
   */
  onScrollDown(): void {
    if (this.websocketService.live_comment_offset == 0) { return; }
    this.getLiveCommentList();
  }

  /**
   * post Live Comment
   */
  postLiveComment(e): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    e.target.value = e.target.value.trim();
    if (e.target.value == '' || e.target.value == undefined) { return; }
    e.target.focus();

    this.assignProfileObj();
    const dt = new Date();
    const random = 'web_' + btoa(dt.getTime().toString()) + dt.getTime().toString();
    this.requestBody = {
      'user_auth': this.authkey,
      'roomname': this.websocketService.roomName,
      'comment': e.target.value,
      'product_type': this.pType,
      'product_type_id': this.pId,
      'unique_code': random
    };
    this.websocketService.postComment(this.requestBody);
    e.target.value = '';
  }

  /**
   * set Comment
   */
  setComment(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    document.querySelector<HTMLInputElement>('#livecmt').value = document.querySelector<HTMLInputElement>('#livecmt').value.trim();
    const msg = document.querySelector<HTMLInputElement>('#livecmt').value;
    document.querySelector<HTMLInputElement>('#livecmt')?.focus();
    this.assignProfileObj();
    if (msg == '' || msg == undefined) { return; }

    const dt = new Date();
    const random = 'web_' + btoa(dt.getTime().toString()) + dt.getTime().toString();

    this.requestBody = {
      'user_auth': this.authService.getToken(),
      'roomname': this.websocketService.roomName,
      'comment': msg,
      'product_type': this.pType,
      'product_type_id': this.pId,
      'unique_code': random
    };
    this.websocketService.postComment(this.requestBody);
    document.querySelector<HTMLInputElement>('#livecmt').value = '';
  }

  /**
   * sroll top commnt
   */
  srolltopcommnt(): void {
    setTimeout(() => {
      this.fcs.scrollTo(document.querySelector('.chat-div'), 'top');
    }, 500);
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * add Key Value
   */
  addKeyValue(obj, key, data): void {
    obj[key] = data;
  }

  /**
   * retry Comment Post
   */
  retryCommentPost(cmtObj): void {
    this.assignProfileObj();
    const random = cmtObj.comment_id;

    this.requestBody = {
      'user_auth': this.authService.getToken(),
      'roomname': this.websocketService.roomName,
      'comment': cmtObj.comment,
      'product_type': this.pType,
      'product_type_id': this.pId,
      'unique_code': random
    };
    this.websocketService.postComment(this.requestBody);
  }

  /**
   * reset Comment
   */
  resetComment(): void {
    this.commentObj.total_comment = this.commentObj.total_comment + 1;
  }

  /**
   * reset Cmt List
   */
  resetCmtList(): void {
    this.commentObj.total_comment = this.commentObj.total_comment - 1;
  }

  /**
   * get Comment List
   */
  getCommentList(): void {
    this.subscriptions.add(this.getSer.getProductCommentList(27, this.webinarObjData.webinar_id, 1).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data.status == 1) {
          this.commentObj = { comment_flag: 1, 'product_id': this.webinarObjData.webinar_id, 'product_type': 27, 'comment': [], 'total_comment': data.data.total_count, 'kind': '' };

        } else {
          this.fcs.errorHandeler(data);
        }
      });
    }));
  }

  /**
   * Analytics object.
   */
  closelivechat(device: string): void {
    this.controlsEmitchat.emit(false);
    if ( device === this.deviceTypeConst.MOBILE ) {
      this.closeMobileLiveChat.emit('close-chat');
    }
  }
  /**
   * Method for analytics
   */
  generateAnalytic(): void {
    if (this.callingFrom === SCREEN_NAME.WEBINAR_DETAIL) {
      this.analyticsEventService.sendAnalytics(EVENT_NAME.WEBINAR_COMMENT_CLICK,
      PRODUCT_TYPE_CONSTANT.WEBINAR,
      this.pId,
      SCREEN_NAME.WEBINAR_DETAIL);
  }}
}
