/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Library imports.
 */
import { Topic } from '@samvaad/client';

/**
 * Interface imports.
 */
import { ChatList } from '../modal/chat.interface';

/**
 * Constant imports.
 */
import { CHAT_TEXT_TYPE } from '../lib/constants/chat.constants';

/**
 * Get only the group list.
 */
@Pipe({
  name: 'onlyGroupList',
  pure: false
})
export class OnlyGroupListPipe implements PipeTransform {

  transform(chatList: ChatList[]): ChatList[] {
    if (chatList) {
      const groupList = chatList.filter(value => Topic.topicType(value?.topic) === CHAT_TEXT_TYPE.GROUP);
      return groupList;
    } else {
      return;
    }
  }
}
