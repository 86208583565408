/**
 * Angular imports.
 */
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Module imports.
 */
import { DirectivesModule } from './../../directives/directives.module';

/**
 * Component imports.
 */
import { ProductReportComponent } from './product-report.component';



@NgModule({
  declarations: [
    ProductReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [
    ProductReportComponent
  ]
})
export class ProductReportModule { }
