import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {

  @Input() directiveStatus: boolean;


  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if(this.directiveStatus == true)
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if(this.directiveStatus == true)
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if(this.directiveStatus == true)
    e.preventDefault();
  }
}