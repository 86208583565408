/**
 * Angular imports.
 */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * service imports.
 */
import { OpenAccessService } from '../open-access.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';

/**
 * Constant imports
 */
import { LOCAL_STORAGE, NUMBERS, OPEN_ACCESS, PRODUCT_TYPE_CONSTANT } from '../../constants/app.constants';
/**
 * Interface imports
 */
import { openArticleDetail } from '../../onboarding/interface/global.model';
import { IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';
import { PurpleOnboarding } from '../purple-onboarding.service';
/**
 * rxjs imports
 */
import { Subscription } from 'rxjs';



/**
 * Component for displaying public page content.
 */
@Component({
  selector: 'app-public-page-content',
  templateUrl: './public-page-content.component.html',
  styleUrls: ['./public-page-content.component.scss']
})
export class PublicPageContentComponent implements OnInit {

  @ViewChild('articleloginContainer') articleloginContainer!: ElementRef;


  /**
   * Property to store the context from which this component is called.
   */
  public callingFrom: string;

  /**
   * Property to store the details of the open article.
   */
  public articleDetail: openArticleDetail;

  /**
   * Property to store the ID of the article.
   */
  public articleId: string;

  /**
   * Constants for numbers.
   */
  public numbers = NUMBERS;

  public apiInProcess = false;

  public restrictContent = false;
  public restrictData: any;
  public singInRecord: IPublicAnalyticsData;

  public subscription: Subscription;

  /**
   * Constructor to inject OpenAccessService and ActivatedRoute.
   * @param oas - Instance of OpenAccessService.
   * @param route - Instance of ActivatedRoute.
   */
  constructor(
    public oas: OpenAccessService,
    public route: ActivatedRoute,
    public mls: NewLanguageService,
    public fcs: FunCollectionService,
    public login : PurpleOnboarding,
  ) { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    /**
     * Subscribe to route parameter changes to update the article ID.
     */
    this.route.paramMap.subscribe(params => {
      this.articleId = params.get('id');
      this.getOpenArticleDetail();
      this.singInRecord = {
        product_type: OPEN_ACCESS.productlist.article,
        product_id: +this.articleId,
        screen_name: screenName.PRODUCT_DETAIL_PAGE,
        event_name: '',
        eventName: EVENT_NAME.SIGNIN_CLICK
      };
    });

    this.subscription = this.login.login$.subscribe(() => {
      this.fcs.navigateToclinicalDetail(this.articleId);
    })

  }

  /**
   * Method to fetch the details of the open article from the OpenAccessService.
   */
  getOpenArticleDetail(): void {
    this.apiInProcess = true;
    this.oas.getOpenArticleDetail(this.articleId, PRODUCT_TYPE_CONSTANT.ARTICLE).subscribe(
      (response: any) => {
        this.apiInProcess = false;
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          this.articleDetail = res.data.response;
          if(this.articleDetail?.login_required == true){
            this.login.callLoginSdk(this.articleloginContainer);
          }
        }
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND_ONE) {
          this.restrictData = res.data.message;
          this.restrictContent = true;

        }
      }
    );
  }

  /**
   * Navigate user to article detail page.
   */
  navigateTologgedInArtcleDetail(): void {
    let mcToken = localStorage.getItem(LOCAL_STORAGE.TOKEN_DETAIL);
    mcToken = mcToken ? JSON.parse(mcToken) : undefined; 
    if (this.oas.toCallLoginSDK() && !mcToken) {
      this.login.callLoginSdk(this.articleloginContainer);
    }
    else {
      this.fcs.navigateToclinicalDetail(this.articleId);
    }
  }

  /**
   * sends share event
   * @param eventName 
   */
  sendShareEvent(eventName: string): void {
    const record: IPublicAnalyticsData = {
      product_type: PRODUCT_TYPE_CONSTANT.ARTICLE,
      product_id: +this.articleId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: ''
    };
    this.oas.sendEvent(eventName, record);
  }

  onCardClick():void {
    this.login.callLoginSdk(this.articleloginContainer);
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
