import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';

/**
 * Services Imports.
 */
import { OpenAccessService } from '../open-access.service';
import { OnboardingService } from '../../dataservices/onboarding/onboarding.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';

/**
 * Rsjs Imports.
 */
import { Subscription } from 'rxjs';


/**
 * Constant imports
 */
import { LOCAL_STORAGE, NUMBERS, OPEN_ACCESS } from '../../constants/app.constants';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';

/**
 * Global Modal imports.
 */
import { openArticle } from './../../onboarding/interface/global.model';
import { IEventName, IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { PurpleOnboarding } from '../purple-onboarding.service';



@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent implements OnInit, OnDestroy {

  @ViewChild('owlItem', { static: false }) owlItem: any;
  @ViewChild('owlCarousel') owlCarousel: CarouselComponent;
  @ViewChild('articleloginContainer') articleloginContainer!: ElementRef;

  public customOptionsForCarousal: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center: false,
    navSpeed: 90,
    navText: ['<i class="prev-slider position-absolute"></i>', '<i class="next-slider position-absolute"></i>'],
    responsive: {
      0: {
        items: 1.33,
        navSpeed: 600
      },

      600: {
        items: 2.33
      },
      940: {
        items: 3.33,
        navSpeed: 600
      }
    },
    nav: true,
    margin: 14,
    // navigation: true,
    autoplay: false,
    autoHeight: true,
    autoWidth: true
  };

  /**
   * Used to hold the api data in saperate array.
   */
  public articeList: any;
  public webinarList: any;
  public doctalkList: any;
  public seriesList: any;

  /**
   * Used to hold const values.
   */
  public numberConst = NUMBERS;
  public openAccessConst = OPEN_ACCESS;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();





  /**
   * object for passing open article data
   */
  public openArticle: openArticle;

  /**
   * Flag for api process.
   */
  public apiInProcess = false;

  /**
   * card container.
   */
  public textContainer = { width: '306px' };

  /**
   * Receive Params.
   */
  public queryParams = [];

  public isCarousel = true;

  public subscription: Subscription;

  /**
   * contain header events
   */
  private readonly eventName: Map<number, IEventName> = new Map([
    [OPEN_ACCESS.productlist.community, EVENT_NAME.COMMUNITY_ICON_CLICK],
    [OPEN_ACCESS.productlist.doctalk, EVENT_NAME.DOCTALKS_ICON_CLICK],
    [OPEN_ACCESS.productlist.article, EVENT_NAME.ARTICLES_ICON_CLICK],
    [OPEN_ACCESS.productlist.webinar, EVENT_NAME.WEBINAR_ICON_CLICK]
  ]);

  /**
   * article card event
   */
  public pArticleAnalytic: IPublicAnalyticsData = {
    screen_name: screenName.RESOURCE_LISTING,
    event_name: '',
    eventName: EVENT_NAME.CARD_CLICK,
    product_type: OPEN_ACCESS.productlist.article,
    product_id: 0
  };
  /**
   * doctalk card event
   */
  public pDoctalkAnalytic: IPublicAnalyticsData = {
    screen_name: screenName.RESOURCE_LISTING,
    event_name: '',
    eventName: EVENT_NAME.CARD_CLICK,
    product_type: OPEN_ACCESS.productlist.doctalk,
    product_id: 0
  };
  /**
   * webinar card event
   */
  public pWebinarAnalytic: IPublicAnalyticsData = {
    screen_name: screenName.RESOURCE_LISTING,
    event_name: '',
    eventName: EVENT_NAME.CARD_CLICK,
    product_type: OPEN_ACCESS.productlist.webinar,
    product_id: 0
  };
  /**
   * series card event
   */
  public pSeriesAnalytic: IPublicAnalyticsData = {
    screen_name: screenName.RESOURCE_LISTING,
    event_name: '',
    eventName: EVENT_NAME.CARD_CLICK,
    product_type: OPEN_ACCESS.productlist.series,
    product_id: 0
  };

  constructor(
    public onboardingSer: OnboardingService,
    public oas: OpenAccessService,
    public fcs: FunCollectionService,
    public mls: NewLanguageService,
    public activatedRoute: ActivatedRoute,
    private paes: PublicAnalyticsEventService,
    public login: PurpleOnboarding
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.resetData();
      this.login.loaderSwitch = false;
      if (params['dt']) {
        this.queryParams.push(params['dt']);
        this.oas.selectedTab = params['dt'];
      }
      if (params['sr']) {
        this.queryParams.push(params['sr']);
        this.oas.selectedTab = params['dt'];
      }
      this.getProductList();
    });
  }

  ngOnInit(): void {
    this.sendEntryEvent();
    this.subscription = this.login.login$.subscribe(() => {
      this.fcs.navigateToAppProductDetail(this.oas.openArticle.product_type, this.oas.openArticle.id);
    })
  }

  /**
   * Method to fetch the list of open articles from the OpenAccessService.
   */
  getProductList(): void {
    this.apiInProcess = true;
    this.subscriptions.add(this.oas.getOpenArticleList(this.queryParams.join(','), this.numberConst.ONE).subscribe(
      (response: any) => {
        this.apiInProcess = false;
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          res.data.response.forEach(element => {
            if (element.key === 'articles') {
              this.articeList = element;
            } else if (element.key === 'webinar') {
              this.webinarList = element;
            } else if (element.key === 'doctalks') {
              this.doctalkList = element;
            } else if (element.key === 'series') {
              this.seriesList = element;
            }
          });
        }
      }
    ));
  }

  /**
   * Reset data.
   */
  resetData(): void {
    this.oas.selectedTab = null;
    this.queryParams = [];
    this.articeList = null;
    this.webinarList = null;
    this.doctalkList = null;
    this.seriesList = null;
  }

  /**
  * Destroy the subscriptions.
  */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.sendExitEvent();
  }
  /**
   * sends header menu click event
   * @param pType
   * @returns
   */
  sendEvent(pType): void {
    if (!this.eventName.has(pType)) {
      return;
    }
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: this.eventName.get(pType)
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.RESOURCE_LISTING_ENTRY,
      extra: JSON.stringify({ unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id })
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.RESOURCE_LISTING_EXIT
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * Article view all click event
   * @returns
   */
  articleRecord(): IPublicAnalyticsData {
    return {
      product_type: OPEN_ACCESS.productlist.article,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.VIEW_ALL_CLICK
    };
  }
  /**
   * webinar view all click event
   * @returns
   */
  webinarRecord(): IPublicAnalyticsData {
    return {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.VIEW_ALL_CLICK
    };
  }
  /**
   * doctalk view all click event
   * @returns
   */
  doctalkRecord(): IPublicAnalyticsData {
    return {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.VIEW_ALL_CLICK
    };
  }

  /**
   * series view all click event
   * @returns
   */
  seriesRecord(): IPublicAnalyticsData {
    return {
      product_type: OPEN_ACCESS.productlist.series,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.VIEW_ALL_CLICK
    };
  }
  /**
   * updates product id in analytic data
   * @param record
   * @returns
   */
  merge(...record): IPublicAnalyticsData {
    return Object.assign({}, ...record);
  }

  /**
   * header analytic event
   * @returns
   */
  getRecord(): IPublicAnalyticsData {
    return {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.RESOURCE_LISTING,
      event_name: '',
      eventName: EVENT_NAME.COUNTRY_DROPDOWN_CLICK
    };
  }

  /**
   * click on each card
   */
  onCardClick(): void {
    this.oas.checkForTokenPresent();
    if (!this.oas.isUserLoggedIn) {
      this.login.callLoginSdk(this.articleloginContainer);
    } else {
      this.fcs.navigateToAppProductDetail(this.oas.openArticle.product_type, this.oas.openArticle.id);
    }
  }

}


