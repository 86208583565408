import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Sponsor } from '../../broadcast/models/sponsor';
import { Subscription, interval } from 'rxjs';

/**
 * This component is used to receive the sponsor data and display it.
 */
@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit, OnDestroy {

  /**
   * receiving all sponsor data;
   */
  @Input() sponsorsLogo: Sponsor[];

  /**
   * emitter to send output data to channel component
   */
  @Output() sponsorLogoAction = new EventEmitter<Sponsor>();

  /**
   * Contains the primary sponsor data
   */
  public primarySponsorList: Sponsor[];

  /**
   * Contains the secondary sponsor data
   */
  public secondarySponsorList: Sponsor[];

  /**
   * Subscription variable for time
   */
  private time: Subscription;

  /**
   * whold the current time
   */
  currentTime: number;

  /**
   * This method will execute on component load and it contain the logic
   * for filter primary and secondary sponsor
   */
  ngOnInit(): void {
    this.primarySponsorList = this.sponsorsLogo
      .filter(obj => obj.isPrimary === 1);
    this.secondarySponsorList = this.sponsorsLogo
      .filter(obj => obj.isPrimary === 0);

    const timeInterval = interval(2000);
    this.time = timeInterval.subscribe(() => {
      this.currentTime = Math.round(Date.now() / 1000);
    });
  }

  /**
   * This method will execute when user click on sponsor logo.
   */
  navigateToSponsorWebsite(data: Sponsor): void {
    this.sponsorLogoAction.emit(data);
    if (data.website != '' && data.website !== null) {
      this.openUrlInNewTab(data.website);
    }
  }

  /**
   * This method is for open url in new tab.
   */
  openUrlInNewTab(url): void {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * Unsubscribe the time variable.
   */
  ngOnDestroy(): void {
    this.time.unsubscribe();
  }
}
