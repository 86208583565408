/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * Service imports.
 */
import { OpenAccessService } from '../open-access.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';

/**
 * Constant imports.
 */

import { NUMBERS, SCREEN_NAME, OPEN_ACCESS } from '../../constants/app.constants';
import { IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';

/**
 * Component for displaying public article content on the login page.
 */
@Component({
  selector: 'app-product-suggestion-list',
  templateUrl: './product-suggestion-list.component.html',
  styleUrls: ['./product-suggestion-list.component.scss']
})
export class PublicArticleLoginContentComponent implements OnInit {

  /**
   * Input property to determine the context from which this component is called.
   */
  @Input() callingFrom: string;
   /**
   * input property to store the list of open articles.
   */
  @Input() suggestionList = [];

  /**
   * Title of suggestion list
   */
  @Input() listTitle: string;

  /**
   * Public property to store the screenName.
   */
  public screenName= SCREEN_NAME;

  /**
   * Receiving product type for suggestion list.
   */
  @Input() pType;

  /**
   * emitting event in case of card click and rendering login sdk
   */
  @Output() cardClicked: EventEmitter<void> = new EventEmitter<void>();

  /**
   * property for numbers
   */
  public numbers = NUMBERS;
  public openAccessConst = OPEN_ACCESS;
  /**
   * Constructor to inject services (FunCollectionService, OpenAccessService).
   * @param fcs - Instance of FunCollectionService.
   * @param oas - Instance of OpenAccessService.
   */
  constructor(
    public fcs: FunCollectionService,
    public oas: OpenAccessService,
    public mls: NewLanguageService
  ) {
  }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    this.scrollToTop();
  }

  /**
   * Method to scroll to the top of the page.
   */
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  /**
   * updates product id in analytic event
   * @param productId 
   * @returns 
   */
  merge(productId: number): IPublicAnalyticsData {
    const record: IPublicAnalyticsData = {
      product_type: this.pType,
      product_id: productId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.CARD_CLICK
    };
    return record;
  }
  /**
   * function to emit card click event
   */
  onCardClick(): void {
    this.cardClicked.emit();
  }
}
