/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

/**
 * Service imports.
 */
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Product Subscribe Component
 */
@Component({
  selector: 'app-product-subscribe',
  templateUrl: './product-subscribe.component.html',
  styleUrls: ['./product-subscribe.component.scss']
})

export class ProductSubscribeComponent implements OnDestroy {
  @Output() resetSubscribeModal = new EventEmitter();
  @Output() hasSubscribed = new EventEmitter();
  @Input() showSubscribeModal: boolean;
  @Input() detailId;
  @Input() channelData;
  @Output() backClicked = new EventEmitter();
  @Input() channelcardcalling;
  public api_in_process = false;
  public isShowTncModal = false;
  public TncMsg: string;
  public tnc_scr: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public tncData: any;
  public displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: []
  };

  public erroMsg = false;
  public showSubscribeChecked = true;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    private _location: Location,
    public multiLangService: MultiLangService,
    public funCollectionService: FunCollectionService,
    public postSer: PostApiService,
    public masterService: MasterserviceService,
    private jwtSer: JwtDecodeService, public mls: NewLanguageService) {
  }

  /**
   * channel Tnc
   */
  channelTnc(): void {
    this.isShowTncModal = true;
  }

  /**
   * hide Tnc Modal
   */
  hideTncModal(): void {
    this.isShowTncModal = false;
  }

  /**
   * show Tnc Modal
   */
  showTncModal(): void {
    this.isShowTncModal = true;
  }

  /**
   * onHidden
   */
  onHidden(): void {
    if (this.channelData.product_view == 1) {
      this.showSubscribeModal = false;
      this.backClicked.emit();
    } else if (this.channelData.product_view == 0) {
      this.showSubscribeModal = false;
      this.backClicked.emit();
      // this._location.back();
    }
  }

  /**
   * show tnc
   */
  showtnc(): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.tncData = { tnc_url: this.channelData.tnc_url, safeurl: this.channelData.safe_url, display_section: 'showtnc' };
    this.displayObj.isShow = true;
    this.displayObj.display_section = 'showtnc';
    this.displayObj.data = this.tncData;
    this.masterService.showWebModal.next(this.displayObj);
  }

  /**
   * show Subscribe
   */
  showSubscribe(safe_url): void {
    if (this.showSubscribeChecked === false) {

      this.erroMsg = true;
    } else {
      this.api_in_process = true;
      this.erroMsg = false;
      this.subscriptions.add(this.postSer.channelSubscribe(safe_url).subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          this.api_in_process = false;
          if (data['status'] == 1) {
            this.hasSubscribed.emit(true);
            this.resetSubscribeModal.emit(true);
            this.showSubscribeModal = false;
            this.funCollectionService.showSuccess(data['msg'], 'Success');

            if (this.channelData.calling_from == 'clinical_notes_list') {
              this.funCollectionService.navigateToJournalDetail(this.channelData.target_id_clinical_detail);
            } else if (this.channelData.calling_from == 'cme_list') {
              this.funCollectionService.navigateToCmeDetail(this.channelData.target_id_clinical_detail);
            } else if (this.channelcardcalling == 'doctalkCard') {
              this.funCollectionService.navigateToDoctalkDetail(this.detailId, 24);
            } else if (this.channelcardcalling == 'webinarCard') {
              this.funCollectionService.navigateToWebinarDetail(this.detailId);
            } else if (this.channelcardcalling == 'feedcard') {
              this.funCollectionService.navigateToFeedDetail(this.detailId);
            }
          } else if (data['status'] == 0) {
            this.funCollectionService.errorHandeler(data);
          }
        });
      }));
    }
  }

  /**
   * change
   */
  change(e): void {
    if (e.target.checked == true) {
      this.showSubscribeChecked = true;
      this.erroMsg = false;
    } else {
      this.showSubscribeChecked = false;
    }
  }

  /**
   * cancel Subscribe modal
   */
  cancelSubscribemodal(): void {
    if (this.channelData.product_view == 1) {
      this.showSubscribeModal = false;
      this.resetSubscribeModal.emit(true);
      this.backClicked.emit();
    } else if (this.channelData.calling_from == 'webinar_deatil') {
      this.funCollectionService.navigateToWebinarlist();
    } else if (this.channelData.calling_from == 'doctalk_detail') {
      this.funCollectionService.navigateToDoctalk();
    } else if (this.channelData.calling_from == 'clinical_detail') {
      this.funCollectionService.navigateToJournalDetail(0);
    } else if (this.channelData.calling_from == 'clinical_notes_list' || this.channelData.calling_from == 'cme_list') {
      this.showSubscribeModal = false;
      this.resetSubscribeModal.emit(true);
      this.backClicked.emit();
    } else if (this.channelData.product_view == 0 && this.channelData.calling_from == 'cme_list_deatil') {
      this.funCollectionService.navigateToCme();
      return;

    } else if (this.channelData.product_view == 0 && this.channelData.calling_from != 'clinical_notes_list') {

      this.showSubscribeModal = false;
      this.resetSubscribeModal.emit(true);
      this.backClicked.emit();
      // this._location.back();
    } else if (this.channelData.is_product_view == 0 && (this.channelcardcalling == 'doctalkCard' || this.channelcardcalling == 'webinarCard' || this.channelcardcalling == 'feedcard')) {

      this.showSubscribeModal = false;
      this.resetSubscribeModal.emit(true);
      this.backClicked.emit();
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.showSubscribeModal = false;
    this.resetSubscribeModal.emit(false);
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
