import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {

  transform(value: string, size: number): string {
    const result = value.length > size ?
                      value.slice(0, size) + '...' :
                      value;
    return result;
  }
}
