/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Basic profile information.
 */
@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent {

  /**
   * Pic for the profile information.
   * Badge Pic for the profile information.
   * Name text for the profile information.
   * Secondary text for the profile information.
   * Show/Hide the bold for the secondary text.
   */
  @Input() pic: string;
  @Input() badgePic: string;
  @Input() name: string;
  @Input() nameCss = 'paragraph text-truncate font-weight-bold';
  @Input() secondaryCss = 'secondary-paragraph text-truncate';
  @Input() secondary: string;
  @Input() isBold: boolean;
  @Input() defaultSrc = 'assets/samvaad-assets/images/icons/user-profile-placeholder.svg';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAvatarClick: EventEmitter<void> = new EventEmitter();

  /**
   * Emit the avatar click event.
   */
  emitAvatarClick(): void {
    this.onAvatarClick.emit();
  }

  setDefaultSrc(event): void {
    event.target.src = this.defaultSrc;
  }
}
