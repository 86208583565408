<app-public-page-header (emitIconClick)="sendEvent($event)" [analytic]="getRecord()"
[communityText]="mls.languageText.open_article.key10"
[doctalkText]="mls.languageText.open_article.key11"
[articleText]="mls.languageText.open_article.key12"
[webinarText]="mls.languageText.open_article.key13"></app-public-page-header>

<div class="container">
  <div class='text-center' *ngIf="login.loaderSwitch">
    <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post">
</div>
<div *ngIf="!login.loaderSwitch">
  <div *ngIf="webinarList?.list.length || doctalkList?.list.length || articeList?.list.length">
  <!-- Section 1 start-->
  <div *ngIf="articeList?.list.length > numberConst.ZERO">
    <div class="clearfix mb-3 py-1 mt-4 ">
      <div>
        <div class="d-flex align-items-center">
          <span class="heading-4">{{articeList?.label}}</span>
          <button *ngIf="articeList?.list.length > numberConst.THREE "
              (click)="fcs.navigateToOpenProductList(openAccessConst.productlist.article)"
              class="ml-auto btn-design small outline bg-transparent"
              [pAppAnalyticsEvent]="articleRecord()">{{mls.languageText.open_article.key15}}</button>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="custom-owl-carousel bg-transparent shadow-none p-0 carousel-wrapper">
        <owl-carousel-o [options]="customOptionsForCarousal">
          <ng-template carouselSlide *ngFor="let card of articeList?.list">
            <div >
              <app-public-article-login-card
                [analyticRecord]="merge(pArticleAnalytic, {product_id: card.id})"
                [openArticle]="card"
                [callingFrom]="callingFrom"
                [renderType]="openAccessConst.prodListRenderType.product_list"
                [pType]="openAccessConst.productlist.article"
                [textContainer]="textContainer"
                [isCarousel]="isCarousel"
                (cardClicked)="onCardClick()"
                >
              </app-public-article-login-card>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>

  <!-- Section 2 start-->
  <div *ngIf="doctalkList?.list.length > numberConst.ZERO">
    <div class="clearfix mb-3 py-1 mt-4 ">
      <div>
        <div class="d-flex align-items-center">
          <span class="heading-4">{{doctalkList?.label}}</span>
          <button *ngIf="doctalkList?.list.length > numberConst.THREE"
              (click)="(queryParams.length > 0)? fcs.navigateToOpenProductList(openAccessConst.productlist.doctalk): fcs.navigateToOpenProductList(openAccessConst.productlist.doctalk, openAccessConst.productlist.series)"
              class="ml-auto btn-design small outline bg-transparent"
              [pAppAnalyticsEvent]="doctalkRecord()">{{mls.languageText.open_article.key15}}</button>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="custom-owl-carousel bg-transparent shadow-none p-0 carousel-wrapper">
        <owl-carousel-o [options]="customOptionsForCarousal">
          <ng-template carouselSlide *ngFor="let card of doctalkList?.list">
            <div>
              <app-public-article-login-card
                [analyticRecord]="merge(pDoctalkAnalytic, {product_id: card.id})"
                [openArticle]="card"
                [callingFrom]="callingFrom"
                [renderType]="openAccessConst.prodListRenderType.product_list"
                [pType]="openAccessConst.productlist.doctalk"
                [textContainer]="textContainer"
                [isCarousel]="isCarousel"
                (cardClicked)="onCardClick()">
              </app-public-article-login-card>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>

    <!-- Section 3 start-->
    <div *ngIf="webinarList?.list.length > numberConst.ZERO">
      <div class="clearfix mb-3 py-1 mt-4 mb-4">
        <div>
          <div class="d-flex align-items-center">
            <span class="heading-4">{{webinarList?.label}}</span>
            <button *ngIf="webinarList?.list.length > numberConst.THREE"
              (click)="fcs.navigateToOpenProductList(openAccessConst.productlist.webinar)"
              class="ml-auto btn-design small outline bg-transparent"
              [pAppAnalyticsEvent]="webinarRecord()">{{mls.languageText.open_article.key15}}</button>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div class="custom-owl-carousel bg-transparent shadow-none p-0 carousel-wrapper">
          <owl-carousel-o [options]="customOptionsForCarousal">
            <ng-template carouselSlide *ngFor="let card of webinarList?.list">
              <div>
                <app-public-article-login-card
                  [analyticRecord]="merge(pWebinarAnalytic, {product_id: card.id})"
                  [openArticle]="card"
                  [callingFrom]="callingFrom"
                  [renderType]="openAccessConst.prodListRenderType.product_list"
                  [pType]="openAccessConst.productlist.webinar"
                  [textContainer]="textContainer"
                  [isCarousel]="isCarousel"
                  (cardClicked)="onCardClick()">
                </app-public-article-login-card>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>

    <!-- Section 4 start-->
    <div *ngIf="seriesList?.list.length > numberConst.ZERO">
      <div class="clearfix mb-3 py-1 mt-4 ">
        <div>
          <div class="d-flex align-items-center">
            <span class="heading-4">{{seriesList?.label}}</span>
            <button *ngIf="seriesList?.list.length > numberConst.THREE"
              (click)="fcs.navigateToOpenProductList(openAccessConst.productlist.series)"
              class="ml-auto btn-design small outline bg-transparent"
              [pAppAnalyticsEvent]="seriesRecord()">{{mls.languageText.open_article.key15}}</button>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div class="custom-owl-carousel bg-transparent shadow-none p-0 carousel-wrapper">
          <owl-carousel-o [options]="customOptionsForCarousal">
            <ng-template carouselSlide *ngFor="let card of seriesList?.list">
              <div>
                <app-public-article-login-card
                  [analyticRecord]="merge(pSeriesAnalytic, {product_id: card.id})"
                  [openArticle]="card"
                  [callingFrom]="callingFrom"
                  [renderType]="openAccessConst.prodListRenderType.product_list"
                  [pType]="openAccessConst.productlist.series"
                  [textContainer]="textContainer"
                  [isCarousel]="isCarousel"
                  (cardClicked)="onCardClick()">
                </app-public-article-login-card>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>

  </div>

    <div *ngIf="!webinarList?.list.length && !doctalkList?.list.length && !articeList?.list.length && !apiInProcess" class="no-data-found text-center mt-4">
     {{mls.languageText.open_article.key29}}
    </div>

  <div *ngIf="apiInProcess" class="text-center mt-4">
    <img src="assets/themes/images/img-video-loader.gif" width="40" alt="loader" />
  </div>
</div>
</div>

<app-public-skip-popup 
(loginClicked)="onCardClick()">
</app-public-skip-popup>

<div #articleloginContainer class="d-none"></div>