/**
 * angular imports
 */
import { Injectable } from "@angular/core";
/**
 * library imports
 */
import * as moment from "moment";
/**
 * import constant
 */
import { hrefRegex } from "../constants/app.constants";

/**
 * survey helper service
 */
@Injectable({
    providedIn: 'root'
  })
  export class SurveyHelperService {
  /**
   * Check if survey is expired or not.
   */
  checkForTheExpiry(utcTimeinMillis: number): boolean {
    const currentTime = moment();
    const expirationTime = moment(utcTimeinMillis);
    const localExpirationTime = expirationTime.local();
    if(currentTime.isBefore(localExpirationTime)) {
        return false;
    } else {
        return true;
    } 
  }

  extractHref(link: string): string {
    const regex = hrefRegex;
    const match = link.match(regex);

    if (match) {
      return match[2];
    } else {
      return ''
    }
  }
}