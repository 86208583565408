/**
 * Angular imports.
 */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { PostwebService } from '../../dataservices/web/postweb.service';
import { OnboardingService } from '../../dataservices/onboarding/onboarding.service';
import { LocalstorageService } from '../../auth/localstorage.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { DataStorageService } from '../../dataservices/data-storage.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
/**
 * Constant imports.
 */
import { USER_TYPES } from './../../constants/app.constants';

/**
 * Speciality Component
 */
@Component({
  selector: 'app-speciality',
  templateUrl: './speciality.component.html',
  styleUrls: ['./speciality.component.scss']
})
export class SpecialityComponent implements OnInit, OnDestroy {
  @Input() isSpecialityShown;
  @Input() speciality_select_type;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public speciality_list: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userFilter: any = { speciality_name: '' };
  public user_speciality = [];
  public api_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  public err_msg = '';
  public loading_data_in_process = false;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances
   */
  constructor(
    public dss: DataStorageService,
    private jwtSer: JwtDecodeService,
    private localStorage: LocalstorageService,
    private webSer: PostwebService,
    public onboardingService: OnboardingService,
    public mls: NewLanguageService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }

    this.getSpecility();

    setTimeout(() => {
      document.addEventListener('DOMContentLoaded', () => {
        document.getElementById('speciality')?.click();
      });
    }, 100);

  }

  /**
   * openModal
   */
  openModal(): void {
    // No Code
  }

  /**
   * get Speciality
   */
  getSpecility(): void {
    this.loading_data_in_process = true;
    this.subscriptions.add(this.webSer.getSpecialityList().subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        this.loading_data_in_process = false;
        if (data['status'] == 1) {
          this.speciality_list = data['data'].speciality_list;
        }
      }, () => {
        this.loading_data_in_process = false;
      });
    }));
    document.getElementById('divToScroll').scrollTop = 0;
  }

  /**
   * onHidden
   */
  onHidden(): void {
    // No Code
  }

  /**
   * select speciality
   */
  select_speciality(event, data): void {
    this.err_msg = '';

    if (event.target.checked && this.speciality_select_type == 'single' && this.user_speciality.length >= 0) {
      this.user_speciality = [];
      this.user_speciality.push(data);
      return;
    }

    if (event.target.checked) {
      this.user_speciality.push(data);
    } else {
      let i = 0;
      for (; i < this.user_speciality.length; i++) {
        if (this.user_speciality[i].speciality_id == data.speciality_id) {
          break;
        }
      }

      this.user_speciality.splice(i, 1);
    }
  }

  /**
   * remove spec
   */
  removespec(idx): void {
    this.err_msg = '';
    this.user_speciality.splice(idx, 1);
  }

  /**
   * make Selected
   */
  makeSelected(data): boolean {
    let i = 0;
    for (; i < this.user_speciality.length; i++) {
      if (this.user_speciality[i].speciality_id == data.speciality_id) {
        return true;
      }
    }

  }

  /**
   * add Speciality
   */
  addSpeciality(): void {
    this.err_msg = '';
    if (this.user_speciality.length == 0) {
      this.err_msg = 'Please select speciality';
      return;
    } else {
      const temp = [];
      this.user_speciality.forEach(el => {
        temp.push(el.speciality_id);
      });

      const user_input_json = [];
      if (this.speciality_select_type == 'single') {
        user_input_json.push({
          'key': 'select_speciality',
          'value': { 'speciality_id': temp }
        });
      } else {
        user_input_json.push({
          'key': 'select_interest',
          'value': { 'interest_id': temp }
        });
      }
      this.api_in_process = true;
      this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, 0).subscribe(registerPromise => {
        this.api_in_process = false;
        registerPromise.then(data => {
          if (data['status'] == 1) {
            sessionStorage.removeItem('verify_mobile');
            localStorage.removeItem('temp_req');

            this.localStorage.setInLocalstorage('newUser', '1');
            if (data['code'] == 2007 || data['code'] == 2006) {
              if ((this.dss.user_profile_data.profile.user_type === 'doctor' || this.dss.user_profile_data.profile.user_type === 'dentist') && this.speciality_select_type === 'single') {
                this.speciality_select_type = 'multiple';
                this.getSpecility();
              } else {
                this.localStorage.setInLocalstorage('uProfile', this.onboardingService.encryptAes(JSON.stringify(data['data'])));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.localStorage.setInLocalstorage('token', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.user_auth_key)));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.localStorage.setInLocalstorage('userpermission', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.permission)));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.localStorage.setInLocalstorage('token', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.user_auth_key)));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.localStorage.setInLocalstorage('profile_obj', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.custom_id)));
                sessionStorage.removeItem('verify_mobile');

                localStorage.removeItem('temp_req');
                localStorage.removeItem('otpToken');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (data['data'].profile_info?.profile?.country_code !== '') {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  localStorage.setItem('country_code', data['data'].profile_info.profile.country_code);
                }
                setTimeout(() => {
                  location.reload();
                }, 500);
              }
              localStorage.removeItem('verify_mobile');

              if (this.dss.user_profile_data.profile.user_type !== USER_TYPES.STUDENT) {
                this.registrationSuccessStatus();
              }
            } else {
              this.localStorage.setInLocalstorage('newUser', '0');
            }
          }
        },
          () => {
            this.api_in_process = false;
          });
      }));
    }
  }

  /**
   * remove Speciality
   */
  removeSpeciality(): void {
    this.err_msg = '';
    this.user_speciality = [];
  }

  /**
   * reset Search Box
   */
  resetSearchBox(): void {
    this.userFilter.speciality_name = '';
    document.getElementById('searchSpe')?.focus();
  }

  /**
   * Skip interest.
   */
  skipInterest(): void {
    sessionStorage.removeItem('verify_mobile');
    localStorage.removeItem('verify_mobile');
    localStorage.removeItem('temp_req');
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  /**
   * Get status of registration.
   */
  registrationSuccessStatus(): void {
    this.localStorage.setInLocalstorage('successScreen', true);
  }

  /**
   * remove white spacee
   */
  removeWhiteSpace(event: string): void {
    this.userFilter.speciality_name = event.trim();
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

