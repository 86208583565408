<div class="profile-info">
  <div class="pic-box cursor-pointer" (click)="$event.preventDefault();$event.stopPropagation();emitAvatarClick()">
    <img class="pic" [src]="pic ? pic : defaultSrc" (error)="setDefaultSrc($event)" alt="pic">
    <img *ngIf="badgePic" class="badge" [src]="badgePic" alt="status">
  </div>
  <div class="ml-2 d-flex flex-column min-width-0 justify-content-center" *ngIf="name">
    <p [class]="nameCss" class="text-truncate">
      {{name}}
    </p>
    <p [class]="secondaryCss" *ngIf="secondary" class="text-truncate">
      <span [ngClass]="{'font-weight-bold': isBold, 'color-grey-500': !isBold}">
        {{secondary}}
      </span>
    </p>
  </div>
</div>
