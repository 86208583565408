/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
/**
 * Import constants.
 */
import { LANG_ABBREVIATIONS } from '../../constants/app.constants';

/**
 * MultiLang Service
 */
@Injectable({
  providedIn: 'root'
})
export class MultiLangService {
  you_have_bookmarked_successfully = '';
  you_have_unbookmarked_successfully = '';
  oops_something_went_wrong_please_try_again = '';
  Are_you_sure_you_want_to_unfollow_this_case = '';
  We_are_but_the_page_you_were_looking_was_not_found = '';
  Go_To_Home_Page = '';
  referal_points = '';
  Collaboration_points = '';
  K305 = '';
  K121 = '';

  // connection
  View_Profile = '';
  Remove_connection: string;
  Rejected: string;
  Connect: string;
  Withdraw: string;
  Reject: string;
  Accept: string;
  Suggestion: string;
  Connection: string;
  Connection_Request: string;
  Sent_Request: string;
  No_Connection: string;
  Keep_yourself_updated_with_the_latest: string;
  medical_trends: string;
  No_Search_Result: string;
  View_More: string;
  No_Connection_Request: string;
  View_All_Requests: string;
  No_Connection_sent: string;
  No_Suggestion: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Search_people: any;

  // onboarding
  Enter_Your_Mobile_Number: string;
  We_will_send_you_a_one_time_verification_code: string;
  Your_details_are_secured_encrypted_We_respect_confidentiality_this_helps_us_confirm_your_identity: string;
  Get_OTP: string;
  English: string;
  Language: string;
  Bahasa: string;
  Share_your_clinical_experiences: string;
  Attempt_Certified_CME_courses: string;
  Read_Medical_Journals_Guidelines: string;
  Attend_Webinars_by_top_Experts: string;
  Verify_Phone_Number: string;
  I_am_a: string;
  OR: string;
  Use_invite_code_to_get_easy_access: string;
  Which_student_you_are: string;
  Whats_your_College_Name: string;
  Upload_College_Id: string;
  What_your_Name: string;
  Benefit_of_Verification: string;
  Verify: string;
  Upload_MRN_Photo: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Search_College: any;

  // survey list
  Saved_List: string;
  Survey_List: string;
  Credits: string;
  T_and_c: string;
  Take_This_Survey: string;

  // clinical resource
  Search: string;
  No_matching_elements: string;
  No_result_found: string;

  // verify process
  Association_Verification: string;
  Next: string;
  Select_your_association: string;
  Selected_Country: string;

  // web-modal
  Info: string;

  // cme
  Attempt_Now: string;
  Reattempt: string;
  Speciality: string;
  Certificates: string;
  Total_CME_points: string;
  Add_CME_SKP_Points: string;
  Source: string;
  Events_End_Date: string;
  Events_Start_Date: string;
  Download_Certificate: string;
  Submit: string;
  Start_date: string;
  End_date: string;
  Points: string;
  Title: string;
  Add_a_heading_of_points: string;
  Add_your_Points: string;
  CME: string;
  View_Results: string;
  Preview_and_Submit: string;
  CME_Details: string;

  // doctalks
  Views: string;
  Speaker_Details: string;
  Doctalks: string;
  Video: string;
  Select_by: string;

  // webinar
  Releasing_Date: string;
  Notify_me: string;
  Notified: string;
  Webinars: string;
  Live: string;
  All: string;
  Releasing_on: string;
  People_Watching: string;
  View_points: string;

  // channel
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Subscribed: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  About_Channel: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Please_select_TC: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  I_have_read_all: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Pharma_Channels: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  About_Pharma: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Clinical_Resources: any;

  // cpmment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  No_comment_yet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Enter_Mobile_Number: any;

  // event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  About_Session: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Speakers: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Mark_Going: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  days_event: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Sessions: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  About_Events: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Image: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PDF: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PAST: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UPCOMING: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GOING: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Missed: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  People_Going: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Related_Article: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Speaker_Profile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  About_Speaker: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Topic_name: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Details: any;

  // posting
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Drafts: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Posting_By: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Annonymous: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Add_Another: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Select_Association: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  No_data_found: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  File_is_uploading: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Type_of_Post: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Is_this_a_case: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Selected: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Request_Processed: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Connect_and_Tag: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Tag_People: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Done: any;

  // drug sample
  Order_Now = '';
  Drug_Sample = '';
  Composition = '';
  Please_enter_your_valid_name = '';
  Plese_enter_your_valid_mobile_number = '';
  Plese_enter_your_valid_Address = '';
  Plese_enter_your_valid_Post_code = '';
  New_Education = '';
  to_present = 't';
  Educational_Details = '';
  more_Education_Details = '';

  New_Experience = '';
  Start_Month = '';
  Month = '';
  Year = '';
  End_Month = '';
  End_Year = '';

  more_Experience_Details = '';
  Interests = '';
  New_Publication = '';
  more_Publication_Details = '';
  Update_Speciality = '';
  Basic = '';

  ago = '';
  new = '';
  Allow_Notification = '';

  Who_viewed_your_profile = '';
  You_may_know = '';
  Profile_Info = '';
  Send_Image = '';
  Minutes = '';
  Play_Episode_Now = '';
  Play_Now = '';
  New_Message = '';
  Created_this_group = '';
  // header
  Logout = '';
  people = '';
  Messages_are_now_secured_with_end_to_end_encrypted = '';
  has_invited_you_to_discuss = '';

  constructor() {
    if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == 'en') {
      this.setLanguage('en');
      localStorage.setItem('lang', 'en');
    } else if (localStorage.getItem('lang') == 'in') {
      this.setLanguage('in');
    } else if (localStorage.getItem('lang') == 'vi') {
      this.setLanguage('vi');
    } else if (localStorage.getItem('lang') == 'ko') {
      this.setLanguage('ko');
    } else if (localStorage.getItem('lang') == 'zh') {
      this.setLanguage('zh');
    } else if (localStorage.getItem('lang') === LANG_ABBREVIATIONS.JAPANESE) {
      this.setLanguage(LANG_ABBREVIATIONS.JAPANESE);
    } else if (localStorage.getItem('lang') === LANG_ABBREVIATIONS.SPANISH) {
      this.setLanguage(LANG_ABBREVIATIONS.SPANISH);
  }
}

  public setLanguage(lang): void {
    if (lang == 'en') {
      this.you_have_bookmarked_successfully = 'You have bookmarked successfully';
      this.you_have_unbookmarked_successfully = 'You have unbookmarked successfully';
      this.oops_something_went_wrong_please_try_again = 'something went wrong please try again';
      this.Are_you_sure_you_want_to_unfollow_this_case = 'Are you sure you want to unfollow this case?';
      this.We_are_but_the_page_you_were_looking_was_not_found = 'We are but the page you were looking was not found';
      this.Go_To_Home_Page = 'Go To Home Page';
      this.referal_points = 'Referal points';
      this.Collaboration_points = 'Collaboration points';
      this.K305 = 'Cancel';
      this.K121 = 'Save';
    } else if (lang == 'in') {
      this.you_have_bookmarked_successfully = 'You have bookmarked successfully';
      this.you_have_unbookmarked_successfully = 'You have unbookmarked_successfully';
      this.oops_something_went_wrong_please_try_again = 'something went wrong please try again';
      this.Are_you_sure_you_want_to_unfollow_this_case = 'Are you sure you want to unfollow this case?';
      this.We_are_but_the_page_you_were_looking_was_not_found = 'Halaman yang ada cari tidak ditemukan';
      this.Go_To_Home_Page = 'Kembali ke rumah';
      this.referal_points = 'Referal points';
      this.Collaboration_points = 'Poin Kolaborasi (Need more info about "collaboration")';
      this.K305 = 'Cancel';
      this.K121 = 'Save';
    } else {
      this.you_have_bookmarked_successfully = 'You have bookmarked successfully';
      this.you_have_unbookmarked_successfully = 'You have unbookmarked successfully';
      this.oops_something_went_wrong_please_try_again = 'something went wrong please try again';
      this.Are_you_sure_you_want_to_unfollow_this_case = 'Are you sure you want to unfollow this case?';
      this.We_are_but_the_page_you_were_looking_was_not_found = 'We are but the page you were looking was not found';
      this.Go_To_Home_Page = 'Go To Home Page';
      this.referal_points = 'Referal points';
      this.Collaboration_points = 'Collaboration points';
      this.K305 = 'Cancel';
      this.K121 = 'Save';
    }
  }

}
