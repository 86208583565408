import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDateTimeOnTimezone'
})
export class GetDateTimeOnTimezonePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(timestamp: any, timezone: any, dtformat: any): any {
   // timestamp=timestamp/1000;
   if (timezone == 'GMT+05:30') {
     timezone = 'asia/kolkata';
   }
//   alert(new Date(new Date(timestamp).toLocaleString("en-ES",{timeZone: timezone})).getTime());
   if (dtformat == 'DWT') {
    return new Date(timestamp).toLocaleString('en-ES', {timeZone: timezone, year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});
   } else if (dtformat == 'D') {
    return new Date(timestamp).toLocaleString('en-ES', {timeZone: timezone, year: 'numeric', month: 'long', day: 'numeric'});
   } else if (dtformat == 'T') {
    return new Date(timestamp).toLocaleString('en-ES', {timeZone: timezone, hour: 'numeric', minute: 'numeric'});
   }
  }

}
