/**
 * Angular Import.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Model Import.
 */
import { FileUpload, UploadedFile } from '../../models/FileUploads';

/**
 * Constant Import.
 */
import { FILE_UPLOAD } from '../../constants/fileUpload.constants';

/**
 * Component used to upload the files.
 */
@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent {

  /**
   * Used to set the total files allow to upload.
   */
  @Input() fileLimit: number;

  /**
   * Used to set the max files size allow to upload in bytes.
   */
  @Input() maxFileSize: number;

  /**
   * Used to set the accepted files extensions.
   */
  @Input() accept: string;

  /**
   * Used to set the multiple file allowed to upload.
   */
  @Input() multiple: boolean;

  /**
   * Used to set upload button content text.
   */
  @Input() uploadButtonContent: string;

  /**
   * Used to set drag and drop content text.
   */
  @Input() dragAndDropContent: string;

  /**
   * Used to set the thumbnail image source.
   */
  @Input() uploadFilesImageSrc: string;

  /**
   * Used to set the support files content text.
   */
  @Input() supportFilesContent: string;

  /**
   * Used to show hide upload button.
   */
  @Input() showUploadButton: boolean;

  /**
   * Used to set the drag and drop container css.
   */
  @Input() dragAndDropContainerCss: string;

  /**
   * Used to set the label Css.
   */
  @Input() labelCss: string;

  /**
   * Used to set the drag and drop content css.
   */
  @Input() dragAndDropContentCss: string;

  /**
   * Used to set the support files content css.
   */
  @Input() supportFilesContentCss: string;

  /**
   * Used to hold the selected file array.
   */
  @Input() selectedFiles: UploadedFile[] = [];

  /**
   * Used to hold the selected file array.
   */
  @Input() disabled = false;

  /**
   * Used to emit the UploadFile List array.
   */
  @Output() emitUploadFileList = new EventEmitter<FileUpload[]>();

  /**
   * Used to hold the single file upload object.
   */
  public uploadFileObj: FileUpload;

  /**
   * Used to hold the selected file array.
   */
  public uploadFiles: FileUpload[] = [];

  /**
   * Used to set error msg for invalid file.
   */
  public errorMsgForInvalidFile: string;

  /**
   * Used to set error msg for Size limit.
   */
  public errorMsgForSizeLimit: string;

  /**
   * Used to set error msg for special character.
   */
  public errorMsgForSpecialCharacter: string;

  /**
   * Used to set error msg for file limit.
   */
  public errorMsgForFileLimit: string;

  /**
   * Used to store the Size limit files array.
   */
  public sizeLimitArray: FileUpload[] = [];

  /**
   * Used to store the valid files array.
   */
  public valid: FileUpload[] = [];

  /**
   * Error Popup.
   */
  public isErrorModal: boolean;
  /**
   * samvaad translations
   */
  @Input() samvaadLanguageText: any = {};

  /**
   * Used to get the files by upload the button and send array for api.
   */
  handleFileInput(ev): void {
    this.valid = [];
    this.sizeLimitArray = [];
    this.errorMsgForInvalidFile = '';
    this.errorMsgForFileLimit = '';
    this.errorMsgForSizeLimit = '';
    this.errorMsgForSpecialCharacter = '';
    this.uploadFiles = [];
    if (ev.target.files) {
      if (ev.target.files.length + this.selectedFiles.length > this.fileLimit) {
        this.errorMsgForFileLimit =
          this.samvaadLanguageText?.samvaad_validation?.key10 + ' ' + this.fileLimit + ' ' + this.samvaadLanguageText?.samvaad?.key37;
        this.isErrorModal = true;
        return;
      } else {
        this.isErrorModal = false;
        this.errorMsgForSpecialCharacter = '';
        this.errorMsgForFileLimit = '';
      }
      for (const data of ev.target.files) {
        const file = data;
        this.commonValidation(file);
      }
      this.getFileUploadStatus(this.uploadFiles);
    }
  }

  /**
   * Used to get the file by drop and send individual file for api.
   */
  dropFiles(ev): void {
    this.valid = [];
    this.sizeLimitArray = [];
    this.errorMsgForInvalidFile = '';
    this.errorMsgForFileLimit = '';
    this.errorMsgForSizeLimit = '';
    this.errorMsgForSpecialCharacter = '';
    this.uploadFiles = [];
    ev.preventDefault();
    if (this.multiple === false) {
      if (ev.dataTransfer.items.length + this.selectedFiles.length > this.fileLimit) {
        this.errorMsgForFileLimit =
          FILE_UPLOAD.ALLOW_ONLY + ' ' + this.fileLimit + ' ' + FILE_UPLOAD.FILES;
        this.isErrorModal = true;
        return;
      } else {
        this.isErrorModal = false;
        this.errorMsgForFileLimit = '';
        this.errorMsgForSpecialCharacter = '';
        if (ev.dataTransfer.items.length > 0) {
          const file = ev.dataTransfer.items[0].getAsFile();
          this.commonValidation(file);
          this.getFileUploadStatus(this.uploadFiles);
        }
      }
    } else {
      if (ev.dataTransfer.items.length + this.selectedFiles.length > this.fileLimit) {
        this.errorMsgForFileLimit =
          FILE_UPLOAD.ALLOW_ONLY + ' ' + this.fileLimit + ' ' + FILE_UPLOAD.FILES;
        this.isErrorModal = true;
        return;
      } else {
        this.isErrorModal = false;
        this.errorMsgForFileLimit = '';
        this.errorMsgForSpecialCharacter = '';
      }
      if (ev.dataTransfer.items) {
        for (const data of ev.dataTransfer.items) {
          if (data.kind === 'file') {
            const file = data.getAsFile();
            this.commonValidation(file);
          }
        }
        this.getFileUploadStatus(this.uploadFiles);
      }
    }
  }

  /**
   * Used to validate the single file for size and filetype.
   */
  commonValidation(file): void {
    this.uploadFileObj = {
      fileName: file.name,
      selectedFile: file,
      fileId: `${file.name}-${file.lastModified}`,
      fileType: file.type,
      fileSize: file.size,
    };

    this.uploadFiles.push(this.uploadFileObj);
    if (this.uploadFileObj.fileType || this.uploadFileObj.fileType === '') {
      const extension = this.uploadFileObj.fileType.split('/');
      if (this.accept.includes(extension[1])) {
        // this.errorMsgForInvalidFile = '';
        // this.isErrorModal = false;
      } else {
        this.valid.push(this.uploadFileObj);
        this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1);
        this.errorMsgForInvalidFile = FILE_UPLOAD.NOT_VALID_FORMAT;
        this.isErrorModal = true;
      }
    }

    if (this.uploadFileObj.fileSize > this.maxFileSize) {
      this.sizeLimitArray.push(this.uploadFileObj);
      this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1);
      this.errorMsgForSizeLimit = FILE_UPLOAD.LARGE_FILE_SIZE;
      this.isErrorModal = true;
    }
  }

  /**
   * Used to handle the dragover.
   */
  dragOverHandler(ev): void {
    ev.preventDefault();
    ev.stopPropagation();
  }

  /**
   * Used to get the upload array.
   */
  getFileUploadStatus(file): void {
    this.emitUploadFileList.emit(file);
  }

  /**
   * Used to delete the file from the list.
   */
  deleteFile(file): void {
    this.isErrorModal = false;
    this.errorMsgForFileLimit = '';
    this.selectedFiles = this.selectedFiles.filter(data => data !== file);
  }
}
