<router-outlet *ngIf="countryCode"></router-outlet>

<app-invite-user-welcome-screen *ngIf="fcs.openAccessWelcomeScreenData?.extras?.welcome_popup"
[drName]="fcs.openAccessWelcomeScreenData?.extras?.name"
[unique_id]="fcs.openAccessWelcomeScreenData?.extras?.id"
[ptype]="fcs.openAccessWelcomeScreenData?.ptype"
[pid]="fcs.openAccessWelcomeScreenData?.pid"
[welcomeMsg]="mls.languageText.open_article.key32"
[greetingText]="mls.languageText.open_article.key31"
[detailMsg]="mls.languageText.open_article.key33"
[carousaldata]="carousaldata"
[screenName]="fcs.openAccessScreenName"
(closeAction)="closeAction($event)"></app-invite-user-welcome-screen>
