import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { Router } from '@angular/router';
// new lanuguage
import {NewLanguageService} from '../../dataservices/new-language/new-language.service';

/**
 * Service import.
 */
import { DqChatPanelService } from 'src/app/samvaad/dq-chat-panel.service';

@Component({
  selector: 'app-backscreenmodal',
  templateUrl: './backscreenmodal.component.html',
  styleUrls: ['./backscreenmodal.component.scss']
})
export class BackscreenmodalComponent {

  @Output() resetConfirmModal = new EventEmitter();
  @Input() isConfirmModalShown: boolean;
  @Input() confirmInfo: string;
  confirmAction = false;

  constructor(private dqChatPanelService: DqChatPanelService, public multiLangService: MultiLangService,  private router: Router, public masterService: MasterserviceService, public mls: NewLanguageService) { }

   resetConfirmBox(): void {
     this.resetConfirmModal.emit(this.confirmAction);
   }

   ActivateAction(): void {
     this.confirmAction = true;
     this.resetConfirmBox();
     this.isConfirmModalShown = false;
     if (localStorage.getItem('lang') != null) {
        const x = localStorage.getItem('lang');
        localStorage.clear();
        this.exitChatPanel();
        localStorage.setItem('lang', x);
      } else {
        localStorage.clear();
        this.exitChatPanel();
      }
     this.router.navigateByUrl('/login');
   }

  /**
   * Exit the chat panel in case of logout.
   */
  exitChatPanel(): void {
    this.dqChatPanelService.exitMiniChatPanel.next(true);
  }

   showModal(): void {
     this.isConfirmModalShown = true;
   }

   hideModal(): void {
     this.isConfirmModalShown = false;
     this.resetConfirmBox();
   }

   onHidden(): void {
     this.isConfirmModalShown = false;
     this.resetConfirmBox();
   }

 }
