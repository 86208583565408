  <div class="mt-3 mb-3 row">
    <div class="col-12 mb-1">
      <div class="custom-dropdown">
        <span class="icon-left">
          <img src="assets/themes/icons/search-icon.svg" alt="search-icon">
        </span>
        <input id="useruniversity" autocomplete="off" type="text" placeholder="{{mls?.languageText?.search_university?.key1}}"
          (keyup)="searchUniversity($event)" />
        <div>
          <ul *ngIf="university_list?.length>0" [perfectScrollbar]="config" infiniteScroll
            [infiniteScrollDistance]="5" [infiniteScrollThrottle]="50" [scrollWindow]="false"
            (scrolled)="loadmoreUniversity();">
            <li *ngFor="let university of university_list" (click)="selectUniversity(university)">
              {{university?.university_name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
