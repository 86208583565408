import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewLanguageService {
  public is_lskey_display = 0;
  public selected_lang = 'en';
  public languageText =
    {
     'open_article': {
      'key1': 'To view or add a comment',
      'key2': 'Create your account for free or login to continue.',
      'key3': 'Tell us about your role',
      'key4': 'Business Representative',
      'key5': 'Healthcare Professional',
      'key6': 'Others',
      'key7': 'Next',
      'key8': 'Sign in',
      "key9": "Search Docquity",
      "key10": "Community",
      "key11": "DocTalks",
      "key12": "Articles",
      "key13": "Webinar",
      "key14": "more",
      "key15": "View All",
      "key16": "Powered by Docquity",
      "key17": "Continue to Docquity",
      "key18": "About Webinar",
      "key19": "Speaker Details",
      "key20": "Releasing On",
      "key21": "Play Now",
      "key22": "Medical Content",
      "key23": "Content for Doctors",
      "key24": "Speaker List",
      "key25": "Share it with",
      "key26": "facebook",
      "key27": "twitter",
      "key28": "linkedIn",
      "key29": "No Record Found",
      "key30": "We've got fresh resources to fuel your knowledge",
      "key31": "Hello",
      "key32": "Welcome to Docquity",
      "key33": "One of the largest & trusted network of Doctors in the World",
      "key34": "Episodes",
      "key35": "Register",
      "key36": "Access exclusive Medical Content from across the World",
      "key37": "Learn from the range of Articles, Webinars & Short Videos",
      "key38": "Connect with Peers and Interact in real time",
      "key39" : "Play Episode Now",
      "key40" : "Interested in lively discussions and shared insights?",
      "key41" : "Click here to explore thought-provoking discussions or to share your unique perspective",
      "key42": "Comments",
      "key43": "Español"
      },
      'left_section': {
        'key1': 'Share Clinical Experiences',
        'key2': 'Attempt certified CME courses',
        'key3': 'Read updated guidelines and watch procedural videos',
        'key4': 'Attend webinars by top Specialists'
      },
      'input_screen': {
        'key1': 'Join Docquity, The Doctor\'s Only Network',
        'key2': 'We will send you a one - time verification code',
        'key3': 'Enter Mobile Number',
        'key4': 'Your details are secured & encrypted',
        'key5': 'Agree & Request OTP',
        'key6': 'Select Language',
        'key7': 'English',
        'key8': 'Bahasa',
        'key9': 'Please enter only digits',
        'key10': 'Tiếng Việt',
        'key11': 'x한국어',
        'key12': 'Dear user, our server has detected malicious activity from your IP address. We have blocked the phone number provided to investigate further. We request you to provide us with your email id to provide you further updates. Please reach out to',
        'key13': 'for further assistance.',
        'key14': 'Submit',
        'key15': 'Mobile Number',
        'key16': 'Enter Email Id',
        'key17': 'Please enter valid email',
        'key18': 'Thank you for submitting. We will provide you with further updates on provided email address. Please reach out to',
        'key19': 'for further assistance.',
        'key20': 'Okay',
        'key21': 'Dear user, your request is already in progress. We will provide you with further updates on provided email address. Please reach out to',
        'key22': 'for further assistance.',
        'key23': 'Thank you for submitting. Your request is in progress.',
        'key24': 'Your request is in progress',
        'key25': '中文',
        'key26': '日本語'
      },
      'otp_screen': {
        'key1': 'Verify Mobile Number',
        'key2': 'Edit',
        'key3': 'Enter OTP',
        'key4': 'Resend OTP',
        'key5': 'VERIFY',
        'key6': 'Edit'
      },
      'usertype_screen': {
        'key1': 'Skip',
        'key2': 'I am a',
        'key3': 'Or',
        'key4': 'Use invite code to get easy access',
        'key5': 'Enter invite code (if available)',
        'key6': 'Submit',
        'key7': 'Please enter invite code',
        'key8': 'Please Select your profession',
        'key9': 'Have an Invite code',
        'key10': 'Please paste invite code which is shared by your association',
        'key11': 'Invalid invite code. Please try again!',
        'key12': 'Continue'
      },
      'userdetail_screen': {
        'key1': 'Skip',
        'key2': 'Doctor, Let\'s get to know you',
        'key3': 'Next',
        'key4': 'First name',
        'key5': 'Middle name',
        'key6': 'Last name',
        'key7': 'Email Id',
        'key8': 'Enter first name',
        'key9': 'Enter middle name',
        'key10': 'Enter last name',
        'key11': 'Enter email Id'
      },
      'studenttype_screen': {
        'key1': 'Select Student Type '
      },
      'verifygroup_user': {
        'key1': 'Skip',
        'key2': 'Or',
        'key3': 'Click to Upload Id ',
        'key4': 'Next',
        'key5': 'Why we need this?',
        'key6': 'What\'s your Registration Number',
        'key7': 'Enter registration Number',
        'key8': 'Please provide requre information',
        'key9': 'Please enter valid email',
        'key10': 'Please select image file'
      },
      'backscreen_modal': {
        'key1': 'Confirm',
        'key2': 'Do you really want to Exit Registration Process?',
        'key3': 'No',
        'key4': 'Yes'
      },
      'skiponboarding_screen': {
        'key1': 'Skip',
        'key2': 'Don\'t skip this!',
        'key3': 'Back to Edit'
      },
      'onboardspeciality_screen': {
        'key1': 'Select your speciality',
        'key2': 'Select your interest',
        'key3': 'Continue',
        'key4': 'Search Speciality',
        'key5': 'No Specialities Available ',
        'key6': 'Selected',
        'key7': 'Select at least one interest to continue',
        'key8': 'Skip'
      },
      'grandround_header': {
        'key1': 'View Profile',
        'key2': 'Refer & Earn',
        'key3': 'My Vault',
        'key4': 'Calendar',
        'key5': 'Bookmarks',
        'key6': 'Settings',
        'key7': 'Send Feedback',
        'key8': 'Terms & Conditions',
        'key9': 'Logout',
        'key10': 'Post',
        'key11': 'Explore',
        'key12': 'Post'
      },
      'grandround_left_section': {
        'key1': 'Feed',
        'key2': 'Invite Your Peers'
      },
      'grandround': {
        'key1': 'Deleted Successfully',
        'key2': 'Back'
      },
      'feed_card_screen': {
        'key1': 'Report Post',
        'key2': 'Copy Link',
        'key3': 'Edit',
        'key4': 'Delete',
        'key5': 'Like',
        'key6': 'Likes',
        'key7': 'Bookmark',
        'key8': 'Bookmarked',
        'key9': 'Share',
        'key10': 'Comment',
        'key11': 'Comments',
        'key12': 'Be the first one to like',
        'key13': 'Get Sample',
        'key14': 'Liked',
        'key15': 'More',
        'key16': 'Are you sure you want to delete this post?',
        'key17': 'Repost',
        'key18': 'This post cannot be reposted since the original post has been deleted by the post owner!',
        'key19': 'Introducing Reposting!',
        'key20': 'Now you can start sharing webinars and posts from other users on your feed. Simply tap the “Repost” button.',
        'key21': 'Verify your profile to repost! Submit your medical registration number by going to your profile.',
        'key22': 'We are uploading your post. Please try again once the post has been uploaded successfully.',
        'key23': 'You cannot edit this post since the original post has been deleted by the post owner. You can delete the post to remove it from the platform.'
      },
      'editor_choice': {
        'key1': 'Bookmark',
        'key2': 'Bookmarked',
        'key3': 'View All',
        'key4': 'Read more'
      },
      'comment_card': {
        'key1': 'Edit',
        'key2': 'Delete',
        'key3': 'Like',
        'key4': 'Upvote',
        'key5': 'Likes',
        'key6': 'Reply',
        'key7': 'Upvotes',
        'key8': 'Replies',
        'key9': 'Updated Successfully',
        'key10': 'View entire discussion',
        'key11': 'Update',
        'key12': 'No comments yet',
        'key13': 'Type a message',
        'key14': 'Report Comment',
        'key15': 'View Profile',
        'key16': 'Connect',
        'key17': 'Are you sure you want to delete this comment?',
        'key18': 'Live Comments',
        'key19': 'No comment yet',
        'key20': 'Retry',
        'key21': 'Comments',
        'key22': 'Write a comment',
        'key23': 'Something went wrong, Please retry',
        'key24': 'Add a Reply',
        'key25': 'Invalid image format, please select jpg/png image',
        'key26': 'Add a Comment',
        'key27': 'Edit Comment'
      },
      'reply_comment_card': {
        'key1': 'Edit',
        'key2': 'Delete',
        'key3': 'Like',
        'key4': 'Upvote',
        'key5': 'Likes',
        'key6': 'Reply',
        'key7': 'Upvotes',
        'key8': 'Replies',
        'key9': 'Updated Successfully',
        'key10': 'View All Replies',
        'key11': 'Update',
        'key12': 'Edit Comment'
      },
      'association_verification_screen': {
        'key1': 'Verification',
        'key2': 'Click to upload Id',
        'key3': 'Next',
        'key4': '',
        'key5': 'Invalid format, Please select jpg or png image',
        'key6': 'e.g.'
      },
      'verification_country_and_association_screen': {
        'key1': 'Add Association',
        'key2': 'Selected Country',
        'key3': 'No data found',
        'key4': 'Next',
        'key5': 'You can select maximum 3 Association'
      },
      'clinical_resource_card': {
        'key1': 'Be the first one to like',
        'key2': 'Like',
        'key3': 'Likes',
        'key4': 'Liked',
        'key5': 'Bookmark',
        'key6': 'Bookmarked',
        'key7': 'Share',
        'key8': 'Follow',
        'key9': 'Following',
        'key10': 'Comment',
        'key11': 'Comments',
        'key12': 'Views',
        'key13': 'Terms and Conditions',
        'key14': 'Please accept Terms & Conditions',
        'key15': 'I have read all',
        'key16': 'Report'
      },
      'webinar_list': {
        'key1': 'Webinars',
        'key2': 'All',
        'key3': 'Live',
        'key4': 'View All',
        'key5': 'No data found!'

      },
      'webinar_card': {
        'key1': 'Views',
        'key2': 'People Watching',
        'key3': 'Videos',
        'key4': 'Share',
        'key5': 'Bookmark',
        'key6': 'Like',
        'key7': 'Releasing',
        'key8': 'Share',
        'key9': 'Report',
        'key10': 'Report',
        'key11': 'Releasing on',
        'key12': 'Repost'
      },
      'survey_card': {
        'key1': 'Share',
        'key2': 'View Details',
        'key3': 'Continue to Survey',
        'key4': 'Retake',
        'key5': 'Expired on:',
        'key6': 'Powered by',
        'key7': 'Attempted :',
        'key8': 'Expires on'
      },
      'survey_feed_card': {
        'key1': 'Start',
        'key2': 'Complete Survey',
        'key3': 'Share',
        'key4': 'Terms and Conditions',
        'key5': 'Expires on'
      },
      'survey_list': {
        'key1': 'Survey',
        'key2': 'All',
        'key3': 'Drafts',
        'key4': 'Completed',
        'key5': 'View All',
        'key6': 'Survey you left in the middle',
        'key7': 'Explore Homepage',
        'key8': 'No survey found',
        'key9': 'No survey on draft',
        'key10': 'No draft completed yet',
      },
      'survey_rewards': {
        'key1': 'Reward History',
        'key2': 'Pending',
        'key3': 'Redeemed',
        'key4': 'Expired',
        'key5': 'Your Reward Earnings',
        'key6': 'All survey rewards will display here',
        'key7': 'Redeem All',
        'key8': 'Know more about Docquity rewards',
        'key9': 'How it works?',
        'key10': 'View History',
        'key11': 'Redeem Coins',
        'key12': 'Docqucoin',
        'key13': 'Complete this survey and earn the reward mentioned below.!',
        'key14': 'Valid until ',
        'key15': 'How to redeem?',
        'key16': 'Welcome to your ',
        'key17': 'Rewards Hub!',
        'key18': 'Answer questions, claim rewards!',
        'key19': 'Know more about Docquity rewards',
        'key20': 'How it works?',
        'key21': 'Redeemable amount',
        'key22': 'You have total',
        'key23': 'Cash',
        'key24': 'Enter Account Details',
        'key25': 'Redeem Now',
        'key26': 'Account Name',
        'key27': 'Account Number',
        'key28': 'Explore Survey',
        'key29': 'Cash',
        'key30': 'Transaction id',
        'key31': 'Redeemed on',
        'key32': 'It’s that simple!',
        'key33': 'Payment failed !',
        'key34': 'Your payment did not go through.',
        'key35': 'You can try again by clicking on "Try Again" and initiating the payment.',
        'key36': 'Successfully redeemed!',
        'key37': 'You have successfully redeemed your coins',
        'key38': 'Back to Survey page',
        'key39': 'Try again',
        'key40': 'Account Holder\'s Name',
        'key41': 'Account Holder\'s Email',
        'key42': 'Invalid',
        'key43': 'Amount not redeemable.',
        'key44': 'Update in Progress',
        'key45': 'Reward expires on',
        'key46': 'Reward expired on',
        'survey_reward_modal':{
          "key1":"Now Introducing!",
          "key2":"Docquity reward program.",
          "key3":"How to earn and redeem your cash reward",
          "key4":"You can now earn cash rewards for your valuable insights! Complete surveys on Docquity and redeem your earnings directly to your bank account.",
          "key5":"Step 1",
          "key6":"Complete a survey indicating the cash reward. ",
          "key7":"Step 2",
          "key8":"Earn the cash reward on Docquity.",
          "key9":"Step 3",
          "key10":"Click on redeem button to redeem the cash reward in your bank account.",
          "key11":"Terms & conditions",
       },
      },
      'survey_detail': {
        'key1': 'Share',
        'key2': 'Read More',
        'key3': 'Read Less',
        'key4': 'Let\'s get started',
        'key5': 'All survey responses are anonymous. We won\'t share any of your personal information with anyone.',
        'key6': 'I agree & Let\'s Start',
        'key7': 'Powered by',
        'key8': 'Mandatory Consent!',
        'key9': 'Cancel',
        'key10': 'Back',
        'key11': 'To attempt this survey, you need to agree to',
        'key12': 'Terms & Conditions',
        'key13': 'I have read & agreed to the',
        'key14': 'Continue To Survey',
        'key15': 'View Details'
      },
      'survey_thankyou': {
        'key1': 'Share',
        'key2': 'Retake Survey',
        'key3': 'Redirecting you back to survey lists in ',
        'key4': 'Thank You for completing survey',
        'key5': 'Back',
        'key6': 'seconds',
        'key7': 'Re-directing you to interesting articles and other docquity resources in ',
        'key8': 'Congratulations! You have been rewarded'
      },
      'survey_sidebar_card': {
        'key1': 'Continue to Survey',
        'key2': 'Expired on:',
        'key3': 'Expires on'
      },
      'cme_card': {
        'key1': 'Bookmark',
        'key2': 'Share',
        'key3': 'Start',
        'key4': 'Expires on',
        'key5': 'Re-Attempt',
        'key6': 'View Certificate',
        'key7': 'Bookmarked',
        'key8': 'Attempted',
        'key9': 'View Leaderboard',
        'key10': 'Check your ranking'
      },
      'cme_list': {
        'key1': 'Courses',
        'key2': 'My Vault',
        'key4': 'Select Speciality',
        'key5': 'Apply',
        'key6': 'Close',
        'key7': 'clear all',
        'key8': 'No Data Found',
        'key9': 'Search',
        'key10': 'Add more points',
        'key11': 'Certificates',
        'key12': 'Start',
        'key13': 'Share this CME',
        'key14': 'I accept the',
        'key15': 'Terms',
        'key16': 'Please accept Terms & Conditions',
        'key17': 'Subscribe',
        'key18': 'Re-Attempt',
        'key19': 'View Certificate',
        'key20': 'Total CME Points',
        'key21': 'Docquity',
        'key22': 'Offline',
        'key23': 'View Details',
        'key24': 'Share',
        'key25': 'Edit',
        'key26': 'Delete',
        'key27': 'Report',
        'key28': 'Download',
        'key29': 'Repost',
      },
      'add_skp_points': {
        'key1': 'Add more points',
        'key2': 'Course Title',
        'key3': 'Add a Source Type',
        'key4': 'Add Points',
        'key5': 'Start Date',
        'key6': 'End Date',
        'key7': 'Upload Certificate',
        'key8': 'Submit',
        'key9': 'Give a Title to this Entry!',
        'key10': 'Enter your points',
        'key11': 'Select start date',
        'key12': 'Select end date',
        'key13': 'Please select start date',
        'key14': 'Please select end date',
        'key15': 'Select source'
      },
      'view_skp_points': {
        'key1': 'Title',
        'key2': 'Certificate',
        'key3': 'View Certificate',
        'key4': 'Download',
        'key5': 'Share',
        'key6': 'Select Start Date',
        'key7': 'Select End Date',
        'key8': 'Source',
        'key9': 'Course Details',
        'key10': 'Credits'
      },
      'drug_sample_card': {
        'key1': 'Order Now'
      },
      'drug_sample_list': {
        'key1': 'Drug Sample',
        'key2': 'Available',
        'key3': 'Ordered',
        'key4': 'Clear All'
      },
      'drug_sample_detail': {
        'key1': 'Order Now',
        'key2': 'Composition'
      },
      'drug_order_form': {
        'key1': 'Order Now',
        'key2': 'Name',
        'key3': 'Mobile Number',
        'key4': 'Email',
        'key5': 'Update',
        'key6': 'Address',
        'key7': 'Post Code',
        'key8': 'Time range',
        'key9': 'Comment',
        'key10': 'Place Order',
        'key11': 'Enter Name',
        'key12': 'Enter Mobile Number',
        'key13': 'Enter Email ID',
        'key14': 'Enter Complete Address',
        'key15': 'Enter Post Code',
        'key16': 'Enter Post Code',
        'key17': 'Select time range',
        'key18': 'Enter Preferred date',
        'key19': 'Enter Name',
        'key20': 'Enter Mobile Number',
        'key21': 'Please enter a valid Email ID',
        'key22': 'Enter Complete Address',
        'key23': 'Time range',
        'key24': 'Select Your Date',
        'key25': 'Please select start date'
      },
      'doctalk_card': {
        'key1': 'Views',
        'key2': 'Like',
        'key3': 'Bookmark',
        'key4': 'Share',
        'key5': 'Likes',
        'key6': 'Be the first one to like',
        'key7': 'Bookmarked',
        'key8': 'Liked',
        'key9': 'Videos',
        'key10': 'Episodes',
        'key11': 'More',
        'key12': 'Report',
        'key13': 'Share'
      },
      'doctalk_list': {
        'key1': 'View All',
        'key2': 'Doctalks'
      },
      'doctalk_details': {
        'key1': 'Like',
        'key2': 'Likes',
        'key3': 'Liked',
        'key4': 'Bookmark',
        'key5': 'Bookmarked',
        'key6': 'Share',
        'key7': 'Play Now',
        'key8': 'Play Episode Now',
        'key9': 'See more',
        'key10': 'Speaker Details',
        'key11': 'Views',
        'key12': 'Minutes',
        'key13': 'More',
        'key14': 'Follow'
      },
      'event_card': {
        'key1': 'Missed',
        'key2': 'Attended',
        'key3': 'Day Event',
        'key4': 'Doctors Attending',
        'key5': 'Details',
        'key6': 'Share',
        'key7': 'Going',
        'key8': 'Attending',
        'key9': 'Book Now',
        'key10': 'Payment in Process',
        'key11': 'Book Now',
        'key12': 'Going',
        'key13': 'day\'s event'
      },
      'event_going_modal': {
        'key1': 'You  marked going',
        'key2': 'You Marked going',
        'key3': 'Share this Event'
      },
      'event_list': {
        'key1': 'Events',
        'key2': 'Upcoming',
        'key3': 'Attending',
        'key4': 'Completed',
        'key5': 'No Data Found',
        'key6': 'All',
        'key7': 'Attended event',
        'key8': 'Missed Event',
        'key9': 'Total CME Points',
        'key10': 'My Vault',
        'key11': 'Related Article',
        'key12': 'No record found'
      },
      'event_detail': {
        'key1': 'Back',
        'key2': 'Day Event',
        'key3': 'Share',
        'key4': 'Mark going',
        'key5': 'You marked going',
        'key6': 'Mark Attendance',
        'key7': 'Sessions',
        'key8': 'About',
        'key9': 'Discussion',
        'key10': 'About Events',
        'key11': 'Media',
        'key12': 'Images',
        'key13': 'Videos',
        'key14': 'PDF',
        'key15': 'Going',
        'key16': 'About Session',
        'key17': 'Speakers',
        'key18': 'View All',
        'key19': 'Payment Failed. Please Retry',
        'key20': 'Book Now',
        'key21': 'Payment in Process',
        'key22': 'Book Now',
        'key23': 'Going',
        'key24': 'Days',
        'key25': 'Day',
        'key26': 'Session',
        'key27': 'Events Detail',
        'key28': 'view more',
        'key29': 'view less',
        'key30': 'Add to calendar',
        'key31': 'View Map',
        'key32': 'Doctor(s) Attending',
        'key33': 'Credits',
        'key34': 'Attendance',
        'key35': 'Punch In',
        'key36': 'Punch Out',
        'key37': 'Event Schedules',
        'key38': 'View Detail',
      },
      'event_schedule': {
        'key1': 'Home',
        'key2': 'Events',
        'key3': 'Event Detail',
        'key4': 'Event Itineraries',
        'key5': 'Event Itinerary',
        'key6': 'Itineraries',
        'key7': 'No Sessions Available',
        'key8': 'Session Details',
        'key9': 'Expire on',
        'key10': 'No',
        'key11': 'View Details',
        'key12': 'Join Webinar',
        'key13': 'Start',
        'key14': 'Re-Attempt',
        'key15': 'No Sessions Available',
        'key16': 'Event Itinerary Will be Coming Soon'
      },
      'event_session_schedule': {
        'key1': 'Home',
        'key2': 'Events',
        'key3': 'Event Detail',
        'key4': 'Event Itineraries',
        'key5': 'Session Itinerary',
        'key6': 'Session Itinerary',
        'key7': 'Session Itinerary Will be Coming Soon'
      },
      'event_session_detail': {
        'key1': 'Back',
        'key2': 'Day Event',
        'key3': 'Share',
        'key4': 'Mark going',
        'key5': 'You marked going',
        'key6': 'Mark Attendance',
        'key7': 'Sessions',
        'key8': 'About',
        'key9': 'Discussion',
        'key10': 'About Event',
        'key11': 'Media',
        'key12': 'Images',
        'key13': 'Videos',
        'key14': 'PDF',
        'key15': 'Going',
        'key16': 'About Sessions',
        'key17': 'Speakers',
        'key18': 'View All'
      },
      'speaker_list': {
        'key1': 'Speakers',
        'key2': 'Details',
        'key3': 'About Speaker',
        'key4': 'Speaker Profile',
        'key5': 'View Detail'
      },
      'connection_card': {
        'key1': 'Accept',
        'key2': 'Reject',
        'key3': 'Withdraw',
        'key4': 'Rejected',
        'key5': 'View Profile',
        'key6': 'Remove Connection',
        'key7': 'Chat',
        'key8': 'Create Group Chat',
        'key9': 'Connect'
      },
      'connection_list': {
        'key1': 'Suggestion',
        'key2': 'Connections',
        'key3': 'Connection Request',
        'key4': 'Request Sent',
        'key5': 'No Connection Yet!',
        'key6': 'Keep yourself updated with the latest medical trends',
        'key7': 'No Data Found',
        'key8': 'Search People'
      },
      'connection_request_list': {
        'key1': 'No Connection Request',
        'key2': 'Keep yourself updated with the latest medical trends',
        'key3': 'View All',
        'key4': 'Suggestion',
        'key5': 'No data found',
        'key6': 'Try adjusting your search or filter to find what you’re looking for.',
        'key7': 'Filter by'
      },
      'connection_sent_list': {
        'key1': 'No Request Sent',
        'key2': 'Keep yourself updated with the latest medical trends'
      },
      'connection_suggestion_list': {
        'key1': 'View More',
        'key2': 'No Suggestions Available',
        'key3': 'Keep yourself updated with the latest medical trends'
      },
      'cme_details': {
        'key1': 'Bookmark',
        'key2': 'Share',
        'key3': 'Bookmarked',
        'key4': 'Start',
        'key5': 'Re-Attempt',
        'key6': 'View Certificate',
        'key7': 'Media',
        'key8': 'Expires on',
        'key9': 'Share this CME',
        'key10': 'I accept the',
        'key11': 'Terms',
        'key12': 'Please accept Terms & Conditions',
        'key13': 'Subscribe',
        'key14': 'Report',
        'key15': 'View Leaderboard',
        'key16': 'Check your ranking',
        'key17': 'Download',
        "key18": "Watch Webinar",
        "key19": "Take Survey",
        "key20": "Start CME",
        "key21": "Complete the previous step to unlock",
        "key22": "Thank you! Congratulations on passing the course."
      },
      'product_subscribe': {
        'key1': 'I agreed with all the',
        'key2': 'T&Cs',
        'key3': 'Please accept Terms & Conditions',
        'key4': 'I will do it later',
        'key5': 'Follow',
        'key6': ''
      },
      'clinical_resource_filter': {
        'key1': 'Search',
        'key2': 'No Data Found',
        'key3': 'Clear all',
        'key4': 'Select Speciality',
        'key5': 'Search',
        'key6': 'Search',
        'key7': 'No Data Found'
      },
      'clinical_resource_list': {
        'key1': 'No Data Found'
      },
      'clinical_resource_detail': {
        'category': 'clinical resource detail',
        'key1': 'Like',
        'key2': 'Bookmark',
        'key3': 'Bookmarked',
        'key4': 'Share',
        'key5': 'Follow',
        'key6': 'Following',
        'key7': 'Views',
        'key8': 'T&C',
        'key9': 'Please accept Terms & Conditions',
        'key10': 'I have read all the',
        'key11': 'Report'
      },
      'profile_detail_left': {
        'category': 'profile detail left',
        'key1': 'Share',
        'key2': 'Edit',
        'key3': 'Registered Name with title',
        'key4': 'Connect',
        'key5': 'Withdraw',
        'key6': 'Chat',
        'key7': 'Profile Completed',
        'key8': 'Did you know that doctors over 90% profile completion get most of the views!',
        'key9': 'Accept',
        'key10': 'Reject'
      },
      'profile_detail_timeline': {
        'category': 'profile detail timeline',
        'key1': 'My Profile',
        'key2': 'Posts',
        'key3': 'View All',
        'key4': 'Connections',
        'key5': 'Deleted Successfully',
        'key6': 'No Post Available',
        'key7': 'Education',
        'key8': 'Experience',
        'key9': 'Publication',
        'key10': 'Certifications',
        'key11': 'Interests'
      },
      'profile_detail_timeline_education': {
        'key1': 'Add',
        'key2': 'View',
        'key3': 'View Educational Details',
        'key4': 'No Data Found',
        'key5': 'Currently Pursuing'
      },
      "profile_nudges": {
        "key1": "Save",
        "key2": "Reject"
      },
      'profile_detail_timeline_association': {
        'key1': 'My Associations',
        'key2': 'Not visible to anyone',
        'key3': 'My Associations',
        'key4': 'No Data Found',
        'key5': 'Verification under process',
        'key6': 'Verified Member',
        'key7': 'View'
      },
      'profile_detail_timeline_view': {
        'category': 'Profile View',
        'key1': 'Summary',
        'key2': 'No Data Found',
        'key3': 'My Rewards',
        'key4': 'Other Details'
      },
      'profile_detail_timeline_experience': {
        'key1': 'Experience',
        'key2': 'Add',
        'key3': 'No Data Found',
        'key4': 'View',
        'key5': 'More',
        'key6': 'Currently Working'
      },
      'profile_detail_timeline_publication': {
        'key1': 'Publication',
        'key2': 'Add',
        'key3': 'No Data Found',
        'key4': 'View',
        'key5': 'More'
      },
      'profile_detail_timeline_certification': {
        'key1': 'Certifications',
        'key2': 'Add',
        'key3': 'No Data Found',
        'key4': 'View',
        'key5': 'More'
      },
      'profile_detail_timeline_interest': {
        'key1': 'Interests',
        'key2': 'Add',
        'key3': 'No Data Found',
        'key4': 'View',
        'key5': 'more'
      },
      'create_posting': {
        'category': 'Create Post',
        'key1': 'Share post',
        'key2': 'Post as',
        'key3': 'Share your thoughts.....',
        'key4': 'Gallery',
        'key5': 'Tag People',
        'key6': 'Ask Question',
        'key7': 'Documents',
        'key8': 'Ask your question here',
        'key9': 'Options',
        'key10': 'Add Another',
        'key11': 'Drafts',
        'key12': 'Change posting by click on dropdown',
        'key13': 'Anonymous',
        'key14': 'Next',
        'key15': 'Optional',
        'key16': 'Specialty & Posting options',
        'key17': 'Please select a group where you would like to post. You can select the group under “Posting and specialty options”',
        'key18': 'Your content will be cleared, Do you want to continue?'

      },
      'create_post_type': {
        'category': 'Create post type',
        'key1': 'Share post',
        'key2': 'Next',
        'key3': 'Is this a case',
        'key4': 'Speciality',
        'key5': 'Search Speciality',
        'key6': 'No Data Found',
        'key7': 'Selected'
      },
      'create_post_association': {
        'category': 'create post association',
        'key1': 'You can select max 3 association',
        'key2': 'Post',
        'key3': 'Add Association',
        'key4': 'View ',
        'key5': 'More'
      },
      'create_post_community': {
        'category': 'create post community',
        'key1': 'You can select max 5 community',
        'key2': 'Post',
        'key3': 'Add Community',
        'key4': 'View ',
        'key5': 'More'
      },
      'create_post_drafts': {
        'category': 'create post draft',
        'key1': 'Drafts',
        'key2': 'No Data Found',
        'key3': 'Delete',
        'key4': 'Edit'
      },
      'create_post_save_modal': {
        'category': 'create post save modal',
        'key1': 'Save for later?',
        'key2': 'Save as draft',
        'key3': 'Discard Post',
        'key4': 'Continue Editing'
      },
      'profile_edit_left': {
        'category': 'profile edit',
        'key1': 'Profile',
        'key2': 'Experience',
        'key3': 'Education',
        'key4': 'Publication',
        'key5': 'Certifications',
        'key6': 'Interests',
        'key7': 'Back'
      },
      'profile_edit_experience': {
        'category': 'profile edit experience',
        'key1': 'Experience',
        'key2': 'Add',
        'key3': 'Place of Work',
        'key4': 'Enter Hospital, Clinic, or Institution Name ',
        'key5': 'Designation',
        'key6': 'Enter Current Designation',
        'key7': 'Period',
        'key8': 'Period',
        'key9': 'Currently Working',
        'key10': 'Starting Month',
        'key11': 'Start Year',
        'key12': 'Ending Month',
        'key13': 'Ending Year',
        'key14': 'Submit',
        'key15': 'Professional Experience',
        'key16': 'To Present',
        'key17': 'Month',
        'key18': 'Year',
        'key19': 'Enter Name',
        'key20': 'Period',
        'key21': 'Starting Year',
        'key22': 'Starting Month',
        'key23': 'Period',
        'key24': 'Ending Year',
        'key25': 'Ending Month'
      },
      'profile_edit_education': {
        'category': 'profile edit education',
        'key1': 'Education',
        'key2': 'Add',
        'key3': 'Name of University or college',
        'key4': 'Search University',
        'key5': 'Degree',
        'key6': 'Search degree',
        'key7': 'Period',
        'key8': 'Start Year',
        'key9': 'Ending Year',
        'key10': 'Currently Pursuing',
        'key11': 'Submit',
        'key12': 'Update',
        'key13': 'Qualification',
        'key14': 'Year',
        'key15': 'To Present'
      },
      'profile_edit_publication': {
        'category': 'profile edit publication',
        'key1': 'Publication',
        'key2': 'Add',
        'key3': 'Title',
        'key4': 'Paper title',
        'key5': 'Publisher',
        'key6': 'Name of Publisher',
        'key7': 'Link',
        'key8': 'Link to the publication, if any',
        'key9': 'Summary',
        'key10': 'Add summary',
        'key11': 'Publishing Date',
        'key12': 'Start Month',
        'key13': 'Start Year',
        'key14': 'Submit',
        'key15': 'Update',
        'key16': 'Year',
        'key17': 'New Publication',
        'key18': 'Publication'
      },
      'profile_edit_certificate': {
        'category': 'profile edit certificate',
        'key1': 'Certificate',
        'key2': 'Add',
        'key3': 'Name',
        'key4': 'Name of Certificate',
        'key5': 'Date',
        'key6': 'Issue Date',
        'key7': 'Submit',
        'key8': 'Certification',
        'key9': 'Update'
      },
      'profile_edit_interest': {
        'category': 'profile edit interest',
        'key1': 'Interest',
        'key2': 'Speciality',
        'key3': 'Search Speciality',
        'key4': 'Selected',
        'key5': 'Submit'
      },
      'profile_edit_basic': {
        'category': 'profile edit basic',
        'key1': 'Edit Profile',
        'key2': 'First name',
        'key3': 'Enter first name',
        'key4': 'Middle name',
        'key5': 'Enter middle name',
        'key6': 'Change Profile Image',
        'key7': 'Last name',
        'key8': 'Enter last name',
        'key9': 'Registered name with title (for certificate)',
        'key10': 'Enter Name',
        'key11': 'Speciality',
        'key12': 'Update',
        'key13': 'Location',
        'key14': 'Date of Birth',
        'key15': 'Gender',
        'key16': 'Male',
        'key17': 'Female',
        'key18': 'Phone number',
        'key19': 'Not visible to anyone',
        'key20': 'Email Id',
        'key21': 'Medical Registration Number',
        'key22': 'Update',
        'key23': 'Submit',
        'key24': 'Update',
        'key25': 'Add Location',
        'key26': 'About',
        'key27': 'Add Date of Birth',
        'key28': 'Change Request in Progress',
        'key29': 'Please enter valid Email Id',
        'key30': 'Please select image jpg/png file',
        'key31': 'Updated Successfully',
        'key32': 'Updated Successfully',
        'key33': 'verificationSuccess',
        'key34': 'Success',
        'key35': 'Add Email Id'
      },
      'post_process': {
        'category': 'post processing',
        'key1': 'Your post is going live, stay calm',
        'key2': 'Posted Successfully',
        'key3': 'Post upload failed, please try again',
        'key4': 'Post upload failed, please try again',
        'key5': 'Try Again',
        'key6': 'Posting is in progress. The Post button will be available once the current post is published.',
        'key7': 'Posting is in progress. The Edit button will be available once the current post is published.'
      },
      'feed_detail': {
        'category': 'feed detail',
        'key1': 'Like',
        'key2': 'Bookmark',
        'key3': 'Share',
        'key4': 'No Comment Yet!',
        'key5': 'Be the first one to like',
        'key6': 'Comment',
        'key7': 'Comments',
        'key8': 'Liked',
        'key9': 'Bookmarked',
        'key10': 'Back',
        'key11': 'Likes',
        'key12': 'Tagged Doctors',
        'key13': 'Back',
        'key14': 'Repost',
        'key15': 'This post cannot be reposted since the original post has been deleted by the post owner!'
      },
      'tagged_users': {
        'key1': 'Others'
      },
      'cme_question': {
        'key1': 'CME Details',
        'key2': 'Question',
        'key3': 'Back',
        'key4': 'Preview & Submit',
        'key5': 'Next',
        'key6': 'Submit',
        'key7': 'View Result',
        'key8': 'Answer',
        'key9': 'Edit',
        'key10': 'Are you sure you want exit? \n If you exit, your answers will be lost.',
        'key11': 'Are you sure you want exit? \n If you exit, your answers will be lost.',
        'key12': 'No',
        'key13': 'Ok',
        'key14': 'Values allowed between',
        'key15': 'Please enter only digits',
        'key16': 'Re-Attempt',
        'key17': 'Please input your answer'
      },
      'confirm_modal': {
        'key1': 'Confirm',
        'key2': 'Yes',
        'key3': 'No',
        'key4': 'Delete your account',
        'key5': 'You’re about to delete your account. If you’re ready to delete click “Delete Account”',
        'key6': 'Delete Account',
        'key7': 'Cancel',
        'key8': 'Proceed'
      },
      'webinar_detail': {
        'category': 'webinar detail',
        'key1': 'Live',
        'key2': 'Speaker Details',
        'key3': 'Share',
        'key4': 'View All',
        'key5': 'Releasing',
        'key6': 'Register Now',
        'key7': 'Notified',
        'key8': 'Like',
        'key9': 'Liked',
        'key10': 'Do you really want to close this webinar?',
        'key11': 'Yes, Close Now',
        'key12': 'No, I want to stay',
        'key13': 'Your current browser cannot run our content, please make sure it is updated. For best user experience please use Google Chrome.',
        'key14': 'Follow',
        'key15': 'Few Features won’t work in this browser, for better experience use chrome',
        'key16': 'Open in Chrome',
        'key17': 'View Details',
        'key18': 'Take a survey during this webinar',
        'key19': 'CME',
        'key20': 'About Webinar',
        'key21': 'Download App to view the webinar.',
        'key22': 'Back',
        'key23': 'Payment Failed. Please Retry',
        'key24': 'Releasing on',
        'key25': 'Yes',
        'key26': 'No',
        'key27':'Share',
        'key28':'Decline',
        'key29': 'Repost',
        'key30': 'To unlock the next step, please watch the webinar for atleast 1 minute. ',
            },
            'content_card': {
                'key1': 'View more',
                'key2': 'View less',
            },
            'profile_setting_left': {
                'key1': 'Change Language',
                'key2': 'Send Feedback',
                'key3': 'Push Notification',
                'key4': 'Terms & Conditions',
                'key5': 'Back',
                'key6': 'Delete Profile'
            },
            'profile_setting_language': {
                'key1': 'Change language',
                'key2': 'English',
                'key3': 'Bahasa Indonesia',
                'key4': 'Submit',
                'key5': 'Tiếng Việt',
                'key6': 'Language changed',
                'key7': 'x한국어',
                'key8': '中文',
                'key9': 'Japanese'
            },
            'profile_setting_notification': {
                'key1': 'Push Notification',
                'key2': 'Allow Notification',
                'key3': 'On',
                'key4': 'Off'
            },
            'profile_delete': {
              'key1': 'Delete Profile',
              'key2': 'Deleting your account is permanent. When you delete your Docquity account, you won’t be able to retrieve the content or information you have shared on Docquity.',
              'key3': 'The following information related to your profile will be deleted on Docquity.',
              'key4': 'Continue to Account Deletion'
            },
            'profile_delete_reason': {
              'key1': 'Why are you deleting your account?',
              'key2': 'Enter Your Reason',
              'key3': 'Delete Account',
              'key4': 'Cancel'
            },
            'report_screen': {
                'key1': 'Send Feedback',
                'key2': 'Report',
                'key3': 'Reason',
                'key4': 'Message',
                'key5': 'Submit',
                'key6': 'For any other help Please mail us',
                'key7': 'Report submitted'
            },
            'channel_list': {
                'key1': 'Channels',
                'key2': 'View All'
            },
            'channel_detail': {
                'key1': 'Follow',
                'key2': 'Following',
                'key3': 'I agreed with all the',
                'key4': 'T&Cs',
                'key5': 'Please accept Terms & Conditions',
                'key6': 'About Channel',
                'key7': 'No data found',
                'key8': 'About Pharma',
                'key9': 'Unfollow',
                'key10': 'Report',
                'key11': 'Share',
                'key12': 'Select Filter',
                'key13': ''
            },
            'channel_user_card': {
                'key1': 'Follow',
                'key2': 'Following'
            },
            'channel_card': {
                'key1': 'Follow',
                'key2': 'I accept the',
                'key3': 'Terms & Conditions',
                'key4': 'Please accept Terms & Conditions'
            },
            'create_post_tag': {
                'key1': 'Tag People',
                'key2': 'Done',
                'key3': 'Search',
                'key4': 'Connect & Tag',
                'key5': 'No data found',
                'key6': 'Selected',
                'key7': 'Connect & tag',
                'key8': 'Request Sent'
            },
            'chat_screen': {
                'key1': 'Chat',
                'key2': 'No Chat History Found, Start Messaging',
                'key3': 'Keep your self updated with the latest medical trends',
                'key4': 'New Message',
                'key5': 'No Chats Selected Yet!',
                'key6': 'Keep yourself updated with the latest',
                'key7': 'Medical Trends',
                'key8': 'Type a Message....',
                'key9': 'Oops',
                'key10': 'Something went wrong',
                'key11': 'Connecting to server',
                'key12': 'User is not available to chat.',
                'key13': 'You have blocked this user',
                'key14': 'Chat is not supported on this version. Please update your browser to use Docquity chat.',
                'key15': 'No data found',
                'key16': 'View all',
                'key17': 'Chat',
                'key18': 'Search',
                'key19': 'Please verify your profile to start the chat',
                'key20': 'Verify Now',
                'key21': 'Please select image jpg/png file',
                'key22': 'Group Successfully Updated',
                'key23': 'Unable to display messages'
            },
            'create_chat_screen': {
                'key1': 'New Chat',
                'key2': 'Create Group Chat',
                'key3': 'Search',
                'key4': 'Create New Group',
                'key5': 'Group Name',
                'key6': 'No Data Found',
                'key7': 'Connections'
            },
            'chat_detail_info': {
                'key1': 'Details',
                'key2': 'Group Information',
                'key3': 'Group Members',
                'key4': 'View',
                'key5': 'People',
                'key6': 'Exit',
                'key7': 'Block',
                'key8': 'Suspend Group',
                'key9': 'Report',
                'key10': 'Connections',
                'key11': 'No Connection Yet!',
                'key12': 'Cancel',
                'key13': 'Done',
                'key14': 'Unblock',
                'key15': 'Add People',
                'key16': 'Change Group Image',
                'key17': 'Edit Group Name',
                'key18': 'Save',
                'key19': 'Cancel',
                'key20': 'Enter Name'
            },
            'cookie_msg_info': {
                'key1': 'This website intends to use cookies to improve the site and your experience. By continuing to browse the site you are agreeing to accept our use of cookies.',
                'key2': 'If you require further information and/or do not wish to have cookies placed when using the site.',
                'key3': 'Accept'
            },
            'web_profile_left': {
                'key1': 'You may also like',
                'key2': 'Report'
            },
            'product_share': {
                'key1': 'Share',
                'key2': 'Copy',
                'key3': 'Share Link',
                'key4': 'Facebook',
                'key5': 'Linkedin',
                'key6': 'Twitter',
                'key7': 'Email'
            },
            'profile_connection': {
                'key1': 'Connections',
                'key2': 'Connection Card'
            },
            'profile_viewers': {
                'key1': 'Who viewed your profile',
                'key2': 'No data found.'
            },
            'pharma_detail': {
                'key1': 'Explore more',
                'key2': 'Channels followed by you'
            },
            'search_filter': {
                'key1': 'Properties'
            },
            'mobdrug_filter': {
                'key1': 'Select Filter'
            },
            'survey': {
                'key1': 'Survey'
            },
            'profile_post': {
                'key1': 'Timeline feed'
            },
            'refer_and_earn': {
                'key1': 'Refer Now',
                'key2': 'Enter email Id',
                'key3': 'Refer Now',
                'key4': 'How it works',
                'key5': 'Share your referral link',
                'key6': 'Send SMS',
                'key7': 'Invite your friends through SMS',
                'key8': 'Enter Mobile Number',
                'key9': 'Send SMS',
                'key10': 'Copy URL',
                'key11': 'Copy your unique URL',
                'key12': 'Copy URL',
                'key13': 'Social Media',
                'key14': 'Share on Social Media....',
                'key15': 'Share on Facebook',
                'key16': 'Share on Whatsapp',
                'key17': 'Share on Twitter',
                'key18': 'Please enter your email',
                'key19': 'Please enter a valid email address',
                'key20': 'Please enter your mobile number',
                'key21': 'Please enter your valid mobile number'
            },
            'web_calendar_list': {
                'key1': 'Calendar'
            },
            'webinar_right': {
                'key1': 'Calendar'
            },
            'bookmark': {
                'key1': 'Bookmarks'
            },
            'mob_button_menu': {
                'key1': 'Home',
                'key2': 'CME',
                'key3': 'Explore',
                'key4': 'Connections',
                'key5': 'Profile'
            },
            'hrw': {
                'key1': 'Follow',
                'key2': 'View More',
                'key3': 'This Month Earned',
                'key4': 'Redeem it before expiry date',
                'key5': 'Redeem Voucher',
                'key6': 'May Referal Points',
                'key7': 'Total Points',
                'key8': 'Points'
            },
            'videogular': {
                'key1': 'There are some issues loading this video. Please try to reload.or try to refresh the page.',
                'key2': 'Refresh'
            },
            'video_series': {
                'key1': 'Something went wrong with this video'
            },
            'internet_connection_modal': {
                'key1': 'You are not connected to the internet',
                'key2': 'Please check your internet connectivity'
            },
            'verification_success_modal': {
                'key1': 'Resubmit Your Documents'
            },
            'profile_notification': {
                'key1': 'Notification',
                'key2': 'Today',
                'key3': 'new',
                'key4': 'Confirmation',
                'key5': 'Cancel',
                'key6': 'OK'
            },
            'product_like': {
                'key1': 'Likes',
                'key2': 'Like',
                'key3': 'People Like'
            },
            'poll_modal': {
                'key1': 'Take the poll'
            },
            'NPS_modal': {
                'key1': 'Low',
                'key2': 'Average ',
                'key3': 'High',
                'key4': 'Done',
                'key5': 'Add Review',
                'key6': 'Tell us about your experience',
                'key7': 'You have exceeded the limit of 250 characters.'
            },
            'searching': {
                'key1': 'Trending Searches',
                'key2': 'see result for',
                'key3': 'back',
                'key4': 'No data found!'
            },
            'toster': {
                'key1': 'Link copied',
                'key2': 'Success'
            },
            'update_speciality': {
                'key1': 'Update Speciality',
                'key2': 'Update',
                'key3': 'Speciality',
                'key4': 'search',
                'key5': 'Search Speciality',
                'key6': 'No Data Found',
                'key7': 'Selected'
            },
            'update_location': {
                'key1': 'Add/Update Location',
                'key2': 'Update',
                'key3': 'CURRENT LOCATION',
                'key4': 'Country',
                'key5': 'Country',
                'key6': 'Please enter country name',
                'key7': 'State',
                'key8': 'State',
                'key9': 'City',
                'key10': 'City',
                'key11': 'Postal code',
                'key12': 'Postal code',
                'key13': 'Full address',
                'key14': 'Full address'
            },
            'months': {
                'key1': 'Jan',
                'key2': 'Feb',
                'key3': 'Mar',
                'key4': 'Apr',
                'key5': 'May',
                'key6': 'Jun',
                'key7': 'July',
                'key8': 'Aug',
                'key9': 'Sep',
                'key10': 'Oct',
                'key11': 'Nov',
                'key12': 'Dec'
            },
            'product_masking': {
                'key1': 'Lock Content',
                'key2': 'Lock Content'
            },
            'doctalk_speakers_details': {
                'key1': 'Videos',
                'key2': 'Views',
                'key3': 'Select by',
                'key4': 'No Data Found'
            },
            'multiSelectDropDown': {
                'key1': 'Search'
            },
            'profile_setting_tandc': {
                'key1': 'Terms & Conditons',
                'key2': 'DOCQUITY: PRIVACY POLICY',
                'key3': 'Effective Date: 01 February 2021',
                'key4': 'This privacy policy describes how',
                'key5': 'DOCQUITY HOLDINGS PTE. LTD.',
                'key6': '(UEN: 201703503D), a company incorporated in Singapore with its registered office at 150 Beach Road, #35-28, The Gateway West, Singapore 189720, its subsidiaries, affiliates and related corporations  (“Docquity,” “we,” “us,” or “our”) collects, uses, shares, and otherwise handles personal information about individuals (“you” and/or “your”) who visit and use our website located at www.docquity.com, all affiliated websites (including the Docquity Mobile App), APIs and any other applications published by us or our affiliates (“Sites”) for access to or use of the Site owned and operated by us, our predecessors or successors in interest, or our affiliates, and any related products, features, and the Services.',
                'key7': 'Scope',
                'key8': 'This Docquity Privacy and Data Protection Policy applies to the collection, storage, processing, transfer, and use of Personal Data concerning its customers, including outsourcing and other services clients, business partners, employees, former employees, and applicants for employment, (“covered individuals”) except where any contract with a covered individual defines different requirements.',
                'key9': 'Personal Data may be collected from covered individuals/ users through a variety of means, including, for example, through websites, Mobile Applications, other ordering channels, and service or employment processes.',
                'key10': 'This Privacy and Data Protection Policy aims to facilitate “Privacy-by-Design” principles in the engineering and implementation of systems and processes by Docquity. Therefore, among other things it is intended to govern customer and employee data protection policies, and to influence system implementation standards; rulebooks; business processes; applications; web, product, and service developments; and technology roadmaps.',
                'key11': 'In this privacy policy, unless the context otherwise requires, the following terms shall have the respective meanings corresponding to them:',
                'key12': 'Materials',
                'key13': 'means images and content, including, but not limited to, text, pages, software, graphics, data, messages, Services, any other information, and any other Site content owned, operated, licensed, or controlled by Docquity.',
                'key14': 'Purpose',
                'key15': 'means the purpose of the Site and our Materials, which is to provide our Users with a platform for medical education and knowledge sharing.',
                'key16': 'Services',
                'key17': 'means all platform services and applications (including but not limited to (i) “DocTalks”, a library of short video lectures from medical experts; (ii) live & interactive webinars by key opinion leaders discussing specialised medical topics; (iii) access to international medical journals; (iv) medical events calendar; (v) access to HIPPA compliant chat platform; (vi) summarised medical articles; (vii) provision to earn accredited',
                'key18': 'SKP',
                'key19': 'Continued Professional Development',
                'key20': 'CPD',
                'key21': 'Continued Medical Education',
                'key22': 'CME',
                'key23': ' credits from leading medical associations and institutions; (viii) provision of storing certifications on a virtual wallet on the Site; (ix) provision to receive drug samples from top pharmaceutical companies for trial; (ix) journals and guidelines; (x) access to clinical case discussions, medical insights and polls; (xii) specialised channels) that are accessible through the Site (including the publicly available portions of the Site).',
                'key24': 'User',
                'key25': 'means any individual who has registered with the Site (and agreed to the terms of this Agreement) to utilise the Site and access the Materials for the Purpose.',
                'key26': 'Visitor',
                'key27': 'means visitors to the Site who visit the publicly available portions of the Site, who are not registered Users.',
                'key28': 'By using the Services, whether as a User or an Visitor, you acknowledge that you have read this privacy policy, and consent to the handling of your personal information as described in this policy, which we may update from time to time as described in the “Changes to this Privacy Policy” section, below.',
                'key29': 'By using the Services, you consent to your personal information being stored and otherwise processed as described in this policy.',
                'key30': 'Collection of Information',
                'key31': 'We collect personal information that you provide us directly, from third party sources, and automatically when you use the Services as follows:',
                'key32': 'Information That You Provide To Us Directly',
                'key33': 'Docquity User Accounts',
                'key34': 'When you register a Docquity account, we require you to provide certain personal information that varies depending on whether we have already created a professional profile for you, as described in the “Docquity User Accounts” section below. If you already have a profile, we ask that you verify the information, and if you do not we ask that you provide us some of the information typically included in a Docquity profile. All Docquity users must provide their email address, and healthcare professionals are required to provide certain information about their education, such as school attended, year of graduation, and specialty.',
                'key35': 'Registering Docquity users also have the option to provide additional information to include in their profile, including subspecialty, clinical and professional interests, languages spoken at the office, profile photo, medical group, hospital affiliations, internship, residency program, fellowships, employment history, speaking engagements, publications, userships and board certifications. You may choose to update or supplement the information you have provided us at any time as described in the “Updating Your Account Information” section below.',
                'key36': 'To enhance the accuracy and integrity of the Docquity network, we may require registering users to verify their identity and credentials, which verification may require them to answer identity verification questions and/or provide us a professional email address, a copy of their professional license, medical ID or other relevant documentation. While we take steps to verify that Docquity users are actually the healthcare providers they claim to be, we make no guarantee as to any User’s identity, professional credentials or licensure status.',
                'key37': 'Submissions',
                'key38': 'We collect content and information that you submit to the Service (and information about you may be provided by other Docquity users), including comments, searches, contacts, schedule details, your resume, survey responses, contest entries, and creative submissions.',
                'key39': 'Communications',
                'key40': 'When you communicate with us, such as when you contact User Support or respond to a feedback survey, and when you use the Service to communicate with others, we collect details about your communications.',
                'key41': 'Information Collected Automatically When You Use The Services',
                'key42': 'In addition to the personal information you provide to us directly, we collect a variety of personal information automatically when you use the Services through the use of cookies and similar technologies (e.g., pixels, web beacons and device identifiers), which we refer to as “Log Data” as further described in the "Cookies and Similar Technologies" section below. Log Data includes activity information such as the date and time that you access one of our sites or apps, and whether you view, click or otherwise interact with content, including articles, links, advertisements promoting the products and services of Docquity or our customers, open or click a link in one of our emails, conduct a search on one of our websites, share an article displayed in your newsfeed, or send, receive, or engage with a message sent through the Service. We collect device information including your IP address (which is a unique numerical address that identifies your device when you access the Internet), your browser and device type and model, operating system, your ISP and mobile carrier, your advertising ID (which is a unique, user-resettable identification number associated with a mobile device), other unique identifiers associated with your computer or mobile device, and the sites you visit before and after you visit one of our sites',
                'key43': 'The use of these technologies helps us serve you better by understanding what you’re interested in, tracking trends, measuring the effectiveness of our content, including ads, and saving your preferences. For information about how you can manage your cookie preferences, refer to the “Choices Regarding Personal Information” section, below.',
                'key44': 'Location Information',
                'key45': 'We do not collect precise location information unless you elect to enable location services on your device, but we do receive information about your general location at a city or zip code level through your computer’s IP address and your mobile device.',
                'key46': 'Information Obtained From Third Party Sources',
                'key47': 'We collect information from third party sources, including government agencies, publicly available databases, professional associations, publications, employers, our business partners, service providers, customers, and other Docquity users, some of which we may add to your professional profile as described in the “Docquity User Accounts” section, below. We also may obtain email addresses that we use to promote the Service to users and potential users, and prescribing and claims data that we may use to personalize content, including advertising.',
                'key48': 'Uses of Information',
                'key49': 'We use the personal information we collect for the following business and commercial purposes:',
                'key50': 'Provision of the Service',
                'key51': 'We use the personal information we collect to provide, operate, support, and improve the Services, including through the development of new tools and features.',
                'key52': 'Docquity User Accounts',
                'key53': 'We use information about you to create and update your professional profile, to create and maintain your Docquity user account if you choose to register, and to support and respond to your inquiries and requests.',
                'key54': 'Communications',
                'key55': 'We use your information to communicate with you about the Service. These communications may contain information about your professional connections, professional opportunities, conferences and other events, relevant editorial content, and marketing about products and services of Docquity and our customers. These communications may be sent through a variety of channels, including on-site or in-app notifications and email. We also use your information to process, store, and transmit your communications through the Services.',
                'key56': 'Personalizing your Experience',
                'key57': 'We use your information to personalize the Services including content, marketing messages, and advertising served to you on our sites and third party sites, and to generally enhance your Docquity experience. For example, we tailor your newsfeed to focus on content relevant to your practice, specialty and inferred interests based on your profile and how you engage with the Services. We use your information to target (and measure the performance of) advertising and other content sponsored by third parties, including pharmaceutical companies promoting their products and therapies, and hospitals promoting their services. For example, if you are an oncologist or have demonstrated an interest in oncology content, you may see advertisements targeted to oncologists or oncology-interested users. Advertising will be identified as an “Advertisement,” “Sponsored,” “Promoted” or some similar designation indicating that the content is promotional and has been selected by the third-party sponsor.',
                'key58': 'Other Uses of Your Information',
                'key59': 'We also use your information to detect, investigate, and prevent fraudulent, harmful, unauthorized, unethical or illegal activities to protect the rights, safety and property of Docquity, our users and others, including through the enforcement of the Docquity Terms of Service, in connection with legal proceedings, including arbitration, and to comply with our legal obligations. Also, we may create anonymous, aggregated or de-identified data from your personal information and other individuals whose personal information we collect. We may use this anonymous, aggregated or de-identified data and share it with third parties for our lawful business purposes, including to analyze and improve the Services and promote our business.',
                'key60': 'Sharing of Information',
                'key61': 'In connection with the activities described above, we may share personal information with third parties as follows:',
                'key62': 'Business Partners',
                'key63': 'We may share your public profile information with trusted business partners that we work with to provide services to you and others. These services increase the visibility of our users and their employers in the healthcare market, thereby increasing their business and professional opportunities.',
                'key64': 'Research Institutions',
                'key65': 'As part of our mission to understand and support the medical profession, we may share your information with research institutions that perform research related to their respective missions. We require these institutions to agree that they will only use and share the data as needed for the approved research purpose and that any resulting research output be aggregated and anonymized so that it cannot be linked back to any individual. We may publish or allow others to publish insights learned from such research activities.',
                'key66': 'Service Providers',
                'key67': 'We may share your personal information with third parties that help us provide the Services and operate our business, including in the areas of website and database hosting and maintenance, information security, fraud detection and prevention, email management, data analytics, marketing, advertising, User support, and identity and professional credential verification.',
                'key68': 'Commercial Clients',
                'key69': 'We provide our commercial clients, including hospitals, pharmaceutical, and life science companies, with profile search and messaging tools as part of our talent and marketing services businesses. They use these tools to select users that may be interested in their information and opportunities, including in the areas of events, clinical trials, innovative therapies, and other clinical solutions, and we communicate their messages to the selected Docquity users on their behalf. We also provide our commercial clients with User information in connection with our audience development and performance reporting for their advertising and other sponsored content programs delivered through the Service. When you engage with sponsored content, we may share with the sponsor and its agency limited information from your Docquity profile such as your name, specialty, and zip code, along with details about the engagement such as whether you viewed and or interacted with an advertisement. Prior to sharing this information, we require the recipients to agree to limit their use to the authorized purpose. We do not share your Private Profile Information or any personal contact information with our commercial clients without your consent.',
                'key70': 'CME/CPD Providers',
                'key71': 'As part of our Services, we may share your personal information with Continuing Medical Education (CME) and Professional Development (CPD)  providers who provide and/or certify CME and CPD activities that we offer through the Service, for the purpose of processing CME or CPD activities in which you choose to participate, and as required for their accreditation compliance and record keeping purposes. We may forward to you emails from these organizations in connection with your participation in the applicable activities, including to send you CME/CPD credit certificates you have earned.',
                'key72': 'Advertising Partners',
                'key73': 'We work with third party partners to serve advertising for us on third party sites and to evaluate the effectiveness of our advertising. These third parties use cookies and similar technologies to collect information about your use of the Service and other sites and online services over time, including certain Log Data described above, so they can display our ads to you on other sites and apps.',
                'key74': 'Business Transferees',
                'key75': 'We may share your personal information in connection with a business transaction (or potential transaction) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, Docquity or our corporate affiliates (including, in connection with a bankruptcy or similar proceedings).',
                'key76': 'Affiliated Companies',
                'key77': 'We may share personal information with our corporate affiliates for use consistent with this privacy policy.',
                'key78': 'Authorities',
                'key79': 'We may access, preserve, and share your information with third parties, as required or permitted by applicable law, if we believe it is reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on us, which may involve disclosure to law enforcement, courts, or governmental authorities. Also, if you provide us information about an adverse event relating to a pharmaceutical product or medical device, we may be required to report this information along with your contact information to the manufacturer so it can comply with legally required reporting obligations.',
                'key80': 'Other Third Parties',
                'key81': 'We may access, preserve, and share your information if we determine it is reasonably necessary or appropriate to investigate, prevent or take action regarding suspected fraudulent, harmful, unethical or illegal activities, or any violation of our Terms of Service or to protect and defend the legal rights, safety, and property of Docquity, our employees, agents and contractors (including to enforce our agreements) or in connection with any safety or security concerns, including the personal safety of our users and the public.',
                'key82': 'With Your Consent',
                'key83': 'We also may share your personal information with a third party in a manner not addressed by this policy with your consent.',
                'key84': 'Cookies and Similar Technologies',
                'key85': 'We and our partners use various tools to collect data when you visit our sites and apps, including cookies, pixel tags, and other similar technologies. These technologies enable us to track usage of the Services and to enhance the user experience by providing a more relevant and personalized online experience. A cookie is a small data file that is sent to a user’s browser when visiting a website. It is stored, accessed and maintained on the computer\'s hard drive or on a tablet or mobile device. A cookie contains a unique number that allows a website to recognize a particular user’s device each time that user visits the website so they don’t need to log in each time they visit and their preferences can be stored. Cookies enable us to measure the use and effectiveness of our content, track user trends, monitor and stabilize our websites, and improve the quality of the Services and content personalization (including ads) both on Docquity and third party sites.',
                'key86': 'Most browsers can be set to reject all cookies, including third party cookies, however, if you choose to reject our cookies, your ability to access and use the Services will be limited. We and our partners also use pixel tags, often in conjunction with cookies. Pixel tags are small pieces of code that are embedded on web pages and apps in order to provide us with information about how our sites and apps are used. We also include pixel tags in our emails to inform us whether the recipient has opened those emails and/or clicked on links in those emails.',
                'key87': 'Third Party Links',
                'key88': 'Our Services may contain links to third-party sites. Any access to and use of such linked sites is not governed by this policy, but instead is governed by the privacy policies of those third party sites, which we encourage you to read. We are not responsible for the information practices of such third party sites.',
                'key89': 'Security',
                'key90': 'We implement technical and organizational measures designed to protect the security of your personal information. However, no security measure can guarantee security and that data will not be accessed, disclosed, altered, or destroyed by a breach of our safeguards. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a complex password that nobody else knows or can easily guess, and keeping your log-in credentials private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.',
                'key91': 'Data Retention',
                'key92': 'We will retain your personal information for at least the period reasonably necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. If we delete some or all of your personal information, we may continue to retain and use aggregate or anonymous data previously collected and/or aggregate or anonymize your personal information.',
                'key93': 'Choices Regarding Personal Information',
                'key94': 'Your Account And Profile Information.',
                'key95': 'If you have registered a Docquity account, you may review and update certain personal information through your account settings. If you prefer to submit an update request to us directly, or if you do not have a Docquity account but would like us to modify or remove your public profile, or if you wish to close your account, please contact us at',
                'key96': 'Opting Out of Marketing Emails',
                'key97': 'You may opt-out of receiving marketing emails from Docquity at any time by opting out via the link the email footer, adjusting your account settings or sending an opt-out request to',
                'key98': 'Please be aware that you cannot opt-out of receiving services messages from us including security and legal notices.',
                'key99': 'Controlling Cookies and Interest-based Advertising',
                'key100': 'If you would prefer not to accept cookies, visit the “help” section of your browser to learn about cookie preferences and other privacy settings that may be available. Most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. Note that rejecting cookies will not prevent device identification and related data collection from occurring. Also, if you choose to reject cookies, your access to some functionality and areas of the Service may be restricted. For additional information about cookies visit',
                'key101': 'As described above, we allow certain third party partners to place tracking technologies on the Service for the purpose of delivering interest-based advertising to you on third party sites. You can opt-out of certain third party interest-based advertising services we may use by visiting the following links: for Google:',
                'key102': 'For Twitter',
                'key103': 'For Facebook',
                'key104': 'and for LinkedIn',
                'key105': 'We may use Google Analytics to recognize you and link the devices you use when you visit the Service on your browser or mobile device. Google Analytics allows us to better understand how our users interact with the Service and to tailor our content to you. For information on how Google Analytics collects and processes data, as well as how you can control information sent to Google, review Google\'s site “How Google uses information from sites or apps that use our services” currently located at',
                'key106': 'You can learn about Google Analytics’ currently available opt-outs, including the Google Analytics Browser Ad-On here',
                'key107': 'Push Notifications',
                'key108': 'If you consent to receive push notifications, you may withdraw your consent at any time by adjusting the appropriate operating system settings on your mobile device.',
                'key109': 'Changes to this Privacy Policy',
                'key110': 'We reserve the right to modify this privacy policy at any time and in our sole discretion. When we make a change, we will update the “Effective Date” and post the updated policy on or through the Service. We may, and if required by law will, also provide notification of changes in another way that we believe is reasonably likely to reach you, such as by e-mail (if you have an account where we have your contact information) or another manner through the Service. Any modifications to this privacy policy will be effective upon posting or as otherwise indicated at the time of posting. In all cases, by continuing to use the Service after posting of the updated policy, you are consenting to the changes. If you object to any changes, you may close your account by contacting',
                'key111': 'Contact Us',
                'key112': 'If you have any questions about this policy or Docquity’s practices in relation to information collection, use and disclosure, you may reach us at any time by emailing',
                'key113': 'We will use all reasonable efforts to respond promptly to requests, questions or concerns You may have regarding our use of personal information about You. Except where required by law, Docquity cannot ensure a response to questions or comments regarding topics unrelated to this policy or Docquity’s practices in relation to information collection, use and disclosure.'
            },
            'related_items': {
                'key1': 'Related Article'
            },
            'success_modal': {
                'key1': 'Message'
            },
            'suggestion_right': {
                'key1': 'Trending Course',
                'key2': 'Points',
                'key3': 'Set it up today',
                'key4': 'Verification Pending',
                'key5': 'Get access to all the new and exciting features',
                'key6': 'Verify Yourself'
            },
            'document_require': {
                'key1': 'Upload',
                'key2': 'Hint',
                'key3': 'Submit',
                'key4': 'Verify Now',
                'key5': 'Message'
            },
            'clinical_resource_search': {
                'key1': 'Clinical Resources',
                'key2': 'Search'
            },
            'prod_get_sample': {
                'key1': 'Product List',
                'key2': 'Drug Order form',
                'key3': 'Get Sample',
                'key4': 'Get Sample',
                'key5': 'User name',
                'key6': 'User name',
                'key7': 'Mobile number',
                'key8': 'Mobile number',
                'key9': 'Enter email id',
                'key10': 'Enter email id',
                'key11': 'Please enter your Address',
                'key12': 'Please enter your Address',
                'key13': 'Pincode',
                'key14': 'Pincode',
                'key15': 'Date',
                'key16': 'Select Date',
                'key17': 'Time Range',
                'key18': 'Comment',
                'key19': 'Comment',
                'key20': 'Submit',
                'key21': 'No more drug sample',
                'key22': 'Message',
                'key23': 'Confirm',
                'key24': 'Confirm',
                'key25': 'cancel'
            },
            'middle_ware': {
                'key1': 'Oops!',
                'key2': 'something went wrong please try again',
                'key3': 'Reload page',
                'key4': 'Something Went Wrong. Try Again Later'
            },
            'info': {
                'key1': 'Info'
            },
            'event_session_modal': {
                'key1': 'Sessions',
                'key2': 'Back'
            },
            'event_media_list': {
                'key1': 'Media'
            },
            'cme_desc': {
                'key1': 'Info'
            },
            'cme_search': {
                'key1': 'CME',
                'key2': 'BSearchack'
            },
            'conn_sugg_card': {
                'key1': 'Admin',
                'key2': 'Admin'
            },
            'search_university': {
                'key1': 'Search College'
            },
            'university': {
                'key1': 'Skip',
                'key2': 'Enter your College Name',
                'key3': 'Click to Upload Id ',
                'key4': 'Submit',
                'key5': 'ID proof mandatory for Verification'
            },
            'clinical_resource_right': {
                'key1': 'Drug Sample',
                'key2': 'View All',
                'key3': 'Read Next Article'
            },
            'feed_list': {
                'key1': 'No data found!'
            },
            'upload_mrn': {
                'key1': 'Next',
                'key2': 'Benefit of verification',
                'key3': 'Visible Posts',
                'key4': 'Make your posts visible to',
                'key5': '100,000+ Doctors on Docquity',
                'key6': 'Visible Posts',
                'key7': 'Make your posts visible to',
                'key8': '100,000+ Doctors on Docquity',
                'key9': 'What\'s your Registration Number',
                'key10': 'Enter registration Number',
                'key11': 'Please provide requre information',
                'key12': 'Please enter valid email'
            },
            'TnCUpdateModal_Content': {
                'key1': 'Updated Terms and Conditions',
                'key2': 'By continuing to use Docquity you agree to our updated',
                'key3': 'Terms & Conditions',
                'key4': 'Data Privacy Policies',
                'key5': 'and',
                'key6': 'Okay'
            },
            'calendarEvent_list_NoData': {
                'key1': 'There are no calendar items',
                'key2': 'Keep checking this space for the latest updates',
                'key3': 'assets/images/webcalendar/no-data.svg',
                'key4': 'No meetings scheduled today',
                'key5': 'Keep checking this space for all your upcoming meetings',
                'key6': 'assets/images/webcalendar/no-meetings.svg',
                'key7': 'No events scheduled today',
                'key8': 'Keep checking this space for the latest and upcoming events',
                'key9': 'assets/images/webcalendar/no-events.svg',
                'key10': 'No webinars scheduled today',
                'key11': 'Keep checking this space for the latest and upcoming webinars',
                'key12': 'assets/images/webcalendar/no-webinars.svg'
            },
            'calendar_fullView_title': {
                'key1': 'All',
                'key2': 'My Meetings',
                'key3': 'Webinars',
                'key4': 'Events'
            },
            'event_card_data': {
                'key1': 'Meeting Started',
                'key2': 'Meeting ended',
                'key3': 'Meeting expired',
                'key4': 'Decline',
                'key5': 'Re-schedule',
                'key6': 'Accept',
                'key7': 'View Details',
                'key8': 'Join Meeting',
                'key9': 'Join Webinar',
                'key10': 'Actual meeting Date & time',
                'key11': 'Proposed Date & time'
            },
            'event_modal_text': {
                'key1': 'Select Time Slot',
                'key2': 'MR is available at below mentioned time slots. Please select your preferred time slot for the meeting',
                'key3': 'Re-Schedule Meeting',
                'key4': 'Let us know your preferred time and we will try to book a meeting at your preferred date and time.',
                'key5': 'Are you sure want to decline meeting?',
                'key6': 'You can come back and accept or reschedule this meeting anytime you want',
                'key7': 'Cancel',
                'key8': 'Decline Meeting',
                'key9': 'Send Request'
            },
            'meeting_detail': {
                'key1': 'Meeting Details',
                'key2': 'Organizer',
                'key3': 'Information',
                'key4': 'Description',
                'key5': 'Attachment',
                'key6': 'Invitee List',
                'key7': 'View All',
                'key8': 'Details',
                'key9': 'Group Chat',
                'key10': 'You'
            },
            'edetail': {
                'key1': 'Continue',
                'key2': 'I\'ll do it later',
                'key3': 'Accept',
                'key4': 'Add Missing Details',
                'key5': 'Please fill the following details to see e-detailing',
                'key6': 'Speciality',
                'key7': 'Add your speciality',
                'key8': 'Email ID',
                'key9': 'Work Place',
                'key10': 'Continue',
                'key11': 'Terms & conditions',
                'key12': 'Select Therapeutic Area',
                'key13': 'Please select this field',
                'key14': 'Please enter your email ID or correct email ID',
                'key15': 'Share',
                'key16': 'View All',
                'key17': 'No data found!',
                'key18': 'View All Detail',
                'key19': 'Enter your Email',
                'key20': 'I accept the',
                'key21': 'Subscribe Now',
                'key22': 'Close'
            },
            'event_listing': {
                'key1': 'Home',
                'key2': 'Events',
                'key3': 'Types of Event',
                'key4': 'Upcoming',
                'key5': 'Attending',
                'key6': 'Past',
                'key7': 'Attended',
                'key8': 'No Upcoming Events',
                'key9': 'Watch this space for all your upcoming events',
                'key10': 'No Attending Events',
                'key11': 'To see the attending event, please register your event now',
                'key12': 'No Past Events',
                'key13': 'Watch this space for all your past events',
                'key14': 'No Attended Events',
                'key15': 'Watch this space for all your attended events',
                'key16': 'Doctor(s) Attending',
                'key17': 'Itinerary',
                'key18': 'View Detail',
                'key19': 'Register',
                'key20': 'Share',
                'key21': 'Mark Going',
                'key22': 'No credit',
                'key23': 'Congratulation',
                'key24': 'Ok'
            },
            'events_detail': {
                'key1': 'Virtual Booth(s)',
                'key2': 'My Event Activity',
                'key3': 'View Stats',
                'key4': 'View Vaults',
                'key5': 'View All Details',
                'key6': 'Sponsors',
                'key7': 'Certificates',
                'key8': 'View Certificate',
                'key9': 'Credits',
                'key10': 'Title',
                'key11': 'Certificate',
                'key12': 'View Certificate',
                'key13': 'Download',
                'key14': 'Speakers',
                'key15': 'Details',
                'key16': 'About Speaker',
                'key17': 'View Detail',
                'key18': 'Speakers will be available soon',
                'key19': 'View Details',
                'key20': 'Sessions',
                'key21': 'Session',
                'key22': 'Days',
                'key23': 'Day',
                'key24': 'Event Itineraries',
                'key27': 'Events Detail',
                'key30': 'Add to calendar',
                'key31': 'View Map',
                'key33': 'Credits',
                'key25': 'Share',
                'key26': 'Mark going',
                'key28': 'Congratulations',
                'key29': 'Doctor(s) Attending',
                'key32': 'view less',
                'key34': 'more',
                'key35': 'Read more',
                'key36': 'Read less',
                'key37': 'Other Details',
                'key38': 'Session Schedules',
                'key39': 'Session Detail',
                'key40': 'Doctor(s) are attending',
                'key41': 'No record found',
                'key42': 'My Session Activity',
                'key43': 'hrs',
                'key44': 'This activity progress will be updated within 24 hours'
            },
            'inviteCode': {
                'key1': 'Enter New Invite Code',
                'key2': 'Invite Code',
                'key3': 'Fill User Invite Code',
                'key4': 'Invalid invite code. Please try again!',
                'key5': 'Submit'
            },
            'teleConsulting': {
              'key1': 'Own your Digital Clinic',
              'key2': 'Consult your patients via video calls, chats, manage their medical data and much more',
              'key3': 'Claim Now',
              'key4': 'About docquityclinic',
              'key5': 'We have created a Digital Clinic platform exclusively for your current e-Consultation needs. Now seamlessly tele-consult with your patients with the greatest ease!',
              'key6': 'Features',
              'key7': 'E-Prescription',
              'key8': 'Manage Teleconsultation',
              'key9': 'Create Patient Communities',
              'key10': 'I agree to share my profile details to docquityclinic to create my account in docquityclinic which includes but is not limited to Name, Phone number, Email ID, Speciality, City, Workplace, etc.',
              'key11': 'Set up Digital Clinic',
              'key12': 'Loading…',
              'key13': 'Your digital clinic data is being set up, please wait.',
              'key14': 'Sorry for the inconvenience, it’s taking longer than expected, please wait.',
              'key15': 'Completed',
              'key16': 'Your clinic has been set up successfully.',
              'key17': 'Something went wrong',
              'key18': 'Sorry for the inconvenience, please wait a few minutes before you try again.',
              'key19': 'Please wait, your request is being processed. If it’s taking more time, you can close this screen, and click the Teleconsultation button again to see your progress.',
              'key20': 'Sorry, your request is still being processed, it’s taking longer than expected. You can close this screen and we’ll notify you once it’s done.'
            },
            'noResultFoundScreen': {
              'key1': 'No results found',
              'key2': 'Try searching for something else',
              'key3': 'Edit search'
            },
            'community': {
              'key1': 'Community',
              'key2': 'Create',
              'key3': 'Communities',
              'key4': 'new',
              'key5': 'Edit',
              'key6': 'Delete',
              'key7': 'unFollow',
              'key8': 'Report',
              'key9': 'Created By',
              'key10': 'View All',
              'key11': 'Create your own community by adding friends, colleagues and experts to share content in closed groups.',
              'key12': 'Done',
              'key13': 'Specialty does not exist',
              'key14': 'You can select maximum',
              'key15': 'Search Speciality',
              'key16': 'Add',
              'key17': 'User does not exist',
              'key18': 'Selected',
              'key19': 'Members',
              'key20': 'Speciality',
              'key21': 'Name',
              'key22': 'Enter',
              'key23': 'Upload Media',
              'key24': 'Select',
              'key25': 'Home',
              'key26': 'Search',
              'key27': 'Communities will be listed here when other users add you as a member to their community',
              'key28': 'Back',
              'key29': 'Special characters (!@#$%^&*) are not allowed. Enter alphanumeric characters only.',
              'key30': 'Description',
              'key31': 'Update',
              'key32': 'Detail',
              'key33': 'specialties',
              'key34': 'Create Community',
              'key35': 'Maximum file size should be 5 MB.',
              'key36': 'Only PNG and JPG format is supported.',
              'key37': 'About Community',
              'key38': 'Posted By',
              'key39': 'Content Type',
              'key40': 'Community Type',
              'key41': 'My Communities',
              'key42': 'All',
              'key43': 'Other Communities',
              'key44': 'You haven’t posted anything under the community',
              'key45': 'Post',
              'key46': 'Exit Community',
              'key47': 'Do you want to exit this community?',
              'key48': 'You will not be able to access content of this community once you exit.',
              'key49': 'Cancel',
              'key50': 'Community Creator',
              'key51': 'You',
              'key52': 'View Existing Members',
              'key53': 'Admin',
              'key54': 'Member',
              'key55': 'Here, you can view the list of communities you were earlier. You will be able to view the content you had posted in these community. Your content is visible only to you in these communities.',
              'key56': 'Past/Inactive Communities',
              'key57': 'You are not part of this community.',
              'key58': 'Past communities are communities you were part of formerly.',
              'key59': 'Inactivated communities are communities which are no longer active.',
              'key60': 'Only you will be able to view the content posted by you in a past / inactivated community.',
              'key61': 'You don’t have a past community.',
              'key62': 'No data found',
              'key63': 'Past/Inactive Community',
              'key64': 'Past Community Details',
              'key65': 'Select Privacy',
              'key66': 'Public Community',
              'key67': 'Private Community',
              'key68': 'Public communities are visible to everybody and any Docquity user can join these.',
              'key69': 'Private communities are visible only to the members of the community.',
              'key70': 'Creator',
              'key71': 'Public communities will appear here.',
              'key72': 'Join Community',
              'key73': 'The community has reached maximum members capacity. Contact the community admin for more details.',
              'key74': 'Join this community to view this area',
              'key75': 'Inactivate Community',
              'key76': 'Activate Community',
              'key77': 'Inactivate Community?',
              'key78': 'Inactivating the community will move the community to dormant state. The community content will no longer be available.',
              'key79': 'Yes',
              'key80': 'No',
              'key81': 'Are you sure you want to inactivate this community?',
              'key82': 'Confirm',
              'key83': 'Cancel',
              'key84': 'This community is currently Inactivated.',
              'key85': 'More',
              'key86': 'You are Admin of this community',
              'key87': 'You don’t have an inactive community.',
              'key88': 'Withdraw join request',
              'key89': 'You will be added to the community on approval by community admins',
              'key90': 'Your join request has been withdrawn.',
              'key91': 'Would you like to cancel request?',
              'key92': 'Withdraw Request',
              'key93': 'Withdraw',
              'key94': 'Don\'t Withdraw',
              'key95': 'You are Admin',
              'key96': 'Join Requests',
              'key97': 'Accept',
              'key98': 'Reject',
              'key99': 'Do you want to reject user_name\'s request to join this community?',
              'key100': 'user_name has been added',
              'key101': 'user_name has been rejected',
              'key102': 'Requests',
              'key103': 'Request to join',
              'key104': 'Advance Settings',
              'key105': 'Public community join requests',
              'key106': 'Auto approve join community request',
              'key107': 'Admins need to approve join community request',
              'key108': 'Are you sure you want to delete this content?',
              'key109': 'Delete from community',
              'key110': 'Once you delete the content, the community members will not be able to view the content in this community',
              'key111': 'Cancel',
              'key112': 'Proceed',
              'key113': 'No',
              'key114': 'Yes',
              'key115': 'Waiting for community admin to approve your request.',
              'key116': 'and more.',
              'key117': 'Community',
              'key118': 'Public Communities',
              'key119': 'View All Communities',
              'key120': 'Create new Community',
              'key121': 'Edit Community',
              'key122': 'Community Name',
              'key123': 'Community Description',
              'key124': 'Community Members',
              'key125': 'Create Community',
              'key126': 'Update Community',
              'key127': 'Select Speciality',
              'key128': 'Select Community Members',
              'key129': 'Enter Community Name',
              'key130': 'Enter Community Description',
              'key131': 'Communities managed by me',
              'key132': 'Past Communities',
              'key133': 'Inactive Communities',
              'key134': 'And',
              'key135': 'Posted in',
              'key136': 'Channels',
              'key137': 'Associations',
              'key138': 'You are not eligible to view this',
              'key139': 'and actual_count more associations',
              'key140': 'and actual_count more communities',
              'key141': 'user_name posted in actual_count associations and more',
              'key142': 'Posted',
              'key143': 'posted in community_name and actual_count more',
              'key144': 'association',
              'key145': 'more',
              'key146': 'and',
              'key147': 'community',
              'key148': 'communities',
              'key149': 'associations',
              'key150': 'Existing Members',
              'key151': 'Association',
              'key152': 'View Community',
              'key153': 'Share',
              'key154': 'About this community',
              'key155': 'read more',
              'key156': 'read less',
              'key157': 'Pin post',
              'key158': 'Unpin post',
              'key159': 'Review and add members',
              'key160': 'Review and remove',
              'key161': 'Confirm and remove members',
              'key162': 'Confirm and add users',
              'key163': 'Review addition',
              'key164': 'Review removal',
              'key165': 'Remove',
              'key166': 'Remove members',
              'key167': 'Existing members list',
              'key168': 'Review',
              'key169': 'The changes made by you will be discarded! Do you want to proceed?',
              'key170': 'Add again',
              'key171': 'Add Members',
              'key172': 'Save'
            },
            'consentFormValidations': {
              'key1': 'Please enter valid Email Id'
            },
            'global_search': {
              'key1': 'View All'
            },
            'ncp_card_screen': {
              'key1': 'Like',
              'key2': 'likes',
              'key3': 'Comment',
              'key4': 'Comments',
              'key5': 'Share',
              'key6': 'View all',
              'key7': 'Liked',
              'key8': 'by',
            },
            'game_detail': {
              'key1': 'Game details'
            },
            'leaderboard': {
              'key1': 'Leaderboard'
            },
            'health_card': {
              'key1': 'Available for',
              'key2': 'Share',
              'key3': 'Dr.'
           },
            'tutorial': {
              'key1': 'Welcome to picture in picture mode',
              'key2': 'Watch your video while using Docquity',
              'key3': 'How it works',
              'key4': 'Skip',
              'key5': 'Next',
              'key6': 'Got it',
              'key7': 'Clicking here will expand the video',
              'key8': 'Click here play/pause the video',
              'key9': 'Click here to close the player',
              'key10': 'New',
              'key11': 'Step',
              'key12': 'Introducing Audio',
              'key13': 'Now you can listen to journals directly in the app. Tap the listen button at the top of a journal to start',
              'key14': 'Listen to this article',
              'key15': 'Tag Your Connections',
              'key16': 'Now you can tag your connection in the comment section. Type \'@\' to start tagging',
              'key17': 'Introducing Chat!',
              'key18': 'Stay in touch, discuss and share content with your peers',
              'key19': 'Start a chat'
            },
            'livePoll': {
              'key1': 'Poll',
              'key2': 'This poll has ended',
              'key3': 'Answer the live poll by selecting below options',
              'key4': 'Informative polls conducted during live webinar'
            },
            "survey_questionnaire": {
              "key1": "All",
              "key2": "Expired on",
              "key3": "{X} doctor(s) attempted this survey",
              "key4": "Share",
              "key5": "View Details",
              "key6": "Survey you left in the middle",
              "key7": "View All",
              "key8": "Drafts",
              "key9": "Completed",
              "key10": "Read More",
              "key11": "Read Less",
              "key12": "Retake",
              "key13": "I have read & agreed to the Terms & Conditions",
              "key14": "Let's get started",
              "key15": "This is a required question",
              "key16": "Others",
              "key17": "Enter Details",
              "key18": "The minimum character is",
              "key19": "You’ve reached the max. character limit",
              "key20": "Options",
              "key21": "Rank",
              "key22": "How can we improve it?",
              "key23": "Please write your feedback",
              "key24": "Please write your answers",
              "key25": "You only can upload maximum ",
              "key26": "You only can upload the file with maximum ",
              "key27": "You are almost there!",
              "key28": "Answer the remaining question to complete the survey",
              "key29": "Continue to Survey",
              "key30": "Save & Continue Later",
              "key31": "Discard Survey",
              "key32": "Powered by",
              "key33": "Responses",
              "key34": "Back",
              "key35": "Next",
              "key36": "Cancel",
              "key37": "Write a description",
              "key38": "Select",
              "key39": "Description",
              "key40": "Select Time",
              "key41": "Browse file or drag and drop",
              "key42": "Uploading",
              "key43": "Mandatory Consent!",
              "key44": "To attempt this survey, you need to agree to Terms & Conditions",
              "key45": "I agree & Let's Start",
              "key46": "All survey responses are anonymous. We won't share any of your personal information with anyone.",
              "key47": "Maximum respondents have been reached",
              "key48": "Thank you for your time and interest in this survey. We have already collected enough data to move forward. You can choose another survey from our lists by clicking the button below.",
              "key49": "Back to survey lists",
              "key50": "Thank you for your time and interest in this survey. We have already collected enough data to move forward. See you next time 👋",
              "key51": "Close survey",
              "key52": "Survey",
              "key53": "Explore Homepage",
              "key54": "No survey found",
              "key55": "No survey on draft",
              "key56": "No draft completed yet",
              "key57": "Attempted",
              "key58": "Submit",
              "key59": "Retake Survey",
              "key60": "Thank you for completing survey",
              "key61": "Oops, this survey has been expired ",
              "key62": "Thank you for your time and interest in this Survey. Please click the button below to see other active surveys.",
              "key63": "Explore Other Surveys",
              "key64": "Close",
              "key65": " files",
              "key66": " MB",
              "key67": "File type is invalid. Please upload in the provided formats.",
              "key68": "Only .jpeg, .png, .svg & .gif format accepted",
              "key69": "Only .mp4 format accepted",
              "key70": "Only .doc, .docx, .pdf & .ppt  format accepted",
              "key71": "Uploading...",
              "key72": "DD/MM/YYYY",
              "key73": "Ranking is already assigned to another option",
              "key74": "Invalid input! Only numerical values are allowed.",
              "key75": "Invalid input! Only alphanumeric values are allowed.",
              "key76": "Invalid input! Characters only from A-Z are allowed",
              "key77": "Invalid input! Alphanumeric and special characters are allowed.",
              "key78": "Only",
              "key79": " format accepted",
              "key80": "Explore Docquity!",
              "key81": "Invalid Date Range",
              "key82": "Invalid Date"
            },
            'samvaad': {
             'key1': 'You',
             'key2': 'Exchange messages and medias to a connection or a group.',
             'key3': 'Start a chat',
             'key4': 'Cancel',
             'key5': 'View Profile',
             'key6': 'Report User',
             'key7': 'View Group',
             'key8': 'Report Group',
             'key9': 'Suspend group',
             'key10': 'Online',
             'key11': 'typing...',
             'key12': 'Done',
             'key13': 'Update Group image',
             'key14': 'Chat',
             'key15': 'Chats',
             'key16': 'Search chat...',
             'key17': 'Type your message...',
             'key18': 'Send files',
             'key19': 'Upload Media',
             'key20': 'Select user',
             'key21': 'No Result Found',
             'key22': 'Success',
             'key23': 'You have no active group yet',
             'key24': 'Preview',
             'key25': 'Close',
             'key26': 'to',
             'key27': 'and',
             'key28': 'others',
             'key29': 'members',
             'key30': 'The user is no longer available for the chat',
             'key31': 'Admin',
             'key32': 'Chat is under maintenance',
             'key33': 'Apologies, our chat service is either busy assisting others or undergoing maintenance. We\'ll be back soon. Thanks for your patience!',
             'key34': 'Got it'
           },
           'samvaad_floating_button': {
             'key1': 'Start a personal chat',
             'key2': 'Start a group chat'
           },
           'samvaad_system_msg': {
             'key1': 'created this group',
             'key2': 'Removed',
             'key3': 'Added',
             'key4': 'left the group',
             'key5': 'changed the group image ',
             'key6': 'changed the group name from',
             'key7': 'You Have Blocked this Person',
             'key8': 'You can\'t send message to this group because you\'re no longer a participant',
             'key9': 'Sent you a post',
             'key10': 'You sent a post',
             'key11': 'Admin removed you from the group',
             'key12': 'You left the group',
             'key13': 'Group Successfully Created',
             'key14': 'Your connection created this group',
             'key15': 'You removed a member from the group',
             'key16': 'Admin removed a member from the group',
             'key17': 'You added member to the group',
             'key18': 'Admin added you in the group',
             'key19': 'Admin added a member into the group',
             'key20': 'A Member left this group',
             'key21': 'You updated the group icon',
             'key22': 'Admin updated the group icon',
             'key23': 'You updated the group name',
             'key24': 'Admin updated the group name',
             'key25': 'members to the group ',
             'key26': 'a member into the group',
             'key27': 'removed a member from the group'
           },
           'samvaad_chat_detail': {
             'key1': 'Invalid Content',
             'key2': 'Unblock chat ',
             'key3': 'Added',
             'key4': 'left the group',
             'key5': 'changed the group image ',
             'key6': 'changed group name to',
             'key7': 'You have blocked this user',
             'key8': 'You can no longer exchange messages',
             'key9': 'To chat, unblock this user',
             'key10': 'You can\'t send message to this group because you\'re no longer a participant',
             'key11': 'Say hi to ',
             'key12': 'hi'
           },
           'samvaad_block_unblock_modal': {
             'key1': 'Block this User?',
             'key2': 'Unblock this user?',
             'key3': 'If you block this user,',
             'key4': 'you can no longer exchange messages in the group.',
             'key5': 'If you unblock this user, you can exchange messages',
             'key6': 'Block chat',
             'key7': 'Unblock chat',
             'key8': 'Block chat',
             'key9': 'UnBlock chat'
           },
           'samvaad_mute_unmute_modal': {
             'key1': 'Mute notifications for this chat?',
             'key2': 'unMute notifications for this chat?',
             'key3': 'If you unmute this chat, you will be able to get notifications for new messages including attachments',
             'key4': 'If you mute this chat, you will no longer receive notifications for new messages.',
             'key5': 'Mute',
             'key6': 'Unmute',
             'key7': 'Mute notification',
             'key8': 'unMute notification'
           },
           'samvaad_exit_modal': {
             'key1': 'Are you sure want to exit the group?',
             'key2': 'If you exit this group, you can no longer exchange messages in the group.',
             'key3': 'Exit Group'
           },
           'samvaad_create_edit_group': {
             'key1': 'Change Group Image',
             'key2': 'Group name',
             'key3': 'Enter group name',
             'key4': 'Group members',
             'key5': 'Admin',
             'key6': 'Selected',
             'key7': 'Add Members',
             'key8': 'Add Members',
             'key9': 'Are you sure want to remove this person from group?',
             'key10': 'If you remove user from group, he will no longer able to send or receive message in group. ',
             'key11': 'Remove from Group',
             'key12': 'Update Group info',
             'key13': 'Group Information ',
             'key14': 'Create group chat',
             'key15': 'Groups',
             'key16': 'You have no active chat yet',
             'key17': 'Group Successfully Created',
             'key18': 'Group image changed Successfully',
             'key19': 'User removed Successfully',
             'key20': 'Group information updated Successfully',
             'key21': 'Create group chat'
           },
           'samvaad_validation': {
             'key1': 'Please upload png / jpeg file upto 30 MB',
             'key2': 'Only png, jpeg are allowed',
             'key3': 'You can only select a maximum of 10 images at a time',
             'key4': 'Something went Wrong',
             'key5': 'User not in samvaad',
             'key6': 'Not Able to Update',
             'key7': 'Samvaad Can not initiate',
             'key8': 'This users are not part of samvaad',
             'key9': 'You can select maximum'
           },
           'samvaad_tutorial': {
             'key1': 'New',
             'key2': 'Introducing Chat',
             'key3': 'Stay in touch, discuss and share content with your peers',
             'key4': 'Start a chat'
           },
           'samvaad_tooltips': {
             'key1': 'Minimize',
             'key2': 'Maximize',
             'key3': 'Close',
             'key4': 'Options',
             'key5': 'Back',
             'key6': 'Clear',
             'key7': 'Search',
             'key8': 'Expand',
             'key9': 'Start new chat',
             'key10': 'Emoji\'s',
             'key11': 'Send images',
             'key12': 'Send message',
             'key13': 'Add more images',
             'key14': 'Already a member, You can remove the user from the group list',
             'key15': 'Remove'
           },
            'no_stories': {
              'key1': 'Story unavailable',
              'key2': 'Stories from your peers on Docquity and other channels will appear here',
              'key3': 'Story unavailable',
              'key4': 'It seems that this story might have been deleted by the original uploader',
              'key5': 'Back to home'
            },
            'stories': {
              'key1': 'Stories',
              'key2': 'Delete Story',
              'key3': 'Cancel',
              'key4': 'Report'
            },
            'stories_modal': {
              'key1': 'Delete story',
              'key2': 'Are you sure you want to delete this story? We wont be able to save your deleted stories',
              'key3': 'Cancel',
              'key4': 'Delete',
              'key5': 'Delete text',
              'key6': 'Are you sure you want to delete this text'
            },
            'stories_creation': {
              'key1': 'Repost story',
              'key2': 'Change Background Color',
              'key3': 'Explore Templates',
              'key4': 'Share Story ',
              'key5': 'Enter your Message',
              'key6': 'Type something...',
              'key7': 'Done Typing',
              'key8': 'Add text',
              'key9': 'Tag users',
              'key10': 'View Post',
              'key11': 'Register Now!',
              'key12': 'Watch Now!',
              'key13': 'Read More!',
              'key14': 'Repost',
              'Key15': 'No Results Found',
              'key16': 'The changes made by you will be discarded. Do you want to proceed?',
              'key17': 'Discard changes',
              'key18': 'Uploading Failed!',
              'key19': 'Your story was not uploaded. Try again to start uploading. ',
              'key20': 'Reactions',
              'key21': 'Views ',
              'key22': 'View Details',
              'key23': 'View Less',
              'key24': 'Done',
              'key25': 'More Details!',
              'key26': 'You can tag a maximum of 5 connections.',
              'key27': 'Enter your message',
              'key28': 'Repost on your feed',
              'key29': 'Share this on your feed to gather opinions from your peers.',
              'key30': 'Post on your story',
              'key31': 'You can upload this on your story for your peers to view and react.',
              'key32': 'Discard',
              'key33': 'Cancel',
              'key34': 'Try Again',
              'key35': 'You can upload on your stories',
              'key36': 'You can share this on your feed',
              'key37': 'Repost'
            },
            'stories_tutorial': {
              'key1': 'You can use stories on Docquity to share everyday moments with your connections for 24 hours!',
              'key2': 'Create custom stories with the story editor!',
              'key3': 'Wish your peers with a  template for every occasion!',
              'key4': 'Share your most proudest achievements!',
              'key5': 'Next',
              'key6': 'Back',
              'key7': 'Got it!',
              'key8': 'Learn More',
              'key9': 'Registration Confirmed',
              'key10': 'Invite your peers to join this intersting webinar by sharing on YOUR STORY!',
              'key11': 'Liked what you read!',
              'key12': 'Share it with your peers on Docquity by posting on your story',
              'key13': 'You have successfully earned a certification. Share it with your peers by creating a story',
              'key14': 'Hi Dr. ',
              'key15': 'Say \'Hello\' to your peers on Docquity to let them know you are here. Post on your story NOW!',
              'key16': 'Share on my story!',
              'key17': 'Share this post on YOUR STORY to grab more eyeballs.',
              'key18': 'Introducing',
              'key19': 'Stories!',
              'key20': 'Learn More',
              'key21': 'Next',
              'key22': 'Back',
              'key23': 'Got it',
              'key24': 'Share this achievement!',
              'key25': 'Now share interesting content on stories for your peers. ',
              'key26': 'Wish your peers with a template for every occasion!',
              'key27': 'Create stories to share interesting content with your peers!',
              'key28': 'Share your proudest achievements!',
              'key29': 'New!',
              'key30': 'Say Hi!',
              'key31': 'Congratulations!',
              'key32': 'You',
              'key33': 'Increase the reach of your post!'
            },
            'profile_nudges_experience_required': {
            'key1': 'Please enter your title at work',
            'key2': 'Please provide name of your Organization',
            'key3': 'Please select applicable Employment Type',
            'key4': 'Where was your Organization based out of?',
            'key5': 'Please select applicable Organization Type',
            'key6': 'Please enter Start Date',
            'key7': 'Please enter Date of Completion',
            'key8': 'Please upload png/jpg/jpeg file smaller than 30 MB'
            },
            'profile_basics': {
              'key1': 'Currently Working',
              'key2': 'I am Currently Working here',
              'key3': 'Currently Pursuing',
              'key4': 'End Date should be greater than Start Date'
            },
            'profile_nudges_education_required': {
              'key1': 'Please enter the name of Medical school you attended',
              'key2': 'Please enter the title of your Educational qualification',
              'key3': 'Please select your Field of Study',
              'key4': 'Please enter Start Date',
              'key5': 'Please enter Date of Completion',
              'key6': 'Please upload png/jpg/jpeg file smaller than 30 MB'
            },
            'profile_nudge_practice_details_required': {
              'key1': 'What time do you start your practice?',
              'key2': 'What time do you conclude your practice?',
              'key3': 'Please select days when you practice',
              'key4': 'Please enter ZIP Code in numeric form',
              'key5': 'Please enter City where you practice',
            },
            'profile_nudge_residential_address_required': {
              'key1': 'Please enter ZIP Code in numeric form',
              'key2': 'Please provide your residential address details',
              'key3': 'Please enter your City of residence',
            },
            'profile_nudge_medical_awards_required': {
              'key1': 'What is the title of award that you received?',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'When did you receive this award?'
            },
            'profile_nudge_training_received_required': {
              'key1': 'Please enter the title of Training',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date',
              'key4': 'Please enter Date of Completion',
            },
            'profile_nudge_training_imparted_required': {
              'key1': 'Please enter the title of Training',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date',
              'key4': 'Please enter Date of Completion'
            },
            'profile_nudge_consultaion_details_required': {
              'key1': 'How many consultations do you generally do in a day?',
              'key2': 'How many prescription do you write on an average in a day?',
              'key3': 'Please provide names of 3 drugs that you prescribe the most'
            },
            'profile_nudge_civil_awards_required': {
              'key1': 'What is the title of award that you received?',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'When did you receive this award?'
            },
            'profile_nudge_position_of_responsibility_required': {
              'key1': 'Please enter the title of your Position of Responsibility',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date'
            },
            'profile_nudge_paper_presentation_required': {
              'key1': 'Please provide the title of your Paper',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'When was this paper presented?',
            },
            'profile_nudge_confrence_required': {
              'key1': 'Please enter the title of Conference',
              'key2': 'Please enter the venue of Conference',
              'key3': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key4': 'Please enter Start Date',
              'key5': 'Please enter Date of Completion'
            },
            'profile_nudge_blog_required': {
              'key1': 'Please enter the title of Blog you wrote',
              'key2': 'Please share the link to your Blog'
            },
            'profile_nudge_certification_required': {
              'key1': 'What is the title of Certification that you received?',
              'key2': 'Please upload pdf/png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date'
            },
            'profile_nudge_workshop_required': {
              'key1': 'Please enter the title of Workshop',
              'key2': 'Please enter the venue of Workshop',
              'key3': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key4': 'Please enter Start Date',
              'key5': 'Please enter Date of Completion'
            },
            'profile_nudge_webinar_required': {
              'key1': 'Please enter the title of Webinar',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date',
              'key4': 'Please enter Date of Completion'
            },
            'profile_nudge_seminar_required': {
              'key1': 'Please enter the title of Seminar',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date',
              'key4': 'Please enter Date of Completion'
            },
            'profile_nudge_voluntary_service_required': {
              'key1': 'Please enter your title during this service',
              'key2': 'Please upload png/jpg/jpeg file smaller than 30 MB',
              'key3': 'Please enter Start Date'
            },
            'profile_nudge_thesis_required': {
              'key1': 'Please enter the title of your Thesis',
              'key2': 'When did you write this Thesis?'
            },
            'profile_nudge_patent_required': {
              'key1': 'Please enter the title of your Patent',
              'key2': 'When were you granted this Patent?'
            },
            'profile_nudge_publication_required': {
              'key1': 'Please enter the title of your Publication',
              'key2': 'Please provide the Date of Publishing',
              'key3': 'Please upload pdf/png/jpg/jpeg file smaller than 30 MB'
            },
            'profile_nudge_unpublished_work_required': {
              'key1': 'Please enter the title of your Research work',
            },
            'no_login': {
              'key1': 'Hi doctor',
              'key2': 'This feature is not accesible for now',
              'key3': 'Submit',
              'key4': 'Login and get access to our full range of benefits!',
              'key5': "Unlock exclusive technical content for logged-in users.",
              'key6': "Unlock tailored high-quality resources for you (Articles, Webinar, Medicals Cases..etc)",
              'key7': "Join a community of professionals sharing insights and knowledge.",
              'key8': "Personalize browsing with tailored recomendations based on your preference and history.",
              'key9': "Log in to Maximize Your Experience.",
              'key10': "Skip" ,
              'key11':"Log in"
            },
            "common_text": {
              "key1": "Save",
              "key2": "Change Profile pic",
              "key3": "Please enter name",
              "key4": "Please select year and month",
              "key5": "Please select end year and month",
              "key6": "Please enter valid url",
              "key7": "Please enter publisher name",
              "key8": "Please enter title",
              "key9": "Data updated successfully",
              "key10": "Please enter college name",
              "key11" : "Please enter certificate name",
              "key12" : "Success",
              "key13" : "Please select end month",
              "key14" : "Please select start year",
              "key15" : "Please select start month",
              "key16" : "Please select end year"
            }
        };

  /////////////////////////////////////////////

  googleEventText = {
    input_screen: {
      category: 'input screen',
      key1: 'ONBOARDING_REQUEST_OTP_CLICK',
      key2: 'ONBOARDING_LANGUAGE_CHANGED',
      key3: 'ONBOARDING_COUNTRY_CHANGED',
      key4: 'ONBOARDING_MOBILE_INPUT_VISIT'
    },
    otp_screen: {
      category: 'otp screen',
      key1: 'ONBOARDING_Edit_clicked',
      key2: 'RESEND_OTP_CLICKED',
      key3: 'ONBOARDING_VERIFY_OTP_CLICK',
      key4: 'OTP_BACK_BUTTON_CLICKED',
      key5: 'ONBOARDING_OTP_VISIT'
    },
    usertype_screen: {
      category: 'ONBOARDING_SELECT_PROF',
      key1: 'USERTYPE_BACK_BUTTON_CLICKED',
      key2: 'USERTYPE_SKIP_BUTTON_CLICKED',
      key3: 'USERTYPE_INVITE_SUBMIT_BUTTON_CLICKED',
      key4: 'Profession_Selection_Click',
      key5: 'Onboarding_Select_Profession_Visit'
    },
    onboarding_invite_code: {
      category: 'ONBOARDING_INVITE_CODE',
      key1: 'Onboarding_Invite_Code_Click'
    },
    userdetail_screen: {
      category: 'userdetail screen',
      key1: 'USERDETAIL_BACK_BUTTON_CLICKED',
      key2: 'USERDETAIL_NEXT_BUTTON_CLICKED',
      key3: 'ONBOARDING_PER_DETAILS'
    },
    studenttype_screen: {
      category: 'student type screen',
      key1: 'STUDENTTYPE_BACK_BUTTON_CLICKED'
    },
    studenttype_click: {
      category: 'Student_Type_Click',
      key1: 'Student_Type_Click'
    },
    studenttype_screen_visit: {
      category: 'Onboarding_student_type_visit',
      key1: 'Onboarding_student_type_visit'
    },
    verifygroup_user: {
      category: 'verify user screen',
      key1: 'VERIFYUSER_UPLOAD_ID_CLICKED',
      key2: 'VERIFYUSER_NEXT_CLICKED',
      key3: 'VERIFYUSER_SKIP_BUTTON_CLICKED',
      key4: 'VERIFYUSER_BACK_BUTTON_CLICKED',
      key5: 'VERIFYUSER_REMOVE_DOCUMENT_BUTTON_CLICKED',
      key6: 'Onboarding_MRN_Input_Visit'
    },
    onboardspeciality_screen: {
      category: 'onboarding speciality or interest',
      key1: 'ONBOARDING_SPECIALITY_NEXT_BUTTON_CLICKED',
      key2: 'ONBOARDING_SPECIALITY_SELECTED'
    },
    skiponboarding_screen: {
      category: 'skip onboarding screen',
      key1: 'ONBOARDSKIP_CROSS_BUTTON_CLICKED',
      key2: 'ONBOARDSKIP_SKIP_BUTTON_CLICKED',
      key3: 'ONBOARDSKIP_EDIT_BUTTON_CLICKED'
    },
    backscreen_modal: {
      category: 'back registration process',
      key1: 'CONFRIMSCREEN_YES_BUTTON_CLICKED',
      key2: 'CONFRIMSCREEN_NO_BUTTON_CLICKED'
    },
    grandround_header: {
      category: 'header_section',
      key1: 'Hamburger_profile_Click ',
      key2: 'HAMBURGER_REFER_CLICK',
      key3: 'HAMBURGER_VAULT_CLICK',
      key4: 'HAMBURGER_CALENDAR_CLICK',
      key5: 'Hamburger_Bookmark_Click',
      key6: 'Hamburger_Setting_Click',
      key7: 'Hamburger_SendFeedback_Click ',
      key8: 'Hamburger_Terms_Click',
      key9: 'Hamburger_logout_Click ',
      key10: 'Hamburger_Post_Click',
      key11: 'Hamburger_Explore_Click ',
      key12: 'Hamburger_Notification_Click',
      key13: 'notification list clicked',
      key14: 'logo_clicked',
      key15: 'Hamburger_Chat_Click'
    },
    grandround_left_section: {
      category: 'left_section_grandround',
      key1: 'Profile_Clicked',
      key2: 'Refer_&_Earn_clicked',
      key3: 'Connections_Clicked'
    },
    main_banner: {
      key1: 'BANNER_CLICK'
    },
    feed_card_screen: {
      category: 'feed card',
      key1: 'FEED_CARD_REPORT_CLICKED',
      key2: 'FEED_CARD_COPY_LINKED_CLICKED',
      key3: 'FEED_CARD_EDIT_FEED_CLICKED',
      key4: 'FEED_CARD_DELETE_FEED_CLICKED',
      key5: 'FEED_CARD_FEED_MEDIA_CLICKED',
      key6: 'FEED_CARD_FEED_TEXT_CLICKED',
      key7: 'FEED_CARD_LIKE_LIST_CLICKED',
      key8: 'FEED_CARD_LIKE_BUTTON_CLICKED',
      key9: 'FEED_CARD_BOOKMARK_BUTTON_CLICKED',
      key10: 'FEED_CARD_SHARE_BUTTON_CLICKED',
      key11: 'Poll_option_click'
    },
    comment_card: {
      category: 'comment_card',
      key1: 'EDIT_COMMENT_CLICKED',
      key2: 'DELETE_COMMENT_CLICKED',
      key3: 'COMMENT_USER_CLICKED',
      key4: 'COMMENT_REPLY_CLICKED',
      key5: 'COMMENT_LIKE_CLICKED',
      key6: 'COMMENT_UPVOTE_CLICKED',
      key7: 'COMMENT_LIKE_LIST_CLICKED',
      key8: 'COMMENT_UPVOTE_LIST_CLICKED',
      key9: 'COMMENT_UPDATE_BUTTON_CLICKED',
      key10: 'COMMENT_LOAD_MORE_CLICKED'
    },
    reply_comment_card: {
      category: 'reply_comment_card',
      key1: 'EDIT_REPLY_COMMENT_CLICKED',
      key2: 'DELETE_REPLY_COMMENT_CLICKED',
      key3: 'COMMENT_REPLY_USER_CLICKED',
      key4: 'COMMENT_REPLY_REPLY_CLICKED',
      key5: 'COMMENT_REPLY_LIKE_CLICKED',
      key6: 'COMMENT_REPLY_UPVOTE_CLICKED',
      key7: 'COMMENT_REPLY_LIKE_LIST_CLICKED',
      key8: 'COMMENT_REPLY_UPVOTE_LIST_CLICKED',
      key9: 'COMMENT_REPLY_UPDATE_BUTTON_CLICKED',
      key10: 'COMMENT_REPLY_LOAD_MORE_CLICKED'
    },
    verification_screen: {
      category: 'verification screen',
      key1: 'VERIFY_NOW_CLICKED',
      key2: 'VERIFY_INAPP_CLICK'
    },
    generic_card: {
      category: 'generic_card',
      key1: 'GENERIC_BUTTON_CLICKED',
      key2: 'PROFILE_INCOMPLETE_CLICKED'
    },
    association_verification_screen: {
      category: 'Association Verification',
      key1: 'Association_Verification_upload_BUTTON_CLICKED',
      key2: 'Association_Verification_Next_Button_Clicked',
      key3: 'Association_Verification_close_Button_Clicked'
    },
    map_association_screen: {
      category: 'verify country and association screen',
      key1: 'Add_Association_Button_Clicked'
    },
    verification_country_and_association_screen: {
      category: 'Association Verification',
      key1: 'close_button_clicked',
      key2: 'Next_Button_Clicked'
    },
    verification_underprocess_screen: {
      category: 'verification under process screen',
      key1: 'Verification_Button_Clicked'
    },
    clinical_resource_card: {
      category: 'Clinical Resource',
      key1: 'REFERENCE_LIKE_CLICK',
      key2: 'clinical_detail_clicked',
      key3: 'REFERENCE_BOOKMARK_CLICK',
      key4: 'REFERENCE_SHARE_CLICK',
      key5: 'refrerence_Follow_button_clicked',
      key6: 'refrerence_Like_list_clicked',
    },
    webinar_card: {
      key1: 'webinar_Like_Button_Clicked',
      key2: 'webinar_Bookmark_button_Clicked',
      key3: 'webinar_Share_Button_Clicked',
      key4: 'webinar_Detail_Page_Clicked',
      key5: 'webinar_Speciality_List_Clicked',
    },
    webinar_list: {
      category: 'webinar list',
      key1: 'webinar_All_Button_Clicked',
      key2: 'webinar_live button_Clicked',
      key3: 'webinar_view_All_Clicked',
      key4: 'Webinar_Home_Visit'
    },
    survey_card: {
      key1: 'survey_Start_button_clicked',
      key2: 'survey_Complete_Survey_button_clicked',
      key3: 'survey_Share_button_clicked',
      key4: 'survey_T&C_clicked',
      key5: 'survey_details_page_clicked'
    },
    survey_list: {
      category: 'Survey List',
      key1: 'Survey_List_clicked',
      key2: 'Saved_List_clicked',
      key3: 'Completed_List_clicked',
      key4: 'Survey_Dashboard_Visit'
    },
    cme_card: {
      key1: 'CME_BOOKMARK_CLICK',
      key2: 'CME_Share_click',
      key3: 'ATTEMPTED_CME',
      key4: 'cme_detail_clicked',
      key5: 'REATTEMPT_CME_CLICK',
      key6: 'Cme_View_Certificate_button_Clicked',
    },
    cme_list: {
      category: 'CME_DASHBOARD',
      key1: 'cme_vault_clicked',
      key2: 'Cme_Apply_Filter_clicked',
      key3: 'CMe_Close_filter_clicked',
      key4: 'CME_Clear_Filter_Clicked',
      key5: 'CME_ADD_COURSE_POINT_CLICK',
      key6: 'CME_Filter_Click',
      key7: 'CME_Dashboard_Visit'
    },
    cme_list_vault: {
      category: 'My Vault',
      key1: 'CME_ADD_COURSE_POINT_CLICK',
      key2: 'CME_Tab_Docquity_Click',
      key3: 'CME_Tab_Offline_Click',
      key4: 'Cme_view_certificate_clicked',
      key5: 'CME_CERTIFICATE_SHARE_CLICK',
      key6: 'back_button_clicked'
    },
    add_skp_points: {
      category: 'Add SKP Points',
      key1: 'Cme_close_skp_point_clicked',
      key2: 'CME_Add_Point_Upload_Certificate_clicked',
      key3: 'CME_ADD_VAULT_ITEM_SUBMIT_CLICK'
    },
    view_skp_points: {
      category: 'View Skp Points',
      key1: 'Cme_view_certificate_clicked',
      key2: 'CME_CERTIFICATE_DOWNLOAD_CLICK',
      key3: 'CME_CERTIFICATE_SHARE_CLICK',
      key4: 'close_button_clicked',
    },
    drug_sample_card: {
      key1: 'Drug_Order_Click',
      key2: 'drug_detail_clicked'
    },
    drug_sample_list: {
      category: 'DRUG_LIST',
      key1: 'Available_button_clicked',
      key2: 'Ordered_button_clicked',
      key3: 'clear_all_filter_clicked',
      key4: 'filter_selected'
    },
    doctalk_card: {
      key1: 'DOCTALK_LIKE_CLICK',
      key2: 'DOCTALK_BOOKMARK_CLICK',
      key3: 'DOCTALK_SHARE_CLICK',
      key4: 'Doctalk_Likes_clicked',
      key5: 'doctalk_detail_clicked'
    },
    doctalk_list: {
      category: 'Doctalks_Home',
      key1: 'Doctalk_View_All_clicked',
      key2: 'Doctalk_speaker_clicked',
      key3: 'Doctalk_speciality_clicked',
      key4: 'Doctalks_Home_Visit'
    },
    event_card: {
      key1: 'Event_details_clicked',
      key2: 'Event_Share_clicked',
      key3: 'Event_Mark_Going_Click',
      key4: 'Event_details_clicked'
    },
    event_list: {
      category: 'event list',
      key1: 'Event_tab_Upcoming_clicked',
      key2: 'Event_tab_Attending_clicked',
      key3: 'Event_tab_Completed_clicked',
      key4: 'Event_vault_clicked',
      key5: 'EVENT_CALENDAR_CLICK',
      key6: 'Event_vault_clicked',
      key7: 'Event_tab_all_button_clicked',
      key8: 'Event_tab_attended_button_clicked',
      key9: 'Event_tab_missed_button_clicked',
      key10: 'Event_Home_Visit'
    },
    event_detail: {
      category: 'event detail',
      key1: 'Event_Back_buton_clicked',
      key2: 'Event_Share_clicked',
      key3: 'Event_Mark_going_clicked',
      key4: 'Mark Attendance clicked',
      key5: 'Event_Session_clicked',
      key6: 'Event_About_clicked',
      key7: 'Event_Discussion_clicked',
      key8: 'Event_Images_clicked',
      key9: 'Event_Videos_clicked',
      key10: 'Event_PDF_clicked',
      key11: 'Event_Speaker_clicked',
      key12: 'Event_View_All_clicked',
      key13: 'EVENT_CALENDAR_CLICK',
      key14: 'Event_media_clicked',
      key15: 'EVENT_DETAIL_VISIT'
    },
    event_session_detail: {
      category: 'SESSION_DETAIL',
      key1: 'Event_Session_Share_clicked',
      key2: 'Event_Session_About_clicked',
      key3: 'Event_Session_Discussion_clicked',
      key4: 'Event_Session_Speaker_clicked',
      key5: 'Event_Session_View_All_speaker_clicked',
      key6: 'Event_Session_calendar_clicked',
      key7: 'Event_Session_media_clicked',
      key8: 'Event_Sessiondetail_Visit'
    },
    event_speaker_card: {
      key1: 'Event_speaker_clicked'
    },
    connection_card: {
      key1: 'REQUEST_ACCECPT_CLICK',
      key2: 'REQUEST_REJECT_CLICK',
      key3: 'Request_Withdraw_clicked',
      key4: 'View_Connection_Clicked',
      key5: 'Connection_Remove_clicked',
      key6: 'Connection_Chat_Click ',
      key7: 'Connection_creategroup_Chat_clicked',
      key8: 'REQUEST_SENT_CLICK'
    },
    connection_list: {
      category: 'connection list',
      key1: 'Connection_Tab_Suggestion_Click',
      key2: 'CONNECTION_TAB_CLICK',
      key3: 'Connection_Tab_Request_Click',
      key4: 'Connection_Tab_Sent_Click',
      key5: 'Connection_Tab_Communities_Click'
    },
    cme_details: {
      category: 'cme detail',
      key1: 'CME_BOOKMARK_CLICK',
      key2: 'CME_Share_click',
      key3: 'ATTEMPTED_CME',
      key4: 'Re-Attempted_CME ',
      key5: 'CME_view_certificate_Click',
      key6: 'CME_Media_clicked',
      key7: 'CME_back_button_clicked',
      key8: 'cme_subscribe_clicked',
      key9: 'cme_terms_clicked',
      key10: 'CME_Course_Details_Visit'
    },
    product_subscribe: {
      category: 'product subscribe',
      key1: 'Subscribe_T&C_clicked',
      key2: 'Subscribe_later_clicked',
      key3: 'Subscribe_Follow_clicked'
    },
    doctalk_details: {
      category: 'doctalk detail',
      key1: 'Doctalk_Like_clicked',
      key2: 'Doctalk_Likes_list_clicked',
      key3: 'Doctalk_Bookmark_clicked',
      key4: 'DOCTALK_SHARE_CLICK',
      key5: 'Doctalk_Play_clicked',
      key7: 'Doctalk_See_more_cicked',
      key8: 'Doctalk_Speaker_clicked',
      key9: 'Doctalk_More_Speaker_clicked',
      key10: 'DOCTALK_DETAIL_VISIT'
    },
    drug_sample_detail: {
      category: 'DRUG_DETAIL',
      key1: 'Drug_Order_Click',
      key2: 'DRUG_DETAIL_VISIT',
      key3: 'DRUG_DETAIL',
      key4: 'PDF_CLICK',
      key5: 'DRUG_IMAGE_CLICK',
      key6: 'DRUG_VIDEO_CLICK',
      key7: 'DRUG_COMPONENT_READ_MORE_CLICK',
      key8: 'DRUG_COMPONENT_VISIT',
      key9: 'DRUG_VIDEO_VISIT',
      key10: 'PDF_VISIT',
    },
    drug_order_form: {
      key1: 'ORDER_SAMPLE_NOW_CLICK'
    },
    clinical_resource_filter: {
      category: 'Reference_List',
      key1: 'Filter_Specialty_Click',
      key2: 'Refrence_Clear_filter_clicked',
      key3: 'Filter_Category_Clicked',
      key4: 'Reference_List_Visit'
    },
    clinical_resource_detail: {
      category: 'Journal_Detail',
      key1: 'reference_like_click',
      key2: 'reference_bookmark_click',
      key3: 'reference_share_click',
      key4: 'reference_Follow_clicked',
      key5: 'reference_T&C_clicked',
      key6: 'Journal_Details_Visit'
    },
    profile_detail_left: {
      category: 'Profile_Visit',
      key1: 'Share_Profile_clicked',
      key2: 'Edit_Profile_Click ',
      key3: 'Profile_Connect_clicked',
      key4: 'Profile_Connect_Withdraw_clicked',
      key5: 'Profile_Chat_clicked',
      key6: 'Profile_setting_clicked',
      key7: 'profile_pic_clicked',
      key8: 'Profile_connection_Clicked',
      key9: 'Profile_views_clicked',
      key10: 'Profile_missing_data_clicked',
      key11: 'Profile_you_may_like_clicked',
      key12: 'Profile_Visit'
    },
    profile_detail_timeline_education: {
      key1: 'PROFILE_ADD_EDUCATION_CLICKED',
      key2: 'PROFILE_EDIT_EDUCATION_CLICKED',
      key3: 'PROFILE_MORE_EDUCATION_CLICKED'
    },
    profile_detail_timeline_association: {
      key1: 'PROFILE_ADD_ASSOCIATION_CLICK',
      key2: 'Profile_more_association_Clicked'
    },
    profile_detail_timeline_view: {
      category: 'PROFILE_VISIT',
      key1: 'Profile_Edit_Summary_Clicked',
      key2: 'Profile_Reward_Clicked',
      key3: 'PROFILE_VISIT'
    },
    profile_detail_timeline_experience: {
      key1: 'PROFILE_EDIT_EXPERIENCE_CLICKED',
      key2: 'PROFILE_ADD_EXPERIENCE_CLICKED',
      key3: 'PROFILE_MORE_EXPERIENCE_CLICKED',
    },
    profile_detail_timeline_publication: {
      key1: 'PROFILE_EDIT_PUBLICATION_CLICKED',
      key2: 'PROFILE_ADD_PUBLICATION_CLICKED',
      key3: 'PROFILE_MORE_PUBLICATION_CLICKED',
      key4: 'PROFILE_PUBLICATION_CLICKED'
    },
    profile_detail_timeline_certification: {
      key1: 'PROFILE_edit_certification_clicked',
      key2: 'PROFILE_add_certification_clicked',
      key3: 'PROFILE_MORE_certification_clicked',
    },
    profile_detail_timeline_interest: {
      key1: 'PROFILE_add_INTEREST_clicked',
      key2: 'PROFILE_EDIT_INTEREST_clicked',
      key3: 'PROFILE_MORE_INTEREST_clicked'
    },
    create_posting: {
      category: 'Create Post',
      key1: 'Post_close_button_clicked',
      key2: 'Post_Gallery_clicked',
      key3: 'Post_TagPeople_Click ',
      key4: 'Post_Poll_clicked',
      key5: 'Post_Document_clicked',
      key6: 'Post_Poll_removed_clicked',
      key7: 'Post_Poll_Add_option_clicked',
      key8: 'Post_Drafts_clicked',
      key9: 'POST_ANONYMOUS_CLICK',
      key10: 'POST_NEXT_CLICK'
    },
    create_post_type: {
      category: 'Create post type',
      key1: 'POST_NEXT_CLICK',
      key2: 'Post_casetype_ clicked',
      key3: 'Post_Speciality_clicked',
      key4: 'Post_Selected_data_removed'
    },
    create_post_association: {
      category: 'create post association',
      key1: 'post_clicked',
      key2: 'post_association_clicked',
    },
    create_post_community: {
      category: 'create post community',
      key1: 'post_clicked',
      key2: 'post_community_clicked',
    },
    create_post_drafts: {
      category: 'create post draft',
      key1: 'Post_Delete_draft_clicked',
      key2: 'Edit_draft_clicked'
    },
    create_post_save_modal: {
      category: 'create post save modal',
      key1: 'POSTING_SAVE_TO_DRAFT_CLICK',
      key2: 'POSTING_DISCARD_CLICK',
      key3: 'POSTING_Continue_EDIT_CLICK'
    },
    profile_edit_left: {
      key1: 'PROFILE_EDIT_Basic_clicked',
      key2: 'PROFILE_EDIT_Experience_clicked',
      key3: 'PROFILE_EDIT_Education_clicked',
      key4: 'PROFILE_EDIT_Publication_clicked',
      key5: 'PROFILE_EDIT_Certifications_clicked',
      key6: 'PROFILE_EDIT_Interests_clicked',
      key7: 'PROFILE_EDIT_back_clicked'
    },
    profile_edit_experience: {
      category: 'profile edit experience',
      key1: 'PROFILE_EDIT_add_experience_clicked',
      key2: 'PROFILE_EDIT_delete_experience_clicked',
      key3: 'PROFILE_EDIT_submit_experience_clicked'
    },
    profile_edit_education: {
      category: 'profile edit education',
      key1: 'PROFILE_EDIT_add_education_clicked',
      key2: 'PROFILE_EDIT_DELETE_education_clicked',
      key3: 'PROFILE_EDIT_education_submit_clicked',
      key4: 'PROFILE_EDIT_education_update_clicked'
    },
    profile_edit_publication: {
      category: 'profile edit publication',
      key1: 'PROFILE_EDIT_add_PUBLICATION_clicked',
      key2: 'PROFILE_EDIT_DELETE_PUBLICATION_clicked',
      key3: 'PROFILE_EDIT_PUBLICATION_submit_clicked',
      key4: 'PROFILE_EDIT_PUBLICATION_UPDATE_clicked'
    },
    profile_edit_certificate: {
      category: 'profile edit certificate',
      key1: 'PROFILE_EDIT_add_CERTIFICATE_clicked',
      key2: 'PROFILE_EDIT_DELETE_CERTIFICATE_clicked',
      key3: 'PROFILE_EDIT_CERTIFICATE_submit_clicked',
      key4: 'PROFILE_EDIT_CERTIFICATE_UPDATE_clicked'
    },
    profile_edit_interest: {
      key1: 'PROFILE_EDIT_speciality_selected',
      key2: 'PROFILE_EDIT_speciality_removed',
      key3: 'PROFILE_EDIT_submit_speciality_clicked',
    },
    feed_detail: {
      category: 'feed detail',
      key1: 'Feed_Detail_Like_button_clicked',
      key2: 'Feed_Detail_Bookmark_button_clicked',
      key3: 'Feed_Detail_Share_button_clicked',
      key4: 'Feed_Detail_Back_button_clicked',
      key5: 'Feed_Detail_Like_list_clicked',
      key6: 'Poll_option_click',
      key7: 'FEED_DETAIL_VISIT',
      key8: 'Audio_Time_Spent'
    },
    cme_question: {
      category: 'cme question',
      key1: 'PREVIEW_AND_SUBMIT_CLICKED',
      key2: 'CME_DETAILS_CLICKED',
      key3: 'BACK_BUTTON_CLICKED',
      key4: 'PREVIEW_SUBMIT_CLICKED',
      key5: 'NEXT_QUESTION_CLICKED',
      key6: 'SUBMIT_BUTTON_CLICKED',
      key7: 'VIEW_RESULT_CLICKED',
      key8: 'EXIT_QUESTION_BUTTON_CLICKED',
      key9: 'CONTINUE_BUTTON_CLICKED',
      key10: 'CLOSE_QUESTION_CLICKED',
      key11: 'EDIT_QUESTION_CLICKED',
      key12: 'LAST_QUESTION_SUBMIT_CLICK',
      key13: 'CME_Question_Visit',
      key14: 'CME_QUESTION_REVIEW_LIST_VISIT'
    },
    report_screen: {
      category: 'Report Screen',
      key1: 'REPORT_SUBMIT_CLICKED'
    },
    comment_box: {
      key1: 'COMMENT_SUBMITTED'
    },
    repy_comment_box: {
      key1: 'COMMENT_REPLY_SUBMITTED'
    },
    create_post_tag: {
      category: 'post tag people',
      key1: 'POSTTAG_BACK_CLICKED',
      key2: 'POSTTAG_DONE_CLICKED',
      key3: 'POST_CONNEC&TAG_CLICK',
      key4: 'POST_SELECTCONN_CLICK',
      key5: 'POSTTAG_USER_REMOVED'
    },
    profile_edit_basic: {
      category: 'profile edit basic',
      key1: 'PROFILE_UPDATE_LOCATION_CLICKED',
      key2: 'PROFILE_UPDATE_LOCATION_CLOSED',
      key3: 'PROFILE_LOCATION_SUBMIT',
      key4: 'PROFILE_BASIC_SUBMIT'
    },
    profile_setting_language: {
      category: 'profile language setting',
      key1: 'Profile_Language_English_Clicked',
      key2: 'Profile_Language_Bahasa_Clicked',
      key3: 'Profile_Language_Submit',
      key4: 'Profile_Language_Viatnameese_Clicked',
      key5: 'Profile_Language_Korean_Clicked',
      key6: 'Profile_Language_Mandarin_Clicked',
    },
    profile_setting_notification: {
      category: 'push notification',
      key1: 'Push_Notification_Changed'
    },
    global_search: {
      category: 'global search',
      key1: 'POPULAR_SEARCH_CLICK',
      key2: 'Search_Filter_Click',
      key3: 'SEARCH_RESULT_CLICK',
      key4: 'Search_Property_Click',
      key5: 'TAP_ON_SEARCH_CLICK'
    },
    channel_detail: {
      category: 'channel detail',
      key1: 'CHANNEL_SUBSCRIBE_CLICK',
      key2: 'CHANNEL_DETAIL_VISIT'
    },
    feedback_screen: {
      category: 'send feedback',
      key1: 'SUBMIT_FEEDBACK_CLICKED'
    },
    country_screen: {
      key1: 'Country_Changed'
    },
    chat_screen: {
      category: 'Chat screen',
      key1: 'Chat_Detail_Visit',
      key2: 'Start_Messaging_Click',
      key3: 'Create_Group_Click',
      key4: 'Start_Messaging_Click',
      key5: 'Chat_Float_Click',
      key6: 'New_Group_Visit',
      key7: 'Chat_Detail_Info_Click',
      key8: 'Create_New_chat_Clicked',
      key9: 'Chat_User_Profile_Clicked'
    },
    create_chat_screen: {
      category: 'create new chat',
      key1: 'CHAT_CREATE_USER_SELECT',
      key2: 'CHAT_CREATEDIALOG_CLICKED'
    },
    create_group_chat_screen: {
      category: 'create group chat',
      key1: 'CHAT_CREATEGROUP_USER_SELECT'
    },
    chat_user_info: {
      category: 'chat info',
      key1: 'CHAT_GROUP_NAME_CHANGE_CLICKED',
      key2: 'CHAT_GROUP_IMAGE_CHANGE_CLICKED',
      key3: 'CHAT_ADD_GROUPMEMBER_CLICKED',
      key4: 'CHAT_REPORT_CLICKED',
      key5: 'CHAT_MUTE_NOTIFICATION_CLICKED',
      key6: 'CHAT_EXIT_GROUP_CLICKED',
      key7: 'CHAT_SUSPENDGROUP_CLICKED',
      key8: 'CHAT_USERBLOCKED_CLICKED',
      key9: 'CHAT_USERUNBLOCKED_CLICKED'
    },
    grandround: {
      category: 'GRAND_ROUND',
      key1: 'GRAND_ROUND_VISIT'
    },
    docktalk_speaker_list: {
      category: 'Speaker_List'
    },
    docktalk_speaker_profile: {
      category: 'Speaker_Profile'
    },
    docktalk_details: {
      category: 'Doctalks_Detail',
      key1: 'Doctalk_Detail_Visit'
    },
    channel_list: {
      category: 'CHANNEL_HOME',
      key1: 'CHANNEL_HOME_VISIT'
    },
    pharma_detail: {
      category: 'PHARMA_LIST',
      key1: 'Pharma_List_Visit'
    },
    webinar_detail: {
      category: 'WEBINAR_DETAIL',
      key1: 'Webinar_Detail_Visit',
      key2: 'WEBINAR_CME_CLICK',
      key3: 'WEBINAR_SURVEY_CLICK',
      key4: 'WEBINAR_LOGO_CLICK',
      key5: 'SQS_VIDEO_LOG',
      key6: 'Webinar_action_click',
    },
    drug_list: {
      category: 'DRUG_LIST',
      key1: 'DRUG_LIST_VISIT'
    },
    cme_result: {
      category: 'CME_RESULT',
      key1: 'CME_RESULT_VISIT'
    },
    calendar: {
      category: 'Calender_Screen',
      key1: 'Calendar_Visit'
    },
    poll_popup: {
      category: 'WEBINAR_POLL_POPUP',
      key1: 'WEBINAR_POLL_VISIT',
      key2: 'WEBINAR_POLL_OPTION_CLICK',
      key3: 'WEBINAR_POLL_CLOSE_CLICK'
    },
    webinar_agora_back_screen: {
      category: 'WEBINAR_BACK_LISTINGPG',
      key1: 'WEBINAR_EXIT_BACK_CLICK',
      key2: 'WEBINAR_EXIT_CONFIRM_VISIT',
      key3: 'WEBINAR_EXIT_YES_CLICK',
      key4: 'WEBINAR_EXIT_NO_CLICK',
      key5: 'WEBINAR_EXIT_CLOSE_CLICK'
    },
    webinar_NPS_screen_popup: {
      category: 'WEBINAR_NPS_POPUP',
      key1: 'WEBINAR_NPS_VISIT',
      key2: 'WEBINAR_NPS_DONE_CLICK',
      key3: 'WEBINAR_NPS_CLOSE_CLICK'
    },
    tnc_popup: {
      category: ' TNC_POPUP',
      key1: 'WEBINAR_TNC_VISIT',
      key2: 'WEBINAR_TNC_CONTINUE_CLICK',
      key3: 'WEBINAR_TNC_AGREE_CLICK',
      key4: 'WEBINAR_TNC_CLOSE_CLICK'
    },
    events_list: {
      category: 'EVENT_HOME',
      key1: 'Event_Home_Visit',
      key2: 'Mark_Going_Click',
      key3: 'View_Schedule_Click',
      key4: 'Event_View_Details_Click',
      key5: 'Bookmark_Click',
      key6: 'Event_Share_Click'
    },
    events_detail: {
      category: 'EVENT_DETAIL',
      key1: 'Event_Detail_Visit',
      key2: 'Mark_Going_Click',
      key3: 'View_Schedule_Click',
      key4: 'Bookmark_Click',
      key5: 'Event_Add_to_calendar_click',
      key6: 'Event_About_Click',
      key7: 'Event_FAQs_Click',
      key8: 'Media_Click',
      key9: 'View_Certificate_Click',
      key10: 'Event_Share_Click',
      key11: 'Event_Comment_Post',
      key12: 'Event_Survey_Click',
      key13: 'Sponsor_Click',
      key14: 'Virtual_Booth_Click',
      key15: 'SKP_Click',
      key16: 'Event_Speakers_Click'
    },
    event_schedule: {
      category: 'EVENT_SCHEDULE',
      key1: 'Event_Schedule_Visit',
      key2: 'Session_Details_Click',
      key3: 'Webinar_View_Details_Click',
      key4: 'Join_Webinar_Click',
      key5: 'CME_Start_Click',
      key6: 'CME_ReAttempt-Click',
      key7: 'CME_View_Details_Click'
    },
    session_detail: {
      category: 'SESSION_DETAIL',
      key1: 'Event_Detail_Visit',
      key2: 'Mark_Going_Click',
      key3: 'View_Schedule_Click',
      key4: 'Bookmark_Click',
      key5: 'Event_Add_to_calendar_click',
      key6: 'Event_About_Click',
      key7: 'Event_FAQs_Click',
      key8: 'Media_Click',
      key9: 'View_Certificate_Click',
      key10: 'Event_Share_Click',
      key11: 'Event_Comment_Post',
      key12: 'Event_Survey_Click',
      key13: 'Sponsor_Click',
      key14: 'Virtual_Booth_Click',
      key15: 'SKP_Click',
      key16: 'Event_Speakers_Click'
    },

    session_schedule: {
      category: 'SESSION_SCHEDULE',
      key1: 'Session_Schedule_Visit',
      key2: 'Join_Webinar_Click',
      key3: 'CME_View_Details_Click'
    },
    inviteCode: {
      category: 'PROFILE_INVITE_CODE',
      key1: 'Profile_Invite_Code_Click'
    },
    dc_consent: {
      category: 'DC_CONSENT_FORM',
      key1: 'DC_START_TELE_CLICK',
      key2: 'DC_CONSENT_FORM_VISIT',
      key3: 'SETUP_DC_CLICK',
      key4: 'CONSENT_FORM_CANCEL'
    },
    journal_detail: {
      category: 'JOURNAL_DETAIL',
      key1: 'AUDIO_PLAYTIME'
    }
  };

  docquityEventText = {

  };

  cleaverTapText = {};

  branchText = {};

  public toShowNologinRestrictionPopUp = false;

  public featureOnOffList = {
    "moduleList": {
        "CONNECTION": {
            "name": "CONNECTION",
            "value": false
        },
        "CALENDAR": {
            "name": "CALENDAR",
            "value": false
        },
        "CME": {
            "name": "CME",
            "value": false
        },
        "NOTIFICATION": {
            "name": "NOTIFICATION",
            "value": false
        },
        "SURVEY": {
            "name": "SURVEY",
            "value": false
        },
        "ONLINE_EVENT": {
            "name": "ONLINE_EVENT",
            "value": false
        },
        "OFFLINE_EVENT": {
            "name": "OFFLINE_EVENT",
            "value": false
        },
        "CHAT": {
            "name": "CHAT",
            "value": false
        },
        "GAME": {
            "name": "GAME",
            "value": false
        },
        "DRUG_SAMPLE": {
            "name": "DRUG_SAMPLE",
            "value": false
        },
        "MEETING": {
            "name": "MEETING",
            "value": false
        },
        "EDITOR_CHOICE": {
            "name": "EDITOR_CHOICE",
            "value": false
        },
        "REFER_EARN": {
            "name": "REFER_EARN",
            "value": false
        },
        "POST": {
            "name": "POST",
            "value": false
        },
        "PHARMA": {
            "name": "PHARMA",
            "value": false
        },
        "BOOKMARK_LIST": {
          "name": "BOOKMARK_LIST",
          "value": false
        }
    },
    "featureList": {
        "connections": {
            "name": "connection",
            "value": false
        },
        "calendar": {
            "name": "calendar",
            "value": false
        },
        "cme": {
            "name": "cme",
            "value": false
        },
        "myVault": {
            "name": "myVault",
            "value": false
        },
        "repost": {
            "name": "repost",
            "value": false
        },
        "share": {
            "name": "share",
            "value": false
        },
        "sendFeedback": {
            "name": "sendFeedback",
            "value": false
        },
        "poll": {
            "name": "poll",
            "value": false
        },
        "chat": {
            "name": "chat",
            "value": false
        },
        "follow": {
            "name": "follow",
            "value": false
        },
        "upvote": {
            "name": "upvote",
            "value": false
        },
        "connectionButtonOnCard": {
            "name": "connectionButtonOnCard",
            "value": false
        },
        "chatButtonOnCard": {
            "name": "chatButtonOnCard",
            "value": false
        },
        "post": {
            "name": "post",
            "value": false
        },
        "bookmark": {
            "name": "bookmark",
            "value": false
        },
        "connectionCountOnGrProfile": {
            "name": "connectionCountOnGrProfile",
            "value": false
        },
        "userStory": {
            "name": "userStory",
            "value": false
        },
        "insightCollector": {
            "name": "insightCollector",
            "value": false
        },
        "clinicalCases": {
            "name": "clinicalCases",
            "value": false
        },
        "bookmarkList": {
            "name": "bookmarkList",
            "value": false
        },
        "report": {
            "name": "report",
            "value": false
        },
        "deleteProfile": {
            "name": "deleteProfile",
            "value": false
        },
        "notificationChangeFromSettings": {
            "name": "notificationChangeFromSettings",
            "value": false
        },
        "pvs": {
            "name": "pvs",
            "value": false
        },
        "shareProfile": {
            "name": "shareProfile",
            "value": false
        },
        "youMayAlsoKnowInProfile": {
            "name": "youMayAlsoKnowInProfile",
            "value": false
        },
        "connectionSuggestionOnProfile": {
            "name": "connectionSuggestionOnProfile",
            "value": false
        },
        "healthCardDownloadAndShare": {
            "name": "healthCardDownloadAndShare",
            "value": false
        },
        "drugSample": {
            "name": "drugSample",
            "value": false
        },
        "editorChoice": {
            "name": "editorChoice",
            "value": false
        },
        "inviteCode": {
          "name": "inviteCode",
          "value": false
      }
    }
  };

  /**
   * Used to hold the user login flow flag.
   */
  public userLoginFlow=0;



  constructor() {
    this.userLoginFlow = (localStorage.getItem('fromOpenAccess'))? 1: 0
  }

}
