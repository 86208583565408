<div [ngClass]="{'full-version': chatView === chatViewConstant.FULL,
  'mini-version width-350': chatView === chatViewConstant.MINI,
  'height-56': chatView === chatViewConstant.MINI && isChatListExpanded
}">
  <div class="chat-list-panel width-350" [hidden]="isChatListHide && chatView === chatViewConstant.MINI">
    <app-chat-list-panel [isChatListOverLapped]="isChatListOverLapped" [ngClass]="{'pointer-events-none': disableChat}" [chatView]="chatView" [chat]="chat"
      [isMeTopic]="chat.client?._myUID" [selectedUserIndex]="selectedUserIndex" [chatMode]="chatMode"
      [chatList]="chatList" [totalUnreadCount]="totalUnreadCount" [floatingOptions]="floatingOptions"
      [authToken]="chat?.authToken" [isChatListExpanded]="isChatListExpanded" [isLoading]="chat.chatListLoading"
      [samvaadLanguageText]="samvaadLanguageText" (getChatDetail)="checkForTopic($event)" (onChatExpand)="chatExpand()"
      (onSearchClicked)="searchClicked()"
      (onCloseChatList)="closeChatList()" (emitFloatingAction)="doFloatingAction($event)" [hostKey]="samvaadConfig?.host" [apiKey]="samvaadConfig?.apiKey"
      (onChatListToggle)="onChatListToggle($event)" (onVisit)="emitOnChatListVisit($event)" (onLeave)="emitOnChatListLeave($event)"
      (onNewJourney)="$event === chatType.P2P ? doFloatingAction(1) : doFloatingAction(2)" (onChatListOpen)="emitOnChatListOpen($event)"
      (onAvatarClick)="!$event.type ? redirectToProfile($event.topicId) : doMoreOptionAction({ action: moreOptions.GROUP_DETAIL, type: chatType.GROUP }, $event.topicId)">
    </app-chat-list-panel>
  </div>
  <div class="w-100 chat-detail-panel position-relative"
    [ngClass]="{'min-width-0' : chatView === chatViewConstant.FULL}">
    <ng-container *ngTemplateOutlet="chatDetailLoading"></ng-container>
    <app-chat-detail-panel *ngIf="isChatDetail" #chatDetail [ngClass]="{'pointer-events-none': disableChat}"
      [hidden]="chatView === chatViewConstant.MINI ? false : (isChatGroupCreation || isSearchUser)" [chat]="chat"
      [chatView]="chatView" [chatDetail]="chatList[selectedUserIndex]" [selectedUserIndex]="selectedUserIndex"
      [hideDetailWindow]="isChatDetailWindowHide" [authToken]="chat?.authToken" [hostKey]="samvaadConfig?.host"
      [isChatDetailExpanded]="isChatDetailExpanded" [isMeTopic]="chat.client?._myUID" [apiKey]="samvaadConfig?.apiKey"
      [isChatDetailMembers]="isChatDetailMembers" [isMobileVersion]="isMobileVersion"
      [isLoading]="!isChatDetailMembers || (!chat.chatListLoading && chat.chatDetailLoading) || chat.internalChatDetailLoading || (!chatList[selectedUserIndex]?.msg?.length && chatList[selectedUserIndex]?.seq)"
      [samvaadLanguageText]="samvaadLanguageText" (openMsgDetail)="openMsgDetail($event)"
      (onChatHide)="onChatDetailClose($event, chatList[selectedUserIndex].topic)" (getMoreChat)="getMoreChat($event)"
      (onChatDetailSwitched)="chatDetailSwitched($event)"
      (emitValueChange)="sendKeyPress($event)" (emitSendMessage)="sendMessage($event)"
      (onChatDetailToggle)="onChatDetailToggle($event)" (onVisit)="emitOnChatDetailVisit($event)" (onLeave)="emitOnChatDetailLeave($event)"
      (emitAction)="doMoreOptionAction($event, chatList[selectedUserIndex].topic)" (onNewJourney)="doFloatingAction(chatType.GROUP)"
      (emitFileUploadSubmit)="sendImageMessage($event, chatList[selectedUserIndex].topic)"
      (emitRetryMessage)="retryMsg($event)" (emitDeleteMessage)="deleteMsg($event)"
      (onRetryFetchMessages)="retryFetchingMessage($event)" (onRetryMembers)="retryMembers($event)" (onAttachmentClick)="emitAttachmentClick($event)"
      (onAvatarClick)="!$event ? redirectToProfile(chatList[selectedUserIndex].topic) : doMoreOptionAction({ action: moreOptions.GROUP_DETAIL, type: chatType.GROUP }, chatList[selectedUserIndex].topic)">
    </app-chat-detail-panel>
    <app-chat-group-creation [ngClass]="{'chat-list-group': chatView === chatViewConstant.MINI}"
      *ngIf="isChatGroupCreation" [chatGroupLoading]="chatGroupLoading" [chatView]="chatView"
      (emitGroupFormSubmit)="createNewGroup($event)" [groupMembers]="groupDetail?.members || groupMembers"
      (emitSearchInputClick)="onSearchInputClick()"
      [authToken]="chat?.authToken" [samvaadLanguageText]="samvaadLanguageText" (onMaxLimitEvent)="onMaxLimitEvent($event)"
      (emitGroupEditFormSubmit)="updateGroup($event)" [isViewMode]="groupViewMode" [groupDetail]="groupDetail" [apiKey]="samvaadConfig?.apiKey"
      (emitUploadGroupPic)="uploadAvatar($event)" (emitRemoveUser)="removeMemberFromGroup($event)" [hostKey]="samvaadConfig?.host"
      (emitGroupLoading)="toggleChatGroupLoading($event)" (onAvatarClick)="redirectToProfile($event.customId, true)"
      (onVisit)="emitOnGroupVisit($event)" (onLeave)="emitOnGroupLeave($event)" (onGoBack)="closeGroup()" (onAddDoctorInGroup)="emitAddDoctorInGroup($event)"
      (onNewMemberVisit)="emitOnNewMemberVisit($event)" (onGroupAddMemberDone)="emitOnGroupAddMemberDone($event)" (onNewMemberLeave)="emitOnNewMemberLeave($event)" >
    </app-chat-group-creation>
    <div class="chat-search-user" *ngIf="isSearchUser">
      <app-search-users [ngClass]="{'chat-list-group': chatView === chatViewConstant.MINI}"
        [isLoading]="searchUserLoading" [isFullLoading]="chat.chatLoading" [isSearchUser]="isSearchUser"
        [offset]="searchUserOffset" [connectionsList]="searchUsersList[0]" [suggestionsList]="searchUsersList[1]"
        [noDataFoundLabel]="samvaadLanguageText?.samvaad?.key21" [chatView]="chatView"
        [samvaadLanguageText]="samvaadLanguageText"
        [selectedLabel]="samvaadLanguageText?.samvaad_create_edit_group?.key6"
        (onSearchKeyword)="searchUsers($event.keyword, $event.key, $event.offset)"
        (onSingleSelect)="startNewChat($event)" (onViewAll)="searchUsers($event.keyword, $event.key, $event.offset)"
        (onSearchInputClick)="onSearchInputClick()"
        (onVisit)="emitOnNewChatVisit($event)" (onLeave)="emitOnNewChatLeave($event)"
        (onHide)="isSearchUser = false">
      </app-search-users>
    </div>
    <div *ngIf="chat.chatLoading" [ngClass]="{'chat-list-group': chatView === chatViewConstant.MINI}">
      <app-chat-progress [isSmallLoading]="false" [isFullScreen]="2" [width]="60" [height]="60"></app-chat-progress>
    </div>
    <div *ngIf="!isChatDetail && !isChatGroupCreation && chatView === chatViewConstant.FULL && !isSearchUser"
      class="no-chat-avail full-chat-detail-panel">
      <app-chat-no-info banner="assets/samvaad-assets/images/icons/no-group-chat.svg"
        [paragraph]="samvaadLanguageText?.samvaad?.key2" [btnText]="samvaadLanguageText?.samvaad?.key3"
        (onBtnClick)="doFloatingAction(1)">
      </app-chat-no-info>
    </div>
  </div>
</div>
<div *ngIf="isChatPreview">
  <app-chat-preview [isChatPreview]="isChatPreview" [title]="samvaadLanguageText?.samvaad.key24"
    [samvaadLanguageText]="samvaadLanguageText" [cancelText]="samvaadLanguageText?.samvaad.key25" [isUploadBtn]="false"
    [imageSrc]="chatPreviewContent.src" [paragraph]="chatPreviewContent.content" (onHide)="isChatPreview = false">
  </app-chat-preview>
</div>

<div *ngIf="isErrorModal">
  <app-chat-error-modal [isErrorModal]="isErrorModal" [heading]="samvaadLanguageText?.samvaad_validation.key4"
    [btnText]="samvaadLanguageText?.samvaad.key25"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg" [paragraph]="errorMsg"
    (onHide)="isErrorModal = false" [samvaadLanguageText]="samvaadLanguageText">
  </app-chat-error-modal>
</div>
<div *ngIf="noAbleToAddMemberList && noAbleToAddMemberList.length > 0">
  <app-chat-error-modal [isErrorModal]="noAbleToAddMemberList && noAbleToAddMemberList.length > 0"
    [heading]="samvaadLanguageText?.samvaad_validation.key4" [btnText]="samvaadLanguageText?.samvaad.key25"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg"
    [heading]="samvaadLanguageText?.samvaadValidation?.key8"
    (onHide)="noAbleToAddMemberList = []" [samvaadLanguageText]="samvaadLanguageText">
  </app-chat-error-modal>
</div>
<ng-template #chatDetailLoading>
  <app-chat-progress *ngIf="!chat.chatListLoading && chat.chatDetailLoading && false" [isSmallLoading]="true"
    [width]="60" [height]="60">
  </app-chat-progress>
</ng-template>
