/**
 * Angular imports.
 */
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Module imports.
 */
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

/**
 * Component imports.
 */
import { DropDownComponent } from './drop-down.component';



@NgModule({
  declarations: [
    DropDownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule
  ],
  exports: [
    DropDownComponent
  ]
})
export class DropDownModule { }
