/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Individual information box for the chat.
 */
@Component({
  selector: 'app-individual-info-box',
  templateUrl: './individual-info-box.component.html',
  styleUrls: ['./individual-info-box.component.scss']
})
export class IndividualInfoBoxComponent {

  /**
   * Basic information for the box.
   * Show/Hide the cursor pointer.
   * Secondary type text for the information box.
   * Profile basic Secondary text for the basic.
   * Count for the profile basic info.
   * End src for the individual box info.
   */
  @Input() info: { name: string; pic: string; badgePic: string };
  @Input() isCursorPointer: boolean;
  @Input() secondaryType: string;
  @Input() profileSecondary: string;
  @Input() count: number;
  @Input() endSrc: string;
  @Input() nameCss = 'paragraph font-weight-bold';
  @Input() secondaryCss = 'secondary-paragraph';
  @Input() defaultSrc = 'assets/samvaad-assets/images/icons/user-profile-placeholder.svg';
  @Input() isEndSrcHoverState = false;
  @Input() endSrcTooltip = '';

  /**
   * Emit the box click event.
   * Emit the end src click event.
   * Emit the avatar click event.
   */
  @Output() onBoxClick: EventEmitter<void> = new EventEmitter();
  @Output() onEndSrcClick: EventEmitter<void> = new EventEmitter();
  @Output() onAvatarClick: EventEmitter<void> = new EventEmitter();

  /**
   * Emit the box click event.
   */
  boxClick(): void {
    this.onBoxClick.emit();
  }

  /**
   * Emit the end src click event.
   */
  endSrcClick(): void {
    this.onEndSrcClick.emit();
  }

  /**
   * Emit the avatar click event.
   */
  emitAvatarClick(): void {
    this.onAvatarClick.emit();
  }
}
