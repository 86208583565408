/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Constant imports.
 */
import { HEADER_CLASS } from '../../../constants/chat.constants';

/**
 * This component is used for the confirmation popup.
 */
@Component({
  selector: 'app-chat-confirm-modal',
  templateUrl: './chat-confirm-modal.component.html',
  styleUrls: ['./chat-confirm-modal.component.scss']
})
export class ChatConfirmModalComponent implements OnInit, OnDestroy {

  /**
   * Show/hide popup
   * Src for the information.
   * Title text for the confirm modal header.
   * Heading text for the confirm modal.
   * Paragraph text for the confirm modal.
   * Cancel button text for the confirm modal.
   * Submit button text for the confirm modal.
   */
  @Input() isConfirmModal: boolean;
  @Input() infoSrc: string;
  @Input() title: string;
  @Input() heading: string;
  @Input() paragraph: string;
  @Input() btnCancel: string;
  @Input() btnText: string;
  @Input() srcWidth: number;

  /**
   * Emit on hide event.
   * Emit the submit event.
   *
   */
  @Output() onHide: EventEmitter<void> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  /**
   * Decrease z-index of header.
   */
  ngOnInit(): void {
    document.body?.classList?.add(HEADER_CLASS);
  }

  /**
   * Emit the Hidden button.
   */
  onHidden(): void {
    this.onHide.emit();
    this.isConfirmModal = false;
  }

  /**
   * Emit the submit button.
   */
  submit(): void {
    this.onSubmit.emit();
  }

  /**
   * Increase z-index of header.
   */
  ngOnDestroy(): void {
    document.body?.classList?.remove(HEADER_CLASS);
  }
}
