/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, HostListener } from "@angular/core";
import { DataStorageService } from "src/app/dataservices/data-storage.service";

/**
 * Modal imports.
 */
import { Chat } from "../modal/chat";
import { ChatList, SamvaadConfig, ChatDetailTimeTopic, GroupCreationSuccessFailure } from "../modal/chat.interface";

/**
 * Service imports.
 */
import { ChatService } from "./chat.service";
import { SamvaadLangService } from "../samvaad-assets/language/samvaad-language.service";

/**
 * Library imports.
 */
import { Drafty, Topic } from "@samvaad/client";
import { ToastrService } from "ngx-toastr";

/**
 * Rxjs imports.
 */
import { Subject, Subscription, of } from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";

/**
 * Constant imports.
 */
import { CHAT_TEXT_TYPE, CHAT_TYPE, CHAT_VIEW, FLOATING_OPTIONS, ACS_STATUS, WHAT_STATUS, ERROR_STATUS, MSG_STATUS, TYPING_DELAY, META_IDENTIFIER, DEFAULT_MESSAGE_REQUEST, DEFAULT_HIGHEST_READ_RECV, IS_ME, IS_ADMIN, CONNECTION_STATUS, IS_CONNECTION_ENABLE, CHAT_ALLOW, IS_SELECTED, IS_PRIMARY, SPECIALITY_ID, API_STATUS, MESSAGE_REPLACE, DEFAULT_IMAGE_MESSAGE_DIMENSIONS, GROUP_DEFACS_AUTH, MORE_OPTIONS, TOASTER, EXTRA_OFFSET_HEIGHT, CUSTOM_MESSAGE_REQUEST, RESET_USER_INDEX, MSG_RECEIVED_DEBOUNCE_TIME, SUB_LEAVE_DELAYED, SECOND_LAST_MSG, CHAT_DETAIL_LOADING_DELAY, RECEIVED_MSG_DELAY, OTHER_TOPIC_DELAY, GROUP_REQUEST_DELAY, CONNECTION, HEADERS, FND_DEFAULT_KEY, MSG_SEND_DELAY, CREATE_GROUP_DELAY, FND_DEFAULT, URl, LAST_CLICK_TIME, ZERO, ONE, GROUP_CREATE_DELAY, DELETED_USER_NAME, SAMVAAD_INDEX_DB, UNSUBSCRIBE_DEBOUNCE_TIME, } from "./constants/chat.constants";
import { CHAT_API } from "./constants/chat-api.constant";
import { SafeResourceUrl } from "@angular/platform-browser";

/**
 * This is used to control the flow of samvaad library imports.
 */
@Component({
  selector: "lib-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Reference for the chat detail for accessing the scroll bottom event.
   */
  @ViewChild("chatDetail", { static: false }) chatDetail!: any;

  /**
   * Get the samvaad basic configuration.
   * Get the sam token for the rest login.
   * Get the auth token for the auth login.
   * Used to show/hide the chat detail expanding min/full version view.
   * Used to show/hide the chat list expanding min/full version view.
   * Used to show/hide the chat list.
   * Used to mange the chat mode ex mini, full, mobile.
   */
  @Input() readonly samvaadConfig: SamvaadConfig;
  @Input() private readonly samToken: string;
  @Input() private readonly authToken: string;
  @Input() public readonly chatView: number;
  @Input() public readonly disableChat: boolean;
  @Input() public isChatDetailExpanded: boolean;
  @Input() public isChatListExpanded: boolean;
  @Input() public isChatListHide: boolean;
  @Input() chatMode = 0;
  @Input() samvaadLanguageText = this.samvaadLangService?.languageText;
  @Input() isInternet: boolean;
  @Input() isMobileVersion: boolean;

  /**
   * Emit the chat expanding event.
   * Emit the reporting user/group with topic id.
   * Emit the user detail for the redirection to profile.
   * Emit the chat list expanding event.
   * Emit the chat list hide event.
   * Emit the chat unread count event.
   */
  @Output() onChatExpand: EventEmitter<void> = new EventEmitter();
  @Output() emitReportUser: EventEmitter<{ id: string; type: number }> =
    new EventEmitter();
  @Output() emitUserDetail: EventEmitter<string> = new EventEmitter();
  @Output() emitChatDetailExpanded: EventEmitter<boolean> = new EventEmitter();
  @Output() emitChatListExpanded: EventEmitter<boolean> = new EventEmitter();
  @Output() onChatListOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() emitChatListHide: EventEmitter<boolean> = new EventEmitter();
  @Output() emitUnreadCount: EventEmitter<number> = new EventEmitter();
  @Output() emitChatClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onChatDetailVisit: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onChatDetailLeave: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onSearchIconClicked: EventEmitter<void> = new EventEmitter();
  @Output() onChatListVisit: EventEmitter<Date> = new EventEmitter();
  @Output() onChatListLeave: EventEmitter<Date> = new EventEmitter();
  @Output() onNewChatVisit: EventEmitter<Date> = new EventEmitter();
  @Output() onNewChatLeave: EventEmitter<Date> = new EventEmitter();
  @Output() onGroupVisit: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onGroupLeave: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onNewMemberVisit: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onGroupAddMemberDone: EventEmitter<string> = new EventEmitter();
  @Output() onChatDetailSwitched: EventEmitter<{date: Date, previousTopic: string, currentTopic: string}> = new EventEmitter();
  @Output() onNewMemberLeave: EventEmitter<ChatDetailTimeTopic> = new EventEmitter();
  @Output() onStartNewChatClick: EventEmitter<void> = new EventEmitter();
  @Output() onStartNewChatClickFromPlus: EventEmitter<void> = new EventEmitter();
  @Output() onCreateNewGroupClick: EventEmitter<void> = new EventEmitter();
  @Output() onCreateGroupDoneClick: EventEmitter<void> = new EventEmitter();
  @Output() onCreateGroupSuccesFailure: EventEmitter<GroupCreationSuccessFailure> = new EventEmitter();
  @Output() onSendClick: EventEmitter<string> = new EventEmitter();
  @Output() onAttachmentClick: EventEmitter<string> = new EventEmitter();
  @Output() onMuteNotificationClick: EventEmitter<string> = new EventEmitter();
  @Output() onUnmuteNotificationClick: EventEmitter<string> = new EventEmitter();
  @Output() onViewProfileClick: EventEmitter<string> = new EventEmitter();
  @Output() onBlockUserClick: EventEmitter<string> = new EventEmitter();
  @Output() onUnblockUserClick: EventEmitter<string> = new EventEmitter();
  @Output() onExitGroupClick: EventEmitter<string> = new EventEmitter();
  @Output() onMemberRemoveFromGroup: EventEmitter<String> = new EventEmitter();
  @Output() onAddDoctorInGroup: EventEmitter<string> = new EventEmitter();
  @Output() onFailedMsg: EventEmitter<void> = new EventEmitter();
  @Output() onChatError: EventEmitter<number> = new EventEmitter();

  /**
   * Floating button id constant.
   * To unsubscribe data.
   * Get the msg subscription after debounce.
   */
  private floatingOptionConstant = FLOATING_OPTIONS;
  private subscriptions: Subscription = new Subscription();
  private msgSubject = new Subject<any | undefined>();
  private msgReceivedSubjectIds: Map<
    string,
    Subject<{ topicName: string; presMsg: any }>
  > = new Map<string, Subject<any>>();
  private topicUnsubscribeSubject = new Subject<any | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  private lastClickTime: number = 0;

  public isChatListOverLapped = false;
  /**
   * How many type of chat available ex: p2p, group.
   * Initiate the chat and store its instance.
   * Floating button options.
   * Chat list for the users.
   * Get the selected chat/group index.
   * Get the chat detail window hide status.
   * Flag to show/hide the search user.
   * Get the search user list.
   * Get the search user offset.
   * Get the chat preview hide/show status.
   * Get the chat preview content.
   * Status for sending the typing event.
   * Status for the first time messaging like go the detail page ask for more chats.
   * Flag to show/hide the group creation.
   * Used to store the members name who is not in samvaad yet.
   * List of particular group members.
   * Information of particular group.
   * Total unread count of chats p2p/group.
   * Show/Hide chat group loading.
   * Show/Hide the error modal.
   * Store the error message to show in modal.
   * Either show the view only or able to edit the group information for the owner.
   * Store the current group topic.
   * Show/Hide the chat detail.
   */
  public chatType = CHAT_TYPE;
  public chat: Chat;
  public floatingOptions: { id: number; url: string; text: string }[];
  public chatList: ChatList[] = [];
  public selectedUserIndex = -1;
  public isChatDetailWindowHide = true;
  public isSearchUser: boolean;
  public searchUsersList: any;
  public searchUserOffset = 1;
  public isChatPreview: boolean;
  public chatPreviewContent: { content: string; src: SafeResourceUrl };
  public keyPressSending: boolean;
  public firstTimeMsg = false;
  public isChatGroupCreation = false;
  public noAbleToAddMemberList = [];
  public groupMembers = [];
  public groupDetail: {
    banner: string;
    name: string;
    members: any[];
    topicId: string;
  };
  public totalUnreadCount = 0;
  public chatGroupLoading: boolean;
  public isErrorModal: boolean;
  public errorMsg: string;
  public groupViewMode: boolean;
  public isChatDetail = false;
  public chatViewConstant = CHAT_VIEW;
  public moreOptions = MORE_OPTIONS;
  public isInFocus = true;
  public unFocusReads = [];
  public id = 0;
  public msgList = {};
  public searchUserLoading: boolean;
  public isChatDetailMembers: boolean;
  public deletedUserName = DELETED_USER_NAME;

  /**
   * Necessary instances.
   */
  constructor(
    private chatService: ChatService,
    private toastr: ToastrService,
    private samvaadLangService: SamvaadLangService,
    private dss: DataStorageService
  ) { }

  /**
   * This is mainly used for the View of the chat.
   * Note: Only use when there is no other options available.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes?.chatView) {
      if (changes?.chatView?.currentValue === this.chatViewConstant.MINI) {
        if (this.isChatDetail) {
          this.emitOnChatDetailLeave(new Date());
        }
        if (!this.isChatGroupCreation) {
          this.isChatListExpanded = true;
         this.emitChatListExpanded.emit(this.isChatListExpanded);
        }
        this.isChatDetailExpanded = false;
        if (this.isChatDetail) {
          this.emitOnChatDetailVisit(new Date());
        }
        if (
          this.isMobileVersion &&
          this.selectedUserIndex &&
          this.selectedUserIndex !== -1
        ) {
          this.onHideChatDetailWindow(
            this.chatList[this.selectedUserIndex].topic
          );
        }
      } else {
        this.isChatListExpanded = false;
        this.isChatDetailExpanded = false;
        if (!changes?.chatView?.firstChange) {
        }
        if (this.isChatDetail) {
          this.emitOnChatDetailLeave(new Date());
        }
        this.emitChatListExpanded.emit(this.isChatListExpanded);
        if (this.isChatDetail) {
          this.emitOnChatDetailVisit(new Date());
        }
      }
      if (this.selectedUserIndex !== RESET_USER_INDEX) {
        this.scrollToBottom();
      }
    }
    if (changes && changes?.isInternet) {
      if (this.selectedUserIndex !== RESET_USER_INDEX) {
        this.getTopicDetail({
          topicId: this.chatList[this.selectedUserIndex].topic,
          userType: this.chatList[this.selectedUserIndex].type,
        });
      }
    }
  }

  /**
   * Initiate the hat and the listeners.
   */
  ngOnInit(): void {
    this.floatingOptions = [
      {
        id: this.floatingOptionConstant.P2P,
        url: "assets/samvaad-assets/images/icons/chat-black.svg",
        text: this.samvaadLanguageText?.samvaad_floating_button?.key1,
      },
      {
        id: this.floatingOptionConstant.GROUP,
        url: "assets/samvaad-assets/images/icons/chat-group-black.svg",
        text: this.samvaadLanguageText?.samvaad_floating_button?.key2,
      },
    ];
    this.chat = new Chat(this.samvaadConfig, this.samToken, this.authToken);
    this.getErrors();
    this.getChatList();
    this.getOnPressMsg();
    this.getOnMessage();
    this.getOnOtherTopic();
    this.getOnGetMetaData();
    this.getDetailMsgs();
    this.getDebounceMsgs();
    this.getDebounceUnsubscribe();
  }

  @HostListener("document:visibilitychange", ["$event"])
  visibilitychange(event): void {
    if (event.target.hidden) {
      this.isInFocus = false;
    } else {
      this.isInFocus = true;
      for (const topic of this.unFocusReads) {
        this.chat.sendReadReceipt(topic);
      }
      this.unFocusReads = [];
    }
  }

  /**
   * Get the message using debounce so that will get the message received end status.
   */
  getDebounceMsgs(): void {
    this.subscriptions.add(
      this.msgSubject
        .pipe(
          debounceTime(MSG_RECEIVED_DEBOUNCE_TIME),
          distinctUntilChanged(),
          switchMap(
            async (msgQuery: {
              userIndex: number;
              topic: Topic;
              from: string;
            }) => this.getNewMessages(msgQuery)
          )
        )
        .subscribe()
    );
  }

  /**
   * Get the message using debounce so that will get the message received end status.
   */
  getDebounceUnsubscribe(): void {
    this.subscriptions.add(
      this.topicUnsubscribeSubject
        .pipe(
          debounceTime(UNSUBSCRIBE_DEBOUNCE_TIME),
          switchMap(async (topicQuery: Topic) => {
            if (topicQuery.isSubscribed()) {
              await topicQuery.leaveDelayed(false, SUB_LEAVE_DELAYED);
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Get the internal error for the user to show.
   */
  getErrors(): void {
    this.subscriptions.add(
      this.chat.error.subscribe((res) => {
        if (res && res.code === ERROR_STATUS.LOGIN_FAILED) {
          window.indexedDB.deleteDatabase(SAMVAAD_INDEX_DB);
          this.emitChatClose.emit(true);
          this.onChatError.emit(res.code);
        }
        if (
          (res && res?.code === ERROR_STATUS.RESTART_LOGIN) ||
          res?.code === ERROR_STATUS.AUTH_FAILED
        ) {
          this.onChatError.emit(res.code);
        }
        this.errorMsg = "";
        this.isErrorModal = true;
        this.errorMsg = res?.msg;
      })
    );
  }

  /**
   * Get the chat list contacts for the p2p/group.
   */
  getChatList(): void {
    this.subscriptions.add(
      this.chat.chatLists.subscribe((chatListObj) => {
        if (chatListObj && chatListObj.sub) {
          if (!this.chatList.length) {
            this.chatList = chatListObj.sub;
            for (const chat of this.chatList) {
              chat.type =
                Topic.topicType(chat?.topic) === CHAT_TEXT_TYPE.GROUP
                  ? this.chatType.GROUP
                  : this.chatType.P2P;
              this.checkForUnread(chat);
              this.chat.chatListLoading = false;
            }
          } else {
            for (const chat of chatListObj.sub) {
              const found = this.chatList.some((el) => el.topic === chat.topic);
              if (!found) {
                chat.type =
                  Topic.topicType(chat?.topic) === CHAT_TEXT_TYPE.GROUP
                    ? this.chatType.GROUP
                    : this.chatType.P2P;
                this.checkForUnread(chat);
                this.chatList.push(chat);
                this.chat.chatListLoading = false;
              }
            }
          }
          this.emitChatClose.emit(false);
        }
      })
    );
  }

  /**
   * Checked for the first time of contacts list.
   */
  checkForUnread(chat: ChatList): void {
    const topic = this.chat.getTopic(chat.topic);
    if (chat?.unread > ZERO) {
      this.getTotalUnreadCount();
    }
    if (chat?.seq - chat?.recv > ZERO) {
      const topic = this.chat.getTopic(chat.topic);
      topic.subscribe().then(() => {
        this.chat.onSendRecvAndLeave.next({ topic, fetchData: true });
    });
    }
  }

  /**
   * Get the new messages from debounce.
   * Update the msg status and scroll to bottom in case of new message in same chat.
   */
  getNewMessages(msgSubject: {
    userIndex: number;
    topic: Topic;
    from: string;
  }): void {
    const userIndex = msgSubject.userIndex;
    this.chatList[userIndex].msg = this.chatList[userIndex].msg.concat(
      this.msgList[msgSubject.topic.name].new
    );
    if (
      userIndex === this.selectedUserIndex &&
      this.msgList[msgSubject?.topic?.name]?.new?.length < SECOND_LAST_MSG &&
      this.msgList[msgSubject?.topic?.name].new[ZERO]?.seq ===
      this.chatList[userIndex]?.msg[
        this.chatList[userIndex]?.msg?.length - ONE
      ]?.seq
    ) {
      this.chatDetail ? (this.chatDetail.isUnreadDot = true) : "";
    }
    this.msgList[msgSubject.topic.name].new = [];
    if (userIndex === this.selectedUserIndex) {
      if (
        this.chatDetail?.scrollMe?.nativeElement?.scrollHeight <
        this.chatDetail?.scrollMe?.nativeElement?.scrollTop +
        this.chatDetail?.scrollMe?.nativeElement?.offsetHeight +
        EXTRA_OFFSET_HEIGHT
      ) {
        this.chatDetail.isUnreadDot = false;
        this.isChatDetailExpanded = false;
        this.subscriptions.add(
          of(true)
            .pipe(
              delay(ZERO),
              tap(() => {
                this.scrollToBottom();
              })
            )
            .subscribe()
        );
      }
      if (this.chatDetail) {
        this.chatDetail?.scrollTOSpecificIndex();
      }
      const topicID = msgSubject?.topic?.name;
      if (this.chatList[this.selectedUserIndex]?.topic === topicID) {
        if (this.isInFocus) {
          this.chat.sendReadReceipt(msgSubject.topic);
          if (
            this.chatList[this.selectedUserIndex]?.type === this.chatType.P2P
          ) {
            const status = msgSubject.topic.msgStatus(
              this.chatList[userIndex].msg[
              this.chatList[userIndex].msg.length - ONE
              ],
              true
            );
            this.chatList[userIndex].msg[
              this.chatList[userIndex].msg.length - ONE
            ]._status = status;
          }
        } else {
          this.unFocusReads.push(msgSubject.topic);
        }
      }
      this.chatList[userIndex].msg = [...this.chatList[userIndex].msg];
      this.subscriptions.add(
        of(true)
          .pipe(
            delay(CHAT_DETAIL_LOADING_DELAY),
            tap(() => {
              this.chat.internalChatDetailLoading = false;
              this.chat.chatDetailLoading = false;
            })
          )
          .subscribe()
      );
    }
  }

  /**
   * Receive the message from the server or cache one by one.
   * Checking the duplicate messages and then emitting using debounce to make the list.
   * Updating the last msg.
   */
  getDetailMsgs(): void {
    this.subscriptions.add(
      this.chat.onGetMessage.subscribe(({ msg, topic, from }) => {
        if (msg && msg.content && msg.seq) {
          const userIndex = this.getChatListIndex(msg.topic);
          if (userIndex !== RESET_USER_INDEX) {
            if (this.chatList[userIndex] && !this.chatList[userIndex].msg) {
              this.chatList[userIndex].msg = [];
            }
            const found = this.chatList[userIndex].msg.some(
              (el) => el.seq === msg.seq
            );
            const foundRep = this.msgList[topic.name]?.new?.some(
              (el) => el?.seq === msg?.seq
            );
            if (!found && !foundRep) {
              if (
                msg?.seq <
                this.chatList[userIndex]?.msg[
                  this?.chatList[userIndex]?.msg?.length - ONE
                ]?.seq
              ) {
                this.chat.internalChatDetailLoading = true;
              }
              if (this.chatList[this.selectedUserIndex]?.topic !== msg.topic) {
                const count = topic.unread;
                this.updateUnReadCount(topic.name, count);
                if (count > ZERO) {
                  this.getTotalUnreadCount();
                }
              }
              this.chatList[userIndex].msg =
                this.chatList[userIndex]?.msg || [];
              if (msg.blocked !== MSG_STATUS.BLOCKED) {
                this.chatList[userIndex].lastMsg =
                  !this.chatList[userIndex]?.lastMsg ||
                    msg.seq > this.chatList[userIndex].lastMsg?.seq
                    ? msg
                    : this.chatList[userIndex].lastMsg;
              }
              if (topic.name !== this.chat.client?._myUID) {
                const status = topic.msgStatus(msg, true);
                msg._status = status;
              }
              this.msgList[topic.name] = {
                new: this.msgList[topic.name]?.new || [],
              };
              this.msgList[topic.name].new.push(msg);
              this.msgSubject.next({ userIndex: userIndex, topic, from });
            }
          }
        }
      })
    );
  }

  /**
   * Scroll to bottom for the chat detail using the view child.
   */
  scrollToBottom(): void {
    this.chatDetail?.scrollToBottom();
  }

  /**
   * Get the total unread counts and emit outside.
   */
  getTotalUnreadCount(): void {
    this.totalUnreadCount = this.chatList.reduce((accumulator, object) => {
      return accumulator + (object.unread || ZERO);
    }, ZERO);
    this.emitUnreadCount.emit(this.totalUnreadCount);
  }

  /**
   * Toggle the chat detail loading.
   */
  toggleChatDetailLoading(status: boolean): void {
    this.chat.chatDetailLoading = status;
  }

  /**
   * Get the press msg event from server.
   */
  getOnPressMsg(): void {
    this.subscriptions.add(
      this.chat.onPresMsg.subscribe(async (presMsg) => {
        if (presMsg && presMsg.what) {
          switch (presMsg.what) {
            case WHAT_STATUS.ONLINE:
              this.updateOnlineStatus(presMsg.src, true);
              break;
            case WHAT_STATUS.OFFLINE:
              this.updateOnlineStatus(presMsg.src, false);
              break;
            case WHAT_STATUS.UPDATE:
              // eslint-disable-next-line no-case-declarations
              const updatedTopic = this.chat.getTopic(presMsg.src);
              // eslint-disable-next-line no-case-declarations
              const updatedIndex = this.getChatListIndex(updatedTopic.name);
              if (updatedIndex !== RESET_USER_INDEX) {
                this.chatList[updatedIndex].public = updatedTopic.public;
              }
              break;
            case WHAT_STATUS.ACCESS_MODE:
              if (presMsg.dacs && (presMsg.dacs.given || presMsg.dacs.want)) {
                const groupTopicId =
                  presMsg.topic !== ACS_STATUS.ME ? presMsg.topic : presMsg.src;
                const groupTopic = this.chat.getTopic(groupTopicId);
                const groupIndex = this.getChatListIndex(groupTopicId);
                if (groupIndex !== RESET_USER_INDEX) {
                  this.updateSelfGroupMembers(groupIndex, groupTopic);
                }
              }
              break;
            case WHAT_STATUS.MESSAGE:
              // eslint-disable-next-line no-case-declarations
              const topicId = presMsg.src ?? presMsg.topic;
              // eslint-disable-next-line no-case-declarations
              let msgReceivedSubject = this.msgReceivedSubjectIds.get(topicId);
              if (!msgReceivedSubject) {
                msgReceivedSubject = new Subject<{
                  topicName: string;
                  presMsg: any;
                }>();
                msgReceivedSubject
                  .pipe(debounceTime(RECEIVED_MSG_DELAY))
                  .subscribe(async ({ topicName, presMsg }) => {
                    this.firstTimeMsg = false;
                    const topic = this.chat.getTopic(topicName);
                    const fetchFromSeqId = Math.min(
                      presMsg.seq + 1,
                      topic.maxMsgSeq() + 1
                    );
                    await topic
                      .subscribe(topic.startMetaQuery().build())
                      .then(async () => {
                        topic
                          .getMeta(
                            topic
                              .startMetaQuery()
                              .withData(
                                fetchFromSeqId,
                                undefined,
                                DEFAULT_MESSAGE_REQUEST
                              )
                              .build()
                          )
                          .then(() => {
                            this.topicUnsubscribeSubject.next(topic);
                          });
                      });
                  });
                this.msgReceivedSubjectIds.set(topicId, msgReceivedSubject);
              }
              msgReceivedSubject.next({ topicName: topicId, presMsg: presMsg });
              break;
            default:
              break;
          }
        }
      })
    );
  }

  /**
   * Get the on message event from the server.
   */
  getOnMessage(): void {
    this.subscriptions.add(
      this.chat.onMessage.subscribe((onMessage) => {
        if (onMessage.ctrl && onMessage.ctrl.code === ERROR_STATUS.NO_CONTENT) {
          this.toggleChatDetailLoading(false);
        }
        if (onMessage.info && onMessage.info.what) {
          switch (onMessage.info.what) {
            case WHAT_STATUS.RECEIVE:
              if (onMessage.info.from !== this.chat.client._myUID) {
                const topicId = onMessage.info.src ?? onMessage.info.topic;
                this.firstTimeMsg = false;
                const chatIndex = this.getChatListIndex(onMessage.info.topic);
                if (chatIndex !== RESET_USER_INDEX) {
                  if (Topic.topicType(topicId) === CHAT_TEXT_TYPE.GROUP) {
                    if (topicId === onMessage.info.from) {
                      this.chatList[chatIndex].minRecv = onMessage.info.seq;
                      this.filterMessages(this.chatList[chatIndex]);
                    }
                  } else {
                    this.chatList[chatIndex].recv =
                      this.chatList[chatIndex]?.recv || ZERO;
                    this.chatList[chatIndex].recv = onMessage.info.seq;
                    this.filterMessages(this.chatList[chatIndex]);
                  }
                }
              }
              break;
            case WHAT_STATUS.KEY_PRESS:
              this.addKeyPressMsg(onMessage.info);
              break;
            case WHAT_STATUS.READ:
              if (onMessage.info.from !== this.chat.client._myUID) {
                const userIndex = this.getChatListIndex(onMessage.info.topic);
                this.firstTimeMsg = false;
                if (userIndex !== RESET_USER_INDEX) {
                  if (
                    Topic.topicType(onMessage.info.topic) ===
                    CHAT_TEXT_TYPE.GROUP
                  ) {
                    if (onMessage.info.topic === onMessage.info.from) {
                      this.chatList[userIndex].minRecv = onMessage.info.seq;
                      this.chatList[userIndex].minRead = onMessage.info.seq;
                      this.filterMessages(this.chatList[userIndex]);
                    }
                  } else {
                    this.chatList[userIndex].recv = onMessage.info.seq;
                    this.chatList[userIndex].read = onMessage.info.seq;
                    this.filterMessages(this.chatList[userIndex]);
                  }
                }
              }
              break;
            case WHAT_STATUS.EVENT:
              this.dss.profileNudgeParamter.next(onMessage.info.content.eventName);
              break;
            default:
              break;
          }
        }
      })
    );
  }

  /**
   * Add the blue or double tick status for the group.
   */
  filterMessages(user: ChatList): void {
    if (user?.msg) {
      user.msg = [...user.msg];
    }
  }

  /**
   * Add the key press event for the user who is typing.
   */
  addKeyPressMsg(info: any): void {
    const userIndex = this.getChatListIndex(info.src ?? info.topic);
    if (userIndex !== RESET_USER_INDEX) {
      this.chatList[userIndex].typing = {
        isTyping: true,
        from: info.from,
      };
      this.resetKeyPress(userIndex);
    }
  }

  /**
   * Reset the typing status for the user.
   */
  resetKeyPress(userIndex: number): void {
    this.subscriptions.add(
      of(true)
        .pipe(
          delay(TYPING_DELAY),
          tap(() => {
            this.chatList[userIndex].typing = {
              isTyping: false,
              from: "",
            };
          })
        )
        .subscribe()
    );
  }

  /**
   * Get the other topic.
   */
  getOnOtherTopic(): void {
    this.subscriptions.add(
      this.chat.onOtherTopic.subscribe((topic) => {
        if (topic) {
          this.firstTimeMsg = true;
          this.resetUnReadCount(topic);
          this.chat.getMetaData(
            topic,
            topic
              .startMetaQuery()
              .withLaterData(DEFAULT_MESSAGE_REQUEST)
              .withSub()
              .build(),
            META_IDENTIFIER.MSG
          );
          this.subscriptions.add(
            of(true)
              .pipe(
                delay(OTHER_TOPIC_DELAY),
                tap(() => {
                  this.isChatDetail = true;
                  const userIndex = this.getChatListIndex(topic.name);
                  this.filterMessages(this.chatList[userIndex]);
                })
              )
              .subscribe()
          );
        }
      })
    );
  }

  /**
   * Reset the unread counts of p2p and group.
   */
  resetUnReadCount(topic: Topic): void {
    const index = this.getChatListIndex(topic?.name);
    if (index !== RESET_USER_INDEX && this.chatList[index]?.unread > ZERO) {
      this.chat.sendReadReceipt(topic);
      this.chatList[index].unread = ZERO;
      this.getTotalUnreadCount();
    }
  }

  /**
   * Get setter meta data.
   */
  getOnGetMetaData(): void {
    this.subscriptions.add(
      this.chat.onGetMetaData.subscribe(({ topic, metaData, identifier }) => {
        if (topic && metaData && identifier) {
          switch (identifier) {
            case META_IDENTIFIER.MSG:
              break;
            case META_IDENTIFIER.ADD_USER:
              if (metaData && metaData.sub && metaData.sub[ZERO]) {
                const newTopic: Topic | any = this.chat.getTopic(
                  metaData.sub[ZERO].user
                );
                newTopic.subscribe().then(() => {
                  const newUser = {
                    acs: newTopic.acs,
                    online: newTopic.online,
                    public: metaData.sub[ZERO].public,
                    topic: metaData.sub[ZERO].user,
                    type: ZERO,
                    msg: [],
                    read: ZERO,
                    recv: ZERO,
                    updated: newTopic.updated,
                    private: metaData.sub[ZERO].private,
                    rawList: "",
                    memberList: null,
                  };
                  this.chatList.push(newUser);
                  this.openDetailWithoutTopic();
                });
              } else {
                this.chat.emitError({
                  code: ERROR_STATUS.NOT_FOUND,
                  msg: "User not in samvaad",
                });
              }
              break;
            default:
              break;
          }
        }
        if (topic && metaData && metaData.sub) {
          const userIndex = this.getChatListIndex(topic?.topic ?? topic?.name);
          if (userIndex !== RESET_USER_INDEX) {
            for (let i = ZERO; i < metaData.sub.length; i++) {
              const sub = metaData.sub[i];
              if (sub.public && sub.user) {
                this.chatList[userIndex].memberList =
                  this.chatList[userIndex].memberList || {};
                this.chatList[userIndex].memberList[sub.user] = sub;
              }
            }
            this.updateMinReadRecv(this.chatList[userIndex], metaData.sub);
            this.filterMessages(this.chatList[userIndex]);
          }
        }
      })
    );
  }

  /**
   * Update the min read and recv for the group members.
   */
  updateMinReadRecv(user: ChatList, membersList: any): void {
    user.minRead = user.minRead || ZERO;
    user.minRecv = user.minRecv || ZERO;
    const { read, recv } = this.getSmallestReadRecv(membersList);
    user.minRead = read;
    user.minRecv = recv;
  }

  /**
   * Get the smallest read, recv from the group members exclude the leave and me members.
   */
  getSmallestReadRecv(groupMembers: any): { read: number; recv: number } {
    const read = Math.min(
      ...groupMembers.map((member) =>
        member.acs.mode !== ACS_STATUS.BLOCKED &&
          member.user !== this.chat.client._myUID
          ? member.read || ZERO
          : DEFAULT_HIGHEST_READ_RECV
      )
    );
    const recv = Math.min(
      ...groupMembers.map((member) =>
        member.acs.mode !== ACS_STATUS.BLOCKED &&
          member.user !== this.chat.client._myUID
          ? member.recv || ZERO
          : DEFAULT_HIGHEST_READ_RECV
      )
    );
    return { read, recv };
  }

  /**
   * Open the detail without the topic of the contact.
   */
  openDetailWithoutTopic(): void {
    this.selectedUserIndex = this.chatList.length - ONE;
    this.isSearchUser = false;
    this.isChatDetailWindowHide = false;
    this.chat.internalChatDetailLoading = false;
    this.isChatDetailMembers = true;
    this.isChatDetail = true;
  }

  /**
   * Update the unread count.
   */
  updateUnReadCount(topicId: string, count: number): void {
    const userIndex = this.getChatListIndex(topicId);
    if (userIndex !== RESET_USER_INDEX) {
      this.chatList[userIndex].unread =
        this.chatList[userIndex]?.unread || ZERO;
      this.chatList[userIndex].unread = count;
    }
  }

  /**
   * Get the p2p online/offline status.
   */
  updateOnlineStatus(topicId: string, status: boolean): void {
    const userIndex = this.getChatListIndex(topicId);
    if (userIndex !== RESET_USER_INDEX) {
      this.chatList[userIndex].online = status;
    }
  }
  chatDetailSwitched(event: {date: Date, previousTopic: string, currentTopic: string}) {
    this.onChatDetailSwitched.emit(event);
  }
  /**
   * Get the p2p/group index from the topic id.
   */
  getChatListIndex(topicId: string): number {
    return this.chatList.findIndex((user) => user.topic === topicId);
  }

  checkForTopic(event: { topicId: string; userType: number }): void {
    if (event.topicId === this.chatList[this.selectedUserIndex]?.topic) {
      this.isChatDetailExpanded = false;
      this.isSearchUser = false;
    } else {
      this.getTopicDetail({ topicId: event.topicId, userType: undefined });
    }
  }

  /**
   * Get the detail for the particular topic either p2p/group.
   */
  async getTopicDetail(event: {
    topicId: string;
    userType: number;
  }): Promise<void> {
    if (event.userType === undefined || event.userType === null) {
      event.userType =
        Topic.topicType(event?.topicId) === CHAT_TEXT_TYPE.GROUP
          ? this.chatType.GROUP
          : this.chatType.P2P;
    }
    this.isChatDetailMembers = true;
    this.isChatDetailExpanded = false;
    this.isChatDetail = false;
    this.isChatGroupCreation = false;
    this.isSearchUser = false;
    this.isChatDetailWindowHide = false;

    const userIndex = this.getChatListIndex(event.topicId);
    if (userIndex === RESET_USER_INDEX) {
      return;
    }

    this.id = ZERO;
    this.toggleChatDetailLoading(true);
    this.selectedUserIndex = userIndex;
    this.chatList[userIndex].type = event.userType;

    if (this.chat.oldSubTopic) {
      await this.chat.leaveOtherTopic(this.chat.oldSubTopic);
    }
    const topic: Topic | any = this.chat.getTopic(event.topicId);
    this.chatList[userIndex].topicDetail = topic;
    this.firstTimeMsg = true;
    const unreadCount = this.chatList[userIndex].unread;
    if (unreadCount > DEFAULT_MESSAGE_REQUEST && topic.acs.isJoiner()) {
      this.removeMsgFromIndexDBFromRange(topic, ONE, topic.seq + ONE);
    }

    if (this.chatList[userIndex]?.msg?.length) {
      this.chat.internalChatDetailLoading = false;
      this.toggleChatDetailLoading(false);
      this.scrollToBottom();
    }
    this.isChatDetail = true;
    topic.messages((msg) => {
      if (msg && !msg.hi) {
        this.chat.onGetMessage.next({
          msg,
          topic: topic,
          from: META_IDENTIFIER.DETAIL_REQUEST,
        });
      }
    });

    this.chatList[userIndex].memberList =
      this.chatList[userIndex]?.memberList || {};
    const onSubsUpdated = (): void => {
      topic.subscribers((sub) => {
        if (sub) {
          this.chatList[userIndex].memberList[sub.user] = sub;
          this.isChatDetailMembers = true;
        } else {
          this.isChatDetailMembers = false;
        }
      });
    };

    topic.onSubsUpdated = onSubsUpdated;

    if (topic.acs.isJoiner()) {
      try {
        await topic.subscribe();
        this.chat.setOldTopic(topic);
        this.resetUnReadCount(topic);

        const since = topic.seq > ZERO ? topic.seq + ONE : undefined;
        let before = undefined;

        if (unreadCount > DEFAULT_MESSAGE_REQUEST) {
          before =
            topic.seq - unreadCount > ZERO
              ? topic.seq - DEFAULT_MESSAGE_REQUEST
              : ONE;
        }

        const params = topic.startMetaQuery().withSub().build();
        const metaData = await topic.getMeta(params);

        if (metaData && metaData.sub) {
          for (const sub of metaData.sub) {
            this.chatList[userIndex].memberList[sub.user] = sub;
          }
          this.isChatDetailMembers = true;
        } else {
          this.isChatDetailMembers = false;
        }
        if (unreadCount < DEFAULT_MESSAGE_REQUEST) {
          const result = await topic.getMeta(
            topic
              .startMetaQuery()
              .withLaterData(DEFAULT_MESSAGE_REQUEST)
              .build()
          );
          if (result?.code === API_STATUS.TWO_HUNDRED_FOUR) {
            topic.messages((msg) => {
              if (msg && !msg.hi) {
                const status = topic.msgStatus(msg, true);
                msg._status = status;
                this.chat.onGetMessage.next({
                  msg,
                  topic: topic,
                  from: META_IDENTIFIER.DETAIL_REQUEST,
                });
              }
            });
          }
        } else {
          const msgParams = topic
            .startMetaQuery()
            .withData(before, since, CUSTOM_MESSAGE_REQUEST)
            .build();
          await topic.getMeta(msgParams);
        }

        if (
          userIndex !== RESET_USER_INDEX &&
          Topic.topicType(topic.name) !== CHAT_TEXT_TYPE.GROUP
        ) {
          if (
            this.chatList[userIndex]?.memberList &&
            Object.values(this.chatList[userIndex]?.memberList || {})
          ) {
            for (const key of Object.keys(
              this.chatList[userIndex]?.memberList || {}
            )) {
              if (key !== this.chat.client?._myUID) {
                this.chatList[userIndex].read =
                  this.chatList[userIndex]?.memberList[key]?.read || ZERO;
                this.chatList[userIndex].recv =
                  this.chatList[userIndex]?.memberList[key]?.recv || ZERO;
                this.filterMessages(this.chatList[userIndex]);
              }
            }
          }
        }

        if (
          Topic.topicType(this.chatList[userIndex]?.topic) ===
          CHAT_TEXT_TYPE.GROUP
        ) {
          if (
            this.chatList[userIndex]?.memberList &&
            Object.values(this.chatList[userIndex]?.memberList || {})
          ) {
            this.updateMinReadRecv(
              this.chatList[userIndex],
              Object.values(this.chatList[userIndex]?.memberList || {})
            );
            this.filterMessages(this.chatList[userIndex]);
          }
        }

        this.toggleChatDetailLoading(false);
      } catch (err) {
        this.chat.chatDetailLoading = false;
      }
    } else {
      this.isChatDetail = true;
      this.chat.chatDetailLoading = false;
      this.chatList[userIndex].unread = ZERO;
    }

    this.isSearchUser = false;
    this.isChatDetailWindowHide = false;
    this.isChatDetail = true;
  }

  /**
   * Close the chat detail only in mini version.
   */
  onHideChatDetailWindow(topicId: string): void {
    const topic = this.chat.getTopic(topicId);
    this.chat.leaveOtherTopic(topic);
    this.isChatDetail = false;
    this.isChatDetailWindowHide = true;
    this.selectedUserIndex = RESET_USER_INDEX;
  }

  /**
   * on chat detail hide
   */
  onChatDetailClose(event, topicId: string): void {
    this.onChatDetailLeave.emit({date: event, topic: topicId});
    const topic = this.chat.getTopic(topicId);
    this.chat.leaveOtherTopic(topic);
    this.isChatDetail = false;
    this.isChatDetailWindowHide = true;
    this.selectedUserIndex = RESET_USER_INDEX;
  }

  /**
   * Close the chat list only in mobile view.
   */
  closeChatList(): void {
    this.isChatListHide = true;
    this.isChatListExpanded = true;
    this.emitChatListExpanded.emit(this.isChatListExpanded);
    this.emitChatListHide.emit(this.isChatListHide);
  }

  /**
   * Emit the chat expand event.
   */
  chatExpand(): void {
    this.onChatExpand.emit();
  }
  /**
   * emit on search input click
   */
  onSearchInputClick(): void {
    // NO CODE
  }
  /**
   * on search icon clicked
   */
  searchClicked(): void {
    this.onSearchIconClicked.emit();
  }
  /**
   * Emit the dropdown floating button actions.
   */
  doFloatingAction(action: number): void {
    this.isChatListOverLapped = true;
    if (action === FLOATING_OPTIONS.P2P) {
      this.toggleChatLoading(true);
      this.searchUsers();
      this.emitOnStartNewChatClickFromPlus();
    }
    if (action === FLOATING_OPTIONS.GROUP) {
      this.emitOnCreateNewGroupClick();
      this.isSearchUser = false;
      this.closeGroup();
      this.subscriptions.add(
        of(true)
          .pipe(
            delay(GROUP_REQUEST_DELAY),
            tap(() => {
              this.groupMembers.push({
                acs: this.chat.meTopic.acs,
                custom_id: this.chat.meTopic.public.publicId,
                uuid: this.chat.meTopic.public.publicId,
                permission: "",
                isMe: IS_ME,
                isAdmin: IS_ADMIN,
                topic: this.chat.client._myUID,
                first_name: !this.chat.meTopic.public?.misc?.deleted
                  ? this.chat.meTopic.public.fn
                  : this.deletedUserName,
                middle_name: this.chat.meTopic.public?.mn,
                last_name: this.chat.meTopic.public?.ln,
                full_name: !this.chat.meTopic.public?.misc?.deleted
                  ? this.chat.meTopic.public.fn +
                  " " +
                  this.chat.meTopic?.public?.mn +
                  " " +
                  this.chat.meTopic?.public?.ln
                  : this.deletedUserName,
                profile_pic: this.chat.meTopic.public?.dp?.data,
                connection_status: CONNECTION_STATUS,
                chat_allow: CHAT_ALLOW,
                is_connect_enable: IS_CONNECTION_ENABLE,
                specialities: [
                  {
                    speciality_id: SPECIALITY_ID,
                    speciality_name: this.chat.meTopic.public?.misc?.speciality,
                    logo: "",
                    is_selected: IS_SELECTED,
                    is_primary: IS_PRIMARY,
                  },
                ],
              });
              this.groupViewMode = false;
              this.isChatGroupCreation = true;
              if (
                this.chatView === this.chatViewConstant.FULL &&
                this.selectedUserIndex &&
                this.selectedUserIndex !== RESET_USER_INDEX
              ) {
                this.onHideChatDetailWindow(
                  this.chatList[this.selectedUserIndex].topic
                );
              }
            })
          )
          .subscribe()
      );
    }
  }

  /**
   * Toggle the chat loading.
   */
  toggleChatLoading(status: boolean): void {
    this.chat.chatLoading = status;
  }

  /**
   * Get the search user list.
   */
  searchUsers(keyword = "", key = "", offset = ONE): void {
    this.isChatGroupCreation = false;
    this.searchUserLoading = true;
    this.searchUserOffset = offset;
    const url = CHAT_API.CHAT_SEARCH_LIST;
    const params = {
      offset: offset,
      speciality_id: ZERO.toString(),
      association_id: ZERO.toString(),
      search_type: !key ? CONNECTION : key,
      is_suggestion: key === CONNECTION ? ZERO : ONE,
      keyword: keyword,
      is_group: "",
      filter: "",
    };
    const headers = {
      ver: HEADERS.VER_4_3,
    };
    this.subscriptions.add(
      this.chatService.searchUser(url, params, headers).subscribe((list) => {
        if (this.searchUserOffset > ONE) {
          if (list?.data?.search_list[ZERO]?.list) {
            this.searchUsersList[ZERO].list = this.searchUsersList[
              ZERO
            ]?.list.concat(list?.data?.search_list[ZERO]?.list);
          }
        } else {
          this.searchUsersList = list.data.search_list;
        }
        this.searchUserOffset = list.data.offset;
        this.isSearchUser = true;
        this.isChatListOverLapped = true;
        if (
          this.chatView === this.chatViewConstant.FULL &&
          this.selectedUserIndex &&
          this.selectedUserIndex !== RESET_USER_INDEX
        ) {
          this.onHideChatDetailWindow(
            this.chatList[this.selectedUserIndex].topic
          );
        }
        this.toggleChatLoading(false);
        this.searchUserLoading = false;
      })
    );
  }

  /**
   * Toggle the chat group loading.
   */
  toggleChatGroupLoading(status: boolean): void {
    this.chatGroupLoading = status;
  }

  /**
   * Create the new fnd and set the meta for the new user p2p only.
   */
  startNewChat(user): void {
    if (!this.chat.chatListLoading) {
      const userExistIndex = this.searchForCustomId(user.uuid);
      if (userExistIndex !== RESET_USER_INDEX) {
        this.checkForTopic({
          topicId: this.chatList[userExistIndex].topic,
          userType: this.chatType.P2P,
        });
      } else {
        const params = {
          desc: {
            public:
              FND_DEFAULT +
              this.samvaadConfig.appName +
              FND_DEFAULT_KEY +
              user.uuid,
          },
        };
        this.chat.setMetaInTopic(this.chat.fndTopic, params);
      }
    }
  }

  /**
   * Search the index from the custom id for user.
   */
  searchForCustomId(uuid: string): any {
    return this.chatList.findIndex((user) => user?.public?.publicId === uuid);
  }

  /**
   * Get the past messages of the p2p/group.
   */
  async getMoreChat(topicId: string): Promise<void> {
    const topic: any = this.chat.getTopic(topicId);
    if (topic.msgHasMoreMessages(false)) {
      if (!topic.isSubscribed()) {
        await topic.subscribe();
      }
      this.id = ONE;
      this.chat.internalChatDetailLoading = true;
      this.firstTimeMsg = true;
      topic.getMessagesPage(DEFAULT_MESSAGE_REQUEST, false).then((data) => {
        if (data && data.code === API_STATUS.TWO_HUNDRED) {
          topic.messages((msg) => {
            if (msg && !msg.hi) {
              this.chat.onGetMessage.next({
                msg,
                topic: topic,
                from: META_IDENTIFIER.MORE_REQUEST,
              });
            }
          });
        }
      });
    }
  }

  /**
   * Open the preview for the image message to show in modal with caption.
   */
  openMsgDetail(event: { content: string; src: SafeResourceUrl }): void {
    this.isChatPreview = true;
    this.chatPreviewContent = event;
  }

  /**
   * Send the key press event to others.
   */
  sendKeyPress(topicId: string): void {
    const topic = this.chat.getTopic(topicId);
    if (!this.keyPressSending) {
      this.keyPressSending = true;
      topic.noteKeyPress();
      this.subscriptions.add(
        of(true)
          .pipe(
            delay(TYPING_DELAY),
            tap(() => {
              this.keyPressSending = false;
            })
          )
          .subscribe()
      );
    }
  }

  /**
   * Send the message to the other p2p/group.
   */
  async sendMessage(event: { value: any; topicId: string }): Promise<void> {
    const userIndex = this.getChatListIndex(event.topicId);
    this.emitOnSendClick(event.topicId);
    if (userIndex != RESET_USER_INDEX) {
      const completion = [];
      const topic = this.chat.getTopic(event.topicId);
      const fMsg = event.value.ent
        ? event.value
        : event.value.replace(
          MESSAGE_REPLACE.BRAKE.FROM,
          MESSAGE_REPLACE.BRAKE.TO
        );
      const pub = topic.createMessage(fMsg, true);
      const newMessage = {
        content: pub.content,
        from: this.chat.client._myUID,
        head: null,
        blocked: pub?.blocked,
        id: pub.id,
        noecho: true,
        seq: pub?.seq,
        topic: event.topicId,
        ts: new Date(),
        _failed: false,
        _noForwarding: true,
        _sending: false,
        _status: MSG_STATUS.QUEUED,
      };
      this.chatList[userIndex].msg = this.chatList[userIndex]?.msg || [];
      this.chatList[userIndex].msg = this.chatList[userIndex].msg.concat([
        newMessage,
      ]);
      if (!topic.isSubscribed()) {
        const subscribePromise = await topic.subscribe().then(async () => {
          const calls = [];
          await topic.queuedMessages(
            (que) => {
              if (que._sending || que.seq == pub.seq) {
                return;
              }
              if (que.head && que.head.webrtc) {
                calls.push(que.seq);
                return;
              }
              if (topic.isSubscribed()) {
                topic
                  .publishMessage(que)
                  .then((): void => {
                    //
                  })
                  .catch((err) => console.log("publishMessage err", err)
                  );
              }
              // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
            },
            (s) => {
                //No code
            }
          );
          if (calls.length > ZERO) {
            topic.delMessagesList(calls, true);
          }
        });
        completion.push(subscribePromise);
      }
      await topic
        .publishDraft(pub, Promise.all(completion))
        .then((ctrl) => {
          if (ctrl.code === API_STATUS.TWO_HUNDRED_TWO) {
            this.afterMsgSuccess(topic, userIndex, pub, ctrl, true);
          }
        })
        .catch(() => {
          this.emitFailedMsg();
          const msgIndex = this.chatList[userIndex].msg.findIndex(
            (msg) => msg.id === pub.id
          );
          this.chatList[userIndex].msg[msgIndex] = {
            content: pub.content,
            from: pub.from,
            head: pub.head,
            id: pub.id,
            noecho: pub.noecho,
            seq: pub.seq,
            topic: pub.topic,
            ts: pub.ts,
            _failed: pub._failed,
            _noForwarding: pub._noForwarding,
            _sending: pub._sending,
            _status: MSG_STATUS.QUEUED,
          };
          this.subscriptions.add(
            of(true)
              .pipe(
                delay(MSG_SEND_DELAY),
                tap(() => {
                  this.chatList[userIndex].msg = [
                    ...this.chatList[userIndex].msg,
                  ];
                  this.scrollToBottom();
                })
              )
              .subscribe()
          );
        });
    }
  }

  /**
   * Add after msg success.
   */
  afterMsgSuccess(topic, userIndex, pub, ctrl, isScrollBottom): void {
    this.chatList[userIndex].touched = new Date();
    const msgIndex = this.chatList[userIndex].msg.findIndex(
      (msg) => msg.id === ctrl.id
    );
    this.chatList[userIndex].msg[msgIndex] = {
      content: pub.content,
      from: pub.from,
      head: pub.head,
      id: pub.id,
      blocked: pub.blocked,
      noecho: pub.noecho,
      seq: pub.seq,
      topic: pub.topic,
      ts: pub.ts,
      _failed: pub._failed,
      _noForwarding: pub._noForwarding,
      _sending: pub._sending,
    };
    this.chatList[userIndex].lastMsg = pub;
    if (isScrollBottom) {
      this.subscriptions.add(
        of(true)
          .pipe(
            delay(ZERO),
            tap(() => {
              this.scrollToBottom();
            })
          )
          .subscribe()
      );
    }
    this.subscriptions.add(
      of(true)
        .pipe(
          delay(MSG_SEND_DELAY),
          tap(() => {
            this.chatList[userIndex].msg = [...this.chatList[userIndex].msg];
            if (Topic?.topicType(topic.name) !== CHAT_TEXT_TYPE.GROUP) {
              const status = topic.msgStatus(
                this.chatList[userIndex].msg[msgIndex],
                true
              );
              this.chatList[userIndex].msg[msgIndex]._status = status;
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Create the image message and send to the send message.
   */
  async sendImageMessage(files, topicId): Promise<void> {
    const pubs = [];
    const userIndex = this.getChatListIndex(topicId);
    const topic = this.chat.getTopic(topicId);
    this.emitOnSendClick(topicId);
    if (userIndex !== RESET_USER_INDEX) {
      for (const image of files) {
        const blob = image.banner;
        const file = image.file;
        const caption = image.caption;
        const width = DEFAULT_IMAGE_MESSAGE_DIMENSIONS.WIDTH;
        const height = DEFAULT_IMAGE_MESSAGE_DIMENSIONS.HEIGHT;
        const create = {
          mime: file.type,
          _tempPreview: blob.bits,
          width: width,
          height: height,
          filename: file.name,
          size: file.size,
          refurl: blob,
        };
        let msg: any = Drafty.insertImage(null, ZERO, create);
        msg = Drafty.appendLineBreak(msg);
        msg = Drafty.append(msg, Drafty.parse(caption));
        const fMsg = msg.ent
          ? msg
          : msg.replace(MESSAGE_REPLACE.BRAKE.FROM, MESSAGE_REPLACE.BRAKE.TO);
        const data = topic.createMessage(fMsg, true);
        const newMessage = {
          content: data.content,
          from: this.chat.client._myUID,
          head: null,
          file: file,
          id: data.id,
          noecho: true,
          seq: data?.seq,
          blocked: data?.blocked,
          topic: topicId,
          ts: new Date(),
          _failed: false,
          _noForwarding: true,
          _sending: false,
          _status: MSG_STATUS.QUEUED,
        };
        data.file = file;
        this.chatList[userIndex].msg = this.chatList[userIndex]?.msg || [];
        this.chatList[userIndex].msg = this.chatList[userIndex].msg.concat([
          newMessage,
        ]);
        pubs.push(data);
      }
      this.scrollToBottom();
      for (const pub of pubs) {
        const file = pub.file;
        const uploader = this.chat.client.getLargeFileHelper();
        if (!uploader) {
          return;
        }
        let ref = "";
        await uploader
          .upload(
            file,
            "",
            // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
            (onProgress) => { },
            (onSuccess) => {
              ref = onSuccess.params.url;
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
            (onFailure) => {
              return;
            }
          )
          .then((value) => {
            ref = value;
          })
          .catch((err) => {
            this.chat.emitError(err);
            return;
          });
        if (ref) {
          pub.content.ent[ZERO].data.ref = ref;
          await topic
            .publishDraft(pub, Promise.all([]))
            .then((ctrl) => {
              if (ctrl.code === API_STATUS.TWO_HUNDRED_TWO) {
                this.afterMsgSuccess(topic, userIndex, pub, ctrl, false);
              }
            })
            .catch(() => {
              this.chatList[userIndex].msg.push(pub);
            });
        }
      }
    }
  }

  /**
   * Retry message Not in use.
   */
  retryMsg(event: { msg: any; topicId: string }): void {
    this.deleteMsg(event);
    this.sendMessage({ value: event.msg.content, topicId: event.topicId });
  }

  /**
   * Delete message Not in use.
   */
  deleteMsg(event: { msg: any; topicId: string }): void {
    const topic = this.chat.getTopic(event.topicId);
    if (topic.isSubscribed()) {
      const flush = this.removeMsgFromIndexDB(topic, event?.msg?.seq);
      if (this.selectedUserIndex !== RESET_USER_INDEX) {
        this.chatList[this.selectedUserIndex].msg = this.chatList[
          this.selectedUserIndex
        ]?.msg?.filter((msg) => msg?.seq !== flush?.seq);
      }
    }
  }

  /**
   * Remove messages from the index DB.
   */
  removeMsgFromIndexDB(topic: Topic, seq: number): any {
    return topic?.flushMessage(seq);
  }

  /**
   * Remove messages from the index DB.
   */
  removeMsgFromIndexDBFromRange(
    topic: Topic,
    fromId: number,
    untilId: number
  ): any {
    return topic?.flushMessageRange(fromId, untilId);
  }

  /**
   * Create the new group with avatar and members.
   */
  async createNewGroup(groupDetail: any): Promise<void> {
    this.emitOnCreateGroupDoneClick();
    const createTopicName = this.chat.client.newGroupTopicName(false);
    const newTopic = this.chat.getTopic(createTopicName);
    const file = groupDetail?.banner[0]?.file;
    let photo = {};
    let ref: string[];
    if (file) {
      const uploader = this.chat.client.getLargeFileHelper();
      await uploader
        .upload(
          file,
          null,
          // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
          (onProgress) => { },
          (onSuccess) => {
            ref = [onSuccess.params.url];
            photo = { type: "url", ref: ref[ZERO] };
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
          (onFailure) => { }
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        .then(() => { });
    }
    const params = {
      _topicName: createTopicName,
    };
    params["attachments"] = ref;
    params["desc"] = {
      public: {
        fn: groupDetail.name,
        name: groupDetail.name,
        photo: photo,
      },
      defacs: {
        auth: GROUP_DEFACS_AUTH,
      },
    };
    params["tags"] = [];
    const getQuery = newTopic
      .startMetaQuery()
      .withLaterDesc()
      .withLaterData()
      .withLaterDel()
      .withLaterSub()
      .build();
    newTopic
      .subscribe(getQuery, params)
      .then(async (data) => {
        if (data && data.topic && data.code === API_STATUS.TWO_HUNDRED) {
          const currentGroupTopic = this.chat.getTopic(data.topic);
          await this.addMemberInGroup(groupDetail, currentGroupTopic, true);
          this.subscriptions.add(
            of(true)
              .pipe(
                delay(CREATE_GROUP_DELAY),
                tap(() => {
                  currentGroupTopic.subscribe().then(async (value) => {
                    const addGroupToChatList = {
                      acs: value.acs,
                      online: value.online,
                      public: value.public,
                      topic: value.name,
                      type: this.chatType.GROUP,
                      msg: [],
                      touched: value.touched,
                      updated: value.updated,
                      _noForwarding: true,
                      membersList: {},
                      rawList: "",
                    };
                    this.chatList.push(addGroupToChatList);
                    this.chatMode = this.chatType.GROUP;
                    this.subscriptions.add(
                      of(true)
                        .pipe(
                          delay(GROUP_CREATE_DELAY),
                          tap(() => {
                            this.checkForTopic({
                              topicId: value.name,
                              userType: this.chatType.GROUP,
                            });
                          })
                        )
                        .subscribe()
                    );
                    this.showSuccess(
                      this.samvaadLanguageText?.samvaad?.key22,
                      this.samvaadLanguageText?.samvaad_create_edit_group?.key17
                    );
                    this.onCreateGroupSuccesFailure.emit({success: true, topicId: value.name});
                  });
                })
              )
              .subscribe()
          );
        } else {
          this.chat.emitError({ code: data, msg: data });
          this.onCreateGroupSuccesFailure.emit({success: false, topicId: data});
          this.toggleChatGroupLoading(false);
        }
      })
      .catch((err) => {
        this.onCreateGroupSuccesFailure.emit({success: false, topicId: err});
        this.toggleChatGroupLoading(false);
        this.chat.emitError({ code: err, msg: err });
      });
  }

  /**
   * Update the self group members msg status.
   */
  updateSelfGroupMembers(groupIndex: number, groupTopic: Topic): void {
    this.chatList[groupIndex].acs = groupTopic.acs;
    this.chatList[groupIndex].unread = groupTopic.unread;
    groupTopic.subscribe().then(() => {
      groupTopic
        .getMeta(
          groupTopic
            .startMetaQuery()
            .withLaterData(DEFAULT_MESSAGE_REQUEST)
            .withSub()
            .build()
        )
        .then((metaData) => {
          this.chatList[groupIndex].memberList =
            this.chatList[groupIndex]?.memberList || {};
          if (metaData && metaData?.sub) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const member of metaData?.sub) {
              this.chatList[groupIndex].memberList[member.user] = member;
            }
          }
          if (
            this.chatList[groupIndex]?.memberList &&
            Object?.keys(this.chatList[groupIndex]?.memberList || {}).length !==
            ZERO
          ) {
            this.updateMinReadRecv(
              this.chatList[groupIndex],
              Object.values(this.chatList[groupIndex].memberList || {})
            );
            this.filterMessages(this.chatList[groupIndex]);
          }
        });
    });
  }

  /**
   * Add members in the group both edit and create.
   */
  async addMemberInGroup(
    groupDetail: any,
    currentGroupTopic: Topic,
    isNewGroup: boolean
  ): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let topics = [];
      let fnd = FND_DEFAULT + this.samvaadConfig.appName;
      let isFnd = false;
      if (!currentGroupTopic.isSubscribed()) {
        await currentGroupTopic.subscribe();
      }
      const groupExistIndex = this.getChatListIndex(currentGroupTopic.name);
      for (const member of groupDetail.members) {
        const userExistIndex = this.searchForCustomId(member.uuid);
        if (userExistIndex !== RESET_USER_INDEX) {
          if (isNewGroup) {
            topics = topics.concat([this.chatList[userExistIndex].topic]);
            // await currentGroupTopic.invite(this.chatList[userExistIndex].topic);
          } else {
            topics = topics.concat([this.chatList[userExistIndex].topic]);
            // await currentGroupTopic.updateMode(this.chatList[userExistIndex].topic, ACS_STATUS.ADD_USER_GROUP).then((update) => {
            //   this.updateGroupMembersStatus(currentGroupTopic, groupExistIndex);
            // });
          }
        } else {
          fnd = fnd + " ext:" + member.uuid + ",";
          isFnd = true;
        }
      }
      if (isFnd) {
        const paramsput = { desc: { public: fnd } };
        if (this.chat.fndTopic.isSubscribed()) {
          await this.chat.fndTopic
            .setMeta(paramsput)
            .then(async () => {
              await this.chat.fndTopic
                .getMeta(this.chat.fndTopic.startMetaQuery().withSub().build())
                .then(async (metaData) => {
                  if (metaData && metaData?.sub) {
                    topics = topics.concat(metaData.sub);
                    // for (let sub of metaData.sub) {
                    //   if (isNewGroup) {
                    //     // await currentGroupTopic.invite(sub.user);
                    //   } else {
                    //     topics = topics.concat([sub.user]);
                    //     // await currentGroupTopic.updateMode(sub.user, ACS_STATUS.ADD_USER_GROUP).then((update) => {
                    //     //   this.updateGroupMembersStatus(currentGroupTopic, groupExistIndex);
                    //     // });
                    //   }
                    // }
                    this.inviteAllMembers(
                      topics,
                      currentGroupTopic,
                      isNewGroup,
                      groupExistIndex
                    ).then(() => {
                      resolve();
                    });
                  } else {
                    this.inviteAllMembers(
                      topics,
                      currentGroupTopic,
                      isNewGroup,
                      groupExistIndex
                    ).then(() => {
                      resolve();
                    });
                  }
                })
                .catch((err) => {
                  this.chat.emitError(err);
                });
            })
            .catch((err) => {
              this.chat.emitError(err);
            });
        }
      } else {
        this.inviteAllMembers(
          topics,
          currentGroupTopic,
          isNewGroup,
          groupExistIndex
        ).then(() => {
          resolve();
        });
      }
    });
  }

  /**
   * Invite/Update all members in one go.
   */
  async inviteAllMembers(
    topics,
    currentGroupTopic,
    isNewGroup,
    groupExistIndex
  ): Promise<any> {
    if (isNewGroup) {
      const promises = [];
      for (const top of topics) {
        promises.push(currentGroupTopic.invite(top?.user ? top.user : top));
      }
      return Promise.all(promises)
        .then((dataArray) => {
          return dataArray;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      const promises = [];
      for (const top of topics) {
        promises.push(
          currentGroupTopic.updateMode(
            top?.user ? top.user : top,
            ACS_STATUS.ADD_USER_GROUP
          )
        );
      }
      return Promise.all(promises)
        .then((update) => {
          this.updateGroupMembersStatus(currentGroupTopic, groupExistIndex);
          return update;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  /**
   * Upload the avatar alone in edit group.
   */
  uploadAvatar(value: any): void {
    const files = value.files;
    const topicId = value.topicId;
    const file = files[ZERO].file;
    const uploader = this.chat.client.getLargeFileHelper();
    uploader
      .upload(
        file,
        topicId,
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        (onProgress) => { },
        async (onSuccess) => {
          const params = {
            public: {
              photo: { data: file, type: URl, ref: onSuccess.params.url },
            },
          };
          const existGroupTopic = this.chat.getTopic(topicId);
          if (!existGroupTopic.isSubscribed()) {
            await existGroupTopic.subscribe();
          }
          existGroupTopic
            .setMeta({
              desc: params,
              attachments: [onSuccess.params.url],
            })
            .then(() => {
              const index = this.getChatListIndex(topicId);
              this.chatList[index].public.photo = params.public.photo;
              if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
                this.chat.leaveOtherTopic(existGroupTopic);
              }
              this.showSuccess(
                this.samvaadLanguageText?.samvaad?.key22,
                this.samvaadLanguageText?.samvaad_create_edit_group?.key18
              );
            })
            .catch((err) => {
              if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
                this.chat.leaveOtherTopic(existGroupTopic);
              }
              this.chat.emitError(err);
            });
        },
        (onFailure) => {
          console.log("onFailure", onFailure);
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .then(() => { });
  }

  /**
   * Remove members from the edit group.
   */
  async removeMemberFromGroup(value: any): Promise<void> {
    this.emitMemberRemoveFromGroup(value?.topicId);
    const member = value.member;
    const topicId = value.topicId;
    const groupTopic = this.chat.getTopic(topicId);
    const groupIndex = this.getChatListIndex(topicId);
    if (!groupTopic.isSubscribed()) {
      await groupTopic.subscribe();
    }
    groupTopic
      .updateMode(member.topic, ACS_STATUS.EXIT_GROUP)
      .then((value) => {
        if (value) {
          this.groupDetail.members = [
            ...this.groupDetail.members.filter(
              (value) => value.uuid !== member.uuid
            ),
          ];
          this.updateGroupMembersStatus(groupTopic, groupIndex);
          if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
            this.chat.leaveOtherTopic(groupTopic);
          }
          this.updateSelfGroupMembers(this.selectedUserIndex, groupTopic);
          this.showSuccess(
            this.samvaadLanguageText?.samvaad?.key22,
            this.samvaadLanguageText?.samvaad_create_edit_group?.key19
          );
        }
      })
      .catch((err) => {
        if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
          this.chat.leaveOtherTopic(groupTopic);
        }
        this.chat.emitError(err);
      });
  }

  /**
   * Update the status for the group members.
   */
  updateGroupMembersStatus(groupTopic: Topic, groupIndex: number): void {
    groupTopic.subscribe().then(() => {
      groupTopic
        .getMeta(groupTopic.startMetaQuery().withSub().build())
        .then((metaData) => {
          if (metaData && metaData?.sub) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const member of metaData?.sub) {
              this.chatList[groupIndex].memberList[member.user] = member;
            }
          }
        });
    });
  }

  /**
   * Update the group basic information.
   */
  async updateGroup(value: any): Promise<void> {
    const groupDetail = value.groupDetail;
    const topicId = value.topicId;
    const existGroupTopic = this.chat.getTopic(topicId);
    if (!existGroupTopic.isSubscribed()) {
      await existGroupTopic.subscribe();
    }
    await this.addMemberInGroup(groupDetail, existGroupTopic, false);
    this.updateSelfGroupMembers(this.selectedUserIndex, existGroupTopic);
    if (existGroupTopic.public.name !== groupDetail.name) {
      const params = {
        public: {
          fn: groupDetail.name,
          name: groupDetail.name,
        },
      };
      existGroupTopic
        .setMeta({
          desc: params,
        })
        .then((value) => {
          if (value.code === API_STATUS.TWO_HUNDRED) {
            this.chatMode = this.chatType.GROUP;
            this.isChatGroupCreation = false;
            this.groupDetail = null;
            this.groupMembers = [];
            this.noAbleToAddMemberList = [];
            const index = this.getChatListIndex(existGroupTopic.name);
            if (index !== RESET_USER_INDEX) {
              this.chatList[index].public.name = groupDetail.name;
            }
            this.checkForTopic({
              topicId: value.topic,
              userType: this.chatType.GROUP,
            });
            this.showSuccess(
              this.samvaadLanguageText?.samvaad?.key22,
              this.samvaadLanguageText?.samvaad_create_edit_group?.key20
            );
          } else {
            this.toggleChatGroupLoading(false);
          }
          if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
            this.chat.leaveOtherTopic(existGroupTopic);
          }
        })
        .catch((err) => {
          this.toggleChatGroupLoading(false);
          this.chat.emitError({ code: err, msg: err });
          if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
            this.chat.leaveOtherTopic(existGroupTopic);
          }
        });
    } else {
      this.chatMode = this.chatType.GROUP;
      this.isChatGroupCreation = false;
      this.groupDetail = null;
      this.groupMembers = [];
      this.noAbleToAddMemberList = [];
      this.checkForTopic({
        topicId: existGroupTopic.name,
        userType: this.chatType.GROUP,
      });
      this.toggleChatGroupLoading(false);
    }
  }

  /**
   * Function to stop the multiple clicks.
   */
  calculateLastClickTime(): boolean {
    const currentTime = Date.now();
    const elapsedTime = currentTime - this.lastClickTime;
    this.lastClickTime = currentTime;
    if (elapsedTime < LAST_CLICK_TIME) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * More option actions.
   */
  async doMoreOptionAction({ action, type }, topicId: string): Promise<void> {
    switch (action) {
      case this.moreOptions.P2P_DETAIL || this.moreOptions.GROUP_DETAIL:
        this.onViewProfileClick.emit(topicId);
        if (type === this.chatType.GROUP) {
          if (this.calculateLastClickTime()) {
            this.closeGroup();
            this.getGroupDetail(topicId);
            if (this.chat.getTopic(topicId).acs.isOwner()) {
              this.groupViewMode = false;
            } else {
              this.groupViewMode = true;
            }
          }
        } else {
          this.redirectToProfile(topicId);
        }
        break;
      case this.moreOptions.MUTE_NOTIFICATION:
        this.emitMuteNotificationClick(topicId);
        if (type === this.chatType.P2P) {
          this.updateMode(topicId, ACS_STATUS.MUTE, null).then((ctrl) => {
            if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
              this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
            }
          });
        } else {
          if (this.chat.getTopic(topicId).acs.isOwner()) {
            this.updateMode(topicId, ACS_STATUS.MUTE_GROUP_OWNER, null).then(
              (ctrl) => {
                if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
                  this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
                }
              }
            );
          } else {
            this.updateMode(topicId, ACS_STATUS.MUTE_GROUP, null).then(
              (ctrl) => {
                if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
                  this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
                }
              }
            );
          }
        }
        break;
      case this.moreOptions.UNMUTE_NOTIFICATION:
        this.emitUnmuteNotificationClick(topicId);
        if (type === this.chatType.P2P) {
          this.updateMode(topicId, ACS_STATUS.UNMUTE, null).then((ctrl) => {
            if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
              this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
            }
          });
        } else {
          if (this.chat.getTopic(topicId).acs.isOwner()) {
            this.updateMode(topicId, ACS_STATUS.UNMUTE_GROUP_OWNER, null).then(
              (ctrl) => {
                if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
                  this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
                }
              }
            );
          } else {
            this.updateMode(topicId, ACS_STATUS.UNMUTE_GROUP, null).then(
              (ctrl) => {
                if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
                  this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
                }
              }
            );
          }
        }
        break;
      case this.moreOptions.REPORT:
        if (type === this.chatType.P2P) {
          const index = this.getChatListIndex(topicId);
          if (index !== RESET_USER_INDEX) {
            this.emitReportUser.emit({
              id: this.chatList[index].public.publicId,
              type: type,
            });
          }
        } else {
          this.emitReportUser.emit({ id: topicId, type: type });
        }
        break;
      case this.moreOptions.BLOCK_P2P:
        this.emitBlockUserClick(topicId);
        if (type === this.chatType.P2P) {
          this.updateMode(topicId, ACS_STATUS.BLOCK, null).then((ctrl) => {
            if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
              this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
            }
          });
        }
        break;
      case this.moreOptions.UNBLOCK_P2P || this.moreOptions.EXIT_GROUP:
        if (type === this.chatType.P2P) {
          this.emitUnblockUserClick(topicId);
          await this.updateMode(topicId, ACS_STATUS.UNBLOCK, null).then(
            (ctrl) => {
              if (ctrl && ctrl.code === API_STATUS.TWO_HUNDRED) {
                this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
                const userIndex = this.getChatListIndex(topicId);
                if (userIndex !== RESET_USER_INDEX) {
                  this.selectedUserIndex = userIndex;
                  this.chatList[userIndex].type = this.chatType.P2P;
                }
                this.chat.getOtherTopic(topicId);
                this.isSearchUser = false;
                this.isChatDetailWindowHide = false;
              }
            }
          );
        } else if (type === this.chatType.GROUP) {
          this.emitExitGroupClick(topicId);
          await this.updateMode(
            topicId,
            ACS_STATUS.EXIT_GROUP,
            this.chat.client._myUID
          ).then((ctrl) => {
            if (ctrl.code === API_STATUS.TWO_HUNDRED) {
              this.chatList[this.selectedUserIndex].acs = ctrl.params.acs;
            }
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * Redirecting to the user profile.
   */
  redirectToProfile(id: string, isCustomId?: boolean): void {
    if (!isCustomId) {
      const index = this.getChatListIndex(id);
      const publicId = this.chatList[index]?.public?.publicId;
      if (!this.chatList[index]?.public?.misc?.deleted) {
        this.emitUserDetail.emit(publicId);
      }
    } else {
      this.emitUserDetail.emit(id);
    }
  }

  /**
   * Update the ACS status of the topic.
   */
  async updateMode(
    topicId: string,
    mode: string,
    meTopic: string
  ): Promise<any> {
    const topic = this.chat.client.getTopic(topicId);
    return await topic.updateMode(meTopic, mode).catch(() => {
      this.chat.emitError({
        code: ERROR_STATUS.NOT_UPDATED,
        msg: "Not Able to Update",
      });
    });
  }

  /**
   * Get the group detail.
   */
  getGroupDetail(topicId: string): void {
    const groupTopic = this.chat.getTopic(topicId);
    groupTopic.subscribe().then(() => {
      if (groupTopic.isSubscribed()) {
        groupTopic
          .getMeta(groupTopic.startMetaQuery().withSub().build())
          .then((metaData) => {
            const customId = [];
            const groupIndex = this.getChatListIndex(groupTopic.name);
            if (groupIndex !== RESET_USER_INDEX && metaData && metaData?.sub) {
              // eslint-disable-next-line no-unsafe-optional-chaining
              for (const member of metaData?.sub) {
                if (member) {
                  this.chatList[groupIndex].memberList =
                    this.chatList[groupIndex].memberList || {};
                  this.chatList[groupIndex].memberList[member.user] = member;
                }
                if (member && member.acs.mode !== ACS_STATUS.BLOCKED) {
                  customId.push(member.public.publicId);
                  this.groupMembers.push({
                    acs: member.acs,
                    custom_id: member.public.publicId,
                    uuid: member.public.publicId,
                    permission: "",
                    isMe:
                      member.user === this.chat.client?._myUID ? IS_ME : !IS_ME,
                    isAdmin: member.acs.isOwner() ? IS_ADMIN : !IS_ADMIN,
                    topic: member.user,
                    first_name: !member.public?.misc?.deleted
                      ? member.public?.fn
                      : this.deletedUserName,
                    middle_name: member.public?.mn,
                    last_name: member.public?.ln,
                    full_name: !member.public?.misc?.deleted
                      ? member.public?.fn +
                      " " +
                      member.public?.mn +
                      " " +
                      member.public?.ln
                      : this.deletedUserName,
                    profile_pic: member.public?.dp?.data,
                    connection_status: CONNECTION_STATUS,
                    chat_allow: CHAT_ALLOW,
                    is_connect_enable: IS_CONNECTION_ENABLE,
                    specialities: [
                      {
                        speciality_id: SPECIALITY_ID,
                        speciality_name: member.public?.misc?.speciality,
                        logo: "",
                        is_selected: IS_SELECTED,
                        is_primary: IS_PRIMARY,
                      },
                    ],
                  });
                }
              }
            }
            this.getOtherUserInfo(customId, {
              banner: groupTopic.public?.photo,
              name: groupTopic.public.name,
              topicId: topicId,
            });
            if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
              this.chat.leaveOtherTopic(groupTopic);
            }
          })
          .catch((err) => {
            if (topicId !== this.chatList[this.selectedUserIndex]?.topic) {
              this.chat.leaveOtherTopic(groupTopic);
            }
            this.chat.emitError(err);
          });
      }
    });
  }

  /**
   * Get the other user info for the group detail.
   */
  getOtherUserInfo(customId, groupDetail): void {
    const url = CHAT_API.OTHER_USER_INFO;
    const body = { uuid: customId };
    const headers = {
      ver: HEADERS.VER_4_3,
    };
    this.subscriptions.add(
      this.chatService
        .searchOtherUsers(url, headers, body)
        .subscribe((list) => {
          if (
            list.code === API_STATUS.TWO_THOUSAND &&
            list.status === API_STATUS.ONE
          ) {
            for (const member of this.groupMembers) {
              const find = this.findMember(list.data.user_info, member.uuid);
              if (find) {
                member["chat_allow"] = find.chat_allow;
                member["connection_status"] = find.connection_status;
                member["is_connect_enable"] = find.is_connect_enable;
                member["permission"] = find.permission;
              }
            }
          }
          this.groupDetail = {
            banner: groupDetail.banner ?? "",
            name: groupDetail.name,
            members: this.groupMembers,
            topicId: groupDetail.topicId,
          };
          this.isChatGroupCreation = true;
        })
    );
  }

  /**
   * Find member from the custom id.
   */
  findMember(list: any[], fnd: string): any {
    return list.find((list) => list.uuid === fnd);
  }

  /**
   * Close the group detail and remove the information of group detail.
   */
  closeGroup(): void {
    this.isChatGroupCreation = false;
    this.groupDetail?.banner ? (this.groupDetail.banner = "") : "";
    this.groupDetail?.name ? (this.groupDetail.name = "") : "";
    this.groupDetail = null;
    this.groupMembers = [];
    this.noAbleToAddMemberList = [];
  }

  /**
   * Send the chat toggle event.
   */
  onChatDetailToggle(event: boolean): void {
    this.isChatDetailExpanded = event;
    this.emitChatDetailExpanded.emit(this.isChatDetailExpanded);
  }

  /**
   * Send the chat list toggle event.
   */
  onChatListToggle(event: boolean): void {
    this.isChatListExpanded = event;
    this.emitChatListExpanded.emit(this.isChatListExpanded);
  }

  /**
   * Show toaster in case of group member limit reached.
   */
  onMaxLimitEvent(event: number): void {
    this.showSuccess(
      this.samvaadLanguageText?.samvaad_validation?.key9 +
      " " +
      event +
      " " +
      this.samvaadLanguageText?.samvaad?.key29,
      ""
    );
  }

  /**
   * Send the chat list toggle event.
   */
  emitOnChatListOpen(event: boolean): void {
    this.onChatListOpen.emit(event);
  }

  /**
   * Show the success toaster for 1 sec.
   */
  showSuccess(msg: string, msgHead: string): void {
    this.showCustomToast({
      toastType: TOASTER.TOAST_TYPE,
      timeout: TOASTER.TIMEOUT,
      message: msg,
      title: msgHead,
    });
  }

  /**
   * Customize the toaster preview.
   */
  showCustomToast(config: any): void {
    const toastRef = this.toastr.show(
      config.message,
      config.title,
      {
        tapToDismiss: TOASTER.TAP_TO_DISMISS,
        toastClass: "",
        positionClass: TOASTER.POSITION_CLASS,
        timeOut: config.timeout,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toastButtons: config.toastButtons,
      },
      config.toastType
    );
    toastRef.onAction.subscribe((actionCtaClicked: string) => {
      if (actionCtaClicked === TOASTER.ACTION_PRIMARY) {
        //TO DO - Event notification for primary CTA click
      }
      if (actionCtaClicked === TOASTER.ACTION_SECONDARY) {
        //TO DO - Event notification for secondary CTA click
      }
      this.toastr.clear(toastRef.toastId);
    });
  }

  /**
   * Retry the failed message fetching.
   */
  async retryFetchingMessage(topicId: string): Promise<void> {
    const topic = this.chat.getTopic(topicId);
    if (!topic.isSubscribed()) {
      await topic.subscribe();
    }
    const msgParams = topic
      .startMetaQuery()
      .withData(undefined, topic.seq, DEFAULT_MESSAGE_REQUEST)
      .build();
    await topic
      .getMeta(msgParams)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => { });
  }

  /**
   * Retry the failed members fetching.
   */
  async retryMembers(topicId: string): Promise<void> {
    const topic = this.chat.getTopic(topicId);
    if (!topic.isSubscribed()) {
      await topic.subscribe();
    }
    const userIndex = this.getChatListIndex(topicId);
    const subParams = topic.startMetaQuery().withSub().build();
    await topic.getMeta(subParams).then((metaData) => {
      if (metaData && metaData?.sub) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const sub of metaData?.sub) {
          this.chatList[userIndex].memberList[sub.user] = sub;
        }
        this.isChatDetailMembers = true;
      }
    });
  }

  /**
   * Emit the chat detail entry event outside.
   */
  emitOnChatDetailVisit(event: Date): void {
    if (this.selectedUserIndex && this.chatList?.length > this.selectedUserIndex) {
      this.onChatDetailVisit.emit({date: event, topic: this.chatList[this.selectedUserIndex].topic});
    }
  }

  /**
   * Emit the chat detail exit event outside.
   */
  emitOnChatDetailLeave(event: Date): void {
    if (this.selectedUserIndex >= 0 &&  this.chatList?.length > this.selectedUserIndex) {
      this.onChatDetailLeave.emit({date: event, topic: this.chatList[this.selectedUserIndex].topic});
    }
  }

  /**
   * Emit the chat list entry event outside.
   */
  emitOnChatListVisit(event: Date): void {
    this.onChatListVisit.emit(event);
  }

  /**
   * Emit the chat list exit event outside.
   */
  emitOnChatListLeave(event: Date): void {
    this.onChatListLeave.emit(event);
  }

  /**
   * Emit the new chat entry event outside.
   */
  emitOnNewChatVisit(event: Date): void {
    this.onNewChatVisit.emit(event);
  }

  /**
   * Emit the new chat exit event outside.
   */
  emitOnNewChatLeave(event: Date): void {
    this.onNewChatLeave.emit(event);
    if (this.isChatListOverLapped == true) {
      this.isChatListOverLapped=false;
    }
  }
  /**
   * Emit the create group entry event outside.
   */
  emitOnGroupVisit(event: ChatDetailTimeTopic): void {
    this.onGroupVisit.emit(event);
  }

  /**
   * Emit the create group exit event outside.
   */
  emitOnGroupLeave(event: ChatDetailTimeTopic): void {
    this.onGroupLeave.emit(event);
    this.isChatListOverLapped = false;
  }

  /**
   * Emit the new member entry event outside.
   */
  emitOnNewMemberVisit(event: ChatDetailTimeTopic): void {
    this.onNewMemberVisit.emit(event);
  }
  /**
   * emit on adding done after adding group members
   */
  emitOnGroupAddMemberDone(event: string): void {
    this.onGroupAddMemberDone.emit(event);
  }

  /**
   * Emit the new member exit event outside.
   */
  emitOnNewMemberLeave(event: ChatDetailTimeTopic): void {
    this.onNewMemberLeave.emit(event);
  }

  /**
   * Emit the start new chat click event outside.
   */
  emitOnStartNewChatClick(): void {
    this.onStartNewChatClick.emit();
  }
    /**
   * Emit the start new chat click event outside.
   */
    emitOnStartNewChatClickFromPlus(): void {
      this.onStartNewChatClickFromPlus.emit();
    }
  /**
   * Emit the start new group click event outside.
   */
  emitOnCreateNewGroupClick(): void {
    this.onCreateNewGroupClick.emit();
  }

  /**
   * Emit the group created click event outside.
   */
  emitOnCreateGroupDoneClick(): void {
    this.onCreateGroupDoneClick.emit();
  }

  /**
   * Emit the send message click event outside.
   */
  emitOnSendClick(event: string): void {
    this.onSendClick.emit(event);
  }

  /**
   * Emit the mute notification click event.
   */
  emitMuteNotificationClick(event: string): void {
    this.onMuteNotificationClick.emit(event);
  }

  /**
   * Emit the unmute notification click event.
   */
  emitUnmuteNotificationClick(event: string): void {
    this.onUnmuteNotificationClick.emit(event);
  }
  /**
   * Emit the block p2p click event.
   */
  emitBlockUserClick(event: string): void {
    this.onBlockUserClick.emit(event);
  }

  /**
   * Emit the unblock p2p click event.
   */
  emitUnblockUserClick(event: string): void {
    this.onUnblockUserClick.emit(event);
  }

  /**
   * Emit the exit group click event.
   */
  emitExitGroupClick(event: string): void {
    this.onExitGroupClick.emit(event);
  }

  /**
   * Emit the attachment click event.
   */
  emitAttachmentClick(event: string): void {
    this.onAttachmentClick.emit(event);
  }

  /**
   * Emit the member remove click event.
   */
  emitMemberRemoveFromGroup(event: string): void {
    this.onMemberRemoveFromGroup.emit(event);
  }

  /**
   * Emit the add doctor click event.
   */
  emitAddDoctorInGroup(event: string): void {
    this.onAddDoctorInGroup.emit(event);
  }

  /**
   * Emit the failed msg event.
   */
  emitFailedMsg(): void {
    this.onFailedMsg.emit();
  }

  /**
   * Disconnect the DB
   * Unsubscribe the me topic
   * Clear the index DB.
   * Unsubscribe the subscriptions.
   */
  async ngOnDestroy(): Promise<void> {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    await this.chat.clearStorage();
    await this.chat.checkForOldTopic();
    await this.chat.disconnectClient();
    await this.chat.logout();
  }
}
