/**
 * Angular imports.
*/
import { Injectable } from '@angular/core';

/**
 * Service imports.
 */
import { EncryptionService } from './encryption.service';
import { LocalstorageService } from './localstorage.service';
import { DqChatPanelService } from '../samvaad/dq-chat-panel.service';

/**
 * Import constants.
 */
 import {LANG_ABBREVIATIONS} from '../constants/app.constants';

/**
 * rxjs imports
 */
import { Subject } from 'rxjs';

/**
 * AuthService
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthService {
  public token: string;
  public token_name: string;
  public custom_id: string;
  /**
   * notifier subject to send logout request
   */
  private sendLogoutRequestSubject = new Subject<{callAPI: boolean, reload?: boolean}>();
  public sendLogoutRequest$ = this.sendLogoutRequestSubject.asObservable();

  /**
   * Necessary instances.
   */
  constructor(
    private localStorageService: LocalstorageService,
    private encrypt: EncryptionService,
    private dqChatPanelService: DqChatPanelService
    ) {
  }

  /**
   * logout
   */
  logout(): void {
    this.token = null;
    let logOutType = '';
    if(sessionStorage.getItem('logoutType')) {
      logOutType  = sessionStorage.getItem('logoutType');
    }
    if (localStorage.getItem('lang') != null || localStorage.getItem('isCookies') != null) {
      let x = localStorage.getItem('lang');
      const y = localStorage.getItem('isCookies');
      const sd = localStorage.getItem('surveyDetail');
      const openAccessData = JSON.parse(localStorage.getItem('openAccess'));
      const ip = localStorage.getItem('ipAddress') ? localStorage.getItem('ipAddress') : '';
      /**
       * Get if user come from open access or not.
       */
      const fromOpenAccess = localStorage.getItem('fromOpenAccess');
      /**
       * Get utm source.
       */
      const utmData = localStorage.getItem('utmSourceData') 
      const isFromDeepLink = localStorage.getItem('isComingFromDeepLink') 
      sessionStorage.clear();
       localStorage.clear();
      this.exitChatPanel();
      x = (x != 'in' && x != 'vi' && x != 'ko' && x != 'zh' && x != LANG_ABBREVIATIONS.JAPANESE && x != LANG_ABBREVIATIONS.SPANISH) ? 'en' : x;
      localStorage.setItem('lang', x);
      localStorage.setItem('isCookies', y);
      sd && localStorage.setItem('surveyDetail', sd);
      if(openAccessData) {
        localStorage.setItem('openAccess', JSON.stringify(openAccessData));
        /**
         * Set if user come from open access or not in local storage.
         */
        localStorage.setItem('fromOpenAccess', fromOpenAccess);
      }
      localStorage.setItem('ipAddress', ip);
      localStorage.setItem('utmSourceData', utmData);
      localStorage.setItem('isComingFromDeepLink', isFromDeepLink);
    } else {
      sessionStorage.clear();
      localStorage.clear();
      this.exitChatPanel();
      localStorage.setItem('lang', 'en');
    }
    sessionStorage.setItem('logoutType',logOutType);
    return;
  }

  /**
   * Exit the chat panel in case of logout.
   */
  exitChatPanel(): void {
    this.dqChatPanelService.exitMiniChatPanel.next(true);
  }

  /**
   * get Token
   */
  getToken(): string {

    if (localStorage.getItem('token') != null) {
      const cipherToken = this.localStorageService.getInLocalStorage('token');
      this.token = this.encrypt.decryptAes(cipherToken);
      this.token = this.token.replace('"', '');
      this.token = this.token.replace('"', '');
      return this.token;
    } else {
      if(!localStorage.getItem('externalSurveyDetail') && !this.isUserLoggedInViaATRT()) {
        this.setLogoutRequest({callAPI: false});
      }
    }

  }

  /**
   * set By Name
   */
  setByName(set_in, set): void {
    const cipherToken = this.encrypt.encryptAes(set);
    this.localStorageService.setInLocalstorage(set_in, cipherToken);
  }

  /**
   * get by Name
   */
  getbyName(token_name): string {
    if (localStorage.getItem(token_name) != null) {
      const cipherToken = this.localStorageService.getInLocalStorage(token_name);
      this.token_name = this.encrypt.decryptAes(cipherToken);
      this.token_name = this.token_name.replace('"', '');
      this.token_name = this.token_name.replace('"', '');
      return this.token_name;
    }

  }

    /**
   * get by Name
   */
    byPassAuthGetbyName(token_name): string {
      if (localStorage.getItem(token_name) != null) {
        const cipherToken = this.localStorageService.getInLocalStorage(token_name);
        this.token_name = this.encrypt.byPassAuthDecryptAes(cipherToken);
        this.token_name = this.token_name.replace('"', '');
        this.token_name = this.token_name.replace('"', '');
        return this.token_name;
      }
    }

  /**
   * is Authenticated
   */
  isAuthenticated(): boolean {
    return this.localStorageService.getInLocalStorage('token') != null || this.isUserLoggedInViaATRT();
  }

  isUserLoggedInViaATRT(): boolean {
    return JSON.parse(localStorage.getItem('mcTokenDetail'))? true : false;
  }

  /**
   * get Custom
   */
  getCustom(): string {
    if (localStorage.getItem('profile_obj') != null) {
      const cipherCustomId = this.localStorageService.getInLocalStorage('profile_obj');
      this.custom_id = this.encrypt.decryptAes(cipherCustomId);
      this.custom_id = this.custom_id.replace('"', '');
      this.custom_id = this.custom_id.replace('"', '');
      return this.custom_id;
    }
  }

  /**
   * set logout request observable
   */
  setLogoutRequest(data?: {callAPI: boolean, reload?: boolean}):void {
    this.sendLogoutRequestSubject.next(data);
  }
}
