import { SamvaadConfig } from "../../modal/chat.interface";

/**
 * For the chat type p2p and group.
 */
export const CHAT_TYPE = {
  P2P: 0,
  GROUP: 1,
};

/**
 * For the chat view full view and minimize view.
 */
export const CHAT_VIEW = {
  FULL: 0,
  MINI: 1,
};

/**
 * More options for the chat detail.
 */
export const MORE_OPTIONS = {
  GROUP_DETAIL: 0,
  P2P_DETAIL: 0,
  MUTE_NOTIFICATION: 1,
  UNMUTE_NOTIFICATION: 2,
  BLOCK_P2P: 4,
  UNBLOCK_P2P: 5,
  SUSPEND_GROUP: 4,
  EXIT_GROUP: 5,
  REPORT: 3,
};

export const MORE_OPTIONS_STATUS = {
  ENABLE: 1,
  DISABLED: 0,
};

/**
 * Access mode for the p2p/group.
 */
export const ACCESS_MODE = {
  NONE: 0,
  JOIN: 1,
  READ: 2,
  WRITE: 4,
  PRES: 8,
  APPROVE: 16,
  SHARE: 32,
  DELETE: 64,
  OWNER: 128,
  BUZZ: 256,
  BITMASK: 511,
  INVALID: 1048576,
};

/**
 * Update mode for the p2p/group.
 */
export const ACS_STATUS = {
  MUTE: "-B",
  UNMUTE: "+B",
  BLOCK: "-JPB",
  UNBLOCK: "+JPB",
  INCOGNITO_ON: "-PB",
  INCOGNITO_OF: "+PB",
  EXIT_GROUP: "N",
  UNMUTE_GROUP: "JRWPSB",
  MUTE_GROUP: "JRWPS",
  UNMUTE_GROUP_OWNER: "JRWPASDOB",
  MUTE_GROUP_OWNER: "JRWPASDO",
  ME: "me",
  BLOCKED: 0,
  ADD_USER_GROUP: "JRWPBS",
};

/**
 * Update mode for the p2p/group.
 */
export const WHAT_STATUS = {
  ONLINE: "on",
  OFFLINE: "off",
  UPDATE: "upd",
  ACCESS_MODE: "acs",
  MESSAGE: "msg",
  RECEIVE: "recv",
  KEY_PRESS: "kp",
  READ: "read",
  EVENT: "event",
  WEBINARLINKED: "TimeMonitoringEvent"
};

/**
 * Chat msg delivery status.
 */
export const MSG_STATUS = {
  NONE: 0,
  QUEUED: 1,
  SENDING: 2,
  FAILED: 3,
  SENT: 4,
  RECEIVED: 5,
  READ: 6,
  TO_ME: 7,
  STATUS_DEL_RANGE: 8,
  BLOCKED: 1,
};

/**
 * For the File upload size, extensions, limits.
 */
export const FILE_UPLOAD_SIZE = 31457280;
export const FILE_UPLOAD_EXTENSION = [
  "jpg",
  "jpeg",
  "png",
  "JPG",
  "JPEG",
  "PNG",
];
export const FILE_LIMITS = 10;
export const BANNER_FILE_LIMIT = 1;

/**
 * For create group default mode.
 */
export const GROUP_DEFACS_AUTH = "RWPBS";

/**
 * Group title max length
 */
export const GROUP_TITLE_MAX_LENGTH = 30;

/**
 * Floating button options.
 */
export const FLOATING_OPTIONS = {
  P2P: 1,
  GROUP: 2,
};

/**
 * Floating button options.
 */
export const ERROR_STATUS = {
  LOGIN_FAILED: 503,
  NO_CONTENT: 204,
  NOT_FOUND: 404,
  NOT_UPDATED: 101,
  RESTART_LOGIN: 400,
  AUTH_FAILED: 401,
  MALFORMED: "malformed",
  AUTHENTICATION_FAILED: "authentication failed",
};

/**
 * Samvaad API status.
 */
export const API_STATUS = {
  TWO_HUNDRED: 200,
  TWO_HUNDRED_TWO: 202,
  TWO_HUNDRED_FOUR: 204,
  TWO_THOUSAND: 2000,
  ONE: 1,
  FIVE_HUNDRED: 500,
  FOUR_TWENTY_NINE: 429,
  API_RETRY: 3,
  FOUR_HUNDRED: 400,
  FOUR_HUNDRED_ONE: 401,
};

/**
 * Variable to store the smavaad auth in local storage.
 */
export const SAMVAAD_AUTH_DATA = "samvaadAuthData";

/**
 * Meta identifier for the get meta.
 */
export const META_IDENTIFIER = {
  ADD_USER: "add_user",
  MSG: "msg",
  DETAIL_REQUEST: "DETAIL_REQUEST",
  MORE_REQUEST: "MORE_REQUEST",
};

/**
 * Default configuration for the samvaad login.
 */
export const BASIC_CONFIGURATION: SamvaadConfig = {
  appName: "DQ",
  host: "chat-uat.docquity.live",
  apiKey: "AQAAAAABAAD-ds_QJf6UFsCYg-yAr5cF",
  transport: "ws",
  secure: true,
  platform: "web",
  persist: true,
};

/**
 * Text type of chat.
 */
export const CHAT_TEXT_TYPE = {
  P2P: "p2p",
  GROUP: "grp",
};

/**
 * Typing delay.
 */
export const TYPING_DELAY = 2000;

/**
 * Default message request.
 */
export const DEFAULT_MESSAGE_REQUEST = 24;
export const CUSTOM_MESSAGE_REQUEST = 50;

/**
 * Highest read receive count.
 */
// eslint-disable-next-line @typescript-eslint/no-loss-of-precision
export const DEFAULT_HIGHEST_READ_RECV = 999999999999999999999999999999999999999999;

/**
 * Is me flag.
 */
export const IS_ME = 1;

/**
 * Is admin flag.
 */
export const IS_ADMIN = 1;

/**
 * Connection status for the members.
 */
export const CONNECTION_STATUS = 1;

/**
 * Chat allow status for the members.
 */
export const CHAT_ALLOW = 1;

/**
 * Is connection enable.
 */
export const IS_CONNECTION_ENABLE = 1;

/**
 * Is member is selected.
 */
export const IS_SELECTED = 1;

/**
 * Is primary speciality.
 */
export const IS_PRIMARY = 1;

/**
 * Default speciality id.
 */
export const SPECIALITY_ID = 1;

/**
 * Replace the space with br tag.
 */
export const MESSAGE_REPLACE = {
  BRAKE: { FROM: "\r\n", TO: "<br/>" },
};

/**
 * Default dimensions for the message image.
 */
export const DEFAULT_IMAGE_MESSAGE_DIMENSIONS = {
  WIDTH: 100,
  HEIGHT: 100,
};

/**
 * Toaster configuration constant.
 */
export const TOASTER = {
  TIMEOUT: 10000,
  TOAST_TYPE: "success",
  POSITION_CLASS: "toast-bottom-left",
  TAP_TO_DISMISS: false,
  ACTION_PRIMARY: "primary",
  ACTION_SECONDARY: "secondary",
};

/**
 * Extra space for the tap to bottom icon. visibility
 */
export const EXTRA_OFFSET_HEIGHT = 125;

/**
 * Raw list length for the group members.
 */
export const RAW_LIST_LENGTH = 0;

/**
 * Comma string from the raw list of members.
 */
export const COMMA = ", ";

/**
 * Regex to remove the double commas.
 */
export const COMMA_REPLACE_REGEX = /^,|,$/g;

/**
 * Regex to check valid base64 url.
 */
export const BASE_64_CHECK_REGEX = /^data:image\/[a-z]+;base64,/;

/**
 * English date constant.
 */
export const DATE_EN_US = "en-US";

/**
 * Hour, minute, am/pm date formatter.
 */
export const DATE_FORMAT = "hh:mm a";

/**
 * Specific index delay for the custom carousel.
 */
export const SCROLL_SPECIFIC_INDEX_DELAY = 100;

/**
 * Key media constant for the images.
 */
export const API_KEY_MEDIA = "?apikey=";
export const API_AUTH_TOKEN = "&auth=token&secret=";

/**
 * Media type for the attachments.
 */
export const MEDIA_TYPE = {
  JPEG: "image/jpeg",
  PNG: "image/png",
  JPG: "image/jpg",
  DATA_IMAGE_BASE: "data:image/png;base64,",
  DATA: "data:",
  BASE: ";base64,",
  APP_OCTET_STREAM: "application/octet-stream",
};

/**
 * System message constants.
 */
export const SYSTEM_MSG_CONSTANT = {
  GROUP_CREATE: "GROUP_CREATE",
  MEMBERS_REMOVE: "MEMBERS_REMOVE",
  MEMBERS_ADD: "MEMBERS_ADD",
  MEMBER_LEFT: "MEMBER_LEFT",
  GROUP_DESC_UPDATE: "GROUP_DESC_UPDATE",
};

/**
 * Image constant.
 */
export const IMAGE = "IM";

/**
 * Type constant.
 */
export const TYPE = "tp";

/**
 * Connect constant.
 */
export const CONNECTION = "connection";

/**
 * Header constant.
 */
export const HEADERS = {
  VER_4_3: "4.3",
};

/**
 * Max unread count and text constant.
 */
export const MAX_UNREAD_COUNT = {
  TEXT: "99+",
  COUNT: 99,
};

/**
 * Header class constant to change the z-index of header when popups triggers.
 */
export const HEADER_CLASS = "header-dec";

/**
 * Custom carousel offset for media.
 */
export const SCROLL_OFFSET = 225;

/**
 * Custom carousel offset for selected users.
 */
export const SCROLL_OFFSET_SEARCH = 250;

/**
 * Delay for the search user typing.
 */
export const SEARCH_USER_DEBOUNCE = 300;

/**
 * Index not found. constant
 */
export const RESET_USER_INDEX = -1;

/**
 * Delay for the messages received.
 */
export const MSG_RECEIVED_DEBOUNCE_TIME = 50;

export const UNSUBSCRIBE_DEBOUNCE_TIME = 100;

/**
 * Delay for the topic leave.
 */
export const SUB_LEAVE_DELAYED = 1000;

/**
 * Second last message constant.
 */
export const SECOND_LAST_MSG = 2;

/**
 * Delay for the message received from the debounce.
 */
export const RECEIVED_MSG_DELAY = 100;

/**
 * Delay for the other topic subscription.
 */
export const OTHER_TOPIC_DELAY = 100;

/**
 * Delay for the create group.
 */
export const GROUP_CREATE_DELAY = 100;

/**
 * Delay for the chat detail loading
 */
export const CHAT_DETAIL_LOADING_DELAY = 1000;

/**
 * Delay for the Group request
 */
export const GROUP_REQUEST_DELAY = 50;

/**
 * Delay for the message send.
 */
export const MSG_SEND_DELAY = 300;

/**
 * Delay for the create group.
 */
export const CREATE_GROUP_DELAY = 500;

/**
 * Default key to find the topic of the users.
 */
export const FND_DEFAULT_KEY = " ext:";

/**
 * Default key to find the topic of the all users at once.
 */
export const FND_DEFAULT = "app:";

/**
 * Url key constant
 */
export const URl = "url";

/**
 * Delay for the Double click.
 */
export const LAST_CLICK_TIME = 300;

/**
 * key constant.
 */
export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;

/**
 * Delay for the internal search focus.
 */
export const INTERNAL_SEARCH_FOCUS_DELAY = 500;

/**
 * Delay for the images set for the thumbnail.
 */
export const THUMBNAIL_IMAGE_DELAY = 500;

/**
 * Maximum number of members can be added in group.
 */
export const MAX_GROUP_MEMBERS_LIMIT = 511;

/**
 * Default text for the deleted user
 */
export const DELETED_USER_NAME = "Docquity User";

/**
 * Index DB name for the samvaad.
 */
export const SAMVAAD_INDEX_DB = "tinode-web";
