<div *ngIf="!fcs.device_detail.is_mobile" class="container"
[ngClass]="callingFrom === screenName.OPEN_ARTICLE_DETAIL ? ''  : ' py-5'">
    <div class="ml-2 p-2" [ngClass]="callingFrom === screenName.OPEN_ARTICLE_DETAIL ? 'article-container-detail'  : 'article-container'">
        <div *ngIf="callingFrom !== screenName.OPEN_ARTICLE_DETAIL" class="my-4 mx-4 heading-4"> {{listTitle}} </div>
        <div *ngIf="callingFrom === screenName.OPEN_ARTICLE_DETAIL" class="ml-2 heading-4"> {{listTitle}} </div>
        <div class="row businesss-container mb-5" >
        <div class="ml-4 card-container" [perfectScrollbar]="config"
        infiniteScroll [infiniteScrollDistance] = "numbers.ONE" [infiniteScrollThrottle]= "numbers.ONE_THOUSAND"
        [scrollWindow]="false">
            <div *ngFor="let card of suggestionList" class="article-card">
                <app-public-article-login-card
                    [analyticRecord]="merge(card.id)"
                    [openArticle]="card"
                    [callingFrom]="callingFrom"
                    (cardClicked)="onCardClick()"
                    [renderType]="openAccessConst.prodListRenderType.product_sugg_list"
                    [pType]="pType">
                </app-public-article-login-card>
            </div>
        </div>
    </div>
    </div>
</div>

<div *ngIf="fcs.device_detail.is_mobile" class="">
    <div class="article-container">
        <div class="my-2 mx-2 heading-4"> {{listTitle}} </div>
        <!-- card-container -->
        <div class="d-flex flex-column scroll-container">
            <div *ngFor="let card of suggestionList" class="article-card">
                <app-public-article-login-card
                [analyticRecord]="merge(card.id)"
                [openArticle]="card"
                [callingFrom]="callingFrom"
                [pType]="pType"
                (cardClicked)="onCardClick()"
                [renderType]="openAccessConst.prodListRenderType.list">
                </app-public-article-login-card>
            </div>
        </div>
    </div>
</div>
