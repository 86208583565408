/**
 * Angular imports.
 */
import { Component, OnInit, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { NewLanguageService } from './../../../dataservices/new-language/new-language.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * SearchUniversity Component
 */
@Component({
  selector: 'app-search-university',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'handleClick($event)',
  },
  templateUrl: './search-university.component.html',
  styleUrls: ['./search-university.component.scss']
})
export class SearchUniversityComponent implements OnInit, OnDestroy {
  @Output() userUniversity = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  public err_msg = '';
  public user_input_json = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public university_list: any;
  public elementRef;
  public offset = 1;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(private jwtSer: JwtDecodeService, public mls: NewLanguageService, private masterService: MasterserviceService, private router: Router, private onboardService: OnboardingService, myElement: ElementRef) {
    this.elementRef = myElement;
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }
    if (sessionStorage.getItem('verify_mobile') == null) {
      this.router.navigateByUrl('/login/input-mobile');
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('verify_mobile'));
    }
  }

  /**
   * search University
   */
  searchUniversity(e): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.university_list = [];
    if (e.target.value == '') {
      this.userUniversity.emit(0);
    }
    this.offset = 1;
    this.subscriptions.add(this.onboardService.searchUniversity(e.target.value, this.offset, this.req_data.token_id).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          if (data['code'] == 2000) {
            this.university_list = data['data'].university_list;
            this.offset = data['data'].offset;
          }
          if (data['code'] == 2001) {
            const university = { country_code: '', university_id: 0, university_name: e.target.value };
            this.userUniversity.emit(university);
          }

        }
      });
    }));
  }

  /**
   * load more University
   */
  loadmoreUniversity(): void {
    const search_by = document.querySelector<HTMLInputElement>('#useruniversity').value;
    this.subscriptions.add(this.onboardService.searchUniversity(search_by, this.offset, this.req_data.token_id).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          if (data['code'] == 2000) {
            this.university_list = this.university_list.concat(data['data'].university_list);
            this.offset = data['data'].offset;
          }
          if (data['code'] == 2001) {
            const university = { country_code: '', university_id: 0, university_name: search_by };
            this.userUniversity.emit(university);
          }
        }
      });
    }));
  }

  /**
   * select University
   */
  selectUniversity(data): void {
    document.querySelector<HTMLInputElement>('#useruniversity').value = data.university_name;
    this.userUniversity.emit(data);
    this.university_list = [];
  }

  /**
   * will active when user click on anywhere on page
   */
  handleClick(event): void {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      // No Code
    } else {
      this.university_list = [];
    }
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
