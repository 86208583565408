import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addTargetBlank'
})
export class AddTargetBlankPipe implements PipeTransform {

  constructor() {
    // No Code
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    // console.log(value);
    if (value != '') {
      value = value.replace(/target='_blank' /g, '');
      value = value.replace(/target="_blank" /g, '');
      value = value.replace(/<a /g, '<a target=\'_blank\' ');
    }
    return value;
  }

}
