/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * More options dropdown.
 */
@Component({
  selector: 'app-more-options',
  templateUrl: './more-options.component.html',
  styleUrls: ['./more-options.component.scss']
})
export class MoreOptionsComponent {

  /**
   * Header information.
   */
  @Input() moreOptions: { id: number, url: string }[];

  /**
   * Emit the dropdown actions.
   */
  @Output() emitAction: EventEmitter<number> = new EventEmitter();

  /**
   * Is dropdown open.
   */
  public isDropdown = false;

  /**
   * Emit the dropdown actions.
   */
  doAction(action: number): void {
    this.emitAction.emit(action);
  }

  /**
   * Toggle progress bar status.
   */
  toggleFloating(status: boolean): void {
    this.isDropdown = status;
  }
}
