/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/**
 * Constant imports
 */
import { NUMBERS, USER_ROLE } from 'src/app/constants/app.constants';
import { OpenAccessService } from '../open-access.service';

/**
 * Component for displaying a business enquiry form.
 */
@Component({
  selector: 'app-business-enquiry-form',
  templateUrl: './business-enquiry-form.component.html',
  styleUrls: ['./business-enquiry-form.component.scss']
})
export class BusinessEnquiryFormComponent implements OnInit {

  /**
   * Property to control the visibility of the user role popup.
   */
  public userRolePopup = true;

  /**
   * Constants for numbers.
   */
  public numbers = NUMBERS;

  /**
   * Form group to handle the business enquiry form.
   */
  myForm: FormGroup;

  /**
   * Constructor to inject FormBuilder.
   * @param formBuilder - Instance of FormBuilder.
   */
  constructor(private formBuilder: FormBuilder,
    public oas : OpenAccessService) {}

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    console.log('blueee');
    console.log('User Role:', this.oas.userRole);
    /**
     * Initialize the form with form controls and validators.
     */
    this.myForm = this.formBuilder.group({
      lookingFor: [this.oas.userRole === USER_ROLE.BR ? 'option1' : 'option2', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
    });
    console.log('redd');
  }

  /**
   * Getter for the 'lookingFor' form control.
   * @returns The 'lookingFor' form control.
   */
  get lookingFor() {
    return this.myForm.get('lookingFor');
  }

  /**
   * Getter for the 'name' form control.
   * @returns The 'name' form control.
   */
  get name() {
    return this.myForm.get('name');
  }

  /**
   * Getter for the 'email' form control.
   * @returns The 'email' form control.
   */
  get email() {
    return this.myForm.get('email');
  }

  /**
   * Getter for the 'number' form control.
   * @returns The 'number' form control.
   */
  get number() {
    return this.myForm.get('number');
  }

  /**
   * Handle form submission.
   * If the form is valid, process the form data; otherwise, mark form controls as touched to display validation errors.
   */
  onSubmit() {
    if (this.myForm.valid) {
      console.log(this.myForm.value);
    } else {
      // Mark form controls as touched to display validation errors
      this.myForm.markAllAsTouched();
    }
  }

}
