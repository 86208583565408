/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
*/
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Modal imports.
*/
import { Chat } from '../../../modal/chat';
import { ChatList } from '../../../modal/chat.interface';

/**
 * Constant imports.
 */
import { MSG_STATUS, CHAT_TYPE, CHAT_VIEW, MORE_OPTIONS, MORE_OPTIONS_STATUS, DEFAULT_HIGHEST_READ_RECV, EXTRA_OFFSET_HEIGHT, RAW_LIST_LENGTH, COMMA, COMMA_REPLACE_REGEX, DATE_EN_US, DATE_FORMAT, SCROLL_SPECIFIC_INDEX_DELAY, API_KEY_MEDIA, MEDIA_TYPE, BASE_64_CHECK_REGEX, SYSTEM_MSG_CONSTANT, IMAGE, ZERO, ONE, THREE, TWO, DELETED_USER_NAME, API_AUTH_TOKEN } from '../../constants/chat.constants';
import { CHAT_API } from '../../constants/chat-api.constant';

/**
 * Pipe imports.
 */
import { NiceDateFormatPipe } from '../../../pipes/nice-date.pipe';
import { DatePipe } from '@angular/common';

/**
 * Rxjs imports.
 */
import { Subscription, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-detail-panel',
  templateUrl: './chat-detail-panel.component.html',
  styleUrls: ['./chat-detail-panel.component.scss']
})
export class ChatDetailPanelComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  /**
   * Scroll For the chat list div.
   * Div for the history offset scroll up.
   */
  @ViewChild('scrollMe', { static: false }) scrollMe!: ElementRef;
  @ViewChildren("commentDiv", { read: ElementRef }) commentDiv: QueryList<ElementRef>;
  /**
   * All the details regarding the chat.
   * Auth token for the image preview.
   * Current user index.
   * Hide the detail window in case of mini view.
   * Me topic.
   * VIew for the chat detail mini or full.
   * Is Chat detail box is expanded.
   * Loading for the chat detail.
   * Show preview for the send image msg.
   * Get the instance of the samvaad chat only to get the members list.
   */
  @Input() chatDetail: ChatList;
  @Input() authToken: string;
  @Input() selectedUserIndex: number;
  @Input() hideDetailWindow: boolean;
  @Input() isMeTopic: string;
  @Input() chatView: number;
  @Input() isChatDetailExpanded: boolean;
  @Input() isLoading: boolean;
  @Input() chat: Chat;
  @Input() samvaadLanguageText: any = {};
  @Input() isChatDetailMembers: boolean;
  @Input() isMobileVersion: boolean;
  @Input() hostKey: string;
  @Input() apiKey: string;

  /**
   * Emit hide chat event.
   * Emit the request for more chats.
   * Emit the typing message value change.
   * Emit the message send event.
   * Emit the retry msg.
   * Emit the file upload submit event for the msg.
   * Emit the dropdown actions.
   * Emit the avatar click event.
   */
  @Output() onChatHide: EventEmitter<Date> = new EventEmitter();
  @Output() getMoreChat: EventEmitter<string> = new EventEmitter();
  @Output() openMsgDetail: EventEmitter<{ content: string; src: SafeResourceUrl }> = new EventEmitter();
  @Output() emitValueChange: EventEmitter<string> = new EventEmitter();
  @Output() emitSendMessage: EventEmitter<{ value: string, topicId: string }> = new EventEmitter();
  @Output() emitRetryMessage: EventEmitter<{ msg: string, topicId: string }> = new EventEmitter();
  @Output() emitDeleteMessage: EventEmitter<{ msg: string, topicId: string }> = new EventEmitter();
  @Output() emitFileUploadSubmit: EventEmitter<any> = new EventEmitter();
  @Output() emitAction: EventEmitter<{ action: number; type: number }> = new EventEmitter();
  @Output() onAvatarClick: EventEmitter<number> = new EventEmitter();
  @Output() onNewJourney: EventEmitter<void> = new EventEmitter();
  @Output() onChatDetailToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onRetryFetchMessages: EventEmitter<string> = new EventEmitter();
  @Output() onRetryMembers: EventEmitter<string> = new EventEmitter();
  @Output() onVisit: EventEmitter<Date> = new EventEmitter();
  @Output() onLeave: EventEmitter<Date> = new EventEmitter();
  @Output() onAttachmentClick: EventEmitter<string> = new EventEmitter();

  @Output() onChatDetailSwitched: EventEmitter<{date: Date, previousTopic: string, currentTopic: string}> = new EventEmitter();

  /**
   * More options constants.
   */
  public moreOptions = MORE_OPTIONS;
  public moreOptionsStatus = MORE_OPTIONS_STATUS;
  public chatType = CHAT_TYPE;
  public chatViewConstant = CHAT_VIEW;
  public msgStatus = MSG_STATUS;
  public systemMsgConstant = SYSTEM_MSG_CONSTANT;
  public IMAGE = IMAGE;
  public deletedUserName = DELETED_USER_NAME;

  /**
   * More option dropdown for the p2p.
   * Do not change the position of the array.
   */
  public userMoreOptions: { id: number; url: string; text: string; status: number }[];
  /**
   * More option dropdown for the group.
   * Dp not change the position of the array.
   */
  public groupMoreOptions: { id: number; url: string; text: string; status: number }[];

  /**
   * To show the block/unblock p2p modal.
   * To show the mute/unmute modal.
   * To check if user is mute or not.
   * To check if user is block or not.
   * To show the exit group modal.
   */
  public isBlockModal: boolean;
  public isNotificationModal: boolean;
  public isNotificationMute: boolean;
  public isUserBlock: boolean;
  public isExitGroupModal: boolean;
  public isUnreadDot: boolean;
  public EXTRA_OFFSET_HEIGHT = EXTRA_OFFSET_HEIGHT;

  /**
   * Unsubscribe the subscription.
   * Collect the sanitize seq of images in chat.
   * First index to find the first message of offset.
   */
  private subscriptions: Subscription = new Subscription();
  private sanitizeSeq = {};
  private firstIndex = DEFAULT_HIGHEST_READ_RECV;
  private visitTime: Date;

  /**
   * Necessary instances.
   */
  constructor(private _sanitizer: DomSanitizer) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chatDetail) {
      if (changes.chatDetail.currentValue && changes.chatDetail.previousValue) {
        this.onChatDetailSwitched.emit({date: this.visitTime, previousTopic:changes.chatDetail.previousValue.topic, currentTopic: changes.chatDetail.currentValue.topic });
        this.visitTime = new Date();
      }
    }
  }

  /**
   * Set the options for the p2p and group with languages.
   */
  ngOnInit(): void {
    this.emitOnVisit();
    this.setUserGroupOptions();
  }

  /**
   * Emit the entry event outside.
   */
  emitOnVisit(): void {
    this.visitTime = new Date();
    this.onVisit.emit(new Date());
  }

  /**
   * Emit the exit event outside.
   */
  emitOnLeave(): void {
    this.onLeave.emit(this.visitTime);
  }

  /**
   * More option dropdown for the group.
   * Dp not change the position of the array.
   */
  setUserGroupOptions(): void {
    this.userMoreOptions = [
      { id: this.moreOptions.P2P_DETAIL, url: 'assets/samvaad-assets/images/icons/person.svg', text: this.samvaadLanguageText?.samvaad?.key5, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.MUTE_NOTIFICATION, url: 'assets/samvaad-assets/images/icons/bell.svg', text: this.samvaadLanguageText?.samvaad_mute_unmute_modal?.key7, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.UNMUTE_NOTIFICATION, url: 'assets/samvaad-assets/images/icons/unmute.svg', text: this.samvaadLanguageText?.samvaad_mute_unmute_modal?.key8, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.REPORT, url: 'assets/samvaad-assets/images/icons/search-user-list-select.svg', text: this.samvaadLanguageText?.samvaad?.key6, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.BLOCK_P2P, url: 'assets/samvaad-assets/images/icons/block.svg', text: this.samvaadLanguageText?.samvaad_block_unblock_modal?.key6, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.UNBLOCK_P2P, url: 'assets/samvaad-assets/images/icons/unblock.svg', text: this.samvaadLanguageText?.samvaad_block_unblock_modal?.key7, status: this.moreOptionsStatus.ENABLE }
    ];

    this.groupMoreOptions = [
      { id: this.moreOptions.GROUP_DETAIL, url: 'assets/samvaad-assets/images/icons/person.svg', text: this.samvaadLanguageText?.samvaad?.key7, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.MUTE_NOTIFICATION, url: 'assets/samvaad-assets/images/icons/bell.svg', text: this.samvaadLanguageText?.samvaad_mute_unmute_modal?.key7, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.UNMUTE_NOTIFICATION, url: 'assets/samvaad-assets/images/icons/unmute.svg', text: this.samvaadLanguageText?.samvaad_mute_unmute_modal?.key8, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.REPORT, url: 'assets/samvaad-assets/images/icons/search-user-list-select.svg', text: this.samvaadLanguageText?.samvaad?.key8, status: this.moreOptionsStatus.ENABLE },
      { id: this.moreOptions.SUSPEND_GROUP, url: 'assets/samvaad-assets/images/icons/block.svg', text: this.samvaadLanguageText?.samvaad?.key9, status: this.moreOptionsStatus.DISABLED },
      { id: this.moreOptions.EXIT_GROUP, url: 'assets/samvaad-assets/images/icons/block.svg', text: this.samvaadLanguageText?.samvaad_exit_modal?.key3, status: this.moreOptionsStatus.ENABLE }
    ];
  }

  /**
   * When component view is initiate scroll to bottom the chat box.
   */
  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  /**
   * To scroll to the bottom of the div.
   */
  scrollToBottom(): void {
    if (this.scrollMe && this.scrollMe.nativeElement) {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
      this.isUnreadDot = false;
    }
  }

  /**
   * Get the online/offline text and image.
   */
  getUserOnlineOfflineStatus(users: ChatList, img: boolean): string {
    if (users?.online) {
      if (img) {
        return 'assets/samvaad-assets/images/icons/online-status.svg'
      } else {
        return this.samvaadLanguageText?.samvaad?.key10;
      }
    } else {
      if (img) {
        return 'assets/samvaad-assets/images/icons/offline-status.svg'
      } else {
        return '';
      }
    }
  }

  /**
   * Get the last message or typing status.
   */
  getLastMsg(user: ChatList, type: number): string {
    if (user?.typing?.isTyping) {
      if (type === this.chatType.GROUP) {
        return user?.memberList[user?.typing?.from]?.public?.fn + ' ' + user?.memberList[user?.typing?.from]?.public?.ln + ' ' + this.samvaadLanguageText?.samvaad?.key11;
      } else {
        return this.samvaadLanguageText?.samvaad?.key11;
      }
    }
    if (type === this.chatType.GROUP) {
      this.getRawList(user);
      return user.rawList;
    } else {
      return this.getUserOnlineOfflineStatus(user, false);
    }
  }

  /**
   * Get the raw list for the group members.
   */
  getRawList(user: ChatList): void {
    user.rawList = '';
    if (user?.memberList && Object?.values(user?.memberList)) {
      const members: any = Object?.values(user?.memberList);
      for (let i = RAW_LIST_LENGTH; i < members.length; i++) {
        const member = members[i];
        if (member?.acs?.mode) {
          user.rawList = user?.rawList + (i > RAW_LIST_LENGTH ? COMMA : '') + (member.user === this.isMeTopic ? this.samvaadLanguageText?.samvaad?.key1 : (!member?.public?.misc?.deleted ? member?.public?.fn : this.deletedUserName));
          user.rawList = user.rawList.replace(COMMA_REPLACE_REGEX, '');
        }
      }
    }
  }

  /**
   * Get the last message date using nice date formate.
   */
  getLastMsgDate(user: ChatList): string {
    if (user.lastMsg && user.lastMsg.ts) {
      const niceDatePipe = new NiceDateFormatPipe;
      const lastMsgDate = niceDatePipe.transform(user.lastMsg.ts,
        (new DatePipe(DATE_EN_US)).transform(user.lastMsg.ts, DATE_FORMAT));
      return lastMsgDate;
    } else {
      return '';
    }
  }

  /**
   * Track by for the message sequence.
   */
  trackBySeq(index: number, chatMessage: any): any {
    return chatMessage ? chatMessage.seq : undefined;
  }

  /**
   * Track by for the message sequence.
   */
  trackByDate(index: number, group: any): any {
    return group ? group.date : undefined;
  }

  /**
   * Emit the close window event for mini view.
   */
  closeDetailWindow(): void {
    this.onChatHide.emit(this.visitTime);
  }

  /**
   * Toggle the expand of detail for the mini view
   */
  toggleExpandWindow(): void {
    this.isChatDetailExpanded = !this.isChatDetailExpanded;
    this.emitChatDetail();
  }

  /**
   * Take actions for the more options.
   */
  doMoreOptionAction(action: number, type: number): any {
    switch (action) {
      case this.moreOptions.P2P_DETAIL || this.moreOptions.GROUP_DETAIL:
        this.onEmitAction(action, type);
        break;
      case this.moreOptions.MUTE_NOTIFICATION:
        this.isNotificationMute = true;
        this.isNotificationModal = true;
        break;
      case this.moreOptions.UNMUTE_NOTIFICATION:
        this.onEmitAction(this.moreOptions.UNMUTE_NOTIFICATION, this.chatDetail.type === this.chatType.P2P ? this.chatType.P2P : this.chatType.GROUP)
        break;
      case this.moreOptions.REPORT:
        this.onEmitAction(action, type);
        break;
      case this.moreOptions.BLOCK_P2P:
        if (type === this.chatType.P2P) {
          this.isUserBlock = true;
          this.isBlockModal = true;
        }
        break;
      case this.moreOptions.UNBLOCK_P2P || this.moreOptions.EXIT_GROUP:
        if (type === this.chatType.P2P) {
          this.isUserBlock = false;
          this.isBlockModal = true;
        }
        if (type === this.chatType.GROUP) {
          this.isExitGroupModal = true;
        }
        break;
      default:
        break;
    }

  }

  /**
   * Emit the actions for the more option dropdown.
   */
  onEmitAction(action: number, type: number): void {
    this.emitAction.emit({ action, type });
    this.isBlockModal = false;
    this.isNotificationModal = false;
    this.isExitGroupModal = false;
  }

  /**
   * Scroll up event.
   */
  onScrollUp(): void {
    const topic: any = this.chat.getTopic(this.chatDetail.topic);
    if (topic.msgHasMoreMessages(false)) {
      this.firstIndex = this?.chatDetail?.msg.length;
      this.getMoreChat.emit(this.chatDetail.topic);
    }
  }

  /**
   * Scroll to the specific seq of the chat.
   */
  scrollTOSpecificIndex(): void {
    this.subscriptions.add(of(true).pipe(
      delay(SCROLL_SPECIFIC_INDEX_DELAY),
      tap(() => {
        const userToScrollOn = this.commentDiv.toArray();
        userToScrollOn[userToScrollOn.length - this.firstIndex]?.nativeElement?.scrollIntoView();
        this.firstIndex = DEFAULT_HIGHEST_READ_RECV;
      })).subscribe());
  }

  /**
   * Open the detail for the image msg.
   */
  openImageMsg(msg): void {
    const caption = msg.content.txt;
    const imgSrc = this.sanitizeSeq[msg?.id ? msg?.id : msg?.seq];
    this.openMsgDetail.emit({ content: caption, src: imgSrc })
  }

  /**
   * Sanitize the dp.
   */
  dpSanitize(image: any): string {
    if (image?.ref) {
      const authToken = encodeURIComponent(this.authToken);
      return CHAT_API.BASE_URL + this.hostKey + image.ref + API_KEY_MEDIA + this.apiKey + API_AUTH_TOKEN + authToken;
    }
  }

  /**
   * Sanetize the image.
   */
  sanetizeImage(msg: any): SafeResourceUrl {
    const image = msg.content.ent[ZERO].data;
    const authToken = encodeURIComponent(this.authToken);
    let url: SafeResourceUrl = '';
    if (image && image.mime === MEDIA_TYPE.APP_OCTET_STREAM) {
      url = this._sanitizer.bypassSecurityTrustResourceUrl(image.val);
    } else if (image && (image.mime === MEDIA_TYPE.JPEG || image.mime === MEDIA_TYPE.PNG || image.mime === MEDIA_TYPE.JPG)) {
      if (image.ref && !this.isBase64Url(image.ref)) {
        url = CHAT_API.BASE_URL + this.hostKey + image.ref + API_KEY_MEDIA + this.apiKey + API_AUTH_TOKEN + authToken;
      } else if (image.val) {
        url = this._sanitizer.bypassSecurityTrustResourceUrl(MEDIA_TYPE.DATA + image.mime + MEDIA_TYPE.BASE + image.val);
      } else if (image.ref && this.isBase64Url(image.ref)) {
        url = this._sanitizer.bypassSecurityTrustResourceUrl(image.ref);
      }
    } else {
      url = this._sanitizer.bypassSecurityTrustResourceUrl(MEDIA_TYPE.DATA_IMAGE_BASE + image);
    }
    this.sanitizeSeq[msg?.id ? msg?.id : msg?.seq] = url;
    return url;
  }

  /**
   * Check if it is a base 64 url.
   */
  isBase64Url(url: string): boolean {
    const base64Regex = BASE_64_CHECK_REGEX;
    return base64Regex.test(url);
  }

  /**
   * Emit the value change for the message.
   */
  valueChange(value: string): void {
    this.emitValueChange.emit(value);
  }

  /**
   * Send the message to the other.
   */
  sendMessage(value: string, topicId: string): void {
    this.emitSendMessage.emit({ value, topicId });
  }

  /**
   * Delete message in case of failure.
   */
  deleteMsg(msg: string, topicId: string): void {
    this.emitDeleteMessage.emit({ msg, topicId });
  }

  /**
   * Retry message in case of failure.
   */
  resendMsg(msg: string, topicId: string): void {
    this.emitRetryMessage.emit({ msg, topicId });
  }

  /**
   * Send the image message with caption.
   */
  sendImageMessage(event: any): void {
    this.emitFileUploadSubmit.emit(event);
  }

  /**
   * Get the more options dropdown list according to the user/group.
   */
  getMoreOptions(chatDetail: ChatList): {
    id: number;
    url: string;
    text: string;
    status: number;
  }[] {
    if (chatDetail.type) {
      if (!chatDetail.acs.isBuzzer()) {
        this.groupMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.ENABLE;
        this.groupMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
      } else {
        this.groupMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.ENABLE;
      }
      if (chatDetail.acs.isOwner()) {
        this.groupMoreOptions[this.moreOptions.SUSPEND_GROUP].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.EXIT_GROUP].status = this.moreOptionsStatus.DISABLED;
      } else {
        this.groupMoreOptions[this.moreOptions.SUSPEND_GROUP].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.EXIT_GROUP].status = this.moreOptionsStatus.ENABLE;
      }
      if (!chatDetail.acs.isJoiner()) {
        this.groupMoreOptions[this.moreOptions.GROUP_DETAIL].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.SUSPEND_GROUP].status = this.moreOptionsStatus.DISABLED;
        this.groupMoreOptions[this.moreOptions.EXIT_GROUP].status = this.moreOptionsStatus.DISABLED;
      } else {
        this.groupMoreOptions[this.moreOptions.GROUP_DETAIL].status = this.moreOptionsStatus.ENABLE;
      }
      return this.groupMoreOptions;
    } else {
      if (!chatDetail.acs.isBuzzer()) {
        this.userMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.ENABLE;
        this.userMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
      } else {
        this.userMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
        this.userMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.ENABLE;
      }
      if (chatDetail.acs.isJoiner()) {
        this.userMoreOptions[this.moreOptions.BLOCK_P2P].status = this.moreOptionsStatus.ENABLE;
        this.userMoreOptions[this.moreOptions.UNBLOCK_P2P].status = this.moreOptionsStatus.DISABLED;
      } else {
        this.userMoreOptions[this.moreOptions.BLOCK_P2P].status = this.moreOptionsStatus.DISABLED;
        this.userMoreOptions[this.moreOptions.UNBLOCK_P2P].status = this.moreOptionsStatus.ENABLE;
        this.userMoreOptions[this.moreOptions.UNMUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
        this.userMoreOptions[this.moreOptions.MUTE_NOTIFICATION].status = this.moreOptionsStatus.DISABLED;
      }
      return this.userMoreOptions;
    }
  }

  /**
   * Emit the avatar click event.
   */
  emitAvatarClick(type: number): void {
    this.onAvatarClick.emit(type);
  }

  /**
   * Emit the new user journey start event.
   */
  newJourney(): void {
    this.onNewJourney.emit();
  }

  /**
   * Emit the chat expand event.
   */
  emitChatDetail(): void {
    this.onChatDetailToggle.emit(this.isChatDetailExpanded);
  }

  /**
   * Remove the key value pipe sorting.
   */
  returnZero(): number {
    return ZERO;
  }

  /**
   * Remove the unread dot from the detail.
   */
  removeUnreadDot(): void {
    this.isUnreadDot = false;
  }

  /**
   * Return when image is loaded.
   */
  onImageLoad(image: any): void {
    image.loaded = true;
  }

  /**
   * Retry when failed to get the messages.
   */
  retryFetchMessages(topicId: string): void {
    this.onRetryFetchMessages.emit(topicId);
  }

  /**
   * Retry when failed to get the members.
   */
  retryMembers(topicId: string): void {
    this.onRetryMembers.emit(topicId);
  }

  /**
   * Get the system messages.
   */
  getSystemMsg(msg): string {
    let systemMsg = '';
    switch (msg?.head?.system_message) {
      case this.systemMsgConstant.GROUP_CREATE:
        if (msg.from !== this.isMeTopic) {
          const member = this.chatDetail.topicDetail.userDesc(msg.from);
          systemMsg = (!member?.public?.misc?.deleted ? member?.public?.fn + ' ' + member?.public?.ln + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key1 : this.samvaadLanguageText?.samvaad_system_msg?.key14);
        } else {
          systemMsg = this.samvaadLanguageText?.samvaad?.key1 + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key1;
        }
        break;
      case this.systemMsgConstant.MEMBERS_REMOVE:
        if (msg.from !== this.isMeTopic) {
          const member = this.chatDetail.topicDetail.userDesc(msg.from);
          systemMsg = (!member?.public?.misc?.deleted ? member?.public?.fn + ' ' + member?.public?.ln : this.samvaadLanguageText?.samvaad?.key31);
        } else {
          systemMsg = this.samvaadLanguageText?.samvaad?.key1;
        }
        systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key2;
        if (msg?.content?.targets && this.searchForMeInTargets(msg?.content?.targets)) {
          systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad?.key1;
        } else {
          const member = this.chatDetail.topicDetail.userDesc(msg.content?.targets ? msg.content?.targets[ZERO] : '');
          const fromMember = this.chatDetail.topicDetail.userDesc(msg.from);
          if (msg.from !== this.isMeTopic && member?.public?.misc?.deleted) {
            if(fromMember?.public?.misc?.deleted) {
              systemMsg = this.samvaadLanguageText?.samvaad_system_msg?.key16;
            } else {
              systemMsg = fromMember?.public?.fn + ' ' + fromMember?.public?.ln + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key27;
            }
            return systemMsg;
          } else if (msg.from === this.isMeTopic && member?.public?.misc?.deleted) {
            systemMsg = this.samvaadLanguageText?.samvaad_system_msg?.key15;
            return systemMsg;
          }
          systemMsg = systemMsg + ' ' + member?.public?.fn + ' ' + member?.public?.ln;
        }
        if (msg.content?.targets && msg.content?.targets?.length > ONE) {
          systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad?.key27 + ' ' + (msg.content?.targets.length - ONE) + ' ' + this.samvaadLanguageText?.samvaad?.key28;
        }
        break;
      case this.systemMsgConstant.MEMBERS_ADD:
        // eslint-disable-next-line no-case-declarations
        const from = this.checkUserIdentifier(msg.from);
        systemMsg = from + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key3;
        if (msg.content?.targets && msg.content?.targets?.length < TWO) {
          const firstTarget = this.checkUserIdentifier(msg.content?.targets ? msg.content?.targets[ZERO] : '');
          if (firstTarget === 'Admin') {
            systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key26;
          } else {
            systemMsg = systemMsg + ' ' + firstTarget;
          }
        }

        if (msg.content?.targets && msg.content?.targets?.length < THREE && msg.content?.targets?.length > ONE) {
          const firstTarget = this.checkUserIdentifier(msg.content?.targets ? msg.content?.targets[ZERO] : '');
          const secondTarget = this.checkUserIdentifier(msg.content?.targets ? msg.content?.targets[ONE] : '');
          if (firstTarget === 'Admin' || secondTarget === 'Admin') {
            systemMsg = systemMsg + ' ' + (msg.content?.targets.length) + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key25;
          } else {
            systemMsg = systemMsg + ' ' + firstTarget + ' ' + this.samvaadLanguageText?.samvaad?.key27 + ' ' + secondTarget;
          }
        }

        if (msg.content?.targets && msg.content?.targets?.length > TWO) {
          const isMe = this.searchForMeInTargets(msg?.content?.targets)
          const firstTarget = this.checkUserIdentifier(msg.content?.targets ? msg.content?.targets[ZERO] : '');
          const length = msg.content?.targets.length;
          if (firstTarget === 'Admin') {
            systemMsg = systemMsg + ' ' + length + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key25;
          } else {
            systemMsg = systemMsg + ' ' + (isMe ? this.samvaadLanguageText?.samvaad?.key1 : firstTarget) + ' ' + this.samvaadLanguageText?.samvaad?.key27 + ' ' + (length - 1) + ' ' + this.samvaadLanguageText?.samvaad?.key28;
          }
        }
        break;
      case this.systemMsgConstant.MEMBER_LEFT:
        if (msg.content?.target === this.isMeTopic) {
          systemMsg = this.samvaadLanguageText?.samvaad?.key1;
        } else {
          const member = this.chatDetail.topicDetail.userDesc(msg.content?.target);
          if (member?.public?.misc?.deleted) {
            systemMsg = this.samvaadLanguageText?.samvaad_system_msg?.key20;
            return systemMsg;
          }
          systemMsg = member?.public?.fn + ' ' + member?.public?.ln;
        }
        systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key4;
        break;
      case this.systemMsgConstant.GROUP_DESC_UPDATE:
        if (msg.content?.target === this.isMeTopic) {
          systemMsg = this.samvaadLanguageText?.samvaad?.key1;
        } else {
          const member = this.chatDetail.topicDetail.userDesc(msg.content?.target);
          systemMsg = (!member?.public?.misc?.deleted ? member?.public?.fn + ' ' + member?.public?.ln : this.samvaadLanguageText?.samvaad?.key31);
        }
        if (msg?.content?.photo) {
          systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key5;
        } else {
          systemMsg = systemMsg + ' ' + this.samvaadLanguageText?.samvaad_system_msg?.key6 + ' "' +
            msg?.content?.oldName + '"' + ' ' + this.samvaadLanguageText?.samvaad?.key26 + ' ' + ' "' + msg?.content?.name + '"';
        }
        break;
      default:
        systemMsg = '';
        break;
    }
    return systemMsg;
  }

  /**
   * Get the user system msg identifier.
   */
  checkUserIdentifier(topic): string {
    if (topic === this.isMeTopic) {
      return 'You';
    } else {
      const member = this.chatDetail.topicDetail.userDesc(topic);
      return member?.public?.misc?.deleted ? 'Admin' : member?.public?.fn + ' ' + member?.public?.ln;
    }
  }

  /**
   * Search for the You.
   */
  searchForMeInTargets(targets: string[]): boolean {
    return targets.some(val => val === this.isMeTopic);
  }

  /**
   * Emit the attachment click event.
   */
  emitAttachmentClick(): void {
    this.onAttachmentClick.emit(this.chatDetail.topic);
  }

  /**
   * Unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
     this.emitOnLeave();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
