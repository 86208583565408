import { Pipe, PipeTransform } from '@angular/core';
/**
 * This pipe is used for display the newtag image in explore section.
 */
@Pipe({
  name: 'newTagImage'
})
export class NewTagImagePipe implements PipeTransform {

  transform(value: string, args: NewTags[]): unknown {
    let returnValue = '';
    if (args.length > 0) {
      const hasNewTag = args.find(obj => obj.identifier === value);
      if (hasNewTag !== undefined) {
        returnValue = hasNewTag.images.hamburger_explore_menu;
      } else {
        returnValue = '';
      }
    }
    return returnValue;
  }

}

/**
 * This is only for new tags image.
 */
export class NewTags {
  identifier: string;
  badge_text: string;
  images: {
    bottom_navigation_bar: string;
    hamburger_explore_menu: string;
  };
}
