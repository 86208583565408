<div *ngIf="isConfirmModal" id="SubscribeBoxModal" class="modal fade success-popup" style="z-index: 999999;" tabindex="-1" role="dialog"
  [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <div class="d-flex justify-content-end align-items-center pt-3 px-3">
          <img class="cursor-pointer" src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="cross"
            (click)="onHidden()">
        </div>
      </div>
      <div class="modal-body rounded bg-white position-relative px-3">
        <div class="text-center">
          <img [src]="infoSrc" alt="banner" [width]="srcWidth">
          <h3 class="heading-4 mt-3">
            {{heading}}
          </h3>
          <p class="secondary-paragraph my-2">
            <span class="color-grey-700">
              {{paragraph}}
            </span>
          </p>
        </div>
      </div>
      <div class="modal-footer pb-4 px-3 d-flex text-center mt-3 w-100 justify-content-center">
        <button type="button" class="btn-design outline small" (click)="onHidden()">
          {{btnCancel}}
        </button>
        <button type="button" class="btn-design filled small bg-error ml-md-3 px-3" (click)="submit()">
          {{btnText}}
        </button>
      </div>
    </div>
  </div>
</div>
