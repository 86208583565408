/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { PostApiService } from '../post-api.service';
import { OnboardingService } from '../../dataservices/onboarding/onboarding.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { GetService } from '../get.service';
import { NewLanguageService } from '../new-language/new-language.service';

/**
 * Constant imports.
 */
import { CONSENT_TYPE, DYNAMIC_FORM_IDENTIFIERS, IS_CONSENT_GIVEN, NUMBERS, WEBINAR_CONSENT_POPUP_OBJECT } from '../../constants/app.constants';

/**
 * Modal imports.
 */
import { CountryListData, DynamicFormResponse } from '../../onboarding/interface/apiResponse.model';
import { ConsentFormSubmit, CountryCodes, DynamicFormControl, FormValue, Label } from '../../onboarding/interface/global.model';

/**
 * Rxjs imports.
 */
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

/**
 * Consent service to invoke the dynamic consent form.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsentService implements OnDestroy {

  /**
   * Labels for the dynamic form.
   * Form Data from the api.
   * Check if consent form is open/close.
   * Check if consent is given or not.
   * Country codes for dynamic form
   * Check if consent button is disabled or not.
   */
  public label: Label;
  public formData: DynamicFormControl[];
  public isConsentFormOpen = false;
  public isConsentGiven = false;
  public countryCodes: CountryCodes[] = [];
  public productTypeId: number;
  public isConsentConfirmationShown = false;
  public consentConfirmationFormData = {};
  private isRegistered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public openConsentFormWebinarId = NUMBERS.ZERO;

  /**
   * Subscription to add the subscriber.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * necessary instance created.
   */
  constructor(
    private getApiService: GetService,
    private fcs: FunCollectionService,
    private onboardingService: OnboardingService,
    private postSer: PostApiService,
    private mls: NewLanguageService
  ) { }

  /**
   * Open the consent dynamic form
   */
  openConsentForm(productType: string, productTypeId: string, formType: string, onlyLabels: number): void {
    this.productTypeId = Number(productTypeId);
    const getDynamicSub = this.getApiService.getDynamicFormData(productType, productTypeId, formType, onlyLabels.toString())
      .subscribe((res: DynamicFormResponse) => {
        if (res.status === 1 && res.data.formControl.length > 0) {
          this.formData = res.data.formControl;
          this.label = res.data.label;
          const isCountryCode = this.formData.find(obj => obj.name === DYNAMIC_FORM_IDENTIFIERS.CODE);
          if (isCountryCode) {
            this.getCountryList();
          } else {
            this.isConsentFormOpen = true;
          }
        } else {
          this.isConsentGiven = false;
          this.fcs.errorHandeler(res);
        }
      }, (err) => {
        this.isConsentGiven = false;
        this.fcs.errorHandeler(err);
      });
    this.subscriptions.add(getDynamicSub);
  }

  /**
   * Get Country list according to the IP address.
   */
  getCountryList(): void {
    const getCountryListSub = this.onboardingService.getCountryList().subscribe((res: CountryListData) => {
      if (res.status === 1) {
        this.countryCodes = res.data.country_list.map(res => ({ id: res.id, code: res.country_code, name: res.country }));
        this.isConsentFormOpen = true;
      } else {
        this.isConsentGiven = false;
        this.fcs.errorHandeler(res);
      }
    }, (err) => {
      this.isConsentGiven = false;
      this.fcs.errorHandeler(err);
    });
    this.subscriptions.add(getCountryListSub);
  }

  /**
   * Submit the consent.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consentSubmit(form: any, productType: string, productTypeId: string, consent: number, consentType: string, pageType: string): void {
    this.postSer.setConsentButtonStatus(true);
    const formData: ConsentFormSubmit[] = this.formData.map(control => ({
      name: control.name,
      value: this.getFormValue(control, form)
    }));
    const consentFormSub = this.postSer.consentFormSubmit(formData, productType, productTypeId, consent.toString(), consentType, pageType)
      .subscribe(res => {
        if (res.status === 1) {
          this.isConsentGiven = true;
          this.isRegistered.next(true);
        } else {
          this.fcs.errorHandeler(res);
        }
        this.isConsentFormOpen = false;
      }, (err) => {
        this.fcs.errorHandeler(err);
        this.isConsentFormOpen = false;
      });
    this.subscriptions.add(consentFormSub);
  }

  /**
   * Get the form value according to the key.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormValue(control: DynamicFormControl, form: any): FormValue {
    const value: FormValue = {
      text: form.formValue[control.name]
    };
    if (control.name === DYNAMIC_FORM_IDENTIFIERS.CODE) {
      value.code = form.code[0].code;
    }
    return value;
  }

  /**
   * Open the consent confirmation form
   */
  openConsentConfirmationForm(productType: string, productTypeId: string, formType: string, onlyLabels: number): void {
    const getConsentConfirmationPopupSub = this.getApiService.getDynamicFormData(productType, productTypeId, formType, onlyLabels.toString())
      .subscribe((res: DynamicFormResponse) => {
        if (res.status === 1 && res.data.label) {
          this.label = res.data.label;
          this.isConsentConfirmationShown = true;
          this.consentConfirmationFormData = {
            param: [
              { 'key': 'title', 'value': this.label['pop_up_title'] },
              { 'key': 'description', 'value': this.label['pop_up_description'] }
            ],
            process: [
              {
                'type': 'button',
                'param': [
                  { 'key': 'label', 'value': this.mls.languageText.webinar_detail.key28 }
                ],
                'class': 'btn-design outline small',
                'target': { 'type': WEBINAR_CONSENT_POPUP_OBJECT.SHOW_WEBINAR_CONSENT_POPUP_KEY, 'url': '', 'id': formType }
              },
              {
                'type': 'button',
                'param': [
                  { 'key': 'label', 'value': this.mls.languageText.webinar_detail.key27 }
                ],
                'class': 'btn-design filled small',
                'target': { 'type': 'close', 'url': '', 'id': '' }
              }
            ]
          };
        } else {
          this.isConsentConfirmationShown = false;
          this.fcs.errorHandeler(res);
        }
      }, (err) => {
        this.isConsentConfirmationShown = false;
        this.fcs.errorHandeler(err);
      });
    this.subscriptions.add(getConsentConfirmationPopupSub);
  }

  /**
   * Cancel request for the consent.
   */
  giveConsentWithoutForm(productType: string, productTypeId: string, consent: number, consentType: string, pageType: string): void {
    const consentFormDec = this.postSer.consentFormSubmit([], productType, productTypeId, consent.toString(), consentType, pageType)
      .subscribe(res => {
        if (res.status === 1) {
          this.setConsentGiveStatus(consent === IS_CONSENT_GIVEN.YES ? true : false);
          this.setRegisteredButtonStatus(true);
        } else {
          this.setConsentGiveStatus(consent === IS_CONSENT_GIVEN.YES ? false : true);
          this.fcs.errorHandeler(res);
        }
        this.isConsentFormOpen = false;
        this.isConsentConfirmationShown = false;
      }, (err) => {
        this.setConsentGiveStatus(consent === IS_CONSENT_GIVEN.YES ? false : true);
        this.fcs.errorHandeler(err);
        this.isConsentFormOpen = false;
        this.isConsentConfirmationShown = false;
      });
    this.subscriptions.add(consentFormDec);
  }

  /**
   * Set the consent iin case of consent is already given.
   */
  setConsentGiveStatus(consent: boolean): void {
    if (consent && !this.isConsentFormOpen) {
      this.isConsentGiven = true;
    } else {
      this.isConsentGiven = false;
    }
  }

  /**
   * Get the registered Button flag as observable.
   */
  getRegisteredButtonStatus(): Observable<boolean> {
    return this.isRegistered.asObservable();
  }

  /**
   * Used to set the registered Button flag status true/false.
   */
  setRegisteredButtonStatus(status: boolean): void {
    this.isRegistered.next(status);
  }

  /**
   * Set the implicit consent labels.
   */
  openImplicitConsent(label): void {
    this.isConsentConfirmationShown = true;
    this.consentConfirmationFormData = {
      param: [
        { 'key': 'title', 'value': label['consent_toggle_popup_title'] },
        { 'key': 'description', 'value': label['consent_toggle_popup_body'] }
      ],
      process: [
        {
          'type': 'button',
          'param': [
            { 'key': 'label', 'value': this.mls.languageText.webinar_detail.key27 }
          ],
          'class': 'btn-design outline small',
          'target': { 'type': 'close', 'url': '', 'id': '' }
        },
        {
          'type': 'button',
          'param': [
            { 'key': 'label', 'value': this.mls.languageText.webinar_detail.key28 }
          ],
          'class': 'btn-design filled small',
          'target': { 'type': WEBINAR_CONSENT_POPUP_OBJECT.SHOW_WEBINAR_CONSENT_POPUP_KEY, 'url': '', 'id': CONSENT_TYPE.IMPLICIT_CONSENT }
        }
      ]
    };
  }

  /**
   * Clear the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
