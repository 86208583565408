/**
 * Angular imports.
 */
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EVENT_NAME } from '../../../analytics-event/analytics.constant';

/**
 * Constant imports.
 */
import { AUDIO_FADE_DELAY, AUDIO_SEEK_BARS_COLOR, CONTROL_PANEL_ELEMENTS, PLAYER_STATUS, PRODUCT_TYPE_CONSTANT, SCREEN_NAME } from '../../../constants/app.constants';

/**
 * Service imports.
 */
import { DqVideoPlayerService } from '../dq-video-player.service';
import { AnalyticsEventService } from './../../../analytics-event/analytics-event.service';
import { NewLanguageService } from './../../new-language/new-language.service';

/**
 * Modal imports.
 */
import { AnalyticsInterface, EventDataKey } from '../../../analytics-event/analytics.model';
import { Subscription } from 'rxjs';
import { DataStorageService } from '../../data-storage.service';

/**
 * Audio player with mini view.
 */
@Component({
  selector: 'app-dq-audio-player',
  templateUrl: './dq-audio-player.component.html',
  styleUrls: ['./dq-audio-player.component.scss']
})
export class DqAudioPlayerComponent implements OnDestroy {

  private subscriptions: Subscription = new Subscription();
  /**
   * Config for the audio player.
   */
  public audioConfig = {
    fadeDelay: AUDIO_FADE_DELAY,
    addSeekBar: true,
    controlPanelElements: [CONTROL_PANEL_ELEMENTS.PLAY_PAUSE],
    seekBarColors: {
      base: AUDIO_SEEK_BARS_COLOR.BASE,
      buffered: AUDIO_SEEK_BARS_COLOR.BUFFERED,
      played: AUDIO_SEEK_BARS_COLOR.PLAYED
    },
    castReceiverAppId: ''
  };

  /**
   * Necessary instances.
   */
  constructor(
    public dqVideoPlayerService: DqVideoPlayerService,
    private analyticsEventService: AnalyticsEventService,
    private mls: NewLanguageService,
    private router: Router,
    public dss: DataStorageService
  ) { }
  /**
   * Calculate sqs interval.
   */
  receiveSQSInterval(data): void {
    this.dqVideoPlayerService.receiveSQSInterval(data, this.dqVideoPlayerService.audioPlayerDetail?.productType, this.dqVideoPlayerService.audioPlayerDetail?.productTypeId, this.mls.googleEventText.journal_detail.key1, this.mls.googleEventText.journal_detail.category);
  }

  /**
   * Set the audio player status.
   */
  audioPlayerStatus(status: string): void {
    if (status === PLAYER_STATUS.PLAY) {
      this.sendAnalytics(EVENT_NAME.PLAY_BUTTON_CLICK);
    } else if (status === PLAYER_STATUS.PAUSE) {
      this.sendAnalytics(EVENT_NAME.PLAYER_PAUSE_CLICK);
    }
    this.dqVideoPlayerService.audioPlayerStatus = status;
  }

  /**
   * Close the player and destroy the component.
   */
  closeMiniPlayer(): void {
    this.sendAnalytics(EVENT_NAME.PLAYER_CROSS_CLICK);
    this.dqVideoPlayerService.isAudioPlayer = false;
    this.dqVideoPlayerService.exitMiniAudioPlayer.next(true);
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, screenName = SCREEN_NAME.JOURNAL_DETAIL, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    const analyticsData: AnalyticsInterface = {
      event_name: eventName,
      product_type: PRODUCT_TYPE_CONSTANT.ARTICLE,
      product_type_id: this.dqVideoPlayerService.audioPlayerDetail?.productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(analyticsData);
  }

  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * Navigate to the detail page of playing audio.
   */
  navigateToDetail(): void {
    this.router.navigate([`/clinical/detail/${this.dqVideoPlayerService?.audioPlayerDetail?.productTypeId}`]);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
