/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpBackend } from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable, Subscription } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { LocalstorageService } from '../../auth/localstorage.service';

/**
 * JS imports.
 */
import * as crypto from 'crypto-js';
import * as jwt from '../../../assets/js/jwt.js';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';
import {LANG_ABBREVIATIONS} from '../../constants/app.constants';

/**
 * Interface imports.
 */
import { CountryListData, RequestOtpData, ResendOtpData, UniversityListData, UserBasicInfoData, UserRegisterData, VerifyMobileData, RequestBaseUrls } from '../../onboarding/interface/apiResponse.model';

/**
 * API service
 */
@Injectable({
  providedIn: 'root'
})
export class PublicAPIService implements OnDestroy {
  /**
   * Hold flag if user directly come to the userdetail page.
   */
  public directToUserdetails = false;

  public client_ip_address: any = '';
  public ddl_data: any = {
    placeholderL: 'Ex. +91',
    single_select: true,
    enable_search: true,
    selected_data: [],
    list_data: [],
    identifier: 'country',
    showCheckbox: false,
    enableCheckAll: false,
    disabled: false,
    enableFilterSelectAll: false
  };
  public deviceType = '';
  public mobileType = '';
  public country_list: any[];
  public lang = 'en';
  /**
   * Device type.
   */
  public DEVICE_TYPE = environment.deviceType;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary Instances.
   */
  constructor(
    private localstorage: LocalstorageService,
    private httpClient: HttpClient,
    public nls: NewLanguageService,
    private handler: HttpBackend
  ) {

  }

  
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${this.DEVICE_TYPE}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  recordBrowserDetails(browserFingerprintId, browserUniqueId, trackId): Observable<any> {
    const httpClient = this.createNewHttpClient();
    const url =   environment.API_URLS.record_browser_details + '/'+environment.versions.api_url_version_updated+'/open-content/record-browser-details';
    const headers = {
      Authorization: environment.appKey,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      lang: this.lang,
      udid: '',
      'Content-Type': 'application/json',
    };
    const data = {
      browserFingerprintId,
      browserUniqueId,
      trackId
    };
    const body = JSON.stringify(data) ;
    return httpClient.post<any>( url , body,
      {
        headers: headers
      });
  }

  createNewHttpClient(): HttpClient {
    return new HttpClient(this.handler);
  }

   /**
   * unsubscribe the subscription.
   */
   ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


}
