/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../dataservices/onboarding/onboarding.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OpenAccessService } from '../public-module/open-access.service';
import { AuthService } from './../auth/auth.service';
import { NewLanguageService } from './../../app/dataservices/new-language/new-language.service';

/**
 * rxjs imports
 */
import { Subject } from 'rxjs';
/**
 * Constant imports
 */
import { NUMBERS, OPEN_ACCESS } from '../constants/app.constants';
/**
 * Interface imports
 */
import { openArticle } from './interface/global.model';




/**
 * Onboarding Component
 */
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  public flag = false;
  public activateTab: string;
  public displayObj = {
    isShow: false,
    display_section: 'updatedModal2.0',
    data: [],
    errObj: []
  };

  /**
   * notifier subject to remove data stream
   */
  private notifier$ = new Subject();
  public showElement = false;
  codeDropdownSettings = {
    singleSelection: true,
    enableSearchFilter: true,
    showCheckbox: false,
    classes: '',
    enableCheckAll: false,
    enableFilterSelectAll: false,
    disabled: false,
    single_select: true,
    enable_search: true,
    position: 'top',
    autoPosition: false,
    tagToBody: true
  };
  selectedCode = [];
  countryCodes = [
    { id: 0, name: 'zero', value: 'zero' },
    { id: 1, name: 'one', value: 'one' },
    { id: 2, name: 'two', value: 'two' },
    { id: 3, name: 'three', value: 'three' },
    { id: 4, name: 'four', value: 'four' },
    { id: 5, name: 'five', value: 'five' },
    { id: 6, name: 'six', value: 'six' },
  ];
  /**
   * object for passing open article data
   */
      public openArticle: openArticle;
  /**
   * Necessary instances.
   */
  constructor(
    private nls: NewLanguageService,
    private router: Router,
    private deviceSer: DeviceDetectorService,
    public onboardingSer: OnboardingService,
    public oas : OpenAccessService,
    private authSer: AuthService) {
      this.onboardingSer.setLanguage();
    if (this.authSer.isAuthenticated()) {
      this.router.navigateByUrl('/web');
      return;
    }
  }


  /**
   * ngOnInit
   */
  ngOnInit(): void {
    document.querySelector('body')?.classList?.add('onboarding-upgraded');


    if (this.deviceSer.isMobile() == true) {
      this.onboardingSer.deviceType = 'mobile';
    } else if (this.deviceSer.isTablet() == true) {
      this.onboardingSer.deviceType = 'tablet';
    } else {
      this.onboardingSer.deviceType = 'desktop';
    }

    this.activeTab('active-tab1');
    /**
     * Remove open access key in local storage when user login through normal flow.
     */
    localStorage.removeItem('fromOpenAccess')
    this.nls.userLoginFlow =  0;
  }

  activeTab(item: string): void {
    this.activateTab = item;
  }


  /**
   * Method to fetch the list of open articles from the OpenAccessService.
   */
    getArticleList(): void {
      this.oas.getOpenArticleList(OPEN_ACCESS.productlist.article).subscribe(
        (response: any) => {
          const res = response;
          if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
            this.openArticle = res.data.response.articles;
          }
        }
      );
    }


  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
    document.querySelector('body')?.classList?.remove('onboarding-screen');
    document.querySelector('body')?.classList?.remove('onboarding-upgraded');
  }
}
