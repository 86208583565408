<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div  class="position-relative px-3 px-sm-4 py-sm-5 pb-3 pt-5">
      <a class="back-page position-absolute" [appOnboardingEventTracker]='{"category":mls.googleEventText.usertype_screen.category,"action":mls.googleEventText.usertype_screen.key1,"product_type":"", "product_id":""}' (click)="back_screen()"><img src="assets/images/onboarding/back.svg" width="20px" /></a>
      <span (click)="skipProfession()" [appOnboardingEventTracker]='{"category":mls.googleEventText.usertype_screen.category,"action":mls.googleEventText.usertype_screen.key2,"product_type":"", "product_id":""}' class="position-absolute cursor-pointer" *ngIf="profession_data?.is_skippable==1">{{mls?.languageText?.usertype_screen?.key1}} <span *ngIf="mls?.is_lskey_display==1">usertype_screen.key1</span></span>
      <div>
        <h5 class="mb-1 heading-4">
          <span class="color-deep-green">{{mls?.languageText?.usertype_screen?.key8}}
          <span *ngIf="mls?.is_lskey_display==1">(usertype_screen?.key8)</span></span>
        </h5>
        <!-- form -->
        <div class="my-3 clearfix">
          <a class="form-control cursor-pointer utype-list user-type-card" *ngFor="let prof of profession_data?.data?.profession; let idx=index" (click)="selectProfession(prof,idx)" [appOnboardingEventTracker]='{"category":mls.googleEventText.usertype_screen.category,"action":mls.googleEventText.usertype_screen.key4,"product_type":"", "product_id":""}'>
            <span class="card-type secondary-paragraph">
              <img *ngIf="prof?.type === 'doctor'" src="assets/themes/icons/user-doc.svg" alt="Doctor" />
              <img *ngIf="prof?.type === 'dentist'" src="assets/themes/icons/user-dentist.svg" alt="Dentist" />
              <img *ngIf="prof?.type === 'student'" src="assets/themes/icons/user-student.svg" alt="Student" />
              {{prof?.value}}
            </span>
            <svg id="Layer_1" class="float-right" data-name="Layer 1" style="margin-top: 3px;" width="16px" viewBox="0 0 25 25"><defs><style>.cls-right-arrow{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><polyline class="cls-right-arrow" points="9.82 21 18.32 12.5 9.82 4"/></svg>
          </a>
        </div>
      </div>
      <p class="secondary-paragraph text-center pb-3">
        <span class="primary-small">
          {{mls?.languageText?.usertype_screen?.key3 | uppercase}}
          <span *ngIf="mls?.is_lskey_display==1">(usertype_screen.key3)</span>
        </span>
      </p>
      <div>
        <div class="invite-code-card">
          <h6 class="paragraph-bold">{{mls?.languageText?.usertype_screen?.key9}}<span *ngIf="mls?.is_lskey_display==1">(usertype_screen.key9)</span></h6>
          <p class="primary-small mt-1 mb-3">{{mls?.languageText?.usertype_screen?.key10}}<span *ngIf="mls?.is_lskey_display==1">(usertype_screen.key10)</span></p>
          <p class="primary-small error-color mt-1 mb-3" *ngIf="err_msg!=''">{{err_msg}}</p>
          <div>
            <div class="form-input" [ngClass]="{ 'error' : api_err_msg }">
              <input class="input-field" id="invitecode" type="text"
              [maxLength]="Dss.inputBoxMaxLimit"
              (keypress)="invitecodeInput($event)"
              placeholder="{{mls?.languageText?.usertype_screen?.key5}}{{(mls?.is_lskey_display==1)? '(usertype_screen.key5)':''}}"
              (keyup)="resetErrMsg($event)"/>
              <p class="help-block" *ngIf="api_err_msg">
                <span>{{api_err_msg}}</span>
              </p>
            </div>
            <button class="btn-design w-100 my-3" [disabled]="showColor === false" [ngClass]="[showColor ? 'filled' : 'filled-disabled', fcs.device_detail.is_mobile ? 'medium' : 'large']" type="button"
              (click)="inviteUser()" [appOnboardingEventTracker]='{"category":mls.googleEventText.onboarding_invite_code.category,"action":mls.googleEventText.onboarding_invite_code.key1,"product_type":"", "product_id":""}'>
              {{mls?.languageText?.usertype_screen?.key12}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-backscreenmodal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event) [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-backscreenmodal>
