<div *ngIf="!restrictContent">
<div class="article-detail">
    <div *ngIf="apiInProcess" class="text-center mt-4">
        <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post" alt="loader" />
      </div>
      <div *ngIf="!apiInProcess">
    <p class="heading-4">  {{mls.languageText.open_article.key22}} </p>
    <p class="heading-2 mt-2 article-desc "> <span class="color-grey-700 ">{{articleDetail?.title}}</span></p>
    <p class="mt-3 publish-time">
        <span class="color-grey-700 bg-color-inline">
          <img src="assets/themes/icons/public/Calender.svg" alt="">
          {{ articleDetail?.publish_date*1000 | date : 'MMM dd, yyyy' }}
          <img src="assets/themes/icons/survey_clock.svg" alt="logo" width="16" height="16" class="ml-1 mb-1">
          {{ articleDetail?.publish_date*1000 | date : ' h:mm a'}}
        </span>
      </p>

    <p class="color-grey-500 mt-3">
        <span *ngFor="let hashtag of articleDetail?.speciality">
            <span class="secondary-paragraph"><span class="color-grey-500 mr-2">#{{hashtag.name}}</span>
            </span></span>
    </p>
    <p class="mt-3">
        <img class="mr-2" src="./assets/themes/icons/public/avatars.svg" />
        <span class="paragraph">{{mls.languageText.open_article.key23}}</span>
    </p>

    <div class="paragraph new-inner-html new-inner-html-list banner-image mt-3">
        <span innerHTML="{{articleDetail?.description}}"> </span>
    </div>
    <div>

    </div>
    <div>
        <app-share-strip
            [title]="mls.languageText.open_article.key25"
            [fbText]="mls.languageText.open_article.key26"
            [twText]="mls.languageText.open_article.key27"
            [liText]="mls.languageText.open_article.key28"
            [shareUrl]="articleDetail?.share_url"
            (emitClick)="sendShareEvent($event)"></app-share-strip>
    </div>
    <div>
        <p class="heading-4 color-grey-700 mt-4">{{mls.languageText.open_article.key42}}</p>
        <div class="my-3">
            <div class="text-center signin-card cursor-pointer" (click)="navigateTologgedInArtcleDetail()">
                <div class="card-elements p-2">
                    <div>
                        <img src="assets/themes/icons/public/doctor-icon.svg" alt="doctor-icon" class="doctor-img mt-3">
                    </div>
                    <p class="heading-4 mt-3">{{mls.languageText.open_article.key40}}</p>
                    <p class="secondary-paragraph mt-2">{{mls.languageText.open_article.key41}}</p>
                    <button [pAppAnalyticsEvent]="singInRecord"
                        class="btn-design small filled h-auto cursor-pointer my-3">
                        {{mls.languageText.open_article.key8}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>

<div *ngIf="restrictContent">
<app-restricted-popup
[restrictedPopup]="restrictContent"
[restrictData]="restrictData">
</app-restricted-popup>
</div>

<app-public-skip-popup 
(loginClicked)="onCardClick()">
</app-public-skip-popup>

<div #articleloginContainer class="d-none"></div>