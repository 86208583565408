/* eslint-disable prefer-const */
/**
 * Angular imports.
 */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

/**
 * Rxjs imports.
 */
import { filter, finalize, pairwise, takeUntil } from 'rxjs/operators';

/**
 * Service imports.
 */
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { MobileWebViewCommunicationService } from '../../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';
import { OnboardingService } from '../../dataservices/onboarding/onboarding.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { LocalstorageService } from '../../auth/localstorage.service';
import { PublicFileUploadKeeperService } from '../../dataservices/public-file-upload-keeper.service';
import { SurveyHelperService } from '../../survey/survey-helper.service';
import { GetService } from '../../dataservices/get.service';

/**
 * Rxjs imports.
 */
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Constant imports.
 */
import { BRANCH_IDENTIFIER, DEVICE_TYPE, NOTIFICATION_TYPE, PRODUCT_TYPE_CONSTANT, NUMBERS, USER_NAME_REGX, SPLIT, BREAKPOINTS, SCREEN_NAME, STATUS_CODE, TARGET_TYPE, PROTOCOL, READ_KEY, TOSTR_TIME, LANG_ABBREVIATIONS, SURVEY_DEEPLINK_ACTION, ERROR_CODES, SURVEY_ERROR_CODE, DEVICES, SURVEY_ACCESS_TYPE, DEEPLINK_SURVEY, DEFAULT_LANG, SURVEY_TYPE, WEBVIEW_MOBILE_METHOD_POST, WEBVIEW_MOBILE_PARAMS, CANONICAL_DOMAIN_REGEX, CME_TYPE } from '../../constants/app.constants';
import { CME_LOCAL_STORAGE, CME_MODULE_TARGET, CME_REMARK } from '../../constants/featureConstants/cme.constants';
/**
 * Modal imports.
 */
import { CardRendering, HMargin, PostedIn, Product, SurveyData, Target, ToastrConfig, ToastrType, openAccessBranch } from '../../onboarding/interface/global.model';
import { AppearedCards } from '../../directives/intersection/appear.interface';
import { SCREEN_NAME as screenName } from '../../public-module/public-analytics/public-analytics.model';
import { GatewayAPIResponse } from '../../survey/model/survey.model';
import { WebinarContinueWatchLog } from 'src/assets/service-worker/dev/WebinarContinueWatchLog.service';



const tempGeolocation = new Subject();
const is_page_visible = new Subject();

declare let require;

const CrossStorageHub = require('cross-storage').CrossStorageHub;

/**
 * FunCollection Service
 */
@Injectable({
  providedIn: 'root'
})

export class FunCollectionService implements OnDestroy {
  public tempGeolocation = new Subject();
  public error_msg: string;
  public isErrorModalShown = false;
  public isCookie = true;
  public deviceInfo = null;
  public device_detail = { is_mobile: false, is_tablet: false, is_desktop: false };
  public displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: []
  };
  public shareImageUrl = new BehaviorSubject('');
  sharedImageUrl = this.shareImageUrl.asObservable();
  /**
   * Subject to get status and make action to withdraw request.
   */
  public getWithdrawStatus = new Subject<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public geoLocation: any;
  public page_from = '';
  public sessiontime = { 'start_time': '', 'end_time': '' };

  /**
   * Key is used to make observable for previous URL.
   */
  public previousUrl: string;

  /**
   * Collection of all render cards.
   */
  public failedCardsCollection: CardRendering[] = [];

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Notifier for maximum member count in a community
   */
  public publicCommunityMaxMemberCount$ = new Subject<boolean>();

  /**
   * Key pair value to store the productTypeId of cards.
   */
  public hRenderingCards: CardRendering[] = [];

  /**
   * Root marging for cards
   */
  public margin: HMargin = {
    ICCurrentHRootMargin: '0px',
    bannerCurrentHRootMargin: '0px',
    ncpCurrentHRootMargin: '0px',
    drugCurrentHRootMargin: '0px',
    channelCurrentHRootMargin: '0px',
    publicCommunityCurrentHRootMargin: '0px'
  };
  /**
   * notifier subject to remove data stream
   */
  private notifier$ = new Subject();
  public chatUserId = new BehaviorSubject({ uuid: '', isNotify: false });
  public isChatMaintenance = false;
  public isSamvaadMaintanceModal = false;
  public notifyGrouping = {};
  /**
   * Used to hold open access welcome screen data
   */
  public openAccessWelcomeScreenData: openAccessBranch;
  public openAccessScreenName: string;

  /**
   * pop state listener added and check if already created instance exists
   */
  private popstateListenerAdded = false;
  currentScreename: string;

  /**
   * Necessary instances.
   */
  constructor(
    private authService: AuthService,
    private jwtSer: JwtDecodeService,
    private postSer: PostApiService,
    private deviceService: DeviceDetectorService,
    protected toastr: ToastrService,
    private router: Router,
    public mls: NewLanguageService,
    private _location: Location,
    private masterService: MasterserviceService,
    public dss: DataStorageService,
    private onboardingService: OnboardingService,
    private breakpointObserver: BreakpointObserver,
    private lcs: LocalstorageService,
    private mvc: MobileWebViewCommunicationService,
    private pfks: PublicFileUploadKeeperService,
    private shs: SurveyHelperService,
    private getService: GetService,
    private webinarWatchService:WebinarContinueWatchLog
  ) {

    /**
     * Set initial value of previous url to null.
     */
    this.previousUrl = null;
    this.subscriptions.add(this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).pipe(
        pairwise())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any[]) => {
        this.previousUrl = event[0].urlAfterRedirects;
        localStorage.setItem('previousRoute', JSON.stringify(this.previousUrl));
      }));

    CrossStorageHub.init([

      // { origin: /localhost:5200$/, allow: ['get'] },
      { origin: /:\/\/(www\.)?dev.docquity.com $/, allow: ['get'] },
      { origin: /:\/\/(www\.)?docquity.com$/, allow: ['get'] },
      { origin: 'http://dev.docquity.com', allow: ['get'] },
      { origin: 'website.docquity.com/', allow: ['get'] },
      { origin: 'http://website.docquity.com/', allow: ['get'] }
    ]);
    this.subscriptions.add(is_page_visible.subscribe(data => {
      const temp = { page_from: this.page_from, is_visible: data };
      this.dss.is_browser_tab_active_action.next(temp);
    }));
    this.epicFunction();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.subscriptions.add(tempGeolocation.subscribe((data: any) => {
      this.dss.currentGeolocation = data.results[0];
    }));
    this.authService.sendLogoutRequest$.subscribe(data => {
      this.authService.logout();
      if (data.reload) {
        window.location.reload();
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mvc.deeplinkRedirect$.subscribe((data: any) => {
      if (data?.isComingFromDeeplink) {
        this.surveyDeepLinkRedirection(data.deeplinkAction, data.surveyId);
      }
    });
  }

  /**
   * Function used to store the application previous route.
   */
  public get previousRoute(): string {
    const prevRoute = JSON.parse(localStorage.getItem('previousRoute'));
    return prevRoute;
  }

  /**
   * copy Link
   */
  copyLink(text: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showSuccess(this.mls.languageText.toster.key1, this.mls.languageText.toster.key2);
  }
  /**
   *
   * @param safeurl open story
   * @param storyCode
   */
  openStory(safeurl: string, storyCode: string, viewerList: boolean): void {
    this.dss.viewStoryThroughNotificationUuid = safeurl;
    this.dss.currentStoryCode = storyCode;
    this.dss.viewListByDefault = viewerList;
    this.dss.showStoryThroughNotification.next(true);
  }
  openStoryViaUrl(storySafeUrl: string, storyCode: string, viewerList): void {
    if (storySafeUrl && storyCode) {
      this.router.navigate(['/web/list'], { queryParams: { storySafeUrl: storySafeUrl, storyCode: storyCode, viewerList: viewerList } }).catch(e => {
        this.error_connection_refused('/web/list');
      });
    }
  }

  /**
   * toster success function msg
   */
  showSuccess(msg, msg_head): void {
    if (msg_head == 'Success') {
      msg_head = this.mls.languageText.toster.key2;
    }
    this.showCustomToast({
      toastType: ToastrType.SUCCESS,
      timeout: 10000,
      message: msg,
      title: msg_head
    });

    /**
    Commenting out 1.0 success toastr
    this.toastr.success(msg, msg_head, {
      positionClass: 'toast-bottom-left',
      timeOut: 10000,
    });
    */
  }


  showWebPushNoti(payload): void {
    const additional_info = JSON.parse(payload.data?.additional_info);
    this.showCustomToast({
      toastType: ToastrType.NOTIFICATION,
      timeout: NUMBERS.TEN_THOUSAND,
      message: payload.data.message,
      title: { title: payload.data.title, identi: payload.data.identifier, id: payload.data.target, img: payload.data.notification_pic, url: payload.data.target_url, isSamvaadNotify: payload?.data?.isSamvaadNotify, additional_info: additional_info }
    });
  }

  /**
   * Close all notifications.
   */
  closeNotification(): void {
    this.toastr.clear();
  }

  /**
   * toster error function msg
   */
  showError(msg, msg_head): void {
    this.showCustomToast({
      toastType: ToastrType.ERROR,
      timeout: TOSTR_TIME,
      message: msg,
      title: msg_head
    });

    /*
    Commenting out 1.0 error toastr
    this.toastr.error(msg, msg_head, {
      positionClass: 'toast-bottom-left',
      timeOut: TOSTR_TIME,
    });
    */
  }

  showCustomToast(config: ToastrConfig): void {
    const toastRef = this.toastr.show(config.message, config.title, {
      tapToDismiss: false,
      toastClass: '',
      positionClass: config.toastType === ToastrType.NOTIFICATION ? 'toast-bottom-right' : 'toast-bottom-left',
      timeOut: config.timeout,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toastButtons: config.toastButtons
    }, config.toastType);
    toastRef.onAction.subscribe((actionCtaClicked: string) => {
      if (actionCtaClicked === 'primary') {
        //TO DO - Event notification for primary CTA click
      }
      if (actionCtaClicked === 'secondary') {
        //TO DO - Event notification for secondary CTA click
      }
      this.toastr.clear(toastRef.toastId);
    });
  }

  /**
   * add connection
   */
  connectionAdd(requestee_custom_id, navigateToProfile = false): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    this.subscriptions.add(this.postSer.userConnection(9, requestee_custom_id, 'add').subscribe(data => {
      if (data.status == 1) {
        if (!navigateToProfile) {
          this.showSuccess(data.msg, this.mls.languageText.toster.key2);
        } else {
          this.navigateToUserProfie(requestee_custom_id);
        }
      } else if (data.status == 0) {
        this.errorHandeler(data);
      }
    },
      () => {
        // something went wrong
      }));

  }

  /**
   * remove animation for mobile
   */
  epicFunction(): boolean {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.browser == 'Safari') {
      let x = this.deviceInfo.browser_version;
      x = x.split('.');
      // tslint:disable-next-line: radix
      if (parseInt(x[0]) < 13) {
        this.dss.browser_not_support_msg = 'Chat is not supported on this version. Please update your browser to use Docquity chat.';
      }
    }
    if (this.dss.browser_not_support_version_list[this.deviceInfo.browser]?.indexOf(this.deviceInfo.browser_version) != -1) {
      this.dss.browser_not_support_msg = 'Chat is not supported on this version. Please update your browser to use Docquity chat.';
    }
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    this.device_detail.is_mobile = isMobile;
    this.device_detail.is_tablet = isTablet;
    this.device_detail.is_desktop = isDesktop;

    if (isMobile == true || isTablet == true) {
      return true;
    } else {
      return false;
    }

  }

  /**
   * navigate to user profile
   */
  navigateToUserProfie(customeId): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (!this.isStringEmpty(customeId)) {
      this.router.navigateByUrl('webprofile/view/info/' + customeId).catch(() => {
        this.error_connection_refused('webprofile/view/info/' + customeId);
      });
    }
    else {
      if (localStorage.getItem('loggedInUserCustomId')) {
        this.router.navigateByUrl('webprofile/view/info/' + localStorage.getItem('loggedInUserCustomId'));
      }
    }
  }

  /**
   * navigate to user profile
   */
  navigateToSessionDetail(id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (id != '' && id != undefined && id != 0) {
      this.router.navigateByUrl('event/session-details/' + id);
    }
  }

  /**
   * navigate to user profile edit basic
   */
  navigateToUserEditInfo(customeId): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('webprofile/edit/info/' + customeId).catch(() => {
      this.error_connection_refused('webprofile/edit/info/' + customeId);
    });
  }

  /**
   * navigate to user basic profile
   */
  navigateToEditProfileBasic(customeId): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('webprofile/edit/basic/' + customeId).catch(() => {
      this.error_connection_refused('webprofile/edit/basic/' + customeId);
    });
  }

  /**
   * navigate to setting
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToSetings(): any {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('webprofile/setting/language').catch(() => {
      this.error_connection_refused('webprofile/setting/language');
    });
  }


  /**
   * navigate to user profile
   */
  navigateToUserProfie_spe_sec(customeId, section): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/web/profile/' + customeId + '/' + section).catch(() => {
      this.error_connection_refused('/web/profile/' + customeId + '/' + section);
    });
  }

  /**
   * navigate To Speaker Detail
   */
  navigateToSpeakerDetail(id): void {
    this.router.navigateByUrl('doctalk/speakers/detail/' + id).catch(() => {
      this.error_connection_refused('doctalk/speakers/detail/' + id);
    });
  }

  /**
   * navigate to speaker list
   */
  navigateToSpeakerList(): void {
    this.router.navigateByUrl('doctalk/speakers').catch(() => {
      this.error_connection_refused('doctalk/speakers');
    });
  }

  /**
   * feed cdetail page
   */
  navigateToFeedDetail(feed_id, ecId?: number): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (feed_id != 0 && feed_id != '' && feed_id != null) {

      if (this.device_detail.is_desktop == false) {

        this.router.navigateByUrl('/web/detail/' + feed_id).catch(() => {
          this.error_connection_refused('/web/detail/' + feed_id);
        });
      } else {
        const feedDetailObj = {
          is_show: true,
          feed_id: feed_id,
          mediaIdx: 0,
          ecId
        };
        this.dss.showFeedDetailInModal.next(feedDetailObj);
      }


    } else {
      this.router.navigateByUrl('/web/list').catch(() => {
        this.error_connection_refused('/web/list');
      });
    }
  }

  /**
   * close feed detail
   */
  closeFeedDetailModal(): void {
    const feedDetailObj = {
      is_show: false,
      feed_id: 0,
      mediaIdx: 0
    };
    this.dss.showFeedDetailInModal.next(feedDetailObj);
  }

  /**
   * navigate to grand round
   */
  navigateToHome(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/web/list').catch(() => {
      this.error_connection_refused('/web/list');
    });
  }

  /**
   * navigate to grand round
   */
  navigateToPatientList(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/survey/patient-list').catch(() => {
      this.error_connection_refused('/survey/patient-list');
    });
  }

  /**
   * Navigate to Editor's choice detail.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToEditorsChoice(editorChoice_id): any {
    if (this.masterService.isOnline === false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.router.navigateByUrl('/editor-choice/' + editorChoice_id).catch(() => {
      this.error_connection_refused('/editor-choice/' + editorChoice_id);
    });
  }

  /**
   * navigate to referral section
   */
  navigateToReferal(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('refer-and-earn/referral').catch(() => {
      this.error_connection_refused('refer-and-earn/referral');
    });
  }

  /**
   * get random string
   */
  makeid(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * make numeric id
   */
  makenumericid(): string {
    let text = '';
    const possible = '0123456789';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * navigate to channel detail
   */
  navigateToChannelDetail(target_id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (target_id != 0) {
      // const chid = target_id.toString();
      //  this.router.navigateByUrl('/channel/detail/'+btoa(chid));
      this.router.navigateByUrl('/channel/detail/' + target_id).catch(() => {
        this.error_connection_refused('/channel/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('channel/list').catch(() => {
        this.error_connection_refused('channel/list');
      });
    }
  }

  /**
   * navigate to channel list
   */
  navigateToChannel(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    {
      this.router.navigateByUrl('channel/list').catch(() => {
        this.error_connection_refused('channel/list');
      });
    }
  }

  /**
   * navigate to referrel
   */
  navigateToRefetal(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('refer-and-earn/referal').catch(() => {
      this.error_connection_refused('refer-and-earn/referal');
    });
  }

  /**
   * navigate to cme detail
   */
  navigateToCmeDetail(target_id=0): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (target_id !== 0 && target_id != null) {      
      this.router.navigateByUrl('/cme/detail/' + target_id).catch(() => {
        this.error_connection_refused('/cme/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/cme/list').catch(() => {
        this.error_connection_refused('/cme/list');
      });
    }
  }

  /**
   * navigate To Cme
   */
  navigateToCme(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.router.navigateByUrl('/cme/list').catch(() => {
      this.error_connection_refused('/cme/list');
    });
  }

  /**
   * navigate to vault
   */
  navigateToVault(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.router.navigateByUrl('/cme/my-vault').catch(() => {
      this.error_connection_refused('/cme/my-vault');
    });
  }

  /**
   * navigate To User Info
   */
  navigateToUserInfo(custom_id): void {
    this.router.navigateByUrl('/webprofile/view/info/' + custom_id).catch(() => {
      this.error_connection_refused('/webprofile/view/info/' + custom_id);
    });
  }

  /**
   * navigate to user post
   */
  navigateToUserPosts(custom_id): void {
    this.router.navigateByUrl('/webprofile/view/posts/' + custom_id).catch(() => {
      this.error_connection_refused('/webprofile/view/posts/' + custom_id);
    });
  }

  /**
   * navigate to journal detail
   */
  navigateToJournalDetail(target_id, ecId?: number): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    if (target_id != 0) {
      this.router.navigateByUrl(`/clinical/detail/${target_id}/${ecId}`).catch(() => {
        this.error_connection_refused('/clinical/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/clinical/list').catch(() => {
        this.error_connection_refused('/clinical/list');
      });
    }
  }

  /**
   * navigate to link
   */
  navigateToLink(targetUrl): void {
    window.open(targetUrl, '_blank');
  }

  /**
   * navigate to doctalk list
   */
  navigateToDoctalk(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/doctalk/list').catch(() => {
      this.error_connection_refused('/doctalk/list');
    });
  }

  /**
   * navigate to doctalk detail
   */
  navigateToDoctalkDetail(id, type): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/doctalk/detail/' + id + '/' + type).catch(() => {
      this.error_connection_refused('/doctalk/detail/' + id + '/' + type);
    });
  }

  /**
   * navigate To Notification Doctalk Detail
   */
  navigateToNotificationDoctalkDetail(id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/doctalk/detail/' + id + '/24').catch(() => {
      this.error_connection_refused('/doctalk/detail/' + id + '/24');
    });
  }

  /**
   * navigate To Notification Series Detail
   */
  navigateToNotificationSeriesDetail(id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (!id) {
      this.router.navigateByUrl('doctalk/viewall/doctalks_series').catch(() => {
        this.error_connection_refused('doctalk/viewall/doctalks_series');
      });

    } else {
      this.router.navigateByUrl('/doctalk/detail/' + id + '/36').catch(() => {
        this.error_connection_refused('/doctalk/detail/' + id + '/36');
      });
    }

  }

  /**
   * navigate to suggestion
   */
  navigateToConnection(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/connection/suggestion').catch(() => {
      this.error_connection_refused('/connection/suggestion');
    });
  }

  /**
   * navigate to connection request
   */
  navigateToConnectionrequest(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/connection/request').catch(() => {
      this.error_connection_refused('/connection/request');
    });
  }

  /**
   * navigate to connection list
   */
  navigateToConnectionList(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/connection/list').catch(() => {
      this.error_connection_refused('/connection/list');
    });
  }

  /**
   * navigate to connection list
   */
  navigateToRequestSent(): void {
    if (this.masterService.isOnline === false) {
      this.masterService.isConnectionModalShown.next(NUMBERS.ONE.toString());
      return;
    }
    this.router.navigateByUrl('/connection/sent-request').catch(() => {
      this.error_connection_refused('/connection/sent-request');
    });
  }

  /**
   * Navigate to meeting detail.
   */
  navigateToMeetingDetail(targetId): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/meeting/detail/' + targetId).catch(() => {
      this.error_connection_refused('/meeting/detail/' + targetId);
    });
  }

  /**
   * navigate to setting
   */
  navigateToSetting(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/webprofile/setting/language').catch(() => {
      this.error_connection_refused('/webprofile/setting/language');
    });
  }

  /**
   * navigate to feedback
   */
  navigateToFeedback(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/webprofile/setting/feedback').catch(() => {
      this.error_connection_refused('/webprofile/setting/feedback');
    });
  }

  /**
   * navigate to tnc
   */
  navigateTotnc(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    const url = (localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en';
    this.openUrlInNewTab(environment.tncUrl + url);
  }

  /**
   * navigate to privacy policy.
   */
  navigateToPrivacyPolicy(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    const url = (localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en';
    this.openUrlInNewTab(environment.privacyPolicyUrl + url);
  }

  /**
   * navigate to survey
   */
  navigateToSurvey(safeUrl?, type = 'all'): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (safeUrl !== undefined && safeUrl !== '' && safeUrl !== 0) {
      this.openUrlInNewTab('#/survey/detail/' + safeUrl);
    } 
    if (this.dss.cmeModuleSurveyData) {
      const surveyData: { has_parent: number, mi: number, code: string } = this.dss.cmeModuleSurveyData;
      this.redirectToNextStepJourney(PRODUCT_TYPE_CONSTANT.SURVEY, surveyData.mi);
    } else {
      this.router.navigateByUrl(`survey/list/${type}`).catch(() => {
        this.error_connection_refused(`survey/list/${type}`);
      });
    }
  }

  /**
   * navigate to bookmark
   */
  navigateToBookmark(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/webprofile/bookmark/case').catch(() => {
      this.error_connection_refused('/webprofile/bookmark/case');
    });
  }

  /**
   * navigate to event list
   */
  navigateToEvent(filter): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.router.navigateByUrl('/event/list/' + filter).catch(() => {
      this.error_connection_refused('/event/list/' + filter);
    });
  }



  /**
   *  navigate to new version of events
   **/
  navigateToNewEventDetail(target_id): void {
    if (this.masterService.isOnline === false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (target_id !== 0) {
      this.router.navigateByUrl('/eventv3/detail/' + target_id).catch(() => {
        this.error_connection_refused('/eventv3/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/eventv3/upcoming').catch(() => {
        this.error_connection_refused('/eventv3/upcoming');
      });
    }
  }

  /**
   *  navigate to new version of session.
   **/
  navigateToNewSessionDetail(id): void {
    if (this.masterService.isOnline === false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (id !== '' && id !== undefined && id !== 0) {
      this.router.navigateByUrl('eventv3/session/' + id);
    }
  }

  /**
   *  navigate to new version of session.
   **/
  navigateToEventScheduleList(id): void {
    if (this.masterService.isOnline === false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (id !== '' && id !== undefined && id !== 0) {
      this.router.navigateByUrl('eventv3/schedule/' + id);
    }
  }

  /**
   *  navigate To Event Detail
   **/
  navigateToEventDetail(target_id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (target_id != 0) {
      this.router.navigateByUrl('/event/detail/' + target_id).catch(() => {
        this.error_connection_refused('/event/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/event/list/upcoming').catch(() => {
        this.error_connection_refused('/event/list/upcoming');
      });
    }
  }

  /**
   * navigate To News
   */
  navigateToNews(target_id): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (target_id != 0) {
      this.router.navigateByUrl('/news/detail/' + target_id).catch(() => {
        this.error_connection_refused('/news/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/news/list').catch(() => {
        this.error_connection_refused('/news/list');
      });
    }
  }

  /**
   * check for email validation
   */
  isEmail(email): boolean {
    // eslint-disable-next-line no-useless-escape
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  /**
   * send logout API request on Logout CTA click
   */
  initiateLogout(): void {
    this.postSer.sendLogoutRequest().subscribe(
      (response) => {
        if (response.status === NUMBERS.ONE) {
          this.restoreLoginState();
        }
        if (response.status === NUMBERS.ZERO) {
          this.errorHandeler(response);
        }
      },
      (error) => {
        throw new Error(error);
      });
  }
  /**
   * logout handler
   */
  logout(): void {
    const utmObject = { 'UTM_Medium':  'NU', 'UTM_Source': 'NU', 'UTM_Campaign': 'NU' };
    localStorage.setItem('utmSourceData',  JSON.stringify(utmObject));
    this.restoreLoginState();
  }

  restoreLoginState(): void {
    this.resetQBData();
    this.dss.user_profile_data = [];
    this.masterService.clearProfileDetail();
    this.dss.chats = [];
    this.authService.logout();
    this.webinarWatchService.clearIndexedDB();
    window.location.reload();
  }
  /**
   * Api error handler.
   */
  errorHandeler(data, screen_name = ''): boolean {
    if (data.status === 0) {
      if (data.error.code === 1002) {
        this.logout();
        return;
      }
      if (data.error.code === 1030 || data.error.code === 1005 || data.error.code === 1018) {
        this.displayObj.errObj = data.error;
        this.displayObj.isShow = true;
        this.displayObj.display_section = 'infoModal';
        this.displayObj.data = data;
        this.masterService.showWebModal.next(this.displayObj);
        return;
      }
      if (data.error.code === 1025) {
        this.displayObj.isShow = true;
        this.displayObj.display_section = 'verificationSuccess';
        this.displayObj.data = data.error.data.pending_document;
        this.displayObj.errObj = [];
        this.masterService.showWebModal.next(this.displayObj);
        return;
      }
      if (data.error.code !== 1013) {
        this.displayObj.errObj = data.error;
        this.displayObj.isShow = true;
        this.displayObj.display_section = 'infoModal';
        this.displayObj.data = data;
        this.masterService.showWebModal.next(this.displayObj);
      } else {
        if (sessionStorage.getItem('verify_mobile') != null) {
          const temp = JSON.parse(sessionStorage.getItem('verify_mobile'));
          if (temp.data.screen_data.identifier == 'select_speciality' || temp.data.screen_data.identifier == 'select_interest') {
            return false;
          }
        }

        if (screen_name == 'generate_certificate') {
          this.displayObj.isShow = true;
          this.displayObj.display_section = 'user_not_verified';
          this.displayObj.data = data.error.data;
          this.masterService.showWebModal.next(this.displayObj);
        } else {
          this.displayObj.isShow = true;
          this.displayObj.display_section = 'verify_step2';
          this.displayObj.data = data.error.data;
          this.masterService.showWebModal.next(this.displayObj);
        }
      }
    }
  }
  /**
   * Survey error handler
   * @param data
   * @returns
   */
  surveyErrorHandler(data): void {
    const error = data.error;
    const msg = error?.m.slice(error?.m.indexOf('-') + 1);
    const displayObj = {
      errObj: { msg },
      isShow: true,
      display_section: 'infoModal',
    };
    this.masterService.showWebModal.next(displayObj);
    return;
  }

  /**
   * sets error message
   * @param data
   */
  extractErrorMsg(error: GatewayAPIResponse): { showErrorMsg: boolean, errorMsg: string } {
    if (error) {
      return {
        showErrorMsg: true,
        errorMsg: error?.m?.slice(error?.m?.indexOf('-') + 1)
      };
    }
  }

  /**
   * close Web Modal
   */
  closeWebModal(): void {
    this.displayObj.isShow = false;
    this.displayObj.display_section = '';
    // this.displayObj.data.push(temp);
    this.displayObj.data = [];
    this.masterService.showWebModal.next(this.displayObj);
  }

  /**
   * show product share modal
   */
  productShare(data): void {
    this.displayObj.isShow = true;
    this.displayObj.display_section = 'share';
    this.displayObj.data = data;
    this.masterService.showWebModal.next(this.displayObj);
  }

  /**
   * millie To Minutes And Seconds
   */
  millisToMinutesAndSeconds(millis): string {
    const minutes = Math.floor(millis / 60000);
    // tslint:disable-next-line: radix
    const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
  /**
   * seconds to minutes and seconds
   */
  secondsToMinutesAndSeconds(seconds): string {
    const minutes = Math.floor(seconds / 60);
    const second = parseInt((seconds % 60).toFixed(0), 10);
    return minutes + ':' + (second < 10 ? '0' : '') + second;
  }
  /**
   * seconds to minutes and hours
   */
  secondToHoursMinutesSeconds(seconds): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const second = parseInt((seconds % 60).toFixed(0));
    return (hours > 0 ? ((hours < 10 ? '0' : '') + hours + ':') : '') + (minutes < 10 ? '0' : '') + minutes + ':' + (second < 10 ? '0' : '') + second;
  }
  /**
   * url validation
   */
  validURL(str): boolean {
    if (str.indexOf('www') != -1 && str.indexOf('http') == -1) {
      str = 'https://' + str;
    }
    // eslint-disable-next-line no-useless-escape
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * navigate to webinar detail
   */
  navigateToWebinarDetail(target_id = ''): void {
    if (target_id != '') {
      this.router.navigateByUrl('/webinar/detail/' + target_id).catch(() => {
        this.error_connection_refused('/webinar/detail/' + target_id);
      });
    } else {
      this.router.navigateByUrl('/webinar/list/all').catch(() => {
        this.error_connection_refused('/webinar/list/all');
      });
    }
  }

  /**
   * navigate To Webinar list
   */
  navigateToWebinarlist(): void {
    this.router.navigateByUrl('/webinar/list/all').catch(() => {
      this.error_connection_refused('/webinar/list/all');
    });

  }

  /**
   * navigate to live webinar list
   */
  navigateToLiveWebinarList(): void {
    this.router.navigateByUrl('webinar/view-all/live_webinar').catch(() => {
      this.error_connection_refused('/webinar/list/all');
    });
  }

  /**
   * Navigate to upcoming webinar list.
   */
  navigateToUpcomingWebinar(): void {
    this.router.navigateByUrl('webinar/view-all/upcoming_Webinar');
  }

  /**
   * speaker detail
   */
  redirectOnSection(section, id, storyCode = null, viewListByDefault = false): void {
    switch (section) {
      case BRANCH_IDENTIFIER.WEBINAR_LIVE_LIST: {
        this.navigateToLiveWebinarList();
        break;
      }

      case BRANCH_IDENTIFIER.UPCOMING_WEBINAR_LIST: {
        this.navigateToUpcomingWebinar();
        break;
      }
      case BRANCH_IDENTIFIER.CHANNEL: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('channel/detail/' + id).catch(() => {
            this.error_connection_refused('channel/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('pharma/list').catch(() => {
            this.error_connection_refused('pharma/list');
          });
        }
        break;
      }
      case 'pseelection': {
        this.router.navigateByUrl('web/registration/register');

        break;
      }
      case 'drugsample': {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('pharma/drug/' + id).catch(() => {
            this.error_connection_refused('pharma/drug/' + id);
          });
        } else {
          this.router.navigateByUrl('drugsample/list/drugs').catch(() => {
            this.error_connection_refused('drugsample/list/drugs');
          });
        }
        break;
      }

      case 'session': {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('event/session-details/' + id).catch(() => {
            this.error_connection_refused('event/session-details/' + id);
          });
        } else {
          this.router.navigateByUrl('web/list').catch(() => {
            this.error_connection_refused('web/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.CME: {

        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('cme/detail/' + id).catch(() => {
            this.error_connection_refused('cme/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('cme/list').catch(() => {
            this.error_connection_refused('cme/list');
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.WEBINAR: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('webinar/detail/' + id).catch(() => {
            this.error_connection_refused('webinar/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('webinar/list/all').catch(() => {
            this.error_connection_refused('webinar/list/all');
          });
        }
        break;
      }

      case 'doctalk': {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('doctalk/detail/' + id + '/24').catch(() => {
            this.error_connection_refused('doctalk/detail/' + id + '/24');
          });
        } else {
          this.router.navigateByUrl('doctalk/list').catch(() => {
            this.error_connection_refused('doctalk/list');
          });
        }
        break;
      }

      case 'survey': {
        if (id != undefined && id != '' && id != 0) {
          // this.router.navigateByUrl('survey/detail/'+id);
          this.router.navigateByUrl('survey/list/all').catch(() => {
            this.error_connection_refused('survey/list/all');
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.OFFLINE_EVENT: {
        if (id != undefined && id != '' && id != 0) {
          // this.router.navigateByUrl('survey/detail/'+id);
          this.router.navigateByUrl('event/detail/' + id).catch(() => {
            this.error_connection_refused('event/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('event/list/upcoming').catch(() => {
            this.error_connection_refused('event/list/upcoming');
          });
        }
        break;
      }

      case 'clinical': {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('clinical/detail/' + id).catch(() => {
            this.error_connection_refused('clinical/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('clinical/list').catch(() => {
            this.error_connection_refused('clinical/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.JOURNAL: {
        if (id !== undefined && id !== '' && id !== NUMBERS.ZERO) {
          this.router.navigateByUrl('clinical/detail/' + id).catch(() => {
            this.error_connection_refused('clinical/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('clinical/list').catch(() => {
            this.error_connection_refused('clinical/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.ONLINE_EVENT: {
        if (id !== undefined && id !== '' && id !== NUMBERS.ZERO) {
          this.router.navigateByUrl(`eventv3/detail/${id}`).catch(() => {
            this.error_connection_refused(`eventv3/detail/${id}`);
          });
        } else {
          this.router.navigateByUrl('eventv3/upcoming').catch(() => {
            this.error_connection_refused('eventv3/upcoming');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.PHARMA: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('pharma/detail/' + id).catch(() => {
            this.error_connection_refused('pharma/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('pharma/list').catch(() => {
            this.error_connection_refused('pharma/list');
          });
        }
        break;
      }

      case NOTIFICATION_TYPE.JOIN_INVITATION: {
        if (id) {
          this.router.navigateByUrl('pharma/detail/' + id).catch(() => {
            this.error_connection_refused('pharma/detail/' + id);
          });
        } else {
          this.router.navigateByUrl('pharma/list').catch(() => {
            this.error_connection_refused('pharma/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.DRUG: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl('pharma/drug/' + id).catch(() => {
            this.error_connection_refused('pharma/drug/' + id);
          });
        } else {
          this.router.navigateByUrl('drugsample/list/drugs').catch(() => {
            this.error_connection_refused('drugsample/list/drugs');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.FEED: {
        if (id === null || id === 'list' || id === NUMBERS.ZERO || id === '') {
          this.router.navigateByUrl('web/list').catch(() => {
            this.error_connection_refused('web/list');
          });
        } else {
          this.router.navigateByUrl('web/list?feed=' + id).catch(() => {
            this.error_connection_refused('web/list?feed=' + id);
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.DOCTALK_SERIES: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl(`doctalk/detail/${id}/${PRODUCT_TYPE_CONSTANT.DOCTALKS_SERIES}`).catch(() => {
            this.error_connection_refused(`doctalk/detail/${id}/${PRODUCT_TYPE_CONSTANT.DOCTALKS_SERIES}`);
          });
        } else {
          this.router.navigateByUrl('doctalk/viewall/doctalks_series').catch(() => {
            this.error_connection_refused('doctalk/viewall/doctalks_series');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.DOCTALKS: {
        if (id != undefined && id != '' && id != 0) {
          this.router.navigateByUrl(`doctalk/detail/${id}/${PRODUCT_TYPE_CONSTANT.DOCTALKS}`).catch(() => {
            this.error_connection_refused(`doctalk/detail/${id}/${PRODUCT_TYPE_CONSTANT.DOCTALKS}`);
          });
        } else {
          this.router.navigateByUrl('doctalk/list').catch(() => {
            this.error_connection_refused('doctalk/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.COMMUNITY_LIST: {
        if (id !== undefined && id !== '' && id != NUMBERS.ZERO) {
          this.router.navigateByUrl(`community/detail/${id}`).catch(() => {
            this.error_connection_refused(`community/detail/${id}`);
          });
        } else {
          this.router.navigateByUrl('community/list').catch(() => {
            this.error_connection_refused('community/list');
          });
        }
        break;
      }

      case BRANCH_IDENTIFIER.COMMUNITY_CREATE: {
        this.router.navigateByUrl('community/createCommunity').catch(() => {
          this.error_connection_refused('community/createCommunity');
        });
        break;
      }
      case BRANCH_IDENTIFIER.COMMUNITY_REQUEST: {
        if (id !== undefined && id !== '' && id != NUMBERS.ZERO) {
          this.router.navigateByUrl(`community/detail/${id}/join_requests`).catch(() => {
            this.error_connection_refused(`community/detail/${id}`);
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.COMMUNITY_POSTS: {
        if (id !== undefined && id !== '' && id != NUMBERS.ZERO) {
          this.router.navigateByUrl(`community/detail/${id}/posts`).catch(() => {
            this.error_connection_refused(`community/detail/${id}`);
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.COMMUNITY_MEMBERS: {
        if (id !== undefined && id !== '' && id != NUMBERS.ZERO) {
          this.router.navigateByUrl(`community/detail/${id}/members`).catch(() => {
            this.error_connection_refused(`community/detail/${id}`);
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.COMMUNITY_LISTING: {
        if (id !== undefined && id !== '' && id != NUMBERS.ZERO) {
          this.router.navigateByUrl(`community/detail/${id}`).catch(() => {
            this.error_connection_refused(`community/detail/${id}`);
          });
        } else {
          this.router.navigateByUrl('community/list').catch(() => {
            this.error_connection_refused('community/list');
          });
        }
        break;
      }
      case BRANCH_IDENTIFIER.USER: {
        this.navigateToUserProfie(id);
        break;
      }

      case BRANCH_IDENTIFIER.CONNECTION: {
        this.navigateToConnection();
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE: {
        this.navigateToUserProfie(id);
        break;
      }

      case BRANCH_IDENTIFIER.CONNECTION_HOME: {
        this.navigateToConnection();
        break;
      }

      case BRANCH_IDENTIFIER.PROFILE_CONNECT: {
        this.connectionAdd(id, true);
        break;
      }

      case BRANCH_IDENTIFIER.CONNECTION_LIST: {
        this.navigateToConnectionList();
        break;
      }

      case BRANCH_IDENTIFIER.CONNECTION_SENT: {
        this.navigateToRequestSent();
        break;
      }

      // case BRANCH_IDENTIFIER.WEBINAR: {
      //   this.navigateToWebinarDetail(id);
      //   break;
      // }

      case BRANCH_IDENTIFIER.GR_HOME: {
        this.navigateToHome();
        break;
      }
      case BRANCH_IDENTIFIER.USER_STORY: {
        if (id)
          this.openStoryViaUrl(id, storyCode, viewListByDefault);
        break;
      }
      /**
       * redirections on basis of identifiers for profile nudges buttons
       */
      case BRANCH_IDENTIFIER.SETTINGS: {
        this.router.navigateByUrl('webprofile/setting/language');
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ADD_INTEREST: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/interest/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ADD_EXP: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/experience/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_SUMMARY: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/view/info/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ADD_PUBLICATION: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/publication/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.VAULT: {
        this.navigateToVault();
        break;  
      }
      case BRANCH_IDENTIFIER.REWARDS: {
        this.navigateToVault();
        break;
      }
      case BRANCH_IDENTIFIER.CALENDAR: {
        this.navigateToCalenderModule();
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_INFO: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/view/info/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ACTIVITY: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/view/info/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_POSTS: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.navigateToUserPosts(localStorage.getItem('loggedInUserCustomId'))
          break;
        }
      }
      case BRANCH_IDENTIFIER.PROFILE_COLLECTION: {
        break;
      }
      case BRANCH_IDENTIFIER.SPECIALITY: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/interest/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ADD_CERTIFICATE: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/certificate/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_ADD_EDUCATION: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.router.navigateByUrl('webprofile/add/education/' +localStorage.getItem('loggedInUserCustomId'));
        }
        break;
      }
      case BRANCH_IDENTIFIER.INVITE_CODE: {
        this.navigateToInviteCode(id);
        break;
      }
      case BRANCH_IDENTIFIER.BOOKMARK: {
        this.navigateToBookmark();
        break;
      }
      case BRANCH_IDENTIFIER.PROFILE_MEDIA: {
        if (localStorage.getItem('loggedInUserCustomId')) {
          this.navigateToUserPosts(localStorage.getItem('loggedInUserCustomId'))
          break;
        }
      }
    }
  }

  /**
   * navigate To Edit User Profile
   */
  navigateToEditUserProfile(section, cid): void {
    this.router.navigateByUrl('/webprofile/edit/' + section + '/' + cid).catch(() => {
      this.error_connection_refused('/webprofile/edit/' + section + '/' + cid);
    });
  }

  /**
   * navigate To Add User Profile
   */
  navigateToAddUserProfile(section, cid): void {
    this.router.navigateByUrl('/webprofile/add/' + section + '/' + cid).catch(() => {
      this.error_connection_refused('/webprofile/add/' + section + '/' + cid);
    });
  }

  /**
   * navigate To Creage GroupChat
   */
  navigateToCreageGroupChat(): void {
    this.router.navigateByUrl('webchat/view/cg').catch(() => {
      this.error_connection_refused('webchat/view/cg');
    });
  }

  /**
   * navigate To Invite Chat
   */
  navigateToInviteChat(): void {
    this.router.navigateByUrl('webchat/view/ic').catch(() => {
      this.error_connection_refused('webchat/view/ic');
    });
  }

  /**
   * navigate To Chat
   */
  navigateToChat(): void {
    // this.router.navigateByUrl('webchat/view');
    this.router.navigateByUrl('webchat/dashboard').catch(() => {
      this.error_connection_refused('webchat/dashboard');
    });
  }

  /**
   * navigate To clinical Detail
   */
  navigateToclinicalDetail(id): void {
    if (id !== NUMBERS.ZERO) {
      this.router.navigateByUrl('clinical/detail/' + id).catch(() => {
        this.error_connection_refused('clinical/detail/' + id);
      });
    }
    else {
      this.router.navigateByUrl('clinical/list');
    }
  }
  /**
   * navigate To clinical
   */
  navigateToclinical(): void {
    this.router.navigateByUrl('clinical/list').catch(() => {
      this.error_connection_refused('clinical/list');
    });
  }

  /**
   * navigate To Calender Module
   */
  navigateToCalenderModule(): void {
    this.router.navigateByUrl('webcalendar/list').catch(() => {
      this.error_connection_refused('webcalendar/list');
    });
  }

  /**
   * navigate To cme Module
   */
  navigateTocmeModule(): void {
    this.router.navigateByUrl('cme/list').catch(() => {
      this.error_connection_refused('cme/list');
    });
  }

  /**
   * navigate To historyback
   */
  navigateTohistoryback(): void {
    this._location.back();
  }

  /**
   * navigate To Dialog
   */
  navigateToDialog(dialog_id): void {
    this.router.navigateByUrl('webchat/dashboard/' + dialog_id).catch(() => {
      this.error_connection_refused('webchat/dashboard/' + dialog_id);
    });
  }

  /**
   * navigate For Chat
   */
  navigateForChat(uuid, isNotify?: boolean): void {
    if (this.dss.user_profile_data.profile.permission != 'all') {
      this.dss.selected_suggAssList = [];
      this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0).subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            if (data['code'] == 2000) {
              this.displayObj.isShow = true;
              this.displayObj.display_section = 'verificationSuccess';
              this.displayObj.data = data['data'];
              this.displayObj.errObj = [];
              this.masterService.showWebModal.next(this.displayObj);
            }
          } else {
            this.errorHandeler(data, 'generate_certificate');
            return;
          }
        });
      }));

      return;
    } else {
      if (this.isChatMaintenance) {
        this.isSamvaadMaintanceModal = true;
        return;
      }
      if (this.deviceService.isMobile() == true) {
        this.router.navigateByUrl('/samvaad/list');
      }
      this.chatUserId.next({ uuid, isNotify });
    }
  }

  /**
   * get Suggestion Detail
   */
  getSuggestionDetail(data): void {
    if (data.type == 'feed') {
      const feedDetailObj = {
        is_show: true,
        feed_id: data.id,
        mediaIdx: 0
      };
      this.dss.showFeedDetailInModal.next(feedDetailObj);
    } else if (data.type == 'journal') {
      this.navigateToclinicalDetail(data.id);
    } else if (data.type == 'cme') {
      this.navigateToCmeDetail(data.id);
    } else if (data.type == 'doctalk') {
      this.navigateToDoctalkDetail(data.id, 24);
    } else if (data.type == 'event') {
      this.navigateToEventDetail(data.id);
    } else if (data.type == 'channel') {
      this.navigateToChannelDetail(data.id);
    } else if (data.type == 'webinar') {
      this.navigateToWebinarDetail(data.id);
    } else if (data.type == 'survey') {
      this.navigateToSurvey();
    }
  }

  /**
   * navigate To Profile Viewers
   */
  navigateToProfileViewers(): void {
    this.router.navigateByUrl('/webprofile/viewer').catch(() => {
      this.error_connection_refused('/webprofile/viewer');
    });
  }

  /**
   * navigate To Profile Connection
   */
  navigateToProfileConnection(cid): void {
    this.router.navigateByUrl('/webprofile/view/connection/' + cid).catch(() => {
      this.error_connection_refused('/webprofile/view/connection/' + cid);
    });
  }


  /**
   * navigate To Notification
   */
  navigateToNotification(): void {
    this.router.navigateByUrl('/webprofile/notification').catch(() => {
      this.error_connection_refused('/webprofile/notification');
    });
  }

  /**
   * speaker detail
   */
  openReportModal(product_type, product_type_id): void {
    this.displayObj.isShow = true;
    this.displayObj.display_section = 'reportForm';
    const data = { product_type: product_type, product_type_id: product_type_id };
    this.displayObj.data = [];
    this.displayObj.data.push(data);
    this.displayObj.errObj = [];
    this.masterService.showWebModal.next(this.displayObj);
  }

  /**
   * assign Association
   */
  assignAssociation(str): void {
    this.dss.selected_suggAssList = [];
    this.subscriptions.add(this.postSer.assignAssociation('association', '[]', str, 0).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          if (data['code'] == 2000) {
            this.displayObj.isShow = true;
            this.displayObj.display_section = 'verificationSuccess';
            this.displayObj.data = data['data'];
            this.displayObj.errObj = [];
            this.masterService.showWebModal.next(this.displayObj);
          }
        } else {
          this.errorHandeler(data, 'generate_certificate');
          return;
        }
      });
    }));
  }

  /**
   * navigate To Top
   */
  navigateToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * open Feed Post Modal
   */
  openFeedPostModal(): void {
    this.dss.resetPostData();
    this.onboardingService.ddl_data.list_data = [];
    this.dss.show_feed_post_modal.next(1);
  }

  /**
   * page view Clicked
   */
  pageviewClicked(): void {
    setTimeout((id) => {
      document.querySelector<HTMLElement>('#' + id).click();
    }, 500);
  }

  /**
   * Is User Active On App
   */
  IsUserActiveOnApp(page_from): void {
    this.page_from = page_from;
    const vis = (() => {
      // tslint:disable-next-line: prefer-const
      let stateKey, eventKey, keys = {
        hidden: 'visibilitychange',
        webkitHidden: 'webkitvisibilitychange',
        mozHidden: 'mozvisibilitychange',
        msHidden: 'msvisibilitychange'
      };

      for (stateKey in keys) {
        if (stateKey in document) {
          eventKey = keys[stateKey];
          break;
        }
      }
      return function (c) {
        if (c) {
          document.addEventListener(eventKey, c);
        }

        return !document[stateKey];
      };

    })();

    vis(function () {
      const x = vis('') ? 1 : 0;
      // alert(x)
      // console.log(x+'page=');
      if (x == 0 || x == 1) {
        is_page_visible.next(x);
      }
    });

  }

  /**
   * reset QB Data
   */
  resetQBData(): void {
    this.dss.chats = [];
  }

  /**
   * reset Canvas Image EditObj
   */
  resetCanvasImageEditObj(): void {
    this.dss.canvas_image_edit_obj = {
      index: 0,
      base64Str: '',
      is_show: false,
      calling_from: ''
    };
  }
  scrollTo(elem, position): void {
    const
      elemHeight = elem.offsetHeight,
      elemScrollHeight = elem.scrollHeight;

    if (position === 'bottom') {
      if ((elemScrollHeight - elemHeight) > 0) {
        elem.scrollTop = elemScrollHeight;
      }
    } else if (position === 'top') {
      elem.scrollTop = 0; // 10
    } else if (+position) {
      elem.scrollTop = +position;
    }
  }

  /**
   * error connection refused
   */
  error_connection_refused(url): void {
    window.location.href = environment.baseUrls.webSiteUrl + '/#/' + url;
  }

  /**
   * open Url In NewTab
   */
  openUrlInNewTab(url): void {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * Download by url.
   */
  downloadImage(url, imageTitle): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = imageTitle;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * this method is for payment, like upcomming webimar and events
   */
  navigateForProductPayment(payment_info): void {
    if (payment_info.payments_status == 'PENDING' || payment_info.payments_status == '' || payment_info.payments_status == 'FAILED') {
      this.openUrlInNewTab(payment_info.redirect_url);
    }
  }

  /**
   * Navigate to Invite code popup on profile screen.
   */
  navigateToInviteCode(customId: string): void {
    if (this.isStringEmpty(customId)) {
      customId =  localStorage.getItem('loggedInUserCustomId');
    }
    this.router.navigate(['webprofile/view/info/' + customId], { queryParams: { inviteCode: 1 } });
  }

  /**
   * Navigate to Product Chat Bot on Drug detail page.
   */
  navigateToProductChatBot(pharmaId: number | string): void {
    this.router.navigate([`pharma/drug/${pharmaId}/chatbot`]);
  }

  accessStrictExternalSurvey(parsedData): void {
    this.mvc.isAuthenticByPass = true;
    this.mvc.authenticByPass$.next(true);
    const urlParams = [parsedData.ptype, parsedData.sub_type.action, parsedData.pid,
    this.generateTimestampUUID(), environment.deviceType, localStorage.getItem('lang'),
    ];
    this.mvc.externalSurveyDetail = this.setProperty(urlParams, parsedData.sub_type?.identifier);
    localStorage.setItem('externalSurveyDetail', JSON.stringify(this.mvc.externalSurveyDetail));
    localStorage.setItem('uuid', urlParams[3]);
    this.pfks.isPublicRequest = true;
    this.pfks.getPublicTokenForKeeper().then(value => this.pfks.publicToken = value?.at);
    this.mvc.isExternalSurvey = true;
    localStorage.setItem('isExternalSurvey', this.mvc.isExternalSurvey.toString());
  }

  /**
   * Get data from query param.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getParameterByName(name, url = window.location.href): any {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /**
   * Navigate to mobile explore.
   */
  navigateExplore(): void {
    this.router.navigateByUrl('web/explore');
  }

  /**
   * Function for event stop propagation
   */
  stopEventPropagation(): void {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  /**
   * Get local timezone.
   */
  getLocalTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${DEVICE_TYPE.WEB}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  /**
   * Move to Game Screen.
   */
  navigateToGameScreen(gameId: number): void {
    this.router.navigateByUrl(`/game/playGame/${gameId}`);
  }

  navigateInternally(path: string, id: number | string): void {
    this.router.navigateByUrl(`${path}${id}`);
  }

  /**
   * Move to game detail screen
   */
  navigateToGameDetail(gameId: number): void {
    this.router.navigateByUrl(`/game/detail/${gameId}`);
  }

  /**
   * Move to NCP detail screen
   */
  navigateToNCPDetail(id: number): void {
    this.router.navigateByUrl(`/game/list/${id}`);
  }

  /**
   * Used to share the healthCard.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatedSharedHealthCard(imageUrl: any): void {
    this.shareImageUrl.next(imageUrl);
  }

  /**
   * Community withdraw status to perform action from confirmation modal.
   */
  communityWithDrawStatus(request): void {
    if (request) {
      this.getWithdrawStatus.next(request);
    }
  }

  /**
   * navigate To Community Module
   */
  navigateToCommunityModule(): void {
    this.router.navigateByUrl('community/list').catch(() => {
      this.error_connection_refused('community/list');
    });
  }
  /**
   * navigate To Community Edit Page
   */
  navigateToCommunityEditPage(communityId): void {
    this.router.navigateByUrl(`community/editCommunity/${communityId}`);
  }
  /**
   * navigate To Community Detail Page
   */
  navigateToCommunityDetailPage(communityId): void {
    this.router.navigateByUrl(`community/detail/${communityId}`);
  }

  /**
   * navigate To Community Detail Page and reload the route
   */
  navigateToCommunityDetailAndReload(communityId): void {
    const url = `community/detail/${communityId}`;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }

  /**
   * makes API request for joinging public community
   * @param communityId for community
   * @param memberCount for community
   * @returns service instance
   */
  joinPublicCommunity(
    communityId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action?: string): Observable<any> {
    if (action && action !== '') {
      return this.postSer.joinPublicCommunity(communityId, action);
    } else {
      return this.postSer.joinPublicCommunity(communityId);
    }
  }

  /**
   * Function to replace user_name from confirmation , success , error messages
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replaceWithValue(message: string, actualValue: string): any {
    const regex = USER_NAME_REGX.user_name;
    const finalString = message.replace(regex, actualValue);
    return finalString;
  }

  /**
  * method to replace placeholder in a string with actual value
  */
  replaceWithActualValue(regex: RegExp, message: string, actualValue: string): string {
    const actualString = message.replace(regex, actualValue);
    return actualString;
  }

  /**
   * commenting below code as part of ticket#WEBDC-8253
   */
  /**
   * Navigate to completed survey list.
   */
  // navigateToSurveyCompletedList(): void {
  //   this.router.navigateByUrl('survey/list/completed');
  // }

  /**
   * Navigate to survey list.
   */
  navigateToNotAttemptedSurveyList(): void {
    this.router.navigateByUrl('/survey/list/all');
  }

  /**
   * returns commnity and association detail where feed is posted
   */
  getPostedInDetail(feed: Product): PostedIn {
    const count = feed.mapping_count;
    if (feed.community.length) {
      const community = feed.community[0];
      const posted: PostedIn = {
        isAssociation: false,
        id: community.community_id,
        name: community.title,
        count: (count.community + count.association) - NUMBERS.ONE
      };
      return posted;
    }
    if (feed.user_feed_association_list.length || count.association) {
      const posted: PostedIn = {
        isAssociation: true,
        id: null,
        name: count.association === NUMBERS.ONE ? this.mls.languageText.community.key144 : this.mls.languageText.community.key149,
        count: this.postedAssociationSize(feed),
        remainingCount: this.remainingCommunity(feed)
      };
      return posted;
    }
    return null;
  }

  /**
   * return count of association where feed is posted
   * @param feed for product
   * @returns number
   */
  postedAssociationSize(feed: Product): number {
    return feed.mapping_count.association || NUMBERS.ZERO;
  }

  /**
   * return remain count of community where user is not part and feed is posted
   * @param feed for product
   * @returns feed length
   */
  remainingCommunity(feed: Product): number {
    return (feed.mapping_count.community - feed.community.length);
  }

  /**
   * manage horizontal card details
   * @param cards for appeared cards
   * @param screen_name for screen
   */
  onHorizontalCardAppear(cards: AppearedCards, screen_name: string): void {
    if (screen_name !== SCREEN_NAME.GRAND_ROUND) {
      return;
    }
    const cardDetails: CardRendering = {
      local_id: this.generateLocalId(),
      retry: false,
      track_id: this.dss.user_profile_data.profile.track_id,
      product_type: Number(cards.appearIds.split(SPLIT)[0]),
      product_id: Number(cards.appearIds.split(SPLIT)[1]),
      session_id: sessionStorage.getItem('event_session_id'),
      start_timestamp: cards.startTime,
      end_timestamp: cards.endTime,
      screen_name,
      scroll_type: cards.scroll_type
    };
    this.hRenderingCards.push(cardDetails);
  }

  /**
   * Send collecting render cards for vertical and horizontal scroll.
   */
  sendCardRecord(records: CardRendering[]): void {
    if (records.length || this.failedCardsCollection.length || this.hRenderingCards.length) {
      const cardRenderingSub = this.postSer.sendCardRendering(this.failedCardsCollection.concat(records, this.hRenderingCards))
        .pipe(finalize(() => cardRenderingSub.unsubscribe()))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .subscribe((res: any) => {
          if (res.status === NUMBERS.ONE && res.code === STATUS_CODE.TWO_THOUSAND) {
            this.failedCardsCollection = [];
          } else {
            this.failedCardsCollection = res;
            this.failedCardsCollection.forEach(el => el.retry = true);
          }
        });
      this.hRenderingCards = [];
    }
  }

  /**
   * event handler for screen changes
   */
  detectScreenChanges(): void {
    this.breakpointObserver.observe([
      BREAKPOINTS.XSMALL,
      BREAKPOINTS.SMALL,
      BREAKPOINTS.MEDIUM,
      BREAKPOINTS.LARGE
    ]).pipe(
      takeUntil(this.notifier$)
    ).subscribe(() => {
      this.margin.ICCurrentHRootMargin = '-50px 0px 0px -60px';
      if (this.breakpointObserver.isMatched(BREAKPOINTS.XXSMALL)) {
        this.margin.ICCurrentHRootMargin = '-50px 0px 0px -60px';
        this.margin.ncpCurrentHRootMargin = '-50px 0px 0px -60px';
      }
      if (this.breakpointObserver.isMatched(BREAKPOINTS.LARGE)) {
        this.margin.ICCurrentHRootMargin = '-50px -330px 0px -530px';
      }
    });
  }

  /**
   * validates target types
   * @param redirect to target
   * @param id number
   */
  validateTargetType(redirect: Target, id: number, customId = ''): void {
    this.navigateProductLink(redirect, id, customId);
  }
  /**
   * Handler for links
   * @param redirect to target
   */
  navigateProductLink(redirect: Target, id?: number, customId = ''): void {
    let url = redirect.url;
    if (url) {
      if (this.validURL(url) || redirect.type === TARGET_TYPE.PROFILE) {
        url = this.rewriteUrl(url);
        this.navigateByLinkType(redirect.type, url, id);
      } else if (redirect.type === TARGET_TYPE.SURVEY) {
        //TO-DO -add navigation based on HCF response
        this.navigateToSurveyDetail(url, customId);
      }
      else {
        this.showErrorMessage();
      }
    } else if (redirect.type) {
      this.navigateByLinkType(redirect.type, url, id);
    }
  }

  /**
   * returna valid url
   * @param url for rewrite
   * @returns url
   */
  rewriteUrl(url: string): string {
    if (url.indexOf(PROTOCOL.WWW) !== NUMBERS.MINUS_ONE &&
      url.indexOf(PROTOCOL.HTTP) === NUMBERS.MINUS_ONE) {
      return `${PROTOCOL.HTTPS}://${url}`;
    }
    if (url.indexOf(`${PROTOCOL.HTTP}://`) !== NUMBERS.MINUS_ONE) {
      return url.replace(`${PROTOCOL.HTTP}://`, `${PROTOCOL.HTTPS}://`);
    }
    return url;
  }

  /**
   * Handler for route navigation based on link type
   * @param redirect to link
   */
  navigateByLinkType(linkType: string, url: string, id?: number): void {
    switch (linkType) {
      case TARGET_TYPE.DEEPLINK:
        window.location.href = url;
        break;
      case TARGET_TYPE.NCP_LINK:
        this.navigateToGameScreen(id);
        break;
      case TARGET_TYPE.DRUG:
        this.navigateInternally('/pharma/drug/', id);
        break;
      case TARGET_TYPE.ARTICLE:
        this.navigateInternally('/clinical/detail/', id);
        break;
      case TARGET_TYPE.PROFILE:
        this.navigateInternally('/webprofile/view/info/', url);
        break;
      case TARGET_TYPE.WEBINAR:
        this.navigateInternally('/webinar/detail/', id);
        break;
      case TARGET_TYPE.DOCTALK:
        this.navigateInternally(`/doctalk/detail/${id}/`, PRODUCT_TYPE_CONSTANT.DOCTALKS);
        break;
      case TARGET_TYPE.LINK:
      case TARGET_TYPE.EXTERNAL_LINK:
        window.open(url, '_blank');
        break;
    }
  }





  /**
   * Navigate to survey detail.
   */
  navigateToSurveyDetail(safeUrl, customId): void {
    const url = `${environment.baseUrls.webSiteUrl}/${environment.survey_base_href}/survey/${safeUrl}/${customId}/web/en`;
    this.openUrlInNewTab(url);
  }


  /**
   * sets error message for invalid url
   */
  showErrorMessage(): void {
    const displayObj = {
      errObj: { 'msg': this.mls.languageText.middle_ware.key4 },
      isShow: true,
      display_section: 'infoModal',
      data: [],
    };
    this.masterService.showWebModal.next(displayObj);
  }

  /**
   * Navigate to Tagged User Comment
   */
  scrollToTaggedUserComment(): void {
    // TODO: Need to remove the settimeout.
    setTimeout(() => {
      document.getElementById('commentbox-1').scrollIntoView();
    }, 2000);
  }

  /**
   * Method for check the device is apple or not.
   */
  isApple(): boolean {
    let expression = /(Mac|iPhone|iPod|iPad)/i;
    return expression.test(navigator.platform);
  }


  /**
   * Returns true if Ctrl or cmd keys were pressed.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isControl(event): any {
    if (this.isApple()) {
      return event.metaKey;
    }
    return event.ctrlKey; // Windows, Linux, UNIX
  }

  /**
   * Returns true if Ctrl+a or cmd+a were pressed (all text selection).
   */
  isSelectingAll(event): boolean {
    if (this.isControl(event)) {
      return event.key === READ_KEY.A;
    }
    return false;
  }

  /**
   * For navigation to community listing
   */
  navigateToCommunityListing(viewType: string): void {
    this.router.navigate(['community/list'], { queryParams: { view: viewType } });
  }

  continueToSurvey(surveyId: string): void {
    this.navigateInternally('/survey/detail/', surveyId);
  }
  /**
   * Branch IO redirection for survey
   */
  surveyRedirection(id: string, subtype: string, type: string): void {
    if (id) {
      if (type === SURVEY_ACCESS_TYPE.EXTERNAL && !!localStorage.getItem('mcTokenDetail')) {
        this.setExternalSurveyProp();
      }
      if ((this.mvc.isExternalSurvey && type) || this.mvc.authenticatedExternalSurvey) {
        this.router.navigate(['survey/detail/' + id], { queryParams: { 'survey-type': type }, replaceUrl: true }).catch(e => {
          this.error_connection_refused('survey/detail/' + id);
        });
      } else {
        this.router.navigate(['survey/detail/' + id]).catch(e => {
          this.error_connection_refused('survey/detail/' + id);
        });
      }
    } else {
      this.router.navigate(['/survey/list/' + subtype]).catch(e => {
        this.error_connection_refused('/survey/list/' + subtype);
      });
    }
  }

  private setExternalSurveyProp(): void {
    localStorage.setItem('externalUuid', this.generateTimestampUUID());
    this.pfks.isPublicRequest = true;
    this.pfks.getPublicTokenForKeeper().then(value => this.pfks.publicToken = value?.at);
    this.mvc.authenticatedExternalSurvey = true;
  }
  /**
   * Survey deeplink rediction from notification
   * @param screen
   * @param surveyCode
   */
  surveyDeepLinkRedirection(screen: string, surveyCode: string, type = '') {
    switch (screen) {
      case SURVEY_DEEPLINK_ACTION.PREVIEW:
        localStorage.setItem('surveyDetail', surveyCode);
        this.router.navigate(['/survey/question', surveyCode], { queryParams: { vm: true } });
        break;
      case SURVEY_DEEPLINK_ACTION.SECTION:
        this.setSurveyType(type);
        this.router.navigate(['survey/detail/', surveyCode], { queryParams: { 'survey-type': type }, state: { deeplinkDraftRedirect: true } });
        break;
      case SURVEY_DEEPLINK_ACTION.DETAIL:
      default:
        this.surveyRedirection(surveyCode, '', type);
        break;
    }
  }

  /**
   * set survey type based on user login
   * @param type 
   */
  private setSurveyType(type: string): void {
    if (type === SURVEY_ACCESS_TYPE.EXTERNAL) {
      if (localStorage.getItem('mcTokenDetail')) {
        this.setExternalSurveyProp();
      } else {
        this.mvc.isExternalSurvey = true;
      }
    }
  }

  /**
   * Navigate to gr filter
   */
  navigateToGRFilter(filter: string): void {
    this.router.navigateByUrl(`web/list?grfilter=${filter}`);
  }

  navigateToDrugSample(id: number): void {
    if (id !== NUMBERS.ZERO) {
      this.router.navigateByUrl(`pharma/drug/${id}`);
    }
    else
      this.router.navigateByUrl('drugsample/list/drugs');
  }

  navigateToCommunityDetail(id: number): void {
    if (id !== NUMBERS.ZERO) {
      this.router.navigateByUrl(`community/detail/${id}`);
    }
    else {
      this.router.navigateByUrl('community/list');
    }
  }
  /**
   * navigate To Community Module
   */
  navigateToCommunityCreate(): void {
    this.router.navigateByUrl('community/createCommunity').catch(() => {
      this.error_connection_refused('community/createCommunity');
    });
  }

  navigateToSessionSchedule(id): void {
    if (id) {
      this.router.navigateByUrl('eventv3/session/schedule/21/' + id);
    }
  }

  /**
   * returns an object with specified property
   * @param urlParams
   * @param surveyType
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setProperty(urlParams: any[], surveyType: string): SurveyData {
    return {
      ptype: urlParams[0],
      screen: urlParams[1],
      surveyId: urlParams[2],
      uuid: urlParams[3],
      deviceType: urlParams[4],
      lang: urlParams[5].split('?')[0],
      surveyType
    };
  }

  /**
   * Validate application language or sets a default value
   * @param lang
   * @returns
   */
  langValidator(lang: string): string {
    if (Object.values(LANG_ABBREVIATIONS).indexOf(lang) < NUMBERS.ZERO) {
      lang = 'en';
    }
    return lang;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCustomUUID(): any {
    return this.getCookieData('browserUniqueId');

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFingerPrintUUID(): any {
    return this.getCookieData('browserFingerprintId');
  }

  getCookieData(name): boolean {
    let pairs = document.cookie.split('; '),
      count = pairs.length, parts;
    while (count--) {
      parts = pairs[count].split('=');
      if (parts[0] === name)
        return parts[1];
    }
    return false;
  }

  extractUrlParams(): void {
    const url = window.location.href;
    const urlParams = url.slice(url.indexOf('#') + 1).split('/');
    urlParams.shift();
    const isComingFromDeepLink = localStorage.getItem('isComingFromDeepLink');
    const surveyType = this.getParameterByName('survey-type') || '';

    if (isComingFromDeepLink && urlParams.length === NUMBERS.THREE && this.validateExternalSurveyRoute(urlParams)) {
      if (!localStorage.getItem('mcTokenDetail') &&
        surveyType === SURVEY_ACCESS_TYPE.EXTERNAL ||
        localStorage.getItem('isExternalSurvey') === 'true') {
        const detail: string[] = [
          DEEPLINK_SURVEY.SURVEY,
          SURVEY_DEEPLINK_ACTION.DETAIL,
          urlParams[2].split('?')[0],
          this.generateTimestampUUID(),
          environment.deviceType,
          localStorage.getItem('lang') || DEFAULT_LANG,
        ];
        if (urlParams[0] === 'survey') {
          if (urlParams[1] === 'detail') {
            localStorage.setItem('uuid', detail[3]);
          } else if (!localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', detail[3]);
          }
        }
        this.mvc.externalSurveyDetail = this.setProperty(detail, SURVEY_ACCESS_TYPE.EXTERNAL);
        let lang = this.langValidator(this.mvc.externalSurveyDetail.lang);
        this.mvc.setWebLanguage(lang);
        localStorage.setItem('isCookies', false.toString());
        this.mvc.isAuthenticByPass = true;
        this.mvc.authenticByPass$.next(true);
        localStorage.setItem('externalSurveyDetail', JSON.stringify(this.mvc.externalSurveyDetail));
        this.pfks.isPublicRequest = true;
        this.pfks.getPublicTokenForKeeper().then(value => this.pfks.publicToken = value?.at);
        this.mvc.isExternalSurvey = true;
        localStorage.setItem('isExternalSurvey', this.mvc.isExternalSurvey.toString());
      }
      if (localStorage.getItem('mcTokenDetail')) {
        if (surveyType === SURVEY_ACCESS_TYPE.EXTERNAL) {
          this.setExternalSurveyProp();
        } else if (localStorage.getItem('authenticatedExternalSurvey') === 'true') {
          this.pfks.isPublicRequest = true;
          this.pfks.getPublicTokenForKeeper().then(value => this.pfks.publicToken = value?.at);
          this.mvc.authenticatedExternalSurvey = true;
        }
      }

    }
    if (!isComingFromDeepLink && urlParams.length === NUMBERS.SIX) {
      if (surveyType === SURVEY_ACCESS_TYPE.EXTERNAL) {
        this.mvc.externalSurveyDetail = this.setProperty(urlParams, surveyType);
        let lang = this.langValidator(this.mvc.externalSurveyDetail.lang);
        this.mvc.setWebLanguage(lang);
        if (this.mvc.checkForMobilePlatform(this.mvc.externalSurveyDetail?.deviceType)) {
          localStorage.setItem('isCookies', false.toString());
          this.mvc.authenticByPass$.next(true);
        }
        localStorage.setItem('externalSurveyDetail', JSON.stringify(this.mvc.externalSurveyDetail));
      }
      if (surveyType === SURVEY_ACCESS_TYPE.INTERNAL) {
        this.mvc.internalSurveyDetail = this.setProperty(urlParams, surveyType);
        let lang = this.langValidator(this.mvc.internalSurveyDetail.lang);
        this.mvc.setWebLanguage(lang);
        if (this.mvc.checkForMobilePlatform(this.mvc.internalSurveyDetail.deviceType)) {
          localStorage.setItem('isCookies', false.toString());
          this.mvc.authenticByPass$.next(true);
        }
      }
    }
  }

  /**
   * 
   * @param function to check whether string is null or empty
   * @returns 
   */
  isStringEmpty(value: string): boolean {

    if (value == null || value == '' || value == undefined) {
      return true;
    }
    return false;
  }

  private validateExternalSurveyRoute(urlParams: string[]): boolean {
    if (urlParams[0] === 'survey' && (['detail', 'question', 'thank'].indexOf(urlParams[1]) > -1)) {
      return true;
    }
    return false;
  }

  generateTimestampUUID() {
    let timestamp = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (timestamp + Math.random() * 16) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    console.log('uuid', uuid);
    return uuid;
  }

  /**
   * navigate to sponsor detail
   * @param code 
   * @param url 
   * @returns 
   */
  navigateToSponsorDetail(code: number, url: string): boolean {
    if (url && !url.includes('_blank') && !!code) {
      return true;
    }
    return false;
  }
  /**
   * returns a canonical name from a url
   * @param url
   * @returns
   */
  extractCanonicalUrl(url: string): string {
    const domainRegex = CANONICAL_DOMAIN_REGEX;
    const canonicalUrl = url && url.match(domainRegex);
    return canonicalUrl && canonicalUrl[2];
  }
  /**
   * navigate To clinical Detail
   */
  navigateToOpenArticleDetail(articleId): void {
    this.router.navigate(['/open-access/public-article', articleId]);
  }


  /**
   * Navgate user on open product combined list/specific product list
   */
  navigateToOpenProductList(type, type2 = 0): void {
    if (type && type2 === 0) {
      if (type !== PRODUCT_TYPE_CONSTANT.COMMUNITY) {
        this.router.navigateByUrl('open-access/public-product-list/' + type);
      } else {
        this.router.navigateByUrl('/login');
      }
    } else if (type && type2) {
      if (type === PRODUCT_TYPE_CONSTANT.DOCTALKS && type2 === PRODUCT_TYPE_CONSTANT.DOCTALKS_SERIES) {
        this.router.navigateByUrl('open-access/public-product-list?dt=24&sr=36');
      }
    } else {
      this.router.navigateByUrl('open-access/public-product-list');
    }
  }

  /**
   * Navigate user on open product detail page.
   */
  navigateToOpenProductDetail(pType, pId): void {
    pType = Number(pType);
    pId = Number(pId);
    if (pId === 0 && pType === 0) {
      this.openAccessScreenName = screenName.RESOURCE_LISTING;
      this.router.navigateByUrl('/open-access/public-product-list');
      return;

    }
    if (pId === 0) {
      this.openAccessScreenName = screenName.PRODUCT_DASHBOARD;
      this.router.navigateByUrl('/open-access/public-product-list/' + pType);
      return;
    }
    if (pId && this.openAccessWelcomeScreenData) {
      this.openAccessScreenName = screenName.PRODUCT_DETAIL_PAGE;
    }
    switch (pType) {
      case 3: {
        this.router.navigateByUrl('/open-access/public-article/' + pId);
        break;
      }

      case 27: {
        this.router.navigateByUrl('/open-access/public-webinar/' + pId);
        break;
      }

      case 24: {
        this.router.navigateByUrl('/open-access/public-doctalk/' + pId);
        break;
      }

      case 36: {
        this.router.navigateByUrl('/open-access/public-dt-series/' + pId);
        break;
      }
    }
  }

  /**
   * navigate for cme module
   * @param type 
   * @param targetId 
   */
  public navigateForCmeModule(type: string, targetId , toParent = false): void {
    if (!this.popstateListenerAdded) {
      window.addEventListener('popstate', this.redirectionForCmeChild , { once: true});
      this.popstateListenerAdded = true;
    }

    localStorage.removeItem(CME_LOCAL_STORAGE.NEXT_STEP_CME_MODULE_DATA);

    if (type === CME_MODULE_TARGET.WEBINAR) {
      this.navigateToWebinarDetail(targetId);
    } else if (type === CME_MODULE_TARGET.SURVEY) {
      this.router.navigate(['/survey/detail/', targetId]);
    }
    else if (type === CME_MODULE_TARGET.CME && toParent == false) {
      this.router.navigate(['/cme/child-cme/' + targetId]);
    }
    else if(type === CME_MODULE_TARGET.CME && toParent == true){
      this.router.navigate(['/cme/detail/' + targetId]);
    }
  }

  /**
   * redirection on back button click
   * for cme child and next step screen
   */
  public redirectionForCmeChild = ()=>{
      this.popstateListenerAdded = false;
      const parentData = localStorage.getItem(CME_LOCAL_STORAGE.CME_PARENT_DATA);
      if (parentData && parentData != '1') {
        this.router.navigate(['/cme/detail/' + localStorage.getItem(CME_LOCAL_STORAGE.CME_PARENT_DATA)]);
      } else {
        this.router.navigate(['/cme/list/']);
      }
  }

  /**
   * redirect to next step in case of module type cme
   */
  redirectToNextStepJourney(type, lessonId) {
    window.removeEventListener('popstate',this.redirectionForCmeChild);
    this.popstateListenerAdded=false;

    const nextStepSubscription = (this.getService.getNextStepForCmeModule(type, lessonId).subscribe(resData => {
      if (resData.data) {
        localStorage.setItem(CME_LOCAL_STORAGE.NEXT_STEP_CME_MODULE_DATA, JSON.stringify(resData.data));
        
        if (resData.data.generate_certificate == 1) {
          // Go to generate certificate when all mandatory child done
          this.generateHeaders(resData.data);
          this.router.navigateByUrl('/cme/result');
        } else if (resData.data.target == null && resData.data.generate_certificate == 0) {
          // Go to thank you screen without certificate when all mandatory child done 
          this.generateHeaders(resData.data);
          this.router.navigateByUrl('/cme/result');
        } else if (resData.data?.target != null && resData.data.generate_certificate == 0) {
          // Go To Next Step direction
          window.addEventListener('popstate', this.redirectionForCmeChild , { once: true});
          this._location.go('/cme/detail/'+localStorage.getItem(CME_LOCAL_STORAGE.CME_PARENT_DATA)) 
          this.router.navigate(['/cme/next-step']);
        }   
      }
      nextStepSubscription?.unsubscribe();
    }));
  }

  /**
   * generate headers for cme type module for result screen
   */
  public generateHeaders(nextStepData) {
    let cmeParentDetailData = localStorage.getItem(CME_LOCAL_STORAGE.CME_PARENT_DETAIL_DATA);
    let cmeCourseData;
    if(cmeParentDetailData){
      cmeParentDetailData = JSON.parse(cmeParentDetailData);
      cmeParentDetailData['remark'] = CME_REMARK.PASSED
    }else{
      cmeCourseData = {
        certificate: nextStepData.generate_certificate,
        course_id: nextStepData.lesson_id,
        cme_type: CME_TYPE.MODULE,
        remark : CME_REMARK.PASSED
      }
    }

    const data = {
      course_id: nextStepData.lesson_id,
      data: {
        header_msg: nextStepData?.header_text,
        sub_header_msg: nextStepData.sub_header_text
      },
      course_detail: (cmeParentDetailData ? cmeParentDetailData : cmeCourseData)
    }
    localStorage.setItem(CME_LOCAL_STORAGE.CME_RESULT, JSON.stringify(data));
  }

  /**
   * Navigate user on open product detail page.
   */
  navigateToAppProductDetail(pType, pId): void {
    pType = Number(pType);
      pId = Number(pId);
      if(pId===0 && pType === 0) {
        this.openAccessScreenName = screenName.RESOURCE_LISTING;
        this.router.navigateByUrl('/open-access/public-product-list');
        return;

      }
      if(pId === 0) {
        this.router.navigateByUrl('/open-access/public-product-list/'+pType);
        return;
      }
      switch(pType) {
        case 3:{
          this.navigateToclinicalDetail(pId);
          break;
        }

        case 27: {
          this.navigateToWebinarDetail(pId);
          break;
        }

        case 24: {
          this.navigateToDoctalkDetail(pId , 24);
          break;
        }

        case 36: {
          this.navigateToDoctalkDetail(pId, 36)
          break;
        }
    }
  }
  /**
   * NavigateUser to 404 page
   */
  navigateTo404(): void {
    this.router.navigateByUrl('/404');
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.notifier$.next();
    this.notifier$.complete();
  }

  navigateToExternalLink(link: string): void {
    const extractedLink = this.shs.extractHref(link);
    extractedLink && this.mvc.sendEventToIosApp({
      method: WEBVIEW_MOBILE_METHOD_POST,
      params: WEBVIEW_MOBILE_PARAMS.REDIRECT_CTA_CLICK,
      body: {
        link
      }
    });
  }

  /**
   * Remove local storage
   */
  removeLocalStorage(field: string): void{
    localStorage.removeItem(field);
  }
}
