/**
 * Analytics object interface.
 */
export interface IPublicAnalytics {
    fingerprint_id?: string;
    browser_unique_id?: string;
    open_visitor_id?: string;
    product_type: number;
    product_id: number;
    event_name: string;
    screen_name: SCREEN_NAME;
    extra?: string
}

export interface IPublicAnalyticsData extends IPublicAnalytics {
    eventName?: IEventName;
}
export type IEventName = {
    name: string;
    hasDQ: boolean;
}


export enum SCREEN_NAME {
    LOGIN_SCREEN= 'LOGIN_SCREEN',
    PRODUCT_DETAIL_PAGE= 'PRODUCT_DETAIL_PAGE',
    RESOURCE_LISTING= 'RESOURCE_LISTING',
    PRODUCT_DASHBOARD= 'PRODUCT_DASHBOARD',
    WELCOME_PAGE= 'WELCOME_PAGE',
    ROLE_SELECTION_PAGE= 'ROLE_SELECTION_PAGE',
    BUSINESS_QUERY_OTP= 'BUSINESS_QUERY_OTP',  
    BUSINESS_QUERY_FORM= 'BUSINESS_QUERY_FORM'
}


