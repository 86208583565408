/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Modal imports.
 */
import { Chat } from '../../../modal/chat';
import { ChatList } from '../../../modal/chat.interface';

/**
 * Pipe imports.
 */
import { DatePipe } from '@angular/common';
import { NiceDateFormatPipe } from '../../../pipes/nice-date.pipe';

/**
 * Constant imports.
 */
import { API_AUTH_TOKEN, API_KEY_MEDIA, CHAT_TYPE, CHAT_VIEW, DATE_EN_US, DATE_FORMAT, DELETED_USER_NAME, IMAGE, INTERNAL_SEARCH_FOCUS_DELAY, MAX_UNREAD_COUNT, SYSTEM_MSG_CONSTANT, TYPE, ZERO } from '../../constants/chat.constants';
import { CHAT_API } from '../../constants/chat-api.constant';

/**
 * Component used to show the p2p and group listing.
 */
@Component({
  selector: 'app-chat-list-panel',
  templateUrl: './chat-list-panel.component.html',
  styleUrls: ['./chat-list-panel.component.scss']
})
export class ChatListPanelComponent implements OnInit, OnDestroy, OnChanges {


  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isChatListOverLapped && changes.isChatListOverLapped.previousValue != undefined && this.chatView == CHAT_VIEW.MINI) {
      if (this.isChatListOverLapped == true) {
        this.emitOnLeave();
      }
      if (this.isChatListOverLapped == false) {
        this.emitOnVisit();
      }
    }
  }

  @ViewChild('internalSearch', { static: false }) internalSearch: ElementRef;

  /**
   * Type of chat constant.
   * Type of views for the chat panel.
   */
  public chatType = CHAT_TYPE;
  public chatViewConstant = CHAT_VIEW;
  public maxUnreadCount = MAX_UNREAD_COUNT;

  /**
   * Get tge chat mode default is p2p.
   * Loading flag for the chat listing.
   * Available list for the chat.
   * Which view to show the chat panel.
   * Total unread counts.
   * Flag to determined the chat detail is expanded.
   * Options for the floating buttons.
   * Get the samvaad instance for the system message.
   */
  @Input() chatMode = this.chatType.P2P;
  @Input() isLoading: boolean;
  @Input() chatList: ChatList[];
  @Input() chatView: number;
  @Input() totalUnreadCount: number;
  @Input() isChatListExpanded: boolean;
  @Input() floatingOptions: { id: number, url: string }[];
  @Input() authToken: string | number | boolean;
  @Input() selectedUserIndex: string;
  @Input() chat: Chat;
  @Input() samvaadLanguageText: any = {};
  @Input() isMeTopic: string;
  @Input() hostKey: string;
  @Input() apiKey: string;

  @Input() isChatListOverLapped: boolean;

  /**
   * Emit the chat detail info.
   * Emit the chat expand flag.
   * Emit the close chat list event.
   * Emit the floating button actions.
   * Emit the new user adding request in the list event.
   * Emit the avatar click in the listing.
   */
  @Output() getChatDetail: EventEmitter<{ topicId: string, userType: number }> = new EventEmitter();
  @Output() onChatExpand: EventEmitter<void> = new EventEmitter();
  @Output() onSearchClicked: EventEmitter<void> = new EventEmitter();
  @Output() onCloseChatList: EventEmitter<void> = new EventEmitter();
  @Output() emitFloatingAction: EventEmitter<number> = new EventEmitter();
  @Output() onNewJourney: EventEmitter<number> = new EventEmitter();
  @Output() onAvatarClick: EventEmitter<{ type: number, topicId: string }> = new EventEmitter();
  @Output() onChatListToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onChatListOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() onVisit: EventEmitter<Date> = new EventEmitter();
  @Output() onLeave: EventEmitter<Date> = new EventEmitter();

  /**
   * Search text for the internal search.
   * Flag for the internal search.
   */
  public searchText = '';
  public isInternalSearch: boolean;
  public groupIdsForSystemMsg = [];
  private visitTime: Date;
  public deletedUserName = DELETED_USER_NAME;

  /**
   * Emit the entry event.
   */
  ngOnInit(): void {
    this.visitTime = new Date();
  }

  /**
   * Emit the entry event outside.
   */
  emitOnVisit(): void {
    this.visitTime = new Date();
    this.onVisit.emit(this.visitTime);
  }

  /**
   * Emit the leave event outside.
   */
  emitOnLeave(): void {
    this.onLeave.emit(this.visitTime);
  }

  /**
   * Change the chat mode for the chat listing.
   */
  changeChatMode(mode: number): void {
    this.chatMode = mode;
  }

  /**
   * Track the indexed stream.
   */
  trackMe(index: number, chat: any): any {
    return chat ? chat.topic : undefined;
  }

  /**
   * Get the last msg date and format using the nice date format pipe.
   */
  getLastMsgDate(user: ChatList): string {
    if (user?.lastMsg && user?.lastMsg?.ts) {
      const niceDatePipe = new NiceDateFormatPipe;
      const lastMsgDate = niceDatePipe.transform(user.lastMsg.ts,
        (new DatePipe(DATE_EN_US)).transform(user.lastMsg.ts, DATE_FORMAT));
      return lastMsgDate;
    } else {
      return '';
    }
  }

  /**
   * Get the online offline svg.
   */
  getUserOnlineOfflineStatus(users: ChatList): string {
    if (users.online) {
      return 'assets/samvaad-assets/images/icons/online-status.svg'
    } else {
      return 'assets/samvaad-assets/images/icons/offline-status.svg'
    }
  }

  /**
   * Get the last msg for the particular chat.
   */
  getLastMsg(users: ChatList, type: number): any {
    if (users?.typing?.isTyping) {
      if (type === this.chatType.GROUP) {
        let memberName = '';
        memberName = this.getMemberNameFromTopic(users?.typing?.from);
        return memberName + ' ' + this.samvaadLanguageText?.samvaad?.key11;
      } else {
        return this.samvaadLanguageText?.samvaad?.key11;
      }
    }
    if (users.acs && !users.acs.isJoiner() && type === this.chatType.P2P) {
      return this.samvaadLanguageText?.samvaad_system_msg?.key7;
    }
    if (users.acs && users.acs.given === ZERO && type === this.chatType.GROUP) {
      return this.samvaadLanguageText?.samvaad_system_msg?.key11;
    }
    if (users.acs && users.acs.want === ZERO && type === this.chatType.GROUP) {
      return this.samvaadLanguageText?.samvaad_system_msg?.key12;
    }
    else if (users.lastMsg && users.lastMsg.content && users.lastMsg.content.ent && users.lastMsg.content.ent[ZERO][TYPE] === IMAGE) {
      if (users?.lastMsg.from !== this.isMeTopic) {
        return this.samvaadLanguageText?.samvaad_system_msg?.key9;
      } else {
        return this.samvaadLanguageText?.samvaad_system_msg?.key10;
      }
    }
    else if (users.lastMsg && users.lastMsg?.head?.system_message) {
      return this.getSystemMsg(users);
    }
    else if (users.lastMsg && users.lastMsg.content) {
      return users.lastMsg.content;
    }
  }

  /**
   * Get the member name for the system message.
   */
  getMemberNameFromTopic(topicId: string): string {
    if (this.isMeTopic !== topicId) {
      const topic = this.chat.getTopic(topicId);
      const fn = topic?.public?.fn || '';
      const ln = topic?.public?.ln || '';
      const memberName = fn + ' ' + ln;
      return memberName ? memberName?.trim() : null;
    } else {
      return this.samvaadLanguageText?.samvaad?.key1;
    }
  }

  /**
   * Get the system message.
   */
  getSystemMsg(users: ChatList): string {
    let msg = '';
    switch (users?.lastMsg?.head?.system_message) {
      case SYSTEM_MSG_CONSTANT.GROUP_CREATE:
        if (users?.lastMsg?.from !== this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key14;
        } else {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key13;
        }
        break;
      case SYSTEM_MSG_CONSTANT.MEMBERS_REMOVE:
        if (users?.lastMsg.from === this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key15;
        }
        else if (users?.lastMsg?.content?.targets && users?.lastMsg?.content?.targets[ZERO] === this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key11;
        }
        else if (users?.lastMsg?.content?.targets && users?.lastMsg?.content?.targets[ZERO] !== this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key16;
        }
        break;
      case SYSTEM_MSG_CONSTANT.MEMBERS_ADD:
        if (users?.lastMsg.from === this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key17;
        }
        else if (users?.lastMsg?.content?.targets && this.searchForMeInTargets(users?.lastMsg?.content?.targets)) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key18;
        }
        else if (users?.lastMsg?.content?.targets && users?.lastMsg.from !== this.isMeTopic && !this.searchForMeInTargets(users?.lastMsg?.content?.targets)) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key19;
        }
        break;
      case SYSTEM_MSG_CONSTANT.MEMBER_LEFT:
        if (users?.lastMsg?.content?.target === this.isMeTopic) {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key12;
        } else {
          msg = this.samvaadLanguageText?.samvaad_system_msg?.key20;
        }
        break;
      case SYSTEM_MSG_CONSTANT.GROUP_DESC_UPDATE:
        if (users?.lastMsg?.content?.photo) {
          if (users?.lastMsg?.content?.target === this.isMeTopic) {
            msg = this.samvaadLanguageText?.samvaad_system_msg?.key21;
          } else {
            msg = this.samvaadLanguageText?.samvaad_system_msg?.key22;
          }
        } else {
          if (users?.lastMsg?.content?.target === this.isMeTopic) {
            msg = this.samvaadLanguageText?.samvaad_system_msg?.key23;
          } else {
            msg = this.samvaadLanguageText?.samvaad_system_msg?.key24;
          }
        }
        break;
      default:
        break;
    }
    return msg ?? '';
  }

  /**
   * Search for the You.
   */
  searchForMeInTargets(targets: string[]): boolean {
    return targets.some(val => val === this.isMeTopic);
  }

  /**
   * Emit the chat detail open event.
   */
  openChatDetail(topicId: string, userType: number): void {
    this.getChatDetail.emit({ topicId, userType });
  }

  /**
   * Minimize/Expand the chat list window only in mini view.
   */
  toggleExpandWindow(): void {
    this.isChatListExpanded = !this.isChatListExpanded;
    if (!this.isChatListExpanded) {
      this.emitChatListOpen();
    }
    this.emitChatListExpand();
  }

  /**
   * Emit the chat expand event.
   */
  chatExpand(): void {
    this.onChatExpand.emit();
  }

  /**
   * click event on search icon
   */
  searchIconClicked(): void {
    this.onSearchClicked.emit();
  }

  /**
   * Emit the chat expand event.
   */
  emitChatListExpand(): void {
    this.onChatListToggle.emit(this.isChatListExpanded);
  }

  /**
   * Emit the chat expand event.
   */
  emitChatListOpen(): void {
    this.onChatListOpen.emit(this.isChatListExpanded);
  }

  /**
   * Close the chat list only in mobile view.
   */
  closeChatList(): void {
    this.onCloseChatList.emit();
  }

  /**
   * Emit the dropdown actions.
   */
  doFloatingAction(action: number): void {
    this.emitFloatingAction.emit(action);
    this.isChatListExpanded = false;
    // this.emitChatListExpand();
  }

  /**
   * Emit the new user journey start event.
   */
  newJourney(): void {
    this.onNewJourney.emit(this.chatMode);
  }

  /**
   * Start the internal search for the p2p and group list.
   */
  startInternalChat(): void {
    this.isInternalSearch = true;
    this.focusOnInternalSearch();
  }

  /**
   * Focus on the internal search.
   */
  focusOnInternalSearch(): void {
    setTimeout(() => {
      this.internalSearch.nativeElement.focus();
    }, INTERNAL_SEARCH_FOCUS_DELAY);
  }

  /**
   * Emit the avatar click event.
   */
  emitAvatarClick(type: number, topicId: string): void {
    this.onAvatarClick.emit({ type, topicId });
  }

  dpSanitize(image: any): string {
    if (image?.ref) {
      const authToken = encodeURIComponent(this.authToken);
      return CHAT_API.BASE_URL + this.hostKey + image.ref + API_KEY_MEDIA + this.apiKey + API_AUTH_TOKEN + authToken;
    }
  }

  /**
   * Get the unread status for the p2p and group.
   */
  getUnreadStatus(list: ChatList[]): boolean {
    return list?.some(val => val?.unread > ZERO);
  }

  /**
   * Send the visit event.
   */
  ngOnDestroy(): void {
    this.emitOnLeave();
  }
}
