
  <div class="row">
    <div class="col-md-5 p-0">
      <app-left-section></app-left-section>
    </div>
    <div class="col-md-7 p-0">
      <div class="position-relative"
        [ngClass]="{'px-3 py-5': onboardingService?.deviceType!='mobile', 'pl-4 pr-4 pb-5 pt-5': onboardingService?.deviceType=='mobile'}">
        <a (click)="back_screen()" class="back-btn position-absolute"><img src="assets/images/onboarding/back.svg"
            width="20px" /></a>
        <span (click)="skipOnboardingModal()" class="position-absolute cursor-pointer text-grey primary-small skip-onboarding"
          *ngIf="screen_data?.is_skippable==1">{{mls?.languageText?.university?.key1}}<span
            *ngIf="mls?.is_lskey_display == 1">(university.key1)</span>
            <img class="ml-2" src="assets/themes/icons/right-arrow.svg" width="8" alt="back arrow">
        </span>
        <h5 class="mb-1 heading-4">{{mls?.languageText?.university?.key2}}<span
            *ngIf="mls?.is_lskey_display == 1">(university?.key2)</span>
          <span *ngIf="screen_data?.data != null" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
            class="cursor-pointer">
            <svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <defs>
                <style>
                  .cls-info {
                    fill: #767577;
                  }

                  .cls-info2 {
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }

                  .cls-info3 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <g id="Layer_3" data-name="Layer 3">
                <circle class="cls-info" cx="9.5" cy="10" r="9" />
                <line class="cls-info2" x1="9.5" y1="9.38" x2="9.5" y2="14.38" />
                <circle class="cls-info3" cx="9.5" cy="6.38" r="1" />
              </g>
            </svg>
          </span>
        </h5>

        <div *ngIf="infoshow==true" id="mrn-info" class="p-2 border position-absolute common-dropdown-list d-block w-75">
          <img [src]="info_data?.url" onError="this.src='assets/images/default_pic.png';" class="w-100">
        </div>

        <div class="form-input error" *ngIf="error_msg!=''">
          <p class="help-block">{{error_msg}}</p>
        </div>
        <app-search-university (userUniversity)=userUniversity($event)></app-search-university>

        <p class="secondary-paragraph mb-1">{{mls?.languageText?.university?.key5}}<span
            *ngIf="mls?.is_lskey_display == 1">(university.key5)</span></p>
        <div class="file-upload">
        <label for="college_id"
          class="my-4 file-upload-box">
          <img src="assets/themes/images/upload-cam.svg" class="mr-1" />
            
            <p class="primary-small"><span>{{mls?.languageText?.university?.key3}}<span
            *ngIf="mls?.is_lskey_display == 1">(university.key3)</span></span></p>
        </label>
      </div>
        <!-- upload preview -->
        <div class="position-relative university-preview mb-3" [hidden]="!upload_college_data">
          <a class="cursor-pointer"
            style="width: 20px;right: 9px;top: 9px;background: #eee;display: block;height: 20px;position: absolute;padding: 4px;border-radius: 50%;line-height: 10px;text-align: center;"
            (click)="removeCollegeId()"><img src="assets/images/close-cme.png" /></a>
          <img [src]="upload_college_data?.absolute_path"
            onerror="this.src='assets/images/grandround/image_placeholder.svg';" class="" />
        </div>
        <!-- end upload preview -->
        <span style="margin-left:50%; margin-bottom: 10px;" *ngIf="uploadFile_api_in_process==true"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <input id="college_id" type="file" (change)="onSelectFile($event)" name="college-id" class="d-none"
          accept=" image/jpeg, image/png, image/webp" />

        <button class="btn-design large w-100" [ngClass]=" showColor ? 'filled' : 'filled-disabled'" [disabled]="showColor==false" type="button"
        (click)="userRegister()">
        <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{mls?.languageText?.university?.key4}}
        <span *ngIf="mls?.is_lskey_display == 1">(university.key4)</span>
        </button>
        <!-- end form -->
      </div>
    </div>
  </div>

  
   <!-- <app-error-modal *ngIf="isErrorModalShown==true" (resetErrorModal) = resetErrorModal($event)   [isErrorModalShown]=isErrorModalShown [error_msg]="error_msg"></app-error-modal>
   -->
   <app-backscreenmodal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event) [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-backscreenmodal>

   <app-skip-onboarding-modal *ngIf="isSkipConfirmModalShown==true" (resetSkipConfirmModal)=resetSkipConfirmModal($event) [isSkipConfirmModalShown]=isSkipConfirmModalShown [confirmInfo]=confirmInfo></app-skip-onboarding-modal>

