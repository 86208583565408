import { Component, Input } from '@angular/core';
/**
 * Service imports.
 */
import { LocalstorageService } from './../../auth/localstorage.service';
/**
 * Interface imports.
 */
import { VerificationSuccessData } from './../../onboarding/interface/global.model';
/**
 * Constants imports.
 */
import { LOCAL_STORAGE } from './../../constants/app.constants';

@Component({
  selector: 'app-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['./verification-success.component.scss']
})
export class VerificationSuccessComponent {

  /**
   * Key used to show modal on conditional basis.
   */
  @Input() isShownWebModal: boolean;
  /**
   * Passing data in verification success screen.
   */
  @Input() verificationContent: VerificationSuccessData[];

  /**
   * Necessary instance.
   */
  constructor(private localStorage: LocalstorageService) { }

  /**
   * Function used to perform actions on hidden of modal.
   */
  onHidden(): void {
    this.isShownWebModal = false;
    this.localStorage.removeItemLocalStorage(LOCAL_STORAGE.SUCCESS_SCREEN);
  }
  /**
   * Function used to perform actions on close of modal.
   */
  close(): void {
    this.onHidden();
  }
}
