import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLineBreaks'
})
export class NewLineBreaksPipe implements PipeTransform {

  transform(value: string): string {
    const data = value.replace(/\n/g, '<br/>');
    return data;
 }

}
