<div class="custom-theme-modal modal fade success-popup" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'relative' }"
(onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
<div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onHidden()" class="cross-icon" type="button" class="position-absolute close-icon bg-transparent"
      data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">
        <img src="assets/themes/icons/gallery-close-icon.svg" alt="cross">
      </span>
     </button>
    </div>
    <div class="modal-body px-3">
      <!-- video player start-->
      <app-shaka-basic [videoSrc]="mediaUrl" 
         [autoplay]="true" (emitEndedFlag)="nextVideo()">
      </app-shaka-basic>
      <!-- video player end-->
    </div>
    <div class="modal-footer">
    </div>
  </div>
</div>
</div>