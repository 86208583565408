import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WebinarContinueWatchLog {

    private dbName: string = 'webinar-watch-logs';
    private dbVersion: number = 1;
    private db!: IDBDatabase;
    private collectionName: string = 'event';

    /**
     * Promise to ensure database initialization
     */
    private isDatabaseReady: Promise<void>;

    constructor() {
        // Initialize the database when the service is created
        this.isDatabaseReady = this.initDatabase();
    }

    /**
     * Initializes the IndexedDB database connection.
     * Opens the database and creates object stores and indexes if necessary.
     * Resolves the promise when the database is successfully opened.
     * Rejects the promise if there is an error opening the database.
     */
    private initDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);

            request.onerror = (event) => {
                console.error('Error connecting to IndexedDB! -> ', event);
                reject((event.target as IDBRequest).error); // Reject the promise with the error
            };

            request.onsuccess = (event) => {
                this.db = request.result; // Store the database instance
                console.log("IndexedDB connected successfully");
                resolve(); // Resolve the promise when the database is ready
            };

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;
                const objectStore = db.createObjectStore(this.collectionName, { keyPath: ["product_type", "product_id"]  });
                objectStore.createIndex('expiryDate', 'expiryDate', { unique: false });
            };
        });
    }

    /**
     * Prepares an IndexedDB transaction for read/write operations on the object store.
     * @returns The object store used for the transaction.
     */
    private prepareTransaction(): IDBObjectStore {
        const transaction = this.db.transaction([this.collectionName], 'readwrite');
        return transaction.objectStore(this.collectionName);
    }

    /**
     * Adds or updates data in the IndexedDB object store.
     * The data object is prepared before being put into the object store.
     * @param data The data to be added or updated in the object store.
     * @returns A promise that resolves when the data is successfully added or updated.
     */
    public async addData(data: any): Promise<void> {
        await this.isDatabaseReady; // Wait for the database to be initialized
        const objectStore = this.prepareTransaction();
        const requestData = this.prepareData(data);
        const request = objectStore.put(requestData);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                console.log("Added webinar log successfully");
                resolve();
            };
            request.onerror = (event) => {
                console.error('Error adding data to IndexedDB! -> ', event);
                reject((event.target as IDBRequest).error);
            };
        });
    }

    /**
     * Retrieves data from the IndexedDB object store by ID and product type.
     * Checks if the data is not expired based on the current date.
     * @param id The ID of the data to retrieve.
     * @returns A promise that resolves with the data if not expired, or null if expired or not found.
     */
    public async getData(type:number,id: number): Promise<any | null> {
        await this.isDatabaseReady;
        const objectStore = this.prepareTransaction();
        this.deleteExpiredRecords();
        
        let compoundKey = [Number(type), Number(id)];

        const request = objectStore.get(compoundKey);
        const currentDate = new Date().toISOString();

        return new Promise((resolve, reject) => {
            request.onsuccess = (res) => {
                const result = request.result;
                if (result && currentDate <= result.expiryDate) {
                    resolve(result); // Resolve with the data if not expired
                } else {
                    resolve(null); // Resolve with null if the data is expired or not found
                }
            };
            request.onerror = (event) => {
                console.error('Error fetching record from IndexedDB! -> ', event);
                reject((event.target as IDBRequest).error); // Reject the promise with the error
            };
        });
    }

    /**
     * Calculates the expiry date based on the number of days from the current date.
     * @param days Number of days to add to the current date.
     * @returns The calculated expiry date in ISO string format.
     */
    private calculateExpiryDate(days: number): string {
        const currentTime = new Date();
        currentTime.setDate(currentTime.getDate() + days);
        return currentTime.toISOString();
    }

    /**
     * Prepares data to be stored in IndexedDB.
     * Adds an expiry date based on the watch span provided.
     * @param data The data to prepare, including id, watch span, and duration.
     * @returns The prepared data object including id, expiry date, and duration.
     */
    private prepareData(data: any) {
        const watchSpan = localStorage.getItem("WebinarWatchSpan");
        const expiryDate = this.calculateExpiryDate(Number(watchSpan));
        return {
            product_type: data?.type,
            product_id: data?.id,
            expiryDate: expiryDate,
            duration: data?.duration ?? 0
        };
    }

    /**
     * Deletes all records from the IndexedDB object store that have passed their expiry date.
     * Uses an index on `expiryDate` to find records that are expired.
     * Iterates through the expired records using a cursor and deletes them.
     * 
     * @returns
     */
    private deleteExpiredRecords(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.isDatabaseReady.then(() => {
                const objectStore = this.prepareTransaction();

                const index = objectStore.index('expiryDate');
                const currentDate = new Date().toISOString();

                const request = index.openCursor(IDBKeyRange.upperBound(currentDate));

                request.onsuccess = (event) => {
                    const cursor = (event.target as IDBRequest).result;
                    if (cursor) {
                        // Get the record from the cursor
                        const record = cursor.value;

                        // Delete the expired record
                        cursor.delete();

                        // Continue to the next record
                        cursor.continue();
                    } else {
                        resolve();
                    }
                };

                request.onerror = (event) => {
                    // Handle errors during the cursor operation
                    console.error('Error deleting expired records from IndexedDB! -> ', event);
                    reject((event.target as IDBRequest).error);
                };
            }).catch((error) => {
                console.error('Database initialization failed: ', error);
                reject(error); // Reject the promise if database initialization fails
            });
        });
    }

    /**
     * Clears all data from the specified IndexedDB database by deleting it.
     * 
     * @returns {Promise<void>} - A promise that resolves when the database is deleted successfully, or rejects if an error occurs.
     */
    public clearIndexedDB(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase(this.dbName);

            // Event handler for successful deletion
            request.onsuccess = () => {
                console.log(`${this.dbName} database deleted successfully`);
                resolve();
            };

            // Event handler for errors encountered during deletion
            request.onerror = (event) => {
                console.error(`Error deleting ${this.dbName} database:`, event);
                reject(event);
            };

            // Event handler for cases when the deletion is blocked by open connections
            request.onblocked = () => {
                console.log(`${this.dbName} database deletion blocked`);
            };
        });
    }

}
