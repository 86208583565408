/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from "@angular/core";

/**
 * Search pipe for the internal search.
 */
@Pipe({
  name: "searchFilter",
  pure: false
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    args = args.toLowerCase();
    return value.filter(function (item) {
      return JSON.stringify(item?.public?.fn + ' ' + item?.public?.ln)?.toLowerCase()?.includes(args) || JSON.stringify(item?.public)?.toLowerCase()?.includes(args);
    });
  }
}
