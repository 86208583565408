import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import * as jwt from '../../assets/js/jwt.js';
@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  constructor() {
    // No Code
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public decodeData(data: any): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      // console.log(data)
      if (data['status'] == 1 || data['status'] == 0) {
        if (environment.is_encoded_response == 1) {
          if (data['data'].result != '' && data['data'].result != undefined) {
            const decodedData = jwt.decode(data['data'].result, environment.jwtSecretKey);
            resolve(decodedData);
          } else {
            resolve(data);
          }
        } else {
          resolve(data);
        }

      }
    });
  }
}
