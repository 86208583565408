/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpBackend } from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { NewLanguageService } from '../new-language/new-language.service';
import { LocalstorageService } from '../../auth/localstorage.service';
import { MobileWebViewCommunicationService } from '../mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * JS imports.
 */
import * as crypto from 'crypto-js';
import * as jwt from '../../../assets/js/jwt.js';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';
import { GATEWAY_STATUS_CODE, LANG_ABBREVIATIONS, STATUS_CODE} from '../../constants/app.constants';

/**
 * Interface imports.
 */
import { CountryListData, RequestOtpData, ResendOtpData, UniversityListData, UserBasicInfoData, UserRegisterData, VerifyMobileData } from '../../onboarding/interface/apiResponse.model';
import { JwtDecodeService } from '../jwt-decode.service';
import { AccessTokenService } from '../../auth/access-token.service';
import { TokenDetail } from '../../onboarding/interface/global.model';

/**
 * Onboarding service
 */
@Injectable({
  providedIn: 'root'
})
export class OnboardingService implements OnDestroy {
  /**
   * Hold flag if user directly come to the userdetail page.
   */
  public directToUserdetails = false;

  public client_ip_address = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ddl_data: any = {
    placeholderL: 'Ex. +91',
    single_select: true,
    enable_search: true,
    selected_data: [],
    list_data: [],
    identifier: 'country',
    showCheckbox: false,
    enableCheckAll: false,
    disabled: false,
    enableFilterSelectAll: false
  };
  public deviceType = '';
  public mobileType = '';
  public lang = 'en';
  /**
   * Device type.
   */
  public DEVICE_TYPE = environment.deviceType;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary Instances.
   */
  constructor(
    private localstorage: LocalstorageService,
    private httpClient: HttpClient,
    public nls: NewLanguageService,
    private handler: HttpBackend,
    private jwtService: JwtDecodeService,
    private accessTokenService: AccessTokenService,
    private mvc: MobileWebViewCommunicationService
  ) {
    // this.subscriptions.add(this.getClentIp().subscribe(data => {
    //   this.client_ip_address = data;
    //   this.localstorage.setInLocalstorage('ip_addr', this.client_ip_address);
    // }));

   // this.setLanguage();
     this.subscriptions.add(this.mvc.webLang.subscribe((data: string) => {
      if(data) {
        this.lang = data;
        this.subscriptions.add(this.getLanguageFile(this.lang).subscribe(data => {
          this.nls.languageText = JSON.parse(data);
          this.mvc.webLang.complete();
        }, () => {
          // No Code
        }));
      }
    }));
    this.subscriptions.add(
      this.mvc.userAuthKey$.subscribe((user_auth_key: string)=> {
        const token: TokenDetail = JSON.parse(localStorage.getItem('mcTokenDetail')) || null;
        token && this.accessTokenService.calculateRefreshInterval(token?.expin);
        this.localstorage.setInLocalstorage('token', this.encryptAes(JSON.stringify(user_auth_key)));
      })
    );
  }

  /**
   * Request OTP.
   */
  requestOtp(mobile, country_id, country_code): Observable<RequestOtpData> {
    const device_info = JSON.stringify({ devicetype: environment.deviceType });
    const httpClient = this.createNewHttpClient();
    const url = environment.API_URLS.registration + API.REGISTRATION_OTP_REQUEST;
    const headers = {
      Authorization: environment.appKey,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      lang: this.lang,
      ver: environment.versions.api_version
    };
    const header_data = { headers, url };
    const encryptdata = jwt.encode(header_data, environment.jwtSecretKey);
    const final_header_data = {
      'Content-Type': 'application/x-www-form-urlencoded',
      encryptdata: encryptdata
    };

    const data = {
      mobile,
      country_code,
      country_id,
      device_info,
      is_encoded_response: environment.is_encoded_response
    };
    const encrptparam = jwt.encode(data, environment.jwtSecretKey);
    const body = { encrptparam };
    return httpClient.post<RequestOtpData>(`${environment.API_URLS.others}${API.VALIDATE_API_EXTRACTION}`, body,
      {
        headers: final_header_data
      });
  }

  /**
   * Plain Request OTP.
   */
  plain_request_otp(mobile, country_id, country_code, response): Observable<RequestOtpData> {
    const device_info = JSON.stringify({ devicetype: environment.deviceType });
    const httpClient = this.createNewHttpClient();
    const url = `${environment.API_URLS.registration}${API.REGISTRATION_OTP_REQUEST}`;
    const header = {
      Authorization: environment.appKey,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      lang: this.lang,
      ver: environment.versions.api_version,
      releaseVersion: environment.versions.appReleaseVersion
    };

    const header_data = { headers: header, url: url };
    const encryptdata = jwt.encode(header_data, environment.jwtSecretKey);
    const final_header_data = { encryptdata: encryptdata };

    const data = {
      mobile: mobile,
      country_code: country_code,
      country_id: country_id,
      device_info: device_info,
      catpcha_token: response,
      is_encoded_response: environment.is_encoded_response
    };
    const encrptparam = jwt.encode(data, environment.jwtSecretKey);
    const body = { encrptparam: encrptparam };

    return httpClient.post<RequestOtpData>(`${environment.API_URLS.others}${API.VALIDATE_API_EXTRACTION_V1}`, body, {
      headers: final_header_data
    });
  }

  /**
   * User Captcha Request.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userCaptchaRequest(countryObj, mob, email): Observable<any> {
    const device_info = JSON.stringify({ devicetype: environment.deviceType });
    const body = new HttpParams()
      .set('mobile', mob)
      .set('country_code', countryObj.code)
      .set('country_id', countryObj.id)
      .set('device_info', device_info)
      .set('email', email);

    return this.httpClient.post(API.REGISTRATION_CAPTCHA_USER_REQUEST, body);
  }

  /**
   * Resend OTP
   */
  resendOtp(mobile, country_id, country_code, token_id, response): Observable<ResendOtpData> {
    const httpClient = this.createNewHttpClient();
    const url = environment.API_URLS.registration + API.REGISTRATION_OTP_RESEND;
    const header = {
      Authorization: environment.appKey,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      lang: this.lang,
      ver: environment.versions.api_version,
      releaseVersion: environment.versions.appReleaseVersion
    };
    const header_data = { headers: header, url: url };
    const encryptdata = jwt.encode(header_data, environment.jwtSecretKey);
    const final_header_data = { encryptdata: encryptdata };

    const data = {
      mobile: mobile,
      country_code: country_code,
      country_id: country_id,
      token_id: token_id,
      catpcha_token: response,
      is_encoded_response: environment.is_encoded_response
    };
    const encrptparam = jwt.encode(data, environment.jwtSecretKey);
    const body = { encrptparam: encrptparam };
    return httpClient.post<ResendOtpData>(environment.API_URLS.others + API.VALIDATE_API_EXTRACTION_V1, body, {
      headers: final_header_data
    });
  }

  /**
   * Verify Mobile
   */
  verifyMobile(mobile, country_id, country_code, token_id, confirm_code): Observable<Promise<VerifyMobileData>> {
    const device_info = JSON.stringify({ devicetype: environment.deviceType });
    const body = {
      mobile: mobile,
      country_code: country_code,
      country_id: country_id,
      token_id: token_id,
      confirm_code: confirm_code,
      device_info: device_info,
      device_token: ''
    };

    return this.httpClient.post<VerifyMobileData>(API.REGISTRATION_VERIFY_MOBILE, body)
    .pipe(
      map(response => {
        const userVerifyMobilePromise= this.jwtService.decodeData(response);
        userVerifyMobilePromise.then(data => {
          if(data?.data?.signature && data?.data?.signature?.mc === GATEWAY_STATUS_CODE.GATEWAY_1003) {
            const {at, rt, expin, rexpin} = data.data.signature;
            this.accessTokenService.calculateRefreshInterval(expin);
            this.accessTokenService.setTokenDetailsToStorage({at, rt, expin, rexpin});
          } else {
            if(data?.error?.code !== STATUS_CODE.ONE_THOUSAND && data?.error?.msg !== '') {
              throw new Error(`code: ${data?.error?.code}, msg: ${data?.error?.msg}`);
            } else if(!Array.isArray(data?.data?.signature) &&
                data?.data?.signature?.mc !== GATEWAY_STATUS_CODE.GATEWAY_1003) {
              throw new Error(`code: ${data?.data?.signature?.mc}, msg: ${data?.data?.signature?.m}`);
            }
          }
        });
        return userVerifyMobilePromise;
      }),
    );
  }

  /**
   * User Register
   */
  userRegister(token_id, user_input_json, is_skip_screen): Observable<Promise<UserRegisterData>> {
    const body = {
      token_id: token_id,
      user_input_json: JSON.stringify(user_input_json),
      is_skip_screen: is_skip_screen
    };

    return this.httpClient.post<UserRegisterData>(API.USER_REGISTRATION, body)
    .pipe(
      map(response => {
        const registerPromise = this.jwtService.decodeData(response);
        registerPromise.then(data => {
          if(data?.data?.signature && data?.data?.signature?.mc === GATEWAY_STATUS_CODE.GATEWAY_1003) {
            const {at, rt, expin, rexpin} = data.data.signature;
            this.accessTokenService.calculateRefreshInterval(expin);
            this.accessTokenService.setTokenDetailsToStorage({at, rt, expin, rexpin});
            if(data.profile_info?.profile?.country_code !== '') {
              this.localstorage.setInLocalstorage('country_code', data.profile_info.profile.country_code);
            }
          } else {
            if(data?.error?.code !== STATUS_CODE.ONE_THOUSAND && data?.error?.msg !== '') {
              throw new Error(`code: ${data?.error?.code}, msg: ${data?.error?.msg}`);
            }
          }
        });
        return registerPromise;
      })
     );
  }

  /**
   * Invite Process
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inviteProcess(token_id, action_type, claim_code): Observable<any> {
    const body = {
      token_id: token_id,
      action_type: action_type,
      claim_code: claim_code, claim_data: '',
    };

    return this.httpClient.post(API.REGISTRATION_INVITE_PROCESS, body);
  }

  /**
   * Verify Invite Process
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyInviteProcess(token_id, action_type, claim_code, claim_data): Observable<any> {
    const body = {
      token_id: token_id,
      action_type: action_type,
      claim_code: claim_code,
      claim_data: claim_data
    };

    return this.httpClient.post(API.REGISTRATION_INVITE_PROCESS, body);
  }

  /**
   * Search University
   */
  searchUniversity(keyword, offset, token_id): Observable<UniversityListData> {
    const params = { keyword: keyword, offset: offset, token_id: token_id };

    return this.httpClient.get<UniversityListData>(API.REGISTRATION_UNIVERSITY_LIST, {
      params: params
    });
  }

  /**
   * Store Profile Data
   */
  storeProfileData(data): void {
    this.localstorage.setInLocalstorage('uProfile', this.encryptAes(JSON.stringify(data['data'])));

    this.localstorage.setInLocalstorage('userpermission', this.encryptAes(JSON.stringify(data['data'].profile_info.profile.permission)));
    this.localstorage.setInLocalstorage('token', this.encryptAes(JSON.stringify(data['data'].profile_info.profile.user_auth_key)));
    this.localstorage.setInLocalstorage('profile_obj', this.encryptAes(JSON.stringify(data['data'].profile_info.profile.custom_id)));
    this.localstorage.setInLocalstorage('trackId', data['data'].profile_info.profile.track_id);
  }


  /**
   * Encrypt AES
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public encryptAes(plaintext): any {
    const ciphertext = crypto.AES.encrypt(plaintext, environment.hashKey);
    return ciphertext;
  }

  /**
   * Decrypt AES
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected decryptAes(ciphertext): any {
    const str = ciphertext.toString();
    // Decrypt
    const bytes = crypto.AES.decrypt(str, environment.hashKey);
    const plaintext = bytes.toString(crypto.enc.Utf8);
    return plaintext;

  }

  /**
   * New Upload In Chunk
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newUploadInChunk(file, name, num_chunks, num, file_type, product_type): Observable<any> {
    let f_type = file_type;
    if (file_type == 'application') {
      f_type = 'document';
    }
    f_type = f_type.split('/');
    f_type = f_type[0];
    const input = new FormData();
    input.append('file', file);
    input.append('name', name);
    input.append('file_type', f_type);
    input.append('num', num);
    input.append('num_chunks', num_chunks);
    input.append('product_type', product_type);

    if (num_chunks == num) {
      input.append('chunk_status', '1');
    } else { input.append('chunk_status', '0'); }
    const httpClient = this.createNewHttpClient();
    const headers = this.uploadFileHeaders();
    return httpClient.post(`${environment.API_URLS.media_upload}/${API.CONNECT_SERVICE_F_UPLOAD_SET}`, input,
      {
        headers,
        params: { rquest: 'uploadfile' }
      }
    );

  }

  createNewHttpClient(): HttpClient {
    return new HttpClient(this.handler);
  }

  uploadFileHeaders(): { Authorization: string; version: string; device_type: string; } {
    const headers = {
      'Authorization': environment.appKey,
      'version': environment.versions.apiVersion20,
      'device_type': 'web'
    };
    return headers;
  }

  /**
   * Get Country List
   */
  getCountryList(): Observable<CountryListData> {
    if (this.lang == 'null' || this.lang == null) {
      this.lang = 'en';
      localStorage.setItem('lang', this.lang);
    }
    const params = { ip_address: this.client_ip_address };

    return this.httpClient.get<CountryListData>(API.COUNTRY_LIST_V4, {
      params: params
    });
  }

  /**
   * Get Country List
   */
  getCountryListV5(): Observable<CountryListData> {
    if (this.lang == 'null' || this.lang == null) {
      this.lang = 'en';
      localStorage.setItem('lang', this.lang);
    }
    const params = { ip_address: this.client_ip_address };

    return this.httpClient.get<CountryListData>(API.COUNTRY_LIST_V5, {
      params: params
    });
  }

  /**
   * Get Language File
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLanguageFile(lang): Observable<any> {
    const dt = new Date();
    let url = 'assets/en.json' + '?time=' + dt.getTime();
    if (lang == 'in') {
      url = 'assets/id.json' + '?time=' + dt.getTime();
    }
    if (lang == 'vi') {
      url = 'assets/vi.json' + '?time=' + dt.getTime();
    }
    if (lang == 'ko') {
      url = 'assets/ko.json' + '?time=' + dt.getTime();
    }
    if (lang == 'zh') {
      url = 'assets/zh.json' + '?time=' + dt.getTime();
    }
    if (lang === LANG_ABBREVIATIONS.JAPANESE) {
      url = 'assets/ja.json' + '?time=' + dt.getTime();
    }
    if (lang === LANG_ABBREVIATIONS.SPANISH) {
      url = 'assets/es.json' + '?time=' + dt.getTime();
    }
    if (lang === LANG_ABBREVIATIONS.PORTUGESE) {
      url = 'assets/pt.json' + '?time=' + dt.getTime();
    }

    return this.httpClient.get(url, { responseType: 'text' });
  }

  /**
   * Get Client IP.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getClentIp(): Observable<any> {
    const httpClient = this.createNewHttpClient();
    return httpClient.get(environment.baseUrls.WHATS_MY_IP, { responseType: 'text' });
  }

  /**
   * Custom Id To User Auth Key.
   */
  customeIdToUserAuthkey(custom_id): Observable<UserBasicInfoData> {
    custom_id = jwt.encode(custom_id, environment.jwtSecretKey);
    const data = { custom_id: custom_id };

    return this.httpClient.post<UserBasicInfoData>(API.USER_BASIC_INFO, data);
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


  /**
   * Used to get Members Tyoe Filter Data from the api.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBaseUrls(ip): Observable<any> {
    const httpClient = this.createNewHttpClient();
    const headers = {
      'Device-Type': 'web',
      'IP-Address': ip,
      'Application-Version': environment.versions.appVersion,
      'Request-Id': this.generateLocalId()
    };
    return httpClient.get(environment.APP_CONFIG_URL, { headers }).pipe(
      catchError((error) => {
        return error;
      })
    );
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${this.DEVICE_TYPE}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  /**
   * Setting the application language
   */
  setLanguage() : void {
    if (localStorage.getItem('lang') != null && localStorage.getItem('lang') != undefined && localStorage.getItem('lang') != 'null') {

      this.lang =   localStorage.getItem('lang');
      this.subscriptions.add(this.getLanguageFile(this.lang).subscribe(data => {
        this.nls.languageText = JSON.parse(data);
      }, () => {
        // Handle Error
      }));
    } else {
      this.subscriptions.add(this.getLanguageFile(this.lang).subscribe(data => {
        this.nls.languageText = JSON.parse(data);
      }, () => {
        // Handle Error
      }));
  }}

  resetOpenLanguage() : void {
    if (localStorage.getItem('lang') != null && localStorage.getItem('lang') != undefined && localStorage.getItem('lang') != 'null') {
      const openLang = JSON.parse(localStorage.getItem('openAccess'))?.openLang;
      this.lang =   openLang ? openLang : localStorage.getItem('lang');
      this.subscriptions.add(this.getLanguageFile(this.lang).subscribe(data => {
        this.nls.languageText = JSON.parse(data);
      }, () => {
        // Handle Error
      }));
    } else {
      this.subscriptions.add(this.getLanguageFile(this.lang).subscribe(data => {
        this.nls.languageText = JSON.parse(data);
      }, () => {
        // Handle Error
      }));
  }}
}
