/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Helper imports.
 */
import { MatchUrlsHelper } from '../helper/matchUrls.helper';

/**
 * Create for set base url.
 */
@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let authReq;
    if (request.url.indexOf('amazonaws') !== -1 || request.url.indexOf('https') !== -1) {
      authReq = request.clone({});
    } 
    else {
    const  apiUrl = MatchUrlsHelper.matchBaseUrls(request.url);
      authReq = request.clone({
        url: apiUrl + request.url
      });
    }
    return next.handle(authReq);
  }
}
