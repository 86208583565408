<div cdkDragLockAxis="x" cdkDrag [cdkDragFreeDragPosition]="checkDragPosition()"
    [cdkDragDisabled]="!dqVideoPlayerService.isMiniPlayerVisible" #dqVideoContainer class="miniPlayer"
    (click)="$event.preventDefault();$event.stopPropagation();"
    [ngClass]="{'minimize': dqVideoPlayerService.isMiniPlayerVisible}" (mousemove)="onMouseMove()"
    (mouseleave)="onMouseLeave()">
    <div [ngClass]="{'miniPlayer-overlay': dqVideoPlayerService.isMiniPlayerVisible && isTutorialStarted}">
      <div *ngIf="dqVideoPlayerService.is_agora===webinarType.AGORA">
        <app-lib-channel
          *ngIf="dqVideoPlayerService.agoraOption?.token!='' && doctalkDetail?.status != webinarAgoraStatus.END && doctalkDetail?.status== webinarAgoraStatus.LIVE"
          [streamingType]="doctalkDetail?.streaming_type"
          [appId]="doctalkDetail.media[numbers.ZERO]?.partner?.data?.app_id"
          [channel]="doctalkDetail?.media[numbers.ZERO]?.partner?.data?.chanel" [role]="webinarAgoraRole"
          [token]="dqVideoPlayerService.agoraOption?.token" [uid]="dqVideoPlayerService.agoraOption?.uid"
          [rtmToken]="dqVideoPlayerService.agoraOption?.rtmToken" [rtmUid]="dqVideoPlayerService.agoraOption?.rtmUid"
          [logo]="dqVideoPlayerService.sponsorList" [liveUserCount]="websocketService?.live_user_count"
          [thumbnailImage]="doctalkDetail?.banner" (checkWebinarStatus)="checkWebinarStatus($event)"
          [controls]="dqVideoPlayerService.fullscreenControl" (controlsTrigger)="controlsTrigger($event)"
          (fullscreenStatus)="fullscreenStatus($event)" (joinedStatus)="joinedStatus($event)"
          [deviceCompatibleMsg]="dqVideoPlayerService.deviceCompatibleMsg"
          [sponsorsColorCode]="dqVideoPlayerService.sponsorsColorCode" [ticker]="dqVideoPlayerService.tickerList"
          (sponsorLogoAnalytic)="sponsorLogoAnalytic($event)" (emitMiniPlayerRequest)="openMiniPlayer()"
          [isMiniPlayerVisible]="dqVideoPlayerService.isMiniPlayerVisible" [isPipEnabled]="dqVideoPlayerService.isPipEnabled" [showNewTag]="dqVideoPlayerService.showPipNewTag"
          [autoPlayWebinar]="autoPlayAgoraWebinar"
          (openLivePollPopup)="dqVideoPlayerService.loadLivePollPopup($event)"
          [showLivePollButton]="pollDataSer.showLivePollButton"
          [webinarStatus]="doctalkDetail?.status"
          [hasLivePoll]="pollDataSer.hasLivePoll"
          >
          <!-- Live Poll Modal -->
          <app-live-poll-modal *ngIf="pollDataSer.isLivePollModalShown == true && dqVideoPlayerService.openLivePollWithoutFullScreen"
          [isLoading]=dqVideoPlayerService.isLoading [recievePollData]=dqVideoPlayerService.recievePollData
          (submitLivePoll)="dqVideoPlayerService.submitLivePoll($event)"
          [callingFrom]="dqVideoPlayerService.callingFrom"
          (pollcrossClick)="dqVideoPlayerService.livePollcrossClick($event, doctalkDetail?.product_type, doctalkDetail?.product_type_id)"
          [pollData]="pollDataSer.historicalPollData"
          [livePollIndex]="pollDataSer.livePollIndex"
          [webinarId]="dqVideoPlayerService.webinarId"
          [livePollPopupOverlayHide]="dqVideoPlayerService.livePollPopupOverlayHide"
          [webinarStatus]="doctalkDetail?.status"
          [hasLivePoll]="pollDataSer.hasLivePoll"
          [livePollUserActivity]="pollDataSer.livePollUserActivity">
          </app-live-poll-modal>
          <div *ngIf="dqVideoPlayerService.fullscreenchat && doctalkDetail?.is_live_comment === numbers.ONE" class="full-view-live-comments">
            <app-live-comment [controlEvent]="controlEvent" [pType]="productType.WEBINAR"
              [pId]="doctalkDetail?.webinar_id" [webinarObjData]="doctalkDetail"
              [fullscreenchat]="dqVideoPlayerService.fullscreenchat"
              (controlsEmitchat)="controlsEmitchat($event)"></app-live-comment>
          </div>
          <app-poll-modal *ngIf="dqVideoPlayerService.isPollModalShown == true"
          [isLoading]="dqVideoPlayerService.isLoading" [recievePollData]="dqVideoPlayerService.recievePollData"
            (submitPollClick)="submitPollClick($event)" (pollcrossClick)="pollcrossClick($event)">
          </app-poll-modal>
        </app-lib-channel>

      </div>
      <div
        *ngIf="dqVideoPlayerService.is_agora !==webinarType.AGORA && (doctalkDetail?.status===webinarAgoraStatus.LIVE) && (dqVideoPlayerService.endBannerVisible === false)
  || (doctalkDetail?.status===webinarAgoraStatus.END && doctalkDetail?.media.length>0 && doctalkDetail?.show_end_image===numbers.ZERO)">

        <app-shaka-hls [config]="doctalkDetail?.status===WEBINAR_STATUS.END && doctalkDetail?.media.length>0 && doctalkDetail?.show_end_image === numbers.ZERO ?
    dqVideoPlayerService.recordedConfig : dqVideoPlayerService.liveConfig"
          [resumeTime]="getResumeTime()"
          [videoSrc]="doctalkDetail?.media[numbers.ZERO]?.media_url" [recordedWebinar]="doctalkDetail"
          [pType]="doctalkDetail?.product_type" (receiveSQSInterval)="receiveSQSInterval($event)"
          (shakaPlayerEvent)="checkForPastWebinar($event)" (emitMiniPlayer)="openMiniPlayer()"
          (pipPlayEvent)="pipPlayClicked()"
          [pastWebinarPauseVideoState]="dqVideoPlayerService.playPauseVideoState"
          [showNewTag]="dqVideoPlayerService.showPipNewTag"
          [enableCookies]="(doctalkDetail?.status===WEBINAR_STATUS.LIVE && doctalkDetail?.type===webinarType.DSTREAM  && doctalkDetail?.dstream_data?.is_dstream_secure == numbers.ONE) ? true : false"
          [reloadHls]="reloadHls"
          (loadLivePollPopup)="dqVideoPlayerService.loadLivePollPopup($event)"
          [showLivePollButton]="pollDataSer.showLivePollButton"
          [hasLivePoll]="pollDataSer.hasLivePoll"
          (shakaFullScreenStatus)="dqVideoPlayerService.shakaFullScreenStatus($event)"
          (videoEnd) = "videoEnd()">
            <!-- Live Poll Modal -->
            <app-live-poll-modal *ngIf="pollDataSer.isLivePollModalShown == true && dqVideoPlayerService.openLivePollWithoutFullScreen && dqVideoPlayerService.shakaPlayerFullScreenStatus"
            [isLoading]=dqVideoPlayerService.isLoading [recievePollData]=dqVideoPlayerService.recievePollData
            (submitLivePoll)="dqVideoPlayerService.submitLivePoll($event)"
            [callingFrom]="dqVideoPlayerService.callingFrom"
            (pollcrossClick)="dqVideoPlayerService.livePollcrossClick($event, doctalkDetail?.product_type, doctalkDetail?.product_type_id)"
            [pollData]="pollDataSer.historicalPollData"
            [livePollIndex]="pollDataSer.livePollIndex"
            [webinarId]="dqVideoPlayerService.webinarId"
            [livePollPopupOverlayHide]="dqVideoPlayerService.livePollPopupOverlayHide"
            [webinarStatus]="doctalkDetail?.status"
            [hasLivePoll]="pollDataSer.hasLivePoll"
            [livePollUserActivity]="pollDataSer.livePollUserActivity">
            </app-live-poll-modal>
        </app-shaka-hls>
      </div>
    </div>
    <div class="bg-white p-3" *ngIf="dqVideoPlayerService.isMiniPlayerVisible">
      <p class="paragraph-bold relative text-truncate pb-2">
        {{doctalkDetail?.title}}
      </p>
      <div *ngIf="doctalkDetail?.channel">
        <div class="user-24 float-left mr-2 rounded-circle mb-3">
          <img src="{{doctalkDetail.channel.banner}}" onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'"
            class="user-24 post-pic border rounded-circle" />
        </div>
        <p class="secondary-paragraph-bold text-truncate">
          <span class="color-grey-700">{{doctalkDetail?.channel?.title}}</span></p>
        <p class="secondary-paragraph txt-truncate">{{doctalkDetail?.channel?.powered_by_label}}
          {{doctalkDetail?.channel?.powered_name}}
        </p>
      </div>
    </div>
    <div id="miniPlayerControl">
      <img *ngIf="dqVideoPlayerService.isMiniPlayerVisible" class="miniPlayer-expand-icon" src="assets/images/expand.svg"
        alt="expand" (click)="expandMiniPlayer()">
      <img *ngIf="dqVideoPlayerService.isMiniPlayerVisible" class="miniPlayer-cross-icon"
        src="assets/images/white-close.svg" alt="cross" (click)="closeMiniPlayer()">
    </div>
    <div [ngSwitch]="isTutorialStarted" *ngIf="dqVideoPlayerService.isMiniPlayerVisible && isTutorialStarted">
      <span>
        <img class="miniPlayer-expand-icon" src="assets/images/expand.svg" alt="expand">
        <app-walkthrough *ngSwitchCase="activeStep === activeSteps.STEP1"
          [stepText]="mls?.languageText?.tutorial?.key11 + ' 01'" [title]="mls?.languageText?.tutorial?.key7"
          positionCss="top-left" (emitSkip)="skipTutorial()" (emitNext)="nextStep()"></app-walkthrough>
      </span>
      <span>
        <span class="miniPlayer-play-icon">
          <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24">
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </span>
        <app-walkthrough *ngSwitchCase="activeStep === activeSteps.STEP2"
          [stepText]="mls?.languageText?.tutorial?.key11 + ' 02'" [title]="mls?.languageText?.tutorial?.key8"
          positionCss="bottom-left" (emitSkip)="skipTutorial()" (emitNext)="nextStep()"></app-walkthrough>
      </span>
      <span>
        <img class="miniPlayer-cross-icon" src="assets/images/white-close.svg" alt="cross">
        <app-walkthrough *ngSwitchCase="activeStep === activeSteps.STEP3"
          [stepText]="mls?.languageText?.tutorial?.key11 + ' 03'" [title]="mls?.languageText?.tutorial?.key9"
          [isLastStep]="true" positionCss="top-right" (emitSkip)="skipTutorial()" (emitFinish)="tutorialComplete()">
        </app-walkthrough>
      </span>
    </div>
    <!-- Live Poll Modal -->
    <!-- <app-live-poll-modal *ngIf="pollDataSer.isLivePollModalShown == true && dqVideoPlayerService.openLivePollWithoutFullScreen"
    [isLoading]=dqVideoPlayerService.isLoading [recievePollData]=dqVideoPlayerService.recievePollData
    (submitLivePoll)="dqVideoPlayerService.submitLivePoll($event)"
    [callingFrom]="dqVideoPlayerService.callingFrom"
    (pollcrossClick)="dqVideoPlayerService.livePollcrossClick($event, doctalkDetail?.product_type, doctalkDetail?.product_type_id)"
    [pollData]="pollDataSer.historicalPollData"
    [livePollIndex]="pollDataSer.livePollIndex"
    [webinarId]="dqVideoPlayerService.webinarId"
    [livePollPopupOverlayHide]="dqVideoPlayerService.livePollPopupOverlayHide"
    [webinarStatus]="doctalkDetail?.status"
    [hasLivePoll]="pollDataSer.hasLivePoll"
    [livePollUserActivity]="pollDataSer.livePollUserActivity">
   </app-live-poll-modal> -->
    <div *ngIf="dqVideoPlayerService.isExplicitConsentMandatory && !(consentService.isConsentGiven)" class="overlay-for-explicit-mandetory-consent" (click)="showConsentFormOpen();">
    </div>
  </div>
     <app-tutorial-popover [hidden]="consentService.isConsentFormOpen || dqVideoPlayerService.isSubscribePopupOpen || (dqVideoPlayerService.isExplicitConsentMandatory && !consentService.isConsentGiven)"
      *ngIf="isPopoverOpen" [isInfoModalShown]="isPopoverOpen" (emitStartRequest)="openMiniPlayer()"
      (emitHideModal)="closePopOver()">
    </app-tutorial-popover>
