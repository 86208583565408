  <!-- Audio Player -->
  <div cdkDragLockAxis="x" cdkDrag [ngClass]="{'audio-player-shrink': dqVideoPlayerService.audioPlayerShortForm}" class="audio-mini  d-flex cursor-pointer" (click)="navigateToDetail()">
    <div class="d-flex p-2 audio-mini-content">
      <div class="flex-shrink-0 align-self-center">
        <img class="img-border" [src]="dqVideoPlayerService.audioPlayerDetail?.banner" alt="banner"
          onerror="this.src='assets/themes/icons/banner-placeholder.svg'"
          [width]="dqVideoPlayerService.audioPlayerShortForm ? 28 : 44"
          [height]="dqVideoPlayerService.audioPlayerShortForm ? 28 : 44">
      </div>
      <div [ngClass]="dqVideoPlayerService.audioPlayerShortForm ? 'mt-1' : 'align-self-center'" class="ml-2 text-truncate">
        <p class="secondary-paragraph-bold text-truncate">{{dqVideoPlayerService.audioPlayerDetail?.title}}</p>
        <p *ngIf="!dqVideoPlayerService.audioPlayerShortForm" class="secondary-paragraph text-truncate text-dark-grey">
         <span class="color-grey-700">{{dqVideoPlayerService.audioPlayerDetail?.channelName}}</span>
        </p>
      </div>
    </div>
    <app-shaka-audio shakaStyleCss="audio" [videoSrc]="dqVideoPlayerService.audioPlayerDetail.url"
      [pType]="dqVideoPlayerService.audioPlayerDetail?.productType" [config]="audioConfig"
      [autoplay]="dqVideoPlayerService.autoplay"
      [audioPlayerStatus]="dqVideoPlayerService.audioPlayerStatus"
      [enabledCookies]="dqVideoPlayerService.enableCookiesForAudio"
      (receiveSQSInterval)="receiveSQSInterval($event)"
      (playerStatus)="audioPlayerStatus($event)">
    </app-shaka-audio>
    <div class="audio-mini-controls">
      <img *ngIf="!dqVideoPlayerService.audioPlayerShortForm" class="audio-mini-cross" src="assets/themes/icons/cancel.svg"
        alt="cross" (click)="$event.preventDefault();$event.stopPropagation();closeMiniPlayer()">
      <img class="cursor-pointer" width="16"
        [src]="dqVideoPlayerService.audioPlayerShortForm ? 'assets/themes/icons/arrow-up.svg' : 'assets/themes/icons/down-arrow.svg'"
        alt="down" (click)="$event.preventDefault();$event.stopPropagation();dqVideoPlayerService.toggleAudioShortForm()">
    </div>
  </div>
