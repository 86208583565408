  <!-- Initializing the video player -->
  <div [class]="shakaStyleCss" id="videoContainer" #videoContainer *ngIf="!errorInPlaying else thenBlock">
    <video #videoPlayer id="videoPlayer" width="100%" height="100%" [autoplay]="autoplay"></video>
  </div>
  <ng-template #thenBlock>
    <div class="pt-4 pb-4 text-center">
      <label class="m-0 paragraph-bold">
        {{errorMsg}}
      </label>
    </div>
  </ng-template>