/**
 * Angular imports.
 */
import {
  ElementRef,
  Output,
  Directive,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
  Input,
  HostListener,
  NgZone
} from '@angular/core';
import { SCROLL_TYPE } from '../../constants/app.constants';

/**
 * Modal imports.
 */
import { AppearedCards } from './appear.interface';

/**
 * When any element comes and exit the DOM view it triggers the event.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appear]',
})
export class AppearDirective implements AfterViewInit, OnDestroy {
  /**
   * Contains combination of product type - product id.
   */
  @Input() appearIds: string;

  /**
   * The element that is used as the viewport for checking visibility of the target.
   * Must be the ancestor of the target.
   * Defaults to the browser viewport if not specified or if null.
   */
  @Input() root = null;

  /**
   * Margin around the root. Can have values similar to the CSS margin property,
   * e.g. "10px 20px 30px 40px" (top, right, bottom, left).
   * The values can be percentages.
   * This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
   * Defaults to all zeros.
   */
  @Input() rootMargin = '-50px 0px 0px 0px';

  /**
   * Either a single number or an array of numbers
   * which indicate at what percentage of the target's visibility the observer's callback should be executed.
   * If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5.
   * If you want the callback to run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
   * The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
   * A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
   */
  @Input() threshold = 0;

  /**
   * directive applied on a horizontal scroll
   */
  @Input() horizontal = false;

  /**
   * Emit all the appeared cards.
   */
  @Output() appearedCards = new EventEmitter<AppearedCards>();

  /**
   * Creates a new IntersectionObserver object which will execute a specified callback function
   * when it detects that a target element's visibility has crossed one or more thresholds.
   */
  private observer: IntersectionObserver;

  /**
   * Collection of all appeared cards.
   */
  private cardsCollection: { [key: string]: AppearedCards } = {};

  /**
   * isIntersecting flag to get the visible elements.
   */
  private isIntersecting: boolean;

  /**
   * Create ElementRef instance.
   */
  constructor(private element: ElementRef, private zone: NgZone) { }

  /**
   * Emit the isIntersecting Cards in case of window unload(reload, tab and browser close).
   */
  @HostListener('window:beforeunload.out-zone', ['$event'])
  beforeUnloadHandler(): boolean {
    if (this.cardsCollection[this.appearIds] && this.cardsCollection[this.appearIds].endTime === 0) {
      this.createCardCollection(this.cardsCollection[this.appearIds].startTime, this.getCurrentTimeStamp());
      this.emitAppearedCard(this.cardsCollection[this.appearIds]);
    }
    return true;
  }

  /**
   * Call the onTabFocusOut function in case of tab focus out.
   * Call the onTabFocusIn function in case of tab focus In.
   */
  @HostListener('document:visibilitychange.out-zone', ['$event'])
  visibilitychange(event): void {
    if (event.target.hidden) {
      this.onTabFocusOut();
    } else {
      this.onTabFocusIn();
    }
  }

  /**
   * Emit the isIntersecting Cards in case of tab focus out.
   */
  onTabFocusOut(): void {
    if (this.cardsCollection[this.appearIds] && this.cardsCollection[this.appearIds].endTime === 0) {
      this.createCardCollection(this.cardsCollection[this.appearIds].startTime, this.getCurrentTimeStamp());
      this.emitAppearedCard(this.cardsCollection[this.appearIds]);
    }
  }

  /**
   * Assign the start time on focus in.
   */
  onTabFocusIn(): void {
    if (this.isIntersecting) {
      this.createCardCollection(this.getCurrentTimeStamp(), 0);
    }
  }

  /**
   * Start execution after DOM renders.
   */
  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.initializeIntersectionObserver();
    });
  }

  /**
   * Initialize the Intersection Observer api with options.
   */
  initializeIntersectionObserver(): void {
    const options = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach(this.intersectionObserverEntryCallback);
    }, options);

    this.observer.observe(this.element.nativeElement);
  }
  /**
   * callback eventhandler for intersection observar api
   */
  intersectionObserverEntryCallback = (entry: IntersectionObserverEntry): void => {
    if (entry && entry.isIntersecting) {
      this.isIntersecting = true;
      this.createCardCollection(this.getCurrentTimeStamp(), 0);
    } else {
      this.isIntersecting = false;
      if (this.cardsCollection[this.appearIds] && this.cardsCollection[this.appearIds].startTime) {
        this.createCardCollection(this.cardsCollection[this.appearIds].startTime, this.getCurrentTimeStamp());
        this.emitAppearedCard(this.cardsCollection[this.appearIds]);
      }
    }
    // tslint:disable-next-line: semicolon
  };

  /**
   * Get the current TimeStamp 13 digits.
   */
  getCurrentTimeStamp(): number {
    return new Date().getTime();
  }

  /**
   * Add appeared cards in the key pair collection.
   */
  createCardCollection(startTime: number, endTime: number): void {
    this.cardsCollection[this.appearIds] = {
      startTime: startTime,
      endTime: endTime,
      appearIds: this.appearIds,
      scroll_type: this.horizontal ? SCROLL_TYPE.HORIZONTAL : SCROLL_TYPE.VERTICAL
    };
  }

  /**
   * Emit the appeared card.
   */
  emitAppearedCard(cardCollection: AppearedCards): void {
    this.appearedCards.emit(cardCollection);
  }

  /**
   * Emit the isIntersecting cards when directive is destroyed.
   * Stops the IntersectionObserver object from observing any target.
   */
  ngOnDestroy(): void {
    if (this.cardsCollection[this.appearIds] && this.cardsCollection[this.appearIds].endTime === 0) {
      this.createCardCollection(this.cardsCollection[this.appearIds].startTime, this.getCurrentTimeStamp());
      this.emitAppearedCard(this.cardsCollection[this.appearIds]);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
