/**
 * Angular imports.
 */
import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

/**
 * This directive is used to get the short press event.
 */
@Directive({ selector: '[shortPress]' })

export class ShortPressDirective {

  /**
   * Emit the short press event outside.
   */
  @Output() shortPress = new EventEmitter();

  /**
   * Event subscription.
   * Identify if press is short.
   * Threshold for the press.
   */
  private timeout: any;
  private isShort: boolean;
  private threshold = 500;

  /**
   * Get mousedown event.
   * Start the timer for the short press.
   */
  @HostListener('mousedown') onMouseDown(e) {
    this.isShort = true;
    this.timeout = setTimeout(() => {
      this.isShort = false;
    }, this.threshold);
  }

  /**
   * Get mouseup event.
   * Emit after the isShort true.
   */
  @HostListener('mouseup') onMouseUp(e) {
    if (this.isShort) {
      this.shortPress.emit(e);
    }
    clearTimeout(this.timeout);
  }

  /**
   * Get mouseleave event.
   * Clear the timeout on mouse leave.
   */
  @HostListener('mouseleave') onMouseLeave() {
    clearTimeout(this.timeout);
  }
}
