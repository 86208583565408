<div class="cursor-pointer d-flex justify-content-between" [ngClass]="{'cursor-pointer' : isCursorPointer}"
  (click)="boxClick()">
  <div class="flex-1 min-width-0">
    <app-profile-info [nameCss]="nameCss" [secondaryCss]="secondaryCss" [pic]="info?.pic" [name]="info?.name"
      [badgePic]="info?.badgePic" [defaultSrc]="defaultSrc" [secondary]="profileSecondary" [isBold]="count"
      (onAvatarClick)="emitAvatarClick()">
    </app-profile-info>
  </div>
  <div class="text-right align-self-center icon-hover">
    <p *ngIf="secondaryType" class="primary-small">
      <span [ngClass]="{'color-success': count, 'color-grey-500': !count}">
        {{secondaryType}}
      </span>
    </p>
    <p *ngIf="count" class="count primary-small bg-success float-right">
      <span class="color-white">
        {{count > 99 ? '99+' : count}}
      </span>
    </p>
    <span [ngClass]="{'icon d-block': isEndSrcHoverState, 'custom-tooltip top-right': endSrc && endSrcTooltip}">
      <img *ngIf="endSrc" [src]="endSrc" class="cursor-pointer mr-2" alt="endSrc"
        (click)="$event.preventDefault();$event.stopPropagation();endSrcClick()">
      <span class="tooltip-content" *ngIf="endSrc && endSrcTooltip">{{endSrcTooltip}}</span>
    </span>
  </div>
</div>
