/**
 * Angular imports.
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, CommonModule, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Analytics } from 'analytics2.0'
/**
 * Module imports.
 */
import { PublicModule } from './public-module/public-module.module';
import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ngx-toastr';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { CarouselModule } from 'angular-bootstrap-md';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { AppRoutingModule } from './app-routing.module';

/**
 * Directive imports.
 */
import { ButtonDirective } from './directives/ga.directive';
import { OnboardingEventTrackerDirective } from './directives/onboarding-event-tracker.directive';

/**
 * Component imports.
 */
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { InputMobileComponent } from './onboarding/forms/input-mobile/input-mobile.component';
import { OtpComponent } from './onboarding/forms/otp/otp.component';
import { UsertypeComponent } from './onboarding/forms/usertype/usertype.component';
import { StudenttypeComponent } from './onboarding/forms/studenttype/studenttype.component';
import { UserdetailsComponent } from './onboarding/forms/userdetails/userdetails.component';
import { UploadMrnComponent } from './onboarding/forms/upload-mrn/upload-mrn.component';
import { UniversityComponent } from './onboarding/forms/university/university.component';
import { VerifyGroupUserComponent } from './onboarding/forms/verify-group-user/verify-group-user.component';
import { LeftSectionComponent } from './onboarding/forms/left-section/left-section.component';
import { BackscreenmodalComponent } from './common/backscreenmodal/backscreenmodal.component';
import { SkipOnboardingModalComponent } from './common/skip-onboarding-modal/skip-onboarding-modal.component';
import { UserVerifyHintComponent } from './common/user-verify-hint/user-verify-hint.component';
import { SearchUniversityComponent } from './onboarding/university/search-university/search-university.component';
import { WhitelistingModalComponent } from './onboarding/forms/whitelisting-modal/whitelisting-modal.component';
import { OtpInputComponent } from './onboarding/otp-input/otp-input.component';
import { SurlTokenComponent } from './surl-token/surl-token.component';

/**
 * Service imports.
 */
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataStorageService } from './dataservices/data-storage.service';
import { FunCollectionService } from './common/common-functions/fun-collection.service';
import { ReusableService } from './dataservices/dqVideoPlayer/reusable.service';
import { ChatReusableService } from './dataservices/chat-reusable/chat-reusable.service';
import { MobileWebViewCommunicationService } from './dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';
import { PublicFileUploadKeeperService } from './dataservices/public-file-upload-keeper.service';

/**
 * Import constant.
 */
import { DEEPLINK_SURVEY, ISFROMDEEPLINK, NG_CIRCLE_CONSTANT, NOTIFICATION_TYPE, NUMBERS, SURVEY_ACCESS_TYPE, SURVEY_DEEPLINK_ACTION, TRIGGER_SOURCE } from './constants/app.constants';

/**
 * Import env.
 */
import { environment } from '../environments/environment';

/**
 * Interceptor imports.
 */
import { httpInterceptorProviders } from './interceptor';
/**
 * Module import.
 */
import { AnalyticsEventModule } from '../app/analytics-event/analytics-event.module';
import { MultiselectDdlModule } from './onboarding/multiselect-ddl/multiselect-ddl.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/**
 * Branch sdk import.
 */
import branch from 'branch-sdk';
/**
 * Import Chat Library Module
 */
import { SamvaadModule } from './samvaad/samvaad.module';
import { CustomToastComponent } from './common/custom-toast/custom-toast.component';
branch.init(environment.branchIOKey);
/**
 * Firebase sdk import
 */
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebase);
/*
* Clevertap sdk import.
*/
import clevertap from 'clevertap-web-sdk';
import { ChatModule } from './samvaad/chat/lib/chat.module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    OnboardingComponent,
    InputMobileComponent,
    OtpComponent,
    UsertypeComponent,
    StudenttypeComponent,
    UserdetailsComponent,
    UploadMrnComponent,
    VerifyGroupUserComponent,
    UniversityComponent,
    LeftSectionComponent,
    BackscreenmodalComponent,
    SkipOnboardingModalComponent,
    UserVerifyHintComponent,
    SearchUniversityComponent,
    WhitelistingModalComponent,
    OtpInputComponent,
    CustomToastComponent,
    SurlTokenComponent,
  ],
  imports: [
    PublicModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      autoDismiss: true,
      maxOpened: 2
    }),
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    LightboxModule,
    CommonModule,
    PipesModule,
    NgCircleProgressModule.forRoot({
      radius: NG_CIRCLE_CONSTANT.radius,
      outerStrokeWidth: NG_CIRCLE_CONSTANT.outerStrokeWidth,
      innerStrokeWidth: NG_CIRCLE_CONSTANT.innerStrokeWidth,
      outerStrokeColor: NG_CIRCLE_CONSTANT.outerStrokeColor,
      innerStrokeColor: NG_CIRCLE_CONSTANT.innerStrokeColor,
      animationDuration: NG_CIRCLE_CONSTANT.animationDuration
    }),
    NgHcaptchaModule.forRoot({
      siteKey: environment.hCaptchaSitekey
    }),
    AnalyticsEventModule,
    MultiselectDdlModule,
    ModalModule,
    ChatModule,
    SamvaadModule
  ],
  providers: [
    httpInterceptorProviders,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DeviceDetectorService,
    ButtonDirective,
    OnboardingEventTrackerDirective,
    ReusableService,
    ChatReusableService,
    AsyncPipe,
    Analytics
  ],
  entryComponents: [CustomToastComponent],
  bootstrap: [AppComponent],
})
export class AppModule {

  public utmObject;
  constructor(
    private funCollectionService: FunCollectionService,
    private dss: DataStorageService) {
      this.dss.cleverTapInstance = clevertap;
      branch.data((err, data) => {
        if(data?.data_parsed['+clicked_branch_link']){
          localStorage.setItem('triggerSource', TRIGGER_SOURCE.DEEPLINK);
        }
        if(data.data_parsed.content_type == 'open') {
          this.funCollectionService.openAccessWelcomeScreenData =
          {extras : data.data_parsed.extras,
           pid : data.data_parsed.pid,
           ptype : data.data_parsed.ptype,
          };
          this.funCollectionService.navigateToOpenProductDetail(data.data_parsed.ptype, data.data_parsed.pid)
          return;
        }
          if (data.data_parsed.ptype && data.data_parsed.pid) {
            localStorage.setItem('isComingFromDeepLink', ISFROMDEEPLINK.TRUE);
            localStorage.setItem('newUser','0');
            /**
             * WHen UTM value is coming we set there value in local storage.
             */
            this.utmObject = { 'UTM_Medium':  data.data_parsed["~feature"], 'UTM_Source': data.data_parsed["~channel"], 'UTM_Campaign': data.data_parsed["~campaign"]  };
            localStorage.setItem('utmSourceData',  JSON.stringify(this.utmObject));
          }
 
          if (data.data_parsed.ptype === DEEPLINK_SURVEY.SURVEY &&
              data.data_parsed.sub_type.service === DEEPLINK_SURVEY.DFS) {
               if(data.data_parsed?.sub_type?.identifier === 'EXTERNAL' && !localStorage.getItem('user_auth_key')) {
                this.funCollectionService.accessStrictExternalSurvey(data.data_parsed);
                }
                if(Object.values(SURVEY_DEEPLINK_ACTION).indexOf(data.data_parsed.sub_type.action) > NUMBERS.MINUS_ONE) {
                  this.funCollectionService.surveyDeepLinkRedirection(data.data_parsed.sub_type.action,data.data_parsed.pid, data.data_parsed?.sub_type?.identifier);
                } else {
                  this.funCollectionService.surveyRedirection(data.data_parsed.pid , data.data_parsed.sub_type.tab, data.data_parsed?.sub_type?.identifier);
                }
          } else if (data.data_parsed.ptype === NOTIFICATION_TYPE.USER_STORY) {
            this.funCollectionService.redirectOnSection(data.data_parsed.ptype, data.data_parsed.purl, data.data_parsed.storyCode, data.data_parsed.viewerList);
          } else {
            this.funCollectionService.redirectOnSection(data.data_parsed.ptype, data.data_parsed.pid);
          }
      });

    window.location.href.slice(window.location.href.indexOf('#') + 1).split('/');
    this.readUrlAndTakeAction(window.location.href);
    this.funCollectionService.extractUrlParams();
  }


  readUrlAndTakeAction(url): void {
    const urlobj = window.location.href.slice(url.indexOf('#') + 1).split('/');
    if (urlobj.indexOf('input-mobile') != -1) {
      this.dss.phoneNumber = (urlobj[urlobj.length - 1]);
      this.dss.countryCode = (urlobj[urlobj.length - 2]);
      this.dss.countryId = (urlobj[urlobj.length - 3]);
      this.dss.onlyrunone = false;
    }
  }

}
