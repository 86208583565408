/**
 * Angular imports.
 */
import {
  Injectable,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  Injector,
} from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';

/**
 * Constant imports.
 */
import { NUMBERS } from './../../constants/app.constants';

/**
 * Component imports.
 */
import { DqVideoPlayerComponent } from './dqVideoPlayer/dqVideoPlayer.component';
import { DqAudioPlayerComponent } from './dq-audio-player/dq-audio-player.component';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Service imports.
 */
import { DqVideoPlayerService } from './dq-video-player.service';

/**
 * This service is specific to the reusable directive
 * Do not create another instance of this service.
 */
@Injectable()
export class ReusableService {

  /**
   * Reference of the mini player component.
   */
  private dqVideoPlayerComponentRef: ComponentRef<DqVideoPlayerComponent>;
  private dqAudioPlayerComponentRef: ComponentRef<DqAudioPlayerComponent>;

  /**
   * Reference of the view container.
   */
  private currentViewContainerRef: ViewContainerRef;

  /**
   * Subscription.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router,
    private dqVideoPlayerService: DqVideoPlayerService
  ) {
    this.createDqVideoPlayerComponent();
    this.createDqAudioPlayerComponent();
    this.subscriptions.add(this.router.events.subscribe((event) => {
      if (!this.dqVideoPlayerService.isMiniPlayerVisible && event instanceof ResolveEnd && this.currentViewContainerRef) {
        this.detachDqVideoPlayerComponent(this.currentViewContainerRef);
      }
      if (!this.dqVideoPlayerService.isAudioMiniPlayer && event instanceof ResolveEnd && this.currentViewContainerRef) {
        this.detachDqAudioPlayerComponent(this.currentViewContainerRef);
      }
    }));
    this.subscriptions.add(this.dqVideoPlayerService.exitMiniPlayer
      .subscribe((status: boolean) => {
        if (status) {
          this.destroyDqVideoPlayerComponent();
        }
      }));
    this.subscriptions.add(this.dqVideoPlayerService.exitMiniAudioPlayer
      .subscribe((status: boolean) => {
        if (status) {
          this.destroyDqAudioPlayerComponent();
        }
      }));
  }

  /**
   * Create the mini player component by resolveComponentFactory.
   */
  createDqVideoPlayerComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DqVideoPlayerComponent);
    this.dqVideoPlayerComponentRef = componentFactory.create(this.injector);
  }

  /**
   * Create the mini player component by resolveComponentFactory.
   */
  createDqAudioPlayerComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DqAudioPlayerComponent);
    this.dqAudioPlayerComponentRef = componentFactory.create(this.injector);
  }

  /**
   * Insert the view of the mini player in view container reference.
   */
  attachDqVideoPlayerComponent(viewContainerRef: ViewContainerRef): void {
    this.currentViewContainerRef = viewContainerRef;
    if (this.dqVideoPlayerComponentRef.hostView.destroyed) {
      this.createDqVideoPlayerComponent();
    }
    viewContainerRef.insert(this.dqVideoPlayerComponentRef.hostView);
  }

  /**
   * Insert the view of the mini player in view container reference.
   */
  attachDqAudioPlayerComponent(viewContainerRef: ViewContainerRef): void {
    this.currentViewContainerRef = viewContainerRef;
    if (this.dqAudioPlayerComponentRef.hostView.destroyed) {
      this.createDqAudioPlayerComponent();
    }
    viewContainerRef.insert(this.dqAudioPlayerComponentRef.hostView);
  }

  /**
   * Detach the view of the mini player in view container reference.
   */
  detachDqVideoPlayerComponent(viewContainerRef: ViewContainerRef): void {
    if (viewContainerRef.indexOf(this.dqVideoPlayerComponentRef.hostView) > NUMBERS.MINUS_ONE) {
      viewContainerRef.detach(
        viewContainerRef.indexOf(this.dqVideoPlayerComponentRef.hostView)
      );
    }
  }

  /**
   * Detach the view of the mini player in view container reference.
   */
  detachDqAudioPlayerComponent(viewContainerRef: ViewContainerRef): void {
    if (viewContainerRef.indexOf(this.dqAudioPlayerComponentRef.hostView) > NUMBERS.MINUS_ONE) {
      viewContainerRef.detach(
        viewContainerRef.indexOf(this.dqAudioPlayerComponentRef.hostView)
      );
    }
  }

  /**
   * Destroy the component.
   */
  destroyDqVideoPlayerComponent(): void {
    this.dqVideoPlayerComponentRef.destroy();
    this.dqVideoPlayerService.webinarId = '';
  }

  /**
   * Destroy the component.
   */
  destroyDqAudioPlayerComponent(): void {
    this.dqAudioPlayerComponentRef.destroy();
  }
}
