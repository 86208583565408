/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Library imports.
 */
import { Samvaad, Topic, TopicMe } from "@samvaad/client";

/**
 * Rxjs imports.
 */
import { ReplaySubject } from "rxjs";

/**
 * Modal imports.
 */
import { LoginUser, SamvaadConfig, VALIDATE } from "./chat.interface";

/**
 * Constant imports.
 */
import {
  API_STATUS,
  DEFAULT_MESSAGE_REQUEST,
  ERROR_STATUS,
  META_IDENTIFIER,
  SAMVAAD_AUTH_DATA,
  SUB_LEAVE_DELAYED,
  ZERO,
} from "../lib/constants/chat.constants";

/**
 * THis class is used to login the samvaad library
 * Access the methods of the library
 * Emit them using the Replay subjects.
 */
export class Chat {
  /**
   * Get the instance of the samvaad.
   * Emit the errors for the error handling.
   * Emit the chat list contacts.
   * Emit the press msg event.
   * Emit the on message event.
   * Emit the other topic event.
   * Emit the meta data event.
   * Emit the get message event.
   * Emit the set meta event.
   * Control the chat list loading.
   * Control the chat detail loading.
   * Control the chat loading.
   * Get the me topic.
   * Set the old topic for the subscription.
   * Get the auth token for the image validations.
   */
  public client: Samvaad;
  public error = new ReplaySubject<{ code: number; msg: string }>();
  public chatLists = new ReplaySubject<any>();
  public onPresMsg = new ReplaySubject<any>();
  public onMessage = new ReplaySubject<any>();
  public onOtherTopic = new ReplaySubject<any>();
  public onGetMetaData = new ReplaySubject<any>();
  public onGetMessage = new ReplaySubject<{ msg; topic; from }>();
  public onMetaSet = new ReplaySubject<any>();
  public onSendRecvAndLeave = new ReplaySubject<{
    topic: Topic;
    fetchData?: boolean;
  }>();
  public chatListLoading = true;
  public chatDetailLoading = false;
  public internalChatDetailLoading = false;
  public chatLoading = false;
  public meTopic: TopicMe;
  public oldSubTopic: Topic;
  public authToken: string;
  public retry = 0;

  /**
   * Get the samvaad basic configurations.
   * Sam token for the rest login one time.
   * Token for the auth login
   */
  private samvaadConfig: SamvaadConfig;
  private samToken: string;
  private token: VALIDATE;
  fndTopic: Topic;

  /**
   * Initialize the samvaad with basic configuration.
   */
  constructor(
    samvaadConfig: SamvaadConfig,
    samToken?: string,
    authToken?: string
  ) {
    this.samvaadConfig = samvaadConfig;
    this.samToken = samToken;
    this.authToken = authToken;
    Samvaad.init(samvaadConfig)
      .then(async (client: Samvaad) => {
        this.client = client;
        // this.client.enableLogging(true, true);
        this.initiateListeners();
        this.initiateChat();
      })
      .catch((reason) =>
        this.emitError({
          reason: reason,
          code: ERROR_STATUS.LOGIN_FAILED,
          msg: "Samvaad Can not initiate init",
        })
      );
  }

  /**
   * Connect the client with the DB.
   */
  initiateChat(): void {
    this.client
      .connect()
      .then((): any => {
        this.doLoginForChat();
        this.retry = 0;
      })
      .catch((reason) => {
        if (reason?.code === API_STATUS.FOUR_TWENTY_NINE) {
          if (this.retry < API_STATUS.API_RETRY) {
            this.initiateChat();
          }
          this.retry = ++this.retry;
          return;
        }
        this.emitError({
          reason: reason,
          code: ERROR_STATUS.LOGIN_FAILED,
          msg: "Samvaad Can not initiate connect",
        });
      });
  }

  /**
   * Login the client to the DB.
   * Either use token or rest to login.
   * Rest will use only once to generate the token after that login with token until expire.
   */
  doLoginForChat(): void {
    this.client
      .login(
        this.authToken ? "token" : "rest",
        this.authToken ? this.authToken : this.samToken
      )
      .then((value: LoginUser) => {
        if (value?.code === API_STATUS.FIVE_HUNDRED) {
          if (this.retry < API_STATUS.API_RETRY) {
            this.doLoginForChat();
          }
          this.retry = ++this.retry;
          return;
        }
        if (value?.code === API_STATUS.TWO_HUNDRED) {
          this.getMeTopic();
          localStorage.setItem(SAMVAAD_AUTH_DATA, JSON.stringify(value));
          this.authToken = this.client.getAuthToken().token;
          this.retry = 0;
        }
      })
      .catch((err) => {
        err = err?.toString();
        if (
          this.retry < API_STATUS.API_RETRY &&
          (err?.indexOf(API_STATUS.FOUR_HUNDRED) > -1 ||
            err?.indexOf(API_STATUS.FOUR_HUNDRED_ONE) > -1 ||
            err?.indexOf(ERROR_STATUS.MALFORMED) > -1 ||
            err?.indexOf(ERROR_STATUS.AUTHENTICATION_FAILED) > -1)
        ) {
          this.error.next({ code: API_STATUS.FOUR_HUNDRED_ONE, msg: err });
          this.retry = ++this.retry;
        }
      });
  }

  /**
   * Emit the internal errors.
   */
  emitError(reason: { reason?: any; code: number; msg: string }): void {
    this.error.next(reason);
  }

  /**
   *  Check the connection with DB.
   */
  checkConnection(): boolean {
    return this.client.isConnected();
  }

  /**
   * Initiate the client listeners events.
   */
  initiateListeners(): void {
    this.client.onDisconnect = (disconnect: any) => {
        //No code
    };
    this.client.onAutoreconnectIteration = (autoconnect: any) => {
      if (autoconnect === ZERO) {
        this.initiateChat();
      }
    };
    this.client.onMessage = (info: any) => {
      this.onMessage.next(info);
    };
    this.client.onDataMessage = (info: any) => {
      const topic = this.getTopic(info.topic);
      this.onGetMessage.next({ msg: info, topic, from: "SERVER" });
    };
    this.client.onPresMessage = (onPresMessage: any) => {
      this.onPresMsg.next(onPresMessage);
    };
    this.listenToRecvAndLeaveEvent();
  }

  /**
   * Get the me topic for the me information.
   */
  getMeTopic(): void {
    if (this.meTopic) {
      this.meTopic.leave();
    }
    this.meTopic = this.client.getMeTopic();
    this.getListFromCache();
    this.meTopic
      .subscribe(
        this.meTopic
          .startMetaQuery()
          .withLaterSub()
          .withDesc()
          .withTags()
          .withCred()
          .build()
      )
      .then(() => {
        this.fndTopic = this.getTopicWithoutId();
        this.fndTopic
          .subscribe(this.fndTopic.startMetaQuery().build())
          .then(async (value) => {
            //No code
          });
        this.initializeMeTopic();
      });
  }

  /**
   * Get the chat lists from the cache.
   */
  getListFromCache(): void {
    this.meTopic.contacts((contact: Topic) => {
      this.chatLists.next(Object.assign({}, contact));
    });
  }

  /**
   * Used to leave the me topic subscription.
   */
  leaveMeTopic(): void {
    this.meTopic.leave();
  }

  /**
   * Leave the other topic subscription.
   */
  leaveOtherTopic(topic: Topic): any {
    if (topic.isSubscribed()) {
      return topic.leaveDelayed(false, SUB_LEAVE_DELAYED);
    }
  }

  /**
   * Get the me topic event listeners.
   */
  initializeMeTopic(): void {
    this.meTopic.onSubsUpdated = (val) => {
      //No code
    };
    this.meTopic.onMeta = (meta: { hasOwnProperty: (arg0: string) => any }) => {
      // eslint-disable-next-line no-prototype-builtins
      if (meta.hasOwnProperty("sub")) {
        this.chatLists.next(meta);
      }
      // eslint-disable-next-line no-prototype-builtins
      if (meta.hasOwnProperty("tags")) {
        this.chatListLoading = false;
      }
    };
  }

  /**
   * Get the topic from the topic id.
   */
  getTopic(topicId: string): Topic {
    return this.client.getTopic(topicId);
  }

  /**
   * Used to get the other user topic id.
   * It first unsubscribe the old topic then subscribe the current topic.
   */
  async getOtherTopic(topicId: string): Promise<void> {
    if (this.oldSubTopic) {
      await this.leaveOtherTopic(this.oldSubTopic);
    }
    const topic = this.getTopic(topicId);
    if (topic.acs.isJoiner() && !topic.isSubscribed()) {
      await topic.subscribe().catch(() => {
        this.chatDetailLoading = false;
      });
      this.setOldTopic(topic);
      this.onOtherTopic.next(topic);
    } else {
      this.chatDetailLoading = false;
    }
  }

  /**
   * Set the old topic.
   */
  setOldTopic(topic: any): void {
    this.oldSubTopic = topic;
  }

  /**
   * Check if old topic remains and unsubscribe.
   */
  async checkForOldTopic(): Promise<void> {
    if (this.oldSubTopic) {
      await this.leaveOtherTopic(this.oldSubTopic);
    }
  }

  /**
   * Get the meta data.
   */
  getMetaData(topic: Topic, params: any, identifier: string): void {
    topic
      .getMeta(params)
      .then((metaData) => {
        this.onGetMetaData.next({ topic, metaData, identifier });
      })
      .catch((err) => this.emitError(err));
  }

  /**
   * Get the unread count from the topic id.
   */
  getUnreadCount(topicId: string): number {
    const topic = this.getTopic(topicId);
    return topic.unread;
  }

  /**
   * Get the fnd topic for the new users.
   */
  getTopicWithoutId(): Topic {
    return this.client.getFndTopic();
  }

  /**
   * Set the meta for the new user.
   */
  async setMetaInTopic(
    topic: Topic,
    params,
    identifier = META_IDENTIFIER.ADD_USER
  ): Promise<void> {
    if (topic.isSubscribed()) {
      topic
        .setMeta(params)
        .then(() => {
          this.getMetaData(
            topic,
            topic.startMetaQuery().withSub().build(),
            identifier
          );
        })
        .catch((err) => {
          this.emitError(err);
        });
    }
  }

  /**
   * Send the read receipt to the others.
   */
  sendReadReceipt(topic: Topic): void {
    if (topic.seq > topic.read) {
      topic.noteRead(topic.seq);
    }
  }

  /**
   * Send the read receipt and leave topic
   */
  listenToRecvAndLeaveEvent(): void {
    this.onSendRecvAndLeave.subscribe(
      async (req: { topic: Topic; fetchData?: boolean }) => {
        if (!req.topic.isSubscribed()) {
          await req.topic.subscribe();
        }
        // if at this point i.e. after subscribed and before sending fetch data req,
        // <- data directly came, then that could be the issue
        if (req.fetchData) {
          const ranges: { hi: number; seq: number }[] =
            req.topic.getMissingRanges();
          let fetchFromSeq = Math.min(
            req.topic.maxMsgSeq() + 1,
            req.topic.seq + 1
          );
          let fetchTillSeq = undefined;
          for (const range of ranges) {
            // this will come in play only for the first time at the time of group creation
            if (range.hi >= 1 && range.seq == 1) {
              fetchFromSeq = Math.min(1, fetchFromSeq);
              fetchTillSeq = range.hi + 1;
            }
          }
          await req.topic.getMeta(
            req.topic
              .startMetaQuery()
              .withData(fetchFromSeq, fetchTillSeq, DEFAULT_MESSAGE_REQUEST)
              .build()
          );
        }
        this.sendDelivered(req.topic);
        this.leaveOtherTopic(req.topic);
      }
    );
  }

  /**
   * Send the delivery status to the others.
   */
  sendDelivered(topic: Topic): void {
    if (topic.seq > topic.recv) {
      topic.noteRecv(topic.seq);
    }
  }

  /**
   * Disconnect from the client.
   */
  async disconnectClient(): Promise<void> {
    await this.client?.disconnect();
  }

  /**
   * Clear the index db for the client.
   */
  async clearStorage(): Promise<void> {
    await this.client?.clearStorage();
  }

  /**
   * Leave the me topic.
   */
  async logout(): Promise<void> {
    await this.meTopic?.leave();
  }
}
