import { PollOptions } from './PollInterface';

export class Poll {

    /**
     * This will contain poll id of the webinar.
     */
    private _pollId: number;

    /**
     * This will contain question of the webinar.
     */
    private _question: string;

    /**
     * This will contain pollState of the webinar.
     */
    private _pollState: string;

    /**
     * This will contain options of the webinar.
     */
    private _options: PollOptions[];

    /**
     * Used to get the PollID.
     */
    get pollId(): number {
        return this._pollId;
    }

    /**
     * Used to set the PollID.
     */
    set pollId(val: number) {
        this._pollId = val;
    }

    /**
     * Used to set the Question.
     */
    get question(): string {
        return this._question;
    }

    /**
     * Used to set the Question.
     */
    set question(val: string) {
        this._question = val;
    }

    /**
     * Used to get the Poll State.
     */
    get pollState(): string {
        return this._pollState;
    }

    /**
     * Used to set the Poll State.
     */
    set pollState(val: string) {
        this._pollState = val;
    }

    /**
     * Used to get the Poll Options.
     */
    get options(): PollOptions[] {
        return this._options;
    }

    /**
     * Used to set the Polloptions.
     */
    set options(val: PollOptions[]) {
        this._options = val;
    }
}
