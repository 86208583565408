<!-- speciality select -->
<app-speciality *ngIf="isSpecialityShown" [isSpecialityShown]="isSpecialityShown" [speciality_select_type]="speciality_select_type"></app-speciality>
<!-- end speciality select -->

<!-- get sample start -->
<app-product-get-sample *ngIf="isGetsampleModal==true" (resetProductGetsampleModal) = resetProductGetsampleModal($event) [isGetsampleModal]=isGetsampleModal [JournalData]=BannerObjData [product_type]=' ' [product_type_id]=' ' [drug_id]=notificationObj.target_id></app-product-get-sample>
<!-- get sample end -->



<!--Verification modal-->
<app-verification-success
  [isShownWebModal]="isVerificationOkay"
  [verificationContent]="verificationContent">
</app-verification-success>

<!-- 2.0 -->
<!-- mobile HEADER -->
<ng-container *ngIf="!mvc.checkAuthenticByPass() && !mvc.isAuthenticByPass">
<header class="d-block d-lg-none pt-2 pb-2 fixed-top">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-7 col-7 pr-0">
            <div [hidden]="dss.feedDetailBack">
              <span (click)="backclicked()" class="cursor-pointer mt-2 float-left secondary-paragraph mr-1">
                <svg id="Layer_back" data-name="Layer back" width="20px" viewBox="0 0 25 25">
                  <defs>
                    <style>
                      .header-back {
                        fill: none;
                        stroke: #444445;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 1.5px;
                      }
                    </style>
                  </defs>
                  <polyline class="header-back" points="9.82 4 1.32 12.5 9.82 21" />
                  <line class="header-back" x1="2.21" y1="12.5" x2="23.68" y2="12.5" />
                </svg>
                {{mls?.languageText?.feed_detail?.key10}}</span><span
                *ngIf="mls?.is_lskey_display==1">(feed_detail.key10)</span>
            </div>
            <div [hidden]="dss.feedDetailOther">
              <a class="cursor-pointer mr-2" (click)="openExplore()"
                [eventTracker]='{"category":"Header section","action":ems?.HeaderComponent?.explore_clicked}'>
                <img src="assets/themes/icons/new-hamburger-icon.svg" width="20px" alt="hamburger">
              </a>

              <a class="cursor-pointer" (click)="navigateToHome()"
                [eventTracker]='{"category":"Header section","action":ems?.HeaderComponent?.logo_clicked}'>

                <!-- <svg width="40px" viewBox="0 0 16 16"><defs><style>.logo-icon{fill:#ee3c4d;}</style></defs><g id="Layer_3" data-name="Layer 3"><path class="logo-icon" d="M8,.5A7.5,7.5,0,1,0,15.5,8,7.5,7.5,0,0,0,8,.5ZM6.39,10.61a3.36,3.36,0,0,1,0-6.72H7.71V4.94H6.39a2.31,2.31,0,0,0,0,4.62H7.71v1Zm3.38,0H9.69L8.46,12.76V10.61h0V9.69h0V9.56H9.77a2.31,2.31,0,1,0,0-4.62H8.45V3.89H9.77a3.36,3.36,0,1,1,0,6.72Z"/></g></svg> -->
                <img *ngIf="dss.dynamicAppLogo !== numbers.TWO" src="assets/themes/icons/logo.svg"
                  alt="docquity logo" class="logo-size" />
                <img *ngIf="dss.dynamicAppLogo === numbers.TWO" width="46" height="40"
                  src="assets\images\docquity_christmas_logo.gif" alt="docquity christmas logo">
              </a>
            </div>

          </div>

          <div class="nav-menu col-lg-5 col-5 text-right pr-4 pl-0 mt-1">
            <span class="mr-3 cursor-pointer" (click)="navigateTomobilesearch()">
              <img src="assets/themes/icons/search-icon.svg" alt="Search icon" />
            </span>


            <span *ngIf="chatAllow" class="cursor-pointer mr-3 position-relative"
             (click)="$event.preventDefault();$event.stopPropagation();triggerChat()">
              <span *ngIf="chatUnreadCount > 0" class="count secondary-small mt-1">
                <span class="color-white">
                  {{chatUnreadCount > maxUnreadCount.COUNT ? maxUnreadCount.TEXT : chatUnreadCount}}
                </span>
              </span>
              <ng-container *ngTemplateOutlet="chat"></ng-container>
            </span>

            <span *ngIf="this.mls.featureOnOffList?.featureList?.notification?.value || !mls.userLoginFlow" class="position-relative cursor-pointer" (click)="navigateToNotification()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key12,"product_type":"", "product_id":""}'>
              <img src="assets/themes/icons/headers/icon-bell.svg" width="20px" />
              <span class="nav-item__badge text-center" style="left: 14px !important; top: 0px !important;"
                *ngIf="notification_count>0 && show_notification_count==true">
                <small>{{notification_count}}</small></span>
            </span>



          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- mobile menu -->
  <div [ngClass]="[open_explore == true ? 'explore-side-black' : '']" (click)="hideExpore()"></div>
  <div [@.disabled]="isDisabled">
    <div [@slideInOut] class="explore-side-hidden ps" [perfectScrollbar]="config"
      [ngClass]="[open_explore == true ? 'link_move_left' : '']" id="explore_div" *ngIf="open_explore==true">
      <div class="explore-user-part secondary-paragraph">
        <div class="user-main-div p-3 bg-deep-green-100">
          <!-- <span (click)="hideExpore()" class="cursor-pointer">X</span> -->
          <div class="user-box clearfix" style="cursor: pointer;" (click)="navigateToProfile()"
            [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key1,"product_type":"", "product_id":""}'>
            <div class="ex-u-pic mr-2 position-relative">

              <app-user-pofile-pic *ngIf="hamburgur_pic_image?.prflfname!=''"
                [profile_pic_image]="hamburgur_pic_image"></app-user-pofile-pic>

              <!-- <img class="user-40 post-pic rounded-circle" [src]="masterService.login_user_profile_pic | imagefilter:'./assets/images/profile.jpg'"   onError="this.src='assets/images/profile.jpg';"> -->
            </div>
            <div class="user_name float-left w-75">
              <div class="row">
                <div class="col-md-9">
                  <p class="paragraph-bold">{{dss?.user_profile_data?.profile?.first_name+'
                    '+dss?.user_profile_data?.profile?.middle_name+' '+dss?.user_profile_data?.profile?.last_name}}</p>
                  <p class="primary-small">
                    <span class="color-grey-700">{{this.primary_speciality}}</span></p>
                </div>
                <div class="col-md-3 p-0 text-left p"><button class="btn-design small outline bg-transparent responsive-margin">view</button></div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-mobile-menu">
          <!-- post case -->
          <div (click)="closehamburgur()">
            <button *ngIf="this.mls.featureOnOffList.featureList.post.value || !mls.userLoginFlow" [disabled]="isPostButtonDisable"
              class="ml-3 my-3 tooltip-with-arrow btn-design filled medium"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key10,"product_type":"", "product_id":""}'
              (click)="dss.show_feed_post_modal.next(1)"><img class="mr-2"
                width="16" src="assets/themes/icons/headers/icon-add-post.svg" /><span>{{mls?.languageText?.grandround_header?.key10}}</span><span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key10)</span>
              <span *ngIf="isPostButtonDisable" class="tooltiptext">{{mls.languageText.post_process.key6}}</span>
            </button>
          </div>
          <div *ngFor="let hdr of nav_new">
            <a class="secondary-paragraph p-3 d-block cursor-pointer" (click)="navigateToSection(hdr.navigate_url,hdr.id,hdr.value, true)">
              <span class="mr-2">
                <img src="assets/themes/icons/headers/{{hdr.menu_icon}}.svg" [alt]="hdr.name" />
              </span>{{hdr.name}}</a>
          </div>
          <div>
            <a class="secondary-paragraph p-3 d-block" (click)="navigateToexplore()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key11,"product_type":"", "product_id":""}'>
              <img
                width="20px" class="mr-2" src="assets/themes/icons/headers/explore-icon.svg" />
              {{mls?.languageText?.grandround_header?.key11}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key11)</span></a>
          </div>

          <!-- menu -->
          <div class="separator-line-top">
            <a class="secondary-paragraph p-3 d-block" (click)="navigateToProfile()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key1,"product_type":"", "product_id":""}'><img
                class="mr-2" width="20px" src="assets/themes/icons/headers/profile-dd.svg" />
              {{mls?.languageText?.grandround_header?.key1}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key1)</span></a>
          </div>
          <!-- TODO: Nilesh : Commented code for refer n earn -->
          <!-- <div>
      <a class="prfl-list p-3 d-block" (click)="navigateToReferal()" [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key2,"product_type":"", "product_id":""}'><img width="20px" class="mr-1" src="assets/images/grandround/user-refferal.svg"/> {{mls?.languageText?.grandround_header?.key2}}<span *ngIf="mls?.is_lskey_display==1">(grandround_header.key2)</span></a>
      </div> -->
          <div class="list-hover" *ngIf="this.mls.featureOnOffList.featureList.myVault.value || !mls.userLoginFlow">
            <a class="secondary-paragraph p-3 d-block" (click)="navigateToUserVault()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key3,"product_type":"", "product_id":""}'><img
                width="20px" class="mr-2" src="assets/themes/icons/headers/vault-dd.svg" />
              {{mls?.languageText?.grandround_header?.key3}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key3)</span></a>
          </div>
          <div>
            <a *ngIf="this.mls.featureOnOffList.featureList.calendar.value || !mls.userLoginFlow" class="secondary-paragraph p-3 d-block" (click)="navigateToUserCalender()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key4,"product_type":"", "product_id":""}'><img
                width="20px" class="mr-2" src="assets/themes/icons/headers/profile-calender.svg" />
              {{mls?.languageText?.grandround_header?.key4}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key4)</span></a>
          </div>
          <div>
            <a *ngIf="this.mls.featureOnOffList.featureList.bookmark.value || !mls.userLoginFlow" class="secondary-paragraph p-3 d-block" (click)="navigateToUserBookmark()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key5,"product_type":"", "product_id":""}'><img
                class="mr-2" width="20px" src="assets/themes/icons/headers/bookmark-dd.svg" />
              {{mls?.languageText?.grandround_header?.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key5)</span></a>
          </div>
          <div>
            <a class="secondary-paragraph p-3 d-block" (click)="navigateToSettings()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key6,"product_type":"", "product_id":""}'><img
                class="mr-2" width="20px" src="assets/themes/icons/headers/profile-settings.svg" />
              {{mls?.languageText?.grandround_header?.key6}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key6)</span></a>
          </div>
          <div *ngIf="this.mls.featureOnOffList.featureList.sendFeedback.value || !mls.userLoginFlow">
            <a class="secondary-paragraph p-3 d-block" (click)="navigateToFeedback()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key7,"product_type":"", "product_id":""}'><img
                class="mr-2" width="20px" src="assets/themes/icons/headers/help-support.svg" />
              {{mls?.languageText?.grandround_header?.key7}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key7)</span></a>
          </div>
          <div>
            <a class="secondary-paragraph p-3 d-block" (click)="navigateTotnc()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key8,"product_type":"", "product_id":""}'><img
                width="20px" class="mr-2" src="assets/themes/icons/headers/profile-tnc.svg" />
              {{mls?.languageText?.grandround_header?.key8}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key8)</span></a>
          </div>
          <div>
            <a class="secondary-paragraph p-3 d-block" (click)="logutHamburger()" id="logout"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key9,"product_type":"", "product_id":""}'>
              <img class="mr-2" width="20px"src="assets/themes/icons/headers/logout.svg" alt="log-out">
              {{mls?.languageText?.grandround_header?.key9}}<span
                *ngIf="mls?.is_lskey_display==1">(grandround_header.key9)</span></a>
          </div>
          <!-- end menu -->
          <div class="p-3">
            <a class="secondary-paragraph p-4 d-block cursor-pointer separator-line-top  text-center" (click)="navigateToHome()"
              [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key14,"product_type":"", "product_id":""}'>
              <img *ngIf="dss.dynamicAppLogo !== numbers.TWO" src="assets/themes/icons/logo.svg" alt="docquity logo" width="46" height="40"/>
              <img *ngIf="dss.dynamicAppLogo === numbers.TWO" src="assets\images\docquity_christmas_logo.gif"
                alt="docquity christmas logo">
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>

</header>
<!-- desktop header -->
<header class="d-lg-block d-none fixed-top">
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-4 col-9" style="padding-top:13px;">
              <div class="row align-items-center">
                <div class="col-md-2 col-2 pr-0">
                  <a class="cursor-pointer" (click)="navigateToHome()"
                    [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key14,"product_type":"", "product_id":""}'>
                    <img *ngIf="dss.dynamicAppLogo !== numbers.TWO" src="assets/themes/icons/logo.svg" alt="docquity logo" width="46" height="40"/>
                    <img *ngIf="dss.dynamicAppLogo === numbers.TWO" src="assets\images\docquity_christmas_logo.gif"
                      alt="docquity christmas logo" width="46" height="40">
                  </a>

                  <!-- tooltip -->
                  <!-- <app-custom-tooltip></app-custom-tooltip> -->
                </div>
                <div class="col-md-10 col-10 pr-0">
                  <!-- id="showsearch" class="showsearch"  -->
                  <ng-container *ngIf="masterData?.data?.page_info['search']?.showSearch==1">
                    <app-searching [search_place_holder]="masterData.data.page_info['search'].searchPlaceholder"
                      [focus_place_holder]="masterData.data.page_info['search'].focusSearchText">
                    </app-searching>
                  </ng-container>


                </div>
              </div>
            </div>

            <div class="hz-tabs col-lg-8 col-3 header-menu">
              <ul class="tab-items tabs mb-0 align-items-end justify-content-end">
                <li *ngFor="let hdr of nav_new">
                  <label *ngIf="((hdr.name === 'CME' && this.mls.featureOnOffList.moduleList.CME.value) || !mls.userLoginFlow || hdr.name === 'Home')"  class="cursor-pointer d-flex py-2 px-1 hover-icon"
                    [ngClass]="{'active': activeModule==hdr.module_name  && no_active==false}"
                    (click)="navigateToSection(hdr.navigate_url,hdr.id, hdr.value,false)">
                    <div class="icon">
                      <img width="24" src="assets/themes/icons/headers/{{hdr.menu_icon}}{{activeModule==hdr.module_name ? '-active':''}}.svg"
                        [alt]="hdr.name" />
                    </div>
                    <div class="secondary-paragraph "><span [ngClass]="{'color-grey-500': activeModule!=hdr.module_name}">{{hdr.name}}</span></div>
                  </label>
                </li>
                <li>
                  <label *ngIf="(this.mls.featureOnOffList.moduleList.CALENDAR.value || !mls.userLoginFlow)" class="cursor-pointer d-flex py-2 px-1 hover-icon"
                    (click)="funCollectionService.navigateToCalenderModule()"
                    [ngClass]="{'active': callingfrom === this.mls.googleEventText.calendar.category}">
                    <div class="icon">
                    <img src="assets/themes/icons/headers/icon-calendar{{callingfrom === this.mls.googleEventText.calendar.category?'-active':''}}.svg"
                      width="24"
                      height="24" />
                    </div>
                    <div class="secondary-paragraph"><span [ngClass]="{'color-grey-500': callingfrom != this.mls.googleEventText.calendar.category}">{{mls.languageText.grandround_header.key4}}</span> </div>
                  </label>
                </li>
                <li *ngIf="chatAllow && (this.mls.featureOnOffList.moduleList.CHAT.value || !mls.userLoginFlow) " (click)="$event.preventDefault();$event.stopPropagation();triggerChat()">
                  <label class="px-0 cursor-pointer mr-2 ml-3 pb-3 hover-icon"
                    [ngClass]="{'active': callingfrom === 'chat'}"
                     >
                    <span *ngIf="chatUnreadCount > 0" class="count secondary-small mt-1">
                      <span class="color-white">
                        {{chatUnreadCount > maxUnreadCount.COUNT ? maxUnreadCount.TEXT : chatUnreadCount}}
                      </span>
                    </span>
                    <ng-container *ngTemplateOutlet="chat"></ng-container>
                    <div class="secondary-paragraph"><span class="color-grey-500">{{mls?.languageText?.samvaad?.key14}}</span> </div>
                  </label>
                </li>
                <li>
                  <label *ngIf="(this.mls.featureOnOffList.moduleList.NOTIFICATION.value || !mls.userLoginFlow)" class="position-relative cursor-pointer d-flex py-2 px-1 hover-icon" href="javascript:void(0);" (click)="navigateToNotification()"
                    [eventTracker]='{"category":"Header section","action":ems?.HeaderComponent?.notification_clicked}'
                    [ngClass]="{'active': callingfrom === 'notification' && (dss.isNotificationMenuSelected$ | async)}">
                    <div class="icon">
                      <img src="assets/themes/icons/headers/icon-bell{{callingfrom === 'notification' && (dss.isNotificationMenuSelected$ | async)?'-active':''}}.svg"
                        width="24"
                        height="24"/>
                    </div>
                    <span class="position-absolute notification-badge"
                      *ngIf="notification_count>0 && show_notification_count==true">
                      <span class="secondary-paragraph text-white">{{notification_count}}</span>
                    </span>
                    <div class="secondary-paragraph"><span [ngClass]="{'color-grey-500': callingfrom != ('notification' && (dss.isNotificationMenuSelected$ | async)) }">{{mls.languageText.profile_notification.key1}}</span></div>
                  </label>
                  <div class="custom-dropdown profile-popover d-none" infiniteScroll nfinite-scroll [infiniteScrollDistance]="5"
                    [infiniteScrollThrottle]="500" [scrollWindow]="false">
                    <ul class="clearfix">
                      <!--Loaders-->
                      <div *ngIf="!finished" class='text-center'>
                        <img src="assets/themes/images/img-video-loader.gif" width="40" height="40" id="loadmore_post" />
                      </div>

                      <!--EOf Loaders-->
                      <div *ngFor="let nl of notification_list | slice:0:5; let idx=index">
                        <li [ngClass]="{'unread':nl.read_status=='unread'}" (click)="notificationAction(nl,idx)"
                          [eventTracker]='{"category":"notification","action":ems.HeaderComponent.notification_list_clicked}'>
                          <a><span><img class="user-40" src="{{nl?.notification_pic}}"
                                onerror="this.src='assets/images/grandround/image_placeholder.svg'"></span><strong></strong>
                            <p innerHTML="{{nl.message}}"></p><span>{{nl.date_of_creation | timedifference}}</span>
                          </a></li>
                      </div>

                      <div class="text-center cursor-pointer" (click)="navigateToNotification()"
                        *ngIf="notification_list?.length>5">View all</div>
                      <div style="text-align:center; margin-top:10px;">{{no_record}}</div>
                    </ul>
                  </div>
                </li>
                <li>
                  <label *ngIf="(this.mls.featureOnOffList.moduleList.POST.value || !mls.userLoginFlow)" class="pl-1">
                  <button [disabled]="isPostButtonDisable" class="tooltip-list chip large filled icon-position-left post-btn button-border"
                    (click)="openPost()"
                    [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key10,"product_type":"", "product_id":""}'>
                    <img src="assets/themes/icons/headers/icon-add-post.svg" alt="Add post icon" class="mr-3" />
                    <span>{{mls?.languageText?.grandround_header?.key12}}</span><span
                      *ngIf="mls?.is_lskey_display==1">(grandround_header.key12)</span>
                    <span *ngIf="isPostButtonDisable" class="tooltiptext">{{mls.languageText.post_process.key6}}</span>
                  </button>
                  <app-custom-tooltip *ngIf="tooltip_data?.length>0" [tooltip_data]="tooltip_data"
                    [view_identifier]="'POST_TAG'" [position]="'bottom'"></app-custom-tooltip>
                  </label>
                </li>
                <div class="position-relative custom-dropdown profile-popover mb-1">
                  <label class="cursor-pointer" (click)="callprofilesection()">
                    <div class="clearfix">
                      <div id="prfile-header" class="float-left mr-2 box-size-40">
                        <app-user-pofile-pic
                          [profile_pic_image]="profile_pic_image">
                        </app-user-pofile-pic>
                      </div>
                      <div [class]=" (this.mls.featureOnOffList.moduleList.POST.value 
                      || this.mls.featureOnOffList.moduleList.CHAT.value
                      || this.mls.featureOnOffList.moduleList.NOTIFICATION.value 
                      || this.mls.featureOnOffList.moduleList.CALENDAR.value 
                      || this.mls.featureOnOffList.moduleList.CME.value ) || !mls.userLoginFlow ?'float-left text-truncate heading-5 mt-2' : 'float-left heading-5 mt-2'">
                        <span class="color-black">{{dss?.user_profile_data?.profile?.first_name}}</span>
                      </div>
                      <img src="assets/themes/icons/right-arrow-head.svg" class="icon-right mt-2" alt="down arrow"/>
                    </div>
                  </label>
                  <ul [hidden]="profileDropdown" infiniteScroll [infiniteScrollDistance]="5"
                    [infiniteScrollThrottle]="500" [scrollWindow]="false">
                    <!-- menu -->

                    <li (click)="navigateToProfile()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key1,"product_type":"", "product_id":""}'><img
                        class="mr-1" width="20px" src="assets/themes/icons/headers/profile-dd.svg" alt="profile" />
                      {{mls?.languageText?.grandround_header?.key1}} <span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key1)</span></li>
                    <!-- TODO: Nilesh : Commented code for refer n earn -->
                    <!-- <li (click)="navigateToReferal()" [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key2,"product_type":"", "product_id":""}'><img width="20px" class="mr-1" src="assets/images/grandround/user-refferal.svg"/> {{mls?.languageText?.grandround_header?.key2}}<span *ngIf="mls?.is_lskey_display==1">(grandround_header.key2)</span></li> -->

                    <li *ngIf="(this.mls.featureOnOffList.featureList.myVault.value || !mls.userLoginFlow)" (click)="navigateToUserVault()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key3,"product_type":"", "product_id":""}'><img
                        width="20px" class="mr-1" src="assets/themes/icons/headers/vault-dd.svg" alt="vault" />
                      {{mls?.languageText?.grandround_header?.key3}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key3)</span></li>

                    <li *ngIf="(this.mls.featureOnOffList.moduleList.CALENDAR.value || !mls.userLoginFlow)" (click)="navigateToUserCalender()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key4,"product_type":"", "product_id":""}'><img
                        width="20px" class="mr-1" src="assets/themes/icons/headers/profile-calender.svg" alt="calendar" />
                      {{mls?.languageText?.grandround_header?.key4}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key4)</span></li>

                    <li *ngIf="(this.mls.featureOnOffList.moduleList.BOOKMARK_LIST.value || !mls.userLoginFlow)" (click)="navigateToUserBookmark()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key5,"product_type":"", "product_id":""}'><img
                        class="mr-1" width="20px" src="assets/themes/icons/headers/bookmark-dd.svg" alt="bookmark" />
                      {{mls?.languageText?.grandround_header?.key5}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key5)</span></li>

                    <li (click)="navigateToSettings()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key6,"product_type":"", "product_id":""}'><img
                        class="mr-1" width="20px" src="assets/themes/icons/headers/profile-settings.svg" alt="settings" />
                      {{mls?.languageText?.grandround_header?.key6}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key6)</span></li>

                    <li *ngIf="this.mls.featureOnOffList.featureList.sendFeedback.value || !mls.userLoginFlow" (click)="navigateToFeedback()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key7,"product_type":"", "product_id":""}'><img
                        class="mr-1" width="20px" src="assets/themes/icons/headers/help-support.svg" />
                      {{mls?.languageText?.grandround_header?.key7}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key7)</span></li>

                    <li (click)="navigateTotnc()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key8,"product_type":"", "product_id":""}'><img
                        width="20px" class="mr-1" src="assets/themes/icons/headers/profile-tnc.svg" alt="tnc" />
                      {{mls?.languageText?.grandround_header?.key8}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key8)</span></li>

                    <li (click)="userlogout()"
                      [eventTracker]='{"category":mls.googleEventText.grandround_header.category,"action":mls.googleEventText.grandround_header.key9,"product_type":"", "product_id":""}'
                      id="logout">
                      <img width="20" class="mr-1" src="assets/themes/icons/headers/logout.svg" alt="logout"  />
                       {{mls?.languageText?.grandround_header?.key9}}<span
                        *ngIf="mls?.is_lskey_display==1">(grandround_header.key9)</span></li>

                    <!-- end menu -->
                    </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
</ng-container>
<div *ngIf="termsConditionsUpdate" id="terms-update-Modal" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }" bsModal #autoShownModal="bs-modal" style="z-index: 1111;">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered terms-modal-content">
    <div class="modal-content">
      <div class="modal-body pb-0">
        <img width="80" src="assets/images/grandround/documents.svg" alt="Document Icon"/>
        <h5 class="paragraph-bold">{{mls.languageText.TnCUpdateModal_Content.key1}}</h5>
        <p class="primary-small">
          {{mls.languageText.TnCUpdateModal_Content.key2}}<br>
          <a [href]="website_url + '/termsandconditions'" target="_blank">
            {{mls.languageText.TnCUpdateModal_Content.key3}}
          </a>
          {{mls.languageText.TnCUpdateModal_Content.key5}}
          <a [href]="website_url + '/privacypolicy'" target="_blank">
            {{mls.languageText.TnCUpdateModal_Content.key4}}
          </a>
        </p>
      </div>
      <div class="modal-footer py-3">
        <button class="btn-design medium filled w-100" (click)="acceptConditions()">
          {{mls.languageText.TnCUpdateModal_Content.key6}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #chat>
  <a *ngIf="this.mls.featureOnOffList.featureList.sendFeedback.value || !mls.userLoginFlow"s class="pl-0 pr-0 cursor-pointer position-relative">
    <img [ngClass]="{'chat-icon': callingfrom !== 'chat'}" [src]="callingfrom === 'chat' ? 'assets/images/icons/chat-active.svg' : 'assets/images/icons/chat-icon.svg'" />
    <div *ngIf="isChatTutorial" class="chat-tutorial bg-white">
      <div class="d-flex rounded justify-content-between">
        <div class="new primary-small">
          <span class="color-white">
            {{mls?.languageText?.samvaad_tutorial?.key1}}
          </span>
        </div>
        <div>
          <img src="assets/images/icons/chat-cross.svg" alt="cross" (click)="$event.preventDefault();$event.stopPropagation();isChatTutorial = false">
        </div>
      </div>
      <div class="mt-2">
        <h4 class="heading-4 text-left">
          {{mls?.languageText?.samvaad_tutorial?.key2}}!
        </h4>
        <p class="paragraph text-left">
          {{mls?.languageText?.samvaad_tutorial?.key3}}.
        </p>
      </div>
      <div class="mt-3">
        <button class="btn-design filled medium w-100" type="button" (click)="$event.preventDefault();$event.stopPropagation();expandChat()">
          {{mls?.languageText?.samvaad_tutorial?.key4}}
        </button>
      </div>
    </div>
  </a>
</ng-template>

<div *ngIf="funCollectionService.isSamvaadMaintanceModal" id="terms-update-Modal" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }" (onHidden)="closeSamvaadMaintanceModal()" bsModal #autoShownModal="bs-modal" style="z-index: 1111;">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body pb-0">
        <div class="text-right">
          <img class="cursor-pointer" src="assets/images/icons/chat-cross.svg" alt="cross" (click)="closeSamvaadMaintanceModal()">
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center flex-column" >
          <img src="assets/themes/images/maintenance.svg" alt="maintenance">
          <h4 class="heading-3">
            {{mls?.languageText?.samvaad?.key32}}
          </h4>
          <p class="mt-2 paragraph">
            <span class="color-grey-700">
              {{mls?.languageText?.samvaad?.key33}}
            </span>
          </p>
        </div>
      </div>
      <div class="modal-footer py-3 pt-4">
        <button class="btn-design medium filled w-100" (click)="closeSamvaadMaintanceModal()">
          {{mls?.languageText?.samvaad?.key34}}
        </button>
      </div>
    </div>
  </div>
</div>
