/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderModule } from '../common/header/header.module';
import { SamvaadRoutingModule } from './samvaad-routing.module';

/**
 * Component imports.
 */
import { SamvaadComponent } from './samvaad.component';
import { SamvaadChatFullViewComponent } from './samvaad-chat-full-view/samvaad-chat-full-view.component';
import { ChatModule } from './chat/lib/chat.module';
import { DqChatPanelComponent } from './dq-chat-panel/dq-chat-panel.component';

/**
 * Module imports.
 */
import { DirectivesModule } from '../directives/directives.module';
import { ProductReportModule } from '../common/product-report/product-report.module';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    SamvaadComponent,
    SamvaadChatFullViewComponent,
    DqChatPanelComponent
  ],
  imports: [
    CommonModule,
    ChatModule,
    AppHeaderModule,
    SamvaadRoutingModule,
    DirectivesModule,
    ProductReportModule,
    ModalModule
  ],
  entryComponents: [
    DqChatPanelComponent
  ],
  exports: [
    SamvaadComponent
  ]
})
export class SamvaadModule { }
