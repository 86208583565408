<div class="search-user" *ngIf="isSearchUser">
  <div class="search-user-heading d-flex align-items-center p-3 icon-hover">
    <span class="icon custom-tooltip bottom" (click)="onClose()">
      <img class="mr-2 cursor-pointer" src="assets/samvaad-assets/images/icons/search-user-left-arrow.svg" alt="cross">
      <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key5}}</span>
    </span>
    <h4 class="heading-4">{{samvaadLanguageText?.samvaad?.key20}}</h4>
  </div>
  <div class="form-input with-icon w-100 px-3 pb-0 mt-2 icon-hover">
    <input #input class="input-field" (click)="searchInputClick()" type="text" [placeholder]="samvaadLanguageText?.samvaad_create_edit_group?.key23" (input)="onSearchQueryInput($event)" />
    <span *ngIf="keyword" class="icon-in-field mr-2 custom-tooltip bottom icon custom-tooltip bottom"
      (click)="resetSearch()">
      <img src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="icon" />
      <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key6}}</span>
    </span>
  </div>
  <div #searchBody class="search-users position-relative" [ngClass]="{
    'height_without_footer': !showFooter && chatView === chatViewConstant.FULL,
    'height_with_footer': showFooter && selectedList?.length && chatView === chatViewConstant.FULL,
    'height_with_footer_selected': showFooter && !selectedList?.length && chatView === chatViewConstant.FULL,
    'mini_height_without_footer': !showFooter && chatView === chatViewConstant.MINI,
    'mini_height_with_footer': showFooter && selectedList?.length && chatView === chatViewConstant.MINI,
    'mini_height_with_footer_selected': showFooter && !selectedList?.length && chatView === chatViewConstant.MINI
  }" infiniteScroll [infiniteScrollDistance]="4" [infiniteScrollThrottle]="610" (scrolled)="onScroll()"
    [scrollWindow]="false">
    <div>
      <div *ngIf="connectionsList">
        <ng-container *ngTemplateOutlet="estimateTemplate;context: { $implicit: connectionsList }">
        </ng-container>
      </div>
      <div *ngIf="suggestionsList">
        <ng-container *ngTemplateOutlet="estimateTemplate;context: { $implicit: suggestionsList }">
        </ng-container>
      </div>
      <div *ngIf="!connectionsList?.list && !suggestionsList?.list" class="text-center heading-4 p-3">
        <img src="assets/themes/images/no-result.svg" alt="No Data Found">
        <div class="cursor-pointer mt-3">
          {{noDataFoundLabel}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-2 px-3 d-flex justify-content-start" *ngIf="showFooter">
    <div *ngIf="selectedList?.length" class="chat-selected-list mr-2 pb-2">
      <div *ngIf="widgetsContent?.scrollLeft > 0" class="nav-left" (click)="scrollLeft()">
      </div>
      <div #widgetsContent class="custom-slider-main">
        <div *ngFor="let item of selectedList;trackBy:trackByCustomId"
          class="icon-hover text-center selected-box flex-shrink-0">
          <img class="pic selected-box flex-shrink-0"
            [src]="item.profile_pic ? item.profile_pic : 'assets/samvaad-assets/images/icons/user-profile-placeholder.svg'"
            onerror="this.src='assets/samvaad-assets/images/icons/user-profile-placeholder.svg'" alt="pic" width="40">
          <span>
            <img class="cursor-pointer flex-shrink-0" src="assets/samvaad-assets/images/icons/cross-with-border.svg"
              alt="cross" width="16" height="16" (click)="add($event,item)" style="margin: 18px 0 0 -15px;">
            <!-- <span class="tooltip-content">Remove</span> -->
          </span>
          <p class="text-truncate text-name">
            {{item.full_name}}
          </p>
        </div>
      </div>
      <div *ngIf="!(widgetsContent?.scrollLeft + widgetsContent?.offsetWidth >= widgetsContent?.scrollWidth)"
        class="nav-right" (click)="scrollRight()">
      </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-end" [ngClass]="{'justify-content-between': selectedList?.length}">
      <div class="paragraph mr-2" *ngIf="selectedList?.length">
        {{selectedLabel}}({{selectedList?.length}})
      </div>
      <div>
        <button class="btn-design filled small h-auto" (click)="submit();onHidden()">
          {{btnText}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #estimateTemplate let-detail>
  <div class="d-flex p-3 icon-hover align-items-center" *ngIf="detail.header">
    <span class="icon custom-tooltip bottom" *ngIf="key && backKey" (click)="goBack()">
      <img class="mr-2 cursor-pointer" src="assets/samvaad-assets/images/icons/search-user-left-arrow.svg" alt="back">
      <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key5}}</span>
    </span>
    <h4 class="primary-small font-weight-bold header">{{detail.header}}</h4>
  </div>
  <div *ngFor="let list of detail.list;trackBy:trackByCustomId" class="w-100" (click)="selectUser(list)">
    <div class="cursor-pointer individual-info-box custom-tooltip bottom" [ngClass]="{'p-2 pl-3': !isCheckBox}">
      <span class="tooltip-content" *ngIf="list?.isDisabled">
        {{samvaadLanguageText?.samvaad_tooltips?.key14}}
      </span>
      <app-individual-info-box *ngIf="!isCheckBox"
        [info]="{pic: list?.profile_pic ? list?.profile_pic : '', name: list?.full_name, badgePic: ''}"
        [isCursorPointer]="true" secondaryCss="primary-small text-truncate"
        [profileSecondary]="list?.specialities[0]?.speciality_name" [endSrc]="endSrc"
        (onEndSrcClick)="selectUser(list)">
      </app-individual-info-box>
      <label *ngIf="isCheckBox" class="mb-0 p-2 pl-3 cursor-pointer d-flex justify-content-between align-items-center">
        <app-individual-info-box class="flex-1 min-width-0"
          [info]="{pic: list?.profile_pic ? list?.profile_pic : '', name: list?.full_name, badgePic: ''}"
          [isCursorPointer]="true" secondaryCss="primary-small text-truncate"
          [profileSecondary]="list?.specialities[0]?.speciality_name" [endSrc]="endSrc">
        </app-individual-info-box>
        <label class="checkbox" *ngIf="isCheckBox" style="margin-bottom: 18px !important;">
          <input type="checkbox" [disabled]="list?.isDisabled" type="checkbox" value="{{list.custom_id}}"
            [checked]="isSelected(list) || list?.isDisabled" (click)="add($event,list)" />
          <span></span>
        </label>
      </label>
    </div>
  </div>
  <div *ngIf="!key && detail.view_more_text" class="bg-white p-2 text-center position-relative">
    <button type="button" class="btn-design small outline mt-2" (click)="viewAll(detail.key)">
      {{detail.view_more_text}} >
    </button>
  </div>
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</ng-template>
<ng-template #loading>
  <app-chat-progress *ngIf="isLoading" [isSmallLoading]="true" [width]="60" [height]="60"></app-chat-progress>
</ng-template>
