/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Component used to show the emojis.
 */
@Component({
  selector: 'app-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss']
})
export class EmojisComponent {

  /**
   * Show/Hide the emoji dropdown.
   * Ui class for the emoji.
   */
  @Input() toggleEmoji: boolean;
  @Input() uiClass;

  /**
   * Emit the selected emoji.
   * Emit the on close.
   */
  @Output() getEmoji = new EventEmitter();
  @Output() onClose = new EventEmitter();

  /**
   * Add the set for the emoji.
   */
  public set = 'twitter';

  /**
   * Open the emoji dropdown.
   */
  openEmoji(event): void {
    this.getEmoji.emit(event.emoji.native);
  }

  /**
   * Click outside emoji dropdown.
   */
  onClickOutside(): void {
    this.toggleEmoji = false;
    this.onClose.emit();
  }
}
