/**
 * Angular imports.
 */
import { Directive, ViewContainerRef, OnInit, Input } from '@angular/core';

/**
 * Constant imports.
 */
import { REUSABLE_COMPONENTS } from '../constants/app.constants';

/**
 * Service imports.
 */
import { ChatReusableService } from '../dataservices/chat-reusable/chat-reusable.service';

/**
 * This directive is used to attach the view of miniPlayer in view container reference.
 */
@Directive({
  selector: '[reusable-outlet-chat]',
})
export class ReusableChatDirective implements OnInit {

  /**
   * Get the status for which component to attach.
   */
  @Input() reusableComponent = REUSABLE_COMPONENTS.DQ_CHAT_PANEL;

  /**
   * Necessary instances.
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private chatReusableService: ChatReusableService
  ) { }

  /**
   * Assign the design to miniPlayer Component.
   * Attach the view of miniPlayer in view container reference.
   */
  ngOnInit(): void {
    if(this.reusableComponent === REUSABLE_COMPONENTS.DQ_CHAT_PANEL){
      this.chatReusableService.attachDqChatPanelComponent(this.viewContainerRef);
    }
  }
}
