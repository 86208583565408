/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { DqChatPanelService } from 'src/app/samvaad/dq-chat-panel.service';

/**
 * Directive imports.
 */
import { ButtonDirective } from '../.././../directives/ga.directive';
/**
 * environment imports.
 */
import { environment } from '../../../../environments/environment';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Model imports
 */
import { ERROR_CODES } from '../../../constants/app.constants';
/**
 * Constants imports.
 */
import { LOGOUT_TYPE } from 'src/app/constants/app.constants';

/**
 * Shows OTP screen when user try to login or signup.
 */
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  public password = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_mob_data: any;
  public timeLeft = 0;
  public interval;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  public api_in_process = false;
  public err_msg = '';
  public api_err_msg = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public masterData: any;
  public showColor = false;
  public sessiontime = { 'start_time': '', 'end_time': '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public testResend: any = 1;
  public page_from = this.mls.googleEventText.otp_screen.category;

  /**
   * To unsubscribe data.
   */
   private subscriptions: Subscription = new Subscription();

  /**
   * Key used to to check resend is clicked or not.
   */
  public isResend = false;
  /**
   * Necessary instances.
   */
  constructor(
    private router: Router,
    public onboardingService: OnboardingService,
    private jwtSer: JwtDecodeService,
    private masterService: MasterserviceService,
    public multiLangService: MultiLangService,
    public mls: NewLanguageService,
    public dss: DataStorageService,
    public fcs: FunCollectionService,
    private directive_ser: ButtonDirective,
    public route: ActivatedRoute,
    private elementRef: ElementRef,
    private dqChatPanelService: DqChatPanelService
  ) {
    const d = new Date();
    this.sessiontime.start_time = d.getTime().toString();
  }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    localStorage.setItem('appVer', environment.versions.appVersion);
    this.elementRef.nativeElement.querySelector('#verifyOtp')?.focus();
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
      this.timeLeft = this.req_data.wait_time;
    } else {
      this.router.navigateByUrl('/login/input-mobile');
      return false;
    }
    if (sessionStorage.getItem('user_mob_data') != null) {
      this.user_mob_data = JSON.parse(sessionStorage.getItem('user_mob_data'));
    } else {
      this.router.navigateByUrl('/');
    }
    this.startTimer();

    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.otp_screen.key5, '', '', this.page_from, '');
      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));
  }

  /**
   * navigate To MobInput
   */
  navigateToMobInput(): void {
    this.router.navigate(['/login/input-mobile'], {
      queryParams: {
        redirectUrl: this.route.snapshot.queryParams.redirectUrl
      }
    });
    return;
  }

  /**
   * set focus
   */
  setfocus(event): void {
    this.api_err_msg = '';
    if (isNaN(event.target.value) == true && event.keyCode != 13) {
      let temp = event.target.value;
      temp = temp.replace(/.$/, '');
      event.target.value = temp;
    }
    if (event.target.value.length == 4) {
      this.showColor = true;
      if (event.keyCode == 13) {
        this.verifyMobile();
      }
    } else {
      this.showColor = false;
    }
  }

  /**
   * For 2.0 need to change setFocus function.
   */
  setFocus2(event): void {
    this.api_err_msg = '';
    if (event.length == 4) {
      this.showColor = true;
    } else {
      this.showColor = false;
    }
  }

  /**
   * start Timer
   */
  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  /**
   * pause Timer
   */
  pauseTimer(): void {
    clearInterval(this.interval);
  }

  /**
   * verify Mobile
   */
  verifyMobile(): boolean {
    const otp = this.elementRef.nativeElement.querySelector('#verifyOtp').value ?? this.password;
    if (otp == '') {
      this.err_msg = 'Please enter OTP';
      return;
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.api_in_process = true;
    this.subscriptions.add(this.onboardingService.verifyMobile(this.user_mob_data.mobile_no, this.user_mob_data.country_id, this.user_mob_data.country_code, this.req_data.token_id, otp).subscribe(resData => {
      this.api_in_process = false;
      resData.then(data => {
        if (data['status'] == 1) {
          localStorage.setItem('otpToken', JSON.stringify(data.data.otp_token));
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));
            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.onboardingService.directToUserdetails = true;
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              sessionStorage.setItem('verify_user', JSON.stringify(data));
              this.router.navigate(['/login/verify-user'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              this.onboardingService.storeProfileData(data);
              this.onboardingService.storeProfileData(data);
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.route.snapshot.queryParams.redirectUrl === undefined) {
                  this.router.navigate(['/web/list']);
                } else {
                  this.router.navigate([this.route.snapshot.queryParams.redirectUrl]);
                }
              }, 500);
            }
            if (data['data'].screen_data.identifier == 'select_interest') {
              this.onboardingService.storeProfileData(data);
              setTimeout(() => {
                if (this.route.snapshot.queryParams.redirectUrl === undefined) {
                  this.router.navigate(['/web/list']);
                } else {
                  this.router.navigate([this.route.snapshot.queryParams.redirectUrl]);
                }
              }, 500);
            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          } else if (data['code'] == 2007) {

            this.onboardingService.storeProfileData(data);
            if(sessionStorage.getItem('logoutType') && sessionStorage.getItem('logoutType') === LOGOUT_TYPE.MANUAL) {
              this.router.navigateByUrl('/');
              return;
            }
            this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);

          } else if (data['code'] == 1002) {
            if (localStorage.getItem('lang') != null) {
              let x = localStorage.getItem('lang');
              sessionStorage.clear();
              localStorage.clear();
              this.exitChatPanel();
              x = (x == '') ? 'en' : x;
              localStorage.setItem('lang', x);
            } else {
              sessionStorage.clear();
              localStorage.clear();
              this.exitChatPanel();
            }
          }
        }
        if (data['status'] == 0 || !data) {
          if (data['error'].code == 1001) {
            this.api_err_msg = data['error'].msg;
          } else if (data['error'].code == 1002 || data['error'].code === ERROR_CODES.UNABLE_TO_AUTHENTICATE_REQUEST) {
            this.api_err_msg = data['error'].msg;
          }
        }
      },
      () => {
        this.api_in_process = false;
      }
      );
    }
    ));
  }

  /**
   * Exit the chat panel in case of logout.
   */
  exitChatPanel(): void {
    this.dqChatPanelService.exitMiniChatPanel.next(true);
  }

  /**
   * back screen
   */
  back_screen(): void {
    this.router.navigate(['/login/input-mobile'], {
      queryParams: {
        redirectUrl: this.route.snapshot.queryParams.redirectUrl
      }
    });
  }

  /**
   * onVerify
   */
  onVerify(token: string, data): boolean {
    if (data == 1) {
      this.testResend = 2;
      // this.elementRef.nativeElement.querySelector('.opt').value = '';
      this.isResend = true;
      this.api_err_msg = '';

      setTimeout(() => {
        this.testResend = 1;
      }, 10000);

      if (this.timeLeft == 0) {
        if (this.masterService.isOnline == false) {
          this.masterService.isConnectionModalShown.next('1');
          return false;
        }

        this.subscriptions.add(this.onboardingService.resendOtp(this.user_mob_data.mobile_no, this.user_mob_data.country_id, this.user_mob_data.country_code, this.req_data.token_id, token).subscribe(resData => {
          this.jwtSer.decodeData(resData).then(data => {
            if (data['status'] == 1) {
              const req_data = {
                wait_time: data['data'].wait_time,
                msg: data['msg'],
                screen_data: data['data'].screen_data,
                token_id: this.req_data.token_id,
              };
              sessionStorage.setItem('req_data', JSON.stringify(req_data));
              this.req_data = req_data;
              this.timeLeft = this.req_data.wait_time;
              this.startTimer();
            }
            if (data['status'] == 0) {
              if (data['error'].code == 1001) {
                this.api_err_msg = data['error'].msg;
              } else if (data['error'].code == 1002) {
                this.api_err_msg = data['error'].msg;
              }
            }
          }, () => {
            // Error Handling
          });
        }));
      }
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.otp_screen.key5, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    this.dss.urlsection = '';
    this.dss.urlsectionId = '';
    this.dss.doctalktype = '';
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
