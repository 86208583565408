/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * Rxjs imports.
 */
import { BehaviorSubject } from 'rxjs';

/**
 * Service specially design for the dq chat panel component.
 */
@Injectable({
  providedIn: 'root'
})
export class DqChatPanelService {

  /**
   * Show/Hide the chat panel for the reusable directive.
   * Exit chat panel from the reusable service.
   * Expand chat from the header flag.
   */
  public isChatPanelVisible = false;
  public exitMiniChatPanel = new BehaviorSubject(false);
  public expandChat = new BehaviorSubject(false);
  public isChatListHide = new BehaviorSubject(true);
  public isChatMaintenance = false;
  public unreadNotification = new BehaviorSubject(0);
}
