import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-share-strip',
  templateUrl: './share-strip.component.html',
  styleUrls: ['./share-strip.component.scss']
})
export class ShareStripComponent {

  /**
   * Receiving data from parent.
   */
  @Input() title: string;
  @Input() fbText: string;
  @Input() twText: string;
  @Input() liText: string;
  @Input() shareUrl: string;
  @Output() emitClick: EventEmitter<string> = new EventEmitter();

  productShare(shareOn): void {
    this.shareUrl = encodeURIComponent(this.shareUrl);
    this.emitEvent(shareOn);
    switch(shareOn) {
      case 'fb': {
        const url = 'https://www.facebook.com/sharer/sharer.php?u='+ this.shareUrl;
        window.open(url, '_blank');
        break;
      }

      case 'li': {
        const url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + this.shareUrl;
        window.open(url, '_blank');
        break;
      }

      case 'tw': {
        const url = 'https://twitter.com/share?url='+ this.shareUrl;
        window.open(url, '_blank');
        break;
      }

      case 'gm': {

        break;
      }
    }
  }

  private emitEvent(eventName): void {
    this.emitClick.emit(eventName);
  }
}
