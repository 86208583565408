/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
*/
import { OnInit, OnDestroy, Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';

/**
 * Constant imports.
 */
import { FILE_LIMITS, FILE_UPLOAD_EXTENSION, FILE_UPLOAD_SIZE, HEADER_CLASS, ONE, SCROLL_OFFSET, ZERO } from '../../../constants/chat.constants';

/**
 * Chat preview for the file upload.
 */
@Component({
  selector: 'app-chat-preview',
  templateUrl: './chat-preview.component.html',
  styleUrls: ['./chat-preview.component.scss']
})
export class ChatPreviewComponent implements OnInit, OnDestroy {
  /**
   * Change upload image from Dom.
   */
  @ViewChild('bannerInput') bannerInput;
  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  /**
   * Show/Hide image preview.
   * Heading for the chat preview.
   * Show/Hide multiple upload.
   * Show/Hide the caption for file upload with caption.
   * Image src for the preview image.
   * IsDisabled flag for the uploading.
   * Pre selected files for the view.
   */
  @Input() isChatPreview: boolean;
  @Input() title: string;
  @Input() isMultiple: boolean;
  @Input() showCaption: boolean;
  @Input() imageSrc: string;
  @Input() isDisabled: boolean;
  @Input() isUploadBtn = true;
  @Input() profileForm = [];
  @Input() cancelText = 'Cancel';
  @Input() paragraph = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() samvaadLanguageText: any = {};

  /**
   * Emit the hide event.
   * Emit the submit pic event.
   * Emit the add new Image event.
   */
  @Output() onHide: EventEmitter<void> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() onSubmitPic: EventEmitter<any> = new EventEmitter();

  /**
   * Selected image for the view.
   * Error msg for the error modal.
   * Show/hide error modal.
   * File upload limits per file default 10.
   */
  public isSelected = 0;
  public errorMsg: string;
  public isErrorModal: boolean;
  public filesLimits = FILE_LIMITS;
  public uploadingSize = FILE_UPLOAD_SIZE;
  public uploadingExtensions = FILE_UPLOAD_EXTENSION;
  public files = [];
  public chatLoading: boolean;

  /**
   * Decrease z-index of header.
   */
  ngOnInit(): void {
    document?.body?.classList.add(HEADER_CLASS);
  }

  /**
   * Emit the hide event.
   * Close the chat preview and remove selected file from the input file type.
   */
  onHidden(): void {
    this.isChatPreview = false;
    this.closeImage(true);
    this.onHide.emit();
  }

  /**
   * Get the caption according to the file upload.
   */
  getCaption(value, index): void {
    this.profileForm[index].caption = (value);
  }

  /**
   * Add the new uploaded file.
   */
  addUploader(file): void {
    this.profileForm.push({
      banner: this.imageSrc,
      caption: '',
      file: file
    });
    this.isSelected = this.profileForm.length - ONE;
    this.closeImage(false);
  }

  /**
   * Used to receive the selected image file.
   * Get the thumbnail.
   */
  setBanner(event): void {
    if (event && event.length) {
      this.chatLoading = true;
      if (this.profileForm.length >= this.filesLimits) {
        this.errorMsg = this.samvaadLanguageText?.samvaad_validation?.key3;
        this.isErrorModal = true;
        this.chatLoading = false;
        return;
      } else {
        this.files = event;
        this.imageSrc = '';
        const bar = new Promise<void>((resolve) => {
          this.files.forEach((data, index, array) => {
            const file = data.selectedFile;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.imageSrc = reader.result as string;
              data.banner = this.imageSrc;
              this.addUploader(file);
              if (index === array.length - ONE) {
                resolve();
              }
            };
          });
        });
        bar.then(() => {
          this.chatLoading = false;
          this.scrollRight();
        }).catch(() => {this.chatLoading = false});
      }
    }
  }

  /**
   * Change the image from the list of images.
   */
  changeImage(index: number): void {
    this.isSelected = index;
    this.imageSrc = this.profileForm[index].banner;
  }

  /**
   * Remove the uploading files one by one.
   */
  removeUploader(data, index: number): void {
    this.profileForm = this.profileForm.filter(value => value !== data);
    this.isSelected = index ? index - ONE : ZERO;
    this.imageSrc = this.profileForm[this.isSelected].banner;
  }

  /**
   * Disable the button and submit the pic.
   */
  onSubmit(): void {
    this.isDisabled = true;
    this.onSubmitPic.emit(this.profileForm);
  }

  /**
   * Clear the same image from the upload input using view child bannerInput.
   */
  closeImage(removeSrc: boolean): void {
    if (this.bannerInput) {
      this.bannerInput.nativeElement.value = '';
    }
    if (removeSrc) {
      this.imageSrc = '';
    }
  }

  /**
   * Scroll the selected Images to left.
   */
  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollLeft -= SCROLL_OFFSET;
  }

  /**
   * Scroll the selected Images to right.
   */
  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollLeft += SCROLL_OFFSET;
  }

  /**
   * Increase z-index of header.
   */
  ngOnDestroy(): void {
    document.body?.classList?.remove(HEADER_CLASS);
    this.closeImage(true);
  }
}
