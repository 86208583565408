import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultMedia'
})
export class DefaultMediaPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(): any {
    return null;
  }

}
