import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * constant imports.
 */
import { NUMBERS } from '../../constants/app.constants';
import { IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';


@Component({
  selector: 'app-public-view-all',
  templateUrl: './public-view-all.component.html',
  styleUrls: ['./public-view-all.component.scss']
})
export class PublicViewAllComponent {

  /**
   * Data sharing between parent to child component.
   */
  @Input() viewAllText: string;
  @Input() title: string;
  @Input() pType: number;
  @Input() callingFrom: string;
  @Input() openAccessConst;
  @Input() offset: number;
  @Output()  viewAllClick = new EventEmitter();

  /**
   * Hold constant value.
   */
  public numberConst = NUMBERS;

  /**
   * User action on button click.
   */
  ViewAllAction(pType) {
    this.viewAllClick.emit(pType);
  }
  viewAllRecord(): IPublicAnalyticsData{
    return {
      product_type: this.pType,
      product_id: 0,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.DETAIL_VIEW_CLICK
    };
  }
}
