/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';

/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
/**
 * Constant import.
 */
import { POST_TYPE } from '../../constants/app.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';


/**
 * NgbDropDown Component
 */
@Component({
  selector: 'app-ngb-drop-down',
  templateUrl: './ngb-drop-down.component.html',
  styleUrls: ['./ngb-drop-down.component.scss']
})
export class NgbDropDownComponent implements OnDestroy, AfterViewInit {
  @Input() ngbDDL;
  @Input() callingFrom;
  @Output() resetNgbDDLRecord = new EventEmitter();
  @Input() isdisabled;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public is_productview: any;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    public dss: DataStorageService,
    public fcs: FunCollectionService,
    public postSer: PostApiService,
    private jwtSer: JwtDecodeService,
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService) {
     }

  /**
   * ngb Action
   */
  ngbAction(data): void {
    if (data.action == 'report') {
      this.fcs.openReportModal(data.product_type, data.product_type_id);
    } else if (data.action == 'edit' || data.action == 'delete') {
      this.resetNgbDDLRecord.emit(data);
    } else if (data.action == 'viewprofile') {
      this.fcs.navigateToUserProfie(data.product_type_id);
    } else if (data.action == 'unsubscribe') {
      this.showUnsubscribe(data.product_type_id);
      this.resetNgbDDLRecord.emit(data);
    } else if (data.action == 'share') {
      const x = [{ share_url: '', share_contentt: '', product_type: data.product_type, product_type_id: data.product_type_id }];
      this.fcs.productShare(x);
    } else if (data.action == 'chat') {
      this.fcs.navigateForChat(data.uuid);

    } else if (data.action == 'connect') {

      this.fcs.connectionAdd(data.product_type_id);
      this.resetNgbDDLRecord.emit(9);
    } else if (data.action == 'removefromgroup') {
      this.resetNgbDDLRecord.emit(data);
      this.dss.removeFromGroup.emit(data);
    } else if (data.action === POST_TYPE.REPOST) {
      this.resetNgbDDLRecord.emit(data);
      this.analyticsEventService.sendAnalytics(EVENT_NAME.REPOST_BUTTON_CLICK,
        data.product_type,
        data.product_type_id,
        this.callingFrom);
    }
  }

  /**
   * show Unsubscribe
   */
  showUnsubscribe(safe_url): void {
    this.subscriptions.add(this.postSer.channelUnsubscribe(safe_url).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.is_productview = { unsub: 'unsubscribe', productview: data.data.channel };
          this.fcs.showSuccess(data['msg'], 'Success');
          this.resetNgbDDLRecord.emit(this.is_productview);
        } else if (data['status'] == 0) {
          this.fcs.errorHandeler(data);
        }
      });
    }));
  }

  /**
   * DDL view init
   */
  ngAfterViewInit(): void {
    if(this.mls.featureOnOffList.featureList.report.value == false && this.mls.userLoginFlow == 1)
    {
      this.ngbDDL.data = this.ngbDDL.data.filter((obj)=> obj.action !=='report' && obj.action !=='share' && obj.action !== 'unsubscribe' && obj.action !== 'connect')
    }
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
