/**
 * Angular core imports.
 */
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'gradient'
})
export class GradientPipe implements PipeTransform {
  /**
   * A pipe to return background image liner gradient
   * @param color for gradiant
   * @param direction for gradiant
   * @param percentage1 for gradiant
   * @param percentage2 for gradiant
   * @returns for gradiant
   */
  transform(color: string, direction: string, percentage1: string, percentage2: string): string {
    return `linear-gradient(to ${direction}, ${color} ${percentage1 || ''}, #fff ${percentage2 || ''})`;
  }

}
