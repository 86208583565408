/**
 * Angular imports.
 */
import { Component, Input, OnChanges } from '@angular/core';
/**
 * Service imports.
 */
import {NewLanguageService} from '../../dataservices/new-language/new-language.service';
/**
 * Constant imports.
 */
import { NUMBERS } from './../../constants/app.constants';
/**
 * Service imports
 */
import { FunCollectionService } from '../common-functions/fun-collection.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnChanges {
  @Input() cmt;
  @Input() showChar;
  @Input() isActionBlock;
  @Input() callingfrom;
  @Input() content_id;
  @Input() defaultline;
  @Input() readMoreCss = 'cursor-pointer secondary-paragraph-bold d-inline-block';
  @Input() readLessCss = 'cursor-pointer secondary-paragraph-bold d-inline-block';
  @Input() readMoreText = this.mls?.languageText?.content_card?.key1;
  @Input() readLessText = this.mls?.languageText?.content_card?.key2;

  /**
   * Analytics data for feed content click.
   */
  @Input() analyticsFeedData?;
  /**
   * Analytics data for viewmore click.
   */
  @Input() analyticsFeedViewMoreData?;
  /**
   * Analytics data for viewless click.
   */
  @Input() analyticsFeedViewLessData?;

  see_more = 0;
  showMessageToggle = NUMBERS.ZERO;
  final_str = '';
  originalMessage = '';
    constructor(
      public mls: NewLanguageService,
      private fcs: FunCollectionService,
      private dss: DataStorageService
      ) { }

  ngOnChanges(): void {
    let str = '';
    this.originalMessage = Object.assign(this.cmt);
    const tempstr = this.cmt.split('\n');
    if (this.defaultline != undefined) {
      if (tempstr.length > this.defaultline) {
        str = '';
        let i = NUMBERS.ZERO;
        while ( i < this.defaultline) {
          str = (str) ? str + '\n' +   tempstr[i] : tempstr[i] ;
          i++;
        }
        if (str.length > this.showChar) {
          this.final_str = str.slice(0, this.showChar);
        } else {
          this.final_str = str;
        }
        this.see_more = 1;
      } else {
        if (this.cmt.length > this.showChar) {
          this.final_str = this.cmt.slice(0, this.showChar);
          this.see_more = 1;
        } else {
          this.final_str = this.cmt;


        }
      }
    } else {
      if (tempstr.length > 3) {
        str = tempstr[0] + '<br>' + tempstr[1] + '<br>' + tempstr[2] + '<br>';
        if (str.length > this.showChar) {
          this.final_str = str.slice(0, this.showChar);
        } else {
          this.final_str = str;
        }
        this.see_more = 1;
      } else {
        if (this.cmt.length > this.showChar) {
          this.final_str = this.cmt.slice(0, this.showChar);
          this.see_more = 1;
        } else {
          this.final_str = this.cmt;


        }
      }
    }

  }
  /**
   * Show More Text
   */
  seeMore(): void {
    if (this.isActionBlock == true) { return; }
    this.see_more = NUMBERS.TWO;
    this.showMessageToggle = NUMBERS.ONE;
    this.final_str = this.cmt;
  }

  /**
   * Navigate to Tagged User Profile
   */
  navigateToUrl(customId): void {
    this.fcs.navigateToUserProfie(customId);
  }

  /**
   * Show Less Text
   */
  seeLess(): void {
    this.see_more = NUMBERS.ONE;
    this.showMessageToggle = NUMBERS.ZERO;
    this.ngOnChanges();
  }
}
