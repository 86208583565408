/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { EmojisComponent } from './emojis.component';

/**
 * Module imports.
 */
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [EmojisComponent],
  imports: [
    CommonModule,
    PickerModule
  ],
  exports: [EmojisComponent]
})
export class EmojiModule { }
