import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timedifference'
})
export class TimedifferencePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    if ((value.toString()).length == 10) {
      value = value * 1000;
    }

    const now = Math.floor(Date.now() / 1000);
    const diffIns: number = now - (value / 1000);

    if (diffIns <= 0) {
      return 'Now';
    } else if (diffIns < 60) {
      return Math.floor(diffIns) + ' s';
    } else if (diffIns < (60 * 60)) {
      return Math.floor(diffIns / (60)) + ' m';
    } else if (diffIns < (60 * 60 * 24)) {
      return Math.floor(diffIns / (60 * 60)) + ' h';
    } else if (diffIns < (60 * 60 * 24 * 2)) {
      return Math.floor(diffIns / (60 * 60 * 24)) + ' d';
    } else if (diffIns < (60 * 60 * 24 * 3)) {
      return Math.floor(diffIns / (60 * 60 * 24)) + ' d';
    } else {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      // tslint:disable-next-line: radix
      const convDate = new Date(parseInt(value));
      return (monthNames[(convDate.getMonth())] + ' ' + convDate.getDate() + ', ' + convDate.getFullYear());
    }


  }

}
