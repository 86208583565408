import { Component, Input } from '@angular/core';
import { FunCollectionService } from 'src/app/common/common-functions/fun-collection.service';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';

@Component({
  selector: 'app-restricted-popup',
  templateUrl: './restricted-popup.component.html',
  styleUrls: ['./restricted-popup.component.scss']
})
export class RestrictedPopupComponent {

  /**
   * Flag for show/hide.
   * banner image src
   * heading for the modal
   * paragraph for the modal
   * confirm text for the modal.
   */
    @Input() restrictedPopup: boolean;
    @Input() heading: string;
    @Input() paragraph: string;
    @Input() confirmText: string;
    @Input() restrictData;
  /**
   * Count defined for timer
   */
    public count = 10;

  /**
   * Timer for redirection
   */
     private timer: any;
   /**
    * Timer for redirection
    */
    private interval: any;

  /**
   * Constructor to inject OpenAccessService and ActivatedRoute.
   * @param oas - Instance of OpenAccessService.
   * @param route - Instance of ActivatedRoute.
   */
  constructor(
    public mls: NewLanguageService,
    public fcs : FunCollectionService,
  ) { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    this.startRedirectionTimer();
  }
  /**
   * Start timer
   */
  startRedirectionTimer(): void {
    this.interval = setInterval(() => {
      this.count--;
    }, 1000);

    this.timer = setTimeout(() => {
      this.fcs.navigateToOpenProductList(0);
    }, 5000);
  }

  continueToDocquity() : void {
    this.fcs.navigateToOpenProductList(0);
  }

}
