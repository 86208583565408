/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Component imports.
 */
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { InputMobileComponent } from './onboarding/forms/input-mobile/input-mobile.component';
import { OtpComponent } from './onboarding/forms/otp/otp.component';
import { UsertypeComponent } from './onboarding/forms/usertype/usertype.component';
import { StudenttypeComponent } from './onboarding/forms/studenttype/studenttype.component';
import { UserdetailsComponent } from './onboarding/forms/userdetails/userdetails.component';
import { UploadMrnComponent } from './onboarding/forms/upload-mrn/upload-mrn.component';
import { UniversityComponent } from './onboarding/forms/university/university.component';
import { VerifyGroupUserComponent } from './onboarding/forms/verify-group-user/verify-group-user.component';
import { SurlTokenComponent } from './surl-token/surl-token.component';

/**
 * Service imports.
 */
import { CanDeactivateService } from './auth/can-deactivate.service';
import { RouteParamGuard } from './auth/routeParam.guard';
import { AuthGuard } from './auth/auth.guard';
import { PublicModuleComponent } from './public-module/public-module.component';

/**
 * Onboarding , middleware and 404 error page Routing.
 */
const appRoutes: Routes = [

  {
    path: 'login', component: OnboardingComponent,
    children: [
      {
        path: 'input-mobile', component: InputMobileComponent, data: {
          screen_name: 'input mobile', module_name: 'Onboarding'
        }
      },
      {
        path: 'input-mobile/:cid/:cc/:phn', component: InputMobileComponent, data: {
          screen_name: 'input mobile', module_name: 'Onboarding'
        }
      },
      {
        path: 'otp', component: OtpComponent, data: {
          screen_name: 'otp', module_name: 'Onboarding'
        }
      },
      {
        path: 'usertype', component: UsertypeComponent, canDeactivate: [CanDeactivateService], data: {
          screen_name: 'usertype', module_name: 'Onboarding'
        }
      },
      {
        path: 'subprofession', component: StudenttypeComponent, data: {
          screen_name: 'student_type', module_name: 'Onboarding'
        }
      },
      {
        path: 'userdetail', component: UserdetailsComponent, canDeactivate: [CanDeactivateService], data: {
          screen_name: 'user_detail', module_name: 'Onboarding'
        }
      },
      {
        path: 'verify', component: UploadMrnComponent, canDeactivate: [CanDeactivateService], data: {
          screen_name: 'claim', module_name: 'Onboarding'
        }
      },
      {
        path: 'verify-user', component: VerifyGroupUserComponent, canDeactivate: [CanDeactivateService], data: {
          screen_name: 'verify user', module_name: 'Onboarding'
        }
      },
      {
        path: 'university', component: UniversityComponent, canDeactivate: [CanDeactivateService], data: {
          screen_name: 'university', module_name: 'Onboarding'
        }
      },

      { path: '', redirectTo: 'input-mobile', pathMatch: 'full' },
    ]},

    {
    path : 'open-access', component: PublicModuleComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./public-module/public-module.module')
          .then(m => m.PublicModule),
      },
    ]
  },
  {
    path: '',
    loadChildren: () => import('./authorised-zone/middle-ware.module')
      .then(m => m.MiddleWareModule), canActivate: [AuthGuard, RouteParamGuard],
  },
  {path: 'surl/:token', component: SurlTokenComponent},
  { path: '**', component: PageNotFoundComponent },
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
